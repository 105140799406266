import React, { useState, useEffect } from 'react'
import { Button, Row, Col, Card, Divider, Modal, Form, Input, notification, message } from 'antd'
import "./Job.css"
import { Link, useLocation, useParams } from 'react-router-dom'
import { SaveFilled, UploadOutlined } from "@ant-design/icons";
import axios from 'axios';
import { url } from '../../url/url';
import "./Job.css"
import CryptoJS from 'crypto-js';
const layout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 18,
    },
};


function decryptEmail(ciphertext) {
    try {
        // Decode the URI component first
        const decodedCiphertext = decodeURIComponent(ciphertext);

        // Decrypt the ciphertext using the secret key
        const bytes = CryptoJS.AES.decrypt(decodedCiphertext);

        // Convert the decrypted bytes into a UTF-8 string
        const decryptedEmail = bytes.toString(CryptoJS.enc.Utf8);

        // If the decryption fails, throw an error
        if (!decryptedEmail) {
            throw new Error("Decryption failed");
        }

        return decryptedEmail;
    } catch (error) {
        console.error("Error decrypting email:", error);
        return null;
    }
}

const Desc = () => {
    const [Email, setEmail] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalVisible1, setModalVisible1] = useState(false);
    const users = JSON.parse(localStorage.getItem("user"));
    const [selectedFile, setSelectedFile] = useState(null);
    // const location = useLocation();
    // const jobs = location.state && location.state.jobs;
    const [self, setSelf] = useState([]);
    const [ref, setRef] = useState([]);
    const [image, setImage] = useState();
    const [isImageUploaded, setIsImageUploaded] = useState(false);
    const [form] = Form.useForm();
    const { role, description, dbName,jobId,skills } = useParams();
    console.log(role, description, "job");
    const [emailMatch, setEmailMatch] = useState(false);
    console.log(dbName, "db");
    const [fileName, setFileName] = useState('');
    const handleImageChange = (e, field) => {
        const file = e.target.files[0];
        setFileName(file.name);
        if (file) {
            const allowedFileTypes = ["image/jpeg", "image/png", "image/jpg","application/pdf"];

            // Check if the selected file type is in the allowed list
            if (allowedFileTypes.includes(file.type)) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    const image = reader.result;
                    setImage(image);
                    const isAnyImageUploaded = !!image
                    setIsImageUploaded(isAnyImageUploaded);
                };
                reader.readAsDataURL(file);
            } else {
                // Clear the file error message if no file is selected
                form.setFieldsValue({
                    profileImage: null,
                });
            }
        }
    };
    const formatDate = (date) => {
        const d = new Date(date);
        const day = d.getDate().toString().padStart(2, '0');
        const month = (d.getMonth() + 1).toString().padStart(2, '0');
        const year = d.getFullYear();
        return `${day}-${month}-${year}`;
    };




    const handleSubmitRef = async (values) => {
        console.log(values, "val");
        const currentDate = new Date(); // Get current date
        values.date = formatDate(currentDate);
        values.role = role;
        values.jobId = jobId;
        values.resume = image;
        values.displayName = users.displayName;
        try {
            const response = await axios.post(`${url}/post/Selfreferrals/${dbName}`, values);
            setRef(response.data);
            notification.success({ message: "Successfully Posted" })
            setModalVisible(false)
            setModalVisible1(false)
            form.resetFields();
            console.log('Data posted successfully:', response.data);
        } catch (error) {
            console.error('Error posting data:', error);
        }
    };
    // Function to handle file selection
    const handleFileSelect = event => {
        const file = event.target.files[0];
        setSelectedFile(file);
        // You can perform additional actions here, such as displaying the selected file name
    };

    // Function to handle file upload
    const handleUpload = () => {
        if (selectedFile) {
            // You can perform further actions here, such as uploading the file to a server
            console.log("Uploading file:", selectedFile);
        } else {
            console.log("No file selected");
        }
    };
    const handleInterestedClick = () => {
        setModalVisible(true);
    };

    const handleModalClose = () => {
        setModalVisible(false);
    };


  


    useEffect(() => {
        fetchData();
    }, [])
    const fetchData = async () => {
        try {
            const response = await axios.get(`${url}/get/email/${dbName}`);
            setEmail(response.data);
        } catch (error) {
            console.error("Error fetching email:", error.message);
            message.error("Error fetching email!");
        }
    };

    const Check = (values) => {
        const email = values.email;
        const email1 = Email.shares.map((item) => item.email);
        console.log(email, email1, "mail");

        if (email1.some((e) => e.toLowerCase() === email.toLowerCase())) {
            message.success("Access Approved!");
            setEmailMatch(true);
        } else {
            message.error("Access Denied!");
            setEmailMatch(false);
        }
    }
    return (
        <div>
            {emailMatch ? (
                <img src={require("../../images/unlock.png")} style={{ width: "5vh", height: "5vh" }} />
            ) : (
                <img src={require("../../images/padlock.png")} style={{ width: "5vh", height: "5vh" }} />
            )}

            {!emailMatch &&
                <Form onFinish={Check}>
                    <Row gutter={[16, 16]}>

                        <Col span={7}>
                            <Form.Item style={{ marginTop: "4vh" }} label='Email' name='email'>
                                <Input style={{ width: "50vh" }} />
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Button style={{ marginTop: "4vh" }} type='primary' htmlType='submit'>Submit</Button>
                        </Col>
                    </Row>

                </Form>
            }
            <div className={emailMatch ? "" : "blur-container"}>
                <>

                    <h5 className='text-center'>Finaran | Full time</h5>
                    <h2>{role}</h2>
                    <Row justify="center">
                        <Button style={{ left: "3vh" }} type='primary' onClick={handleInterestedClick}>I'm Interested</Button>
                    </Row>
                    <br />
                    <Card>
                        <Row>
                            <Col span={15}>
                                <div className="job-description">
                                    <h5 style={{ color: "#EA5851" }}>Job Description</h5>
                                    <p style={{ color: "#8C8C86" }}>{description}</p>
                                </div>
                            </Col>
                            <Divider type="vertical" style={{ height: '100%' }} />
                            <Col span={6}>
                                <h5 style={{ color: "#EA5851" }}>Job Information</h5>
                                <h6 style={{ color: "#8C8C86" }}>{skills}</h6>
                                
                            </Col>
                        </Row>
                    </Card>
                </>

                <Modal
                    title="Self Details"
                    visible={modalVisible}
                    onCancel={handleModalClose}
                    footer={null}

                >
                   <Form {...layout} form={form} onFinish={handleSubmitRef}>
                        <Form.Item label='Name' name='name' hasFeedback>
                            <Input placeholder='Enter Name' />
                        </Form.Item>
                        <Form.Item label='Email' name='email' hasFeedback>
                            <Input placeholder='Enter Email' />

                        </Form.Item>
                        <Form.Item label='Mobile' name="mobile" hasFeedback>
                            <Input placeholder='Enter Mobile' />

                        </Form.Item>
                        <Form.Item label='Referral Name' name="displayName" hasFeedback>
                            <Input placeholder='Enter Referral' />

                        </Form.Item>
                        <Form.Item label='Years of exp' name='yearsOfExp' hasFeedback>
                            <Input />

                        </Form.Item>
                        <Form.Item label='Primary skills' name='primary' hasFeedback>
                            <Input />

                        </Form.Item>
                        <Form.Item label='Secondary skills' name='secondary' hasFeedback>
                            <Input />

                        </Form.Item>
                        <Form.Item label='State few reasons how you fit in this job?' name='reason' hasFeedback>
                            <Input.TextArea />
                        </Form.Item>
                        <Form.Item label='Upload Resume' name='resume'>
                            <label className="custom-file-upload">
                                <UploadOutlined />
                                Upload Resume
                                <Input
                                    type="file"
                                    onChange={(e) => handleImageChange(e, "resume")}
                                    style={{ display: "none" }} // Hide the input element
                                />
                            </label>
                            {fileName &&  (
                            <div>
                                <p>{fileName}</p>
                            </div>
                        )}
                        </Form.Item>
                        <Form.Item style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button type="primary" style={{ background: "#EA5851" }} htmlType='submit' icon={<SaveFilled />}>Save</Button>
                        </Form.Item>
                    </Form>
                </Modal>

                {/* <Modal
                    title="Self Details"
                    visible={modalVisible1}
                    onCancel={handleModalClose1}
                    footer={null}
                >
                    <Form {...layout} form={form} onFinish={handleSubmitRef}>
                        <Form.Item label='Name' name='name' hasFeedback>
                            <Input placeholder='Enter Name' />
                        </Form.Item>
                        <Form.Item label='Email' name='email' hasFeedback>
                            <Input placeholder='Enter Email' />

                        </Form.Item>
                        <Form.Item label='Mobile' name="mobile" hasFeedback>
                            <Input placeholder='Enter Mobile' />

                        </Form.Item>
                        <Form.Item label='Years of exp' name='yearsOfExp' hasFeedback>
                            <Input  />

                        </Form.Item>
                        <Form.Item label='Primary skills' name='primary' hasFeedback>
                            <Input />

                        </Form.Item>
                        <Form.Item label='Secondary skills' name='secondary' hasFeedback>
                            <Input />
                        </Form.Item>
                        <Form.Item label='State few reasons how you fit in this job?' name='reason' hasFeedback>
                            <Input.TextArea />
                        </Form.Item>
                        <Form.Item label='Upload Resume' name='resume'>
                            <label className="custom-file-upload">
                                <UploadOutlined />
                                Upload Resume
                                <Input
                                    type="file"
                                    onChange={(e) => handleImageChange(e, "resume")}
                                    style={{ display: "none" }} // Hide the input element
                                />
                            </label>
                            {fileName &&  (
                            <div>
                                <p>{fileName}</p>
                            </div>
                        )}
                        </Form.Item>
                        <Form.Item style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button type="primary" style={{ background: "#EA5851" }} htmlType='submit' icon={<SaveFilled />}>Save</Button>
                        </Form.Item>

                    </Form>
                </Modal> */}
            </div>
        </div>
    )
}

export default Desc