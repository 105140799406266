/*My Work -> Performance Appraisal*/
import React, { useState, useEffect, useRef } from "react";

import axios from "axios";
import {
  Table,
  Menu,
  Dropdown,
  Modal,
  Form,
  Input,
  Button,
  Select,
  Tooltip,
  Spin,
  Card,
  List,
  notification,
} from "antd";
import { url } from "../../url/url";
import moment from "moment";
import {
  EditTwoTone,
  TagsTwoTone,
  EllipsisOutlined,
  EditOutlined,
  AppstoreAddOutlined,
  SettingOutlined,
  BarChartOutlined,
  CommentOutlined,
  StarOutlined,
  CheckCircleOutlined,
  TeamOutlined,
  UnorderedListOutlined, // Add UnorderedListOutlined for list view
  TableOutlined, // Add TableOutlined for table view
} from "@ant-design/icons";
import "./form.css";
// import OrgChartModal from './OrgChartModal'; // Import the OrgChartModal component
// import EmployeeForm from './EmployeeForm';
// import EmployeeAmend from "./EmployeeAmend";
const { Option } = Select;
const ratingOptions = [
  { label: "5- Outstanding", value: 5 },
  { label: "4- Exceeds Expectations", value: 4 },
  { label: "3- Meets Expectations", value: 3 },
  { label: "2- Needs Improvement", value: 2 },
  { label: "1- Unacceptable", value: 1 },
];
const EmployeeList = () => {
  // State variables
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [error, setError] = useState(null);
  const [managerMapping, setManagerMapping] = useState({});
  const [visible, setVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const dropdownRef = useRef(null);
  const [reviewModalVisible, setReviewModalVisible] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [processStatus, setProcessStatus] = useState({});
  const [listView, setListView] = useState(false);
  const [isSetGoalsModalVisible, setIsSetGoalsModalVisible] = React.useState(
    false
  );
  const [year, setYear] = useState("");
  const [selectedPeriod, setSelectedPeriod] = useState("");
  const [
    isPerformanceReviewModalVisible,
    setIsPerformanceReviewModalVisible,
  ] = useState(false);
  const [
    isEmployeeCommentsModalVisible,
    setIsEmployeeCommentsModalVisible,
  ] = useState(false);
  const [isRatingModalVisible, setIsRatingModalVisible] = useState(false);
  const [formSetGoals] = Form.useForm();
  const [formPerformanceReview] = Form.useForm();
  const [formEmployeeComments] = Form.useForm();
  const [formRating] = Form.useForm();
  const [count, setCount] = useState(0);
  const [record, setRecord] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const status = localStorage.getItem("status");

  const fetchUser1 = async () => {
    // Fetch employee reviews from the API
    const dbName = localStorage.getItem("dbName");
    console.log(record.empCode, "EMPCODE");
    const response = await axios.get(
      `${url}/emp/reviewschema/${dbName}/${record.empCode}/${year}/${selectedPeriod}`
    );
    if (response.status === 200) {
      setData2(response.data);
      console.log(response.data, "data2ghhj");
    } else {
      console.error("Error fetching employee reviews:", error);
    }
  };
  const fetchUser = async () => {
    // Fetch employee reviews from the API
    const dbName = localStorage.getItem("dbName");
    console.log(record.empCode, "EMPCODE");
    console.log(year, "selectedYear");
    console.log(selectedPeriod, "selectedPeriod");
    const response = await axios.get(
      `${url}/getallemp/reviewschema/${dbName}/${year}/${selectedPeriod}`
    );
    if (response.status === 200) {
      setData1(response.data);
      setCount(count + 1);
      console.log(response.data, "serttinf");
    } else {
      console.error("Error fetching employee reviews:", error);
    }
  };

  const handleUpdateRow = async (values) => {
    try {
      const dbName = localStorage.getItem("dbName");

      // Include formData in the request body, including empCode
      const empCode = record.empCode;
      const displayName = record.displayName;

      console.log(values, "Handlerow");

      const Goals = values.goals != null ? values.goals : data2?.goals;
      const Performance =
        values.performanceReview != null
          ? values.performanceReview
          : data2?.performanceReview;
      const trainingNeeds =
        values.trainingNeeds != null
          ? values.trainingNeeds
          : data2?.trainingNeeds;
      const Comments =
        values.employeeComments != null
          ? values.employeeComments
          : data2?.employeeComments;
      values.displayName = displayName;
      values.year = year;
      values.periods = selectedPeriod;
      values.goals = Goals;
      values.performanceReview = Performance;
      values.trainingNeeds = trainingNeeds;
      values.employeeComments = Comments;
      console.log(values, "Values");
      console.log(data2, "Data1");
      await axios.put(`${url}/api/update/${dbName}/${empCode}`, values);
      notification.success({
        message: "Updated Successfully",
        description: "Review updated successfully",
        placement: "bottomRight",
      });
      handleSetGoalsModalCancel();
      handlePerformanceReviewModalCancel();
      handleEmployeeCommentsModalCancel();
      handleRatingModalCancel();
    } catch (error) {
      console.error("Error updating review:", error);

      notification.error({
        message: "Update failed",
        description: "Check the details!",
        placement: "bottomRight",
      });
      handleSetGoalsModalCancel();
      handlePerformanceReviewModalCancel();
      handleEmployeeCommentsModalCancel();
      handleRatingModalCancel();
    }
  };

  const handleSetGoalsClick = (record) => {
    setIsSetGoalsModalVisible(true);
    setRecord(record);
    formSetGoals.setFieldsValue({
      empCode: record.empCode,
      goals: data2?.goals,
      performanceReview: data2?.performanceReview,
      trainingNeeds: data2?.trainingNeeds,
      employeeComments: data2?.employeeComments,
      status: data2?.status,
    });
  };

  const handlePerformanceReviewClick = (record) => {
    setIsPerformanceReviewModalVisible(true);
    setRecord(record);
    formPerformanceReview.setFieldsValue({
      empCode: record.empCode,
      goals: data2?.goals,
      performanceReview: data2?.performanceReview,
      trainingNeeds: data2?.trainingNeeds,
      employeeComments: data2?.employeeComments,
      status: data2?.status,
    });
  };

  const handleEmployeeCommentsClick = (record) => {
    setIsEmployeeCommentsModalVisible(true);
    setRecord(record);
    formEmployeeComments.setFieldsValue({
      empCode: record.empCode,
      goals: data2?.goals,
      performanceReview: data2?.performanceReview,
      trainingNeeds: data2?.trainingNeeds,
      employeeComments: data2?.employeeComments,
      status: data2?.status,
    });
  };

  const handleRatingClick = (record) => {
    setIsRatingModalVisible(true);
    setRecord(record);
    formRating.setFieldsValue({
      empCode: record.empCode,
      goals: data2?.goals,
      performanceReview: data2?.performanceReview,
      trainingNeeds: data2?.trainingNeeds,
      employeeComments: data2?.employeeComments,
      status: data2?.status,
    });
  };

  const handleSetGoalsModalCancel = () => {
    console.log("Cancel  set goals button clicked");
    setIsSetGoalsModalVisible(false);
    formSetGoals.resetFields();
  };

  const handlePerformanceReviewModalCancel = () => {
    console.log("Cancel perf review button clicked");
    setIsPerformanceReviewModalVisible(false);
    formPerformanceReview.resetFields();
  };

  const handleEmployeeCommentsModalCancel = () => {
    console.log("Cancel button clicked", formEmployeeComments);

    formEmployeeComments.resetFields();

    setIsEmployeeCommentsModalVisible(false);
    console.log("Form fields reset");
  };

  const handleRatingModalCancel = () => {
    console.log("Cancel rating model button clicked");
    setIsRatingModalVisible(false);
    formRating.resetFields();
  };

  // Fetch employee data
  useEffect(() => {
    if(selectedPeriod){
    fetchUser();
    fetchUser1();
    }
    fetchEmployeeData();
  }, [record, selectedPeriod]);

  const fetchEmployeeData = async () => {
    setLoading(true);
    if (year && selectedPeriod) {
      try {
        let data;
        const dbName = localStorage.getItem("dbName");
        const storedUserData = localStorage.getItem("user");
        const parsedUserData = storedUserData
          ? JSON.parse(storedUserData)
          : null;
        console.log(parsedUserData, "displayName");
        if (parsedUserData.jobTitle === "manager") {
          const response = await axios.get(
            `${url}/api/get/emplist/${parsedUserData.displayName}/manager/${dbName}`
          );
          data = await response.data;
        } else if (parsedUserData.jobTitle === "admin") {
          const response = await fetch(`${url}/api/get/allemplist/${dbName}`);
          data = await response.json();
        } else if (parsedUserData.jobTitle === "hr") {
          const response = await axios.get(
            `${url}/api/get/emplist/${parsedUserData.displayName}/hr/${dbName}`
          );
          data = await response.data;
        }
        // console.log(response, "Employe");
        // Create a mapping of employee IDs to their full names
        const mapping = {};
        for (const employee of data) {
          mapping[
            employee.empCode
          ] = `${employee.firstName} ${employee.lastName}`;
        }
        setManagerMapping(mapping);

        // Your existing logic for calculating experience
        const updatedData = data.map((employee) => {
          const currentDate = moment();
          const dateOfJoining = moment(employee.joinDate);
          const dbYears = employee.yearsofexp || 0;
          const totalMonths =
            currentDate.diff(dateOfJoining, "months") + dbYears * 12;
          const years = Math.floor(totalMonths / 12);
          const months = totalMonths % 12;
          const formattedExperience = `${years} years ${months} months`;
          console.log(employee.joinDate, employee.yearsofexp, "InUpdatedata");
          return {
            ...employee,
            formattedExperience,
          };
        });
        console.log(updatedData, "INEMployee");
        setData(updatedData);
        setLoading(false);
      } catch (error) {
        console.log("Error fetching employee data:", error);
        setError(error);
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };
  const ellipsisMenu = (record) => (
    <Menu
      onClick={(key) => {
        console.log(data2, "data2");
        switch (key.key) {
          case "2":
            handleSetGoalsClick(record);
            break;
          case "3":
            handlePerformanceReviewClick(record);
            break;
          case "4":
            handleEmployeeCommentsClick(record);
            break;
          case "5":
            handleRatingClick(record);
            break;
          default:
            break;
        }
        {
          console.log(record, "ellipsis");
        }
      }}
    >
      <Menu.Item
        key="2"
        icon={<SettingOutlined />}
        disabled={data2?.status != null || data2?.status != undefined}
      >
        <Tooltip title="Set goals for this employee">Set Goals</Tooltip>
      </Menu.Item>
      <Menu.Item
        key="3"
        icon={<BarChartOutlined />}
        disabled={data2?.status !== "Goals Set"}
      >
        <Tooltip title="Review this employee Performance">
          Performance Review
        </Tooltip>
      </Menu.Item>
      <Menu.Item
        key="4"
        icon={<CommentOutlined />}
        disabled={data2?.status !== "Performance Reviewed"}
      >
        <Tooltip title="Capture Employee Comments">Employee Comments</Tooltip>
      </Menu.Item>
      <Menu.Item
        key="5"
        icon={<StarOutlined />}
        disabled={
          data2?.status !== "Rating Done" &&
          data2?.status !== "Comments captured"
        }
      >
        <Tooltip title="Update Performance Rating">Rating</Tooltip>
      </Menu.Item>
    </Menu>
  );
  const uniqueManager = Array.from(new Set(data.map((data) => data.manager)));
  const uniqueName = Array.from(new Set(data.map((data) => data.displayName)));
  const uniqueStatus = Array.from(new Set(data1.map((data) => data.status)));

  const columns = [
    {
      title: "Employee ID",
      dataIndex: "empCode",
      key: "empCode",
      sorter: (a, b) => a.empCode - b.empCode,
    },
    {
      title: "Name",
      key: "fullName",
      render: (text, record) => `${record.firstName} ${record.lastName}`,
      sorter: (a, b) => {
        const fullNameA = `${a.firstName} ${a.lastName}`;
        const fullNameB = `${b.firstName} ${b.lastName}`;
        return fullNameA.localeCompare(fullNameB);
      },
      // filters: uniqueName.map((displayName) => ({
      //   text: displayName,
      //   value: displayName,
      // })),
      // onFilter: (value, record) => record.displayName === value,
    },
    {
      title: "Yrs of Exp",
      dataIndex: "formattedExperience",
      key: "formattedExperience",
      width: 200,
      sorter: (a, b) => {
        const aArray = a.formattedExperience.split(" ");
        const bArray = b.formattedExperience.split(" ");
        return (
          parseInt(aArray[0]) * 12 +
          parseInt(aArray[2]) -
          (parseInt(bArray[0]) * 12 + parseInt(bArray[2]))
        );
      },
    },
    {
      title: "Position",
      dataIndex: "empDesignation",
      key: "empDesignation",
    },
    {
      title: "Manager",
      key: "manager",
      // render: (text, record) => managerMapping[record.manager] || 'N/A',
      render: (text, record) => `${record.manager}`,
      sorter: (a, b) => a.manager.localeCompare(b.manager),
      // filters: uniqueManager.map((manager) => ({
      //   text: manager,
      //   value: manager,
      // })),
      // onFilter: (value, record) => record.manager === value,
    },

    // Add more columns here
    {
      title: "Actions", // Replace 'Menu' with 'Actions'
      key: "actions",
      render: (text, record) => (
        <Dropdown
          overlay={ellipsisMenu(record)}
          placement="bottomRight"
          trigger={["click"]}
        >
          <EllipsisOutlined style={{ fontSize: "18px", cursor: "pointer" }} />
        </Dropdown>
      ),
    },
    {
      title: "Status",
      key: "status",
      width: 200,
      filters: [
        { text: "Open", value: "Open" },
        ...Array.from(new Set(data1.map((item) => item.status))).map(
          (status) => ({
            text: status,
            value: status,
          })
        ),
      ],
      onFilter: (value, record) => {
        const statusData = data1.find(
          (item) => item.empCode === record.empCode
        );
        const status = statusData ? statusData.status : "Open";
        return status === value;
      },
      render: (text, record) => {
        const statusData = data1.find(
          (item) => item.empCode === record.empCode
        );
        const status = statusData ? statusData.status : null;
        return status != null && status !== undefined ? status : "Open";
      },
    },
  ];

  const filteredData = data.filter(
    (item) =>
      (item.empCode.toLowerCase().includes(searchTerm.toLowerCase()) ||
        `${item.firstName} ${item.lastName}`
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        item.position.toLowerCase().includes(searchTerm.toLowerCase())) &&
      item.empStatus !== "inactive"
  );

  // // Transform the data into a hierarchical structure
  // const createOrgChartData = (employees) => {
  //   const orgChartData = [];

  //   employees.forEach((employee) => {
  //     const { empCode, firstName, lastName, reportingManager } = employee;

  //     const employeeNode = {
  //       id: empCode,
  //       name: `${firstName} ${lastName}`,
  //       managerId: reportingManager,
  //       directReports: [],
  //     };

  //     if (reportingManager === null) {
  //       // This employee has no manager, so it's at the top level
  //       orgChartData.push(employeeNode);
  //     } else {
  //       // Find the manager and add this employee as a direct report
  //       const managerNode = orgChartData.find(
  //         (node) => node.id === reportingManager
  //       );
  //       if (managerNode) {
  //         managerNode.directReports.push(employeeNode);
  //       }
  //     }
  //   });

  //   return orgChartData;
  // };

  // Rendered JSX
  const handleTableRowClick = (record) => {
    // When a table row is clicked, set the form values including empCode
    setRecord(record);
  };

  const handleYearChange = (value) => {
    setYear(value);
    setSelectedPeriod("");
  };

  const handlePeriodChange = (value) => {
    setSelectedPeriod(value);
  };
  // Step 3: Provide a range of years for selection
  const currentYear = new Date().getFullYear();
  const yearsRange = Array.from(
    { length: 3 },
    (_, index) => currentYear - 1 + index
  );

  return (
    <div
      style={{
        transform: "scale(0.9)",
        transformOrigin: "0 0",
        width: "110%",
      }}
    >
      <div
        style={{
          marginBottom: "16px",
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <Select
          placeholder="Select Year"
          onChange={handleYearChange}
          style={{ width: 130, marginRight: "8px" }} // Add marginRight for spacing
          value={selectedYear || undefined}
        >
          {yearsRange.map((year) => (
            <Select.Option key={year} value={year}>
              {year}
            </Select.Option>
          ))}
        </Select>

        <Select
          placeholder="Select Period"
          onChange={handlePeriodChange}
          value={selectedPeriod || undefined}
          style={{ width: 130 }} // Set the width as needed
        >
          <Option value="Q1">Q1</Option>
          <Option value="Q2">Q2</Option>
          <Option value="Q3">Q3</Option>
          <Option value="Q4">Q4</Option>
          <Option value="H1">H1</Option>
          <Option value="H2">H2</Option>
        </Select>
      </div>

      <Spin spinning={loading}>
        <Table
          rowKey="empCode"
          onRow={(record) => ({
            onClick: () => handleTableRowClick(record),
          })}
          columns={columns}
          dataSource={filteredData}
          pagination={{ pageSize: 10 }}
          className="custom-table col-lg-12 col-md-12 col-sm-12"
          sticky
        />
      </Spin>

      <Modal
        title="Set Goals"
        visible={isSetGoalsModalVisible}
        onCancel={handleSetGoalsModalCancel}
        footer={null}
        maskClosable={false}
        style={{ backdropFilter: "blur(8px)" }}
        width={630}
      >
        <Form
          form={formSetGoals}
          onFinish={(values) => handleUpdateRow(values)}
        >
          <Form.Item
            label="Emp Code"
            labelCol={{ span: 8 }}
            name="empCode"
            wrapperCol={{ span: 12 }}
            initialValue={record.empCode}
          >
            <Input style={{ fontSize: "16px" }} readOnly />
          </Form.Item>
          <div>
            <Form.Item
              name="year"
              label="Year"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 12 }}
              initialValue={year}
            >
              <Input style={{ fontSize: "16px" }} readOnly />
            </Form.Item>
          </div>
          <div>
            <Form.Item
              name="periods"
              label="Time Period"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 12 }}
              initialValue={selectedPeriod}
            >
              <Input style={{ fontSize: "16px" }} readOnly />
            </Form.Item>
          </div>
          <Form.Item
            label="Goals"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 12 }}
            name="goals"
            rules={[
              {
                required: true,
                message: "Please input goals",
              },
            ]}
          >
            <Input.TextArea
              style={{ fontSize: "16px" }}
              placeholder="Please enter goals separated by a comma"
            />
          </Form.Item>
          <Form.Item
            wrapperCol={{ offset: 6, span: 15 }}
            style={{ textAlign: "center" }}
          >
            <Button
              type="primary"
              htmlType="submit"
              disabled={status === "disable"}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Performance Review"
        visible={isPerformanceReviewModalVisible}
        onCancel={handlePerformanceReviewModalCancel}
        footer={null}
        width={800} // Set the width as needed
        // Add other modal props as needed
      >
        <Form form={formPerformanceReview} onFinish={handleUpdateRow}>
          <div>
            <Form.Item
              label="Emp Code"
              name="empCode"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 12 }}
              initialValue={record.empCode}
            >
              <Input
                readOnly
                style={{
                  backgroundColor: "#fff",
                  color: "black",
                  width: "100%",
                  fontSize: "16px",
                }}
              />
            </Form.Item>
          </div>
          <div>
            <Form.Item
              name="year"
              label="Year"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 12 }}
              rules={[
                {
                  required: false,
                  message: "Please select a year",
                },
              ]}
              initialValue={data2?.year}
            >
              <Input
                readOnly
                style={{
                  backgroundColor: "#fff",
                  color: "black",
                  width: "100%",
                  fontSize: "16px",
                }}
              />
              {/* {formData.years.map((year) => ( */}
            </Form.Item>
          </div>
          <div>
            <Form.Item
              name="periods"
              label="Time Period"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 12 }}
              rules={[
                {
                  required: false,
                  message: "Please select a period",
                },
              ]}
              initialValue={data2?.periods}
            >
              <Input
                readOnly
                style={{
                  backgroundColor: "#fff",
                  color: "black",
                  width: "100%",
                  fontSize: "16px",
                }}
              />
            </Form.Item>
          </div>
          <div>
            <Form.Item
              label="Goals"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 12 }}
              name="goals"
              initialValue={data2?.goals}
            >
              <Input.TextArea
                readOnly
                style={{
                  backgroundColor: "#fff",
                  color: "black",
                  width: "100%",
                  fontSize: "16px",
                }}
              />
            </Form.Item>
          </div>
          <div></div>
          <div>
            <Form.Item
              name="performanceReview"
              label="Employee Performance Review"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 12 }}
              rules={[
                {
                  required: true,
                  message: "Please input employee Performance Review",
                },
              ]}
            >
              <Input.TextArea
                style={{
                  fontSize: "16px",
                }}
                placeholder="Please enter employee Performance Review"
              />
            </Form.Item>
          </div>
          <div>
            <Form.Item
              name="trainingNeeds"
              label="Training Needs"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 12 }}
              rules={[
                {
                  required: true,
                  message: "Please input Training Needs",
                },
              ]}
            >
              <Input.TextArea
                style={{
                  fontSize: "16px",
                }}
                placeholder="Please enter Enter training needs"
              />
            </Form.Item>
          </div>
          <Form.Item
            wrapperCol={{ offset: 6, span: 15 }}
            style={{ textAlign: "center" }}
          >
            <Button
              type="primary"
              htmlType="submit"
              disabled={status === "disable"}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Employee Comments"
        visible={isEmployeeCommentsModalVisible}
        onCancel={handleEmployeeCommentsModalCancel}
        footer={null}
        width={800} // Set the width as needed

        // Add other modal props as needed
      >
        <Form form={formEmployeeComments} onFinish={handleUpdateRow}>
          <div>
            <Form.Item
              label="Emp Code"
              name="empCode"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 12 }}
              initialValue={record.empCode}
            >
              <Input
                readOnly
                style={{
                  backgroundColor: "#fff",
                  color: "black",
                  width: "100%",
                  fontSize: "16px",
                }}
              />
            </Form.Item>
            <div>
              <Form.Item
                name="year"
                label="Year"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 12 }}
                rules={[
                  {
                    required: false,
                    message: "Please select a year",
                  },
                ]}
                initialValue={data2?.year}
              >
                <Input
                  readOnly
                  style={{
                    backgroundColor: "#fff",
                    color: "black",
                    width: "100%",
                    fontSize: "16px",
                  }}
                />
                {/* {formData.years.map((year) => ( */}
              </Form.Item>
            </div>
            <div>
              <Form.Item
                name="periods"
                label="Time Period"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 12 }}
                rules={[
                  {
                    required: false,
                    message: "Please select a period",
                  },
                ]}
                initialValue={data2?.periods}
              >
                <Input
                  readOnly
                  style={{
                    backgroundColor: "#fff",
                    color: "black",
                    width: "100%",
                    fontSize: "16px",
                  }}
                />
              </Form.Item>
              <div>
                <Form.Item
                  label="Goals"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 12 }}
                  name="goals"
                  initialValue={data2?.goals}
                >
                  <Input.TextArea
                    style={{ width: "100%", fontSize: "16px" }}
                    readOnly
                  />
                </Form.Item>
              </div>
            </div>
            <Form.Item
              name="performanceReview"
              label="Performance Review"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 12 }}
              initialValue={data2?.performanceReview}
            >
              <Input.TextArea
                readOnly
                style={{
                  backgroundColor: "#fff",
                  color: "black",
                  width: "100%",
                  fontSize: "16px",
                }}
              />
            </Form.Item>
          </div>
          <div>
            <Form.Item
              name="trainingNeeds"
              label="Training Needs"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 12 }}
              initialValue={data2?.trainingNeeds}
            >
              <Input.TextArea
                readOnly
                style={{
                  backgroundColor: "#fff",
                  color: "black",
                  width: "100%",
                  fontSize: "16px",
                }}
              />
            </Form.Item>
          </div>
          <Form.Item
            name="employeeComments"
            label="Employee Comments"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 12 }}
            rules={[
              {
                required: true,
                message: "Please select a year",
              },
            ]}
          >
            <Input.TextArea
              style={{
                fontSize: "16px",
              }}
              placeholder="Enter Employee Comments"
            />
          </Form.Item>
          <Form.Item
            wrapperCol={{ offset: 6, span: 15 }}
            style={{ textAlign: "center" }}
          >
            <Button
              type="primary"
              htmlType="submit"
              disabled={status === "disable"}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Update Performance Rating"
        visible={isRatingModalVisible}
        onCancel={handleRatingModalCancel}
        footer={null}
        width={800} // Set the width as needed

        // Add other modal props as needed
      >
        <Form form={formRating} onFinish={handleUpdateRow}>
          <Form.Item
            label="Emp Code"
            name="empCode"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 12 }}
            initialValue={record.empCode}
          >
            <Input
              readOnly
              style={{
                backgroundColor: "#fff",
                color: "black",
                width: "100%",
                fontSize: "16px",
              }}
            />
          </Form.Item>

          <div>
            <Form.Item
              name="year"
              label="Year"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 12 }}
              rules={[
                {
                  required: false,
                  message: "Please select a year",
                },
              ]}
              initialValue={data2?.year}
            >
              <Input
                readOnly
                style={{
                  backgroundColor: "#fff",
                  color: "black",
                  width: "100%",
                  fontSize: "16px",
                }}
              />
            </Form.Item>
          </div>
          <div>
            <Form.Item
              name="periods"
              label="Time Period"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 12 }}
              rules={[
                {
                  required: false,
                  message: "Please select a period",
                },
              ]}
              initialValue={data2?.periods}
            >
              <Input
                readOnly
                style={{
                  backgroundColor: "#fff",
                  color: "black",
                  width: "100%",
                  fontSize: "16px",
                }}
              />
            </Form.Item>
          </div>
          <div>
            <Form.Item
              label="Goals"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 12 }}
              name="goals"
              initialValue={data2?.goals}
            >
              <Input.TextArea
                readOnly
                style={{
                  backgroundColor: "#fff",
                  color: "black",
                  width: "100%",
                  fontSize: "16px",
                }}
              />
            </Form.Item>
          </div>
          <div>
            <Form.Item
              name="performanceReview"
              label="Performance Review"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 12 }}
              initialValue={data2?.performanceReview}
            >
              <Input.TextArea
                readOnly
                style={{
                  backgroundColor: "#fff",
                  color: "black",
                  width: "100%",
                  fontSize: "16px",
                }}
              />
            </Form.Item>
          </div>
          <div>
            <Form.Item
              name="trainingNeeds"
              label="Training Needs"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 12 }}
              initialValue={data2?.trainingNeeds}
            >
              <Input.TextArea
                readOnly
                style={{
                  backgroundColor: "#fff",
                  color: "black",
                  width: "100%",
                  fontSize: "16px",
                }}
              />
            </Form.Item>
          </div>
          <div>
            <Form.Item
              name="employeeComments"
              label="Employee Comments"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 12 }}
              initialValue={data2?.employeeComments}
            >
              <Input.TextArea
                readOnly
                style={{
                  backgroundColor: "#fff",
                  color: "black",
                  width: "100%",
                  fontSize: "16px",
                }}
              />
            </Form.Item>
          </div>
          <div>
            <Form.Item
              name="rating"
              label="Rating"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 12 }}
              rules={[
                {
                  required: true,
                  message: "Please select a Rating",
                },
              ]}
            >
              <Select
                placeholder={
                  data2?.status === "Rating Done"
                    ? `${data2?.rating}`
                    : "Select a rating"
                }
                disabled={data2?.status === "Rating Done"}
                style={{
                  opacity: data2?.status === "Rating Done" ? "1" : "0.65",
                  fontWeight:
                    data2?.status === "Rating Done" ? "bold" : "normal",
                }}
              >
                {ratingOptions.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <Form.Item
            wrapperCol={{ offset: 6, span: 15 }}
            style={{ textAlign: "center" }}
          >
            {data2?.status !== "Rating Done" && (
              <Button
                type="primary"
                htmlType="submit"
                disabled={status === "disable"}
              >
                Submit
              </Button>
            )}
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default EmployeeList;
