import React from 'react';
import { Card, Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import { UserOutlined, TeamOutlined,BookOutlined, CheckSquareOutlined, UploadOutlined, FileDoneOutlined, FileTextOutlined, CalendarOutlined, GiftOutlined, AlertOutlined, BarChartOutlined, ContactsOutlined } from '@ant-design/icons';
import '../HR_home/hrhome.css';


// import ProfitabilityNav from './projNav';
const ProfitabilityHome = () => {
    const tileData = [
        { title: 'Billable Rates', link: '/home/billrates', icon: <UserOutlined /> },
        { title: 'Standard Costs', link: '/home/cost', icon: <TeamOutlined /> },
        // { title: 'Manage Revenue', link: '/home/userrole', icon: <TeamOutlined /> },
        // { title: 'Projected Revenue', link: '/home/releasenotes', icon: <BookOutlined /> },
        // { title: 'Settings', link: '/home/userrole', icon: <CheckSquareOutlined /> },
    ];
    const colorData = localStorage.getItem("color");
    console.log(colorData,"colordata");
    
    return (
<>        <div style={{zoom:"90%"}}>
            {/* <ProfitabilityNav/> */}
        </div>
        <div className="site-card-wrapper">
            <Row gutter={[16, 16]}>
                {tileData.map((tile, index) => (
                    <Col xs={24} sm={12} md={8} lg={6} key={index}>
                        <Card
                            className="tile-card"
                            title={tile.icon}
                            headStyle={{
                                background: colorData,
                                color: 'white',
                                textAlign: 'center',
                                display: 'flex',
                            }}
                            style={{
                                background: 'white',
             
                            }}
                        >
                            <Link className="tile-link" to={tile.link} style={{ color: colorData }}>
                                {tile.title}
                            </Link>
                        </Card>
                    </Col>
                ))}
            </Row>
        </div>
        </>
    );
};



export default ProfitabilityHome;
