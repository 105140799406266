/*Approval->compoff Approval*/
import axios from "axios";
import React, { useState, useEffect } from "react";
import {
  Tabs,
  Table,
  Button,
  Image,
  Input,
  Popconfirm,
  Modal,
  message,
  notification,
  Spin,
} from "antd";
import { url } from "../../../url/url";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { decryptRole } from "../../../encrypt";

const { TabPane } = Tabs;
const { Search } = Input;

function Compoff(props) {
  const [EmployeeData, setEmployeeData] = useState([]);
  const [LeaveData, setLeaveData] = useState([]);
  const [days, setDays] = useState({});
  const user = JSON.parse(localStorage.getItem("user"));
  const [searchQuery, setSearchQuery] = useState("");
  const [visible1, setVisible1] = useState(false);
  const [showAllReports, setShowAllReports] = useState(false);
  const [pdfModalContent, setPdfModalContent] = useState(null);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10); // Set an initial page size
  const [total, setTotal] = useState(EmployeeData.length); // Set the total based on your data
  const [loading, setLoading] = useState(false);
  const encryptedRoleFromStorage = localStorage.getItem("role");
  const jobTitle = decryptRole(encryptedRoleFromStorage);

  const uniqueManager = Array.from(
    new Set(EmployeeData.map((data) => data.manager))
  );
  // Handle page change
  const onPageChange = (newCurrent, newPageSize) => {
    setCurrent(newCurrent);
    setPageSize(newPageSize);
    // You may need to update the "total" based on your data or an API call here
  };

  var newAnnualLeaveBalance;
  const shouldUpdate = (prevProps, nextProps) => {
    if (
      prevProps.dataSource === nextProps.dataSource &&
      prevProps.count === nextProps.count
    ) {
      return false;
    }
    return true;
  };
  useEffect(() => {
    fetchData();
  }, [showAllReports]);
  const toggleShowAllReports = () => {
    setShowAllReports(!showAllReports);
  };
  const fetchData = async () => {
    setLoading(true);
    try {
      const dbName = localStorage.getItem("dbName");
      console.log("dbName in fetchData in managerapproval", dbName);
      if (!showAllReports) {
        console.log("insideif");
        const res = await axios.get(
          `${url}/compoff/approval/${user.displayName}/manager/${dbName}`
        );
        setEmployeeData(res.data);
        setLoading(false);
        console.log(res.status);
        console.log(res.data);
      } else if (showAllReports && jobTitle === "hr") {
        const res = await axios.get(`${url}/compoff/approval/${user.displayName}/HR/${dbName}`);
        console.log("insideelse");
        setEmployeeData(res.data);
        setLoading(false);
        console.log(res.status);
        console.log(res.data);
      } else if (showAllReports && jobTitle === "admin") {
        const res = await axios.get(`${url}/compoff/approval/${user.displayName}/admin/${dbName}`);
        console.log("insideelse");
        setEmployeeData(res.data);
        setLoading(false);
        console.log(res.status);
        console.log(res.data);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const empapr = async (ids, email, ename) => {
    const dbName = localStorage.getItem("dbName");
    console.log("dbName in empapr in managerapproval", dbName);
    const res = await axios.put(`${url}/compoff/approve/${ids}/${dbName}`, {
      status: "Approved",
      email: email,
    });
    notification.success({ message: "Successfully updated" });

    const response = await axios.get(
      `${url}/get/managermail/${ename}/${dbName}`
    );
    const manager = response.data.email;
    console.log(manager, "manager");
    // If both API calls are successful, trigger the mail
    const mailTriggerResponse = await axios.post(`${url}/status/mail`, {
      userName: user.displayName,
      email: manager,
      requestType: "leave approved",
    });
  };
  const empdeny = async (ids, email, ename) => {
    const dbName = localStorage.getItem("dbName");
    console.log("dbName in emdeny in managerapproval", dbName);
    const res = await axios.put(`${url}/compoff/approve/${ids}/${dbName}`, {
      status: "Denied",
      email: email,
    });
    notification.success({ message: "Successfully updated" });

    const response = await axios.get(
      `${url}/get/managermail/${ename}/${dbName}`
    );
    const manager = response.data.email;

    // If both API calls are successful, trigger the mail
    const mailTriggerResponse = await axios.post(`${url}/status/mail`, {
      userName: user.displayName,
      email: manager,
      requestType: "leave rejected",
    });
  };
  const getBL = async (ids, empcode) => {
    const dbName = localStorage.getItem("dbName");
    console.log("dbName in getBL in managerapproval", dbName);
    const res = await axios.get(
      `${url}/get/leavebalance/${ids}/${empcode}/${dbName}`
    );
    setLeaveData(res.data);
    console.log(res.status);
    console.log(res.data);
  };
  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredData = EmployeeData.filter((item) =>
    item.ename.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleApprove = async (record) => {
    console.log("Approved:", record);
    console.log(days);
    console.log(record.ename, "email");
    empapr(record._id, record.email, record.ename);
    setEmployeeData((prevData) =>
      prevData.filter((data) => data._id !== record._id)
    );
    getBL();
    newAnnualLeaveBalance = LeaveData - days;
    console.log(newAnnualLeaveBalance);
  };

  const handleDeny = (record) => {
    // Handle deny button click
    console.log("Denied:", record);
    empdeny(record._id, record.email, record.ename);
    setEmployeeData((prevData) =>
      prevData.filter((data) => data._id !== record._id)
    );
  };

  function openPdfInModal(pdfDataUrl) {
    // Set the PDF URL to state
    fetch(pdfDataUrl)
      .then((res) => res.blob())
      .then((blob) => {
        // Create a blob URL
        const blobUrl = URL.createObjectURL(blob);

        // Set the blob URL to a state variable for the modal content
        setPdfModalContent(blobUrl);

        // Show the modal
        setVisible1(true);
      });
  }

  function downloadPdf(pdfDataUrl) {
    const link = document.createElement("a");
    link.href = pdfDataUrl;
    link.download = "Leave Report.pdf"; // Specify the desired file name
    link.click();
  }

  const columns = [
    {
      title: "S.NO",
      dataIndex: "index",
      key: "index",
      width: 100,
      render: (text, record, index) => {
        const currentSerialNumber = index + 1 + pageSize * (current - 1);
        return <span>{currentSerialNumber}</span>;
      },
    },
    {
      title: "Employee Name",
      dataIndex: "ename",
      key: "ename",
      width: 200,

    },
    {
      title: "Manager",
      dataIndex: "manager",
      width: 200,
      filters: uniqueManager.map((manager) => ({
        text: manager,
        value: manager,
      })),
      onFilter: (value, record) => record.manager === value,
    },
    {
      title: "Applying Date",
      dataIndex: "doa",
      key: "doa",
      width: 200,

    },
    {
      title: "Date of Work",
      dataIndex: "workdate",
      key: "workdate",
      width: 200,
    },
    {
      title: "Type of Work",
      dataIndex: "worktype",
      key: "worktype",
      width: 200,
    },
    {
      title: "Nature of Work",
      dataIndex: "workNature",
      key: "workNature",
      width: 200,
    },
    {
      title: "Document",
      width: 200,
      render: (_, record) => (
        <div>
          {record.image ? (
            <div>
              {record.image.startsWith("data:application/pdf") ? (
                <div>
                  <a href="#" onClick={() => openPdfInModal(record.image)}>
                    View PDF
                  </a>
                  {" | "}
                  <a href="#" onClick={() => downloadPdf(record.image)}>
                    Download PDF
                  </a>
                </div>
              ) : (
                <Image width={50} src={record.image} fallback={record.image} />
              )}
            </div>
          ) : (
            <>No file</>
          )}
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <span style={{ whiteSpace: "nowrap" }}>
          <Popconfirm
            title="Are you sure want to approve ?"
            onConfirm={() => handleApprove(record)}
          >
            <Button style={{ color: "green" }} icon={<CheckCircleFilled />} />
          </Popconfirm>
          <Popconfirm
            title="Are you sure want to deny ?"
            onConfirm={() => handleDeny(record)}
          >
            <Button style={{ color: "red" }} icon={<CloseCircleFilled />} />
          </Popconfirm>
        </span>
      ),
    },
  ];

  const handleDownload = (image) => {
    // Convert the base64 image data to a Blob
    const byteString = atob(image.split(",")[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: "image/jpeg" });

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "image.jpg";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div style={{ transform: "scale(0.9)", transformOrigin: "0 0", width: "110%" }}>
      {loading ? (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <Modal
            title="Document"
            visible={visible1}
            onOk={() => setVisible1(false)}
            onCancel={() => setVisible1(false)}
            width={800} // Adjust the width as needed
            footer={null}
          >
            {pdfModalContent && (
              <iframe
                src={pdfModalContent}
                title="Document"
                style={{ width: "100%", height: "500px" }}
              />
            )}
          </Modal>
          <Tabs defaultActiveKey="1" centered>
            <TabPane
              tab={
                <span style={{ color: "black", fontSize: "20px" }}>
                  Compoff Approval
                </span>
              }
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  {(jobTitle === "hr" || jobTitle === "admin") && (
                    <label
                      style={{
                        display: "flex",
                        // justifyContent: "",
                        fontSize: "14px",
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={showAllReports}
                        onChange={toggleShowAllReports}
                      />
                      Show All Employee
                    </label>
                  )}
                </div>
                <div>
                  <Input
                    type="text"
                    size="large"
                    style={{ height: "40px", fontSize: "15px" }}
                    placeholder="Search Employees..."
                    value={searchQuery}
                    onChange={handleSearchQueryChange}
                  />
                </div>
              </div>
              <div style={{ width: "100%", overflowX: "auto" }}>
                <Table
                  className="custom-table col-lg-12 col-md-12 col-sm-12"
                  columns={columns}
                  dataSource={filteredData}
                  pagination={{
                    current: current,
                    pageSize: pageSize,
                    total: total,
                    onChange: onPageChange,
                  }}
                  shouldComponentUpdate={shouldUpdate}
                  sticky
                  scroll={{ x: true }}
                />
              </div>
            </TabPane>
          </Tabs>
        </div>
      )}
    </div>
  );
}

export default Compoff;
