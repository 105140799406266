import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 24,
        }}
        spin
    />
);
const Spinner = () => <Spin indicator={antIcon} style={{display:"flex",alignItems:"center",justifyContent:'center' ,height:"100vh"}}/>;
export default Spinner;