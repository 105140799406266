import { ArrowLeftOutlined, ArrowRightOutlined, PlusCircleOutlined, DeleteFilled, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Radio, Row, Col, Divider, Input, message, Select } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { url } from '../../url/url';

const Assessmentform = () => {
    const [question, setQuestion] = useState('');
    const [options, setOptions] = useState([]);
    const [assessment, setAssessment] = useState([]);
    const [optionInput, setOptionInput] = useState('');
    const [answer, setAnswer] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showAssessment, setShowAssessment] = useState(false);
    const [showSubmittedQuestions, setShowSubmittedQuestions] = useState(false);
    const [showAddAssessment, setShowAddAssessment] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [courses, setCourses] = useState([]);
    const [form] = Form.useForm();
    const [count, setCount] = useState(0);
    const [selectCourse, setSelectCourse] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const submittedQuestions = selectCourse?.flatMap(course => course.assessment);
    const [minimumMarks, setMinimumMarks] = useState(null);


    useEffect(() => {
        fetchCourses();
    }, [])
    const fetchCourses = async () => {
        const dbName = localStorage.getItem("dbName");
        try {
            const response = await axios.get(`${url}/get/courses/${dbName}`);
            setCourses(response.data);
        } catch (err) {
            console.log(err.message);
        }
    };


    const handleCourseChange = (value) => {
        console.log('Selected course:', value);
        setSelectedCourse(value);
        if (value) { // Check if value is not null
            fetchSelectCourses(value);
        }
    };

    const fetchSelectCourses = async (course) => {
        const dbName = localStorage.getItem("dbName");
        console.log(selectedCourse, "course");
        try {
            const response = await axios.get(`${url}/get/selectedcourses/${course}/${dbName}`);
            setSelectCourse(response.data);
            console.log(response.data, "setselect");
        } catch (err) {
            console.log(err.message);
        }
    };

    const questionsPerPage = 3;
    const totalPages = Math.ceil(submittedQuestions.length / questionsPerPage);

    const handlePrevious = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - questionsPerPage);
        }
    };

    const handleNext = () => {
        if (currentIndex + questionsPerPage < submittedQuestions.length) {
            setCurrentIndex(currentIndex + questionsPerPage);
        }
    };

    const handleAssessmentClick = () => {
        setShowAssessment(true);
    };


    const handleQuestionChange = (e) => {
        setQuestion(e.target.value);
    };

    const handleOptionInputChange = (e) => {
        setOptionInput(e.target.value);
    };

    const handleAddOption = () => {
        const newOptions = [...options, optionInput];
        setOptions(newOptions);
        setOptionInput('');
    };

    const handleDeleteAssessment = async (courseName, question) => {
        const dbName = localStorage.getItem("dbName");
        console.log(courseName, question, "response");
        try {
            const encodedCourseName = encodeURIComponent(courseName);
            const encodedQuestion = encodeURIComponent(question);
            const response = await axios.delete(`${url}/remove/assessment/${dbName}/${encodedCourseName}/${encodedQuestion}`);
            setAssessment(assessment.filter((data) => data.courseName != courseName));
            message.success('Assessment deleted successfully');
            window.location.reload();
            // Update the assessment state or reload the data
        } catch (error) {
            message.error('Failed to delete assessment');
        }
    };

    const handleAssessment = async (values) => {
        const dbName = localStorage.getItem("dbName");

        console.log(values.courseName, "id");
        console.log({
            assessment: {
                question: values.question,
                option: options,
                answer: values.answer
            }
        }, "Payload");

        try {
            const response = await axios.put(`${url}/assessment/${dbName}/${values.courseName}`, {
                minimumMarks: values.minimumMarks,
                assessment: {
                    question: values.question,
                    option: options,
                    answer: values.answer
                }
            });
            const assessment = response.data;
            console.log(assessment, "Assessment");
            setAssessment(assessment);
            form.resetFields();

            message.success('Assessment added successfully');
            setCount(count + 1);
            setQuestion('');
            setOptions([]);
            setAnswer('');
            setIsModalOpen(false);
            setShowSubmittedQuestions(true);
        } catch (error) {
            console.error(error.response.data, "Error Response");
            message.error('Failed to add course');
        }
    };


    return (
        <div>
            <div>
                <div >
                    <Button type="primary" icon={<PlusOutlined />} onClick={handleAssessmentClick}>
                        Add Assessment
                    </Button>
                </div>
                {showAssessment ? (
                    <Row style={{ marginTop: "2vh", display: "flex", justifyContent: "center" }}>
                        <h6>Do you want to add an assessment?</h6>
                        <Button type="primary" style={{ marginLeft: "3vh" }} onClick={() => {
                            setShowAddAssessment(true);
                            setShowAssessment(false);
                        }}>
                            Yes
                        </Button>
                        <Button style={{ left: "5vh" }} onClick={() => setShowAssessment(false)}>No</Button>
                    </Row>
                ) : null}

                {showAddAssessment ? (
                    <div>
                        <h6 className='text-center'>Add Assessment Details</h6>
                        <Divider />
                        <Form form={form} onFinish={handleAssessment}>
                            <Row>
                                <Col span={12}>
                                    <Form.Item
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 12 }}
                                        label="Course Name"
                                        name="courseName"
                                        rules={[{ required: true }]}>
                                        <Select >
                                            {courses.map((course) =>
                                                <Select.Option value={course.courseName} key={course.courseName}>
                                                    {course.courseName}
                                                </Select.Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 12 }}
                                        label="Question"
                                        name="question"
                                        rules={[{ required: true }]}>
                                        <Input style={{ height: "6vh", fontSize: "14px" }} onChange={handleQuestionChange} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    <Form.Item
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 12 }}
                                        label="Add Options" rules={[{ required: true }]}>
                                        <Input style={{ height: "6vh", fontSize: "14px" }} value={optionInput} onChange={handleOptionInputChange} />
                                        {optionInput && (
                                            <Button icon={<PlusCircleOutlined />} onClick={handleAddOption}>
                                                Option
                                            </Button>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 12 }}
                                        label="Options">
                                        {options.map((option, index) => (
                                            <Form.Item key={index} valuePropName="checked">
                                                <Radio value={option}>{option}</Radio>
                                            </Form.Item>
                                        ))}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    <Form.Item
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 12 }}
                                        label="Answer"
                                        name="answer"
                                        rules={[{ required: true }]}>
                                        <Select  >
                                            {options.map((option, index) => (
                                                <Select.Option value={option}>{option}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                {minimumMarks === null && (
                                    <Col span={12}>
                                        <Form.Item
                                            labelCol={{ span: 8 }}
                                            wrapperCol={{ span: 12 }}
                                            label="Minimum Score"
                                            name="minimumMarks"
                                            rules={[{ required: true }]}>
                                            <Input style={{ height: "6vh", fontSize: "14px" }} />
                                        </Form.Item>
                                    </Col>
                                )}
                            </Row>
                            <Row justify="space-around">
                                <Button type='primary' danger onClick={() => setShowAddAssessment(false)}>
                                    Cancel
                                </Button>
                                <Button type="primary" htmlType='submit'>
                                    Submit
                                </Button>
                            </Row>
                        </Form>
                    </div>
                ) : null}
                <Divider />

                <div
                    style={{
                        margin: "2vh",
                        backgroundColor: '#dce7fa',
                        padding: 20,
                        borderRadius: 10,
                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                        height: "110vh"
                    }}
                >
                    <h5 className="text-center" style={{ margin: 0, fontSize: 18, fontWeight: 'bold' }}>
                        Submitted Questions and Answers
                    </h5>
                    <br />
                    <Form.Item labelCol={{ span: 3 }}
                        wrapperCol={{ span: 6 }} label="Course Name">
                        <Select onChange={handleCourseChange}>
                            {courses.map((course) =>
                                <Select.Option value={course.courseName} key={course.courseName}>
                                    {course.courseName}
                                </Select.Option>
                            )}
                        </Select>
                    </Form.Item>
                    {submittedQuestions.length === 0 ? (
                        <h6 style={{ color: "red", marginTop: "16vh", textAlign: "center" }}>No Assessment Added</h6>
                    ) : (
                        <>
                            {submittedQuestions?.slice(currentIndex, currentIndex + questionsPerPage).map((question, index) => (
                                <div key={currentIndex + index} style={{ marginBottom: 20 }}>
                                    <Row justify="space-between">
                                        <h6 style={{ margin: '1vh', fontSize: 16, fontWeight: 'bold' }}>
                                            {currentIndex + index + 1}: {question.question}
                                        </h6>
                                    </Row>
                                    <Row style={{ gap: "3vh" }}>
                                        {question.option.map((options, idx) => (
                                            <span key={idx} style={{ marginBottom: 10 }}>
                                                <Radio value={options} style={{ marginRight: 10 }} />
                                                {options}
                                            </span>
                                        ))}
                                    </Row>
                                    <Row justify="space-between">
                                        <p style={{ fontSize: 14, color: '#5c5959' }}>
                                            Answer: {question.answer}
                                        </p>

                                        <Button type='primary' danger icon={<DeleteFilled />} onClick={() => handleDeleteAssessment(selectedCourse, question.question)} />
                                    </Row>
                                    <hr style={{ borderColor: '#ccc', margin: '10px 0' }} />
                                </div>
                            ))}
                            {currentIndex > 0 && ( // show previous arrow if not at the beginning
                                <Button type='primary' danger icon={<ArrowLeftOutlined />} onClick={handlePrevious} style={{ float: 'left' }}>Previous</Button>
                            )}
                            {currentIndex + questionsPerPage < submittedQuestions.length && ( // show next arrow if not at the end
                                <Button type='primary' danger icon={<ArrowRightOutlined />} onClick={handleNext} style={{ float: 'right' }}>Next</Button>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Assessmentform