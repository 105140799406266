import React, { useEffect, useState } from "react";
import {
  Layout,
  Input,
  Select,
  Button,
  Row,
  Col,
  Table,
  message,
  Modal,
} from "antd";
import { DownloadOutlined, EyeOutlined } from "@ant-design/icons";
import axios from "axios";
import { url } from "../../url/url";

const { Option } = Select;
const { Content } = Layout;

const Resumemanagement = () => {
  const [workExperience, setWorkExperience] = useState("");
  const [skills, setSkills] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selfReferrals, setSelfReferrals] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [noResultsFound, setNoResultsFound] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalResumeUrl, setModalResumeUrl] = useState("");

  useEffect(() => {
    const fetchSelfReferrals = async () => {
      const dbName = localStorage.getItem("dbName");
      try {
        const response = await axios.get(`${url}/get/Selfreferrals/${dbName}`);
        const data = response.data.map((item) => ({
          ...item,
          resumeAge: calculateResumeAge(item.date),
          uploadDate: formatUploadDate(item.date),
          resumeSource:
            item.name === item.displayName ? "Self Referral" : item.displayName,
        }));
        setSelfReferrals(data);
        setSearchResults(data);
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };
    fetchSelfReferrals();
  }, []);

  const parseDate = (dateStr) => {
    const [day, month, year] = dateStr.split("-").map(Number);
    return new Date(year, month - 1, day);
  };

  const calculateResumeAge = (uploadDate) => {
    const uploadDateObj = parseDate(uploadDate);
    const currentDate = new Date();
    const timeDiff = Math.abs(currentDate - uploadDateObj);
    return Math.floor(timeDiff / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
  };

  const formatUploadDate = (date) => {
    const uploadDate = parseDate(date);
    return `${uploadDate.getDate()}-${uploadDate.getMonth() +
      1}-${uploadDate.getFullYear()}`;
  };

  const handleExperienceChange = (value) => {
    setWorkExperience(value);
    filterResults(value, skills, searchTerm);
  };

  const handleSkillsChange = (value) => {
    setSkills(value);
    filterResults(workExperience, value, searchTerm);
  };

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearch = () => {
    filterResults(workExperience, skills, searchTerm);
  };

  const filterResults = (experience, skills, term) => {
    let filteredResults = selfReferrals;

    if (experience) {
      filteredResults = filteredResults.filter(
        (user) => user.yearsOfExp === experience
      );
    }

    if (skills) {
      filteredResults = filteredResults.filter((user) =>
        user.skills.toLowerCase().includes(skills.toLowerCase())
      );
    }

    if (term.trim()) {
      filteredResults = filteredResults.filter(
        (user) =>
          user.name.toLowerCase().includes(term.toLowerCase()) ||
          user.role.toLowerCase().includes(term.toLowerCase())
      );
    }

    setSearchResults(filteredResults);
    setNoResultsFound(filteredResults.length === 0);
  };

  const showModal = (resumeUrl) => {
    setModalResumeUrl(resumeUrl);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleDownloadResume = () => {
    if (modalResumeUrl) {
      const anchor = document.createElement("a");
      anchor.href = modalResumeUrl;
      anchor.download = "resume.pdf";
      anchor.click();
    } else {
      message.error("No resume available for download.");
    }
  };

  const columns = [
    {
      title: "Candidate Name",
      dataIndex: "name",
      key: "name",
      width: 200, // Set width for each column
    },
    {
      title: "Skills",
      dataIndex: "skills",
      key: "skills",
      width: 200,
    },
    {
      title: "Total Exp.(In Years)",
      dataIndex: "yearsOfExp",
      key: "yearsOfExp",
      width: 200,
    },
    {
      title: "Resume Age(In Days)",
      dataIndex: "resumeAge",
      key: "resumeAge",
      width: 200,
      sorter: (a, b) => a.resumeAge - b.resumeAge,
    },
    {
      title: "Upload Date",
      dataIndex: "uploadDate",
      key: "uploadDate",
      width: 200,
    },
    {
      title: "Resume Source",
      dataIndex: "resumeSource",
      key: "resumeSource",
      width: 200,
    },
    {
      title: "Job Role",
      dataIndex: "role",
      key: "role",
      width: 200,
    },
    {
      title: "View",
      key: "view",
      width: 200,
      render: (text, record) => (
        <Button
          icon={<EyeOutlined />}
          onClick={() => showModal(record.resume)}
        />
      ),
    },
  ];

  return (
    <Layout style={{ padding: '20px' }}>
      <Content>
        {/* Heading Section */}
        {/* <div
          style={{
            padding: '10px',
            // backgroundColor: '#ffffff',
            marginBottom: '20px',
            maxWidth: '990px', // Set a max-width for alignment
            margin: '0 auto', // Center the container
            borderBottom: '1px solid #e8e8e8' // Optional: Add a border for visual separation
          }}
        >
          <h1 style={{ justifyContent:'center' }}>Resume Database</h1>
        </div> */}

        {/* Search Bar Section */}
        <div
          style={{
            padding: '20px',
            backgroundColor: '#f9f9f9',
            marginBottom: '20px',
            maxWidth: '990px', // Set a max-width for alignment
            margin: '0 auto', // Center the container
          }}
        >
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Select
                placeholder="Select Experience"
                style={{ width: '100%' }}
                onChange={handleExperienceChange}
                value={workExperience} // Control the value of Select
              >
                {Array.from(
                  new Set(selfReferrals.map((self) => self.yearsOfExp))
                ).map((yearsOfExp, index) => (
                  <Option key={index} value={yearsOfExp}>
                    {yearsOfExp}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col span={6}>
              <Select
                placeholder="Select Skills"
                style={{ width: '100%' }}
                onChange={handleSkillsChange}
                value={skills} // Control the value of Select
              >
                {Array.from(
                  new Set(
                    selfReferrals.flatMap((self) =>
                      self.skills
                        .split(',')
                        .map((skill) => skill.trim().toLowerCase())
                    )
                  )
                ).map((skill, index) => (
                  <Option key={index} value={skill}>
                    {skill.charAt(0).toUpperCase() + skill.slice(1)}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col span={6}>
              <Input
                style={{ fontSize: '12px', width: '100%', height: '6vh' }}
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleInputChange}
              />
            </Col>
            <Col span={6}>
              <Button
                type="primary"
                style={{ marginRight: '10px' }}
                onClick={handleSearch}
              >
                Search
              </Button>
              <Button
                onClick={() => {
                  setSearchResults(selfReferrals);
                  setSearchTerm('');
                  setWorkExperience('');
                }}
              >
                Reset
              </Button>
            </Col>
          </Row>
        </div>

        {/* Table Section */}
        <div
          style={{
            maxWidth: '1200px', // Set a max-width for alignment
            margin: '0 auto', // Center the container
          }}
        >
          <Table
            className="custom-table"
            columns={columns}
            dataSource={searchResults}
            pagination={{ pageSize: 10 }}
            rowKey="id"
          />
        </div>

        {/* Modal for Viewing Resume */}
        <Modal
          title="Resume"
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={[
            <Button key="download" onClick={handleDownloadResume} icon={<DownloadOutlined />}>
              Download
            </Button>,
            <Button key="cancel" onClick={handleCancel}>
              Close
            </Button>,
          ]}
        >
          <iframe
            src={modalResumeUrl}
            style={{ width: '100%', height: '60vh' }}
            title="Resume"
          />
        </Modal>
      </Content>
    </Layout>
  );
};

export default Resumemanagement;
