import CryptoJS from 'crypto-js';

// Key for encryption (ensure this is kept secure)
const ENCRYPTION_KEY = 'aransecretfin754';

// Function to encrypt role
export const encryptRole = (role) => {
  return CryptoJS.AES.encrypt(role, ENCRYPTION_KEY).toString();
};

export const encryptPlanType = (plan) => {
  return CryptoJS.AES.encrypt(plan, ENCRYPTION_KEY).toString();
};

// Function to decrypt role
export const decryptRole = (encryptedRole) => {
  const bytes = CryptoJS.AES.decrypt(encryptedRole, ENCRYPTION_KEY);
  return bytes.toString(CryptoJS.enc.Utf8);
};


export const decryptPlanType = (encryptedPlanType) => {
  const bytes = CryptoJS.AES.decrypt(encryptedPlanType, ENCRYPTION_KEY);
  return bytes.toString(CryptoJS.enc.Utf8);
};
