import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, DatePicker, Checkbox, Radio, message, Row, Col, Card, Popconfirm, Modal } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import axios from 'axios';
import moment from 'moment';
const { Option } = Select;
const { RangePicker, MonthPicker } = DatePicker;

const EducationalForm = ({ onSave, initialValues }) => {
    const [form] = Form.useForm();
    const [showForm, setShowForm] = useState(false);
    const [showJsonData, setShowJsonData] = useState(false);
    const [jsonData, setJsonData] = useState(null);

    const toggleJsonData = () => {
        setShowJsonData(!showJsonData);
    };

    const onJsonDataClose = () => {
        setShowJsonData(false);
    };

    const onJsonDataButtonClick = () => {
        const formData = form.getFieldsValue();
        setJsonData(JSON.stringify(formData, null, 2));
        toggleJsonData();
    };

    useEffect(() => {
        if (initialValues) {
            form.setFieldsValue(initialValues);
        }
    }, [initialValues]);


    const onFinish = (values) => {
        console.log('Received values:', values);
        onSave(values);
        message.success('Saved your Details, You can move to the next step');
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    const handleTileClick = () => {
        setShowForm(true);
    };



    return (
        <div>
            <Form
                form={form}
                name="educationalRecords"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
            >
                <Row gutter={16}>
                    <Col span={16}>
                        <Card title="Educational Record" style={{ marginBottom: 16,width:"auto" }}>
                            <Form.List name="educationalRecords">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, value, name, fieldKey, ...restField }) => (
                                            <div key={key}>
                                                <Form.Item
                                                    {...restField}
                                                    label="College Name"
                                                    name={[name, 'collegeName']}
                                                    fieldKey={[fieldKey, 'collegeName']}
                                                    wrapperCol={{ span: 8 }}
                                                    rules={[{ required: true, message: 'Please enter College Name' }]}
                                                >
                                                    <Input  />
                                                </Form.Item>

                                                <Form.Item
                                                    {...restField}
                                                    label="College Address & Contact No"
                                                    name={[name, 'collegeAddress']}
                                                    fieldKey={[fieldKey, 'collegeAddress']}
                                                    wrapperCol={{ span: 8 }}
                                                    rules={[{ required: true, message: 'Please enter College Address and Contact Telephone' }]}
                                                >
                                                    <Input />
                                                </Form.Item>

                                                <Form.Item
                                                    {...restField}
                                                    label="University Name, Address, & Contact No"
                                                    name={[name, 'universityName']}
                                                    fieldKey={[fieldKey, 'universityName']}
                                                    wrapperCol={{ span: 8 }}
                                                    rules={[{ required: true, message: 'Please enter University Name, Address, and Contact Telephone' }]}>
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item label="University City/State/Country"
                                                    name={[name, 'universityCity']}
                                                    fieldKey={[fieldKey, 'universityCity']}
                                                    wrapperCol={{ span: 8 }}
                                                    rules={[{ required: true, message: 'Please enter University City/State/Country' }]}>
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item
                                                    {...restField}
                                                    label="From To (month / year)"
                                                    name={[name, 'eduDateRange']}
                                                    fieldKey={[fieldKey, 'eduDateRange']}
                                                    wrapperCol={{ span: 8 }}
                                                    rules={[{ required: true, message: 'Please enter From  To (month / year)' }]}>
                                                    <DatePicker.RangePicker />
                                                </Form.Item>
                                                <Form.Item
                                                    {...restField}
                                                    label="Graduated"
                                                    name={[name, 'graduated']}
                                                    fieldKey={[fieldKey, 'graduated']}
                                                >
                                                    <Radio.Group>
                                                        <Radio value="Yes">Yes</Radio>
                                                        <Radio value="No">No</Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                                <Form.Item
                                                    {...restField}
                                                    label="Program"
                                                    name={[name, 'program']}
                                                    fieldKey={[fieldKey, 'program']} wrapperCol={{ span: 8 }}>
                                                        
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item
                                                    {...restField}
                                                    label="Full Time/Part Time"
                                                    name={[name, 'fullTimePartTime']}
                                                    wrapperCol={{ span: 8 }}
                                                    fieldKey={[fieldKey, 'fullTimePartTime']}>
                                                    <Select>
                                                        <Select.Option value="Full Time">Full Time</Select.Option>
                                                        <Select.Option value="Part Time">Part Time</Select.Option>
                                                    </Select>
                                                </Form.Item>

                                                <Form.Item
                                                    {...restField}
                                                    label="Registration/Roll No."
                                                    name={[name, 'registrationRollNo']}
                                                    wrapperCol={{ span: 8 }}
                                                    fieldKey={[fieldKey, 'registrationRollNo']}
                                                >
                                                    <Input />
                                                </Form.Item>

                                                <Form.Item
                                                    {...restField}
                                                    label="Type of degree"
                                                    name={[name, 'degreeType']}
                                                    wrapperCol={{ span: 8 }}
                                                    fieldKey={[fieldKey, 'degreeType']}
                                                >
                                                    <Input />
                                                </Form.Item>

                                                <Form.Item
                                                    {...restField}
                                                    label="Graduation date (month / year)"
                                                    name={[name, 'graduationDate']}
                                                    wrapperCol={{ span: 8 }}
                                                    fieldKey={[fieldKey, 'graduationDate']}
                                                >
                                                    <DatePicker.MonthPicker />
                                                </Form.Item>

                                                <Form.Item
                                                    {...restField}
                                                    label="Subject Major"
                                                    name={[name, 'subjectMajor']}
                                                    wrapperCol={{ span: 8 }}
                                                    fieldKey={[fieldKey, 'subjectMajor']}
                                                >
                                                    <Input />
                                                </Form.Item>

                                                <Form.Item
                                                    {...restField}
                                                    label="Copy of the Certificate Attached"
                                                    name={[name, 'certificateAttached']}
                                                    wrapperCol={{ span: 8 }}
                                                    fieldKey={[fieldKey, 'certificateAttached']}
                                                    valuePropName="checked">
                                                    <Checkbox />
                                                </Form.Item>
                                                <Form.Item
                                                    {...restField}
                                                    label="Educated in Overseas?"
                                                    name={[name, 'educatedOverseas']}
                                                    wrapperCol={{ span: 8 }}
                                                    fieldKey={[fieldKey, 'educatedOverseas']}
                                                >
                                                    <Radio.Group onChange={(e) => form.setFieldsValue({ [`educationalRecords[${name}].educatedOverseas`]: e.target.value === 'Yes' })}>
                                                        <Radio value="Yes">Yes</Radio>
                                                        <Radio value="No">No</Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                                {form.getFieldValue(['educationalRecords', name, 'educatedOverseas']) === 'Yes' && (
                                                    <>
                                                        <Form.Item
                                                            {...restField}
                                                            label="Unique identification no. at Overseas (SSN/TIN)"
                                                            name={[name, 'overseasUniqueId']}
                                                            wrapperCol={{ span: 8 }}
                                                            fieldKey={[fieldKey, 'overseasUniqueId']}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item
                                                            {...restField}
                                                            label="Given Name at Overseas"
                                                            name={[name, 'givenNameOverseas']}
                                                            wrapperCol={{ span: 8 }}
                                                            fieldKey={[fieldKey, 'givenNameOverseas']}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </>
                                                )}

                                                <Form.Item>
                                                    <Button type="primary"   htmlType="submit">
                                                        Save
                                                    </Button>
                                                </Form.Item>
                                               
                                                <Form.Item>
                                                    <Popconfirm
                                                        title="Are you sure you want to remove this educational record?"
                                                        onConfirm={() => {
                                                            console.log('Form values:', form.getFieldsValue());
                                                            remove(name);
                                                        }}
                                                        okText="Yes"
                                                        cancelText="No"
                                                    >
                                                        <Button type="danger" icon={<MinusOutlined />}>
                                                            Remove Educational Record
                                                        </Button>
                                                    </Popconfirm>
                                                </Form.Item>
                                               
                                            </div>
                                        ))}
                                        <Form.Item>
                                            <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                                                Add Educational Record
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Card>
                    </Col>
                </Row>
                <Modal
                    title="JSON Data"
                    open={showJsonData}
                    onCancel={onJsonDataClose}
                    footer={[
                        <Button key="close" onClick={onJsonDataClose}>
                            Close
                        </Button>
                    ]}
                >
                    <pre>{jsonData}</pre>
                </Modal>
            </Form>
        </div >
    );
};

export default EducationalForm;
