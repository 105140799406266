import React, { useEffect, useState } from "react";
import { Row, Col, Card, Pagination, Input } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { url } from "../../url/url";
import axios from "axios";

const { Search } = Input;

const Openings = () => {
  const [job, setJob] = useState([]);
  const [filteredJob, setFilteredJob] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [jobsPerPage] = useState(6);
  const navigate = useNavigate();
  const [expandedJobs, setExpandedJobs] = useState({});

  const toggleExpand = (index) => {
    setExpandedJobs((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const truncate = (text, length) => {
    if (text?.length <= length) {
      return text;
    }
    return text?.substring(0, length) + '...';
  };
  const handleClick = (jobs) => {
    navigate("/home/desc", { state: { jobs } });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const dbName = localStorage.getItem("dbName");
    try {
      const response = await axios.get(`${url}/get/jobs/${dbName}`);
      const openJobs = response.data.filter((job) => job.status === "Open");
      setJob(openJobs);
      setFilteredJob(openJobs);
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  // Pagination
  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = filteredJob.slice(indexOfFirstJob, indexOfLastJob);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Search functionality
  const onSearch = (value) => {
    const filtered = job.filter((jobs) =>
      jobs.role.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredJob(filtered);
  };

  return (
    <div>
      <h4 style={{ color: "#EA5851" }}>Current Openings</h4>
      <Row justify="space-between" align="middle">
        <div
          style={{
            border: "1px solid #EBDEDD",
            padding: "10px",
            borderRadius: "30px",
            background: "#C2B5B4",
            color: "white",
          }}
        >
          {job?.length} JOBS
        </div>
        <div>
          <Input
            type="text"
            size="large"
            style={{ height: "40px", fontSize: "15px" }}
            placeholder="Search job role.."
            onChange={(e) => onSearch(e.target.value)}
          />
        </div>
      </Row>
      <br />
      <Row gutter={[16, 16]}>
        {currentJobs?.map((job, index) => {
          const isExpanded = expandedJobs[index];
          const description = isExpanded ? job.description : truncate(job.description, 100);

          return (
            <Col span={12} key={index}>
              <Card
              hoverable
              style={{height:"35vh"}}
                onClick={() => handleClick(job)}
                title={
                  <Link
                    onClick={() => handleClick(job)}
                    style={{ color: "#EA5851" }}
                  >
                    {job?.role}
                  </Link>
                }
              >
                <p>
                  {description}
                  {job?.description?.length > 100 && (
                    <span
                      onClick={() => toggleExpand(index)}
                      style={{ color: "#EA5851", cursor: "pointer" }}
                    >
                      {isExpanded ? ' show less' : ' more'}
                    </span>
                  )}
                </p>
                <p
                  style={{
                    color: "#757191",
                    fontWeight: "bold",
                    textAlign: "right",
                  }}
                >
                  {job.empType}
                </p>
              </Card>
            </Col>
          );
        })}
      </Row>
      <Pagination
        current={currentPage}
        total={filteredJob.length}
        pageSize={jobsPerPage}
        onChange={paginate}
        style={{ marginTop: 20, textAlign: "center" }}
      />
    </div>
  );
};

export default Openings;
