import React from "react";
import { Button, Row, Col, Divider, Form, Input, Select, Checkbox, Card } from "antd";

const { Option } = Select;

const ViewFeedback = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <Card
        style={{
          width: "80%",
          maxWidth: 1200,
          backgroundColor: "transparent",
          boxShadow: "none",
        }}
        bodyStyle={{ padding: 0 }}
      >
        <Row gutter={[16, 16]}>
          <Col span={10}>
            <div style={{ textAlign: "left", marginLeft: "20px" }}>
              <Form.Item name="organization" label="Organization">
                <Select placeholder="Select your organization">
                  <Option value="Resignation">THE DIGITAL GROUP</Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="employee"
                label="Employee Name"
                rules={[
                  {
                    required: true,
                    message: "Please provide the employee name!",
                  },
                ]}
              >
                <Input placeholder="Riley Doug" />
              </Form.Item>
              <p>
                Employment Number:
                <br />
                00648
              </p>
              <p>
                Resignation Letter Date:
                <br />
                16 - May - 2019
              </p>
              <p>
                Actual Relieving Date:
                <br />
                18 - May - 2019
              </p>
            </div>
          </Col>
          <Divider type="vertical" style={{ height: 0, width: "8vh" }} />
          <Col span={10}>
            <div
              style={{
                textAlign: "left",
                marginLeft: "20px",
              }}
            >
              <Form.Item
                name="exit"
                label="Exit Interview Template Name"
                rules={[
                  {
                    required: true,
                    message: "Please select the exit interview template!",
                  },
                ]}
              >
                <Select placeholder="Select Exit Interview Template" />
              </Form.Item>
              <p>
                Designation:
                <br />
                Assistant Manager
              </p>
              <p>
                Date of Joining:
                <br />
                12 - Jul - 2008
              </p>
            </div>
          </Col>
          <Divider style={{ margin: 0, backgroundColor: "#26241e" }} />
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={10}>
            <div style={{ textAlign: "left", marginLeft: "20px" }}>
              <p>
                1. What factors have contributed to your decision of leaving
                Digital Group Info Tech?
              </p>
              <br />
              <p>
                2. Have you shared your concerns with anyone (in the company)
                prior to taking this decision? If yes, When?
              </p>
              <br />
              <p>3. What did you like most about your job?</p>
              <br />
              <p>4. Mention any instances which demotivated you at work:</p>
              <br />
              <p>
                5. Did any organization policies or procedures make your job
                more difficult? If yes, please mention the same:
              </p>
              <br />
              <p>
                6. Do you feel you had the resources and support necessary to
                accomplish your job? If not, what was missing?
              </p>
              <br />
              <p>7. Employee Comments:</p>
              <br />
              <p>8. What circumstances would have prevented your departure?</p>
              <br />
            </div>
          </Col>
          <Col span={10}>
            <div
              style={{
                margin: "3vh",
                textAlign: "left",
                marginLeft: "90px",
              }}
            >
              <Checkbox>Better Remuneration</Checkbox>
              <Checkbox>Better Role</Checkbox>
              <Checkbox>Better Work Culture/ Environment</Checkbox>
              <Checkbox>Onsite Opportunity</Checkbox>
              <Checkbox>Relocation</Checkbox>
              <Checkbox>Marriage</Checkbox>
              <Checkbox>Health Issue</Checkbox>
              <Checkbox>Higher Education</Checkbox>
              <Checkbox>Work Pressure</Checkbox>
              <Checkbox>Other</Checkbox>
              <Input style={{ margin: "1vh" }} placeholder="Other reason" />
            </div>
          </Col>
          <Divider style={{ margin: 0, backgroundColor: "#26241e" }} />
        </Row>
        <div style={{ margin: "1vh", textAlign: "left", marginLeft: "20px" }}>
          <Form.Item
            name="HR"
            label="HR Comments:"
            rules={[
              {
                required: true,
                message: "Please provide the HR comments!",
              },
            ]}
          >
            <Input placeholder="Enter Comments" />
          </Form.Item>
        </div>
        <div style={{ textAlign: "right", marginRight: 12 }}>
          <Button type="primary" style={{ marginRight: 8 }}>
            Submit
          </Button>
          <Button type="primary" style={{ marginRight: 8 }}>
            Reset
          </Button>
          <Button type="primary" style={{ marginRight: 8 }}>
            Export to PDF
          </Button>
          <Button type="primary" style={{ marginRight: 8 }}>
            Export to DOC
          </Button>
          <Button type="primary" style={{ marginRight: 8 }}>
            Export to Excel
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default ViewFeedback;
