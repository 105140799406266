import React, { useState, useEffect } from "react";
import {
  Tabs,
  Table,
  Input,
  Badge,
  Button,
  Modal,
  message,
  notification,
  Popconfirm,
  Form,
} from "antd";
import axios from "axios";
import { url } from "../../url/url";
import { Link } from "react-router-dom";

const { TabPane } = Tabs;

const TimeSheetlist = (props) => {
  const [dataSubmitted, setDataSubmitted] = useState([]);
  const [dataApproved, setDataApproved] = useState([]);
  const [dataRejected, setDataRejected] = useState([]);
  const [data, setData] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [dataId, setdataId] = useState("");
  const [email, setemail] = useState("");
  const [count, setcount] = useState([]);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [total, setTotal] = useState(dataSubmitted.length);
  const [total1, setTotal1] = useState(dataApproved.length);
  const [total2, setTotal2] = useState(dataRejected.length);

  const onPageChange = (newCurrent, newPageSize) => {
    setCurrent(newCurrent);
    setPageSize(newPageSize);
  };

  const columns = [
    {
      title: "Employee Name",
      dataIndex: "userName",
      width: 200
    },
    {
      title: "Employee ID",
      dataIndex: "empCode",
      width: 200
    },
    {
      title: "Start Date",
      dataIndex: "weekStartingDate",
      width: 200

    },
    {
      title: "End Date",
      dataIndex: "weekEndingDate",
      width: 200

    },
    {
      title: "Total Hours",
      dataIndex: "weekTotal",
      width: 200

    },
    {
      title: "Action",
      key: "action",
      dataIndex: "action",
      width: 400,
      align: "center",
      render: (text, record) => {
        if (record.status === "Submitted") {
          // Render buttons for Submitted status
          return (
            <div style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
              <Link to={`/home/usertables/${record._id}/${record.userName}`}>
                <Button>View</Button>
              </Link>
              <Popconfirm
                title="Are you sure you want to Approve this timesheet?"
                onConfirm={() => Approve(record)}
              >
                <Button type="primary" style={{ backgroundColor: "green" }}>
                  Approve
                </Button>
              </Popconfirm>
              <Button type="primary" danger onClick={() => Remove(record)}>
                Reject
              </Button>
            </div>
          );
        } else if (
          record.status === "Approved" ||
          record.status === "Rejected"
        ) {
          // Render buttons for Approved and Reject statuses
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center", // Center the buttons
                gap: "5px",
              }}
            >
              <Link to={`/home/usertables/${record._id}/${record.userName}`}>
                <Button>View</Button>
              </Link>
            </div>
          );
        } else {
          return null; // Handle other cases or provide a default action
        }
      },
    },
  ];

  const fetchData = async (status) => {
    const dbName = localStorage.getItem("dbName");
    try {
      const response = await axios.get(
        `${url}/timesheet/manager/${status}/${user.displayName}/manager/${dbName}`
      );
      switch (status) {
        case "Submitted":
          setDataSubmitted(response.data);
          break;
        case "Approved":
          setDataApproved(response.data);
          break;
        case "Rejected":
          setDataRejected(response.data);
          break;
        default:
          break;
      }
    } catch (error) {
      console.error(`Failed to fetch ${status} timesheet data: ${error}`);
    }
  };

  useEffect(() => {
    fetchData("Submitted");
    fetchData("Approved");
    fetchData("Rejected");
    fetchData("Pending");
  }, [user.displayName, count]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    console.log("Input value:", inputValue);
    if (inputValue === "") {
      message.error("Please enter the reason");
    } else {
      try {
        const dbName = localStorage.getItem("dbName");
        const response = await axios.put(
          `${url}/timesheet/approve/${dataId}/${dbName}`,
          {
            status: "Rejected",
            reason: inputValue,
            emails: email,
          }
        );
        setIsModalOpen(false);
        fetchData("Submitted");
        // setData((prevData) => prevData.filter((data) => data._id !== dataId));
        notification.success({ message: "Rejected  successfully!" });
        setcount(count + 1);
      } catch (error) {
        console.error(error);
        setIsModalOpen(false);
        message.error("Failed to reject");
      }
    }
  };

  const Approve = async (record) => {
    try {
      const dbName = localStorage.getItem("dbName");
      const response = await axios.put(
        `${url}/timesheet/approve/${record._id}/${dbName}`,
        {
          status: "Approved",
          email: record.email,
        }
      );
      setData((prevData) => prevData.filter((data) => data._id !== record._id));
      notification.success({ message: "Approved  successfully!" });
      setcount(count + 1);
    } catch (error) {
      console.error(error);
      message.error("Failed to Approve");
    }
  };
  const Remove = (record) => {
    setIsModalOpen(true);
    setdataId(record._id);
    setemail(record.email);
  };
  const handleCancel = (record) => {
    setIsModalOpen(false);
    setInputValue("");
    setdataId(false);
    setemail(false);
  };

  const handleChange = (event) => {
    const inputValue = event.target.value;

    const alphanumericWithSpaces = /^[a-zA-Z0-9 ]*$/;

    if (alphanumericWithSpaces.test(inputValue)) {
      setInputValue(inputValue);
    } else if (inputValue.trim() === "") {
      message.error("Enter valid input");
    } else {
      message.error("Enter valid input");
    }
  };
  return (
    <div className="">
      <Modal
        title="Reason for reject"
        open={isModalOpen}
        onOk={handleSubmit}
        onCancel={handleCancel}
      >
        <Form onSubmit={handleSubmit}>
          <Form.Item>
            <Input
              type="text"
              value={inputValue}
              onChange={handleChange}
              placeholder="Enter Reason"
              rules={[
                {
                  required: true,
                  message: "Please Input Task Name..!",
                },
              ]}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Tabs defaultActiveKey="1" centered>
        <TabPane
          tab={
            <Badge offset={[15]} count={dataSubmitted.length}>
              <span style={{ color: "black", fontSize: "10px" }}>
                Timesheet Submitted
              </span>
            </Badge>
          }
          key="1"
        >
          <Table
            className="custom-table col-lg-12 col-md-12 col-sm-12"
            columns={columns}
            dataSource={dataSubmitted}
            pagination={{
              current: current,
              pageSize: pageSize,
              total: total1,
              onChange: onPageChange,
            }}
            scroll={{ x: true }}
            sticky
          />
        </TabPane>

        <TabPane
          tab={
            <span style={{ color: "black", fontSize: "10px" }}>
              Timesheet Approved
            </span>
          }
          key="3"
        >
          <Table
            className="custom-table col-lg-12 col-md-12 col-sm-12"
            columns={columns}
            dataSource={dataApproved}
            pagination={{
              current: current,
              pageSize: pageSize,
              total: total1,
              onChange: onPageChange,
            }}
            scroll={{ x: true }}
            sticky
          />
        </TabPane>
        <TabPane
          tab={
            <span style={{ color: "black", fontSize: "10px" }}>
              Timesheet Rejected
            </span>
          }
          key="4"
        >
          <Table
            className="custom-table col-lg-12 col-md-12 col-sm-12"
            columns={columns}
            dataSource={dataRejected}
            pagination={{
              current: current,
              pageSize: pageSize,
              total: total2,
              onChange: onPageChange,
            }}
            scroll={{ x: true }}
            sticky
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default TimeSheetlist;
