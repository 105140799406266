import React, { useState, useEffect } from "react";
import { message, Select, Table, Row, Col, Empty, Button } from "antd";
import ApexCharts from "react-apexcharts";
import { url } from "../../url/url";
import { Spinner } from "react-bootstrap";
import Download from "./Download";
import axios from "axios";

const Reports = (props) => {
  const [projData, setProjData] = useState([]);
  const [table, setTable] = useState([]);
  const [tables, setTables] = useState([]);

  const [loading, setLoading] = useState(false);
  const [selectedProject, setSelectedProject] = useState("");
  const [Image1, setImage1] = useState();
  const [filterByProject, setFilterByProject] = useState(false);
  const [filterByTask, setFilterByTask] = useState(false);
  const [selectedMonthFilter, setSelectedMonthFilter] = useState("");
  const { downloadMode } = props;
  const users = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    fetchData();
    fetchtable();
    fetchLogo();
  }, []);

  const fetchLogo = async () => {
    try {
      const dbName = localStorage.getItem("dbName");
      const response = await axios.get(`${url}/admin/get/logo/${dbName}`);
      setImage1(response.data[0].image);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const dbName = localStorage.getItem("dbName");
      const response = await fetch(`${url}/api/projectsdetails/${dbName}`);
      const projdata = await response.json();
      // Filter projects based on user's displayName
      const userProjects = projdata.filter(project => project.user === users.displayName);
      setProjData(userProjects);
      setLoading(false);
    } catch (error) {
      console.error(error);
      message.error("Failed to fetch data from the server.");
      setLoading(false);
    }
  };

  const fetchtable = async () => {
    setLoading(true);
    try {
      const dbName = localStorage.getItem("dbName");
      // Fetch the project details
      const projectResponse = await axios.get(
        `${url}/api/projectsdetails/${users.displayName}/${dbName}`
      );

      // Extract project names
      const projectNames = projectResponse.data.map((dat) => dat.ProjectName);
      console.log(projectNames, "filtered project names");

      // Fetch the timesheet report
      const timesheetResponse = await fetch(`${url}/timesheet/reportgen/${dbName}`);
      const table1 = await timesheetResponse.json();

      // Filter the entries based on the project names
      const filteredTable = table1.filter((entry) =>
        Array.isArray(entry.entrydata) && entry.entrydata.some((data) => projectNames.includes(data.project))
      );

      // Extract and map the relevant entry data, including weekStartingDate
      const formattedTable = filteredTable.flatMap((entry) =>
        entry.entrydata
          .filter((data) => projectNames.includes(data.project))
          .map((data) => ({
            weekStartingDate: entry.weekStartingDate,
            project: data.project,
            task: data.task,
            totalhours: data.totalhours
          }))
      );

      setTable(formattedTable);
      setLoading(false);
      console.log(formattedTable, "formatted table data");

    } catch (error) {
      console.error(error);
      message.error("Failed to fetch data from the server.");
      setLoading(false);
    }
  };



  const transformDataForTable = (tableData) => {
    const newData = {};
    tableData.map((record) => {

      const dateParts = record.weekStartingDate.split("-");
      const month = parseInt(dateParts[1], 10) - 1; // Months in JavaScript are 0-based (0 = January, 11 = December)
      const key = `${record.project}_${month}`;

      if (!newData[key]) {
        newData[key] = {
          project: record.project,
          weekStartingDate: record.weekStartingDate,
          totalhours: parseFloat(record.totalhours),
          month: month, // Add the month to the data
        };
      } else {
        newData[key].totalhours += parseFloat(record.totalhours);
      }
    })


    // Convert the object values to an array
    const result = Object.values(newData);

    // If you want to combine total hours for the same project across different months, you can do that here
    const combinedData = {};

    result.forEach((entry) => {
      const projectKey = entry.project;

      if (!combinedData[projectKey]) {
        combinedData[projectKey] = {
          project: entry.project,
          totalhours: entry.totalhours,
          weekStartingDates: [entry.weekStartingDate], // Track all week starting dates
        };
      } else {
        combinedData[projectKey].totalhours += entry.totalhours;
        combinedData[projectKey].weekStartingDates.push(entry.weekStartingDate);
      }
    });

    // Convert the combined object values to an array
    return Object.values(combinedData);
  };

  const tableDataForAntd = transformDataForTable(table);
  const columns = [
    {
      title: "Project Name",
      dataIndex: "project",
    },
    {
      title: "Total Hours",
      dataIndex: "totalhours",
    },
  ];

  const columns1 = [
    {
      title: "Month",
      dataIndex: "month",
    },
    {
      title: "Project Name",
      dataIndex: "project",
    },
    {
      title: "Total Hours",
      dataIndex: "totalhours",
    },
  ];

  const columns2 = [
    {
      title: "Task",
      dataIndex: "task",
    },
    {
      title: "Total Hours",
      dataIndex: "totalhours",
    },
  ];

  // const options = {
  //     chart: {
  //         type: 'bar',
  //         height: 350,
  //         stacked: true,
  //         toolbar: {
  //             show: true,
  //         },
  //         zoom: {
  //             enabled: true,
  //         },
  //     },
  //     responsive: [
  //         {
  //             breakpoint: 480,
  //             options: {
  //                 legend: {
  //                     position: 'bottom',
  //                     offsetX: -10,
  //                     offsetY: 0,
  //                 },
  //             },
  //         },
  //     ],
  //     plotOptions: {
  //         bar: {
  //             horizontal: false,
  //             borderRadius: 10,
  //             dataLabels: {
  //                 total: {
  //                     enabled: true,
  //                     style: {
  //                         fontSize: '13px',
  //                         fontWeight: 900,
  //                     },
  //                 },
  //             },
  //         },
  //     },
  //     xaxis: {
  //         type: 'datetime',
  //         categories: [
  //             '01/01/2023 GMT',
  //             '01/02/2023 GMT',
  //             '01/03/2023 GMT',
  //             '01/04/2023 GMT',
  //             '01/05/2023 GMT',
  //             '01/06/2023 GMT',
  //         ],
  //     },
  //     legend: {
  //         position: 'right',
  //         offsetY: 40,
  //     },
  //     fill: {
  //         opacity: 1,
  //     },
  // };

  // const seriesData = [
  //     {
  //         name: 'PRODUCT A',
  //         data: [44, 55, 41, 67, 22, 43],
  //     },
  //     {
  //         name: 'PRODUCT B',
  //         data: [13, 23, 20, 8, 13, 27],
  //     },
  //     {
  //         name: 'PRODUCT C',
  //         data: [11, 17, 15, 15, 21, 14],
  //     },
  //     {
  //         name: 'PRODUCT D',
  //         data: [21, 7, 25, 13, 22, 8],
  //     },
  // ];
  const monthColumn = {
    title: "Month",
    dataIndex: "month",
    filters: [
      // Filtering options
      { text: "Jan", value: "January" },
      { text: "Feb", value: "February" },
      { text: "Mar", value: "March" },
      { text: "Apr", value: "April" },
      { text: "May", value: "May" },
      { text: "June", value: "June" },
      { text: "July", value: "July" },
      { text: "Aug", value: "August" },
      { text: "Sep", value: "September" },
      { text: "Oct", value: "October" },
      { text: "Nov", value: "November" },
      { text: "Dec", value: "December" },
    ],
    onFilter: (value, record) => {
      if (record.weekStartingDate) {
        const dateParts = record.weekStartingDate.split("-");
        const month = parseInt(dateParts[1], 10) - 1;
        const startDate = new Date(dateParts[2], month, dateParts[0]);
        const monthName = startDate.toLocaleString("en-us", { month: "long" });

        // Update the selected month filter
        setSelectedMonthFilter(value);
        return monthName === value;
      } else {
        return false;
      }
    },
    render: (_, record) => {
      if (record.weekStartingDate) {
        const dateParts = record.weekStartingDate.split("-");
        const day = parseInt(dateParts[0], 10);
        const month = parseInt(dateParts[1], 10) - 1;
        const year = parseInt(dateParts[2], 10);

        const startDate = new Date(year, month, day);
        const monthName = startDate.toLocaleString("en-us", { month: "long" });
        return `${monthName}`;
      } else {
        return "No Date Available";
      }
    },
  };

  const chart = {
    chart: {
      height: 500,
      type: "donut",
      toolbar: {
        show: false,
      },
    },

    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
    colors: undefined,
    plotOptions: {
      pie: {
        donut: {
          size: "65%",
          labels: {
            show: true,
            name: {
              show: true,
              offsetY: -20,
            },
            value: {
              show: true,
              offsetY: 90,
            },
            total: {
              show: true,
              showAlways: true,
              fontSize: "15px",
              fontWeight: "bold",
              fontFamily: undefined,
              color: "#373d3f",
              formatter: function (w) {
                return w.globals.seriesTotals.reduce((a, b) => {
                  return a + b;
                }, 0);
              },
            },
          },
        },
      },
    },
  };

  const projectHours = table.reduce((acc, curr) => {
    const projectName = curr.project;
    const totalHours = curr.totalhours;
    if (acc[projectName]) {
      acc[projectName] += totalHours;
    } else {
      acc[projectName] = totalHours;
    }
    return acc;
  }, {});

  const tableDataForAntd1 = Object.entries(projectHours).map(
    ([projectName, totalHours]) => {
      return {
        project: projectName,
        totalhours: totalHours,
      };
    }
  );
  console.log(tableDataForAntd1);

  const series = tableDataForAntd.map((proj) => proj.totalhours);
  const chartOptions = {
    series: series,
    labels: tableDataForAntd.map((proj) => proj.project),
    chart: chart,
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "12px",
        colors: ["#fff"],
      },
      formatter: function (val, opts) {
        return opts.w.globals.series[opts.seriesIndex];
      },
    },
  };
  const amazonTasks = {};
  const amazonData = [];

  table.forEach((entry) => {
    if (Array.isArray(entry.entrydata)) { // Check if entrydata is an array
      entry.entrydata.forEach((data) => {
        if (entry.weekStartingDate) { // Check if weekStartingDate is defined
          const dateParts = entry.weekStartingDate.split("-");
          if (dateParts.length >= 3) { // Ensure dateParts has at least 3 elements
            const month = parseInt(dateParts[1], 10) - 1;
            const startDate = new Date(dateParts[2], month, dateParts[0]);
            const monthName = startDate.toLocaleString("en-us", {
              month: "long",
            });

            // Check if data.project is defined
            if (data.project && monthName === selectedMonthFilter) {
              const key = `${monthName}_${data.project}`;
              console.log("inside if", key);
              if (amazonTasks[key]) {
                amazonTasks[key] += data.totalhours;
              } else {
                amazonTasks[key] = data.totalhours;
              }
            }
          }
        }
      });
    }
  });


  // Convert aggregated data to the desired format for display
  const aggregatedData = Object.entries(amazonTasks).map(
    ([key, totalHours]) => {
      const [month, project] = key.split("_");
      return {
        month,
        project: project,
        totalhours: totalHours,
      };
    }
  );

  // Now, aggregatedData contains an array of objects with month, project, and totalhours

  // ... (rest of the code, you can use aggregatedData as needed)

  const taskDetails = Object.entries(amazonTasks).map(([key, totalHours]) => {
    const [month, project] = key.split("_");
    return {
      month,
      project,
      totalhours: totalHours,
    };
  });

  // Now taskDetails contains the aggregated data with projects and mapped months.

  const amazonChartData = {
    series: Object.values(amazonTasks),
    labels: taskDetails.map((task) => task.project),
    chart: chart,
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "12px",
        colors: ["#fff"],
      },
      formatter: function (val, opts) {
        return opts.w.globals.series[opts.seriesIndex];
      },
    },
  };

  const amazondata = table.filter((entry) => entry.project === selectedProject);


  const amazonTask = {};
  console.log("amazonTask before processing:", amazondata);

  amazondata.forEach((entry) => {
    if (
      entry.project === selectedProject &&
      entry.task !== "Leave" &&
      entry.task !== "Permission" &&
      entry.task !== "Holiday"
    ) {
      if (amazonTask[entry.task]) {
        amazonTask[entry.task] += entry.totalhours;
      } else {
        amazonTask[entry.task] = entry.totalhours;
      }
    }
  });

  const taskDetail = Object.entries(amazonTask).map(([task, totalhours]) => {
    return {
      task: task,
      totalhours: totalhours,
    };
  });

  console.log("Task Details:", taskDetail);
  const amazonChartdata = {
    series: Object.values(amazonTask),
    labels: Object.keys(amazonTask),
    chart: chart,
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "12px",
        colors: ["#fff"],
      },
      formatter: function (val, opts) {
        return opts.w.globals.series[opts.seriesIndex];
      },
    },
  };
  return (
    <div style={{ zoom: "90%" }}>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className="reports" id="download">
            <h2>Report Summary</h2>

            <div>
              <Row justify="end">
                <Col xs={2} sm={4} md={6} lg={5} xl={6}>
                  <img src={Image1} width={150} alt="admin" />
                </Col>
              </Row>
            </div>
            <Row lg={24} style={{ padding: "10px" }}>
              <Col lg={14} justify="space-between" align="middle">
                <Table
                  className="custom-table col-lg-12 col-md-12 col-sm-12"
                  columns={columns}
                  dataSource={tableDataForAntd}
                  pagination={false}
                  sticky
                />
              </Col>
              <Col lg={10} justify="end">
                <ApexCharts
                  options={chartOptions}
                  series={series}
                  type="donut"
                  width={500}
                />
              </Col>
            </Row>
            {filterByProject && (
              <div>
                <h6>Month Filter :-</h6>
                <Select
                  style={{ width: "20%" }}
                  placeholder="Select Month"
                  onChange={(value) => setSelectedMonthFilter(value)}
                  value={selectedMonthFilter}
                >
                  {monthColumn.filters.map((filter) => (
                    <Select.Option key={filter.value} value={filter.value}>
                      {filter.text}
                    </Select.Option>
                  ))}
                </Select>
                <Row lg={18} style={{ padding: "10px" }}>
                  <Col lg={14} justify="end">
                    <Table
                      className="custom-table col-lg-12 col-md-12 col-sm-12"
                      columns={columns1}
                      dataSource={taskDetails}
                      pagination={false}
                      sticky
                    />
                  </Col>
                  <Col lg={4} justify="end">
                    {selectedMonthFilter === "" ? (
                      <Empty description="No Month Selected" height={500} />
                    ) : (
                      <ApexCharts
                        options={amazonChartData}
                        series={amazonChartData.series}
                        type="donut"
                        width={500}
                      />
                    )}
                  </Col>
                </Row>
              </div>
            )}
            {!downloadMode && (
              <div>
                <Button
                  type="primary"
                  onClick={() => setFilterByProject(!filterByProject)}
                >
                  {filterByProject ? "Hide Filter" : "Filter by Month"}
                </Button>
              </div>
            )}

            <br />
            {!downloadMode && (
              <div>
                <Button
                  type="primary"
                  onClick={() => setFilterByTask(!filterByTask)}
                >
                  {filterByTask ? "Hide Filter" : "Filter by Project"}
                </Button>
              </div>
            )}
            <br />
            {filterByTask && (
              <div>
                <h6>Project Filter:-</h6>
                <Select
                  style={{ width: "20%" }}
                  placeholder="Please select"
                  onChange={(value) => setSelectedProject(value)}
                >
                  {tableDataForAntd1.map((data, index) => (
                    <Select.Option key={index} value={data.project}>
                      {data.project}
                    </Select.Option>
                  ))}
                </Select>
                <Row lg={18} style={{ padding: "10px" }}>
                  <Col lg={14} justify="end">
                    <Table
                      className="custom-table col-lg-12 col-md-12 col-sm-12"
                      columns={columns2}
                      dataSource={taskDetail}
                      pagination={false}
                      sticky
                    />
                  </Col>
                  <Col lg={4} justify="end">
                    {selectedProject === "" ? (
                      <Empty description="No Project Selected" height={500} />
                    ) : (
                      <ApexCharts
                        options={amazonChartdata}
                        series={amazonChartdata.series}
                        type="donut"
                        width={500}
                      />
                    )}
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Reports;
