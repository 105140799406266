import axios from "axios";
import { Routes, Route, Link, useNavigate, useParams } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import {
  Layout,
  Dropdown,
  Modal,
  Alert,
  Button,
  Carousel,
  Drawer,
  message,
  Form,
  Input,
  Avatar,
  Tooltip,
  Spin,
  notification,
  Card,
  Collapse,
  Row,
  Col,
} from "antd";
import {
  SearchOutlined,
  CopyOutlined,
  CopyFilled,
  CheckOutlined,
  ApartmentOutlined,
} from "@ant-design/icons";
import "./form.css";
import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { Siders } from "../components/sider/sider";
import Badge from "@mui/material/Badge";
import Addtask from "./task/addtask";
import Timesheet from "./timesheet/timesheet";
import Project from "./project/project";
import Emp from "./employee/Employee";
import Addprojects from "./project/addproject";
import UserDetails from "./employee/EmpDetails2";
import Userapprove from "./HR_table/userapprove";
import Pendinglist from "./HR_table/pendinglist";
import NotFound from "./error/error";
import EmpLeaveForm from "./leave/employee/EmpLeaveApply";
import LeaveApply from "./leave/employee/LeaveApply";
import EmpLeaveReport from "./leave/employee/EmpLeaveForm";
import ManagerApproval from "./leave/manager/ManagerApproval";
import Compoff from "./leave/manager/Compoff";
import ManagerReport from "./leave/manager/ManagerReport";
import Claims from "./claims/claims";
import Profile from "./profile";
import ClaimStatus from "./claims/ClaimsStatus";
import ClaimApproval from "./claims/ClaimAprroval";
import Dashboard2 from "../dashboard/dashboard2";
import Empdash from "../dashboard/employeedashboard";
import EmpApply from "./employee/EmployeeApply";
import Reports from "./Reports/reports";
import { url } from "../url/url";
import ManagerStatus from "./claims/mangerclaim";
import AdminDashboard from "./admin/adminDashboard";
import Map from "../Map";
import Timer from "./timer";
import * as msal from "@azure/msal-browser";
import { CloseOutlined } from "@ant-design/icons";
import Stack from "@mui/material/Stack";
import SupportRequestButton from "../components/header/SubmitRequestButton";
import TimeSheetSummary from "./Reports/timeSheetSummary";
import { ColorContext } from "./admin/ColorProvider";
import "./form.css";
import ReviewTable from "./employee/EmployeeTable";
import UserMenuTable from "./superadmin/Userrole";
import HRReport from "./HR_home/letter";
import RaiseIssue from "./querysupport/empquery";
import ExpandableTable from "./querysupport/adminquery";
import EmpFileUpload from "./employee/EmpBulkUpload";
import EmployeeList from "./employee/Employeelist";
import { decryptPlanType, decryptRole, encryptRole } from "../encrypt";
import EmployeeManagementTile from "./HR_home/hrhome";
import WorkAnniversaries from "./HR_home/workanni";
import Birthdays from "./HR_home/birthday";
import LowLeaveBalance from "./HR_home/leave balance";
import AssetReport from "./HR_home/asset";
import BGVEmp from "./BGV/Emplist";
import EmergencyContacts from "./HR_home/emergency";
import { MenuProvider } from "../Menuprovider";
import SuperAdminHome from "./superadmin/SuperAdminHome";
import MasterOrgTable from "./superadmin/MasterOrgTable";
import ManageDB from "./superadmin/ManageDB";
import MasterUserTable from "./superadmin/MasterUserTable";
import ReleaseNotes from "./superadmin/ReleaseNotes";
import ResultUpload from "./BGV/Result";
import AboutApp from "./superadmin/AboutApp";
import ProfitabilityHome from "./project/ProfitabilityHome";
import BillableRates from "./project/BillableRates";
import EmpLeaveBalRequest from "./leave/employee/EmpLeaveBalRequest";
import EmpLeaveApplyForm from "./leave/employee/EmpLeaveApplyForm";
import InactiveEmp from "./HR_home/InactiveEmp";
import GenericData from "./Offerletter/GenericData";
import Chatbox from "./Chatbot/chatbox";
import Thirukural from "./Thirukural/thirukural";
import Dashboard from "./Release manager/dashboard";
import Travelform from "./travel/form";
import Travelreport from "./travel/report";
import Travelexpense from "./travel/travel";
import Workitems from "./Release manager/workitem";
import Board from "./Release manager/Board";
import Activityboard from "./Release manager/Activityboard";
import SprintDirectory from "./Release manager/SprintList";
import Descriptions from "./joboffer/Descriptions";
import Openings from "./joboffer/Openings";
import Details from "./joboffer/Details";
import Home from "./joboffer/Home";
import Postjobs from "./joboffer/Postjobs";
import ActivityChart from "./Release manager/charts";
import Teamrequest from "./travel/teamrequest";
import BillingDetails from "./superadmin/BillingDetails";
import ViewProfitability from "./project/ViewProfitability";
import Traininghome from "./training/Traininghome";
import Hrtraining from "./training/HRtraining";
import Chart1 from "./employee/orgChart";
import Hrform from "./training/Hrform";
import Assessmentform from "./training/Assessmentform";
import Assessment from "./training/Assessment";
import Resumemanagement from "./HR_home/Resumemanagement";
import PolicyAcknowledgmentForm from "./employee/policy";
import Standardcost from "./project/Standardcost";
import Exitmanagement from "./HR_home/Exit";
import NotificationTab from "./ExitManagement/NotificationTab";
import RecordResignationTab from "./ExitManagement/RecordResignationTab";
import PullBackTab from "./ExitManagement/PullBackTab";
import ExitInterview from "./ExitManagement/ExitInterview";
import ViewFeedback from "./ExitManagement/ViewFeedback";
import FileUpload from "./fileupload";
import Headertabs from "../dashboard/Headertabs";
import AdminConfigPage from "./admin/admin";
const { Header, Content, Footer } = Layout;
const { Search } = Input;
const { Meta } = Card;
const Homepage = (props) => {
  const Navigate = useNavigate();
  const [peer, setPeer] = useState(false);
  const [copied, setCopied] = useState(false);
  const [Users, setUsers] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [peerModalVisible, setPeerModalVisible] = useState(false);
  const [secondModalVisible, setSecondModalVisible] = useState(false);
  const [data, setData] = useState([]);
  const { email } = useParams();
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [openpasswd, setOpenpsswd] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState("Do you really want To logout");
  const encryptedRoleFromStorage = localStorage.getItem("role");
  const role = decryptRole(encryptedRoleFromStorage);
  const [collapse, setCollapse] = useState(true);
  const encryptedPlanTypeFromStorage = localStorage.getItem("uniqueId");
  const planType = decryptPlanType(encryptedPlanTypeFromStorage);
  const [Image, setprofileImage] = useState([]);
  const users = JSON.parse(localStorage.getItem("user"));
  const [user] = useState(users);
  const [Image1, setImage1] = useState();
  const [sessionTimeout, setSessionTimeout] = useState(null);
  const [open1, setOpen1] = useState(false);
  const [userannouncement, setUserannouncement] = useState([]);
  const [loading, setLoading] = useState(false);
  const [placement, setPlacement] = useState("left");
  const [orgName, setOrgName] = useState("");
  const [changePasswordStatus, setChangePasswordStatus] = useState(null);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [changePasswordEmail, setChangePasswordEmail] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [menuData, setMenuData] = useState([]);
  const { color } = useContext(ColorContext);
  const [form] = Form.useForm();
  const [menus, setMenus] = useState([]);
  const colorData = localStorage.getItem("color");
  const [
    isChangePasswordModalVisible,
    setIsChangePasswordModalVisible,
  ] = useState(false);
  const [elementMap, setElementMap] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const location = useLocation();
  const [importantAnnouncement, setImportantAnnouncement] = useState(null);

  // Function to calculate the next refresh time
  const calculateNextRefresh = () => {
    const now = new Date();
    // const nextRefresh = new Date(now.getTime() + 60000); // Set to 1 minute later

    const nextRefresh = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() + 1,
      0,
      0,
      0,
      0
    );
    return nextRefresh.getTime() - now.getTime();
  };

  useEffect(() => {
    const refreshPage = () => {
      setRefresh((prev) => !prev);
      window.location.reload();
    };

    const nextRefreshTime = calculateNextRefresh();
    const timer = setTimeout(refreshPage, nextRefreshTime);

    return () => clearTimeout(timer);
  }, [refresh]);

  useEffect(() => {
    const important = userannouncement?.find((item) => item.Important);

    if (important) {
      setImportantAnnouncement(important);
    }
  }, [userannouncement]);

  useEffect(() => {
    setTimeout(() => setLoading(false), 2000);
  }, []);

  // Fetch the menu data from the database
  useEffect(() => {
    axios.get(`${url}/get/menusider/${planType}`).then((response) => {
      const data = response.data;
      const mapping = data.map((item) => item.access);
      console.log(data, "response");
      setMenus(mapping);
      const mappedMenuData = data.map((menu) => {
        const componentPath = menu.componentPath.split("/"); // Split componentPath
        const valueAfterHomeIndex = componentPath.indexOf("home");
        if (valueAfterHomeIndex >= 0) {
          // Remove the "home" and everything before it
          componentPath.splice(0, valueAfterHomeIndex + 1);
        }

        return {
          key: menu.key,
          componentPath: `/${componentPath.join("/")}`,
          element: menu.element,
          access: menu.access,

          // Modify this to match your data structure
        };
      });

      setMenuData(mappedMenuData);
      setElementMap([mappedMenuData.element]);
    });
  }, []);

  const elementmap = {
    Timesheet: Timesheet,
    Project: Project,
    Emp: Emp,
    Addtask: Addtask,
    ManagerStatus: ManagerStatus,
    Pendinglist: Pendinglist,
    Addprojects: Addprojects,
    UserDetails: UserDetails,
    EmpLeaveForm: EmpLeaveForm,
    EmpLeaveReport: EmpLeaveReport,
    ManagerApproval: ManagerApproval,
    Compoff: Compoff,
    ManagerReport: ManagerReport,
    Claims: Claims,
    Profile: Profile,
    ClaimStatus: ClaimStatus,
    EmpApply: EmpApply,
    ClaimApproval: ClaimApproval,
    AdminDashboard: AdminDashboard,
    AdminConfigPage:AdminConfigPage,
    Reports: Reports,
    UserMenuTable: UserMenuTable,
    ReviewTable: ReviewTable,
    Map: Map,
    Userapprove: Userapprove,
    TimeSheetSummary: TimeSheetSummary,
    RaiseIssue: RaiseIssue,
    ExpandableTable: ExpandableTable,
    EmployeeManagementTile: EmployeeManagementTile,
    EmpFileUpload: EmpFileUpload,
    EmployeeList: EmployeeList,
    LeaveApply: LeaveApply,
    SuperAdminHome: SuperAdminHome,
    ProfitabilityHome: ProfitabilityHome,
    Board: Board,
    EmpLeaveBalRequest: EmpLeaveBalRequest,
    EmpLeaveApplyForm: EmpLeaveApplyForm,
    Teamrequest: Teamrequest,
    Traininghome: Traininghome,
    Home: Home,
    PolicyAcknowledgmentForm: PolicyAcknowledgmentForm,
    // Add other components here
  };
  const handleConfirmNewPasswordChange = (event) => {
    const confirmPasswordValue = event.target.value;
    setConfirmNewPassword(confirmPasswordValue);

    // Check if the new password and confirm password match
    if (newPassword === confirmPasswordValue) {
      setPasswordsMatch(true);
    } else {
      setPasswordsMatch(false);
    }
  };

  //   setLoading(true);
  //   const fetchData = async () => {
  //     const dbName = localStorage.getItem("dbName");
  //     try {
  //       const response = await fetch(`${url}/get/colors/${dbName}`);
  //       console.log(response, "ingetcolor");
  //       if (response.ok) {
  //         const data = await response.json();
  //         console.log(data, "Datainif");
  //         setColorData(data.data.color);
  //         console.log(data.data.color, "Colorinset");
  //         setLoading(false);
  //       } else {
  //         setError("Data not found");
  //       }
  //     } catch (error) {
  //       setError("An error occurred while fetching data");
  //     }
  //   };

  //   fetchData();
  // }, []);

  // localStorage.removeItem("dbName");
  // const response = await axios.get(
  //   `${url}/api/getmasteruser/${user.email}/method`
  // );
  // localStorage.setItem("dbName", response.data.user.dbName);
  const showDrawer = () => {
    setOpen2(true);
  };
  const onClose = () => {
    setOpen2(false);
  };
  useEffect(() => {
    fetchData();
    fetchUserDetailsByEmail();
    fetchprofileimage();
    fetchUserData();
    fetchData5();
    fetchOrgName();
  }, []);
  useEffect(() => {
    if (location.state) {
      setSecondModalVisible(location.state.secondModalVisible);
      setPeerModalVisible(location.state.peerModalVisible);
    }
  }, [location.state?.secondModalVisible, location.state?.peerModalVisible]);
  useEffect(() => {
    fetchIndividualData();
  }, [selectedEmployee]);

  const fetchOrgName = async (dbName) => {
    try {
      const dbName = localStorage.getItem("dbName");
      const response = await axios.get(`${url}/api/orgName/${dbName}`);
      setOrgName(response.data.orgName);
      const colorData = await axios.get(`${url}/get/combined/${dbName}`);
      if (colorData?.data[0]?.backgroundColor) {
        const color = colorData.data[0].backgroundColor;
        localStorage.setItem("color", color);
      } else {
        localStorage.setItem("color", "#00134D");
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  const fetchData5 = async () => {
    try {
      const dbName = localStorage.getItem("dbName");
      const response = await axios.get(`${url}/admin/announcements/${dbName}`);
      console.log(response.data, "data1234");
      setUserannouncement(response.data);
      if (response.status === 200) {
        // If the API call is successful, show success notification
      } else {
        // If there's an error in the API call, show an error notification
      }
    } catch (error) {
      // Handle any network or other errors
      console.error(error);
    }
  };

  const handleUserActivity = () => {
    // Clear the existing session timeout
    if (sessionTimeout) {
      clearTimeout(sessionTimeout);
    }
    // Set a new session timeout (e.g., 30 minutes)
    const newSessionTimeout = setTimeout(() => {
      // Perform logout or session expiration action here
      handlelogout();
    }, 3 * 60 * 60 * 1000); // 3 hours in milliseconds
    // Update the state with the new session timeout
    setSessionTimeout(newSessionTimeout);
  };
  const fetchUserDetailsByEmail = async () => {
    const dbName = localStorage.getItem("dbName");
    try {
      const userType = localStorage.getItem("userType");
      let email;

      if (userType === "true") {
        const email1 = user.userPrincipalName || user.email;
        email = email1.toLowerCase();
        // } else {
        //   email = user.email;
        // }
        const response = await axios.get(
          `${url}/api/getUserByEmail/${email}/${dbName}`
        );
        const User = response.data.user;
        //  setGetUser(user)
        localStorage.removeItem("user");
        localStorage.removeItem("role");
        localStorage.removeItem("status");
        const encryptedRole = encryptRole(User.jobTitle);
        await Promise.all([
          localStorage.setItem("user", JSON.stringify(User)),
          localStorage.setItem("role", encryptedRole),
        ]);
      }
      const res = await axios.get(`${url}/api/masterorg/${dbName}`);
      localStorage.setItem("status", res.data.org.status);
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  const fetchData = async () => {
    try {
      const dbName = localStorage.getItem("dbName");
      const response = await axios.get(`${url}/admin/logo/${dbName}`);
      setImage1(response.data.logo);
    } catch (error) {
      console.error(error);
    }
  };

  const showModal = () => {
    setOpen(true);
  };

  const showModalpasswd = () => {
    setOpenpsswd(true);
  };

  const peerSearch = async () => {
    fetchIndividualData();
    setPeer(true);
    console.log(data, "data in peersearch");
    setPeerModalVisible(true);
  };

  const peerCancel = () => {
    setPeer(false);
    setSearchInput("");
    setSearchResults([]);
    setPeerModalVisible(false);
  };

  const clearSearchInput = () => {
    setSearchInput("");
    setSearchResults([]);
  };

  const fetchUserData = async () => {
    try {
      const dbName = localStorage.getItem("dbName");
      const response = await axios.get(`${url}/api/get/allemplist/${dbName}`);
      setUsers(response.data);
      setSearchResults(response.data);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const fetchIndividualData = async () => {
    try {
      setLoading(true);
      const dbName = localStorage.getItem("dbName");
      const response = await axios.get(
        `${url}/api/get/emplistbyid/${selectedEmployee}/${dbName}`
      );
      if (response.status === 200) {
        setData(response.data);
        setLoading(false);
      } else {
        console.log("Error:", response.status);
      }
    } catch (error) {
      console.error("Error in catch:", error);
      setLoading(false);
    }
  };

  const handleSearchInputChange = async (e) => {
    setSearchInput(e.target.value);
    // Filter results based on search input
    const filteredResults = Users.filter((result) =>
      result.displayName.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setSearchResults(filteredResults);
  };

  const openSecondModal = (user) => {
    setSelectedEmployee(user.email);
    clearSearchInput();
    setSecondModalVisible(true);
    console.log(data, "data in opensecondmodal");
  };

  const closeSecondModal = () => {
    setSecondModalVisible(false);
    clearSearchInput();
  };
  const Orgchart = (email) => {
    Navigate(`/home/orgchart/${email}`, {
      state: {
        from: "/home",
        secondModalVisible: false,
        peerModalVisible: false,
      },
    });
    setSecondModalVisible(false);
    setPeerModalVisible(false);
  };

  const handleCopyEmail = () => {
    if (!navigator.clipboard) {
      // Clipboard API not supported, fallback to document.execCommand
      const emailElement = document.createElement("textarea");
      emailElement.value = data.email;
      document.body.appendChild(emailElement);
      emailElement.select();
      document.execCommand("copy");
      document.body.removeChild(emailElement);
      setCopied(true);
      return;
    }

    navigator.clipboard
      .writeText(data.email)
      .then(() => {
        setCopied(true);
      })
      .catch((err) => {
        console.error("Unable to copy to clipboard", err);
      });
  };

  const items = [
    {
      label: <Link to="/home/profile">Profile</Link>,
      key: "0",
    },
    {
      type: "divider",
    },
    {
      type: "divider", // Divider before "Change Password"
    },
    {
      label: <Link onClick={showModal}>Log Out</Link>,
      key: "2",
    },
    {
      type: "divider",
    },
  ];

  const userType = localStorage.getItem("userType");

  if (userType === "false") {
    items.splice(2, 0, {
      label: <Link onClick={showModalpasswd}>Change Password</Link>,
      key: "1",
    });
  }

  const validateConfirmPassword = (rule, value, callback) => {
    setConfirmNewPassword(value);
    if (newPassword === value) {
      //setPasswordsMatch(true);
      callback();
    } else {
      //setPasswordsMatch(false);
      callback("Passwords do not match");
    }
  };
  const handleNewPasswordCheck = (rule, value, callback) => {
    const newPasswordValue = value;
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    setNewPassword(newPasswordValue);
    // Check if the new password and confirm password match
    if (currentPassword === newPasswordValue) {
      //setnewPasswordcheck(false);
      callback("New password must be different from the current password");
    } else if (!passwordPattern.test(newPasswordValue)) {
      callback(
        "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one digit, and one special character."
      );
    } else {
      callback();
    }

    // Finally, update the newPassword state
    setNewPassword(newPasswordValue);
  };
  const handleOk = () => {
    setModalText("Logout In Few Seconds");
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      handlelogout();
      setConfirmLoading(false);
    }, 2000);
  };
  const handleOk1 = () => {
    setOpen1(false);

    // Store an indicator in local storage to remember that the announcement has been cleared
  };

  // Function to handle closing the modal without clearing announcement
  const handleOk2 = () => {
    setOpen2(false);
  };

  const handleOkpasswd = () => {
    setOpenpsswd(false);
  };
  // Use useEffect to check if the announcement has been cleared on page load

  const handleCancel = () => {
    setOpen(false);
  };
  const handleOpen1 = () => {
    setOpen1(true);
  };

  // Function to handle closing the modal
  const handleCancel1 = () => {
    setOpen1(false);
  };
  const handleCancel2 = () => {
    setOpen2(false);
  };

  const handleCancelChangePassword = () => {
    setIsChangePasswordModalVisible(false);
    setCurrentPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
    setChangePasswordEmail("");
    setPasswordsMatch(true); // Reset passwordsMatch state
    form.resetFields(); // Reset the form fields
    setOpenpsswd(false);
  };

  const handleChangePassword = async (e) => {
    try {
      // Send a request to your API to change the password
      const email = user.email;
      const dbName = localStorage.getItem("dbName");
      const response = await axios.post(`${url}/api/changePassword/${dbName}`, {
        email,
        currentPassword,
        newPassword,
      });

      if (response.data.success) {
        setChangePasswordStatus(null);
        notification.success({ message: "Password changed successfully!" });
        setIsChangePasswordModalVisible(false);
        setCurrentPassword("");
        setNewPassword("");
        setConfirmNewPassword("");
        form.resetFields();
      } else {
        if (response.status === 401) {
          setChangePasswordStatus(
            "Current password is incorrect. Please try again."
          );
          form.resetFields();
        } else {
          setChangePasswordStatus(
            "Failed to change password. Please try again"
          );
          message.error("Failed to change password. Please try again.");
          form.resetFields();
        }
      }
      setOpenpsswd(false);
    } catch (error) {
      console.error("Error during password change:", error);
      if (error.response && error.response.status === 401) {
        // Handle the 401 error for invalid current password
        console.error("Error during password change:401");
        // setChangePasswordStatus("Invalid current password. Please try again.");
        message.error("Invalid current password. Please try again.");
        setCurrentPassword("");
        setNewPassword("");
        setConfirmNewPassword("");
        setIsChangePasswordModalVisible(false);
        setOpenpsswd(false);
        form.resetFields();
      } else {
        // Handle other errors
        console.error("Error during password change:402");
        // setChangePasswordStatus(
        //   "An error occurred during password change. Please try again later."
        // );
        message.error(
          "An error occurred during password change. Please try again later."
        );
        setChangePasswordStatus(null);
        setCurrentPassword("");
        setNewPassword("");
        setConfirmNewPassword("");
        setIsChangePasswordModalVisible(false);
        setOpenpsswd(false);
        form.resetFields();
      }
    }
  };

  const handlelogout = async () => {
    const userType = localStorage.getItem("userType");
    if (userType === "true") {
      const domain = localStorage.getItem("domain");
      const response = await axios.get(`${url}/api/getmasterorg/${domain}`);
      const tenantId = response.data.org.tenantId;
      const clientId = response.data.org.clientId;
      const msalConfig = {
        auth: {
          clientId: `${clientId}`,
          authority: `https://login.microsoftonline.com/${tenantId}`,
          redirectUri: `https://ems.finaran.com/login`,
        },
      };
      const msalInstance = new msal.PublicClientApplication(msalConfig);
      try {
        await msalInstance.logoutPopup();
      } catch (error) {
        // If silent logout fails, perform a redirect logout
        msalInstance.logoutRedirect();
      }
    }
    localStorage.clear();
    localStorage.removeItem("accessToken");
    localStorage.removeItem("routedFromNextPage");
    localStorage.removeItem("manager");
    localStorage.removeItem("user");
    localStorage.removeItem("profileImage");
    localStorage.removeItem("startTime");
    localStorage.removeItem("elapsedTime");
    localStorage.removeItem("switchState");
    localStorage.removeItem("userData");
    localStorage.removeItem("selectedDbName");
    localStorage.removeItem("roles");
    localStorage.removeItem("role");
    localStorage.removeItem("userType");
    localStorage.removeItem("dbName");
    Navigate("/login");
  };

  // const userType = localStorage.getItem("userType")
  // if(userType === 'false'){}
  //const displayName = `${user.firstName} ${user.lastName}`;
  const displayName = `${user.displayName}`;
  const capitalizeddisplayName = displayName.replace(/\b\w/g, (char) =>
    char.toUpperCase()
  );
  const nameInitials = displayName
    .split(" ") // Split the full name into an array of words
    .map((word) => word.charAt(0).toUpperCase()) // Get the first character of each word and capitalize it
    .join(""); // Join the initials together

  const fetchprofileimage = async () => {
    try {
      const userType = localStorage.getItem("userType");
      // const response = await axios.get(
      //   `${url}/api/getUserManager/${user.displayName}/${dbName}`
      // );
      if (userType === "false") {
        const { profileImage } = user;
        setprofileImage(profileImage);
      } else {
        const profileImage = localStorage.getItem("profileImage");
        setprofileImage(profileImage);
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  let propsForTimer = {
    user: user,
    component: <Timer />,
  };

  const isAllowed = (access) => {
    switch (role) {
      case "superAdmin":
        return access[planType].superAdmin === "Allow";
      case "channelPartner":
        return access[planType].channelPartner === "Allow";
      case "admin":
        return access[planType].admin === "Allow";
      case "hr":
        return access[planType]?.hr === "Allow";
      case "manager":
        return access[planType].manager === "Allow";
      case "employee":
        return access[planType].employee === "Allow";
      default:
        return false;
    }
  };
  const renderFooter = () => {
    // Customize the footer for the Change Password modal
    if (openpasswd) {
      return (
        <div>
          <Button onClick={handleCancelChangePassword}>Cancel</Button>
          <Button type="primary" htmlType="submit">
            OK
          </Button>
        </div>
      );
    }
    // Render default footer for other modals
    return null;
  };
  const backgroundColor = colorData ? colorData : "#00134D";
  const status = localStorage.getItem("status");
  return (
    <div>
      {loading ? (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <Layout className="site-layout">
          <Modal
            title="Log Out"
            open={open}
            onOk={handleOk}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
          >
            <p>{modalText}</p>
          </Modal>

          <Modal
            title={
              <span
                style={{
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                Change Password
              </span>
            }
            open={openpasswd}
            footer={null} // Use the custom footer
            className="custom-modal"
          >
            <Form form={form} onFinish={(e) => handleChangePassword(e)}>
              <Form.Item
                style={{
                  marginTop: "50px",
                }}
                label="Current Password"
                name="currentPassword"
                value={currentPassword}
                onChange={(event) => setCurrentPassword(event.target.value)}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please enter your current password.",
                  },
                ]}
              >
                <Input.Password
                  placeholder="Enter your Current Password"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item
                label="New Password"
                name="newPassword"
                value={newPassword}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                  {
                    validator: handleNewPasswordCheck,
                  },
                ]}
              >
                <Input.Password
                  placeholder="Enter your new password"
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Confirm Password"
                name="confirmNewPassword"
                value={confirmNewPassword}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                  {
                    validator: validateConfirmPassword,
                  },
                ]}
              >
                <Input.Password
                  placeholder="Confirm your new password"
                  style={{
                    width: "100%",
                  }}
                />
                {changePasswordStatus && <p>{changePasswordStatus}</p>}
              </Form.Item>
              <Form.Item>
                <Button
                  type="default"
                  onClick={handleCancelChangePassword}
                  style={{ marginLeft: "45vh" }}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ marginLeft: "5px" }}
                >
                  OK
                </Button>
              </Form.Item>
            </Form>
          </Modal>

          <MenuProvider>
            <Layout>
              <Siders
                menuData={menuData}
                collapse={collapse}
                setCollapse={setCollapse}
              />
              <Content
                style={{
                  marginTop: "1%",
                  marginRight: "1%",
                  marginLeft: collapse ? 130 : 200,
                  // paddingLeft: collapse? "0px" :"30px",
                  // boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.35)",
                  minHeight: "100vh",
                }}
              >
                {/* {importantAnnouncement && (
                  <Alert
                    message={importantAnnouncement.Title}
                    description={
                      <div
                        dangerouslySetInnerHTML={{
                          __html: importantAnnouncement.Description,
                        }}
                      />
                    }
                    type="info"
                    showIcon
                    closable 
                    style={{ marginTop: "20px", width: "50%",marginRight:"20px" }} 
                  />
                )} */}

                <Drawer
                  title="Announcement"
                  placement="right"
                  closable={false}
                  open={open2}
                  onClose={onClose}
                  key={placement}
                >
                  <div>
                    {userannouncement?.length > 0
                      ? userannouncement.map((item, index) => (
                          <div
                            className="message-container"
                            key={item._id || index}
                          >
                            <div>
                              <h3>{item.Title}</h3>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item.Description,
                                }}
                              />
                            </div>
                          </div>
                        ))
                      : "No announcements"}
                  </div>
                  <div className="close-button">
                    <Tooltip title="Dismiss notification" placement="top">
                      <Button
                        type="link"
                        icon={<CloseOutlined />}
                        onClick={async () => {
                          onClose();
                        }}
                      ></Button>
                    </Tooltip>
                  </div>
                </Drawer>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  {/* <div
                className="details"
                style={{
                  display: "flex",
                  color: "white",
                  fontSize: "30px",
                  alignItems: "center",
                  marginRight: "60vh",
                }}
              >
                {`${orgName}`}
              </div> */}
                  {status === "disable" ? (
                    <Alert
                      message="Your organization status is disabled."
                      description="Please contact your admin."
                      type="warning"
                      showIcon
                      closable
                    />
                  ) : null}
                  {/* <Alert
                style={{ zIndex: -99 }}
                message={capitalizeddisplayName}
                type="error"
              /> */}
                  <Button
                    onClick={peerSearch}
                    type="link"
                    size="middle"
                    icon={<SearchOutlined />}
                    style={{ marginLeft: "10px" }}
                  ></Button>
                  <Modal
                    title="Peer Search"
                    width={500}
                    open={peerModalVisible}
                    onCancel={peerCancel}
                    footer={[]}
                  >
                    <Input
                      style={{ marginTop: "20px", width: 400 }}
                      placeholder="Enter name"
                      value={searchInput}
                      onChange={handleSearchInputChange}
                    />
                    {searchInput.trim() !== "" && (
                      <div style={{ maxHeight: "300px", overflowY: "auto" }}>
                        {searchResults.length > 0 ? (
                          searchResults.map((result) => (
                            <Card
                              hoverable
                              style={{ width: 400 }}
                              key={result.id}
                              onClick={() => openSecondModal(result)}
                            >
                              <Card.Meta
                                avatar={<Avatar src={result.profileImage} />}
                                title={result.displayName}
                              />
                            </Card>
                          ))
                        ) : (
                          <div>No results found</div>
                        )}
                      </div>
                    )}
                  </Modal>
                  <Modal
                    title="Details"
                    open={secondModalVisible}
                    onCancel={closeSecondModal}
                    footer={[]}
                    style={{ display: "flex", alignItems: "center" }}
                    width={400}
                  >
                    {data && (
                      <Card
                        style={{
                          width: 300,
                          margin: "auto", // Center the Card horizontally
                        }}
                        cover={
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {data.profileImage ? (
                              <Row justify="space-between">
                                <Col span={16}>
                                  <img
                                    alt="Profile"
                                    src={data.profileImage}
                                    style={{
                                      width: "20vh",
                                      height: "20vh",
                                    }}
                                  />
                                </Col>
                              </Row>
                            ) : (
                              <div>
                                <FaUser
                                  size="2em"
                                  style={{
                                    backgroundColor: "#fde3cf",
                                    color: "#f56a00",
                                  }}
                                />
                              </div>
                            )}
                            <Col span={2}>
                              <ApartmentOutlined
                                onClick={() => Orgchart(data.email)}
                              />
                            </Col>
                          </div>
                        }
                      >
                        <Meta
                          title={
                            <div style={{ textAlign: "center" }}>
                              <div>{data.displayName}</div>
                              <div>({data.empCode})</div>
                              <div>{data.empDesignation}</div>
                            </div>
                          }
                          description={
                            <div>
                              <div>
                                Reports To <b>{data.manager}</b>
                              </div>
                              <div>
                                Reachable At <b>{data.phoneNumber}</b>
                              </div>
                              <div>
                                <b> {data.email} </b>
                                <Button
                                  type="link"
                                  size="small"
                                  onClick={handleCopyEmail}
                                >
                                  {copied ? (
                                    <CheckOutlined />
                                  ) : (
                                    <CopyOutlined />
                                  )}
                                </Button>
                              </div>
                            </div>
                          }
                        />
                      </Card>
                    )}
                  </Modal>
                  <Stack
                    spacing={4}
                    direction="row"
                    sx={{ color: "action.active" }}
                  >
                    <Badge
                      color="secondary"
                      badgeContent={userannouncement?.length || 0}
                    >
                      <Link onClick={showDrawer}>
                        <img
                          src={require("../images/announcement.png")}
                          style={{
                            width: "40px",
                            color: "blue",
                            padding: "6px",
                          }}
                        />
                      </Link>
                    </Badge>
                  </Stack>
                  <Dropdown
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    menu={{ items }}
                  >
                    {Image ? (
                      <div>
                        <Avatar
                          size="default"
                          src={Image}
                          alt={user.displayName}
                        />
                      </div>
                    ) : (
                      <div>
                        <Avatar
                          size="default"
                          style={{
                            backgroundColor: "#fde3cf",
                            color: "#f56a00",
                          }}
                        >
                          {nameInitials}
                        </Avatar>
                      </div>
                    )}
                  </Dropdown>
                </div>
                <Routes>
                  <Route
                    path="/"
                    element={
                      role === "manager" ||
                      role === "hr" ||
                      role === "employee" ||
                      role === "admin" ? (
                        <Headertabs {...propsForTimer} />
                      ) : role === "superAdmin" || role === "channelPartner" ? (
                        <SuperAdminHome {...propsForTimer} />
                      ) : (
                        <NotFound />
                      )
                    }
                  ></Route>
                  {menuData.map((route) => {
                    const DynamicComponent = elementmap[route.element]; // Retrieve the component based on the string
                    if (DynamicComponent) {
                      const pathWithoutHome = route.componentPath.replace(
                        /^\/home\//,
                        "/"
                      );
                      if (isAllowed(route.access)) {
                        const routeElement = (
                          <Route
                            key={route.key}
                            path={pathWithoutHome} // Use the path with "/home" removed
                            element={<DynamicComponent data={user} />}
                          />
                        );

                        return routeElement;
                      } else {
                        // Handle the case when the component is not found
                        return null; // or display an error message
                      }
                    }
                  })}
                  {/* {console.log(role,"role")} */}
                  {(role === "employee" ||
                    role === "hr" ||
                    role === "admin" ||
                    role === "superAdmin" ||
                    role === "manager") && (
                    <>
                      <Route
                        path="/orgChart/:email"
                        element={
                          <Chart1
                            secondModalVisible={secondModalVisible}
                            peerModalVisible={peerModalVisible}
                            setSecondModalVisible={setSecondModalVisible}
                            setPeerModalVisible={setPeerModalVisible}
                          />
                        }
                      ></Route>
                      <Route
                        path="/profile"
                        element={<Profile data={user} />}
                      ></Route>
                      <Route
                        path="/assessment/:courseName"
                        element={<Assessment />}
                      ></Route>

                      <Route
                        path="/empleavebalrequest"
                        element={<EmpLeaveBalRequest data={user} />}
                      ></Route>
                      <Route
                        path="/empleaveapplyform"
                        element={<EmpLeaveApplyForm data={user} />}
                      ></Route>
                      <Route
                        path="/travelform"
                        element={<Travelform />}
                      ></Route>
                      <Route
                        path="/travelexpense"
                        element={<Travelexpense />}
                      ></Route>
                      <Route
                        path="/activityboard"
                        element={<Activityboard data={user} />}
                      ></Route>

                      <Route path="/jobdesc" element={<Descriptions />}></Route>
                      <Route path="/jobopenings" element={<Openings />}></Route>
                      <Route path="/desc" element={<Descriptions />}></Route>
                      <Route path="/jobapplied" element={<Details />}></Route>
                    </>
                  )}
                  {(role === "manager" ||
                    role === "hr" ||
                    role === "admin" ||
                    role === "superAdmin" ||
                    role === "channelPartner") && (
                    <>
                      <Route
                        path="/addproject"
                        element={<Addprojects />}
                      ></Route>
                      <Route path="/addtask" element={<Addtask />}></Route>
                      <Route path="/empdetails" element={<EmpApply />}></Route>
                      <Route
                        path="/usertables/:Id/:userName"
                        element={<Userapprove />}
                      />
                      <Route
                        path="/profitable"
                        element={<ProfitabilityHome />}
                      />
                      <Route path="/billrates" element={<BillableRates />} />
                      <Route path="/cost" element={<Standardcost />} />
                      <Route
                        path="/userdetails/:email"
                        element={<UserDetails />}
                      ></Route>
                      <Route
                        path="/empleavereport"
                        element={<EmpLeaveReport data={user} />}
                      ></Route>
                      <Route
                        path="/travelreport"
                        element={<Travelreport />}
                      ></Route>
                      <Route
                        path="/activityboard"
                        element={<Activityboard data={user} />}
                      ></Route>
                      <Route
                        path="/sprintdirectory"
                        element={<SprintDirectory />}
                      />
                    </>
                  )}
                  {(role === "hr" ||
                    role === "admin" ||
                    role === "superAdmin") && (
                    <>
                      <Route
                        path="/travelform"
                        element={<Travelform />}
                      ></Route>
                      <Route
                        path="/travelexpense"
                        element={<Travelexpense />}
                      ></Route>
                      <Route
                        path="/travelreport"
                        element={<Travelreport />}
                      ></Route>
                      <Route
                        path="/workanni"
                        element={<WorkAnniversaries />}
                      ></Route>
                      <Route path="/birthday" element={<Birthdays />}></Route>
                      <Route path="/birthday" element={<Birthdays />}></Route>
                      <Route path="/letter" element={<HRReport />}></Route>
                      <Route
                        path="/resumeSearch"
                        element={<Resumemanagement />}
                      ></Route>
                      <Route
                        path="/emp"
                        element={<BGVEmp data={user} />}
                      ></Route>
                      <Route path="/training" element={<Hrtraining />}></Route>
                      <Route path="/hrform" element={<Hrform />}></Route>
                      <Route path="/exit" element={<Exitmanagement />}></Route>
                      <Route
                        path="/assessmentform"
                        element={<Assessmentform />}
                      ></Route>
                      <Route
                        path="/inactive"
                        element={<InactiveEmp data={user} />}
                      ></Route>
                      <Route
                        path="/lowleave"
                        element={<LowLeaveBalance />}
                      ></Route>
                      <Route path="/asset" element={<AssetReport />}></Route>
                      <Route
                        path="/contacts"
                        element={<EmergencyContacts />}
                      ></Route>
                      <Route
                        path="/ReviewTable"
                        element={<ReviewTable />}
                      ></Route>
                      <Route path="/getbgv" element={<ResultUpload />}></Route>

                      <Route
                        path="/empfileupload"
                        element={<EmpFileUpload />}
                      ></Route>

                      <Route
                        path="/offerletter"
                        element={<GenericData />}
                      ></Route>

                      <Route path="/post" element={<Postjobs />} />
                    </>
                  )}
                  {role === "channelPartner" && (
                    <>
                      <Route
                        path="/masterorgview"
                        element={<MasterOrgTable />}
                      />
                      <Route
                        path="/masterusrview"
                        element={<MasterUserTable />}
                      />
                      <Route
                        path="/userrole"
                        element={<UserMenuTable />}
                      ></Route>
                      <Route
                        path="/profile"
                        element={<Profile data={user} />}
                      ></Route>
                      <Route
                        path="/BillingDetails"
                        element={<BillingDetails />}
                      />
                    </>
                  )}
                  {role === "superAdmin" && (
                    <>
                      <Route
                        path="/masterorgview"
                        element={<MasterOrgTable />}
                      />
                      <Route
                        path="/releasenotes"
                        element={<ReleaseNotes />}
                      ></Route>
                      <Route
                        path="/masterusrview"
                        element={<MasterUserTable />}
                      />
                      <Route
                        path="/BillingDetails"
                        element={<BillingDetails />}
                      />
                      <Route path="/managedb" element={<ManageDB />} />
                      <Route
                        path="/profitable"
                        element={<ProfitabilityHome />}
                      />
                      <Route path="/billrates" element={<BillableRates />} />
                      <Route path="/cost" element={<Standardcost />} />
                      <Route
                        path="/userrole"
                        element={<UserMenuTable />}
                      ></Route>
                    </>
                  )}
                  <Route path="/*" element={<NotFound />}></Route>
                  <Route path="/onedrive" element={<FileUpload />}></Route>
                  <Route path="/dash" element={<ActivityChart />}></Route>
                  <Route path="/admn" element={<AdminConfigPage />}></Route>
                </Routes>
              </Content>
            </Layout>
          </MenuProvider>
          <>
            <Footer style={{ textAlign: "center" }}>
              <div>
                <Chatbox />
              </div>
              <div>
                <SupportRequestButton />
              </div>
              {/* <div>
                <Thirukural />
              </div> */}
              <div>
                <AboutApp />
              </div>

              <div>&copy; 2024 Finan Consulting, All Rights Reserved</div>
            </Footer>
          </>
        </Layout>
      )}
    </div>
  );
};
export default Homepage;
