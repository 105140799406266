import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Tabs,
  message,
  Modal,
  Checkbox,
  Space,
} from "antd";
import {
  MailOutlined,
  WhatsAppOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import React, { useState, useRef, useEffect } from "react";
import "./login.css";
import * as msal from "@azure/msal-browser";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { url } from "../../url/url";
import background from "../../images/lavendar.jpg";
import { encryptPlanType, encryptRole } from "../../encrypt";
const { TabPane } = Tabs;

const Login = () => {
  const [showOTPInput, setShowOTPInput] = useState(false);
  const [showPasswordInput, setShowPasswordInput] = useState(false);
  const [editEmail, setEditEmail] = useState(true);
  const [condition, setCondition] = useState(false);
  const [timer, setTimer] = useState(60);
  const [intervalId, setIntervalId] = useState(null);
  const history = useNavigate();
  const [open, setOpen] = useState(false);
  const [openOTPModal, setOpenOTPModal] = useState(false);
  const [accept, setAccept] = useState(false);
  const [otpValue, setOtpValue] = useState("");
  const [getUser, setGetUser] = useState([]);
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [Mail, setMail] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [loginStatus, setLoginStatus] = useState(null);
  const [isInteractionInProgress, setIsInteractionInProgress] = useState(false); // State to track interaction
  const [terms, setTerms] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [Message, setMessage] = useState("");
  const [method, setMethod] = useState("");
  const passwordInputRef = useRef(null);
  const emailInputRef = useRef(null);
  const [showMenuIcon, setShowMenuIcon] = useState(false);
  const [activeKey, setActiveKey] = useState("1");
  useEffect(() => {
    emailInputRef.current.focus();
    fetchCondition();
  }, []);
  useEffect(() => {
    function handleResize() {
      setShowMenuIcon(window.innerWidth <= 768);
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const showModal = () => {
    setOpen(true);
    setTimeout(() => {
      // Set focus on the password input after a short delay
      if (passwordInputRef.current) {
        passwordInputRef.current.focus();
      }
    }, 100);
  };
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const handleOk = () => {
    console.log("handleok");
    setMethod("email");
    // setOpen(false);
    Nativelogin(true, "email"); //if user logins with password true will be sent
  };

  const handleOtpLogin = async (e) => {
    try {
      console.log(otpValue, "otpValue");
      if (otp === otpValue) {
        console.log("otp is correct calling native login");
        handleCloseOTPModal();
        Nativelogin(false, method);
      } else {
        message.error("Incorrect otp,Please verify the otp");
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };
  const handleCancel = () => {
    setOpen(false);
    setPassword("");
    setOtp("");
    setLoginStatus("");
    Message("");
  };

  const showModal1 = () => {
    setOpen1(true);
  };

  const handleCancelpassword = () => {
    setOpen1(false);
    setNewPassword("");
    setEmail("");
    setMessage("");
  };
  const handleOpenOTPModal = async (e, Method) => {
    if (e) {
      e.preventDefault();
    }
    let mail;
    setMethod(method);
    if (Method === "email") {
      if (!isValidEmail(email)) {
        message.error("Invalid email. Please enter a valid email.");
        return;
      }
      mail = email.toLowerCase();
    } else {
      mail = email;
    }
    console.log(email, Method, "email");
    try {
      const response = await axios.get(
        `${url}/api/getmasteruser/${mail}/${Method}`
      );
      const condition = response.data.user.condition;
      const mobile = response.data.user.mobile;
      const Email = response.data.user.email;
      setMail(Email);

      console.log(mobile, "mobile");
      if (condition !== "agreed") {
        setCondition(true);
        return;
      }

      if (condition === "agreed") {
        const OTP = generateOTP();
        let apiEndpoint;

        switch (Method) {
          case "email":
            apiEndpoint = `${url}/otp`;
            break;
          case "mobile":
            apiEndpoint = `${url}/send-otp/SMS`;
            break;
          case "whatsapp":
            apiEndpoint = `${url}/send-otp/whatsapp`;
            break;
          default:
            message.error("Invalid method selected.");
            return;
        }
        const payload =
          Method === "email"
            ? { email: email, newPassword: OTP }
            : { to: mobile, otp: OTP };

        const resetResponse = await axios.post(apiEndpoint, payload);

        if (resetResponse.status === 200) {
          setOtp(OTP);
          console.log(otp);
          setOpenOTPModal(true);
          startTimer();
        } else {
          message.error("Failed to send OTP.");
        }
      }
    } catch (error) {
      message.error("Enter a valid Email");
      console.error(error, "validmail");
    }
  };

  const onTabChange = (key) => {
    setActiveKey(key); // Update active tab based on user selection
    // Reset states related to password and OTP if switching tabs
    setShowPasswordInput(false);
    setPassword("");
    setOpenOTPModal(false);
    setOtpValue("");
    setLoginStatus(""); // Clear any previous login status message
  };
  const handleAcceptChange = async () => {
    console.log("true", email);
    try {
      const accept = await axios.post(`${url}/api/update-conditions/${email}`);
      console.log("accepttrue", accept);
      if (activeKey === "1") {
        await checkEmailValid();
      } else if (activeKey === "2") {
        await handleOpenOTPModal();
      }
    } catch (error) {
      message.error("Error in accepting terms and conditions");
      console.error(error, "Error");
    }
  };

  const handleCloseOTPModal = () => {
    setOpenOTPModal(false);
  };

  const isValidEmail = (email) => {
    // Implement your email validation logic here
    // For example, you can use a regular expression
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const fetchCondition = async () => {
    try {
      const response = await axios.get(`${url}/api/get-conditions`);
      console.log("Response received:", response);

      if (response.data && response.data.conditions) {
        setTerms(response.data.conditions);
      } else {
        console.error(
          "No conditions found in the response data:",
          response.data
        );
      }
    } catch (error) {
      console.error("There was an error fetching data:", error);
    }
  };

  const checkEmailValid = async (e) => {
    if (e) {
      e.preventDefault();
    }
    // Check if the email is valid (you can implement your validation logic here)
    if (!isValidEmail(email)) {
      message.error("Invalid email. Please enter a valid email.");
      return;
    }
    console.log(email, "email");
    const mail = email.toLowerCase();
    console.log(mail, "mail");
    try {
      const response = await axios.get(
        `${url}/api/getmasteruser/${mail}/method`
      );
      const userType = response.data.user.userType;
      const condition = response.data.user.condition;
      const dbName = response.data.user.dbName;
      const jobTitle = response.data.user.jobTitle;
      if (condition !== "agreed") {
        setCondition(true);
      }
      if (condition === "agreed") {
        console.log("after accept true");
        setEditEmail(false);
        if (userType === "true") {
          setCondition(false);
          handleLoginPopup(e, mail);
        } else if (userType === "false") {
          setShowPasswordInput(true);
        } else if (userType === "false") {
          showModal1();
        } else if (userType === "inactive") {
          message.error(
            "Your account has been disabled. Please contact your administration."
          );
        }
      }
    } catch (error) {
      // Handle other error cases, e.g., by displaying a generic error message
      message.error("Enter a valid Email");
      console.error(error, "validmail"); // Log the error for debugging
    }
  };

  // Define the generateRandomPassword function
  function generateRandomPassword() {
    const length = 12; // You can adjust the password length as needed
    const characters =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*";
    let newPassword = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      newPassword += characters.charAt(randomIndex);
    }
    return newPassword;
  }

  const handleResetPassword = async (e) => {
    e.preventDefault();

    try {
      const newPassword = generateRandomPassword();

      // Ensure newPassword is a valid string
      if (!newPassword) {
        setMessage("An error occurred. Please try again later.");
        return;
      }
      const mail = email.toLowerCase();
      const response = await axios.get(
        `${url}/api/getmasteruser/${mail}/method`
      );
      const dbName = response.data.user.dbName;

      const resetResponse = await axios.post(
        `${url}/api/reset-password/${dbName}`,
        {
          mail: mail,
          newPassword: newPassword,
        }
      );

      if (resetResponse.data.success) {
        setMessage(
          <span style={{ color: "green" }}>Password reset successful.</span>
        );
        setOpen1(false);
      } else {
        setMessage(
          <span style={{ color: "red" }}>
            Password reset failed. Please try again later.
          </span>
        );
      }
    } catch (error) {
      console.error("Error during password reset:", error);
      setMessage("An error occurred. Please try again later.");
    }
  };

  const handleLoginPopup = async (e, email) => {
    console.log("insidehandleLoginPopup", email);
    if (e) {
      e.preventDefault();
    }
    const domain = email.split("@")[1];
    const selectedOrg = domain;
    console.log(selectedOrg, "selectedorg");

    const response = await axios.get(`${url}/api/getmasterorg/${selectedOrg}`);
    const tenantId = response.data.org.tenantId;
    const clientId = response.data.org.clientId;
    const encodedPlanType = encryptPlanType(response.data.org.BillingType);
    console.log(encodedPlanType, "type");
    localStorage.setItem("uniqueId", encodedPlanType);
    console.log(tenantId, "tenatid");
    console.log(clientId, "client");

    const msalConfig = {
      auth: {
        clientId: `${clientId}`,
        authority: `https://login.microsoftonline.com/${tenantId}`,
        redirectUri: "https://ems.finaran.com/",
      },
    };
    // Check if an interaction is already in progress
    if (!isInteractionInProgress) {
      setIsInteractionInProgress(true);
      const msalInstance = new msal.PublicClientApplication(msalConfig);
      const userLoginHint = email;
      try {
        const response = await msalInstance.loginPopup({
          scopes: [
            "openid",
            "profile",
            "user.read",
            "email",
            "Files.ReadWrite",
            "offline_access",
          ],
          loginHint: userLoginHint,
        });
        // Set the authentication token in local storage
        localStorage.setItem("accessToken", response.accessToken);

        // Call the Microsoft Graph API to get the user's job title
        const graphEndpoint =
          "https://graph.microsoft.com/v1.0/me?$select=id,displayName,givenName,surName,userPrincipalName,objectId,jobTitle,companyName,department,employeeHireDate,employeeOrgData,manager,streetAddress,city,employeeId,employeeType,state,postalCode,country,businessPhones,mobilePhone,mail,otherMails,proxyAddresses,faxNumber,mailNickname,imAddresses";
        const graphResponse = await fetch(graphEndpoint, {
          headers: {
            Authorization: `Bearer ${response.accessToken}`,
          },
        });
        const userData = await graphResponse.json();
        console.log(userData);
        // const jobTitle = userData.jobTitle;
        const displayName = userData.displayName;

        const userPrincipalName = userData.userPrincipalName.toLowerCase();
        localStorage.setItem("user", JSON.stringify(userData));
        localStorage.setItem("userJobEmail", userPrincipalName);
        localStorage.setItem("userType", "true");
        localStorage.setItem("domain", selectedOrg);

        console.log("useremail to fetch from master db", email);
        try {
          const response = await axios.get(
            `${url}/api/getmasteruser/${userPrincipalName}/method`
          );
          localStorage.setItem("dbName", response.data.user.dbName);

          const encryptedRole = encryptRole(response.data.user.jobTitle);
          localStorage.setItem("role", encryptedRole);
          console.log(
            "masterresp.data.user.userType",
            response.data.user.userType
          );
          console.log("masterresp.data.use.dbname", response.data.user.dbName);
        } catch (error) {
          console.error("Error fetching user details:", error);
        }

        const photoResponse = await fetch(
          "https://graph.microsoft.com/v1.0/me/photo/$value",
          { headers: { Authorization: `Bearer ${response.accessToken}` } }
        );
        const blob = await photoResponse.blob();
        const reader = new FileReader();

        reader.readAsDataURL(blob);

        reader.onloadend = () => {
          const base64data = reader.result;
          localStorage.setItem("profileImage", base64data);
        };

        if (response.accessToken) {
          const user = axios
            .post(`${url}/user/login`, {
              accessToken: response.accessToken,
              data: userData,
            })
            .then((response) => {
              console.log(response);
              history("/");
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          console.error(error);
        }
      } catch (error) {
        console.error(error);
        throw error;
      } finally {
        setIsInteractionInProgress(false); // Reset interaction status
      }
    }
  };
  const generateOTP = () => {
    return Math.floor(100000 + Math.random() * 900000).toString();
  };

  const generateRandomToken = () => {
    const randomString = Math.random()
      .toString(36)
      .substring(2);
    return randomString;
  };

  const Nativelogin = async (value, method) => {
    const mail = email;
    console.log("in nativelogin", method);

    // Perform your custom login logic here.
    const masterresp = await axios.get(
      `${url}/api/getmasteruser/${mail}/${method}`
    );
    console.log("masterresp", masterresp);
    const dbName = masterresp.data.user.dbName;
    const response = await axios.get(`${url}/api/masterorg/${dbName}`);
    const encodedPlanType = encryptPlanType(response.data.org.BillingType);
    console.log(encodedPlanType, "type");
    localStorage.setItem("uniqueId", encodedPlanType);

    let token;

    try {
      console.log("inside try");
      const mail = email.toLowerCase();

      if (value === true) {
        console.log(dbName, mail, password);
        const loginresponse = await axios.post(`${url}/api/login/${dbName}`, {
          mail,
          password,
        });

        console.log("loginresponse.data.token", loginresponse.data.token);

        if (loginresponse.data.success) {
          const user = axios.post(`${url}/user/login`, {
            accessToken: loginresponse.data.token,
          });

          localStorage.setItem("accessToken", loginresponse.data.token);
          const email = loginresponse.data.user.email;
        } else {
          setLoginStatus(
            <span style={{ color: "red" }}>
              Invalid password. Please try again.
            </span>
          );
          return;
        }
      } else if (value === false) {
        console.log("inside else");
        token = generateRandomToken();
        localStorage.setItem("accessToken", token);
      }

      try {
        const masterresp = await axios.get(
          `${url}/api/getmasteruser/${email}/${method}`
        );

        localStorage.setItem("userType", masterresp.data.user.userType);
        localStorage.setItem("dbName", masterresp.data.user.dbName);
        console.log(
          "masterresp.data.user.userType",
          masterresp.data.user.userType
        );
        console.log("masterresp.data.use.dbname", masterresp.data.user.dbName);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }

      console.log("about to call getuserby email with email", email);

      try {
        const dbName = localStorage.getItem("dbName");
        let mail;
        if (method === "email") {
          mail = email.toLowerCase();
        } else {
          mail = Mail;
        }
        const response = await axios.get(
          `${url}/api/getUserByEmail/${mail}/${dbName}`
        );
        const user = response.data.user;
        setGetUser(user);
        console.log("users in Homepage", response.data.user);
        localStorage.setItem("user", JSON.stringify(user));
        const encryptedRole = encryptRole(response.data.user.jobTitle);
        localStorage.setItem("role", encryptedRole);
        localStorage.setItem("profileImage", response.data.user.profileImage);
        localStorage.setItem("email", email);
        history("/home");
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
      setLoginStatus("Login successful!");
    } catch (error) {
      console.error("Error during login:", error);
      setLoginStatus(
        <span style={{ color: "red" }}>
          An error occurred during login. Please try again later.
        </span>
      );
    }
    setPassword("");
  };

  useEffect(() => {
    if (timer === 0) {
      setOtp("");
    }
  }, [timer]);

  const startTimer = () => {
    let id = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
      if (timer === 0) {
        clearInterval(id);
        setTimer(0);
      }
    }, 1000); // 1 second interval
    setIntervalId(id);
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
      }}
    >
      <Card
        style={{
          width: "135vh",
          // height: "77vh",
          background: "linear-gradient(to left, #ffffff 60%, #5271ff 100%)",
        }}
      >
        <Row>
          <Col span={10}>
            <img
              src={require("../../images/loginimage-removebg-preview.png")}
              style={{ width: "50vh", height: "50vh" }}
            />
            {/* <p className='text-center' style={{ color: "white",fontWeight:"bold",marginRight:"8vh" }}>Timesheet App</p> */}
          </Col>
          <Col span={14}>
            <h5
              style={{
                fontStyle: "italic",
                color: "#a8aced",
                textAlign: "center",
              }}
            >
              Your Gateway to Resources, Support, and Success!
            </h5>
            <Divider />
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                src={require("../../images/logo.png")}
                style={{ width: "8vh", height: "8vh" }}
              />
            </div>
            <h5
              style={{ fontWeight: "bold", color: "#5271ff" }}
              className="text-center"
            >
              Welcome To Finaran
            </h5>
            <Tabs
              defaultValue="1"
              className="custom-tabs"
              centered
              activeKey={activeKey}
              onChange={onTabChange}
            >
              <TabPane key="1" tab="Sign in with Password">
                <Form>
                  <Form.Item
                    label="Email"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 14 }}
                  >
                    <Input
                      ref={emailInputRef}
                      value={email}
                      size="small"
                      onChange={(event) => setEmail(event.target.value)}
                      style={{
                        height: "6vh",
                        fontSize: "14px",
                        paddingTop: "1.5vh", 
                        paddingBottom: "1.5vh",
                      }}
                      placeholder="Enter Email"
                      readOnly={!editEmail}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          event.preventDefault();
                          checkEmailValid();
                        }
                      }}
                    />
                  </Form.Item>
                  {!showPasswordInput && (
                    <Button
                      style={{
                        display: "block",
                        margin: "auto",
                        background: "#00134D",
                      }}
                      type="primary"
                      onClick={checkEmailValid}
                    >
                      Continue
                    </Button>
                  )}
                </Form>
                {terms && (
                  <Modal
                    title="Terms & Conditions"
                    visible={condition}
                    onCancel={() => setCondition(false)}
                    footer={[
                      <Button key="close" onClick={() => setCondition(false)}>
                        ok
                      </Button>,
                    ]}
                  >
                    <div style={{ maxHeight: "70vh", overflowY: "auto" }}>
                      <p
                        style={{
                          whiteSpace: "pre-wrap",
                          fontSize: "14px",
                          lineHeight: "1.6",
                        }}
                      >
                        {terms}
                      </p>
                    </div>
                    <Checkbox onChange={handleAcceptChange}>
                      I accept the terms and conditions
                    </Checkbox>
                  </Modal>
                )}
                {showPasswordInput && (
                  <Form onFinish={handleOk}>
                    <Form.Item
                      label="Password"
                      name="password"
                      labelCol={{ span: 6 }}
                      wrapperCol={{ span: 14 }}
                      rules={[
                        {
                          required: true,
                          message: "Please enter your Password!",
                        },
                      ]}
                    >
                      <Input.Password
                        type="Password"
                        size="small"
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                        ref={passwordInputRef}
                        onKeyDown={(event) => {
                          if (event.key === "Enter") {
                            handleOk(); // Trigger handleOk when Enter key is pressed
                          }
                        }}
                        style={{
                          borderColor:
                            loginStatus ===
                            "Invalid email or password. Please try again."
                              ? "red"
                              : "#ccc",
                          height: "6vh",
                          fontSize: "14px",
                          paddingTop: "0.7vh", 
                        }}
                      />
                    </Form.Item>

                    <span style={{ marginLeft: "6vh", fontSize: "12px" }}>
                      <Link onClick={showModal1}>Forgot Password</Link>
                    </span>
                    {loginStatus && <p>{loginStatus}</p>}
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        type="primary"
                        style={{ background: "#00134D" }}
                        htmlType="submit"
                      >
                        Continue
                      </Button>
                    </div>
                  </Form>
                )}
              </TabPane>
              <TabPane key="2" tab="Sign in with OTP">
                <Form>
                  <Form.Item
                    label="Email"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 14 }}
                  >
                    <Input
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                      style={{ height: "6vh", fontSize: "12px" }}
                      placeholder="Enter Email/Phone Number"
                    />
                  </Form.Item>
                  {!openOTPModal && (
                    <Form.Item wrapperCol={{ offset: 6, span: 14 }}>
                      <Space
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Button
                          onClick={(e) => handleOpenOTPModal(e, "email")}
                          style={{
                            background: "#00134D",
                            borderColor: "#00134D",
                            color: "#fff",
                          }}
                        >
                          <MailOutlined style={{ color: "#ffffff" }} />
                          Email
                        </Button>
                        <Button
                          onClick={(e) => handleOpenOTPModal(e, "mobile")}
                          style={{
                            background: "#00134D",
                            borderColor: "#00134D",
                            color: "#fff",
                          }}
                        >
                          <MessageOutlined style={{ color: "#ffffff" }} />
                          Mobile
                        </Button>
                        <Button
                          onClick={(e) => handleOpenOTPModal(e, "whatsapp")}
                          style={{
                            background: "#00134D",
                            borderColor: "#00134D",
                            color: "#fff",
                          }}
                        >
                          <WhatsAppOutlined style={{ color: "#25D366" }} />
                          WhatsApp
                        </Button>
                      </Space>
                    </Form.Item>
                  )}
                </Form>

                {openOTPModal && (
                  <>
                    <Form onFinish={handleOtpLogin}>
                      <Form.Item
                        label="Enter OTP"
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 14 }}
                      >
                        <Input
                          type="text"
                          value={otpValue}
                          onChange={(e) => setOtpValue(e.target.value)}
                          style={{ height: "6vh", fontSize: "12px" }}
                          placeholder="Enter OTP"
                          required
                          suffix={
                            timer > 0 ? (
                              `00:${timer.toString().padStart(2, "0")}`
                            ) : (
                              <p>
                                <Link
                                  onClick={(e) => {
                                    setTimer(60);
                                    handleOpenOTPModal(e, method);
                                  }}
                                >
                                  Resend OTP
                                </Link>
                              </p>
                            )
                          }
                        />
                      </Form.Item>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {/* {timer === 0 ? (
                          <Button
                            onClick={handleOpenOTPModal}
                            style={{
                              display: "block",
                              margin: "auto",
                              background: "#00134D",
                            }}
                            type="primary"
                          >
                            Resend OTP
                          </Button>
                        ) : ( */}
                        <Button
                          htmlType="submit"
                          style={{
                            display: "block",
                            margin: "auto",
                            background: "#00134D",
                          }}
                          type="primary"
                        >
                          Verify OTP
                        </Button>
                        {/* )} */}
                      </div>
                    </Form>
                  </>
                )}
              </TabPane>
            </Tabs>
            <Modal
              title="Reset Password"
              open={open1}
              onOk={(e) => handleResetPassword(e)}
              onCancel={handleCancelpassword}
              centered
              width={350}
            >
              <Form>
                <Form.Item label="Email">
                  <Input
                    type="email"
                    name="email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    style={{ height: "40px", width: "35vh" }}
                    disabled
                  />
                </Form.Item>

                {Message && <p>{Message}</p>}
              </Form>
            </Modal>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default Login;
