import React from 'react';
import { Card, Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import { UserOutlined, TeamOutlined,BookOutlined, CheckSquareOutlined, } from '@ant-design/icons';
import '../HR_home/hrhome.css';
import { decryptRole } from '../../encrypt';
import { TicketDiagonal16Filled } from '@fluentui/react-icons';


const SuperAdminHome = () => {
    const tileData = [
        { title: 'Manage Customers', link: '/home/masterorgview', icon: <UserOutlined /> },
        { title: 'Manage Users', link: '/home/masterusrview', icon: <TeamOutlined /> },
        { title: 'Manage Billing', link: '/home/BillingDetails', icon: <TeamOutlined /> },
        { title: 'Manage Sider', link: '/home/userrole', icon: <TeamOutlined /> },
        { title: 'Release Notes', link: '/home/releasenotes', icon: <BookOutlined /> },
        { title: 'Query Tickets', link: '/home/assignquery', icon: <TicketDiagonal16Filled /> },
        { title: 'Check List', link: '/home/userrole', icon: <CheckSquareOutlined /> },
    ];
    const colorData = localStorage.getItem("color");
    const encryptedRoleFromStorage = localStorage.getItem("role");
    const userRole = decryptRole(encryptedRoleFromStorage);
    console.log("userRole",userRole);
    
    return (
        <div className="site-card-wrapper" style={{zoom:"90%"}}>
            <Row gutter={[16, 16]}>
                {tileData.map((tile, index) => {
                    if (userRole === 'superAdmin' || (userRole === 'channelPartner' && ['Manage Customers', 'Manage Users','Manage Billing'].includes(tile.title))) {
                        return (
                            <Col xs={24} sm={12} md={8} lg={6} key={index}>
                                <Card
                                    className="tile-card"
                                    title={tile.icon}
                                    headStyle={{
                                        background: colorData,
                                        color: 'white',
                                        textAlign: 'center',
                                        display: 'flex',
                                    }}
                                    style={{
                                        background: 'white',
                                    }}
                                >
                                    <Link className="tile-link" to={tile.link} style={{ color: colorData }}>
                                        {tile.title}
                                    </Link>
                                </Card>
                            </Col>
                        );
                    } else {
                        return null; // If the tile should not be shown, return null
                    }
                })}
            </Row>
        </div>
    );
  
};



export default SuperAdminHome;
