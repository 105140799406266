import {
  Col,
  Form,
  Input,
  Row,
  Card,
  message,
  Button,
  Divider,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import { url } from "../../url/url";
import axios from "axios";
import { ArrowLeftOutlined, UploadOutlined } from "@ant-design/icons";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const Hrform = () => {
  const [form1] = Form.useForm();
  const [courses, setCourses] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [videos, setVideos] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [completionDate, setCompletionDate] = useState("");
  const users = JSON.parse(localStorage.getItem("user"));
  const [loading, setLoading] = useState(false);
  const [courseNameError, setCourseNameError] = useState(null);
  const navigate = useNavigate();

  const handleStartDateChange = (e) => {
      setStartDate(e.target.value);
  };

  const handleCompletionDateChange = (e) => {
    setCompletionDate(e.target.value);
  }

  const today = moment().format("YYYY-MM-DD");

  const handleCourseNameChange = (e) => {
      const courseName = e.target.value;
      const existingCourse = courses.find(
          (course) => course.courseName === courseName
      );
      if (existingCourse) {
          setCourseNameError("Course name already exists");
      } else {
          setCourseNameError(null);
      }
  };
  const handleDocumentChange = ({ fileList }) => {
      setDocuments((prevDocuments) => {
          // Map the new file list and filter out any undefined or duplicate files
          const newDocuments = fileList
              .filter(file => file.originFileObj)
              .map((file, index) => ({
                  uid: file.uid || index.toString(),
                  name: file.name,
                  status: "done",
                  file: file.originFileObj,
              }))
              .filter(newDoc => !prevDocuments.some(prevDoc => prevDoc.name === newDoc.name));

          // Return the accumulated documents
          return [...prevDocuments, ...newDocuments];
      });
  };



  const handleVideoChange = ({ fileList }) => {
      setVideos((prevVideos) => {
          // Map the new file list and filter out any undefined or duplicate files
          const newVideos = fileList
              .filter(file => file.originFileObj)
              .map((file, index) => ({
                  uid: file.uid || index.toString(),
                  name: file.name,
                  status: "done",
                  file: file.originFileObj,
              }))
              .filter(newVid => !prevVideos.some(prevVid => prevVid.name === newVid.name));

          // Return the accumulated videos
          return [...prevVideos, ...newVideos];
      });
  };


  const handleRemoveDocument = (file) => {
      const updatedDocuments = documents.filter((doc) => doc.uid !== file.uid);
      setDocuments(updatedDocuments);
  };

  const handleRemove = (file) => {
      // Filter out the file that needs to be removed
      const updatedVideos = videos.filter((video) => video.uid !== file.uid);
      setVideos(updatedVideos);
  };
  useEffect(() => {
      fetchCourses();
  }, []);

  const fetchCourses = async () => {
      const dbName = localStorage.getItem("dbName");
      try {
          const response = await axios.get(`${url}/get/courses/${dbName}`);
          setCourses(response.data);
      } catch (err) {
          console.log(err.message);
      }
  };
  const handleSubmit = async (values) => {
      const dbName = localStorage.getItem("dbName");

      // Create a new FormData object to handle file uploads
      const formData = new FormData();
      formData.append("courseName", values.courseName);
      formData.append("description", values.description);
      formData.append("startDate", values.startDate);
      formData.append("completionDate", values.completionDate);
      formData.append("category", values.category);
      formData.append("content", values.content);

      // Append documents correctly
      documents.forEach((doc) => {
          formData.append("document", doc.file);
      });

      // Append videos correctly
      videos.forEach((video) => {
          formData.append("video", video.file);
      });

      console.log(documents, "documents");

      try {
          const response = await axios.post(
              `${url}/api/courses/${dbName}`,
              formData,
              {
                  headers: {
                      "Content-Type": "multipart/form-data",
                  },
              }
          );

          form1.resetFields();
          setDocuments([]);
          setVideos([]);
          message.success("Course added successfully");
      } catch (error) {
          message.error("Failed to add course");
      }
  };



  const goBack = () => {
      navigate(-1);
  };

  return (
      <div>
          <Button type="text" onClick={goBack} icon={<ArrowLeftOutlined />} />
          <h5 className="text-center">Add Course</h5>
          <Divider />
          <Form form={form1} onFinish={handleSubmit}>
              <Row gutter={[16, 16]}>
                  <Col span={12}>
                      <Form.Item
                          label="Course Name"
                          name="courseName"
                          labelCol={{
                              span: 8,
                          }}
                          wrapperCol={{
                              span: 12,
                          }}
                          rules={[
                              { required: true, message: "Please enter the course name" },
                          ]}
                          validateStatus={courseNameError ? "error" : "success"}
                          help={courseNameError}
                      >
                          <Input
                              style={{ height: "6vh", fontSize: "14px" }}
                              placeholder="Enter Course Name"
                              onChange={handleCourseNameChange}
                              maxLength={20}
                          />
                      </Form.Item>
                  </Col>
                  <Col span={12}>
                      <Form.Item
                          label="Description"
                          name="description"
                          labelCol={{
                              span: 8,
                          }}
                          wrapperCol={{
                              span: 12,
                          }}
                          rules={[
                              { required: true, message: "Please enter the description" },
                          ]}
                      >
                          <Input
                              style={{ height: "6vh", fontSize: "14px" }}
                              placeholder="Enter Description"
                          />
                      </Form.Item>
                  </Col>
              </Row>
              <Row gutter={[16, 16]}>
                  <Col span={12}>
                      <Form.Item
                          label="Start Date"
                          name="startDate"
                          labelCol={{
                              span: 8,
                          }}
                          wrapperCol={{
                              span: 12,
                          }}
                          rules={[
                              { required: true, message: "Please enter the start date" },
                          ]}
                      >
                          <Input
                              style={{ height: "6vh", fontSize: "14px" }}
                              type="date"
                              min={today}
                              onChange={handleStartDateChange}
                          />
                      </Form.Item>
                  </Col>
                  <Col span={12}>
                      <Form.Item
                          label="End Date"
                          name="completionDate"
                          labelCol={{
                              span: 8,
                          }}
                          wrapperCol={{
                              span: 12,
                          }}
                          rules={[
                              { required: true, message: "Please enter the completion date" },
                          ]}
                      >
                          <Input
                              style={{ height: "6vh", fontSize: "14px" }}
                              type="date"
                              min={completionDate || today}
                              onChange={handleCompletionDateChange}
                          />
                      </Form.Item>
                  </Col>
              </Row>
              <Row gutter={[16, 16]}>
                  <Col span={12}>
                      <Form.Item
                          label="Category"
                          name="category"
                          labelCol={{
                              span: 8,
                          }}
                          wrapperCol={{
                              span: 12,
                          }}
                          rules={[{ required: true, message: "Please enter the category" }]}
                      >
                          <Input
                              style={{ height: "6vh", fontSize: "14px" }}
                              placeholder="Enter Category"
                          />
                      </Form.Item>
                  </Col>
                  <Col span={12}>
                      <Form.Item
                          label="URL"
                          name="content"
                          labelCol={{
                              span: 8,
                          }}
                          wrapperCol={{
                              span: 12,
                          }}
                          rules={[{ required: false, message: "Please enter the URL" }]}
                      >
                          <Input
                              style={{ height: "6vh", fontSize: "14px" }}
                              placeholder="Enter URL"
                          />
                      </Form.Item>
                  </Col>
              </Row>

              {/* Document Upload */}
              <Row gutter={[16, 16]}>
                  <Col span={12}>
                      <Form.Item
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 12 }}
                          label="Document"
                          name="document"
                      >
                          <Upload
                              multiple
                              beforeUpload={() => false}
                              onChange={handleDocumentChange}
                              onRemove={handleRemoveDocument}
                              fileList={documents.map((doc) => ({
                                  uid: doc.uid,
                                  name: doc.name,
                                  status: "done",
                              }))}
                          >
                              <Button icon={<UploadOutlined />}>Upload Documents</Button>
                          </Upload>
                      </Form.Item>
                  </Col>
              </Row>

              <Row gutter={[16, 16]}>
                  <Col span={12}>
                      <Form.Item
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 12 }}
                          label="Videos"
                          name="videos"
                      >
                          <Upload
                              multiple
                              beforeUpload={() => false}
                              onChange={handleVideoChange}
                              onRemove={handleRemove}
                              fileList={videos.map((video) => ({
                                  uid: video.uid,
                                  name: video.name,
                                  status: "done",
                              }))}
                          >
                              <Button icon={<UploadOutlined />}>Upload Videos</Button>
                          </Upload>
                      </Form.Item>
                  </Col>
              </Row>

              <Row gutter={[16, 16]}>
                  <Col span={12}>
                      <Button type="primary" style={{ left: "20vh" }} htmlType="submit">
                          Add Course
                      </Button>
                  </Col>
              </Row>
          </Form>
      </div>
  );
};

export default Hrform;
