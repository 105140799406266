import React, { useState } from 'react';

const ColorPicker = () => {
    const [selectedColor, setSelectedColor] = useState('#000000');
    const [selectedColor1, setSelectedColor1] = useState('#000000');

    const handleColorChange = (event) => {
        const colorValue = event.target.value;
        setSelectedColor(colorValue);
    };
    const handleColorChange1 = (event) => {
        const colorValue1 = event.target.value;
        setSelectedColor1(colorValue1);
    };

    const handleColorSubmit = () => {
        console.log(`Success: Color data ${selectedColor} submitted successfully!`);
    };
    const handleColorSubmit1 = () => {
        console.log(`Success- Color data for Header and Content: ${selectedColor1} submitted successfully!`);
    };

    return (
        <div>
            <div>
                <input type="color" value={selectedColor} onChange={handleColorChange} />
                <div style={{ marginTop: 16 }}>
                    Selected Color: <span style={{ color: selectedColor }}>{selectedColor}</span>
                </div>
                <button onClick={handleColorSubmit}>Submit</button>
            </div>
            <div>
                <input type="color" value={selectedColor1} onChange={handleColorChange1} />
                <div style={{ marginTop: 16 }}>
                    Selected Header and Content Color: <span style={{ color: selectedColor1 }}>{selectedColor1}</span>
                </div>
                <button onClick={handleColorSubmit1}>Submit</button>
            </div>
        </div>
    );
};

export default ColorPicker;
