import React, { useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { useParams } from 'react-router-dom';
import img from "./images/location.png";



const Map = () => {
  const { longitude, latitude } = useParams();
  const position = [parseFloat(latitude), parseFloat(longitude)];
  const customMarkerIcon = L.icon({
    iconUrl: img,
    iconSize: [40, 40],
    iconAnchor: [12, 41],
    popupAnchor: [0, -41],
  });

  

  return (
    <>
      <MapContainer center={position} zoom={14} style={{ position: 'relative', height: 'calc(100vh - 64px)', width: '100%' }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution="Map data © OpenStreetMap contributors"
        />
        <Marker position={position} icon={customMarkerIcon}>
          <Popup>
            Latitude: {latitude}, Longitude: {longitude}
          </Popup>
        </Marker>
      </MapContainer>
    </>
  );
}
export default Map;