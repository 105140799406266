import React, { useState, useEffect } from "react";
import {
  Tabs,
  Table,
  Input,
  Button,
  message,
  Modal,
  DatePicker,
  Form,
  Select,
} from "antd";
import axios from "axios";
import { url } from "../../url/url";
import { decryptRole } from "../../encrypt";
import Highlighter from "react-highlight-words";
import moment from "moment";
import {
  SearchOutlined,
  DownloadOutlined,
  MailOutlined,
  UploadOutlined,
  UndoOutlined,
} from "@ant-design/icons";

const { TabPane } = Tabs;
const { Option } = Select;
const BillingDetails = () => {
  const [data, setData] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = React.useRef(null);
  const users = JSON.parse(localStorage.getItem("user"));
  const [updateModalVisible, setUpdateModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchData();
  }, []);

  const handleProcessInvoices = async () => {
    try {
      console.log("inside ");
      const process = await axios.post(`${url}/api/process-invoices`);
      message.success("Invoices processed successfully");
    } catch (error) {
      console.error("Error processing invoices:", error);
      message.error("Failed to process invoices");
    }
  };
  
  const fetchData = async () => {
    try {
      const encryptedRoleFromStorage = localStorage.getItem("role");
      const role = decryptRole(encryptedRoleFromStorage);
      const channelName = users.firstName;
      const [orgsResponse, usersResponse] = await Promise.all([
        axios.get(`${url}/api/master-orgs/${role}`, {
          params: { channelName },
        }),
        axios.get(`${url}/api/user-count/${channelName}`),
      ]);
      setTotalUsers(usersResponse.data.totalUsers);
      // Flatten the billing details
      const flattenedData = orgsResponse.data.flatMap((org) =>
        org.billingDetails.map((detail) => ({
          ...detail,
          orgName: org.orgName,
          channelName: org.channelName,
          channel: org.channel,
          userCount: usersResponse.data.totalUsers,
          UsersAllowed: org.UsersAllowed,
          sellingPricePerLicense: org.sellingPricePerLicense,
          commissionPercentage: org.commissionPercentage,
          currency: org.currency,
        }))
      );

      setData(flattenedData);
    } catch (error) {
      console.error("There was an error fetching data:", error);
    }
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const handleSendEmail = async (org) => {
    try {
      const pdfData = org.pdf; // Assuming the PDF data is stored in the 'pdf' field of the record
      const pdfBlob = new Blob([pdfData], { type: "application/pdf" });
      const pdfUrl = URL.createObjectURL(pdfBlob);
      const pdfFileName = "invoice.pdf";

      const emailContent = "Please find attached the invoice for this month.";

      // Send email with attached PDF
      const options = {
        to: org.companyemail,
        subject: "Invoice",
        body: emailContent,
        attachments: [
          {
            fileName: pdfFileName,
            content: pdfUrl,
            contentType: "application/pdf",
          },
        ],
      };

      // Call your email sending API with mailOptions
      // For example:
      const mailTriggerResponse = await axios.post(
        `${url}/api/send-email`,
        options
      );

      message.success("Email sent successfully");
    } catch (error) {
      console.error("Error sending email:", error);
      message.error("Failed to send email");
    }
  };

  const handleUpdatePayment = (record) => {
    setUpdateModalVisible(true);
    setSelectedRecord(record);
    form.setFieldsValue({
      paymentDate: record.paymentDate ? moment(record.paymentDate) : null,
      paymentStatus: record.paymentStatus || "Pending",
    });
  };

  const handleRevertPayment = async (record) => {
    try {
      await axios.post(`${url}/api/update-invoice`, {
        ...record,
        orgName: record.orgName,
        paymentStatus: "Pending",
      });

      message.success("Payment reverted successfully");
      await fetchData();
    } catch (error) {
      console.error("Error reverting payment:", error);
      message.error("Failed to revert payment");
    }
  };

  const handleUpdate = async () => {
    try {
      const updatedValues = await form.validateFields();
      const updatedRecord = { ...selectedRecord, ...updatedValues };
      const paymentDate = updatedValues.paymentDate
        ? updatedValues.paymentDate.format("YYYY-MM-DD")
        : null;
      const paymentStatus = updatedValues.paymentStatus
        ? updatedValues.paymentStatus
        : "";
      // Call API to update the record
      await axios.post(`${url}/api/update-invoice`, {
        ...updatedRecord,
        orgName: selectedRecord.orgName,
        paymentDate,
        paymentStatus,
      });
      message.success("Payment details updated successfully");
      await fetchData();
      setUpdateModalVisible(false);
      setSelectedRecord(null);
      form.resetFields();
    } catch (error) {
      console.error("Error updating payment details:", error);
      message.error("Failed to update payment details");
    }
  };

  const handleCancel = () => {
    setUpdateModalVisible(false);
    setSelectedRecord(null);
    form.resetFields();
  };

  const columns = [
    {
      title: "Invoice ID",
      dataIndex: "invoiceId",
      key: "invoiceId",
      ...getColumnSearchProps("invoiceId"),
      width: 190,
    },
    {
      title: "Invoice Date",
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      ...getColumnSearchProps("invoiceDate"),
      width: 200,
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      key: "dueDate",
      ...getColumnSearchProps("dueDate"),
      width: 160,
      render: (dueDate) => (
        <span>{new Date(dueDate).toLocaleDateString("en-GB")}</span>
      ),
    },
    {
      title: "Billing Period Start",
      dataIndex: "billingStartDate",
      key: "billingStartDate",
      ...getColumnSearchProps("billingStartDate"),
      width: 160,
      render: (billingStartDate) => (
        <span>{new Date(billingStartDate).toLocaleDateString("en-GB")}</span>
      ),
    },
    {
      title: "Billing Period End",
      dataIndex: "billingEndDate",
      key: "billingEndDate",
      ...getColumnSearchProps("billingEndDate"),
      width: 160,
      render: (billingEndDate) => (
        <span>{new Date(billingEndDate).toLocaleDateString("en-GB")}</span>
      ),
    },

    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
      ...getColumnSearchProps("currency"),
      width: 160,
    },
    {
      title: "Organization Name",
      dataIndex: "orgName",
      key: "orgName",
      ...getColumnSearchProps("orgName"),
      width: 160,
    },
    {
      title: "Channel Name",
      dataIndex: "channelName",
      key: "channelName",
      ...getColumnSearchProps("channelName"),
      width: 160,
    },
    {
      title: "Quantity",
      dataIndex: "UsersAllowed",
      key: "UsersAllowed",
      ...getColumnSearchProps("UsersAllowed"),
      width: 160,
    },
    {
      title: "Unit Price",
      dataIndex: "sellingPricePerLicense",
      key: "sellingPricePerLicense",
      ...getColumnSearchProps("sellingPricePerLicense"),
      width: 160,
    },
    {
      title: "Commission Percent",
      dataIndex: "commissionPercentage",
      key: "commissionPercentage",
      ...getColumnSearchProps("commissionPercentage"),
      width: 160,
    },
    {
      title: "Total Amount",
      dataIndex: "amount",
      key: "amount",
      ...getColumnSearchProps("amount"),
      width: 160,
      render: (amount) => (
        <span>
          {new Intl.NumberFormat({ style: "currency" }).format(amount)}
        </span>
      ),
    },

    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      ...getColumnSearchProps("paymentStatus"),
      width: 160,
    },
    {
      title: "Payment Date",
      dataIndex: "paymentDate",
      key: "paymentDate",
      ...getColumnSearchProps("paymentDate"),
      width: 160,
    },
    {
      title: "PDF",
      dataIndex: "pdf",
      key: "pdf",
      render: (pdf) =>
        pdf ? (
          <a href={`data:application/pdf;base64,${pdf}`} download="invoice.pdf">
            <DownloadOutlined /> PDF
          </a>
        ) : (
          "No PDF"
        ),
    },
    {
      title: "Update Payment",
      dataIndex: "updatePayment",
      key: "updatePayment",
      render: (text, record) => {
        if (record.paymentStatus === "Done") {
          return (
            <Button type="primary" onClick={() => handleRevertPayment(record)}>
              <UndoOutlined />
            </Button>
          );
        } else {
          return (
            <Button type="primary" onClick={() => handleUpdatePayment(record)}>
              <UploadOutlined />
            </Button>
          );
        }
      },
    },

    {
      title: "Send Email",
      dataIndex: "sendEmail",
      key: "sendEmail",
      render: (text, record) => (
        <Button
          type="primary"
          icon={<MailOutlined />}
          onClick={() => handleSendEmail(record)}
        ></Button>
      ),
    },
  ];
  // Organize data by channel
  const directChannelData = data.filter((item) => item.channel === "Direct");
  const partnerChannelData = data.filter((item) => item.channel === "Partner");
  const filteredDirectChannelData = directChannelData.filter(
    (item) => item.channelName === users.firstName
  );

  return users.jobTitle === "channelPartner" ? (
    <Table
      columns={columns}
      dataSource={filteredDirectChannelData}
      rowKey={(record) => record._id}
      bordered
      scroll={{ x: "max-content" }}
      style={{ boxShadow: "0px 0px 10px rgba(0,0,0,0.1)", zoom: "90%" }}
      title={() => <h2>Billing Details</h2>}
      className="custom-table col-lg-12 col-md-12 col-sm-12"
      sticky
    />
  ) : (
    <Tabs defaultActiveKey="1">
      {users.jobTitle === "superAdmin" && (
        <Button
          type="primary"
          onClick={() => handleProcessInvoices()}
          style={{ marginRight: 10 }}
        >
          Generate Invoice
        </Button>
      )}
      <TabPane tab="Direct" key="direct">
        <Table
          columns={columns}
          dataSource={directChannelData}
          rowKey={(record) => record._id}
          bordered
          scroll={{ x: "max-content" }}
          style={{ boxShadow: "0px 0px 10px rgba(0,0,0,0.1)", zoom: "90%" }}
          title={() => <h2>Billing Details -Direct</h2>}
          className="custom-table col-lg-12 col-md-12 col-sm-12"
          sticky
        />
        <Modal
          title="Update Payment Details"
          visible={updateModalVisible}
          onCancel={handleCancel}
          onOk={handleUpdate}
          okText="Update"
        >
          <Form form={form} layout="vertical">
            <Form.Item
              name="paymentDate"
              label="Payment Date"
              rules={[
                { required: true, message: "Please select payment date" },
              ]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              name="paymentStatus"
              label="Payment Status"
              rules={[
                { required: true, message: "Please select payment status" },
              ]}
            >
              <Select>
                <Option value="Done">Done</Option>
                <Option value="Pending">Pending</Option>
              </Select>
            </Form.Item>
          </Form>
        </Modal>
      </TabPane>
      <TabPane tab="Partner" key="partner">
        <Table
          columns={columns}
          dataSource={partnerChannelData}
          rowKey={(record) => record._id}
          bordered
          scroll={{ x: "max-content" }}
          style={{ boxShadow: "0px 0px 10px rgba(0,0,0,0.1)", zoom: "90%" }}
          title={() => <h2>Billing Details -Partner</h2>}
          className="custom-table col-lg-12 col-md-12 col-sm-12"
          sticky
        />
      </TabPane>
    </Tabs>
  );
};

export default BillingDetails;
