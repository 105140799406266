import React from "react";
import "./employee/EmpDetails2.css";
//import axios from 'axios';
import {
  MailTwoTone,
  CalendarTwoTone,
  SlidersTwoTone,
  FileWordTwoTone,
  PhoneTwoTone,
  ContactsTwoTone,
  CloseOutlined,
} from "@ant-design/icons";
//import { useState } from "react";
//import { url } from '../url/url';
import { Link } from "react-router-dom";
import { Avatar } from "antd";

const Profile = () => {
  //  const [data, setData] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const nameInitials = `${user.firstName.charAt(0).toUpperCase()}${user.lastName
    .charAt(0)
    .toUpperCase()}`;
  const profileImage = localStorage.getItem("profileImage");
  return (
    <div style={{ zoom: "90%" }}>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Link to="/home">
          <div
            className="icon-container"
            style={{
              fontSize: "23px",
              color: "black",
              transition: "background-color 0.2s",
            }}
          >
            <CloseOutlined className="close-icon" />
          </div>
        </Link>
      </div>
      <div className="left-container">
        <div className="maindiv">
          <h2 className="text-center p-3">PROFILE</h2>
          <div className="row">
            <div className="col-md-6">
              <div>
                <div className="text-center">
                  {profileImage ? ( // Check if the user has a profile image
                    <Avatar
                      size="large"
                      src={user.profileImage}
                      alt={user.displayName}
                      className="profile mb-3"
                    />
                  ) : (
                    <Avatar
                      size="large"
                      style={{
                        backgroundColor: "#fde3cf",
                        color: "#f56a00",
                      }}
                    >
                      {nameInitials}
                    </Avatar>
                  )}
                  <h2 className="fw-bold text-uppercase ">
                    {user.displayName}
                  </h2>
                  <h6 className="fw-bold text-start">BASIC INFO</h6>
                </div>
                <div className="p-3">
                  <p>
                    <ContactsTwoTone />
                    <b> Employee Code:</b> {user.empCode}
                  </p>
                  <p>
                    <SlidersTwoTone />
                    <b> Designation:</b> {user.empDesignation}{" "}
                  </p>
                  <p>
                    <MailTwoTone />
                    <b> Company Email:</b> {user.email}
                  </p>
                  <p>
                    <SlidersTwoTone />
                    <b>Reporting Manager Name:</b> {user.manager}
                  </p>
                  <p>
                    <FileWordTwoTone />
                    <b>HR Name:</b> {user.hrName}
                  </p>
                  <p>
                    <PhoneTwoTone />
                    <b> Mobile:</b> {user.phoneNumber}
                  </p>
                  <p>
                    <FileWordTwoTone />
                    <b> JobTitle:</b> {user.jobTitle}
                  </p>
                  <p>
                    <CalendarTwoTone />
                    <b> Date-of-Joining:</b> {user.joinDate}
                  </p>
                  <p>
                    <CalendarTwoTone />
                    <b> Company Laptop:</b> {user.companyLaptop}
                  </p>
                  <p>
                    <CalendarTwoTone />
                    <b> Laptop Serial Number:</b> {user.laptopSerial}
                  </p>
                  <p>
                    <CalendarTwoTone />
                    <b> Laptop Warranty Date:</b> {user.laptopWarranty}
                  </p>
                  <p>
                    <CalendarTwoTone />
                    <b> Accessories:</b>{" "}
                    {user.accessaries ? user.accessaries.join(", ") : ""}
                  </p>
                </div>
              </div>

              <div className="text-start mb-4">
                <p className="text-break"> </p>
              </div>
            </div>

            <div className="col-md-6">
              <h6 className="text-center m-4">
                <b>PERSONAL INFORMATION</b>
              </h6>
              <div className="mb-4">
                <p>
                  <b>Date of Birth:</b> {user.dob}
                </p>
                <p>
                  <b>Gender:</b> {user.gender}
                </p>
                <p>
                  <b>Personal Email:</b> {user.personalemail}
                </p>

                <p className="text-break">
                  <b>Address:</b> {user.address}
                </p>
                <p>
                  <b> Mobile:</b> {user.phoneNumber}
                </p>
                <p>
                  <b>Alternate Mobile Number:</b> {user.alternateMobile}
                </p>
                <p>
                  <b>Blood Group:</b> {user.bloodGroup}
                </p>
                <p>
                  <b>Employee Dependent:</b>{" "}
                  {user.empDependents ? user.empDependents.join(", ") : ""}
                </p>
              </div>

              <div className="mb-4">
                <h6 className="text-center m-4">
                  <b>BANK DETAILS</b>
                </h6>
                <div className="mb-4">
                  <p>
                    <b>Account Number:</b> {user.accountNumber}
                  </p>
                  <p>
                    <b>Account Holder Name:</b> {user.holderName}
                  </p>
                  <p>
                    <b>IFSC Code:</b> {user.ifscCode}
                  </p>
                  <p>
                    <b>Bank Name:</b> {user.bankName}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
