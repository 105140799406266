import React, { useState, useMemo, useEffect } from "react";
import { Select, Table, Button, message, Spin, Modal } from "antd";
import { url } from "../../url/url";
import axios from "axios";
import Papa from "papaparse";
import FileSaver from "file-saver";
const { Option } = Select;

const Totaltask = () => {
  const currentYear = new Date().getFullYear();
  const lastYear = currentYear - 1;
  const currentMonth = new Date().getMonth() + 1;
  const users = JSON.parse(localStorage.getItem("user"));
  const [user] = useState(users);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [showAllReports, setShowAllReports] = useState(false);
  const [extractReport, setExtractReport] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [Columns, setColumns] = useState([]);
  const [visible, setVisible] = useState(false);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [total, setTotal] = useState(tableData.length);

  const onPageChange = (newCurrent, newPageSize) => {
    setCurrent(newCurrent);
    setPageSize(newPageSize);
  };

  useEffect(() => {}, [showAllReports]);


  useEffect(() => {
    fetchTable();
  }, []);

  const handleYearChange = (value) => {
    setSelectedYear(parseInt(value));
    setExtractReport(false);
  };

  const handleMonthChange = (value) => {
    setTableData([]);
    setSelectedMonth(parseInt(value));
    setExtractReport(false);
  };

  const handleExtractReport = () => {
    setExtractReport(true); // Step 3
  };

  const fetchTable = async () => {
    try {
      setLoading(true);
      if (!showAllReports) {
        const dbName = localStorage.getItem("dbName");
        const response = await fetch(
          `${url}/timesheet/filteredmonth/${user.displayName}/manager/${dbName}/${selectedMonth}/${selectedYear}`
        );
        const table = await response.json();
        const changeData = filterDataToChange(table);
        setTableData(changeData);
        setLoading(false);
      } else if(showAllReports && user.jobTitle === "hr") {
        console.log(selectedMonth, selectedYear, "called");
        const dbName = localStorage.getItem("dbName");
        const response = await fetch(
          `${url}/timesheet/filteredmonth/${user.displayName}/hr/${dbName}/${selectedMonth}/${selectedYear}`
        );
        const table = await response.json();
        console.log(table, "table");
        const changeData = filterDataToChange(table);
        setTableData(changeData);
        setLoading(false);
      } else if(showAllReports && user.jobTitle === "admin") {
        console.log(selectedMonth, selectedYear, "called");
        const dbName = localStorage.getItem("dbName");
        const response = await fetch(
          `${url}/timesheet/filteredmonth/${user.displayName}/admin/${dbName}/${selectedMonth}/${selectedYear}`
        );
        const table = await response.json();
        console.log(table, "table");
        const changeData = filterDataToChange(table);
        setTableData(changeData);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      message.error("Failed to fetch data from the server.");
    }
  };
  const filteredData = useMemo(() => {
    return tableData.filter((data) => {
      const weekStartingDate = new Date(data.weekStartingDate);
      return (
        weekStartingDate.getFullYear() === selectedYear &&
        weekStartingDate.getMonth() === selectedMonth - 1
      );
    });
  }, [selectedYear, selectedMonth, tableData]);

  const filterDataToChange = (dataEntry) => {
    const result = dataEntry.reduce((acc, entry) => {
      const { empCode, userName, entrydata } = entry;
      let empEntry = acc.find((item) => item.empCode === empCode);

      if (!empEntry) {
        empEntry = { empCode, userName, projectdata: [] };
        acc.push(empEntry);
      }

      entrydata.forEach((projectMap) => {
        const { project, task } = projectMap;

        // Add a condition to exclude tasks like 'holiday', 'leave', 'permission'
        if (task !== "Holiday" && task !== "Leave" && task !== "Permission") {
          let projectEntry = empEntry.projectdata.find(
            (proj) => proj.project === project
          );

          if (!projectEntry) {
            projectEntry = {
              empCode,
              userName,
              project,
              task: "", // Empty task for the aggregated project entry
            };
            empEntry.projectdata.push(projectEntry);
          }

          Object.keys(projectMap)
            .filter((key) => key.startsWith("day"))
            .forEach((dayKey) => {
              const date = projectMap[dayKey].date;
              const hours = projectMap[dayKey].hours;
              projectEntry[date] = (projectEntry[date] || 0) + hours;
            });
        }
      });

      return acc;
    }, []);

    return result.flatMap((item) => item.projectdata);
  };

  const toggleShowAllReports = () => {
    setShowAllReports(!showAllReports);
  };

  const generateColumns = () => {
    const startDay = 1;
    const endDay = new Date(selectedYear, selectedMonth, 0).getDate();
    const uniqueEmpCodes = Array.from(
      new Set(tableData.map((record) => record.empCode))
    );
    const uniqueEmpNames = Array.from(
      new Set(tableData.map((record) => record.userName))
    );
    const uniquePrj = Array.from(
      new Set(tableData.map((record) => record.project))
    );
    const uniqueTask = Array.from(
      new Set(tableData.map((record) => record.task))
    );

    const columns = [
      {
        title: "Employee Name",
        dataIndex: "userName",
        key: "userName",
        align: "center",
        width: 200,
        fixed: "left",
        filters: uniqueEmpNames.map((userName) => ({
          text: userName,
          value: userName,
        })),
        onFilter: (value, record) => record.userName === value,
        sorter: (a, b) => a.userName.localeCompare(b.userName),
      },
      {
        title: "Employee ID",
        dataIndex: "empCode",
        key: "empCode",
        width: 200,
        align: "center",
        filters: uniqueEmpCodes.map((empCode) => ({
          text: empCode,
          value: empCode,
        })),
        onFilter: (value, record) => record.empCode === value,
      },

      {
        title: "Project",
        dataIndex: "project",
        key: "project",
        width: 200,
        align: "center",
        filters: uniquePrj.map((project) => ({
          text: project,
          value: project,
        })),
        onFilter: (value, record) => record.project === value,
      },
    ];

    for (let day = startDay; day <= endDay; day++) {
      const date = `${day.toString().padStart(2, "0")}-${selectedMonth
        ?.toString()
        .padStart(2, "0")}-${selectedYear}`;
        
      columns.push({
        title: date,
        dataIndex: date,
        key: date,
        width: 200,
        align: "center",
        render: (value) => (
          <p style={{ textAlign: "center", margin: 0 }}>
            {value === undefined ? 0 : String(value)}
          </p>
        ),
      });
    }
    columns.push({
      title: "Total",
      key: "total",
      width: 200,
      align: "center",
      render: (text, record) => calculateTotalHours(record),
    });

    return columns;
  };

  const calculateTotalHours = (record) => {
    const keysToExclude = ["empCode", "project", "task"];
    console.log(record, "record");
    const sum = Object.entries(record)
      .filter(([key]) => !keysToExclude.includes(key))
      .filter(([key]) => {
        const [day, month, year] = key.split("-").map(Number);
        console.log(month, year, "year");

        return month === selectedMonth && year === selectedYear;
      })
      .reduce((acc, [, value]) => acc + value, 0);
    console.log(sum, "sum");

    return sum;
  };

  const generateYearMonthOptions = () => {
    const data = Array.from({ length: 12 }, (_, i) => {
      const month = (i + 1).toString().padStart(2, "0");
      const date = new Date(currentYear, i, 1);
      const monthYearString = date.toLocaleDateString("en-US", {
        month: "short",
      });
      return (
        <Option key={month} value={month}>
          {monthYearString}
        </Option>
      );
    });
    return data;
  };

  const generateYearOptions = () => {
    return [currentYear, lastYear].map((year) => (
      <Option key={year} value={year.toString()}>
        {year}
      </Option>
    ));
  };

  function handleClick() {
    setVisible(true);
    setColumns(Object.keys(tableData[0]));
  }

  const handleok = () => {
    const employeeDataForExport = tableData.map((record) => {
      const dataToExport = {};

      // Include static columns
      selectedColumns.forEach((col) => {
        dataToExport[col] = record[col];
      });

      // Include dynamically generated date columns
      const startDay = 1;
      const endDay = new Date(selectedYear, selectedMonth, 0).getDate();
      for (let day = startDay; day <= endDay; day++) {
        const date = `${day.toString().padStart(2, "0")}-${selectedMonth
          ?.toString()
          .padStart(2, "0")}-${selectedYear}`;
        dataToExport[date] = record[date];
      }

      return dataToExport;
    });

    const csv = Papa.unparse(employeeDataForExport);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    FileSaver.saveAs(blob, "Monthly Timesheet Report.csv");
    setVisible(false); // Close the modal after exporting
    setSelectedColumns([]); // Clear selected columns
  };

  function handleCancel() {
    setVisible(false);
    setSelectedColumns([]);
  }

  function handleColumnSelect(value) {
    setSelectedColumns(value);
  }

  return (
    <div style={{ transform: "scale(0.9)", transformOrigin: "0 0", width: "110%"}}>
      {loading ? (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginRight: "16px" }}>
              <Select
                style={{ width: 150 }}
                value={selectedYear}
                onChange={handleYearChange}
              >
                {generateYearOptions()}
              </Select>
            </div>
            <div style={{ marginRight: "16px" }}>
              <Select style={{ width: 150 }} onChange={handleMonthChange}>
                {generateYearMonthOptions()}
              </Select>
            </div>
            <Button type="primary" onClick={fetchTable}>
              Extract Time Report
            </Button>
          </div>
          {/* <Button
              type="primary"
              onClick={handleClick}
              style={{ margin: "10px" }}
            >
              Export to CSV
            </Button> */}
          <Modal
            title="Export to CSV"
            visible={visible}
            onOk={handleok}
            onCancel={handleCancel}
            okButtonProps={{ disabled: selectedColumns.length === 0 }}
          >
            <p>Select columns to export:</p>
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="Please select"
              value={selectedColumns}
              onChange={handleColumnSelect}
            >
              {tableData.map((col) => (
                <Option key={col} value={col}>
                  {col}
                </Option>
              ))}
            </Select>
          </Modal>
          <div>
            <label
              style={{
                display: "flex",
                justifyContent: "flex-end",
                fontSize: "14px",
              }}
            >
              <input
                type="checkbox"
                checked={showAllReports}
                onChange={toggleShowAllReports}
              />
              Show All Employee
            </label>
          </div>
          <Table
            className="custom-table col-lg-12 col-md-12 col-sm-12"
            dataSource={tableData ? tableData : []}
            columns={tableData.length > 0 ? generateColumns() : []}
            sticky
            pagination={{
              current: current,
              pageSize: pageSize,
              total: total,
              onChange: onPageChange,
            }}
            scroll={{ x: "max-content" }}
          />
        </>
      )}
    </div>
  );
};

export default Totaltask;
