import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import ApexCharts from "react-apexcharts";
import dayGridPlugin from "@fullcalendar/daygrid";
import axios from "axios";
import { Button, Divider, Tooltip } from "antd";
import { url } from "../url/url";
import "./dashboard.css";
import { Card, Row, Col, Alert, Avatar, Progress, Form, Calendar } from "antd";
import {
  FileImageOutlined,
  UsergroupDeleteOutlined,
  NotificationOutlined,
  CalendarOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import "./empdash.css";
import { logDOM } from "@testing-library/react";
import Timer from "../pages/timer";

import { Link } from "react-router-dom";

const { Meta } = Card;
const Empdash = (props) => {
  const [users, setUsers] = useState([]);
  const [leave, setLeave] = useState([]);
  const [location, setlocation] = useState([]);
  const [user] = useState(props.user);
  const [List, setList] = useState([]);
  const users1 = JSON.parse(localStorage.getItem("user"));

  const [balance1, setAnnualbalance] = useState();
  const [balance2, setCasualbalance] = useState();
  const [balance3, setCompoffbalance] = useState();
  const [projects, setProjects] = useState([]);
  const [projData, setProjData] = useState([]);
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [data, setdata] = useState();
  const locationCheck = localStorage.getItem("switchState");
  const [jobTitle, setJobTitle] = useState([]);
  const [managerInfo, setManagerInfo] = useState([]);
  const [empCode, setempCode] = useState([]);
  const [pdfFiles, setPdfFiles] = useState([]);
  const [loading, setLoading] = useState([]);
  const [checkedIn, setCheckedIn] = useState(false);
  const [checkInTime, setCheckInTime] = useState(null);
  const [checkOutTime, setCheckOutTime] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [tasks, setTasks] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [check, setCheck] = useState(null);
  const [disablecheckIn, setDisableCheckIn] = useState(false);
  const [disablecheckOut, setDisableCheckOut] = useState(false);
  const [count, setCount] = useState(0);

  useEffect(() => {
    // Function to fetch check-in and check-out times for the current date
    const fetchCheckInOutData = async () => {
      const dbName = localStorage.getItem("dbName");
      const currentDate = new Date().toISOString().slice(0, 10); // Get current date in YYYY-MM-DD format

      try {
        const response = await fetch(
          `${url}/api/checkinout/${dbName}/${currentDate}/${user.displayName}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch check-in/out data");
        }
        const data = await response.json();
        console.log(data.checkInOutEntries, "dat");
        setCheck(data.checkInOutEntries);

        // Check if data exists for the current date and user
        if (data.checkInOutEntries[0].checkInTime) {
          setDisableCheckIn(true);
        } else {
          setDisableCheckIn(false);
        }
        if (data.checkInOutEntries[0].checkOutTime) {
          setDisableCheckOut(true);
        } else {
          setDisableCheckOut(false);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchCheckInOutData(); // Call the function on component mount
  }, [count]);

  const conicColors = {
    "0%": "#87d068",
    "50%": "#ffe58f",
    "100%": "#ffccc7",
  };
  const visibleTasks = showAll ? tasks : tasks.slice(0, 4);
  const renderTask = (task, index) => {
    const actualHours = task.weekId.reduce(
      (total, week) => total + week.actualHours,
      0
    );
    const estimatedHours = parseInt(task.estimatedHours, 10);
    let progress = 0;

    if (actualHours >= estimatedHours) {
      // If actual hours exceed or equal estimated hours, progress is 100%
      progress = 100;
    } else {
      // If actual hours are less than estimated hours, calculate progress percentage
      progress = (actualHours / estimatedHours) * 100;
    }

    // Determine color based on comparison of actual and estimated hours
    const colorStyle =
      actualHours > estimatedHours ? { color: "red" } : { color: "green" };

    return (
      <Row
        key={index}
        style={{ fontSize: "20px", justifyContent: "space-between" }}
      >
        <Col span={1}>{index + 1}</Col>
        <Col
          span={8}
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          <Tooltip title={task.activityName}>{task.activityName}</Tooltip>
        </Col>
        <Col span={10}>
          <Progress
            style={colorStyle}
            percent={progress}
            size="small"
            format={(percent) => `${actualHours}/${estimatedHours} hours`}
            strokeColor={actualHours > estimatedHours ? "#ff4d4f" : "#87d068"}
            status="active"
          />
        </Col>
        <Col span={4} style={{ display: "flex", justifyContent: "flex-end" }}>
          <Avatar
            style={{
              backgroundColor: getPriorityColor(task.priority),
              color: "black",
              fontSize: "16px",
            }}
            size="large"
          >
            {task.priority.substring(0, 1).toUpperCase()}
          </Avatar>
        </Col>
        <Divider />
      </Row>
    );
  };

  useEffect(() => {
    const fetchTasks = async () => {
      const dbName = localStorage.getItem("dbName");
      const assignee = users1.displayName;
      try {
        const response = await axios.get(
          `${url}/get/tasks/${dbName}/${assignee}`
        );
        setTasks(response.data);
        console.log(response.data, "datata");
      } catch (error) {
        console.error("Error fetching tasks:", error.message);
      }
    };

    fetchTasks();
  }, []);

  const handleCheckIn = async () => {
    // setCheckedIn(true);
    const currentDate = new Date();
    setCheckInTime(currentDate);
    setCheckOutTime(null);
    await postCheckInData(currentDate);
  };

  const handleCheckOut = async () => {
    const currentDate = new Date();
    setCheckOutTime(currentDate);
    await postCheckOutData(currentDate);
  };

  const postCheckInData = async (checkInTime) => {
    const formattedCheckInTime = formatDateTime(checkInTime);
    const postData = {
      userName: users1.displayName,
      checkInTime: formattedCheckInTime,
    };
    const dbName = localStorage.getItem("dbName");
    console.log(postData, "post");
    try {
      const response = await fetch(`${url}/api/checkin/${dbName}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
      });
      if (!response.ok) {
        throw new Error("Failed to save check-in time");
      }
      const data = await response.json();
      console.log(data.message); // Log success message
      setCount(count + 1);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const postCheckOutData = async (checkOutTime) => {
    const formattedCheckOutTime = formatDateTime(checkOutTime);
    const postData = {
      userName: users1.displayName,
      checkOutTime: formattedCheckOutTime,
    };
    const dbName = localStorage.getItem("dbName");
    try {
      const response = await fetch(`${url}/api/checkout/${dbName}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
      });
      if (!response.ok) {
        throw new Error("Failed to save check-out time");
      }
      const data = await response.json();
      console.log(data.message); // Log success message
      setCount(count + 1);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const formatTime = (time) => {
    if (!(time instanceof Date)) {
      return "Invalid Time"; // Or handle the case appropriately
    }

    const hours = time.getHours();
    const minutes = time.getMinutes();
    const seconds = time.getSeconds();
    return `${padZero(hours)}:${padZero(minutes)}:${padZero(seconds)}`;
  };

  const formatDateTime = (currentDate) => {
    if (!currentDate || !(currentDate instanceof Date)) {
      return "Invalid Date"; // Or handle the case appropriately
    }
    const day = String(currentDate.getDate()).padStart(2, "0");
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = currentDate.getFullYear();
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");

    // Format the date and time as desired
    const formattedDate = `${day}/${month}/${year}`;
    const formattedTime = `${hours}:${minutes}:${seconds}`;
    const formattedDateTime = `${formattedDate} ${formattedTime}`;
    return formattedDateTime;
  };

  const padZero = (num) => (num < 10 ? `0${num}` : num);

  const handlePanelChange = (value, mode) => {
    setSelectedDate(value);
  };

  const CustomHeader = ({ value, onChange }) => {
    const handlePrev = () => {
      const newValue = value.clone().subtract(1, "month");
      onChange(newValue);
    };

    const handleNext = () => {
      const newValue = value.clone().add(1, "month");
      onChange(newValue);
    };

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button
          type="link"
          onClick={handlePrev}
          icon={<LeftOutlined />}
        ></Button>
        <div>{value.format("MMMM YYYY")}</div>
        <Button
          type="link"
          onClick={handleNext}
          icon={<RightOutlined />}
        ></Button>
      </div>
    );
  };

  useEffect(() => {
    GetHolidays();
    fetchData();
    fetchData2();
    fetchmanager();
    fetchPDFList();
    // Make a GET request to your server endpoint
    const dbName = localStorage.getItem("dbName");
  }, []);

  const fetchmanager = async () => {
    const dbName = localStorage.getItem("dbName");
    try {
      // For Azure Users, user in localstorage will be set only after refresh

      const response = await axios.get(
        `${url}/api/getUserManager/${user.displayName}/${dbName}`
      );
      const manager = response.data.user.manager;
      const empDesignation = response.data.user.empDesignation;
      const empCode = response.data.user.empCode;
      console.log(empCode, "empCode");
      setManagerInfo(manager);
      setJobTitle(empDesignation);
      setempCode(empCode);
      console.log(manager);
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  const GetHolidays = async () => {
    try {
      const dbName = localStorage.getItem("dbName");
      const response = await axios.get(`${url}/get/admin/services/${dbName}`);
      const res = await axios.get(`${url}/get/leave/${dbName}`);
  
      if (
        response.data[0]?.isActive === true ||
        response.data[0]?.isActive === false
      ) {
        const switchState = response.data[0]?.isActive;
        localStorage.setItem("switchState", switchState);
        console.log("Data retrieved and stored in local storage.");
      } else {
        console.error("No data received from the server.");
      }
  
      // Directly use officeHolidaysData from the response
      const holidays = res.data?.officeHolidaysData || [];
      console.log(holidays, "holidays");
  
      const formattedEvents = holidays.map((holiday) => {
        const [year, month, day] = holiday.date.split("-");
        const isoDate = `${year}-${month}-${day}`;
  
        return {
          title: holiday.title,
          date: isoDate,
          icon: (
            <Tooltip title={holiday.title}>
              <CalendarOutlined
                style={{
                  fontSize: "15px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
            </Tooltip>
          ),
        };
      });
      console.log(formattedEvents, "formattedEvents");
  
      setCalendarEvents(formattedEvents);
    } catch (error) {
      console.error(error);
    }
  };

  const renderEventContent = (eventInfo) => {
    return eventInfo.event.extendedProps.icon;
  };

  const fetchData = async () => {
    const dbName = localStorage.getItem("dbName");
    try {
      const response = await fetch(
        `${url}/api/projects/${user.displayName}/${dbName}`
      );
      const data = await response.json();
      setProjects(data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchData2 = async () => {
    const dbName = localStorage.getItem("dbName");
    try {
      console.log("infetchdata2 emp dashboard");
      // For Azure Users, user in localstorage will be set only after refresh

      const response = await axios.get(
        `${url}/api/getUserManager/${user.displayName}/${dbName}`
      );
      const empCode = response.data.user.empCode;
      const res = await axios.get(`${url}/leave/report/${empCode}/${dbName}`);
      // Filter the leave records to get only the approved ones
      console.log("res after leave resport  ", res, empCode, dbName);
      const currentYear = new Date().getFullYear();

      const approvedLeave = res.data.filter((leave) => {
        const status = leave.status.toLowerCase();
        console.log(new Date(leave.sdate).getFullYear(), "leave.sdate"); // Convert status to lowercase for case-insensitive comparison
        return (
          new Date(leave.sdate).getFullYear() === currentYear &&
          (status === "submitted" || status === "approved")
        );
      });
      console.log(approvedLeave, "approvedLeave");
      setLeave(approvedLeave);
      setLoading(true);

      const balresp = await axios.get(
        `${url}/send/Leavebalance/${empCode}/${dbName}`
      );
      const availableAnnualLeave = balresp.data.annualLeaveBal;
      const availableCasualLeave = balresp.data.casualLeaveBal;
      let availableCompoffLeave = balresp.data.compoffBal;
      if (typeof availableCompoffLeave === "undefined") {
        availableCompoffLeave = 0;
      }
      console.log("in leave****", balresp);
      setAnnualbalance(availableAnnualLeave);
      setCasualbalance(availableCasualLeave);
      setCompoffbalance(availableCompoffLeave);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const calendarHeight = "calc(4 * 6rem)";

  const headerToolbarOptions = {
    left: "prev,next",
    center: "title",
    right: "today",
  };

  const leaveByType = leave.reduce((acc, curr) => {
    let type = curr.type;
    if (type === "annual") {
      type = "vacation";
    }
    const days = parseFloat(curr.leavedays);
    if (acc[type]) {
      acc[type] += days;
    } else {
      acc[type] = days;
    }
    return acc;
  }, {});
  const options = {
    chart: {
      height: 350,
      type: "donut",
      toolbar: {
        show: false,
      },
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      offsetY: 10,
      fontSize: "14px",
      fontFamily: "Arial",
      markers: {
        width: 10,
        height: 10,
        strokeWidth: 0,
        strokeColor: "#fff",
        fillColors: undefined,
        radius: 12,
        onClick: undefined,
        offsetX: 0,
        offsetY: 0,
      },
      itemMargin: {
        horizontal: 5,
        vertical: 10,
      },
    },
    labels: Object.keys(leaveByType),
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "12px",
        colors: ["#fff"],
      },
      formatter: function(val, opts) {
        return opts.w.globals.series[opts.seriesIndex];
      },
    },

    tooltip: {
      y: {
        formatter: function(val) {
          return val;
        },
      },
    },
    colors: undefined,
    plotOptions: {
      pie: {
        donut: {
          size: "60%",
          labels: {
            show: true,
            name: {
              show: true,
              offsetY: -20,
            },
            value: {
              show: true,
              offsetY: 10,
            },
            total: {
              show: true,
              showAlways: true,
              fontSize: "15px",
              fontWeight: "bold",
              fontFamily: undefined,
              color: "#373d3f",
              formatter: function(w) {
                return w.globals.seriesTotals.reduce((a, b) => {
                  return a + b;
                }, 0);
              },
            },
          },
        },
      },
    },
  };

  const series = Object.values(leaveByType);

  const projectNames = projects
    .map((project) => project.ProjectName)
    .join(", ");
  const CustomCard = ({ title, icon, children }) => (
    <Col xs={24} sm={24} lg={8}>
      <Card className="cardcomponent1">
        <div className="flex-container">
          <div className="text-container">
            <div className="title">{title}</div>
            <div className="content">{children}</div>
          </div>
          {icon}
        </div>
      </Card>
    </Col>
  );

  const handleOpenNewPage = (file) => {
    const newWindow = window.open("Pdf-View", "_blank");
    newWindow.document.write(`
        <html>
          <body>
            <div>
              <iframe src="${url}/api/pdf/${file}" width="100%" height="600px"></iframe>
            </div>
          </body>
        </html>
      `);
    newWindow.document.close();
  };

  const fetchPDFList = async () => {
    try {
      const response = await fetch(`${url}/api/pdf/list`);
      if (response.ok) {
        const data = await response.json();
        setPdfFiles(data.files);
      } else {
        console.error("Failed to fetch PDF list");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getPriorityColor = (priority) => {
    switch (priority.toLowerCase()) {
      case "low":
        return "#d1756f";
      case "medium":
        return "#d9d679";
      case "high":
        return "#8df57f";
      default:
        return "transparent";
    }
  };

  return (
    <div
      class="container-fluid"
      style={{ transform: "scale(0.9)", transformOrigin: "0 0", width: "110%" }}
    >
      <h4>Welcome {user.displayName}</h4>
      <Row
        align="center"
        justify="center"
        xs={24}
        sm={24}
        lg={24}
        style={{ display: "flex", gap: 20, padding: 10 }}
      >
        {locationCheck === "true" && <Timer user={user} />}
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} md={12} lg={12} xl={8}>
          <Card>
            <h5 className="text-center">Requests</h5>
            <br />
            <Row
              gutter={[16, 16]}
              style={{ display: "flex", justifyContent: "space-around" }}
            >
              <div style={{ textAlign: "center" }}>
                <Link to="/home/timesheet">
                  <img
                    src={require("../images/timetable.png")}
                    style={{
                      width: "10vh",
                      border: "2px solid #ccc",
                      padding: "5px",
                      boxShadow: "0 3px 3px #9a1f91",
                      borderRadius: "50%",
                    }}
                    alt="Timesheet Approval"
                  />

                  <p style={{ fontSize: "12px", margin: 0 }}>Timesheet</p>
                </Link>
              </div>
              <div style={{ textAlign: "center" }}>
                <Link to="/home/leaveapply">
                  <img
                    src={require("../images/logout.png")}
                    style={{
                      width: "10vh",
                      border: "2px solid #ccc",
                      padding: "5px",
                      boxShadow: "0 3px 3px #9a1f91",
                      borderRadius: "50%",
                    }}
                    alt="Leave Approval"
                  />
                  <p style={{ fontSize: "12px", margin: 0 }}>LeaveApply</p>
                </Link>
              </div>
              <div style={{ textAlign: "center" }}>
                <Link to="/home/claims">
                  <img
                    src={require("../images/report.png")}
                    style={{
                      width: "10vh",
                      border: "2px solid #ccc",
                      padding: "5px",
                      boxShadow: "0 3px 3px #9a1f91",
                      borderRadius: "50%",
                    }}
                    alt="Claims Approval"
                  />
                  <p style={{ fontSize: "12px", margin: 0 }}>Claims</p>
                </Link>
              </div>
            </Row>
          </Card>
          <p
            style={{ background: "linear-gradient(to right,#aa42c7,#5bc1de )" }}
          />
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={8}>
          <Card>
            <h5 className="text-center">Leave Details</h5>
            <br />
            <Row
              gutter={[16, 16]}
              style={{ display: "flex", justifyContent: "space-around" }}
            >
              <div style={{ textAlign: "center" }}>
                <Avatar
                  size={60}
                  style={{
                    background: "linear-gradient(to right,#b386eb,#5bc1de )",
                    color: "white",
                  }}
                >{`${balance1}`}</Avatar>
                <p style={{ fontSize: "12px", margin: 0 }}>Vacation</p>
              </div>
              <div style={{ textAlign: "center" }}>
                <Avatar
                  style={{
                    background: "linear-gradient(to right,#b386eb,#5bc1de )",
                    color: "white",
                  }}
                  size={60}
                >{`${balance2}`}</Avatar>
                <p style={{ fontSize: "12px", margin: 0 }}>Casual</p>
              </div>
              <div style={{ textAlign: "center" }}>
                <Avatar
                  style={{
                    background: "linear-gradient(to right,#b386eb,#5bc1de )",
                    color: "white",
                  }}
                  size={60}
                >{`${balance3}`}</Avatar>
                <p style={{ fontSize: "12px", margin: 0 }}>Compoff</p>
              </div>
            </Row>
          </Card>
          <p
            style={{ background: "linear-gradient(to right,#aa42c7,#5bc1de )" }}
          />
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={8}>
          <Card>
            <h5 className="text-center">Attendance Tracker</h5>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <img
                src={require("../images/Attendance-Management-System-removebg-preview.png")}
                style={{ width: "35vh" }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Button
                  type="text"
                  style={{ width: "8vh", height: "8vh" }}
                  onClick={handleCheckIn}
                  disabled={disablecheckIn}
                >
                  <img
                    src={require("../images/play-button.png")}
                    style={{ width: "3vh", height: "3vh" }}
                  />
                </Button>
                <div>
                  {check ? (
                    <p style={{ fontSize: "8px" }}>{check[0]?.checkInTime}</p>
                  ) : (
                    checkInTime && (
                      <p style={{ fontSize: "8px" }}>
                        {formatDateTime(checkInTime)}
                      </p>
                    )
                  )}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Button
                  type="text"
                  style={{ left: "3px", width: "8vh", height: "8vh" }}
                  onClick={handleCheckOut}
                  disabled={disablecheckOut}
                >
                  <img
                    src={require("../images/stop.png")}
                    style={{ width: "3vh", height: "3vh" }}
                  />
                </Button>
                <div>
                  {console.log(check, "check")}
                  {check ? (
                    <p style={{ fontSize: "8px" }}>{check[0]?.checkOutTime}</p>
                  ) : (
                    checkOutTime && (
                      <p style={{ fontSize: "8px" }}>
                        {formatDateTime(checkOutTime)}
                      </p>
                    )
                  )}
                </div>
              </div>
            </div>
          </Card>
          <p
            style={{ background: "linear-gradient(to right,#aa42c7,#5bc1de )" }}
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} lg={8} style={{ zIndex: 0 }}>
          <div className="calendar-container">
            <Calendar
              className="details"
              fullscreen={false}
              headerRender={({ value, onChange }) => (
                <CustomHeader value={value} onChange={onChange} />
              )}
              dateCellRender={(date) => {
                const formattedDate = date.format("YYYY-MM-DD");
                const event = calendarEvents.find(
                  (event) => event.date === formattedDate
                );

                return event ? (
                  <Tooltip
                    title={<span>{event.title}</span>}
                    getPopupContainer={(triggerNode) =>
                      document.getElementById("tooltipContainer")
                    }
                    placement="top"
                    mouseEnterDelay={0.5}
                    mouseLeaveDelay={0.1}
                  >
                    <span style={{ color: "blue" }}>{event.icon}</span>
                  </Tooltip>
                ) : null;
              }}
              onPanelChange={handlePanelChange}
            />
          </div>
        </Col>
        <Col xs={30} sm={24} lg={16}>
          {/* <Card
            title="My Task"
            extra={tasks.length > 3 && !showAll && <Link>View More</Link>}
            style={{ top: "2vh" }}
          >
            {visibleTasks.slice(0, 3).map(renderTask)}{" "}
            {/* Display only the first three tasks
            </Card> */}
          <Card style={{ top: "2vh", height: "58vh" }}>
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <span style={{ fontSize: "20px" }}>Designation</span>
                <Divider />
                <div style={{ marginTop: "5vh" }}>
                  <img
                    src={require("../images/employee.png")}
                    style={{ width: "12vh", height: "12vh" }}
                    alt="Designation"
                  />
                  <div style={{ marginTop: "30px", fontSize: "16px" }}>
                    {jobTitle}
                  </div>
                </div>
              </Col>
              <Col span={6}>
                <span style={{ fontSize: "20px" }}>Manager</span>
                <Divider />
                <div style={{ marginTop: "5vh" }}>
                  <img
                    src={require("../images/people.png")}
                    style={{ width: "12vh", height: "12vh" }}
                    alt="Reporting Manager"
                  />
                  <div style={{ marginTop: "30px", fontSize: "16px" }}>
                    {managerInfo}
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <span style={{ fontSize: "20px" }}>Project Assigned</span>
                <Divider />
                <div style={{ marginTop: "5vh" }}>
                  <img
                    src={require("../images/project-manager.png")}
                    style={{ width: "12vh", height: "12vh" }}
                    alt="Project"
                  />
                  <div style={{ marginTop: "30px", fontSize: "16px" }}>
                    {" "}
                    {`${projectNames}`}
                  </div>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>

        {/* <Col
          xs={24}
          sm={24}
          lg={8}
        >
          <Card>

          </Card>
        </Col> */}
      </Row>
      {/* <Col xs={24} sm={24} lg={8} style={{ display: "flex" }}>
          <Card
            className="responsive-container"
            title="Policies"
            bordered={false}
            style={{
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              flex: 1,
              display: "flex",
              justifyContent: "flex-start",
              textAlign: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ alignItems: "flex-start" }}>
              {pdfFiles.length > 0
                ? pdfFiles.map((file, index) => (
                  <div
                    xs={24}
                    sm={24}
                    lg={6}
                    style={{ display: "flex", margin: "5px", gap: "10px" }}
                  >
                    <Link
                      onClick={() => handleOpenNewPage(file)}
                      style={{ padding: "1vh" }}
                    >
                      {file}
                    </Link>
                  </div>
                ))
                : "No Files Uploaded"}
            </div>
          </Card>
        </Col> */}
    </div>
  );
};

export default Empdash;
