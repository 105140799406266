// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* YourComponent.css */
.steps-container {
    padding: 10px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
  }
  
  .ant-steps-item-description {
    font-size: 12px;
    
    color: #777;
  }
  
  .ant-steps-item-title {
    font-size: 14px;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/querysupport/query.css"],"names":[],"mappings":"AAAA,sBAAsB;AACtB;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,wCAAwC;IACxC,aAAa;IACb,uBAAuB;EACzB;;EAEA;IACE,eAAe;;IAEf,WAAW;EACb;;EAEA;IACE,eAAe;EACjB","sourcesContent":["/* YourComponent.css */\n.steps-container {\n    padding: 10px;\n    background-color: #fff;\n    border-radius: 10px;\n    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);\n    display: flex;\n    justify-content: center;\n  }\n  \n  .ant-steps-item-description {\n    font-size: 12px;\n    \n    color: #777;\n  }\n  \n  .ant-steps-item-title {\n    font-size: 14px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
