import React, { useState, useEffect } from "react";
import {
  Table,
  Col,
  Row,
  Card,
  Button,
  Modal,
  Form,
  Input,
  Select,
  notification,
  Tabs,
  Descriptions,
  Tooltip,
} from "antd";
import "./Job.css";
import {
  PlusOutlined,
  SaveFilled,
  EyeOutlined,
  DownloadOutlined,
  EditOutlined,
  FileImageOutlined,
  FileDoneOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { url } from "../../url/url";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../employee/employee.css";
const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 12,
  },
};

const { TabPane } = Tabs;

const Details = () => {
  const [modalvisible, setModalVisible] = useState(false);
  const [job, setJob] = useState([]);
  const [selfReferrals, setSelfReferrals] = useState([]);
  const [selectedResume, setSelectedResume] = useState(null);
  const [modalVisible1, setModalVisible1] = useState(false);
  const [offer, setOffer] = useState([]);
  const [form] = Form.useForm();
  const [editingRecord, setEditingRecord] = useState(null);
  const users = JSON.parse(localStorage.getItem("user"));
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [fileName, setFileName] = useState('');
  const [image, setImage] = useState();
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const navigate = useNavigate();
  // Function to handle opening the view modal
  const handleViewModalOpen = (record) => {
    setSelectedRecord(record);
    setViewModalVisible(true);
  };

  const handleUpdate = async (values) => {
    const dbName = localStorage.getItem("dbName");
    try {
      const response = await axios.put(
        `${url}/api/updateselfref/${editingRecord._id}/${dbName}`,
        values
      );
      setSelfReferrals(
        selfReferrals.map((record) =>
          record._id === editingRecord._id
            ? { ...record, ...response.data }
            : record
        )
      );
      setEditingRecord(null)
      notification.success({ message: "Record updated in DB successfully!" });
    } catch (err) {
      notification.error({ message: "Failed to update the Record" });
    }
  };
  // Function to handle closing the view modal
  const handleViewModalClose = () => {
    setSelectedRecord(null);
    setViewModalVisible(false);
  };
  const handleEdit = (record) => {
    setEditingRecord(record);
    form.setFieldsValue({
      ...record,
    });
  };

  const handleImageChange = (e, field) => {
    const file = e.target.files[0];
    setFileName(file.name);
    if (file) {
      const allowedFileTypes = ["image/jpeg", "image/png", "image/jpg", "application/pdf"];

      // Check if the selected file type is in the allowed list
      if (allowedFileTypes.includes(file.type)) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const image = reader.result;
          setImage(image);
          const isAnyImageUploaded = !!image
          setIsImageUploaded(isAnyImageUploaded);
        };
        reader.readAsDataURL(file);
      } else {
        // Clear the file error message if no file is selected
        form.setFieldsValue({
          profileImage: null,
        });
      }
    }
  };
  const shouldUpdate = (prevProps, nextProps) => {
    if (
      prevProps.dataSource === nextProps.dataSource &&
      prevProps.count === nextProps.count
    ) {
      return false;
    }
    return true;
  };
  const fetchData1 = async () => {
    const dbName = localStorage.getItem("dbName");
    try {
      const response = await axios.get(`${url}/get/jobs/${dbName}`);
      setOffer(response.data);
      console.log(response.data, "res");
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const handleViewResume = (resume,record) => {
    setSelectedResume(resume);
    setSelectedRecord(record);
    setModalVisible1(true);
  };

  // Function to handle closing modal
  const handleModalClose = () => {
    setSelectedResume(null);
    setModalVisible1(false);
  };

  // Function to handle downloading resume
  const handleDownloadResume = (resumeUrl) => {
    const fileName = "resume"; // Set a default filename for the downloaded image
    const anchor = document.createElement("a"); // Create anchor element
    anchor.href = resumeUrl; // Set href to the image URL
    anchor.download = fileName; // Set download attribute to desired filename
    anchor.click(); // Programmatically trigger click event to start download
  };


  useEffect(() => {
    fetchData1();
  }, []);
  const fetchData = async () => {
    const dbName = localStorage.getItem("dbName");
    try {
      const response = await axios.get(`${url}/get/Selfreferrals/${dbName}`);
      setSelfReferrals(response.data)
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [editingRecord]);
  const columns = [
    {
      title: "JobId",
      dataIndex: "jobId",
      className: "ant-table-cell",
      filters: selfReferrals
        .map((record) => record.jobId)
        .filter((value, index, self) => self.indexOf(value) === index)
        .map((value) => ({ text: value, value })),
      onFilter: (value, record) => record.jobId.includes(value),
    },
    {
      title: "Emp Name",
      dataIndex: "displayName",
      className: "ant-table-cell",
      filters: selfReferrals
        .map((record) => record.displayName)
        .filter((value, index, self) => self.indexOf(value) === index)
        .map((value) => ({ text: value, value })),
      onFilter: (value, record) => record.displayName.includes(value),
    },
    // {
    //     title: "Referral Name",
    //     dataIndex: "name",
    //     filters: [
    //         ...selfReferrals
    //             .map((record) => ({
    //                 text: record.name === record.displayName ? 'Self' : record.name,
    //                 value: record.name
    //             }))
    //             .filter((value, index, self) => self.findIndex((v) => v.text === value.text) === index)
    //     ],
    //     onFilter: (value, record) => record.name.includes(value),
    //     render: (text, record) => {
    //         return record.name === record.displayName ? 'Self' : text;
    //     }
    // },

    { title: "Email", dataIndex: "email", className: "ant-table-cell" },
    { title: "Mobile", dataIndex: "mobile", className: "ant-table-cell" },
    {
      title: "Job",
      dataIndex: "role",
      className: "ant-table-cell",
      filters: selfReferrals
        .map((record) => record.role)
        .filter((value, index, self) => self.indexOf(value) === index)
        .map((value) => ({ text: value, value })),
      onFilter: (value, record) => record.role.includes(value),
    },
    {
      title: "Primary",
      dataIndex: "primary",
      className: "ant-table-cell",
      filters: selfReferrals
        .map((record) => record.primary)
        .filter((value, index, self) => self.indexOf(value) === index)
        .map((value) => ({ text: value, value })),
      onFilter: (value, record) => record.primary.includes(value),
      render: (text) => (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    // {
    //     title: "Reason", dataIndex: "reason", filters: selfReferrals
    //         .map((record) => record.reason)
    //         .filter((value, index, self) => self.indexOf(value) === index)
    //         .map((value) => ({ text: value, value })),
    //     onFilter: (value, record) => record.reason.includes(value),
    // },
    {
      title: "Status",
      dataIndex: "status",
      className: "ant-table-cell",
      filters: selfReferrals
        .map((record) => record.status)
        .filter((value, index, self) => self.indexOf(value) === index)
        .map((value) => ({ text: value, value })),
      onFilter: (value, record) => record.status.includes(value),
    },
    {
      title: "Resume",
      dataIndex: "resume",
      key: "resume",
      className: "ant-table-cell",
      render: (resume,record) => (
        <div>
          {resume?.length > 0 ? (
            <>
              <div>
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    textAlign: "center",
                  }}
                >
                  <Button
                    type="text"
                    onClick={() => handleViewResume(resume,record)}
                    icon={<FileDoneOutlined />}
                  />
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    textAlign: "center",
                    color: "white",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                  }}
                >
                  {resume && resume.name}
                </div>
              </div>
            </>
          ) : (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "40%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
              }}
            >
              NA
            </div>
          )}
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      className: "ant-table-cell",
      render: (text, record) => (
        <>
          <Button
            type="link"
            onClick={() => handleEdit(record)}
            icon={<EditOutlined />}
          />
          <Button
            type="link"
            onClick={() => handleViewModalOpen(record)}
            icon={<EyeOutlined />}
          />
        </>
      ),
    },
  ];

  const showmodal = () => {
    setModalVisible(true);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  return (
    <div>
      <Modal
        title={
          <Row gutter={[16, 16]}>
            <Col span={20}>
              <h5>Resume</h5>
            </Col>
            <Col span={4}>

            </Col>
          </Row>
        }
        visible={modalVisible1}
        onCancel={handleModalClose}
        footer={null}
      >
        {selectedResume && (
          <Button
            type="primary"
            style={{ background: "#EA5851" }}
            icon={<DownloadOutlined />}
            onClick={() => handleDownloadResume(selectedResume)}
          >{selectedRecord?.name}  Resume</Button>
        )}
      </Modal>

      <div style={{ width: "100%", overflowX: "auto" }}>
        <Table
          className="custom-table col-lg-12 col-md-12 col-sm-12"
          key="referralsTable"
          columns={columns}
          dataSource={selfReferrals}
          scroll={{ x: true }}
          rowClassName="fixed-height-row"
          sticky
        />
      </div>
      {editingRecord && (
        <Modal
          title="Edit Applicant details"
          width={550}
          visible={editingRecord !== null}
          onCancel={() => setEditingRecord(null)}
          onOk={() => form.submit()}
          bodyStyle={{ maxHeight: 450, overflowY: "auto", margin: "20px" }}
          footer={null}
        >
          <Form {...layout} form={form} initialValues={editingRecord} onFinish={handleUpdate}>
            <Form.Item label='Name' name='name' hasFeedback>
              <Input />
            </Form.Item>
            <Form.Item label='Email' name='email' hasFeedback>
              <Input />

            </Form.Item>
            <Form.Item label='Mobile' name="mobile" hasFeedback>
              <Input />

            </Form.Item>
            <Form.Item label='Years of exp' name='yearsOfExp' hasFeedback>
              <Input />

            </Form.Item>
            <Form.Item label='Primary skills' name='primary' hasFeedback>
              <Input />

            </Form.Item>
            <Form.Item label='Secondary skills' name='secondary' hasFeedback>
              <Input />
            </Form.Item>
            <Form.Item label='State few reasons how you fit in this job?' name='reason' labelCol={{ width: "2vh" }} hasFeedback>
              <Input.TextArea rows={4} />
            </Form.Item>
            <Form.Item label='Status' name='status'>
              <Select>
                <Select.Option value="Applied">Applied</Select.Option>
                <Select.Option value="Selected">Selected</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item label='Upload Resume' name='resume'>
              <label className="custom-file-upload">
                <UploadOutlined />
                Upload Resume
                <Input
                  type="file"
                  onChange={(e) => handleImageChange(e, "resume")}
                  style={{ display: "none" }} // Hide the input element
                />
              </label>
              {fileName && (
                <div>
                  <p>{fileName}</p>
                </div>
              )}

            </Form.Item>
          
            <Form.Item style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button type="primary" style={{ background: "#EA5851" }} htmlType='submit' icon={<SaveFilled />}>Save</Button>
            </Form.Item>

          </Form>
        </Modal>
      )}
      <Modal
        title="View Details"
        visible={viewModalVisible}
        onCancel={handleViewModalClose}
        footer={null}
      >
        <Descriptions bordered column={1}>
          <Descriptions.Item label="Emp Name">
            {selectedRecord?.displayName}
          </Descriptions.Item>
          <Descriptions.Item label="Referral Name">
            {selectedRecord?.name}
          </Descriptions.Item>
          <Descriptions.Item label="Email">
            {selectedRecord?.email}
          </Descriptions.Item>
          <Descriptions.Item label="Mobile">
            {selectedRecord?.mobile}
          </Descriptions.Item>
          <Descriptions.Item label="Job">
            {selectedRecord?.role}
          </Descriptions.Item>
          <Descriptions.Item label="Date">
            {selectedRecord?.date}
          </Descriptions.Item>
          <Descriptions.Item label="Primary Skills">
            {selectedRecord?.primary}
          </Descriptions.Item>
          <Descriptions.Item label="Secondary Skills">
            {selectedRecord?.secondary}
          </Descriptions.Item>
          <Descriptions.Item label="Years of Experience">
            {selectedRecord?.yearsOfExp}
          </Descriptions.Item>
          <Descriptions.Item label="Reason">
            {selectedRecord?.reason}
          </Descriptions.Item>
        </Descriptions>
      </Modal>
    </div>
  );
};

export default Details;
