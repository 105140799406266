import React, { useContext,useState } from "react";
import { ColorContext } from "./ColorProvider";
import { ChromePicker } from "react-color";
import { Button, notification } from "antd";
import { url } from "../../url/url";
import axios from "axios";
const ColorPicker = () => {
  const { color, setColor } = useContext(ColorContext);
  const [count, setCount] = useState(0);
  const handleColorChange = (newColor) => {
    setColor(newColor.hex);
  };
  const handleColorSubmit = async (values) => {
    try {
      const dbName = localStorage.getItem("dbName");
      const response = await axios.post(`${url}/colours/${dbName}`, {
        color: values,
      });
      setColor(response.data);
      console.log(values, response, "Colorsapi");
      notification.success({
        message: "Success",
        description: "Color data has been successfully added!",
      });
    } catch (error) {
      console.error(error);
      notification.error({
        message: "Error",
        description: "Failed to add color data.",
      });
    }
  };

  const onFinish = async (values) => {
    const dbName = localStorage.getItem("dbName");
    try {
      let type;
      type = "colors";

      const response = await axios.post(
        `${url}/admin/combined/${type}/${dbName}`,
        { color: values }
      );
      console.log("Data posted:", response.data);
      notification.success({
        message: `Posted Successfully!`,
        duration: 3,
      });
      setCount(count + 1);
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };
  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <ChromePicker color={color} onChange={handleColorChange} />
        <div>
          <br />
          <Button
            style={{ marginLeft: "20px" }}
            type="primary"
            onClick={() => onFinish(color)}
          >
            Submit
          </Button>
        </div>
      </div>
    </>
  );
};

export default ColorPicker;
