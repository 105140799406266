import React, { useEffect, useState } from "react";
import axios from "axios";
import { Avatar, Button, Spin } from "antd";
import { url } from "../../url/url";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Layout from "antd/es/layout/layout";
import OrgChart from "react-orgchart";
import "react-orgchart/index.css";
import "./charts.css";
const fetchManagers = async (email, dbName) => {
  if (!email) {
    return null;
  }

  const response = await axios.get(
    `${url}/api/get/emplistbyemail/${email}/${dbName}`
  );
  const data = response.data;
  if (!data || data.length === 0) {
    return null;
  }

  const manager = data[0];
  const managerData = await fetchManagers(manager.managerEmail, dbName);

  return {
    ...manager,
    manager: managerData,
  };
};

// const getOrgChartData = async (rootEmail, dbName) => {
//   const root = await axios.get(
//     `${url}/api/get/emplistbyid/${rootEmail}/${dbName}`
//   );
//   const rootData = root.data;
//   console.log("Root Data:", rootData); // Debug root data

//   if (!rootData || rootData.length === 0) {
//     return null;
//   }

//   const orgChartData = {
//     ...rootData,
//     reportees: await fetchReportees(rootData.displayName, dbName), // Ensure this fetches correctly
//     manager: await fetchManagers(rootData.managerEmail, dbName),
//   };

//   console.log("Org Chart Data:", orgChartData); // Debug full org chart data

//   return orgChartData;
// };
const getOrgChartData = async (rootEmail, dbName) => {
  const root = await axios.get(
    `${url}/api/get/emplistbyid/${rootEmail}/${dbName}`
  );
  const rootData = root.data;

  if (!rootData || rootData.length === 0) {
    return null;
  }

  const orgChartData = {
    ...rootData,
    reportees: await fetchReportees(rootData.displayName, dbName),
    manager: await fetchManagers(rootData.managerEmail, dbName),
  };

  console.log("Org Chart Data:", orgChartData); // Check the full structure including reportees
  return orgChartData;
};

const fetchReportees = async (email, dbName) => {
  const response = await axios.get(
    `${url}/api/get/emplist/${email}/manager/${dbName}`
  );
  const data = response.data;
  console.log("Fetched Data for Email:", email, data); // Debug API response
  const activeUsers = data.filter((user) => user.empStatus !== "inactive");
  if (!activeUsers || activeUsers.length === 0) {
    return [];
  }

  const reportees = await Promise.all(
    activeUsers.map(async (reportee) => {
      const reporteeData = await fetchReportees(reportee.displayName, dbName);
      return {
        ...reportee,
        reportees: reporteeData,
      };
    })
  );

  console.log("Reportees for Email:", email, reportees); // Debug reportees

  return reportees;
};

const colorPalette = ["#619134", "#e80cd6", "#34a85a", "#ff9900", "#0099cc"];
const buildTreeData = (user, dbName, expandedNodes, toggleNode) => {
  if (!user) return null;

  const subUsers = user.reportees || [];

  const colorIndex = Math.floor(Math.random() * colorPalette.length);
  const color = colorPalette[colorIndex];

  const isExpanded = expandedNodes[user.email] || false;

  const nodeData = {
    name: user.displayName,
    title: user.empDesignation,
    color,
    profileImage: user.profileImage,
    children: isExpanded
      ? subUsers.map((subUser) =>
          buildTreeData(subUser, dbName, expandedNodes, toggleNode)
        )
      : [], // Only populate the children array if expanded
    reporteeCount: subUsers.length, // Track the number of reportees
    isExpanded,
    toggleNode: () => toggleNode(user.email),
  };

  return nodeData;
};

const buildFullTreeData = (user, dbName, expandedNodes, toggleNode) => {
  if (!user) return null;

  const treeData = buildTreeData(user, dbName, expandedNodes, toggleNode);
  let currentNode = treeData;
  let currentUser = user;

  console.log("Initial Tree Data:", treeData); // Check initial tree data

  while (currentUser.manager) {
    const managerNode = buildTreeData(
      currentUser.manager,
      dbName,
      expandedNodes,
      toggleNode
    );
    managerNode.children = [currentNode];
    currentNode = managerNode;
    currentUser = currentUser.manager;
  }

  console.log("Final Tree Data:", currentNode); // Check the final tree structure
  return currentNode;
};

const Chart1 = ({
  secondModalVisible,
  peerModalVisible,
  setSecondModalVisible,
  setPeerModalVisible,
}) => {
  const [orgChartData, setOrgChartData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [expandedNodes, setExpandedNodes] = useState({});
  const dbName = localStorage.getItem("dbName");
  const { email } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const prevLocation = location.state?.from;

  const toggleNode = (email) => {
    setExpandedNodes((prev) => ({
      ...prev,
      [email]: !prev[email],
    }));
  };

  useEffect(() => {
    setSecondModalVisible(false);
    setPeerModalVisible(false);

    const fetchData = async () => {
      const rootEmail = email;
      const data = await getOrgChartData(rootEmail, dbName);
      if (data) {
        setOrgChartData(
          buildFullTreeData(data, dbName, expandedNodes, toggleNode)
        );
      }
      setLoading(false);
    };

    fetchData();
  }, [
    email,
    dbName,
    setSecondModalVisible,
    setPeerModalVisible,
    expandedNodes,
  ]);

  const MyNodeComponent = ({ node }) => {
    const [isExpanded, setIsExpanded] = useState(node.isExpanded);
    const capitalizeFirstLetter = (str) => {
      return str.charAt(0).toUpperCase() + str.slice(1);
    };
    const getInitials = (name) => {
      if (!name) return "";
      const nameParts = name.split(" ");
      const firstLetter = nameParts[0][0];
      const lastLetter = nameParts[nameParts.length - 1][0];
      return `${firstLetter}${lastLetter}`.toUpperCase();
    };
    const handleClick = () => {
      setIsExpanded(!isExpanded);
      node.toggleNode(); // Trigger the expansion in the org chart
    };
    return (
      <div className="initechNode">
        <div onClick={handleClick} className="nodeContent">
          <div className="avatarContainer">
            {node.profileImage ? (
              <Avatar size={64} src={node.profileImage} />
            ) : (
              <Avatar size={64} style={{ backgroundColor: node.color }}>
                {getInitials(node.name)}
              </Avatar>
            )}
          </div>
          <div className="nodeInfo">
            <div className="title">{capitalizeFirstLetter(node.name)}</div>
            <div className="fullname">{capitalizeFirstLetter(node.title)}</div>
          </div>
        </div>
        {/* Display the number of reportees below the node if not expanded */}
        {!isExpanded && node.reporteeCount > 0 && (
          <div className="reporteeCount">
            <span>{node.reporteeCount}</span>
          </div>
        )}
      </div>
    );
  };

  const goBack = () => {
    if (prevLocation === "/home") {
      navigate("/home", {
        state: {
          secondModalVisible: true,
          peerModalVisible: true,
        },
      });
      window.history.replaceState(null, "");
    } else {
      navigate(-1);
    }
  };

  return (
    <div>
      {loading ? (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <>
          <Button onClick={goBack}>Back</Button>
          <h5 className="text-center">Organization chart</h5>
          <br />

          <Layout
            className="site-layout"
            style={{
              transform: "scale(0.6)",
              transformOrigin: "0 0",
              width: "150%",
            }}
          >
            <OrgChart tree={orgChartData} NodeComponent={MyNodeComponent} />
          </Layout>
        </>
      )}
    </div>
  );
};

export default Chart1;




