/*My Work->Employee details->Add New Employee*/
import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  DatePicker,
  Select,
  Button,
  Upload,
  message,
  notification,
  Card,
  Row,
  Col,
  Radio,
  Slider,
  Divider,
} from "antd";
import { UploadOutlined, DeleteFilled } from "@ant-design/icons";
import axios from "axios";
import "./EmployeeForm.css";
import { url } from "../../url/url";
import Emp from "./Employee";
import { useNavigate, Link } from "react-router-dom";
import { decryptRole } from "../../encrypt";
const { Option } = Select;

const EmpApply = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [user, setUser] = useState([]);
  const [data, setData] = useState([]);
  const [domain, setDomain] = useState();
  const [emailValue, setEmailValue] = useState();
  const [selectedDomain, setSelectedDomain] = useState();
  const [organizationData, setOrganizationData] = useState([]);
  const [image, setImage] = useState();
  const formRef = React.useRef(null);
  const [list, setList] = useState([]);
  const [panCopy, setPanCopyImage] = useState(null);
  const [aadharUpload, setAadharImage] = useState(null);
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [isAadharUploaded, setisAadharUploaded] = useState(false);
  const [isPanUploaded, setIsPanUploaded] = useState(false);
  const [companylaptop, setCompanylaptop] = useState("yes");
  const status = localStorage.getItem("status");
  const encryptedRoleFromStorage = localStorage.getItem("role");
  const User = JSON.parse(localStorage.getItem("user"));
  const role = decryptRole(encryptedRoleFromStorage);
  const [count, setCount] = useState(0);
  const [latestEmpCode, setLatestEmpCode] = useState(null);

  useEffect(() => {
    fetchList();
    fetchUsers();
    fetchLatestEmpCode();
  }, []);

  const fetchLatestEmpCode = async () => {
    const dbName = localStorage.getItem("dbName"); // Get the dbName from localStorage
    try {
      // Fetch the latest employee code using your API
      const response = await axios.get(
        `${url}/api/employees/latest-empcode/${dbName}`
      );
      console.log("Latest employee code fetched:", response.data);

      // You can set the latest employee code to state or handle it as needed
      setLatestEmpCode(response.data);
    } catch (error) {
      console.error("Failed to fetch the latest employee code", error);
    }
  };

  const masterUpdate = async (values) => {
    try {
      let userType;
      console.log("inside masterupdte");
      if (role === "superAdmin" || role === "channelPatner") {
        userType = values.userType;
      } else {
        userType = localStorage.getItem("userType");
      }

      let dbName;
      if (role === "superAdmin" || role === "channelPartner") {
        dbName = values.dbName;
      } else {
        dbName = localStorage.getItem("dbName");
      }

      const domain = localStorage.getItem("domain");
      // Conditionally set the email field based on userType
      const officeEmail = values.email.toLowerCase();
      // let officeEmail = "";
      // if (userType === "true") {
      //   // Check if userType is "true" (Azure)
      //   const firstName = values.firstName.toLowerCase();
      //   const lastName = values.lastName.toLowerCase();
      //   officeEmail = `${firstName}.${lastName.charAt(0)}@${domain}`;
      // } else if (userType === "false") {
      //   // Native user
      //   officeEmail = values.email.toLowerCase(); // Use the email from values
      // }
      const mobile = values.phoneNumber;
      const masterupdate = {
        ...values,
        userType,
        dbName,
        mobile,
        officeEmail,
      };
      const responses = await axios.post(`${url}/api/masteruser`, masterupdate);
      if (responses.status === 200) {
        console.log("returning 200");
        return responses.status;
      } else {
        return 500;
      }
    } catch (error) {
      console.log("returning 500");
      console.error("Error adding user:", error);
      return 500;
    }
  };

  const handleDBSubmit = async (values) => {
    try {
      console.log("inside handlesubmit");
      if (role === "superAdmin" || role === "channelPartner") {
        await fetchUsers();
      }
      const employeeExist = user.find((p) => p.empCode === values.empCode);
      const email = user.find((p) => p.email === values.email);
      if (employeeExist !== undefined || email !== undefined) {
        if (email !== undefined && employeeExist !== undefined) {
          message.error("Employee Code and Email Already Exist!");
        } else if (email !== undefined) {
          message.error("Email Already Exists!");
        } else if (employeeExist !== undefined) {
          message.error("Employee Code Already Exists!");
        }
      } else {
        const organization =
          organizationData.length > 0 ? organizationData[0].organization : ""; // Get the organization value from the organizationData state variable
        values.profileImage = image;
        values.panCopy = panCopy;
        values.aadharUpload = aadharUpload;

        const selectedUser = values.manager.split("(");
        const manager_displayname = selectedUser[0].trim();
        const manager_email = selectedUser[1].replace(")", "").trim();

        values.manager = manager_displayname;
        values.managerEmail = manager_email;

        console.log(values.profileImage);
        const officeEmail = values.email.toLowerCase(); // Use the email from values
        console.log(officeEmail, "email");
        const updatedValues = { ...values, organization, officeEmail };
        let dbName;
        if (role === "superAdmin" || role === "channelPartner") {
          dbName = values.dbName;
        } else {
          dbName = localStorage.getItem("dbName");
        }

        const response = await axios.post(
          `${url}/api/userdetail/${dbName}`,
          updatedValues
        );

        console.log("User added:", response.data);
        if (response.status === 200) {
          notification.success({ message: "Employee added successfully!" });

          const userType = localStorage.getItem("userType");

          if (userType === "false") {
            const mail = values.email.toLowerCase();
            // Call handleRegister to register the user after adding them
            await handleRegister(mail, values);
          }
          if (userType === "true") {
            console.log("about to call handleAzureSubmit");
            const azureadd = await handleAzureSubmit(values);
            if (azureadd === 200) {
              goBack();
            } else {
              Emp.handleMasterDelete(values);
              Emp.handleDBDelete(values);
              goBack();
            }
          } else {
            goBack();
          }
        } else {
          message.error("Employee Add Failed!");
        }
      }
    } catch (error) {
      console.error("Error in handleDBSubmit:", error);
      message.error("Something Went Wrong");
    }
  };

  const handleImageChange1 = (e, field) => {
    console.log("inside handleImageChange1");
    const file = e.target.files[0];
    if (file) {
      const allowedFileTypes = ["image/jpeg", "image/png", "image/jpg"];

      // Check if the selected file type is in the allowed list
      if (allowedFileTypes.includes(file.type)) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const image = reader.result;

          if (field === "profileImage") {
            setImage(image);
          } else if (field === "panCopy") {
            setPanCopyImage(image);
          } else if (field === "aadharUpload") {
            setAadharImage(image);
          }

          // Check if any image is present and set isImageUploaded accordingly
          const isAnyImageUploaded = !!image || !!panCopy || !!aadharUpload;
          setIsImageUploaded(isAnyImageUploaded);
        };
        reader.readAsDataURL(file);
      } else {
        // Clear the file error message if no file is selected
        form.setFieldsValue({
          profileImage: null,
          aadharUpload: null,
          panCopy: null,
        });
        message.error(
          "Invalid file type. Please upload only jpeg, png, or jpg files."
        );
      }
    }
  };

  const handleDeleteImage = () => {
    // Clear the corresponding image state variable and reset the form field
    setImage(null);
    formRef.current.setFieldsValue({
      profileImage: null,
    });
    // Also, set isImageUploaded to false
    setIsImageUploaded(false);
  };
  const handleDeletePan = () => {
    // Clear the corresponding image state variable and reset the form field
    setPanCopyImage(null);
    formRef.current.setFieldsValue({
      panCopy: null,
    });
    // Also, set isImageUploaded to false
    setIsPanUploaded(false);
  };
  const handleDeleteAadhaar = () => {
    // Clear the corresponding image state variable and reset the form field
    setAadharImage(null);
    formRef.current.setFieldsValue({
      aadharUpload: null,
    });
    // Also, set isImageUploaded to false
    setisAadharUploaded(false);
  };

  const handleAzureSubmit = async (values) => {
    const employeeExist = user.find((p) => p.employeeId === values.empCode);
    console.log("inside handleAzuresubmit");
    console.log(values);
    console.log("calling masterorg details");
    const domain = localStorage.getItem("domain"); // Make sure you have the user's email stored in 'userData' from a previous step
    const response = await axios.get(`${url}/api/getmasterorg/${domain}`);
    const clientId = response.data.org.clientId;
    const tenantId = response.data.org.tenantId;
    const clientSecret = response.data.org.clientSecret;
    const domainName = response.data.org.domain; // Make sure you have the user's email stored in 'userData' from a previous step
    const officeEmail = values.email.toLowerCase();
    try {
      console.log(
        clientId,
        clientSecret,
        tenantId,
        domainName,
        "clientdetails"
      );
      const response = await axios.post(
        `${url}/api/adduser/${clientId}/${tenantId}/${clientSecret}/${domainName}`,
        {
          values,
          officeEmail,
        }
      );
      console.log("after aduser");
      const responseData = response.data;
      console.log(responseData, "resdataadduser");
      return 200;
      //notification.success({message:"Employee added successfully in Azure!");
    } catch (error) {
      //message.error("Employee failed to add in Azure");
      return 500;
    }
  };

  const handleRegister = async (email, values) => {
    try {
      const length = 12;
      const characters =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*";
      let newPassword = "";
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        newPassword += characters.charAt(randomIndex);
      }
      let dbName;
      if (role === "superAdmin" || role === "channelPartner") {
        dbName = values.dbName;
      } else {
        dbName = localStorage.getItem("dbName");
      }
      const response = await axios.post(`${url}/api/register/${dbName}`, {
        email,
        password: newPassword,
      });
      if (response.data.success) {
        //notification.success({message:"egistration successful!")
        console.log("Registration successful!");
      } else {
        //message.error("Registration failed!")
        console.log("Registration failed. Please try again.");
      }
    } catch (error) {
      console.error("Error during registration:", error);
    }
  };

  const fetchList = async () => {
    const dbName = localStorage.getItem("dbName");
    const response = await axios.get(`${url}/api/get/manager/${dbName}`);
    console.log("manager list fetched fetchlist");
    const activeUsers = response.data.filter(
      (user) => user.empStatus !== "inactive"  
    );
    setList(activeUsers);
  };

  function handleReset() {
    setPanCopyImage("");
    setAadharImage("");
    setImage("");
    form.resetFields(); // Reset the form fields using the form object
  }

  const validateFirstName = (rule, value, callback) => {
    const pattern = /^(?=.*[A-Za-z])[A-Za-z ]*$/;
    const number = /^[0-9]+$/;
    const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/; // Regular expression to match email format
    const empcodePattern = /^[A-Za-z0-9._%+-]+$/;
    const addressPattern = /^(?=.*[A-Za-z0-9,./])[ A-Za-z0-9,./]+$/;
    const empdependents = /[!@#$%^&*()_+{}\[\]:;<>.?~\\]/;
    if (
      rule.field === "firstName" &&
      (!pattern.test(value) || value === undefined)
    ) {
      callback("Please enter valid value");
    } else if (
      rule.field === "lastName" &&
      (!pattern.test(value) || value === undefined)
    ) {
      callback("Please enter valid value");
    } else if (
      rule.field === "email" &&
      (!emailPattern.test(value) || value === undefined)
    ) {
      callback("Please enter a valid value");
    } else if (
      rule.field === "empCode" &&
      (!empcodePattern.test(value) || value === undefined)
    ) {
      callback("Please enter a valid value");
    } else if (rule.field === "empDesignation" && !pattern.test(value)) {
      callback("Please enter only alphabetic characters for Position");
    } else if (rule.field === "department" && !pattern.test(value)) {
      callback("Please enter only alphabetic characters for Department");
    } else if (rule.field === "accountNumber" && !number.test(value)) {
      callback("Please enter only numeric value");
      // } else if (rule.field === "bankName" && !pattern.test(value)) {
      //   callback("Please enter only alphabetic characters for Bank Name");
    } else if (rule.field === "ifscCode" && !empcodePattern.test(value)) {
      callback("Please enter a valid value");
    } else if (rule.field === "address" && !addressPattern.test(value)) {
      callback("Please enter only alphabetic characters for Address");
    } else if (rule.field === "casualLeave" && !number.test(value)) {
      callback("Please enter a valid value");
    } else if (rule.field === "annualLeave" && !number.test(value)) {
      callback("Please enter a valid value");
    } else if (rule.field === "empDependents" && empdependents.test(value)) {
      callback(
        "Please enter only alphabetic characters for employee dependent"
      );
    } else {
      callback();
    }
  };

  const fetchUsers = async () => {
    try {
      const dbName = localStorage.getItem("dbName");
      const response = await fetch(`${url}/api/get/allemplist/${dbName}`);
      const data = await response.json();
      setUser(data);
      const channelName = User.displayName;
      const orgName = await axios.get(`${url}/api/master-orgs/${role}`, {
        params: { channelName },
      });
      setData(orgName.data);
      console.log(orgName.data, "org");
      const domain = localStorage.getItem("domain");
      setDomain(domain);
      console.log(domain, "domain");
    } catch (error) {
      console.error(error);
    }
  };

  // const getEmail = () => {
  //   let email;
  //   const trimmedEmail = emailValue.trim().toLowerCase();
  //   if (User.jobTitle === "superAdmin") {
  //     const trimmedDomain = selectedDomain.trim().toLowerCase();
  //     const joinedDomain = trimmedDomain.replace(/\s+/g, "");
  //     console.log(joinedDomain, "trim");
  //     email = `${trimmedEmail}@${joinedDomain}`;
  //   } else {
  //     email = `${trimmedEmail}@${domain}`;
  //   }
  //   return email;
  // };

  const onFinish = async (values) => {
    const userType = localStorage.getItem("userType");
    let dbName;
    // values.email = getEmail();
    console.log(values, "values");
    if (role === "superAdmin" || role === "channelPartner") {
      dbName = values.dbName;
    } else {
      dbName = localStorage.getItem("dbName");
    } // If usertype is true, perform domain validation
    if (userType === "true") {
      const domain = localStorage.getItem("domain");
      const response = await axios.get(`${url}/api/getmasterorg/${domain}`);
      const domainName = response.data.org.domain;
      const userDomain = values.email.split("@")[1];

      try {
        if (domainName !== userDomain) {
          message.error(
            "Domain in the Official Email does not match the expected domain."
          );
          throw new Error(
            "Domain in the user's email does not match the expected domain."
          );
        }
      } catch (error) {
        console.error(error.message);
        // Handle domain validation error, e.g., show a message to the user
        return;
      }
    }
    const updatedValues = {
      empid: values.empCode,
      empName: `${values.firstName} ${values.lastName}`,
      casualLeaveBal: values.casualLeave,
      annualLeaveBal: values.annualLeave,
    };
    const Send = axios
      .put(`${url}/api/leavebalance/${dbName}`, updatedValues)
      .then((response) => {
        console.log("successfully updated leave balance table");
      });
    const resp = await masterUpdate(values);
    console.log("resp from masterupdate", resp);
    if (resp === 200) {
      console.log("about to call handledbsubmit");
      const dbresp = await handleDBSubmit(values);
    } else {
      //delete from master
      const respdel = Emp.handleMasterDelete(values);
    }
  };

  const validateYearFormat = (rule, value, callback) => {
    // Check if the value is a valid date with a 4-digit year
    const regex = /^\d{4}-\d{2}-\d{2}$/; // YYYY-MM-DD format
    if (!regex.test(value)) {
      callback("Please enter a valid date in DD-MM-YYYY format.");
    } else {
      callback();
    }
  };

  // Add a function to handle the companyLaptop change event
  const handleCompanyLaptopChange = (value) => {
    console.log("Company Laptop Value (onChange):", value);
    // You can also set the value in the form if needed
    setCompanylaptop(value);
  };

  const onFinishFailed = ({ errorFields }) => {
    const [firstErrorField] = errorFields;
    const { name } = firstErrorField || {};
    if (name) {
      const errorElement = document.getElementById(name[0]);
      if (errorElement) {
        errorElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  };
  const goBack = () => {
    navigate(-1);
  };
  return (
    <Card className="employee-form" style={{ zoom: "100%" }}>
      <Button onClick={goBack}>Back</Button>
      <h3 className="text-center mb-5">Employee onBoard</h3>
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
        style={{ zoom: "90%" }}
      >
        <Row gutter={[16, 16]}>
          {/* Personal Information */}
          <Col xs={24} sm={12} md={8} lg={8} xl={8} className="form-section">
            <h5>Personal Information</h5>
            <Divider />
            <Form.Item
              name="empCode"
              label="Employee ID"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Employee ID is required",
                },
                {
                  validator: validateFirstName,
                },
              ]}
            >
              <div>
                <Input placeholder="Enter your employee ID" />
                {latestEmpCode && (
                  <p style={{ marginTop: "8px", color: "#888" }}>
                    Latest Employee ID: {latestEmpCode}
                  </p>
                )}
              </div>
            </Form.Item>
            <Form.Item
              name="firstName"
              label="First Name"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "",
                },
                {
                  validator: validateFirstName,
                },
              ]}
            >
              <Input placeholder="Enter your name" />
            </Form.Item>

            <Form.Item
              name="lastName"
              label="Last Name"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "",
                },
                {
                  validator: validateFirstName,
                },
              ]}
            >
              <Input placeholder="Enter your last name" />
            </Form.Item>
            <Form.Item
              name="gender"
              label="Gender"
              rules={[{ required: true, message: "Please choose the gender!" }]}
            >
              <Radio.Group>
                <Radio value="male">Male</Radio>
                <Radio value="female">Female</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="email"
              label="Official Email"
              hasFeedback
              rules={[
                {
                  type: "email",
                  message: "",
                },
                {
                  required: true,
                  message: "Please enter your email",
                },
              ]}
            >
              <Input
                placeholder="Email address"
                value={emailValue}
                onChange={(e) => setEmailValue(e.target.value)}
              />
            </Form.Item>

            <Form.Item
              name="dateOfBirth"
              label="Date of Birth"
              rules={[
                {
                  required: true,
                  message: "",
                },
                {
                  validator: validateYearFormat, // Use the custom validation function
                },
              ]}
            >
              <Input style={{ height: "40px" }} type="date" size="large" />
            </Form.Item>
            <Form.Item
              name="aadharNumber"
              label="Aadhar Number (Eg. 222233334444)"
              hasFeedback
              // rules={[
              //   { required: true, message: "Please enter Aadhar Number!" },
              //   {
              //     pattern: /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/,
              //     message:
              //       "Aadhar number should be a 12-digit number, and should not start with 0 or 1.",
              //   },
              // ]}
            >
              <Input placeholder="Please enter your Aadhar Number" />
            </Form.Item>

            <Form.Item
              name="pan"
              label="PAN (Eg. ABCTY1234D)"
              hasFeedback
              // rules={[
              //   { required: true, message: "Please enter PAN!" },
              //   {
              //     pattern: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
              //     message: "PAN should be in the format AAAAA1111A.",
              //   },
              // ]}
            >
              <Input placeholder="Please enter your PAN Number" />
            </Form.Item>

            <Form.Item
              name="bloodGroup"
              label="Blood Group"
              rules={[
                {
                  required: false,
                  message: "Please enter your Blood group!",
                },
                {
                  validator: validateFirstName, // Use the custom validation function
                },
              ]}
            >
              <Input
                placeholder="Please enter your Blood Group"
                style={{
                  fontSize: "14px",
                }}
              />
            </Form.Item>
            <Form.Item
              name="empDependents"
              label="Employee Dependent"
              rules={[
                {
                  required: false,
                  message: "Please enter Employee dependent!",
                },
                {
                  validator: validateFirstName, // Use the custom validation function
                },
              ]}
            >
              <Input
                placeholder="Please enter your Dependent Name"
                style={{
                  fontSize: "14px",
                }}
              />
            </Form.Item>
          </Col>
          {/* Professional Information */}
          <Col xs={24} sm={12} md={8} lg={8} xl={8} className="form-section">
            <h5>Professional Information</h5>
            <Divider />
            <Form.Item
              name="dateOfJoining"
              label="Date of Joining"
              rules={[
                {
                  required: true,
                  message: "",
                },
                {
                  validator: validateYearFormat, // Use the custom validation function
                },
              ]}
            >
              <Input style={{ height: "40px" }} type="date" size="large" />
            </Form.Item>
            <Form.Item
              name="empType"
              label="Employee Type"
              hasFeedback
              rules={[
                { required: true, message: "Please select Employee Type!" },
              ]}
            >
              <Select
                placeholder="Select Emp Type"
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              >
                <Option value="permanent">Permanent</Option>
                <Option value="contract">Contract</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="empStatus"
              label="Employee Status"
              hasFeedback
              rules={[
                { required: true, message: "Please select Employee Status!" },
              ]}
            >
              <Select
                placeholder="Select Emp Status"
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              >
                <Option value="active">Active</Option>
                <Option value="inactive">Inactive</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="empDesignation"
              label="Position"
              hasFeedback
              style={{ marginBottom: "16px" }}
              rules={[
                { required: true, message: "" },
                {
                  validator: validateFirstName,
                },
              ]}
            >
              <Input
                style={{ height: "40px" }}
                placeholder="Enter your Position"
              />
            </Form.Item>
            <Form.Item
              name="jobTitle"
              label="Role"
              rules={[{ required: true, message: "Please select your Role!" }]}
            >
              <Select
                placeholder="Select your position"
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              >
                {role === "superAdmin" && (
                  <>
                    <Option value="admin">Admin</Option>
                    <Option value="channelPartner">Channel Partner</Option>
                  </>
                )}

                {role === "admin" && (
                  <>
                    <Option value="employee">Employee</Option>
                    <Option value="manager">Manager</Option>
                    <Option value="hr">HR</Option>
                  </>
                )}
                {role === "channelPartner" && (
                  <>
                    <Option value="admin">Admin</Option>
                  </>
                )}

                {role === "hr" && (
                  <>
                    <Option value="employee">Employee</Option>
                    <Option value="manager">Manager</Option>
                    <Option value="hr">HR</Option>
                  </>
                )}

                {role === "manager" && (
                  <>
                    <Option value="employee">Employee</Option>
                    <Option value="manager">Manager</Option>
                  </>
                )}
              </Select>
            </Form.Item>
            {(role === "superAdmin" || role === "channelPartner") && (
              <Form.Item
                name="userType"
                label="UserType"
                rules={[{ required: true, message: "Please select UserType!" }]}
              >
                <Select
                  placeholder="Select your UserType"
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                >
                  <Option value="true">Azure AD User</Option>
                  <Option value="false">Native User</Option>
                </Select>
              </Form.Item>
            )}

            {role === "superAdmin" || role === "channelPartner" ? (
              <Form.Item
                name="dbName"
                label="Organisation Name"
                rules={[
                  {
                    required: true,
                    message: "Please select Organisation Name!",
                  },
                ]}
              >
                <Select
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  placeholder="Select your Organisation Name"
                  onChange={(value) => {}}
                >
                  {role === "channelPartner" &&
                    data.map((org) => (
                      <Option key={org._id} value={org.dbName}>
                        {org.orgName}
                      </Option>
                    ))}
                  {role === "superAdmin" &&
                    data.map((org) => (
                      <Option key={org._id} value={org.dbName}>
                        {org.dbName}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            ) : null}

            <Form.Item
              name="department"
              label="Department"
              style={{ marginBottom: "16px" }}
              rules={[
                { required: true, message: "" },
                {
                  validator: validateFirstName, // Use the custom validation function
                },
              ]}
            >
              <Input
                style={{ height: "30px", fontSize: "14px" }}
                placeholder="Enter your department"
              />
            </Form.Item>
            {role !== "superAdmin" && role !== "channelPartner" && (
              <>
                <Form.Item
                  label="Reporting Manager"
                  name="manager"
                  rules={[
                    {
                      required: true,
                      message: "Please select your reporting manager!",
                    },
                  ]}
                >
                  <Select
                    style={{ height: "40px" }}
                    placeholder="Please select the Manager"
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  >
                    {list.map((user) => (
                      <Select.Option
                        key={user.id}
                        value={`${user.displayName}(${user.email})`}
                      >
                        {user.displayName}({user.email})
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="HR"
                  name="hrName"
                  rules={[
                    {
                      // required: true,
                      message: "Please input HR Name!",
                    },
                  ]}
                >
                  <Select
                    style={{ height: "40px" }}
                    placeholder="Please select the HR"
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  >
                    {user
                      .filter(
                        (users) =>
                          users.jobTitle === "hr" &&
                          users.empStatus !== "inactive"
                      )
                      .map((users, index) => (
                        <Option key={users.index} value={users.displayName}>
                          {users.displayName}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </>
            )}

            <Form.Item
              name="yoe"
              label="Years of experience"
              rules={[
                { required: true, message: "Please select the Years of exp!" },
              ]}
            >
              <Select
                placeholder="Please select the Years of exp!"
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              >
                {[...Array(31)].map((_, index) => (
                  <Option key={index} value={index}>
                    {index}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="companyLaptop"
              label="Company Laptop"
              style={{ marginBottom: "16px" }}
            >
              <Select
                placeholder="Select Company Laptop status"
                onChange={(value) => handleCompanyLaptopChange(value)}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              >
                <Option value="yes">Yes</Option>
                <Option value="no">No</Option>
              </Select>
            </Form.Item>
            {console.log("Company Laptop Value:", companylaptop)}
            {companylaptop === "yes" && (
              <>
                <Form.Item
                  name="laptopSerial"
                  label="Laptop Serial Number"
                  style={{ marginBottom: "16px" }}
                >
                  <Input
                    style={{ height: "30px", fontSize: "14px" }}
                    placeholder="Enter laptop serial number "
                  />
                </Form.Item>
                <Form.Item
                  name="laptopWarranty"
                  label="Laptop Warranty Date"
                  style={{ marginBottom: "16px" }}
                >
                  <Input
                    style={{ height: "30px", fontSize: "14px" }}
                    type="date"
                    placeholder="Please select laptop warranty date "
                  />
                </Form.Item>
              </>
            )}
            <Form.Item
              name="accessaries"
              label="Accessories"
              style={{ marginBottom: "16px" }}
            >
              <Input
                style={{ height: "30px", fontSize: "14px" }}
                placeholder="Enter your accessories"
              />
            </Form.Item>
            {/* ... similar fields for the second grid/column */}
          </Col>
          {/* Contact Information */}
          <Col xs={24} sm={12} md={8} lg={8} xl={8} className="form-section">
            <h5>Contact Information</h5>
            <Divider />
            <Form.Item
              name="personalemail"
              label="PersonalEmail"

              // rules={[
              //   {
              //     required: true,
              //     type: "email",
              //     message: "Please enter a valid Email!",
              //   },
              // ]}
            >
              <Input
                placeholder="Enter your Personal Email"
                style={{
                  fontSize: "14px",
                }}
              />
            </Form.Item>
            <Form.Item
              name="phoneNumber"
              label="MobileNumber"
              hasFeedback
              rules={[
                { required: true, message: "Please enter the Mobile number!" },
                {
                  pattern: /^[0-9]{10}$/,
                  message: "Mobile number should be a 10-digit number.",
                },
              ]}
            >
              <Input placeholder="Enter your Mobile number!" />
            </Form.Item>
            <Form.Item
              name="alternateMobile"
              label="Alternate Mobile Number"
              rules={[
                { required: false, message: "Please enter the Mobile number!" },
                {
                  pattern: /^[0-9]{10}$/,
                  message: "Mobile number should be a 10-digit number.",
                },
              ]}
            >
              <Input
                placeholder="Enter your Alternate Mobile Number"
                style={{
                  fontSize: "14px",
                }}
              />
            </Form.Item>
            <Form.Item
              name="emergencyContact"
              label="Emergency Contact"
              rules={[
                {
                  required: true,
                  message: "Please enter the Emergency Contact!",
                },
                {
                  pattern: /^[0-9]{10}$/,
                  message: "Mobile number should be a 10-digit number.",
                },
              ]}
            >
              <Input
                placeholder="Enter your Emergency Contact"
                style={{
                  fontSize: "14px",
                }}
              />
            </Form.Item>
            <Form.Item
              name="address"
              label="Address"

              // rules={[
              //   { required: true, message: "" },
              //   {validator:validateFirstName}
              // ]}
            >
              <Input
                placeholder="Enter your Address!"
                style={{
                  fontSize: "14px",
                }}
              />
            </Form.Item>

            <h5>Leave Information</h5>
            <Divider />
            <Form.Item
              name="casualLeave"
              label="Casual Leave"
              hasFeedback
              rules={[
                { required: true, message: "" },
                { validator: validateFirstName },
              ]}
            >
              <Input placeholder="Enter your Casual Leave!" />
            </Form.Item>
            <Form.Item
              name="annualLeave"
              label="Annual Leave"
              hasFeedback
              rules={[
                { required: true, message: "" },
                { validator: validateFirstName },
              ]}
            >
              <Input placeholder="Enter your Annual Leave!" />
            </Form.Item>
            {/* ... similar fields for the third grid/column */}
            <h5>Bank Details</h5>
            <Divider />
            <Form.Item
              name="accountNumber"
              label="Bank Account Number"
              rules={[
                { required: false, message: "" },
                // { validator: validateFirstName },
              ]}
            >
              <Input
                placeholder="Enter your Account Number"
                style={{
                  fontSize: "14px",
                }}
              />
            </Form.Item>
            <Form.Item name="bankName" label="Bank Name">
              <Input
                placeholder="Enter your Bank Name"
                style={{
                  fontSize: "14px",
                }}
              />
            </Form.Item>
            <Form.Item
              name="ifscCode"
              label="IFSC Code"
              // rules={[{ required: true, message: "" },{validator:validateFirstName}]}
            >
              <Input
                placeholder="Enter your IFSC Code"
                style={{
                  fontSize: "14px",
                }}
              />
            </Form.Item>
            <Form.Item
              name="accountType"
              label="Account Type"

              // rules={[{ required: true, message: "Account Type is required" }]}
            >
              <Select
                placeholder="Select Account Type"
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              >
                <Option value="savings">Savings</Option>
                <Option value="current">Current</Option>
              </Select>
            </Form.Item>
            {/* ...add more bank fields here */}
          </Col>
        </Row>
        {/* Uploads Section */}
        <Row gutter={[16, 16]}>
          <Col span={24} className="form-section">
            <h5>Uploads</h5>
            <Divider />
            <Row justify={"space-between"}>
              <Col>
                <Form.Item name="profileImage" label="Photo Upload">
                  <label className="custom-file-upload">
                    <UploadOutlined />
                    Upload Profile Image
                    <Input
                      type="file"
                      accept="image/jpeg, image/png, image/jpg"
                      onChange={(e) => handleImageChange1(e, "profileImage")}
                      style={{ display: "none" }} // Hide the input element
                    />
                  </label>
                  {image && (
                    <div>
                      <img src={image} alt="Uploaded Image" width="200" />
                      {isImageUploaded && (
                        <Button
                          danger
                          size="medium"
                          icon={<DeleteFilled />}
                          onClick={handleDeleteImage}
                        />
                      )}
                    </div>
                  )}
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name="panCopy" label="PAN Upload">
                  <label className="custom-file-upload">
                    <UploadOutlined />
                    Upload Pancopy
                    <Input
                      type="file"
                      accept="image/jpeg, image/png, image/jpg"
                      onChange={(e) => handleImageChange1(e, "panCopy")}
                      style={{ display: "none" }}
                    />
                  </label>
                  {panCopy && (
                    <div>
                      <img src={panCopy} alt="Uploaded Pancopy" width="200" />
                      {/* {isPanUploaded && (
                        <Button
                          danger
                          size="medium"
                          icon={<DeleteFilled />}
                          onClick={handleDeletePan}
                        />
                      )} */}
                      <Button
                        danger
                        size="medium"
                        icon={<DeleteFilled />}
                        onClick={handleDeletePan}
                      />
                    </div>
                  )}
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name="aadharUpload" label="Aadhar Upload">
                  <label className="custom-file-upload">
                    <UploadOutlined />
                    Upload Aadhar
                    <Input
                      type="file"
                      accept="image/jpeg, image/png, image/jpg"
                      onChange={(e) => handleImageChange1(e, "aadharUpload")}
                    />
                  </label>
                  {aadharUpload && (
                    <div>
                      <img
                        src={aadharUpload}
                        alt="Uploaded Aadhar"
                        width="200"
                      />
                      {/* {isAadharUploaded && (
                        <Button
                          danger
                          size="medium"
                          icon={<DeleteFilled />}
                          onClick={handleDeleteAadhaar}
                        />
                      )} */}
                      <Button
                        danger
                        size="medium"
                        icon={<DeleteFilled />}
                        onClick={handleDeleteAadhaar}
                      />
                    </div>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* Bank Details Section */}
        <Row gutter={[16, 16]}></Row>

        <Form.Item>
          <Button size="large" danger type="primary" onClick={handleReset}>
            Reset
          </Button>

          <Button
            type="primary"
            htmlType="submit"
            className="submit-button"
            disabled={status === "disable"}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default EmpApply;
