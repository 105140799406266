import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Table,
  notification,
  Row,
  Col,
  Popconfirm,
  Select
} from "antd";
import axios from "axios";
import { url } from "../../url/url";
import {
  EditOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
const { Option } = Select;
const BillingTypeManager = ({ isVisible, handleModalClose }) => {
  const [billingTypes, setBillingTypes] = useState([]);
  const [selectedBillingType, setSelectedBillingType] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    const fetchBillingTypes = async () => {
      try {
        const response = await axios.get(`${url}/api/billing-types`);
        setBillingTypes(response.data);
      } catch (error) {
        console.error("Error fetching billing types:", error);
        notification.error({
          message: "Fetch Error",
          description: "There was an error fetching billing types.",
        });
      }
    };

    fetchBillingTypes();
  }, []);

  const handleSubmit = async (values) => {
    try {
      if (selectedBillingType) {
        await axios.put(
          `${url}/api/billing-types/${selectedBillingType._id}`,
          values
        );
        notification.success({
          message: "Success",
          description: "Billing type updated successfully.",
        });
      } else {
        await axios.post(`${url}/api/billing-types`, values);
        notification.success({
          message: "Success",
          description: "New billing type added successfully.",
        });
      }
      //handleModalClose();
      const refreshResponse = await axios.get(`${url}/api/billing-types`);
      form.resetFields();
      setShowForm(false);
      setBillingTypes(refreshResponse.data);
    } catch (error) {
      console.error("Error submitting billing type:", error);
      notification.error({
        message: "Submission Error",
        description: "There was an error submitting the billing type.",
      });
    }
  };
  const handleDelete = async () => {
    if (!selectedBillingType) {
      console.error("No billing type selected for deletion");
      return;
    }

    try {
      // Close the modal and reset selected billing type
      handleModalClose();
      setSelectedBillingType(null);
      form.resetFields();

      // Call the API to delete the billing type
      await axios.delete(`${url}/api/billing-types/${selectedBillingType._id}`);

      // Show a success message
      notification.success({
        message: "Delete Success",
        description: "Billing type deleted successfully.",
      });

      // Update the local state to remove the billing type from the table
      setBillingTypes((prevBillingTypes) =>
        prevBillingTypes.filter((bt) => bt._id !== selectedBillingType._id)
      );
    } catch (error) {
      console.error("Error deleting billing type:", error);
      notification.error({
        message: "Delete Error",
        description: "There was an error deleting the billing type.",
      });
    }
  };

  const handleEdit = (billingType) => {
    setSelectedBillingType(billingType);
    form.setFieldsValue(billingType);
    setShowForm(true);
  };

  const handleAddNew = () => {
    setSelectedBillingType(null);
    form.resetFields();
    setShowForm(true);
  };

  const columns = [
    {
      title: "Billing Type",
      dataIndex: "billingPlanname",
      key: "billingPlanname",
    },
    {
      title: "Billing Plan ID",
      dataIndex: "billingPlanID",
      key: "billingPlanID",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Plan Status",
      dataIndex: "planstatus",
      key: "planstatus",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <span>
          <EditOutlined
            onClick={() => handleEdit(record)}
            style={{ color: "blue", marginRight: 16, cursor: "pointer" }}
          />
          <Popconfirm
            title="Are you sure to delete this record ? ?"
            onConfirm={() => handleDelete(record)}
            icon={
              <QuestionCircleOutlined
                style={{
                  color: "red",
                }}
              />
            }
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined
              //   onClick={() => handleDelete(record)}
              style={{ color: "red", cursor: "pointer" }}
            />
          </Popconfirm>
        </span>
      ),
    },
  ];

  return (
    <Modal
      title="Manage Billing Types"
      visible={isVisible}
      onCancel={() => {
        handleModalClose();
        setShowForm(false);
      }}
      width={800} // Adjust the width as per your content
      footer={null}
      bodyStyle={{ padding: "20px" }} // You can adjust the padding
    >
      <Button
        onClick={handleAddNew}
        type="primary"
        style={{ marginBottom: 16 }}
      >
        Add New Billing Type
      </Button>

      <Table
        dataSource={billingTypes}
        columns={columns}
        scroll={{ y: 240 }}
        style={{ width: "100%" }}
        className="custom-table col-lg-12 col-md-12 col-sm-12"
        sticky
      />
      {showForm && (
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="billingPlanname"
                label="Billing Type Name"
                rules={[
                  { required: true, message: "Please enter Billing Type Name" },
                  {
                    pattern: /^[A-Za-z ]*$/,
                    message: "Please enter only alphabetic characters",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="planstatus" label="Plan Status">
                <Select>
                  <Option value="active">Active</Option>
                  <Option value="inactive">Inactive</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {/* ... and so on for other fields ... */}

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="description" label="Description">
                <Input.TextArea />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
              {selectedBillingType ? "Update" : "Add"}
            </Button>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default BillingTypeManager;
