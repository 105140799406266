// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 768px) {
    .ant-table {
      width: 100%;
    }
  }
/* Add this CSS in your stylesheet */
.custom-modal .ant-modal-footer .ant-btn:last-child {
  display: none;
}

.custom-table .ant-table-thead > tr > th {
  background-color: #c0d6e8; /* Set the background color of table header */
  color: black; /* Set the text color of table header */
}

.buttons{
  background-color:#ffff;
}`, "",{"version":3,"sources":["webpack://./src/pages/project/Details.css"],"names":[],"mappings":"AAAA;IACI;MACE,WAAW;IACb;EACF;AACF,oCAAoC;AACpC;EACE,aAAa;AACf;;AAEA;EACE,yBAAyB,EAAE,6CAA6C;EACxE,YAAY,EAAE,uCAAuC;AACvD;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":["@media screen and (max-width: 768px) {\n    .ant-table {\n      width: 100%;\n    }\n  }\n/* Add this CSS in your stylesheet */\n.custom-modal .ant-modal-footer .ant-btn:last-child {\n  display: none;\n}\n\n.custom-table .ant-table-thead > tr > th {\n  background-color: #c0d6e8; /* Set the background color of table header */\n  color: black; /* Set the text color of table header */\n}\n\n.buttons{\n  background-color:#ffff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
