// import React, { useEffect, useState } from "react";
// import { gantt } from "dhtmlx-gantt";
// import "dhtmlx-gantt/codebase/dhtmlxgantt.css";
// import axios from "axios";
// import { url } from "../../url/url";

// // Custom CSS to hide the "+" button
// const customCSS = `
// .gantt_row.gantt_row_task .gantt_add:before {
//   display: none !important;
// }
// `;

// const GanttChart = () => {
//   const [tasks, setTasks] = useState(null);

//   useEffect(() => {
//     // Function to fetch data from backend API
//     const fetchData = async () => {
//       try {
//         const dbName = localStorage.getItem("dbName");
//         const response = await axios.get(
//           `${url}/getByProject/activity/${dbName}/Tata app-activity1`
//         );
//         // Convert array of objects to an object with 'data' property
//         const tasksData = {
//           data: response.data.map((task) => ({
//             id: task.activityId, // Use activityId as task id
//             text: task.activityName,
//             start_date: task.startDate ? new Date(task.startDate) : null, // Use start date if available, otherwise null
//             duration: calculateDuration(task.startDate, task.estimatedHours), // Calculate duration excluding weekends
//             actual: +task.weekId.reduce(
//               (total, week) => total + week.actualHours,
//               0
//             ),
//           hideExpansionSymbol:true,
//           expanded_branch:false
//           })),

//         };
//         setTasks(tasksData);
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       }
//     };
//     fetchData();
//   }, []);

//   useEffect(() => {
//     if (tasks) {
//     gantt.config.xml_date = "%Y-%m-%d %H:%i";
//     gantt.init("ganttContainer");

//     // Load data to Gantt chart
//     gantt.parse(tasks);
//    console.log(tasks,"task");
//     // Customize task rendering based on hideExpansionSymbol property
//     gantt.attachEvent("onBeforeTaskDisplay", (id, task) => {
//       if (task.hideExpansionSymbol) {
//         // Hide the expansion symbol for rows with hideExpansionSymbol = true
//         const taskRow = document.querySelector(`.gantt_row.task_row[data-task-id="${id}"]`);

//         if (taskRow) {
//           const treeIcon = taskRow.querySelector(".gantt_tree_icon");
//           if (treeIcon) {
//             treeIcon.style.display = "none";
//           }
//         }
//       }
//       return true; // Return true to display the task normally
//     });
//   }
// }, [tasks]);
//   // Function to calculate duration excluding weekends
//   const calculateDuration = (startDate, estimatedHours) => {
//     if (!startDate) return 0; // Return 0 if no start date
//     const start = new Date(startDate);
//     const end = new Date(start);
//     let remainingHours = +estimatedHours;
//     while (remainingHours > 0) {
//       end.setDate(end.getDate() + 1);
//       if (end.getDay() !== 0 && end.getDay() !== 6) {
//         // Exclude Saturdays and Sundays
//         remainingHours--;
//       }
//     }
//     return Math.ceil((end - start) / (1000 * 60 * 60 * 24));
//   };

//   return (
//     <div id="ganttContainer" style={{ width: "100%", height: "500px" }}>
//  <style>{`
//         .gantt_row.gantt_row_task .gantt_add:before {
//           display: none !important;
//         }
//       `}</style>    </div>
//   );
// };

// export default GanttChart;

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Line } from 'react-chartjs-2';
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
//   ArcElement,
// } from "chart.js";
// import { url } from '../../url/url';

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
//   ArcElement
// );
// const BarChart = () => {
//   const [activities, setActivities] = useState([]);

//   useEffect(() => {
//     const fetchActivities = async () => {
//       try {
//         const dbName= localStorage.getItem("dbName");
//         const response = await axios.get(
//           `${url}/getByProject/activity/${dbName}/Tata app-activity1`
//           );
//         setActivities(response.data);
//       } catch (error) {
//         console.error('Error fetching activities:', error);
//       }
//     };

//     fetchActivities();
//   }, []);

//   // Count activities based on their status
//   const statusCounts = activities.reduce((acc, activity) => {
//     acc[activity.status] = (acc[activity.status] || 0) + 1;
//     return acc;
//   }, {});

// // Count activities assigned to different users
// const userCounts = activities.reduce((acc, activity) => {
//   if (Array.isArray(activity.assignee)) {
//     activity.assignee.forEach(assignee => {
//       acc[assignee] = (acc[assignee] || 0) + 1;
//     });
//   } else {
//     // If assignee is not an array, treat it as a single assignee
//     const assignee = activity.assignee;
//     acc[assignee] = (acc[assignee] || 0) + 1;
//   }
//   return acc;
// }, {});

//   const data = {
//     labels: Object.keys(statusCounts),
//     datasets: [
//       {
//         label: 'Activities by Status',
//         backgroundColor: 'rgba(75,192,192,0.2)',
//         borderColor: 'rgba(75,192,192,1)',
//         borderWidth: 1,
//         hoverBackgroundColor: 'rgba(75,192,192,0.4)',
//         hoverBorderColor: 'rgba(75,192,192,1)',
//         data: Object.values(statusCounts),
//       },
//       {
//         label: 'Activities by Assignee',
//         backgroundColor: 'rgba(255,99,132,0.2)',
//         borderColor: 'rgba(255,99,132,1)',
//         borderWidth: 1,
//         hoverBackgroundColor: 'rgba(255,99,132,0.4)',
//         hoverBorderColor: 'rgba(255,99,132,1)',
//         data: Object.values(userCounts),
//       },
//     ],
//   };

//   return (
//     <div>
//       <h2>Activity Line Chart</h2>
//       <Line data={data} />
//     </div>
//   );
// };

// export default BarChart;

import React, { useEffect, useState, useCallback } from "react";
import { url } from "../../url/url";
import { Line, Bar, Pie } from "react-chartjs-2";
import axios from "axios";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  PieController,
  ArcElement,
} from "chart.js";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  PieController,
  ArcElement
);

const BarChart = ({ selectedProject }) => {
  const [burndownData, setBurndownData] = useState(null);
  const [existingSprints, setExistingSprints] = useState(null);

  const fetchActivityData = useCallback(async (projectName, dbName) => {
    try {
      const response = await axios.get(
        `${url}/getByProject/activity/${dbName}/${projectName}`
      );
      const activities = response.data || [];
      const labels = activities.map((activity) => activity.activityName.trim());
      const estimatedHours = activities.map(
        (activity) => parseFloat(activity.estimatedHours) || 0
      );
      const actualHours = activities.map((activity) => {
        const totalActualHours = (activity.weekId || []).reduce(
          (sum, week) => sum + parseFloat(week.actualHours || 0),
          0
        );
        return totalActualHours;
      });

      setBurndownData({
        labels: labels,
        datasets: [
          {
            label: "Estimated Hours",
            data: estimatedHours,
            fill: false,
            borderColor: "green",
          },
          {
            label: "Actual Hours",
            data: actualHours,
            fill: false,
            borderColor: "red",
          },
        ],
      });
    } catch (error) {
      console.error("Error fetching activity data:", error);
      setBurndownData(null);
    }
  }, []);

  const fetchSprintsByProjectName = useCallback(async (projectName, dbName) => {
    try {
      const response = await fetch(`${url}/sprints/${projectName}/${dbName}`);
      const sprints = await response.json();

      const sprintDetails = await Promise.all(
        sprints.map(async (sprint) => {
          const featureResponse = await fetch(
            `${url}/feature/${sprint.sprintId}/${dbName}`
          );
          const features = await featureResponse.json();

          const featurePromises = features.map(async (feature) => {
            const activityResponse = await fetch(
              `${url}/getByfeature/activity/${dbName}/${feature.featureId}`
            );
            const activities = await activityResponse.json();
            const totalActualHours = activities.reduce((total, activity) => {
              return (
                total +
                (activity.weekId || []).reduce(
                  (acc, week) => acc + (week.actualHours || 0),
                  0
                )
              );
            }, 0);

            return totalActualHours;
          });

          const totalActualHoursForSprint = (
            await Promise.all(featurePromises)
          ).reduce((acc, total) => acc + total, 0);

          return {
            sprintName: sprint.sprintName,
            totalActualHours: totalActualHoursForSprint,
          };
        })
      );

      setExistingSprints(sprintDetails);
    } catch (error) {
      console.error("Error:", error);
      setExistingSprints(null);
    }
  }, []);

  useEffect(() => {
    if (selectedProject) {
      const dbName = localStorage.getItem("dbName");
      fetchActivityData(selectedProject.ProjectName, dbName);
      fetchSprintsByProjectName(selectedProject.ProjectName, dbName);
    }
  }, [selectedProject, fetchActivityData, fetchSprintsByProjectName]);

  const chartData = {
    labels: existingSprints
      ? existingSprints.map(
          (sprint) => `${sprint.sprintName} - ${sprint.totalActualHours}hrs`
        )
      : [],
    datasets: [
      {
        data: existingSprints
          ? existingSprints.map((sprint) => sprint.totalActualHours)
          : [],
        backgroundColor: [
          "rgba(255, 99, 132, 0.6)",
          "rgba(54, 162, 235, 0.6)",
          "rgba(255, 206, 86, 0.6)",
          "rgba(75, 192, 192, 0.6)",
          "rgba(153, 102, 255, 0.6)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const option = {
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    layout: {
      padding: 20,
    },
    radius: "50%",
  };

  const options = {
    scales: {
      x: {
        type: "category",
        display: true,
        title: {
          display: true,
          text: "Activity",
        },
      },
      y: {
        type: "linear",
        display: true,
        title: {
          display: true,
          text: "Actual Hours",
        },
      },
    },
  };

  return (
    <div className="d-flex justify-content-between">
      <div className="card" style={{ width: "48%", height: "auto" }}>
        <div className="card-header">Activity Chart</div>
        <div className="card-body">
          {burndownData && <Line data={burndownData} options={options} />}
        </div>
      </div>
      <div className="card" style={{ width: "48%", height: "auto" }}>
        <div className="card-header">Velocity Chart</div>
        <div className="card-body">
          <Pie data={chartData} options={option} />
        </div>
      </div>
    </div>
  );
};

export default BarChart;
