import React from "react";
import { Tabs,Button } from "antd";
import { useNavigate } from "react-router-dom";
import NotificationTab from "../ExitManagement/NotificationTab";
import RecordResignationTab from "../ExitManagement/RecordResignationTab";
import PullBackTab from "../ExitManagement/PullBackTab";
import { ArrowLeftOutlined } from "@ant-design/icons";
import ViewFeedback from "../ExitManagement/ViewFeedback";
import ExitInterview from "../ExitManagement/ExitInterview";

const { TabPane } = Tabs;

const ExitManagement = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  return (
    <>
    <Button onClick={goBack} icon={<ArrowLeftOutlined />} />
    <Tabs defaultActiveKey="1" centered>
      <TabPane tab="Notifications" key="1">
        <NotificationTab />
      </TabPane>
      <TabPane tab="Record Resignation" key="2">
        <RecordResignationTab />
      </TabPane>
      <TabPane tab="Pull Back" key="3">
        <PullBackTab />
      </TabPane>
      <TabPane tab="Schedule Exit Interview" key="4">
        <ExitInterview />
      </TabPane>
      <TabPane tab=" View Exit Interview Feedback" key="5">
        <ViewFeedback />
      </TabPane>
    </Tabs>
    </>
  );
};

export default ExitManagement;
