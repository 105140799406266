import React from "react";
import { Button, Divider, Row, Col, Form, Input, Card } from "antd";

const PullBackTab = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <Card
        style={{
          width: "80%",
          maxWidth: 1200,
          backgroundColor: "transparent",
          boxShadow: "none",
        }}
        bodyStyle={{ padding: 0 }}
      >
        <Row gutter={[16, 16]}>
          <Col span={10}>
            <div style={{ textAlign: "left", marginLeft: "20px" }}>
              <p>
                Employee Name: <br />
                Riley Doug
              </p>
              <p>
                Date of Joining:
                <br />
                12 - Jul - 2024
              </p>
              <p>
                Designation:
                <br />
                Assistant Manager
              </p>
              <p>
                Reporting Manager:
                <br />
                Amber Adam(US00001)
              </p>
              <p>
                Base Location:
                <br />
                Pyramid - Pune
              </p>
            </div>
          </Col>
          <Divider type="vertical" style={{ height: 0, width: "8vh" }} />
          <Col span={10}>
            <div style={{ textAlign: "left", marginLeft: "20px" }}>
              <p>
                Employment Number:
                <br />
                00648
              </p>
              <p>
                Business Units:
                <br />
                Accounts
              </p>
              <p>
                Grade:
                <br />
                Not Added
              </p>
              <p>
                Functional Manager:
                <br />
                Renee Shende (US000003) - TDG Inc - Local UAT
              </p>
              <p>
                Work Location: <br />
                Pyramid - Pune
              </p>
            </div>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={10}>
            <div style={{ textAlign: "left", marginLeft: "20px" }}>
              <p>
                Separation Type: <br />
                Resignation
              </p>
              <p>
                Requested Relieving Date:
                <br />
                02 - Jan - 2020
              </p>
              <p>
                End of Notice Period:
                <br />
                01 - Jun - 2020
              </p>
              <p>Notice Period Shortfall (In Calendar Days):</p>
              <p>
                Employee Comments:
                <br />
                13
              </p>
              <Form.Item
                name="reason"
                label="Reason for Pullback"
                rules={[
                  {
                    required: true,
                    message: "Please provide the Reason!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
          </Col>
          <Divider type="vertical" style={{ height: 0, width: "8vh" }} />
          <Col span={10}>
            <div style={{ textAlign: "left", marginLeft: "22px" }}>
              <p>
                Resignation Reason(Primary):
                <br />
                End of Contract
              </p>
              <p>
                Resignation Letter Date:
                <br />
                02 - Jan - 2020
              </p>
              <p>
                Actual Relieving Date:
                <br />
              </p>
              <p>
                Notice Period (In Calendar Days):
                <br />
                90
              </p>
              <p>Manager Comments:</p>
              <Button type="primary" style={{ top: 10 }}>
                Save
              </Button>
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default PullBackTab;
