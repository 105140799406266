import React, { useState, useEffect } from "react";
import { Table, Input, Spin, Button, Modal } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { url } from "../../url/url";
import axios from "axios";
import "./hrreport.css";

const LowLeaveBalance = () => {
  const [employees, setEmployees] = useState([]);
  const [leaves, setLeave] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [lowLeaveBalanceThreshold, setLowLeaveBalanceThreshold] = useState(20);
  const [combinedLeaveBalance, setCombinedLeaveBalance] = useState(0);
  const [isSettingsModalVisible, setIsSettingsModalVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [users, setUsers] = useState([]);
  const pageSize = 9;
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    fetchData1();
    fetchUsers();
  }, [lowLeaveBalanceThreshold]);

  // const fetchEmployeeData = async () => {
  //   setLoading(true);
  //   try {
  //     // Fetch user data or pass it as a prop
  //     const user = { displayName: "YourDisplayName", jobTitle: "hr" }; // Replace with actual user data

  //     const dbName = localStorage.getItem("dbName");


  //     let response;
  //     if (jobTitle === "hr" || jobTitle === "admin") {
  //       response = await axios.get(`${url}/api/get/allemplist/${dbName}`);
  //     } else {
  //       response = await axios.get(
  //         `${url}/api/get/emplist/${user.displayName}/${dbName}`
  //       );
  //     }

  //     if (response.status === 200) {
  //       console.log("Employee list fetched:", response.data);
  //       setEmployees(response.data);
  //       await fetchData1();
  //     } else {
  //       console.error("Error:", response.status);
  //     }
  //     setLoading(false);
  //   } catch (error) {
  //     console.error("Error:", error);
  //     setLoading(false);
  //   }
  // };




  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    if (users.length > 0) {
      fetchData1();
    }
  }, [users]);

  const fetchData1 = async () => {
    try {
      const dbName = localStorage.getItem("dbName");
      console.log("dbName in fetchData1 in empleaveapply", dbName);
      const response = await axios.get(`${url}/employee/leavebalance/${dbName}`);
      const leaves = response.data;
      const activeUserNames = users.map(user => user.displayName);
      const filteredLeaves = leaves.filter(leave => activeUserNames.includes(leave.empName));
      console.log("response.data", filteredLeaves);

      setLeave(filteredLeaves);
      handleShowLowLeaveBalance(filteredLeaves);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchUsers = async () => {
    try {
      const dbName = localStorage.getItem("dbName");
      console.log("dbName in fetchUsers in addproject", dbName);
      const response = await fetch(`${url}/api/get/allemplist/${dbName}`);
      const data = await response.json();
      const activeUsers = data.filter(user => user.empStatus !== "inactive");
      setUsers(activeUsers);
      console.log("active users", activeUsers);
    } catch (error) {
      console.error(error);
    }
  };

  const handleShowLowLeaveBalance = (filteredLeaves) => {
    console.log("leaves", filteredLeaves);
    const filtered = filteredLeaves.filter(
      employee =>
        parseInt(employee.casualLeaveBal) + parseInt(employee.annualLeaveBal) <
        lowLeaveBalanceThreshold
    );
    console.log(filtered, "filtered");
    setFilteredEmployees(filtered);
    setCombinedLeaveBalance(
      filtered.reduce(
        (total, employee) =>
          total +
          parseInt(employee.casualLeaveBal) +
          parseInt(employee.annualLeaveBal),
        0
      )
    );
    setLoading(false);
  };
  const handleShowSettingsModal = () => {
    setIsSettingsModalVisible(true);
  };

  //   const handleSaveSettings = () => {
  //     setIsSettingsModalVisible(false);
  //   };

  const handleCancelSettings = () => {
    setIsSettingsModalVisible(false);
  };

  const uniqueJobTitles = [
    ...new Set(employees.map((employee) => employee.empDesignation)),
  ];

  const columns = [
    {
      title: "Employee ID",
      dataIndex: "empid",
      key: "empid",
      sorter: (a, b) => a.empid.localeCompare(b.empid),
    },
    {
      title: "Employee Name",
      dataIndex: "empName",
      key: "empName",
      sorter: (a, b) => a.empName.localeCompare(b.empName),
    },
    {
      title: "Casual Leave",
      dataIndex: "casualLeaveBal",
      key: "casualLeaveBal",
      align: "center",
    },
    {
      title: "Annual Leave",
      dataIndex: "annualLeaveBal",
      key: "annualLeaveBal",
      align: "center",
      width:200
    },
    {
      title: "Total Leave Balance",
      key: "totalLeaveBalance",
      render: (text, record) =>
        `${parseFloat(record.casualLeaveBal) + parseFloat(record.annualLeaveBal)} day(s)`,
      align: "center",
    },
  ];
  return (
    <div style={{ transform: "scale(0.9)", transformOrigin: "0 0", width: "110%" }}>
      <h1 style={{ textAlign: "center" }}>Low Leave Balance</h1>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "10px",
        }}
      >
        {/* <div>
                    <Input
                        type="text"
                        size="small"
                        style={{ height: "40px", fontSize: "15px" }}
                        placeholder="Search Employees..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </div> */}
        <div>
          <Button
            icon={<SettingOutlined />} // Use the setting icon
            onClick={handleShowSettingsModal}
          />
        </div>
      </div>
      {loading ? (
        // Render a loading indicator while data is being fetched
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        // filteredEmployees.length > 0 && (
        <Table
          dataSource={filteredEmployees
            .map((employee) => ({
              ...employee,
              empName: employee.empName,
            }))
          }
          columns={columns}
          size="middle"
          sticky
          scroll={{ x: "max-content" }}
          pagination={{ pageSize: pageSize }}
          className="custom-table col-lg-12 col-md-12 col-sm-12"
        />
        // )
      )}
      <Modal
        title="Settings"
        visible={isSettingsModalVisible}
        footer={null}
        onCancel={handleCancelSettings}
      >
        <p>Low Leave Balance Threshold:</p>
        <Input
          value={lowLeaveBalanceThreshold}
          onChange={(e) => setLowLeaveBalanceThreshold(e.target.value)}
        />
      </Modal>
    </div>
  );
};

export default LowLeaveBalance;
