/*Report--> Leave Report*/
import axios from "axios";
import React, { useState, useEffect } from "react";
import {
  Tabs,
  Table,
  Image,
  Modal,
  Select,
  Button,
  message,
  Popconfirm,
  notification,
  Spin,
} from "antd";
import { url } from "../../../url/url";
import { useNavigate } from "react-router-dom";
import Papa from "papaparse";
import FileSaver from "file-saver";
import {
  QuestionCircleOutlined,
  CheckCircleFilled,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { DeleteFilled } from "@ant-design/icons";
const { Option } = Select;
const { TabPane } = Tabs;

function EmpLeaveReport(props) {
  const [EmployeeData, setEmployeeData] = useState([]);
  const [EmployeeData1, setEmployeeData1] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [visible, setVisible] = useState(false);
  const [Cvisible, setCVisible] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const [Cvisible1, setCVisible1] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [selectedColumns1, setSelectedColumns1] = useState([]);
  const [Columns, setColumns] = useState([]);
  const [Columns1, setColumns1] = useState([]);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10); // Set an initial page size
  const [total, setTotal] = useState(EmployeeData.length); // Set the total based on your data
  const [pdfModalContent, setPdfModalContent] = useState(null);
  const [loading, setLoading] = useState(false);
  const currentDate = new Date();
  const currentyear = currentDate.getFullYear();
  const [year, setYear] = useState(currentyear.toString());
  const [selectedYear, setSelectedYear] = useState("");
  const navigate = useNavigate();

  // Handle page change
  const onPageChange = (newCurrent, newPageSize) => {
    setCurrent(newCurrent);
    setPageSize(newPageSize);
    // You may need to update the "total" based on your data or an API call here
  };
  useEffect(() => {
    fetchData();
    fetchData1();
  }, [year]);
  const fetchData = async () => {
    setLoading(true);
    try {
      const dbName = localStorage.getItem("dbName");
      console.log("dbName in empleavereport in empleaveform", dbName);
      const response = await axios.get(
        `${url}/api/getUserManager/${user.displayName}/${dbName}`
      );
      const empCode = response.data.user.empCode;
      const res = await axios.get(
        `${url}/leave/repo/${empCode}/${year}/${dbName}`
      );
      setEmployeeData(res.data);
      setData(res.data);
      console.log(res.status);
      console.log(res.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const fetchData1 = async () => {
    setLoading(true);
    try {
      const dbName = localStorage.getItem("dbName");
      console.log("dbName in empleavereport in empleaveform", dbName);
      const response = await axios.get(
        `${url}/api/getUserManager/${user.displayName}/${dbName}`
      );
      const empCode = response.data.user.empCode;
      const res = await axios.get(
        `${url}/compoff/leavebalance/${empCode}/${year}/${dbName}`
      );
      setEmployeeData1(res.data.CompLeave);
      setData1(res.data.CompLeave);
      console.log(res.data.CompLeave);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const handleYearChange = (value) => {
    setYear(value);
  };

  const currentYear = new Date().getFullYear();
  const yearsRange = Array.from(
    { length: 3 },
    (_, index) => currentYear - 1 + index
  );

  function handleOk() {
    const EmployeeData = data.map((row) =>
      selectedColumns.reduce((acc, col) => {
        acc[col] = row[col];
        return acc;
      }, {})
    );
    const csv = Papa.unparse(EmployeeData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    FileSaver.saveAs(blob, "Leave_Report.csv", "C:");
    setVisible(false);
    setSelectedColumns([]);
  }
  function handleOk1() {
    const EmployeeData = data1.map((row) =>
      selectedColumns1.reduce((acc, col) => {
        acc[col] = row[col];
        return acc;
      }, {})
    );
    const csv = Papa.unparse(EmployeeData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    FileSaver.saveAs(blob, "Compoff_Report.csv", "C:");
    setCVisible(false);
    setSelectedColumns1([]);
  }
  const handleDelete = async (id, type) => {
    console.log(id);
    try {
      const dbName = localStorage.getItem("dbName");
      console.log("dbName in handleUpdate in project", dbName);

      const response = await axios.delete(
        `${url}/api/Leavedelete/${id}/${dbName}/${type}`
      );
      console.log(response, "response delete");
      if (response.status === 200) {
        setEmployeeData(EmployeeData.filter((record) => record._id !== id));
        notification.success({ message: "Record deleted successfully!" });
        navigate(`/home/empleaveapplyform`);
      } else {
        // Handle other errors
        message.error("Failed to delete the record.");
      }
    } catch (error) {
      console.error(error);
      message.error("Failed to delete the record.");
    }
  };
  const handleDelete1 = async (id) => {
    console.log(id);
    try {
      const dbName = localStorage.getItem("dbName");
      console.log("dbName in handleUpdate in project", dbName);

      const response = await axios.delete(
        `${url}/compoff/Leavedelete/${id}/${dbName}`
      );
      console.log(response, "response delete");
      if (response.status === 200) {
        setEmployeeData1(EmployeeData1.filter((record) => record._id !== id));
        notification.success({ message: "Record deleted successfully!" });
      } else {
        // Handle other errors
        message.error("Failed to delete the record.");
      }
    } catch (error) {
      console.error(error);
      message.error("Failed to delete the record.");
    }
  };
  function handleClick() {
    setVisible(true);
    setColumns(Object.keys(data[0]));
    const imageBase64 = "{record.image}";
    const byteCharacters = atob(imageBase64);
    const byteArrays = [];
    for (let i = 0; i < byteCharacters.length; i++) {
      byteArrays.push(byteCharacters.charCodeAt(i));
    }
    const imageBlob = new Blob([new Uint8Array(byteArrays)], {
      type: "image/png",
    });

    // Create CSV file with the base64 image
    const csvContent =
      "Column 1, Column 2, Column 3\nValue 1, Value 2, Value 3";
    const csvBlob = new Blob([csvContent], { type: "text/csv" });

    // Create download link for the CSV file
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(csvBlob);
    downloadLink.download = "data.csv";
    downloadLink.click();
  }
  function handleClick1() {
    setCVisible(true);
    setColumns1(Object.keys(data1[0]));
    const imageBase64 = "{record.image}";
    const byteCharacters = atob(imageBase64);
    const byteArrays = [];
    for (let i = 0; i < byteCharacters.length; i++) {
      byteArrays.push(byteCharacters.charCodeAt(i));
    }
    const imageBlob = new Blob([new Uint8Array(byteArrays)], {
      type: "image/png",
    });

    // Create CSV file with the base64 image
    const csvContent =
      "Column 1, Column 2, Column 3\nValue 1, Value 2, Value 3";
    const csvBlob = new Blob([csvContent], { type: "text/csv" });

    // Create download link for the CSV file
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(csvBlob);
    downloadLink.download = "data.csv";
    downloadLink.click();
  }
  function handleCancel() {
    setVisible(false);
    setCVisible(false);
    setSelectedColumns([]);
    setSelectedColumns1([]);
  }
  function handleColumnSelect(value) {
    setSelectedColumns(value);
    setSelectedColumns1(value);
  }

  // Define a function to filter columns
  function filterColumns() {
    // List of fields you want to exclude
    const excludedFields = ["_id", "image", "__v"]; // Add the field names you want to exclude

    // Filter the columns to include only those not in the excludedFields list
    const columnsToInclude = Columns.filter(
      (col) => !excludedFields.includes(col)
    );

    return columnsToInclude;
  }

  function filterColumns1() {
    // List of fields you want to exclude
    const excludedFields = ["_id", "image", "__v"]; // Add the field names you want to exclude

    // Filter the columns to include only those not in the excludedFields list
    const columnsToInclude = Columns1.filter(
      (col) => !excludedFields.includes(col)
    );

    return columnsToInclude;
  }

  function openPdfInModal(pdfDataUrl) {
    // Set the PDF URL to state
    fetch(pdfDataUrl)
      .then((res) => res.blob())
      .then((blob) => {
        // Create a blob URL
        const blobUrl = URL.createObjectURL(blob);

        // Set the blob URL to a state variable for the modal content
        setPdfModalContent(blobUrl);

        // Show the modal
        setVisible1(true);
        setCVisible1(true);
      });
  }

  function downloadPdf(pdfDataUrl) {
    const link = document.createElement("a");
    link.href = pdfDataUrl;
    link.download = "Leave Report.pdf"; // Specify the desired file name
    link.click();
  }

  const columns = [
    {
      title: "S.NO",
      dataIndex: "globalSerialNumber",
      key: "index",
      width:100,
      render: (text, record, index) => {
        const currentSerialNumber = index + 1 + pageSize * (current - 1);
        return <span>{currentSerialNumber}</span>;
      },
    },
    {
      title: "Request Date",
      dataIndex: "doa",
      key: "doa",
    },
    {
      title: "Start Date",
      dataIndex: "sdate",
      key: "sdate",
    },
    {
      title: " End Date",
      dataIndex: "edate",
      key: "edate",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
    },
    {
      title: "Days",
      dataIndex: "leavedays",
      key: "leavedays",
    },
    {
      title: "Document",
      render: (_, record) => (
        <div>
          {record.image ? (
            <div>
              {record.image.startsWith("data:application/pdf") ? (
                <div>
                  <a href="#" onClick={() => openPdfInModal(record.image)}>
                    View PDF
                  </a>
                  {" | "}
                  <a href="#" onClick={() => downloadPdf(record.image)}>
                    Download PDF
                  </a>
                </div>
              ) : (
                <Image width={50} src={record.image} fallback={record.image} />
              )}
            </div>
          ) : (
            <>No file</>
          )}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Action",
      dataIndex: "id",
      render: (_, record) => (
        <>
          {record.status === "Submitted" ? (
            <Popconfirm
              title="Are you sure to delete this record?"
              onConfirm={() => handleDelete(record._id, record.type)} // Pass record._id here
              icon={
                <QuestionCircleOutlined
                  style={{
                    color: "red",
                  }}
                />
              }
              okText="Yes"
              cancelText="No"
            >
              {" "}
              <Button style={{ padding: "6px", fontSize: "12px" }}>
                <DeleteFilled style={{ color: "black" }} />
              </Button>
            </Popconfirm>
          ) : record.status === "Denied" ? (
            <CloseCircleOutlined style={{ color: "red" }} />
          ) : record.status === "Approved" ? (
            <CheckCircleFilled style={{ color: "Green" }} />
          ) : null}
        </>
      ),
    },
  ];
  const compoffcolumns = [
    {
      title: "S.NO",
      dataIndex: "globalSerialNumber",
      key: "index",
      width:100,
      render: (text, record, index) => {
        const currentSerialNumber = index + 1 + pageSize * (current - 1);
        return <span>{currentSerialNumber}</span>;
      },
    },

    {
      title: "Date of Apply",
      dataIndex: "doa",
      key: "doa",
    },
    {
      title: " Date of Work",
      dataIndex: "workdate",
      key: "workdate",
    },
    {
      title: "Type of Work",
      dataIndex: "worktype",
      key: "worktype",
      render: (text, record) => {
        if (text === "holidayworked") {
          return "Holiday Worked";
        } else if (text === "weekendsat") {
          return "Saturday Worked";
        } else if (text === "weekendsun") {
          return "Sunday Worked";
        }
        return text;
      },
    },
    {
      title: "Work Nature",
      dataIndex: "workNature",
      key: "workNature",
      render: (text, record) => {
        if (text === "overtime") {
          return "Overtime";
        } else if (text === "projectdeadline") {
          return "Project Deadline";
        } else if (text === "customersupport") {
          return "Customer Support";
        } else if (text === "others") {
          return "Others";
        }
        return text;
      },
    },
    {
      title: "Manager",
      dataIndex: "manager",
      key: "manager",
    },
    {
      title: "Document",
      render: (_, record) => (
        <div>
          {record.image ? (
            <div>
              {record.image.startsWith("data:application/pdf") ? (
                <div>
                  <a href="#" onClick={() => openPdfInModal(record.image)}>
                    View PDF
                  </a>
                  {" | "}
                  <a href="#" onClick={() => downloadPdf(record.image)}>
                    Download PDF
                  </a>
                </div>
              ) : (
                <Image width={50} src={record.image} fallback={record.image} />
              )}
            </div>
          ) : (
            <>No file</>
          )}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Action",
      dataIndex: "id",
      render: (_, record) => (
        <>
          {record.status === "Submitted" ? (
            <Popconfirm
              title="Are you sure to delete this record?"
              onConfirm={() => handleDelete1(record._id)} // Pass record._id here
              icon={
                <QuestionCircleOutlined
                  style={{
                    color: "red",
                  }}
                />
              }
              okText="Yes"
              cancelText="No"
            >
              {" "}
              <Button style={{ padding: "6px", fontSize: "12px" }}>
                <DeleteFilled style={{ color: "black" }} />
              </Button>
            </Popconfirm>
          ) : record.status === "Denied" ? (
            <CloseCircleOutlined style={{ color: "red" }} />
          ) : record.status === "Approved" ? (
            <CheckCircleFilled style={{ color: "Green" }} />
          ) : null}
        </>
      ),
    },
  ];
  return (
    <div>
      {loading ? (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <Tabs defaultActiveKey="1" centered>
            <TabPane
              tab={
                <span style={{ color: "black", fontSize: "20px" }}>
                  My Leave Report
                </span>
              }
              key="1"
            >
              <div style={{ justifyContent: "space-between" }}>
                <Button
                  className="button"
                  type="primary"
                  style={{ margin: "10px" }}
                  onClick={handleClick}
                >
                  Export to CSV
                </Button>

                <Select
                  placeholder="Select Year"
                  onChange={handleYearChange}
                  style={{ width: 130, marginRight: "8px" }} // Add marginRight for spacing
                  value={selectedYear || undefined}
                >
                  {yearsRange.map((year) => (
                    <Select.Option key={year} value={year}>
                      {year}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <Modal
                title="Export to CSV"
                visible={visible}
                onOk={handleOk}
                onCancel={handleCancel}
                okButtonProps={{ disabled: selectedColumns.length === 0 }}
              >
                <p>Select columns to export:</p>
                <Select
                  mode="multiple"
                  style={{ width: "100%" }}
                  placeholder="Please select"
                  value={selectedColumns}
                  onChange={handleColumnSelect}
                >
                  {filterColumns().map((col) => (
                    <Option key={col} value={col}>
                      {col}
                    </Option>
                  ))}
                </Select>
              </Modal>
              <Modal
                title="Document"
                visible={visible1}
                onOk={() => setVisible1(false)}
                footer={null} // Use the custom footer
                onCancel={() => setVisible1(false)}
                width={800} // Adjust the width as needed
              >
                {pdfModalContent && (
                  <iframe
                    src={pdfModalContent}
                    title="Document"
                    style={{ width: "100%", height: "500px" }}
                  />
                )}
              </Modal>
              <div style={{ width: "110%", overflowX: "auto", transform: "scale(0.9)", transformOrigin: "0 0" }}>
                <Table
                  className="custom-table col-lg-12 col-md-12 col-sm-12"
                  dataSource={EmployeeData}
                  pagination={{
                    current: current,
                    pageSize: pageSize,
                    total: total,
                    onChange: onPageChange,
                  }}
                  columns={columns}
                  sticky
                  scroll={{ x: true }}
                />
              </div>
            </TabPane>
            <TabPane
              tab={
                <span style={{ color: "black", fontSize: "20px" }}>
                  My Compoff Report
                </span>
              }
              key="2"
            >
              <div style={{ justifyContent: "space-evenly" }}>
                <Button
                  className="button"
                  type="primary"
                  style={{ margin: "10px" }}
                  onClick={handleClick1}
                >
                  Export to CSV
                </Button>
                <Select
                  placeholder="Select Year"
                  onChange={handleYearChange}
                  style={{ width: 130, marginRight: "8px" }} // Add marginRight for spacing
                  value={selectedYear || undefined}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                >
                  {yearsRange.map((year) => (
                    <Select.Option key={year} value={year}>
                      {year}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <Modal
                title="Export to CSV"
                visible={Cvisible}
                onOk={handleOk1}
                onCancel={handleCancel}
                okButtonProps={{ disabled: selectedColumns.length === 0 }}
              >
                <p>Select columns to export:</p>
                <Select
                  mode="multiple"
                  style={{ width: "100%" }}
                  placeholder="Please select"
                  value={selectedColumns1}
                  onChange={handleColumnSelect}
                >
                  {filterColumns1().map((col) => (
                    <Option key={col} value={col}>
                      {col}
                    </Option>
                  ))}
                </Select>
              </Modal>
              <Modal
                title="Document"
                visible={Cvisible1}
                onOk={() => setCVisible1(false)}
                footer={null} // Use the custom footer
                onCancel={() => setCVisible1(false)}
                width={800} // Adjust the width as needed
              >
                {pdfModalContent && (
                  <iframe
                    src={pdfModalContent}
                    title="Document"
                    style={{ width: "100%", height: "500px" }}
                  />
                )}
              </Modal>
              <div style={{ width: "110%", overflowX: "auto", transform: "scale(0.9)", transformOrigin: "0 0" }}>
                <Table
                  className="custom-table col-lg-12 col-md-12 col-sm-12"
                  dataSource={EmployeeData1}
                  pagination={{
                    current: current,
                    pageSize: pageSize,
                    total: total,
                    onChange: onPageChange,
                  }}
                  columns={compoffcolumns}
                  scroll={{ x: true }}
                  sticky
                />
              </div>
            </TabPane>
          </Tabs>
        </div>
      )}
    </div>
  );
}
export default EmpLeaveReport;
