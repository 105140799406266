import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { url } from "../../url/url";
//import Wizard from './Wizard'; // Import the Wizard component
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

const EmpFileUpload = () => {
  const [uploadResult, setUploadResult] = useState(null);
  const [file, setFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const onDrop = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[0];
    console.log(file, "file");
    // Create FormData object
    const formData = new FormData();
    formData.append("file", file);

    // Make a POST request to your backend endpoint
    const dbName = localStorage.getItem("dbName");
    const userType = localStorage.getItem("userType");
    try {
      const response = await axios.post(
        `${url}/upload/emplist/${dbName}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("response", response);
      const result = response.data;

      console.log("Success:", result.message);
      console.log("Processed emails:", result.emailProcessed);
      alert(
        `Success: ${result.message}\nProcessed emails: ${result.emailProcessed}\nNo of emails successful: ${result.successCount}`
      );
      setUploadResult(result);
    } catch (error) {
      // Handle error
      console.error("Error uploading file:", error);
      const details = error.response?.data || {};
      console.log("Details from error:", details);
      setErrorMessage(details.message || "An error occurred");
      alert(
        `Employee Upload Result:\nFailed Count: ${
          details.failedCount
        }\nUsers Failed:\n${details.emailFailed.join(
          "\n"
        )}\n\n\nSuccessfully Processed emails: ${details.emailProcessed.join(
          "\n"
        )}\nNo of User Record successful: ${details.successCount}`
      );
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: [".csv", ".xls", ".xlsx"],
    onDrop,
  });

  console.log("uploadResult:", uploadResult); // Log uploadResult

  const handledownload = async () => {
    try {
      const response = await axios.get(`${url}/api/csv`);
      if (response.status === 200) {
        const blob = new Blob([response.data], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);

        // Create a link element
        const link = document.createElement("a");
        link.href = url;
        link.download = "Employee.csv";

        // Append the link to the body
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Remove the link from the body
        document.body.removeChild(link);

        // Release the object URL
        window.URL.revokeObjectURL(url);
      } else {
        console.log("Error:", response.status);
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div style={{ zoom: "90%" }}>
      <Button onClick={goBack}>Back</Button>

      <h2>Upload all Employees data</h2>

      <div {...getRootProps()} style={dropzoneStyle}>
        <input {...getInputProps()} />
        <p>Drag and drop a CSV or Excel file here, or click to select one</p>
      </div>
      <div>
        <Button type="primary" onClick={handledownload}>
          Download Sample
        </Button>
      </div>
      {errorMessage && (
        <div style={{ color: "red" }}>
          <p>Error: {errorMessage}</p>
        </div>
      )}
      {uploadResult !== null && !errorMessage && (
        // <div>
        //   {/* <h3>Upload Result</h3> */}
        //   {uploadResult.error ? (
        //     <p style={{ color: "red" }}>{uploadResult.error}</p>
        //   ) : (
        //     <>
        //       <p>{uploadResult.message}</p>
        //       {/* Extract relevant information from result and pass it to Wizard */}
        //       {/* <Wizard data={uploadResult.result} /> */}
        //     </>
        //   )}
        // </div>

        <div>
          <p>{uploadResult.message}</p>
          {/* Extract relevant information from result and pass it to Wizard */}
          {/* <Wizard data={uploadResult.result} /> */}
        </div>
      )}
    </div>
  );
};

const dropzoneStyle = {
  border: "2px dashed #ccc",
  borderRadius: "4px",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
};

export default EmpFileUpload;
