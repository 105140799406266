import React, { useState, useEffect } from "react";
import { Table, Input, Spin } from "antd";
import { url } from "../../url/url";
import axios from "axios";
import "./hrreport.css";
import { decryptRole } from "../../encrypt";

const Birthdays = () => {
  const [employees, setEmployees] = useState([]);
  const [filteredBirthdays, setFilteredBirthdays] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const pageSize = 9;
  const [loading, setLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    setLoading(true);
    fetchEmployeeData();
  }, []);

  const fetchEmployeeData = async () => {
    try {
      // Fetch user data or pass it as a prop

      const dbName = localStorage.getItem("dbName");
      const encryptedRoleFromStorage = localStorage.getItem("role");
      const jobTitle = decryptRole(encryptedRoleFromStorage);

      let response;
      if (jobTitle === "admin") {
        response = await axios.get(`${url}/api/get/allemplist/${dbName}`);
      } else if(jobTitle === "hr") {
        response = await axios.get(
          `${url}/api/get/emplist/${user.displayName}/hr/${dbName}`
        );
      }

      if (response.status === 200) {
        console.log("Employee list fetched:", response.data);
        setEmployees(response.data);
        handleShowBirthdays(response.data);
      } else {
        console.error("Error:", response.status);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };
  const handleShowBirthdays = (employeeData) => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const filtered = employeeData.filter((employee) => {
      if (employee.empStatus !== "inactive") {
        const birthday = new Date(employee.dob);
        return birthday.getMonth() + 1 === currentMonth;
      }
      return false;
    });
    setFilteredBirthdays(filtered);
    setLoading(false);
  };
  const uniqueJobTitles = [
    ...new Set(employees.map((employee) => employee.empDesignation)),
  ];

  const columns = [
    {
      title: "Employee ID",
      dataIndex: "empCode",
      key: "empCode",
      sorter: (a, b) => a.empCode.localeCompare(b.empCode),
    },
    {
      title: "Employee Name",
      dataIndex: "displayName",
      key: "displayName",
      render: (text, record) => `${record.firstName} ${record.lastName}`,
      sorter: (a, b) => a.displayName.localeCompare(b.displayName),
    },
    {
      title: "Position",
      dataIndex: "empDesignation",
      key: "empDesignation",
      filters: uniqueJobTitles.map((jobTitle) => ({
        text: jobTitle,
        value: jobTitle,
      })),
      onFilter: (value, record) => record.empDesignation === value,
      align: "center",
    },
    {
      title: "Date of Birth",
      dataIndex: "dob",
      key: "dob",
      align: "center",
    },
    {
      title: "Official Email",
      dataIndex: "email",
      key: "email",
      align: "center",
    },
    {
      title: "Mobile Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      align: "center",
    },
  ];
  return (
    <div style={{ transform: "scale(0.9)", transformOrigin: "0 0", width: "110%" }}>
      <h1 style={{ textAlign: "center" }}>Birthdays</h1>

      {/* <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "10px" }}>
                <Input
                    type="text"
                    size="small"
                    style={{ height: "40px", fontSize: "15px" }}
                    placeholder="Search Employees..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
            </div> */}

      {loading ? (
        // Render a loading indicator while data is being fetched
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        filteredBirthdays.length > 0 && (
          // Render the table when data is available
          <Table
            dataSource={filteredBirthdays
              .map((employee) => ({
                ...employee,
                firstName: employee.firstName.toLowerCase(),
              }))
              .filter((employee) =>
                employee.firstName.includes(searchQuery.toLowerCase())
              )}
            columns={columns}
            size="middle"
            sticky
            scroll={{ x: "max-content" }}
            pagination={{ pageSize: pageSize }}
            className="custom-table col-lg-12 col-md-12 col-sm-12"
          />
        )
      )}
    </div>
  );
};

export default Birthdays;
