import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  message,
  Table,
  notification,
  Button,
  Form,
  Input,
  Popconfirm,
} from "antd";
import { url } from "../../url/url";
import { DeleteFilled } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
const Addtask = () => {
  const history = useNavigate();

  const columns = [
    {
      title: "Task Name",
      dataIndex: "task",
    },
    {
      title: "Action",
      render: (_, record) => (
        <Popconfirm
          title="Are you sure want to delete the task ?"
          onConfirm={() => handleDelete(record)}
        >
          <Button icon={<DeleteFilled />}></Button>
        </Popconfirm>
      ),
    },
  ];
  const [form] = Form.useForm();
  const formRef = React.useRef(null);
  const [data, setData] = useState([]);
  const [count, setCount] = useState([]);
  const status = localStorage.getItem("status");

  useEffect(() => {
    fetchData();
  }, [count]);

  function postData(values) {
    const dbName = localStorage.getItem("dbName");
    const exist = data.find(
      (p) => p.task.toLowerCase() === values.task.toLowerCase()
    );
    console.log(exist, "started");
    if (exist === undefined) {
      const requestBody = {
        task: values.task.trim(),
        dbName: dbName,
      };
      fetch(`${url}/api/task`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          notification.success({
            message: "Success",
            description: "Task has been successfully added!",
          });
          setCount(count + 1);
          formRef.current?.resetFields();
        })
        .catch((error) => {
          console.error("Error:", error);
          notification.warning({
            message: "Failed",
            description: "Internal Server Error - Task not saved",
          });
        });
    } else {
      message.error("Task name already exists!");
    }
  }
  const fetchData = async () => {
    try {
      const dbName = localStorage.getItem("dbName");
      const response = await axios.get(`${url}/api/newtask/${dbName}`);
      setData(response.data);
      console.log(response.data, "datafetch");
    } catch (error) {
      console.error(error);
      message.error("Failed to fetch data from the server.");
    }
  };

  const handleDelete = async (record) => {
    try {
      const dbName = localStorage.getItem("dbName");
      console.log("record.task", record.task);
      const data = await fetch(`${url}/tasks/exist/${record.task}/${dbName}`);
      const response = await data.json();
      if (response.length === 0) {
        try {
          console.log("calling deletetask");
          const response = await axios.delete(
            `${url}/api/deletetask/${record._id}/${dbName}`
          );
          notification.success({ message: "Successfully Deleted." });
          setCount(count + 1);
        } catch (error) {
          console.error(error);
          message.error("Failed to fetch data from the server.");
        }
      } else {
        message.error("This task is already assigned in projects");
      }
    } catch (error) {
      console.error(error);
      message.error("Failed to fetch data from the server.");
    }
  };
  // Custom validator function to check for special characters
  function validateTaskName(rule, value, callback) {
    if (!value) {
      callback("Please input task name!..");
    }
    const specialCharactersRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\₹"'€¥√π÷×=/-]/;
    // Check if the value starts with a space or contains special characters
    if (value.startsWith(" ")) {
      callback("Task name should not start with a space");
    } else if (specialCharactersRegex.test(value)) {
      callback("Task name should not contain special characters");
    } else {
      callback();
    }
  }
  
  const goBack = () => {
    history(-1);
  };

  return (
    <div style={{ zoom: "90%" }}>
      <Button onClick={goBack}>Back</Button>

      <h2 className="text-center">Manage Task </h2>

      <div className="row mb-4">
        <div className="col-12">
          <Form
            ref={formRef}
            form={form}
            onFinish={postData}
            value="small"
            style={{ overflowX: "auto", paddingTop: "50px" }}
          >
            <Form.Item
              label="Task Name"
              name="task"
              rules={[
                {
                  required: true,
                  message: "",
                },
                {
                  validator: validateTaskName, // Use the custom validator function
                },
              ]}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Input style={{ width: "50vh", marginRight: "30px" }} />

                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  style={{ marginBottom: "10px" }}
                  disabled={status === "disable"}
                >
                  Submit
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>

      <div style={{ overflowX: "auto", paddingTop: "20px" }}>
        <Table
          className="col"
          columns={columns}
          dataSource={data}
          scroll={{ x: true }}
          style={{ width: "100vh" }}
          sticky
        />
      </div>
    </div>
  );
};

export default Addtask;
