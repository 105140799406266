const ENV = "production";

export const url =
  ENV === "production"
    ? "https://ems.finaran.com:8007"
    : ENV === "development"
    ? "https://myapp.finaran.com:8007"
    : ENV === "local"
    ? "http://localhost:27017"
    : null;


