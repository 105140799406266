import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Input,
  Avatar,
  Row,
  Col,
  Table,
  Card,
  Button,
  Select,
  Progress,
  Form,
  Tooltip,
  notification,
  message,
  Popconfirm,
  Tabs,
} from "antd";
import Papa from "papaparse";
import moment from "moment";
import FileSaver from "file-saver";
import {
  CheckOutlined,
  CloseOutlined,
  DeleteFilled,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  PaperClipOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
  SendOutlined,
  EditFilled,
  ExportOutlined,
} from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import { url } from "../../url/url";
import axios from "axios";
import AddActivity from "./AddActivity";
const { Option } = Select;
const { TabPane } = Tabs;
const Activityboard = ({ propselectedProject, propselectedSprint }) => {
  const [selectedactivity, setSelectedactivity] = useState(null);
  const [base64Image, setBase64Image] = useState(null);
  const [showCard, setShowCard] = useState(false);
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const [hoveredKey, setHoveredKey] = useState("");
  const [showInput, setShowInput] = useState(false);
  const [newactivity, setNewactivity] = useState("");
  const [priority, setPriority] = useState("activity");
  const [description, setDescription] = useState([]);
  const [activities, setactivities] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedSprint, setSelectedSprint] = useState(null);
  const [featureName, setfeatureName] = useState(
    location.state && location.state.featureName
  );
  const [featureId, setfeatureId] = useState(
    location.state && location.state.featureId
  );
  const users = JSON.parse(localStorage.getItem("user"));
  const [count, setCount] = useState(0);
  const [selectedUsers, setSelectedUsers] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [editedActivityName, setEditedActivityName] = useState("");
  const assignee = location.state && location.state.assignee;
  const [Assignee, setAssignee] = useState(assignee || []);
  const { activityId } = useParams();
  const [currentActivity, setCurrentActivity] = useState(selectedactivity);
  const pageSize = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({
    featureName: null,
    status: null,
    assignee: null,
    reporter: null,
    priority: null,
    type: null,
  });
  const [showDetails, setShowDetails] = useState("comments");

  const toggleShowDetails = (detailsType) => {
    setShowDetails(detailsType);
  };
  const handleFilterChange = (pagination, filters, sorter) => {
    setFilters(filters);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  useEffect(() => {
    setCurrentActivity(selectedactivity);
    setComment("");
    console.log(currentActivity, selectedactivity, "current");
  }, [selectedactivity, count]);

  useEffect(() => {}, [activities]);

  const fetchAssigneesByFeatureName = async (featureName) => {
    try {
      const dbName = localStorage.getItem("dbName");
      const response = await fetch(
        `${url}/feature/${featureName}/featureName/${dbName}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch Feature data");
      }
      const data = await response.json();
      setAssignee(data[0].assignee);
    } catch (error) {
      console.error("Error:", error);
      throw new Error("An error occurred while fetching sprint details.");
    }
  };
  const filteredData = activities.filter((item) => {
    // Convert the activity object to a string and check if the searchQuery is present
    const itemString = JSON.stringify(item).toLowerCase();
    return itemString.includes(searchQuery.toLowerCase());
  });

  const handlePriorityChange = (value) => {
    setCurrentActivity({
      ...currentActivity,
      priority: value,
    });
    setSelectedactivity({
      ...selectedactivity,
      priority: value,
    });
  };

  const handleStatusChange = (value) => {
    setCurrentActivity({
      ...currentActivity,
      status: value,
    });
    setSelectedactivity({
      ...selectedactivity,
      status: value,
    });
  };

  function handleClick() {
    const data = activities.map((row) =>
      columns.reduce((acc, col) => {
        acc[col.dataIndex] =
          row[col.dataIndex] !== undefined ? row[col.dataIndex] : "";
        return acc;
      }, {})
    );

    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    FileSaver.saveAs(
      blob,
      `${selectedProject.ProjectName}'s Activities.csv`,
      "C:"
    );
  }

  const prioritySelectRef = useRef(null);
  const handleEditClick = () => {
    setIsEditing(!isEditing);
    setEditedActivityName(selectedactivity.activityName); // Reset edited activity name when edit button is clicked
  };

  const handleInputChange = (e) => {
    setEditedActivityName(e.target.value);
    console.log(e.target.value, "value");
  };

  // useEffect(() => {
  //     const fetchactivities = async () => {
  //         const dbName = localStorage.getItem("dbName");
  //         const user = users.displayName;
  //         try {
  //             const response = await axios.get(`${url}/get/activities/${dbName}/${user}`);
  //             setactivities(response.data);
  //             console.log(response, "datata");

  //         } catch (error) {
  //             console.error('Error fetching activities:', error.message);
  //         }
  //     };

  //     fetchactivities();
  // }, []);

  useEffect(() => {
    console.log(
      "Initial props and location state:",
      propselectedProject,
      location.state?.selectedProject
    );

    const project = propselectedProject || location.state?.selectedProject;
    const sprint = propselectedSprint || location.state?.selectedSprint;

    if (project) {
      setSelectedProject(project);
      setSelectedSprint(sprint);
      fetchactivities(project);
    } else {
      console.log("No project found in props or location state.");
    }
  }, [propselectedProject, propselectedSprint, location.state]);

  const fetchactivities = async (selectedproject) => {
    const dbName = localStorage.getItem("dbName");
    const searchParams = new URLSearchParams(location.search);
    let featureId = searchParams.get("featureId");
    console.log(featureId, "featureid");
    if (!featureId) {
      console.log(selectedproject, "selecetdproject");
      if (selectedproject) {
        featureId = selectedproject.ProjectName;
      }
      try {
        const jobTitle = users.jobTitle;
        const displayName = users.displayName;
        const response = await axios.get(
          `${url}/getByProject/activity/${dbName}/${featureId}`,
          {
            params: {
              jobTitle: jobTitle,
              displayName: displayName,
            },
          }
        );
        setactivities(response.data);
        console.log(response.data, "activity");
      } catch (error) {
        console.error("Error fetching activities:", error.message);
      }
    } else {
      try {
        console.log(featureId, "featureid");
        const response = await axios.get(
          `${url}/getByfeature/activity/${dbName}/${featureId}`
        );
        setactivities(response.data);
        console.log(response.data, "res");
      } catch (error) {
        console.error("Error fetching activities:", error.message);
      }
    }
  };

  const updateactivity = async (values) => {
    const dbName = localStorage.getItem("dbName");
    values.description = selectedactivity.description;
    values.priority = currentActivity.priority;
    values.status = currentActivity.status;
    values.type = selectedactivity.type;
    values.assignee = selectedUsers;
    values.estimatedHours = selectedactivity.estimatedHours;
    values.reporter = selectedactivity.reporter;
    values.updateReporter = users.displayName;
    if (isEditing) {
      values.activityName = editedActivityName;
    } else {
      values.activityName = selectedactivity.activityName;
    }
    try {
      const response = await axios.put(
        `${url}/update/activity/${dbName}/${selectedactivity.activityId}`,
        values
      );
      setactivities(
        activities.map((record) =>
          record.activityId === selectedactivity.activityId
            ? { ...record, ...response.data }
            : record
        )
      );
      notification.success({ message: "Updated successfully!" });
      setIsEditing(false);
      setSelectedactivity(null);
    } catch (error) {
      console.error(error);
      message.error("Failed to update the record.");
    }
  };
  console.log(selectedactivity, "Activity");

  const handleactivityClick1 = (record) => {
    setSelectedactivity(record);
    fetchAssigneesByFeatureName(record?.featureName);
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64 = reader.result;
      setBase64Image(base64);
    };
    reader.readAsDataURL(file);
  };

  const handleDelete = async (id) => {
    try {
      const dbName = localStorage.getItem("dbName");
      const response = await axios.delete(
        `${url}/remove/activity/${dbName}/${id}`
      );
      setactivities(activities.filter((record) => record._id !== id));
      notification.success({
        message: "Activity Deleted!",
        description: `Activity Deleted Successfully`,
      });
    } catch (error) {
      console.error("Error deleting record:", error.message);
      // Handle error scenarios
    }
  };

  const uniqueStatuses = [...new Set(activities.map((item) => item.status))];
  const uniqueAssignees = [...new Set(activities.map((item) => item.assignee))];
  const uniqueReporters = [...new Set(activities.map((item) => item.reporter))];
  const uniquePriorities = [
    ...new Set(activities.map((item) => item.priority)),
  ];
  const uniqueTypes = [...new Set(activities.map((item) => item.type))];
  const uniqueFeatureNames = [
    ...new Set(activities.map((item) => item.featureName)),
  ];

  let columns = [];

  if (propselectedProject) {
    columns.push({
      title: "Feature Name",
      dataIndex: "featureName",
      width: 200,
      filters: uniqueFeatureNames.map((name) => ({
        text: name,
        value: name,
      })),
      onFilter: (value, record) => record.featureName === value,
      filteredValue: filters.featureName || null,
      render: (text) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {text}
        </div>
      ),
    });
  }

  columns.push(
    {
      title: "Activity ID",
      dataIndex: "activityId",
      width: 200,
    },
    {
      title: "Activity Name",
      dataIndex: "activityName",
      width: 250,
      render: (text, record) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          <Link
            onClick={() => {
              handleactivityClick1(record);
            }}
          >
            {text}
          </Link>
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      filters: uniqueStatuses.map((status) => ({
        text: status,
        value: status,
      })),
      onFilter: (value, record) => record.status === value,
      filteredValue: filters.status || null,
      width: 100,
    },
    // );

    // if (!selectedactivity) {
    //   columns.push(
    {
      title: "Assignee",
      dataIndex: "assignee",
      filters: uniqueAssignees.map((assignee) => ({
        text: assignee,
        value: assignee,
      })),
      onFilter: (value, record) => record.assignee === value,
      filteredValue: filters.assignee || null,
      width: 200,
    },
    {
      title: "Reporter",
      dataIndex: "reporter",
      filters: uniqueReporters.map((reporter) => ({
        text: reporter,
        value: reporter,
      })),
      onFilter: (value, record) => record.reporter === value,
      filteredValue: filters.reporter || null,
      width: 200,
    },
    {
      title: "Priority",
      dataIndex: "priority",
      filters: uniquePriorities.map((priority) => ({
        text: priority,
        value: priority,
      })),
      onFilter: (value, record) => record.priority === value,
      filteredValue: filters.priority || null,
      width: 100,
    },
    {
      title: "Type",
      dataIndex: "type",
      filters: uniqueTypes.map((type) => ({
        text: type,
        value: type,
      })),
      onFilter: (value, record) => record.type === value,
      filteredValue: filters.type || null,
      width: 100,
      render: (text) => (
        <Tooltip
          title={text}
          getPopupContainer={(triggerNode) =>
            document.getElementById("tooltipContainer")
          }
          placement="top"
          mouseEnterDelay={0.5}
          mouseLeaveDelay={0.1}
        >
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => (
        <>
          <Popconfirm
            title="Are you sure to delete this record?"
            onConfirm={() => handleDelete(record._id)}
            icon={
              <QuestionCircleOutlined
                style={{
                  color: "red",
                }}
              />
            }
            okText="Yes"
            cancelText="No"
          >
            <Button
              style={{ bottom: "9px" }}
              type="primary"
              size="small"
              danger
            >
              <DeleteFilled />
            </Button>
          </Popconfirm>
        </>
      ),
      width: 100,
    }
  );
  // }

  const handleCommentSubmit = async () => {
    if (comment.trim() !== "") {
      const dbName = localStorage.getItem("dbName");
      const activityId = selectedactivity.activityId;
      const newComment = {
        user: users.displayName,
        description: comment,
      };
      try {
        await axios.post(
          `${url}/update/comments/${dbName}/${activityId}/activity`,
          newComment
        );
        console.log(newComment, "comment");
        setSelectedactivity((prevactivity) => ({
          ...prevactivity,
          comments: [...prevactivity.comments, newComment],
        }));
        setCount(count + 1);
        setComment("");

        notification.success({ message: "Comment added successfully!" });
      } catch (error) {
        console.error("Error updating comments:", error.message);
        message.error("Failed to add comment.");
      }
    }
  };

  const EmployeeInitials = ({ employee, index, colors }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const toggleTooltip = () => {
      setShowTooltip(!showTooltip);
    };

    return (
      <div
        key={index}
        style={{
          position: "relative",
          display: "inline-block",
          margin: "0 5px",
        }}
        onMouseEnter={toggleTooltip} // Show tooltip on hover
        onMouseLeave={toggleTooltip} // Hide tooltip on mouse leave
      >
        <div
          style={{
            borderRadius: "50%",
            width: "30px",
            height: "30px",
            background: colors[index % colors.length],
            textAlign: "center",
            lineHeight: "30px",
            cursor: "pointer",
            color: "white",
          }}
        >
          {employee.initials}
        </div>
        {showTooltip && (
          <div
            style={{
              position: "absolute",
              top: "100%",
              left: "50%",
              transform: "translateX(-50%)",
              padding: "5px",
              background: "#8a8883",
              border: "1px solid #ccc",
              borderRadius: "5px",
              color: "white",
            }}
          >
            {employee.fullName}
          </div>
        )}
      </div>
    );
  };

  const renderEmployeeInitials = () => {
    if (Assignee) {
      const initials = Assignee.map((employee) => {
        const [firstName, lastName] = employee.split(" ");
        return {
          initials: `${firstName[0]}${lastName[0]}`,
          fullName: employee,
        };
      });

      const colors = [
        "red",
        "purple",
        "yellow",
        "blue",
        "orange",
        "green",
        "pink",
      ];

      return (
        <div>
          {initials.map((employee, index) => (
            <EmployeeInitials
              key={index}
              employee={employee}
              index={index}
              colors={colors}
            />
          ))}
        </div>
      );
    }
    return null;
  };

  const renderProjectEmployeeInitials = () => {
    if (selectedProject) {
      const initials = selectedProject.TotalEmp.map((employee) => {
        const [firstName, lastName] = employee.split(" ");
        return {
          initials: `${firstName[0]}${lastName[0]}`,
          fullName: employee,
        };
      });

      const colors = [
        "red",
        "purple",
        "yellow",
        "blue",
        "orange",
        "green",
        "pink",
      ];

      return (
        <div>
          {initials.map((employee, index) => (
            <EmployeeInitials
              key={index}
              employee={employee}
              index={index}
              colors={colors}
            />
          ))}
        </div>
      );
    }
    return null;
  };

  const goBack = () => {
    navigate("/home/board", {
      state: {
        selectedProject,
        selectedSprint,
      },
    });
  };

  const handleSelect = (selectedValues) => {
    setSelectedUsers(selectedValues);
    setSelectedactivity({
      ...selectedactivity,
      assignee: selectedValues,
    });
  };

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleFormSubmit = async (values) => {
    try {
      const dbName = localStorage.getItem("dbName");
      values.featureName = featureName;
      values.featureId = featureId;
      values.reporter = users.displayName;
      values.status = "todo";
      console.log(selectedProject, "project12");
      const response = await fetch(
        `${url}/post/activity/${dbName}/${selectedProject.Projectcode}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit form");
      }
      notification.success({ message: "Activity submitted successfully" });
      setCount(count + 1);
      setShowInput(false);
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle error, show error message to the user, etc.
    }
  };

  return (
    <div
      style={{
        transform: "scale(0.9)",
        transformOrigin: "0 0",
      }}
    >
      {!featureId && selectedProject && (
        <Row display="flex" justify="space-between" align="middle">
          <Col>
            <Button type="primary" size="small" onClick={handleClick}>
              <ExportOutlined />
              Export
            </Button>
          </Col>
          <Col>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Input
                suffix={<SearchOutlined style={{ marginBottom: "10px" }} />}
                size="small"
                placeholder="Search Activity Name"
                value={searchQuery}
                onChange={handleSearchQueryChange}
              />
            </div>
          </Col>
        </Row>
      )}

      {featureId && (
        <>
          <Row display="flex" justify="end" align="middle">
            <Col>
              <Input
                suffix={<SearchOutlined />}
                style={{
                  height: "40px",
                  fontSize: "15px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
                placeholder="Search Activity Name"
                value={searchQuery}
                onChange={handleSearchQueryChange}
              />
            </Col>
          </Row>
          <Row>
            <Col span={5}>
              <Button onClick={goBack}>Back</Button>
            </Col>
            <Col span={12}>
              <h4 style={{ display: "flex", justifyContent: "center" }}>
                Feature Activity Board
              </h4>
            </Col>
          </Row>
        </>
      )}
      <br />
      {featureId && (
        <>
          <Row justify="space-between" align="middle" gutter={[16, 16]}>
            <Col>
              {featureId && (
                <Row align="middle" gutter={[16, 16]}>
                  <Col>
                    {selectedProject && (
                      <i5 style={{ color: "blue", marginRight: "16px" }}>
                        Project: {selectedProject?.ProjectName}
                      </i5>
                    )}
                  </Col>
                  {propselectedProject ? (
                    <Col>{renderProjectEmployeeInitials()}</Col>
                  ) : (
                    <Col>{renderEmployeeInitials()}</Col>
                  )}
                </Row>
              )}
            </Col>
            {featureName && (
              <Col>
                <i5 style={{ color: "blue" }}>Feature: {featureName}</i5>
              </Col>
            )}
            <Col>
              <Button
                icon={<ExportOutlined />}
                type="primary"
                size="small"
                onClick={handleClick}
                style={{ right: 0 }}
              >
                Export
              </Button>
            </Col>
            <Col>
              <Row justify="end" align="middle" gutter={[16, 16]}>
                {showInput && (
                  <AddActivity
                    selectedProject={selectedProject}
                    modalVisible2={showInput}
                    setModalVisible2={setShowInput}
                    handleFormSubmit={handleFormSubmit}
                  />
                )}
              </Row>
            </Col>
          </Row>
          <br />
        </>
      )}
      {selectedactivity && selectedProject ? (
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div style={{ maxHeight: "500px", overflowY: "auto" }}>
              <Table
                dataSource={filteredData}
                columns={columns}
                scroll={{ x: true, y: true }}
                pagination={{
                  pageSize: pageSize,
                  current: currentPage,
                  onChange: handlePageChange,
                }}
                className="custom-table1 col-lg-12 col-md-12 col-sm-12"
                sticky
                onChange={handleFilterChange}
              />
            </div>
          </Col>
          <Col span={12}>
            <div style={{ maxHeight: "500px", overflowY: "auto" }}>
              <Card
                title={`${selectedactivity.activityId}`}
                extra={
                  <Button
                    onClick={() => setSelectedactivity(null)}
                    type="text"
                    shape="circle"
                    icon={<CloseOutlined />}
                  />
                }
              >
                <Form onFinish={updateactivity}>
                  <Row>
                    {isEditing ? (
                      <>
                        <Input
                          value={editedActivityName}
                          onChange={handleInputChange}
                          autoFocus
                        />
                        <Button
                          style={{ left: "3vh" }}
                          icon={<CloseOutlined />}
                          onClick={() => setIsEditing(false)}
                        />
                      </>
                    ) : (
                      <>
                        <p>{selectedactivity.activityName}</p>
                        <Button
                          style={{ left: "3vh" }}
                          icon={<EditFilled />}
                          onClick={handleEditClick}
                          disabled={users.jobTitle === "employee"}
                        />
                      </>
                    )}
                  </Row>
                  <input
                    type="file"
                    id="fileInput"
                    style={{ display: "none" }}
                    onChange={handleFileUpload}
                    accept="image/*"
                  />
                  <label htmlFor="fileInput">
                    <PaperClipOutlined
                      style={{ fontSize: 24, cursor: "pointer" }}
                    />
                  </label>
                  {base64Image && (
                    <img
                      src={base64Image}
                      alt="Uploaded"
                      style={{ maxWidth: "50%", marginTop: "10px" }}
                    />
                  )}
                  <br />
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Form.Item label="Status">
                      <Select
                        value={currentActivity?.status}
                        style={{ width: "20vh" }}
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        onChange={handleStatusChange}
                      >
                        <Select.Option value="todo">To Do</Select.Option>
                        <Select.Option value="doing">Doing</Select.Option>
                        <Select.Option value="done">Done</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item label="Priority">
                      <Select
                        value={currentActivity?.priority}
                        style={{ width: "20vh" }}
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        onChange={handlePriorityChange}
                        disabled={users.jobTitle === "employee"}
                      >
                        <Select.Option value="Low">Low</Select.Option>
                        <Select.Option value="High">High</Select.Option>
                        <Select.Option value="Medium">Medium</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item label="Type">
                      <Select
                        disabled={users.jobTitle === "employee"}
                        onChange={(value) => {
                          setSelectedactivity({
                            ...selectedactivity,
                            type: value.toString(),
                          });
                        }}
                        style={{ width: "150px" }}
                        value={selectedactivity.type}
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                      >
                        <Select.Option value="Bug">Bug</Select.Option>
                        <Select.Option value="Enhancement">
                          Enhancement
                        </Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item label="Estimated Hrs">
                      <Input
                        disabled={users.jobTitle === "employee"}
                        onChange={(e) => {
                          setSelectedactivity({
                            ...selectedactivity,
                            estimatedHours: e.target.value.toString(),
                          });
                        }}
                        style={{ width: "150px" }}
                        value={selectedactivity.estimatedHours}
                      />
                    </Form.Item>
                  </Row>
                  <p style={{ fontSize: "16px", marginTop: "5vh" }}>
                    Description
                  </p>
                  <Form.Item>
                    <Input.TextArea
                      rows={4}
                      onChange={(e) => {
                        setSelectedactivity({
                          ...selectedactivity,
                          description: e.target.value.toString(),
                        });
                      }}
                      value={selectedactivity.description}
                    />
                  </Form.Item>

                  <Card title="Details" style={{ marginTop: "5vh" }}>
                    <Form.Item label="Assignee">
                      <Select
                        disabled={users.jobTitle === "employee"}
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        allowClear
                        placeholder="Please select"
                        onChange={handleSelect}
                        value={selectedactivity.assignee}
                      >
                        {Assignee.map((employee, index) => (
                          <Select.Option key={index} value={employee}>
                            {employee}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Form.Item label="Reporter">
                      <Input
                        value={selectedactivity.reporter}
                        disabled
                        style={{ height: "40px" }}
                      />
                    </Form.Item>
                  </Card>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      icon={<EditOutlined />}
                    >
                      Update
                    </Button>
                  </Form.Item>
                </Form>
                <Card
                  title="Activity"
                  style={{
                    marginTop: "1vh",
                    overflow: "auto",
                    maxHeight: "350px",
                  }}
                >
                  <Tabs
                    defaultActiveKey="comments"
                    onChange={toggleShowDetails}
                  >
                    <TabPane tab="Comments" key="comments">
                      <div>
                        <p>Comments:</p>
                        <Form onFinish={handleCommentSubmit}>
                          <Row>
                            <Col span={14}>
                              <Form.Item>
                                <Input.TextArea
                                  placeholder="Add a Comment"
                                  onChange={(e) => setComment(e.target.value)}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={5}>
                              <Form.Item>
                                <Button
                                  type="text"
                                  htmlType="submit"
                                  icon={<SendOutlined />}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Form>
                        {selectedactivity.comments.map((comment, index) => (
                          <div key={index} style={{ marginTop: "10px" }}>
                            <Avatar size="large" style={{ background: "blue" }}>
                              {comment.user && comment.user.length >= 2
                                ? `${comment.user[0]}${
                                    comment.user.split(" ")[1][0]
                                  }`
                                : comment.user}
                            </Avatar>
                            <span style={{ marginLeft: "10px" }}>
                              {comment.description}
                            </span>
                          </div>
                        ))}
                      </div>
                    </TabPane>
                    <TabPane tab="History" key="history">
                      <div>
                        <p>History:</p>
                        {selectedactivity.history.map((entry, index) => {
                          const userInitials = entry.changedBy
                            ? entry.changedBy
                                .split(" ")
                                .map((namePart, idx) =>
                                  idx === 0 || idx === 1 ? namePart[0] : null
                                )
                                .join("")
                            : "";
                          const formattedTimestamp = moment(
                            entry.timestamp
                          ).format("MMMM D, YYYY [at] h:mm A");
                          return (
                            <div key={index} style={{ marginTop: "10px" }}>
                              <Row>
                                <Col span={2}>
                                  <Avatar
                                    size="large"
                                    style={{ background: "blue" }}
                                  >
                                    {userInitials}
                                  </Avatar>
                                </Col>
                                <Col span={22}>
                                  <div>
                                    <span>{entry.changedBy}</span>
                                    <span style={{ color: "gray" }}>
                                      {` ${formattedTimestamp}`}
                                    </span>
                                  </div>
                                  {entry.changes && entry.changes.length > 0 ? (
                                    entry.changes.map((change, idx) => (
                                      <div
                                        key={idx}
                                        style={{ marginLeft: "34px" }}
                                      >
                                        {change.field === "created" ? (
                                          <p>{` activity created`}</p>
                                        ) : (
                                          <p>
                                            <span>{change.field}</span>
                                            {` changed from `}
                                            <span
                                              style={{
                                                textDecoration: "line-through",
                                                color: "red",
                                              }}
                                            >
                                              {change.oldValue}
                                            </span>
                                            {` to `}
                                            <span style={{ color: "green" }}>
                                              {change.newValue}
                                            </span>
                                          </p>
                                        )}
                                      </div>
                                    ))
                                  ) : (
                                    <div style={{ marginLeft: "34px" }}>
                                      <p>
                                        <span>{entry.field}</span>
                                        {` created`}
                                      </p>
                                    </div>
                                  )}
                                </Col>
                              </Row>
                            </div>
                          );
                        })}
                      </div>
                    </TabPane>
                  </Tabs>
                </Card>
              </Card>
            </div>
          </Col>
        </Row>
      ) : selectedProject ? (
        // <div style={{ maxHeight: "500px", width: "110%", overflowY: "auto" }}>
        <Table
          dataSource={filteredData}
          columns={columns}
          scroll={{ x: true, y: true }}
          pagination={{
            pageSize: pageSize,
            current: currentPage,
            onChange: handlePageChange,
          }}
          sticky
          className="custom-table1 col-lg-12 col-md-12 col-sm-12"
          onChange={handleFilterChange}
        />
      ) : (
        // </div>
        <h6
          style={{ marginTop: "6vh", color: "#e69660" }}
          className="text-center"
        >
          Select Project First to View the Activity Board
        </h6>
      )}
    </div>
  );
};

export default Activityboard;
