import React, { useState, useEffect } from "react";
import {
  Tabs,
  Table,
  Input,
  Badge,
  Button,
  Modal,
  message,
  notification,
  Popconfirm,
  Form,
  Checkbox,
  Spin,
  Tooltip,
} from "antd";
import axios from "axios";
import { url } from "../../url/url";
import { Link } from "react-router-dom";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { decryptRole } from "../../encrypt";
import {
  CheckCircleFilled,
  CheckOutlined,
  CloseCircleFilled,
  CloseOutlined,
  EyeFilled,
} from "@ant-design/icons";

const { TabPane } = Tabs;

const Pendinglist = (props) => {
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const [reject, setReject] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [dataId, setdataId] = useState("");
  const [email, setemail] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showAllReports, setShowAllReports] = useState(false);
  const uniqueManager = Array.from(
    new Set(data.map((data) => data.reportingManager))
  );
  const uniqueName = Array.from(new Set(data.map((data) => data.userName)));
  const encryptedRoleFromStorage = localStorage.getItem("role");
  const jobTitle = decryptRole(encryptedRoleFromStorage);
  const pageSize = 10;

  const columns = [
    {
      title: "Select",
      dataIndex: "select",
      width: 150,
      render: (text, record) => (
        <Checkbox
          checked={selectedRowKeys.includes(record._id)}
          onChange={() => handleRowSelect(record._id)}
        />
      ),
    },
    {
      title: "Employee Name",
      dataIndex: "userName",
      width: 300,
      filters: uniqueName.map((userName) => ({
        text: userName,
        value: userName,
      })),
      onFilter: (value, record) => record.userName === value,
    },
    {
      title: "Employee ID",
      dataIndex: "empCode",
      width: 300,
    },
    {
      title: "Manager",
      dataIndex: "reportingManager",
      width: 200,
      filters: uniqueManager.map((reportingManager) => ({
        text: reportingManager,
        value: reportingManager,
      })),
      onFilter: (value, record) => record.reportingManager === value,
    },
    {
      title: "Start Date",
      dataIndex: "weekStartingDate",
      width: 200,
    },
    {
      title: "End Date",
      dataIndex: "weekEndingDate",
      width: 200,
    },
    {
      title: "Total Hours",
      dataIndex: "weekTotal",
      width: 300,
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 150,
      render: (text) => (
        <span className={text === "Submitted" ? "submitted" : "pending"}>
          {text}
        </span>
      ),
    },
    {
      title: "Submission Date",
      dataIndex: "submittedDate",
      key: "submittedDate",
      width: 150,
      render: (text) => moment(text).format("DD-MM-YYYY"),
    },

    {
      title: "Action",
      key: "action",
      dataIndex: "action",
      width: 200,
      align: "center",
      render: (text, record) => (
        <div style={{ display: "flex", gap: "5px" }}>
          <Link
            to={`/home/usertables/${record._id}/${record.userName}?weekId=${record.weekId}&empCode=${record.empCode}`}
          >
            {" "}
            <Button icon={<EyeFilled />} onclick={""} />
          </Link>
          <Popconfirm
            title="Are you sure you want to Approve this timesheet?"
            onConfirm={() => Approve(record)}
          >
            <Tooltip title="Approve">
              <Button
                icon={<CheckOutlined />}
                type="primary"
                style={{ backgroundColor: "green" }}
              />
            </Tooltip>
          </Popconfirm>
          <Tooltip title="Reject">
            <Button
              icon={<CloseOutlined />}
              type="primary"
              danger
              onClick={() => Remove(record)}
            />
          </Tooltip>
        </div>
      ),
    },
  ];
  const columns1 = [
    {
      title: "Employee Name",
      dataIndex: "userName",
      width: 300,
      filters: uniqueName.map((userName) => ({
        text: userName,
        value: userName,
      })),
      onFilter: (value, record) => record.userName === value,
    },
    {
      title: "Employee ID",
      dataIndex: "empCode",
      width: 300,
    },
    {
      title: "Manager",
      dataIndex: "reportingManager",
      width: 200,
      filters: uniqueManager.map((reportingManager) => ({
        text: reportingManager,
        value: reportingManager,
      })),
      onFilter: (value, record) => record.reportingManager === value,
    },
    {
      title: "Start Date",
      dataIndex: "weekStartingDate",
      width: 200,
    },
    {
      title: "End Date",
      dataIndex: "weekEndingDate",
      width: 200,
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 150,
      render: (text) => (
        <span className={text === "Submitted" ? "submitted" : "pending"}>
          {text}
        </span>
      ),
    },

    {
      title: "Action",
      key: "action",
      dataIndex: "action",
      width: 200,
      align: "center",
      render: (text, record) => (
        <div style={{ display: "flex", gap: "5px" }}>
          <Popconfirm
            title="Are you sure you want to Approve this timesheet?"
            onConfirm={() => UnlockApprove(record)}
          >
            <Tooltip title="Approve">
              <Button
                icon={<CheckOutlined />}
                type="primary"
                style={{ backgroundColor: "green" }}
              />
            </Tooltip>
          </Popconfirm>
          <Popconfirm
            title="Are you sure you want to Approve this timesheet?"
            onConfirm={() => UnlockReject(record)}
          >
            <Tooltip title="Reject">
              <Button icon={<CloseOutlined />} type="primary" danger />
            </Tooltip>
          </Popconfirm>
        </div>
      ),
    },
  ];
  useEffect(() => {
    fetchtable();
    fetchtable1();
  }, [showAllReports]);

  const fetchtable = async () => {
    setLoading(true);
    try {
      // Reset employeeComplianceData to an empty array
      if (!showAllReports) {
        setLoading(true);
        const dbName = localStorage.getItem("dbName");
        fetch(
          `${url}/timesheet/manager/Submitted/${user.displayName}/manager/${dbName}`
        )
          .then((response) => response.json())
          .then((data) => setData(data))
          .catch((error) => console.error(error));
        console.log(data);
        setLoading(false);
      } else if (showAllReports && jobTitle === "hr") {
        setLoading(true);
        const dbName = localStorage.getItem("dbName");
        fetch(
          `${url}/timesheet/manager/Submitted/${user.displayName}/hr/${dbName}`
        )
          .then((response) => response.json())
          .then((data) => setData(data))
          .catch((error) => console.error(error));
        console.log(data);
        setLoading(false);
      } else if (showAllReports && jobTitle === "admin") {
        const dbName = localStorage.getItem("dbName");
        fetch(
          `${url}/timesheet/manager/Submitted/${user.displayName}/admin/${dbName}`
        )
          .then((response) => response.json())
          .then((data) => setData(data))
          .catch((error) => console.error(error));
        console.log(data);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      message.error("Failed to fetch data from the server.");
      setLoading(false);
    }
  };

  const fetchtable1 = async () => {
    setLoading(true);
    try {
      // Reset employeeComplianceData to an empty array
      if (!showAllReports) {
        setLoading(true);
        const dbName = localStorage.getItem("dbName");
        fetch(
          `${url}/unlock/manager/Requested/${user.displayName}/manager/${dbName}`
        )
          .then((response) => response.json())
          .then((data) => setData1(data))
          .catch((error) => console.error(error));
        setLoading(false);
      } else if (showAllReports && jobTitle === "hr") {
        setLoading(true);
        const dbName = localStorage.getItem("dbName");
        fetch(
          `${url}/unlock/manager/Requested/${user.displayName}/hr/${dbName}`
        )
          .then((response) => response.json())
          .then((data) => setData1(data))
          .catch((error) => console.error(error));
        setLoading(false);
      } else if (showAllReports && jobTitle === "admin") {
        const dbName = localStorage.getItem("dbName");
        fetch(
          `${url}/unlock/manager/Requested/${user.displayName}/admin/${dbName}`
        )
          .then((response) => response.json())
          .then((data) => setData1(data))
          .catch((error) => console.error(error));
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      message.error("Failed to fetch data from the server.");
      setLoading(false);
    }
  };
  const toggleShowAllReports = () => {
    setShowAllReports(!showAllReports);
  };

  const handleRowSelect = (id) => {
    if (selectedRowKeys.includes(id)) {
      setSelectedRowKeys(selectedRowKeys.filter((key) => key !== id));
    } else {
      setSelectedRowKeys([...selectedRowKeys, id]);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRowKeys([]);
    } else {
      setSelectedRowKeys(data.map((record) => record._id));
    }
    setSelectAll(!selectAll);
  };

  const handleBulkApprove = async () => {
    setSelectAll(false);
    if (selectedRowKeys.length === 0) {
      message.warning("No rows selected for approval.");

      return;
    }

    try {
      const dbName = localStorage.getItem("dbName");

      // Create an array of promises to approve each selected row
      const approvePromises = selectedRowKeys.map(async (recordId) => {
        const record = data.find((data) => data._id === recordId);
        if (record) {
          return axios.put(`${url}/timesheet/approve/${record._id}/${dbName}`, {
            status: "Approved",
            email: record.email,
          });
        }
      });

      // Execute all approval promises
      const results = await Promise.all(approvePromises);

      // Remove the approved rows from the data
      const updatedData = data.filter(
        (record) => !selectedRowKeys.includes(record._id)
      );

      setData(updatedData);
      setSelectedRowKeys([]);
      notification.success({ message: "Selected rows approved successfully!" });
    } catch (error) {
      console.error(error);
      message.error("Failed to bulk approve");
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    console.log("Input value:", inputValue);
    if (inputValue === "") {
      message.error("Please enter the reason");
    } else {
      try {
        const dbName = localStorage.getItem("dbName");
        const response = await axios.put(
          `${url}/timesheet/approve/${dataId}/${dbName}`,
          {
            status: "Rejected",
            reason: inputValue,
            emails: email,
          }
        );
        setIsModalOpen(false);
        setData((prevData) => prevData.filter((data) => data._id !== dataId));
        notification.success({ message: "Rejected  successfully!" });
        setInputValue("");
      } catch (error) {
        console.error(error);
        setIsModalOpen(false);
        message.error("Failed to reject");
      }
    }
  };

  const Approve = async (record) => {
    try {
      const dbName = localStorage.getItem("dbName");
      const response = await axios.put(
        `${url}/timesheet/approve/${record._id}/${dbName}`,
        {
          status: "Approved",
          email: record.email,
        }
      );
      setData((prevData) => prevData.filter((data) => data._id !== record._id));
      notification.success({ message: "Approved  successfully!" });
    } catch (error) {
      console.error(error);
      message.error("Failed to Approve");
    }
  };
  const UnlockApprove = async (record) => {
    try {
      const dbName = localStorage.getItem("dbName");

      // Make the API call to update or create the timesheet entry
      const response = await axios.post(`${url}/Request/timesheet/${dbName}`, {
        status: "Approved",
        empCode: record.empCode,
        start: record.weekStartingDate,
        end: record.weekEndingDate,
        weekNumber: record.weekId,
        reportingManager: record.reportingManager,
        hrName: record.hrName,
        userName: record.userName,
        email: record.email,
      });

      // Update the UI after successful approval
      setData1((prevData) =>
        prevData.filter((data1) => data1._id !== record._id)
      );
      notification.success({ message: "Approved successfully!" });
    } catch (error) {
      console.error("Error during approval:", error);
      message.error("Failed to Approve");
    }
  };

  const UnlockReject = async (record) => {
    try {
      const dbName = localStorage.getItem("dbName");
      const response = await axios.post(`${url}/Request/timesheet/${dbName}`, {
        status: "Request Denied",
        empCode: record.empCode,
        start: record.weekStartingDate,
        end: record.weekEndingDate,
        weekNumber: record.weekId,
        reportingManager: record.reportingManager,
        hrName: record.hrName,
        userName: record.userName,
        email: record.email,
      });
      setData1((prevData) =>
        prevData.filter((data1) => data1._id !== record._id)
      );
      notification.success({ message: "Approved  successfully!" });
    } catch (error) {
      console.error(error);
      message.error("Failed to Approve");
    }
  };
  const Remove = (record) => {
    setIsModalOpen(true);
    setdataId(record._id);
    setemail(record.email);
  };
  const handleCancel = (record) => {
    setIsModalOpen(false);
    setInputValue("");
    setdataId(false);
    setemail(false);
  };

  const handleChange = (event) => {
    const inputValue = event.target.value;

    const alphanumericWithSpaces = /^[a-zA-Z0-9 ]*$/;

    if (alphanumericWithSpaces.test(inputValue)) {
      setInputValue(inputValue);
    } else if (inputValue.trim() === "") {
      message.error("Enter valid input");
    } else {
      message.error("Enter valid input");
    }
  };
  return (
    <div
      style={{ transform: "scale(0.9)", transformOrigin: "0 0", width: "110%" }}
    >
      <Modal
        title="Reason for reject"
        open={isModalOpen}
        onOk={handleSubmit}
        onCancel={handleCancel}
      >
        <Form onSubmit={handleSubmit}>
          <Form.Item>
            <Input
              type="text"
              value={inputValue}
              onChange={handleChange}
              placeholder="Enter Reason"
              rules={[
                {
                  required: true,
                  message: "Please Input Task Name..!",
                },
              ]}
            />
          </Form.Item>
        </Form>
      </Modal>

      <Tabs defaultActiveKey="1" centered>
        <TabPane
          tab={
            <Badge offset={[15]} count={data.length}>
              <span style={{ color: "black", fontSize: "20px" }}>
                Timesheet Approval
              </span>
            </Badge>
          }
          key="1"
        >
          <div
            style={{
              display: "flex",
              marginBottom: "10px",
              justifyContent: "space-between",
            }}
          >
            <Checkbox
              style={{ marginTop: "5vh" }}
              checked={selectAll}
              onChange={handleSelectAll}
            >
              Select All
            </Checkbox>
            <div>
              {(jobTitle === "hr" || jobTitle === "admin") && (
                <label
                  style={{
                    display: "flex",
                    // justifyContent: "",
                    fontSize: "14px",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={showAllReports}
                    onChange={toggleShowAllReports}
                  />
                  Show All Employee
                </label>
              )}
            </div>
            <Popconfirm
              title="Are you sure you want to approve selected timesheets?"
              onConfirm={handleBulkApprove}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="primary"
                style={{ backgroundColor: "green", float: "right" }}
              >
                Approve
              </Button>
            </Popconfirm>
          </div>

          <div>
            {loading ? (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  fontSize: "100px",
                }}
              >
                <Spin size="large" />
              </div>
            ) : (
              <div style={{ maxHeight: "500px", overflowY: "auto" }}>
                <Table
                  className="custom-table col-lg-12 col-md-12 col-sm-12"
                  columns={columns}
                  dataSource={data}
                  pagination={pageSize}
                  scroll={{ x: true }}
                  sticky
                />
              </div>
            )}
          </div>
        </TabPane>

        <TabPane
          tab={
            <Badge offset={[15]} count={data1.length}>
              <span style={{ color: "black", fontSize: "20px" }}>
                Unlock Past Timesheet
              </span>
            </Badge>
          }
          key="2"
        >
          <div
            style={{
              display: "flex",
              marginBottom: "10px",
              justifyContent: "center",
            }}
          >
            <div>
              {(jobTitle === "hr" || jobTitle === "admin") && (
                <label
                  style={{
                    display: "flex",
                    // justifyContent: "",
                    fontSize: "14px",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={showAllReports}
                    onChange={toggleShowAllReports}
                  />
                  Show All Employee
                </label>
              )}
            </div>
          </div>

          <div>
            {loading ? (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  fontSize: "100px",
                }}
              >
                <Spin size="large" />
              </div>
            ) : (
              <div style={{ maxHeight: "500px", overflowY: "auto" }}>
                <Table
                  className="custom-table col-lg-12 col-md-12 col-sm-12"
                  columns={columns1}
                  dataSource={data1}
                  pagination={pageSize}
                  scroll={{ x: true }}
                  sticky
                />
              </div>
            )}
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Pendinglist;
