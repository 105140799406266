// MenuContext.js
import React, { createContext, useState } from 'react';
 
const MenuContext = createContext();
 
const MenuProvider = ({ children }) => {
  const [selectedMenu, setSelectedMenu] = useState('');
 
  const updateSelectedMenu = (menu) => {
    setSelectedMenu(menu);
  };
 
  return (
<MenuContext.Provider value={{ selectedMenu, updateSelectedMenu }}>
      {children}
</MenuContext.Provider>
  );
};
 
export { MenuProvider, MenuContext };