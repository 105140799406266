import React, { useState, useEffect } from "react";
import {
  Table,
  Input,
  Button,
  Select,
  Row,
  Col,
  notification,
  Popconfirm,
  Modal,
  Spin,
  Tooltip,
} from "antd";
import { EditOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import debounce from "lodash.debounce";
import { url } from "../../url/url";
import axios from "axios";
import {
  currencyOptions,
  yearOptions,
  locOptions,
} from "../../components/Constants";
const { Option } = Select;

const EditJobModal = ({ visible, onOk, onCancel, mode, job }) => {
  const [rateTypeOptions, setRateTypeOptions] = useState([
    // You can fetch these from a server or define them as you need
    { value: "Job-Hourly", label: "Job-Hourly" },
    { value: "Job-Daily", label: "Job-Daily" },
    { value: "Job-Weekly", label: "Job-Weekly" },
    { value: "Emp-Hourly", label: "Emp-Hourly" },
    { value: "Emp-Daily", label: "Emp-Daily" },
    { value: "Emp-Weekly", label: "Emp-Weekly" },
    // ... other rate types ...
  ]);
  const [onsiteRemoteOptions, setOnsiteRemoteOptions] = useState([
    // You can fetch these from a server or define them as you need
    { value: "Onsite", label: "Onsite" },
    { value: "Remote", label: "Remote" },
  ]);
  const defaultEditedJob = {
    designation: "",
    rate: "",
    currency: "INR",
    forYear: "2024",
    location: "TamilNadu, India",
    rateType: "",
    onsiteRemote: "Remote",
  };

  const [editedJob, setEditedJob] = useState(job || defaultEditedJob);

  useEffect(() => {
    if (job) {
      setEditedJob(job);
    } else {
      setEditedJob(defaultEditedJob);
    }
  }, [job]);

  const isAddMode = mode === "add";
  const modalTitle = isAddMode ? "Add Rate Card" : "Edit Rate Card";
  const okButtonText = isAddMode ? "Add" : "Update";
  const isEmployeeRateType = editedJob.rateType
    ? editedJob.rateType.startsWith("Emp-")
    : false;

  const handleOk = () => {
    onOk(editedJob, mode === "add");
  };
  const [employeeIdInput, setEmployeeIdInput] = useState("");
  const [searchResult, setSearchResult] = useState("");

  // Function to call the backend and search by employee ID
  const searchEmployee = async (value) => {
    const dbName = localStorage.getItem("dbName");
    const response = await fetch(
      `${url}/api/search-employee?query=${value}&dbName=${dbName}`
    );
    const data = await response.json();
    if (data.empCode) {
      const displayValue = `${data.empCode} - ${data.firstName} ${data.lastName}`;
      setSearchResult(displayValue);
    } else {
      setSearchResult("");
    }
  };
  const handleSelectEmployee = (empCode) => {
    setEmployeeIdInput(empCode);
    setSearchResult("");
  };
  // Debounced search function
  const debouncedSearch = debounce(searchEmployee, 300);

  useEffect(() => {
    if (employeeIdInput && !searchResult) {
      debouncedSearch(employeeIdInput);
    } else {
      debouncedSearch.cancel();
    }
  }, [employeeIdInput, debouncedSearch]);

  return (
    <Modal
      title={modalTitle}
      visible={visible}
      onOk={handleOk}
      onCancel={onCancel}
      okText={okButtonText}
    >
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12}>
          <label htmlFor="year">Year:</label>
          <Select
            id="year"
            value={editedJob.forYear}
            onChange={(value) => setEditedJob({ ...editedJob, forYear: value })}
          >
            {yearOptions.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} sm={12}>
          <label htmlFor="location">Location:</label>
          <Select
            id="location"
            value={editedJob.location}
            onChange={(value) =>
              setEditedJob({ ...editedJob, location: value })
            }
          >
            {locOptions.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} sm={12}>
          <label htmlFor="onsiteRemote">Remote or Onsite:</label>
          <Select
            id="onsiteRemote"
            value={editedJob.onsiteRemote}
            onChange={(value) =>
              setEditedJob({ ...editedJob, onsiteRemote: value })
            }
          >
            {onsiteRemoteOptions.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Col>
        {!isEmployeeRateType || mode === "add" ? (
          <Col xs={24} sm={12}>
            <label htmlFor="rateType">Rate Type:</label>
            <Select
              id="rateType"
              value={editedJob.rateType}
              onChange={(value) =>
                setEditedJob({ ...editedJob, rateType: value })
              }
            >
              {rateTypeOptions.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Col>
        ) : null}
        <Col xs={24} sm={12}>
          <label htmlFor="rate">Rate Per Hour:</label>
          <Input
            id="rate"
            value={editedJob.rate}
            onChange={(e) =>
              setEditedJob({ ...editedJob, rate: e.target.value })
            }
            placeholder="Rate"
          />
        </Col>
        <Col xs={24} sm={12}>
          <label htmlFor="currency">Currency:</label>
          <Select
            id="currency"
            value={editedJob.currency}
            onChange={(value) =>
              setEditedJob({ ...editedJob, currency: value })
            }
          >
            {currencyOptions.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Col>

        {isEmployeeRateType ? (
          <Col xs={24} sm={24}>
            <label htmlFor="employeeSearch">Employee Search:</label>
            <Input
              id="employeeSearch"
              value={searchResult || employeeIdInput}
              onChange={(e) => {
                setEmployeeIdInput(e.target.value);
                setSearchResult(""); // Clear search result if input is changed
              }}
              placeholder="Employee Name or ID Search"
              onFocus={() => setSearchResult("")}
              onBlur={() => {
                if (searchResult) {
                  setEmployeeIdInput(searchResult);
                  // Update the designation in newJob object
                  setEditedJob({ ...editedJob, designation: searchResult });
                }
              }}
            />
          </Col>
        ) : (
          <Col xs={24} sm={24}>
            <label htmlFor="jobTitle">Job Title:</label>
            <Input
              id="jobTitle"
              value={editedJob.designation}
              onChange={(e) =>
                setEditedJob({ ...editedJob, designation: e.target.value })
              }
              placeholder="Job Title"
            />
          </Col>
        )}

        <Col xs={24} sm={24}>
          <label htmlFor="skillset">Skill Set:</label>
          <Input
            id="skillset"
            value={editedJob.skillset}
            onChange={(e) =>
              setEditedJob({ ...editedJob, skillset: e.target.value })
            }
            placeholder="Capture Skills"
          />
        </Col>
      </Row>
    </Modal>
  );
};

const BillableRates = () => {
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState("add");
  const [designations, setDesignations] = useState([]);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [currentJob, setCurrentJob] = useState(null);
  const [skillsetTooltipVisible, setSkillsetTooltipVisible] = useState(false);
  const [selectedSkillset, setSelectedSkillset] = useState("");
  const handleViewSkills = (record) => {
    setSelectedSkillset(record.skillset);
    setSkillsetTooltipVisible(!skillsetTooltipVisible);
  };

  const [newJob, setNewJob] = useState({
    designation: "",
    rate: "",
    currency: "",
    rateType: "",
    forYear: "",
  });
  const addNewJob = (newJob) => {
    setLoading(true);
    if (
      newJob.designation &&
      newJob.rate &&
      newJob.currency &&
      newJob.forYear &&
      newJob.location
    ) {
      const payload = {
        jobDesignation: newJob.designation,
        ratePerHour: parseFloat(newJob.rate),
        currency: newJob.currency,
        forYear: newJob.forYear,
        rateType: newJob.rateType,
        location: newJob.location,
        onsiteRemote: newJob.onsiteRemote,
        skillset: newJob.skillset,
      };
      const dbName = localStorage.getItem("dbName");
      console.log(payload,"pay");
      axios
        .post(`${url}/api/jobs/${dbName}`, payload)
        .then((response) => {
          notification.success({
            message: "Success",
            description: "Job added successfully!",
          });
          setDesignations([
            ...designations,
            { ...response.data.addJob, key: response.data.addJob._id },
          ]);
          setNewJob({
            designation: "",
            rate: "",
            currency: "",
            forYear: "",
            location: "",
          });
        })
        .catch((error) => {
          notification.error({
            message: "Error",
            description: "There was an error adding the job.",
          });
        });
    } else {
      notification.error({
        message: "Error",
        description: "All fields are required.",
      });
    }
    setEditMode("add");
    setLoading(false);
  };

  const handleUpdateJob = (updatedJob, isAddMode) => {
    setLoading(true);
    if (isAddMode) {
      // Handle adding a new job
      addNewJob(updatedJob);
    } else {
      const index = designations.findIndex(
        (item) => updatedJob.key === item.key
      );

      // Check if there's a change in the data
      if (
        index !== -1 &&
        (updatedJob.rate !== designations[index].rate ||
          updatedJob.currency !== designations[index].currency ||
          updatedJob.onsiteRemote !== designations[index].onsiteRemote ||
          updatedJob.forYear !== designations[index].forYear ||
          updatedJob.location !== designations[index].location)
      ) {
        // Prepare the payload for the update
        const updatePayload = {
          ratePerHour: updatedJob.rate,
          forYear: updatedJob.forYear,
          location: updatedJob.location,
          currency: updatedJob.currency,
          rateType: updatedJob.rateType,
          onsiteRemote: updatedJob.onsiteRemote,
          skillset: updatedJob.skillset,
        };

        const dbName = localStorage.getItem("dbName");
        axios
          .put(
            `${url}/api/jobs/update/${dbName}/${updatedJob.key}`,
            updatePayload
          )
          .then((response) => {
            notification.success({
              message: "Success",
              description: "Job updated successfully!",
            });
            // Update state only after successful response
            const updatedDesignations = [...designations];
            updatedDesignations[index] = {
              ...updatedDesignations[index],
              ...updatedJob,
            };
            setDesignations(updatedDesignations);
          })
          .catch((error) => {
            notification.error({
              message: "Error",
              description: "There was an error updating the job.",
            });
          });
      }
    }

    setEditModalVisible(false);
    setLoading(false);
  };
  const showAddModal = () => {
    setCurrentJob(defaultNewJob);
    setEditModalVisible(true);
    setEditMode("add");
  };
  const defaultNewJob = {
    designation: "",
    rate: "",
    currency: "INR",
    forYear: "2024",
    location: "TamilNadu, India",
    rateType: "Job-Hourly",
    onsiteRemote: "Remote",
  };

  const showEditModal = (job) => {
    setCurrentJob(job);
    setEditModalVisible(true);
    setEditMode("edit");
  };
  useEffect(() => {
    setLoading(true);
    const dbName = localStorage.getItem("dbName");
    axios.get(`${url}/api/jobs/${dbName}`).then((response) => {
      setDesignations(
        response.data.map((job) => ({
          key: job._id,
          designation: job.jobDesignation,
          rate: job.ratePerHour,
          forYear: job.forYear,
          rateType: job.rateType,
          location: job.location,
          currency: job.currency,
          onsiteRemote: job.onsiteRemote,
          skillset: job.skillset,
        }))
      );
      setLoading(false);
    });
  }, []);

  const handleDelete = (key) => {
    const dbName = localStorage.getItem("dbName");
    axios
      .delete(`${url}/api/jobs/${dbName}/${key}`)
      .then(() => {
        setDesignations(designations.filter((item) => item.key !== key));
        notification.success({
          message: "Success",
          description: "Job deleted successfully!",
        });
      })
      .catch(() => {
        notification.error({
          message: "Error",
          description: "There was an error deleting the job.",
        });
      });
  };
  const generateFilters = (dataIndex) => {
    const uniqueValues = [
      ...new Set(designations.map((item) => item[dataIndex])),
    ];
    return uniqueValues.map((value) => ({
      text: value,
      value: value,
    }));
  };
  const columns = [
    {
      title: "Year",
      dataIndex: "forYear",
      key: "forYear",
      filters: generateFilters("forYear"),
      onFilter: (value, record) => record.forYear.includes(value),
    },
    {
      title: "Based on",
      dataIndex: "rateType",
      key: "rateType",
      filters: generateFilters("rateType"),
      onFilter: (value, record) =>
        record.rateType ? record.rateType.includes(value) : false,
    },
    {
      title: "Role or Employee",
      dataIndex: "designation",
      key: "designation",
      filters: generateFilters("designation"),
      onFilter: (value, record) => record.designation.includes(value),
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      filters: generateFilters("location"),
      onFilter: (value, record) => record.location.includes(value),
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
      align: "center",
      filters: generateFilters("currency"),
      onFilter: (value, record) => record.currency.includes(value),
    },
    {
      title: "LocationType",
      dataIndex: "onsiteRemote",
      key: "onsiteRemote",
      align: "center",
      filters: generateFilters("onsiteRemote"),
      onFilter: (value, record) =>
        record.onsiteRemote ? record.onsiteRemote.includes(value) : false,
    },
    {
      title: "Rate",
      dataIndex: "rate",
      key: "rate",
      align: "right",
      sorter: (a, b) => a.rate - b.rate,
      sortDirections: ["descend", "ascend"],
    },

    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <>
          <Tooltip title="Edit">
            <EditOutlined
              onClick={() => showEditModal(record)}
              style={{ color: "blue", marginRight: 12 }}
            />
          </Tooltip>
          <Tooltip title="Delete">
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(record.key)}
            >
              <DeleteOutlined style={{ color: "blue", marginRight: 12 }} />
            </Popconfirm>
          </Tooltip>
          <Tooltip title="Skills">
            <EyeOutlined
              style={{ color: "blue", marginRight: 12 }}
              onClick={() => handleViewSkills(record)}
            />
          </Tooltip>

          <Tooltip
            title={selectedSkillset}
            visible={skillsetTooltipVisible}
            placement="top" // You can change the placement as needed
            trigger="hover" // Change to "hover" if you want to show on hover
            onVisibleChange={(visible) => setSkillsetTooltipVisible(visible)}
          ></Tooltip>
        </>
      ),
    },
  ];

  return (
    <div style={{
      transform: "scale(0.9)",
      transformOrigin: "0 0", width: "110%",
    }}>
      <h4>Manage Rate Card</h4>
      <Row gutter={16} style={{ marginBottom: 10 }}>
        <Col xs={24} sm={12} md={8} lg={3}>
          <Button
            onClick={showAddModal}
            type="primary"
            style={{ marginTop: "32px" }}
          >
            Add Rate Card
          </Button>
        </Col>
      </Row>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "300px",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <Table
          dataSource={designations}
          columns={columns}
          scroll={{ x: "max-content" }}
          className="custom-table col-lg-12 col-md-12 col-sm-12"
          sticky
        />
      )}
      <EditJobModal
        visible={editModalVisible}
        onOk={handleUpdateJob}
        onCancel={() => setEditModalVisible(false)}
        mode={editMode}
        job={currentJob}
      />
    </div>
  );
};

export default BillableRates;
