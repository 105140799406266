import React from "react";
import { Card, Row, Col, Tabs } from "antd";
import { Link } from "react-router-dom";
import { AuditOutlined, DatabaseOutlined } from "@ant-design/icons";
import "./Job.css";
import Openings from "./Openings";
import { TabPane } from "react-bootstrap";
import Details from "./Details";

const Home = () => {
 
  return (
    <div>
      <Tabs style={{ zoom: "90%" }}>
        <TabPane tab="Job Opening" key="Job Opening">
            <Openings/>
        </TabPane>
        <TabPane tab="Applicants Details" key="Applicants Details">
            <Details/>
        </TabPane>
      </Tabs>
     
    </div>
  );
};

export default Home;
