import React from "react";
import { useState, useEffect } from "react";
import "./Details.css";
import { CloseOutlined } from "@ant-design/icons";
import { Button, notification, Select, Form, Input, message } from "antd";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { url } from "../../url/url";

const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 150,
  },
};

const Addproject = () => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [form] = Form.useForm();
  const [list, setList] = useState([]);
  const [tasks, setTask] = useState([]);
  const formRef = React.useRef(null);
  const [users, setUsers] = useState([]);
  const user1 = JSON.parse(localStorage.getItem("user"));
  const status = localStorage.getItem("status");
  const [projData, setprojData] = useState({
    Projectcode: "",
    ProjectName: "",
    ClientName: "",
    ProjectManager: "",
    CostCent: "",
    StartDate: "",
    EndDate: "",
    TotalEmp: [user1.displayName],
    Desc: "",
    TaskName: ["Leave", "Holiday", "Permission"],
    EmpCode: "",
  });
  const [endDateError, setEndDateError] = useState(null);
  const fetchData = async () => {
    try {
      const dbName = localStorage.getItem("dbName");
      const response = await axios.get(`${url}/api/projectsdetails/${dbName}`);
      setTableData(response.data);
    } catch (error) {
      console.error(error);
      message.error("Failed to fetch data from the server.");
    }
  };

  const handleSelect = (selectedValues) => {
    console.log(selectedValues, "dis");
    const selectedUsersArr = [];
    console.log(selectedUsersArr, "selectedUsersArr");
    const selectedUserCodesArr = [];
    console.log(selectedUsersArr);
    console.log(selectedUserCodesArr);
    users.forEach((user) => {
      if (selectedValues.includes(user.displayName)) {
        selectedUsersArr.push(user.displayName);
        selectedUserCodesArr.push(user.empCode);
      }
    });
    setprojData((prevState) => ({
      ...prevState,
      TotalEmp: selectedUsersArr,
    }));

    setprojData((prevState) => ({
      ...prevState,
      EmpCode: selectedUserCodesArr,
    }));
  };
  const handlelist = (selectedValues) => {
    setprojData((prevState) => ({
      ...prevState,
      ProjectManager: selectedValues,
    }));
  };

  const handletask = (selectedValues) => {
    const LeaveTask = "Leave";
    const Holiday = "Holiday";
    const Permission = "Permission";
    const updatedValues = selectedValues.includes(
      LeaveTask,
      Holiday,
      Permission
    )
      ? selectedValues
      : [...selectedValues, LeaveTask, Holiday, Permission];

    setprojData((prevState) => ({
      ...prevState,
      TaskName: updatedValues,
    }));
  };

  const fetchUsers = async () => {
    const dbName = localStorage.getItem("dbName");
    console.log("dbName in fetchUsers in addproject", dbName);
    const response = await fetch(`${url}/api/get/allemplist/${dbName}`);
    const data = await response.json();
    const activeUsers = data.filter((user) => user.empStatus !== "inactive");
    setUsers(activeUsers);
    console.log("active users", activeUsers);
    //setUsers(data);
  };

  useEffect(() => {
    fetchTask();
    fetchList();
    fetchUsers();
    fetchData();
  }, []);

  const fetchList = async () => {
    const dbName = localStorage.getItem("dbName");
    console.log("dbName in fetchList in addproject", dbName);
    const response = await fetch(`${url}/api/get/manager/${dbName}`);
    const data = await response.json();
    console.log(data);
    setList(data);
  };

  const fetchTask = async () => {
    const dbName = localStorage.getItem("dbName");
    console.log("dbName in fetchTask in addproject", dbName);
    const response = await fetch(`${url}/api/newTask/${dbName}`);
    const data = await response.json();
    console.log("task in fetchtask in addproject", data);
    setTask(data);
  };

  const specialCharsRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\]/;

  function containsSpecialCharacters(input) {
    return specialCharsRegex.test(input);
  }

  function isEndDateValid(startDate, endDate) {
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);
    return endDateObj >= startDateObj;
  }

  function handlechange(event) {
    const { name, value } = event.target;

    // if (containsSpecialCharacters(value)) {
    //   message.error("Special characters are not allowed.");
    //   return;
    // }

    if (name === "EndDate") {
      // Check if the entered end date is valid
      if (!isEndDateValid(projData.StartDate, value)) {
        setEndDateError("End Date cannot be less than Start Date"); // Step 2
      } else {
        setEndDateError(null); // Clear the error message
      }
    }

    setprojData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  }

  // ...

  const validateAlphanumeric = (rule, value, callback) => {
    if (!value) {
      callback();
    } else if (!/^[A-Za-z0-9-_ ]*[A-Za-z0-9_][A-Za-z0-9-_ ]*$/.test(value)) {
      callback("Input should not contain Special Characters.");
    } else {
      callback();
    }
  };

  function handleReset() {
    formRef.current?.resetFields();
    setEndDateError("");
  }

  function postData() {
    if (!endDateError) {
      const views = tableData.find(
        (p) => p.ProjectName === projData.ProjectName
      );
      const views1 = tableData.find(
        (p) => p.Projectcode === projData.Projectcode
      );

      if (views1 === undefined) {
        if (views === undefined) {
          const dbName = localStorage.getItem("dbName");
          console.log("dbName in fetchList in postdata in addproject", dbName);

          fetch(`${url}/api/details/${dbName}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(projData),
          })
            .then((response) => response.json())
            .then((data) => {
              console.log("Response from API:", data);

              // Reset projData fields
              setprojData({
                Projectcode: "",
                ProjectName: "",
                ClientName: "",
                CostCent: "",
                StartDate: "",
                EndDate: "",
                TotalEmp: "",
                Desc: "",
              });

              // Navigate or perform other actions
              navigate("/home/project");

              // Show success notification
              notification.success({
                message: "Success",
                description: "Project data has been successfully added!",
              });
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        } else {
          message.error("Project name already exists!");
        }
      } else {
        message.error("Project Code Already exists!");
      }
    }
  }
  const validateYearFormat = (rule, value, callback) => {
    // Check if the value is a valid date with a 4-digit year
    const regex = /^\d{4}-\d{2}-\d{2}$/; // YYYY-MM-DD format
    if (!regex.test(value)) {
      callback("Please enter a valid date in DD-MM-YYYY format.");
    } else {
      callback();
    }
  };

  return (
    <div className="" style={{ zoom: "88%" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Link to="/home/project">
          <Button>Back</Button>
        </Link>
      </div>
      <Form
        {...layout}
        ref={formRef}
        form={form}
        onFinish={postData}
        value="large"
      >
        <h2 className="text-center" style={{ margin: "30px" }}>
          Add Project
        </h2>
        <div className="row mb-4">
          <div className="col-lg-6 col-sm-12">
            <Form.Item
              label="Project Code"
              name="Projectcode"
              rules={[
                {
                  required: true,
                  message: "Please Input Project Code..!",
                },
                {
                  validator: validateAlphanumeric, // Use the custom validation function
                },
              ]}
            >
              <Input
                value={projData.Projectcode}
                onChange={handlechange}
                name="Projectcode"
                style={{ height: "40px" }}
              />
            </Form.Item>
          </div>
          <div className="col-lg-6 col-sm-12">
            <Form.Item
              label="Project Name"
              name="ProjectName"
              rules={[
                {
                  required: true,
                  message: "Please Input Project Name..!",
                },
                {
                  validator: validateAlphanumeric, // Use the custom validation function
                },
              ]}
            >
              <Input
                value={projData.ProjectName}
                onChange={handlechange}
                name="ProjectName"
                style={{ height: "40px" }}
              />
            </Form.Item>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-lg-6 col-sm-12 mb-sm-4">
            <Form.Item
              label="Project Manager"
              name="ProjectManager"
              rules={[
                {
                  required: true,
                  message: "Please Select Manager..!",
                },
              ]}
            >
              <Select
                size="large"
                name="ProjectManager"
                placeholder="Please select"
                onChange={handlelist}
                required
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              >
                {list.map((user) => (
                  <option key={user.id} value={user.displayName}>
                    {user.displayName}
                  </option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="col-lg-6 col-sm-12 ">
            <Form.Item
              label="Client Name"
              name="ClientName"
              rules={[
                {
                  required: true,
                  message: "Please Input Client Name..!",
                },
                {
                  validator: validateAlphanumeric, // Use the custom validation function
                },
              ]}
            >
              <Input
                value={projData.ClientName}
                onChange={handlechange}
                name="ClientName"
                style={{ height: "40px" }}
              />
            </Form.Item>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-lg-6 col-sm-12 mb-sm-4">
            <Form.Item
              label="Start Date"
              name="StartDate"
              rules={[
                {
                  required: true,
                  message: "",
                },
                {
                  validator: validateYearFormat, // Use the custom validation function
                },
              ]}
            >
              <Input
                type="date"
                value={projData.StartDate}
                onChange={handlechange}
                name="StartDate"
                style={{ height: "40px" }}
              />
            </Form.Item>
          </div>
          <div className="col-lg-6 col-sm-12 ">
            <Form.Item
              label="End Date"
              name="EndDate"
              rules={[
                {
                  required: true,
                  message: "",
                },
                {
                  validator: validateYearFormat, // Use the custom validation function
                },
              ]}
              validateStatus={endDateError ? "error" : ""} // Step 3
              help={endDateError}
            >
              <Input
                type="date"
                value={projData.EndDate}
                onChange={handlechange}
                name="EndDate"
                style={{ height: "40px" }}
              />
            </Form.Item>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-lg-6 col-sm-12">
            <Form.Item
              label="Cost Center"
              name="CostCent"
              rules={[
                {
                  required: true,
                  message: "Please Enter Your Cost Center..!",
                },
                {
                  validator: validateAlphanumeric,
                },
              ]}
            >
              <Input
                value={projData.CostCent}
                onChange={handlechange}
                name="CostCent"
                style={{ height: "40px" }}
                rules={[{ required: false }]}
              />
            </Form.Item>
          </div>
          <div className="col-lg-6 col-sm-12">
            <Form.Item
              label="Description"
              name="Desc"
              rules={[
                {
                  validator: validateAlphanumeric, // Use the custom validation function
                },
              ]}
            >
              <Input.TextArea
                placeholder="Capture Project details"
                onChange={handlechange}
                value={projData.Desc}
                name="Desc"
                style={{ height: "40px" }}
              />
            </Form.Item>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-lg-6 col-sm-12 mb-sm-4">
            <Form.Item
              label="Select Employees"
              name="TotalEmp"
              value={projData.TotalEmp.length}
            >
              <Select
                mode="multiple"
                allowClear
                name="TotalEmp"
                placeholder="Please select"
                onChange={handleSelect}
                defaultValue={projData.TotalEmp}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              >
                {users.map((user) => (
                  <option key={user.id} value={user.displayName}>
                    {user.displayName}
                  </option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="col-lg-6 col-sm-12">
            <Form.Item
              label="Task List"
              name="Task"
              value={projData.TaskName.length}
            >
              <Select
                mode="multiple"
                allowClear
                size="large"
                name="Task"
                placeholder="Please select"
                onChange={handletask}
                defaultValue={["Leave", "Holiday", "Permission"]}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              >
                {tasks.map((item) => (
                  <option key={item.task} value={item.task}>
                    {item.task}
                  </option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </div>
        <Form.Item>
          <Button danger onClick={handleReset} style={{ height: "40px" }}>
            Reset
          </Button>
        </Form.Item>
        <div className="text-center">
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ height: "40px" }}
              disabled={status === "disable"}
            >
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};
export default Addproject;
