import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Modal,
  Form,
  Select,
  Input,
  InputNumber,
  Popconfirm,
  message,
  notification,
  Descriptions,
  Spin,
  Tooltip,
} from "antd";
import "./Details.css";
import "../loginpage/login.css";
import axios from "axios";
import {
  PlusOutlined,
  EditFilled,
  EyeFilled,
  QuestionCircleOutlined,
  DeleteFilled,
  PlusSquareTwoTone,
  ExportOutlined,
  DownCircleTwoTone,
  DownloadOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import Papa from "papaparse";
import FileSaver from "file-saver";
import { url } from "../../url/url";
import { icon } from "leaflet";
import { decryptRole } from "../../encrypt";

const { Option } = Select;

const handlelist = (value) => {
  console.log(`selected ${value}`);
};

const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};

const Project = (props) => {
  const status = localStorage.getItem("status");

  const [editingRecord, setEditingRecord] = useState(null);
  const [form] = Form.useForm();
  const [tableData, setTableData] = useState([]);
  const [data, setData] = useState([]);
  const [users, setUsers] = useState([]);
  const [visible, setVisible] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [Columns, setColumns] = useState([]);
  const [tasks, setTask] = useState([]);
  const [open, setOpen] = useState(false);
  const [view, setView] = useState([]);
  const [list, setList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  useEffect(() => {
    fetchUsers();
    fetchTask();
    fetchList();
  }, []);
  useEffect(() => {
    fetchData();
  }, [editingRecord]);

  const fetchUsers = async () => {
    const dbName = localStorage.getItem("dbName");
    console.log("dbName in fetchUsers in project", dbName);
    const response = await fetch(`${url}/api/get/allemplist/${dbName}`);
    const data = await response.json();
    const activeUsers = data.filter((user) => user.empStatus !== "inactive");
    setUsers(activeUsers);
    console.log("active users", activeUsers);
  };
  const selectedUserNamesArr = [];
  const selectedUserEmpCodesArr = [];
  const handleSelect = (selectedValues) => {
    console.log(selectedValues);
    users.forEach((user) => {
      if (selectedValues.includes(user.displayName)) {
        selectedUserNamesArr.push(user.displayName);
        selectedUserEmpCodesArr.push(user.empCode);
      }
    });
  };
  const fetchList = async () => {
    const dbName = localStorage.getItem("dbName");
    console.log("dbName in fetchlists in project", dbName);
    const response = await fetch(`${url}/api/get/manager/${dbName}`);
    const data = await response.json();
    setList(data);
  };

  const fetchTask = async () => {
    const dbName = localStorage.getItem("dbName");
    console.log("dbName in fetchTask in project", dbName);
    const response = await fetch(`${url}/api/newTask/${dbName}`);
    const data = await response.json();
    setTask(data);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const displayName = `${user.firstName} ${user.lastName}`;
      console.log("displayName in project", displayName);
      const dbName = localStorage.getItem("dbName");
      const encryptedRoleFromStorage = localStorage.getItem("role");
      const jobTitle = decryptRole(encryptedRoleFromStorage);

      console.log("dbName in fetchData in project", dbName);
      if (jobTitle === "hr" || jobTitle === "admin") {
        const response = await axios.get(
          `${url}/api/projectsdetails/${dbName}`
        );
        setTableData(response.data);
        setData(response.data);
        setLoading(false);
      } else {
        const response = await axios.get(
          `${url}/api/projectsdetails/${user.displayName}/${dbName}`
        );
        setTableData(response.data);
        setData(response.data);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      message.error("Failed to fetch data from the server.");
    }
  };

  function handleOk() {
    const tableData = data.map((row) =>
      selectedColumns.reduce((acc, col) => {
        acc[col] = row[col];
        return acc;
      }, {})
    );
    const csv = Papa.unparse(tableData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    FileSaver.saveAs(blob, "Project_Details.csv", "C:");
    setVisible(false);
    setSelectedColumns([]);
  }

  function handleClick() {
    setVisible(true);
    setColumns(Object.keys(data[0]));
  }
  function handleCancel() {
    setVisible(false);
    setSelectedColumns([]);
  }
  function handleColumnSelect(value) {
    setSelectedColumns(value);
  }

  const handleEdit = (record) => {
    setEditingRecord(record);
    form.setFieldsValue(record);
  };

  const showModal = () => {
    setOpen(true);
  };

  const hideModal = () => {
    setOpen(false);
  };

  const handleProjectChange = (value) => {
    showModal();
    const views = tableData.find((p) => p._id === value);
    setView(views);
  };

  const handleUpdate = async (values) => {
    try {
      values.EmpCode = selectedUserEmpCodesArr;
      console.log(values);
      const dbName = localStorage.getItem("dbName");
      console.log("dbName in handleUpdate in project", dbName);
      const response = await axios.put(
        `${url}/api/updatedetails/${editingRecord._id}/${dbName}`,
        values
      );
      setTableData(
        tableData.map((record) =>
          record._id === editingRecord._id
            ? { ...record, ...response.data }
            : record
        )
      );
      setEditingRecord(null);
      notification.success({ message: "Record updated successfully!" });
    } catch (error) {
      console.error(error);
      message.error("Failed to update the record.");
    }
  };

  const handleDelete = async (id) => {
    console.log(id);
    try {
      const dbName = localStorage.getItem("dbName");
      console.log("dbName in handleUpdate in project", dbName);
      const response = await axios.delete(
        `${url}/api/deletedetails/${id}/${dbName}`
      );
      setTableData(tableData.filter((record) => record._id !== id));
      setEditingRecord(null);
      notification.success({ message: "Record deleted successfully!" });
    } catch (error) {
      console.error(error);
      message.error("Failed to delete the record.");
    }
  };

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredData = tableData.filter((item) => {
    const itemString = JSON.stringify(item).toLowerCase();
    return itemString.includes(searchQuery.toLowerCase());
  });
  const uniqueManager = [
    ...new Set(filteredData.map((item) => item.ProjectManager)),
  ];
  const columns = [
    {
      title: "Project Code",
      dataIndex: "Projectcode",
      width: 150,
    },
    {
      title: "Project Name",
      dataIndex: "ProjectName",
      width: 200,
      render: (text) => (
        <Tooltip
          title={text}
          getPopupContainer={(triggerNode) =>
            document.getElementById("tooltipContainer")
          }
          placement="top"
          mouseEnterDelay={0.5}
          mouseLeaveDelay={0.1}
        >
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Client Name",
      dataIndex: "ClientName",
      width: 200,
    },
    {
      title: "Project Manager",
      dataIndex: "ProjectManager",
      filters: uniqueManager.map((ProjectManager) => ({
        text: ProjectManager,
        value: ProjectManager,
      })),
      onFilter: (value, record) => record.ProjectManager === value,
      width: 200,
      render: (_, record) => <> {record.ProjectManager}</>,
    },

    {
      title: "Start Date",
      dataIndex: "StartDate",
      width: 200,
    },
    {
      title: "End Date",
      dataIndex: "EndDate",
      width: 200,
    },

    {
      title: "Edit",
      dataIndex: "action",
      render: (_, record) => (
        <Button
          icon={<EditFilled />}
          onClick={() => handleEdit(record)}
        ></Button>
      ),
    },
    {
      title: "View",
      dataIndex: "view",
      render: (_, record) => (
        <Button
          icon={<EyeFilled />}
          onClick={() => handleProjectChange(record._id)}
        ></Button>
      ),
    },
    {
      title: "Delete",
      width: 100,
      render: (_, record) => (
        <Popconfirm
          title="Are you sure to delete this record ?"
          onConfirm={() => handleDelete(record._id)}
          icon={
            <QuestionCircleOutlined
              style={{
                color: "red",
              }}
            />
          }
          okText="Yes"
          cancelText="No"
        >
          <Button size="small">
            <DeleteFilled />
          </Button>
        </Popconfirm>
      ),
    },
  ];
  // Define a function to filter columns
  function filterColumns() {
    // List of fields you want to exclude
    const excludedFields = ["_id", "__v"]; // Add the field names you want to exclude

    // Filter the columns to include only those not in the excludedFields list
    const columnsToInclude = Columns.filter(
      (col) => !excludedFields.includes(col)
    );

    return columnsToInclude;
  }

  const validateInput = (rule, value, callback) => {
    const specialCharactersRegex = /[!@#$%^&*()_+{}\[\]:;<>.?~\\]/;
    if (specialCharactersRegex.test(value)) {
      callback("Special characters are not allowed.");
    } else {
      callback();
    }
  };

  const validateYearFormat = (rule, value, callback) => {
    // Check if the value is a valid date with a 4-digit year
    const regex = /^\d{4}-\d{2}-\d{2}$/; // YYYY-MM-DD format
    if (!regex.test(value)) {
      callback("Please enter a valid date in YYYY-MM-DD format.");
    } else {
      callback();
    }
  };

  return (
    <div
      style={{
        transform: "scale(0.9)",
        transformOrigin: "0 0",
        width: "110%",
      }}
    >
      {loading ? (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <>
          <h4 className="text-center">Manage Project</h4>

          <div
            className="tag"
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "10px",
            }}
          >
            <div>
              <Link to="/home/addproject">
                <Button className="buttons" icon={<PlusSquareTwoTone />}>
                  Project
                </Button>
              </Link>
              <Link to="/home/addtask">
                <Button style={{ left: "5px" }}>Manage task</Button>
              </Link>
              <Button
                style={{ left: "10px" }}
                className="buttons"
                onClick={handleClick}
                icon={<DownloadOutlined />}
              ></Button>
            </div>
            <Modal
              title="Export to CSV"
              visible={visible}
              onOk={handleOk}
              onCancel={handleCancel}
              okButtonProps={{ disabled: selectedColumns.length === 0 }}
            >
              <p>Select columns to export:</p>
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                placeholder="Please select"
                value={selectedColumns}
                onChange={handleColumnSelect}
              >
                {filterColumns().map((col) => (
                  <Option key={col} value={col}>
                    {col}
                  </Option>
                ))}
              </Select>
            </Modal>
            <div>
              <Input
                type="text"
                size="large"
                style={{ height: "40px", fontSize: "15px" }}
                placeholder="Search Project..."
                value={searchQuery}
                onChange={handleSearchQueryChange}
              />
            </div>
          </div>
          <div
            style={{
              overflowX: "auto",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Table
              className="custom-table col-lg-12 col-md-12 col-sm-12"
              columns={columns}
              dataSource={filteredData}
              scroll={{ x: true }}
              sticky
            />
          </div>
          {editingRecord && (
            <Modal
              title="Edit Project"
              width={600}
              visible={editingRecord !== null}
              bodyStyle={{ maxHeight: 450, overflowY: "auto", margin: "20px" }}
              onCancel={() => setEditingRecord(null)}
              onOk={() => form.submit()}
              footer={[
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Button
                      type="primary"
                      onClick={() => form.submit()}
                      disabled={status === "disable"}
                    >
                      Save
                    </Button>
                    <Button onClick={() => setEditingRecord(null)}>
                      Cancel
                    </Button>
                  </div>
                </div>,
              ]}
            >
              <div>
                <Form
                  {...layout}
                  form={form}
                  size="large"
                  // style={{ padding: "0px", width: 'auto', margin: '10px' }} // You can customize the modal's position using inline styles
                  bodyStyle={{ maxHeight: 200, overflowY: "auto" }}
                  initialValues={editingRecord}
                  onFinish={handleUpdate}
                >
                  <Form.Item label="Project Code" name="Projectcode">
                    <Input
                      disabled
                      style={{ fontSize: "15px", marginTop: "10px" }}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Project Name"
                    name="ProjectName"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Project Name!",
                      },
                      {
                        validator: validateInput,
                      },
                    ]}
                  >
                    <Input style={{ fontSize: "15px", marginTop: "10px" }} />
                  </Form.Item>

                  <Form.Item
                    label="Client Name"
                    name="ClientName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your ClientName!",
                      },
                      {
                        validator: validateInput,
                      },
                    ]}
                  >
                    <Input style={{ fontSize: "15px", marginTop: "10px" }} />
                  </Form.Item>
                  <Form.Item
                    label="Project Manager"
                    name="ProjectManager"
                    rules={[
                      {
                        required: true,
                        message: "Select Project Manager",
                      },
                    ]}
                  >
                    <Select
                      style={{
                        width: "100%",
                        fontSize: "15px",
                        marginTop: "10px",
                      }}
                      name="ProjectManager"
                      placeholder="Please select"
                      onChange={handlelist}
                    >
                      {list.map((user) => (
                        <Select.Option key={user.id} value={user.displayName}>
                          {user.displayName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Cost Center"
                    name="CostCent"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Your Cost Center..!",
                      },
                      {
                        validator: validateInput,
                      },
                    ]}
                  >
                    <Input style={{ fontSize: "15px", marginTop: "10px" }} />
                  </Form.Item>
                  <Form.Item
                    label="Start Date"
                    name="StartDate"
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                      {
                        validator: validateYearFormat, // Use the custom validation function
                      },
                    ]}
                  >
                    <Input
                      style={{
                        height: "40px",
                        fontSize: "15px",
                        marginTop: "10px",
                      }}
                      type="date"
                      size="large"
                    />
                  </Form.Item>
                  <Form.Item
                    label="End Date"
                    name="EndDate"
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                      {
                        validator: validateYearFormat, // Use the custom validation function
                      },
                    ]}
                  >
                    <Input
                      style={{
                        height: "40px",
                        fontSize: "15px",
                        marginTop: "10px",
                      }}
                      type="date"
                      size="large"
                    />
                  </Form.Item>
                  <Form.Item label="Employees" name="TotalEmp">
                    <Select
                      style={{ fontSize: "15px", marginTop: "10px" }}
                      mode="multiple"
                      placeholder="Please select the No. of employees"
                      allowClear
                      onChange={handleSelect}
                    >
                      {users.map((user) => (
                        <Select.Option key={user.id} value={user.displayName}>
                          {user.displayName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Task-List" name="TaskName">
                    <Select
                      style={{ fontSize: "15px", marginTop: "10px" }}
                      mode="multiple"
                      placeholder="Please select Task"
                      allowClear
                    >
                      {tasks.map((item) => (
                        <Select.Option key={item.task} value={item.task}>
                          {item.task}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Description"
                    name="Desc"
                    rules={[
                      {
                        message: "Capture the details about the Project",
                      },
                    ]}
                  >
                    <Input.TextArea
                      style={{ fontSize: "15px" }}
                      placeholder="Capture the details about the Project"
                    />
                  </Form.Item>
                </Form>
              </div>
            </Modal>
          )}
          <Modal
            open={open}
            onOk={hideModal}
            width={900}
            onCancel={hideModal}
            footer={null} // Use the custom footer
          >
            <Descriptions title="Project Details" bordered column={1}>
              <Descriptions.Item label="Project Code">
                {view.Projectcode}
              </Descriptions.Item>
              <Descriptions.Item label="Project Name">
                {view.ProjectName}
              </Descriptions.Item>
              <Descriptions.Item label="Client Name">
                {view.ClientName}
              </Descriptions.Item>
              <Descriptions.Item label="Reporting Manager">
                {view.ProjectManager}
              </Descriptions.Item>
              <Descriptions.Item label="Cost Center">
                {view.CostCent}
              </Descriptions.Item>
              <Descriptions.Item label="Start Date">
                {view.StartDate}
              </Descriptions.Item>
              <Descriptions.Item label="End Date">
                {view.EndDate}
              </Descriptions.Item>
              <Descriptions.Item label="Employees">
                {view.TotalEmp && view.TotalEmp.length > 0
                  ? view.TotalEmp.join(", ")
                  : ""}
              </Descriptions.Item>
              <Descriptions.Item label="Task">
                {view.TaskName && view.TaskName.length > 0
                  ? view.TaskName.join(", ")
                  : ""}
              </Descriptions.Item>
              <Descriptions.Item label="Description">
                {view.Desc}
              </Descriptions.Item>
            </Descriptions>
          </Modal>
        </>
      )}
    </div>
  );
};

export default Project;
