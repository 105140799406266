import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, DatePicker, Checkbox, Radio, message, Row, Col, Card, Popconfirm } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import axios from 'axios';

const Employment = ({ onSave, initialValues }) => {
    const [form] = Form.useForm();
    // const [showForm, setShowForm] = useState(false);


    useEffect(() => {
        if (initialValues) {
            form.setFieldsValue(initialValues);
        }
    }, [initialValues]);

    const onFinish = (values) => {
        console.log('Received values:', values);
        onSave(values);
        message.success('Saved your Details, You can move to the next step');
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    // const handleTileClick = () => {
    //     setShowForm(true);
    // };


    const onDownloadPdfClick = async () => {
        const formData = form.getFieldsValue();

        try {
            const response = await axios.post('https://ems.finaran.com:5000/api/convert_pdf', {
                EmploymentRecords: formData.EmploymentRecords,
            }, {
                responseType: 'blob',
            });

            // Create a Blob from the PDF data
            const blob = new Blob([response.data], { type: 'application/pdf' });

            // Create a download link and trigger the download
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'output.pdf';
            link.click();
        } catch (error) {
            console.error('Error downloading PDF:', error);
        }
    };

    return (
        <div>
            <Form
                form={form}
                name="EmploymentRecords"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Row gutter={20}>
                    <Col span={18}>
                        <Card title="Employment Details" style={{ marginBottom: 16 }}>
                            <Form.List name="EmploymentRecords">

                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, value, name, fieldKey, ...restField }) => (
                                            <Row key={key} style={{ justifyContent: 'space-between' }} >
                                                <Col span={12}>
                                                    <div>
                                                        <Form.Item  {...restField}
                                                            label="Company Name"
                                                            name={[name, 'CompanyName']}
                                                            fieldKey={[fieldKey, 'CompanyName']}
                                                            wrapperCol={{ span: 12 }}
                                                            rules={[{ required: true, message: 'Please enter Company Name' }]}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item  {...restField}
                                                            label="Company Address"
                                                            name={[name, 'CompanyAddress']}
                                                            wrapperCol={{ span: 12 }}
                                                            fieldKey={[fieldKey, 'CompanyAddress']}
                                                            rules={[{ required: true, message: 'Please enter Company Address' }]}>
                                                            <Input.TextArea />
                                                        </Form.Item>

                                                        <Form.Item  {...restField}
                                                            label="Company Website"
                                                            name={[name, 'CompanyWeb']}
                                                            wrapperCol={{ span: 12 }}
                                                            fieldKey={[fieldKey, 'CompanyWeb']}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item  {...restField}
                                                            label="Company Number"
                                                            name={[name, 'CompanyPh']}
                                                            wrapperCol={{ span: 12 }}
                                                            fieldKey={[fieldKey, 'CompanyPh']}
                                                            rules={[{ required: true, message: 'Please enter Company No' }]}>
                                                            <Input placeholder="input number" style={{fontSize:"16px"}} />
                                                        </Form.Item>
                                                           
                                                                <Form.Item
                                                                    {...restField}
                                                                    label="Date of Employment"
                                                                    name={[name, 'EmpDateS']}
                                                            wrapperCol={{ span: 9 }}

                                                                    fieldKey={[fieldKey, 'EmpDateS']}
                                                            rules={[{ required: true, message: 'Please choose the date' }]}


                                                                >
                                                                    <DatePicker />
                                                                </Form.Item>
                                                                

                                                        <Form.Item
                                                            {...restField}
                                                            label="Current employment?"
                                                            name={[name, 'isCurrentEmployment']}
                                                            wrapperCol={{ span: 8 }}
                                                            fieldKey={[fieldKey, 'isCurrentEmployment']}
                                                            rules={[{ required: true, message: 'Please choose Current Employment' }]}

                                                            initialValue={initialValues ? initialValues.isCurrentEmployment : undefined}
                                                        >
                                                           
                                                                <Radio.Group onChange={(e) => form.setFieldsValue({ [`EmploymentRecords[${name}].isCurrentEmployment`]: e.target.value === 'Yes' })}>
                                                                    <Radio value="Yes">Yes</Radio>
                                                                    <Radio value="No">No</Radio>
                                                                </Radio.Group>
                                                        </Form.Item>
                                                        {form.getFieldValue(['EmploymentRecords', name, 'isCurrentEmployment']) === 'Yes' && (
                                                            <>
                                                                <Form.Item
                                                                    {...restField}
                                                                    label="When verification Initiated?"
                                                                    name={[name, 'verificationInitiationDate']}
                                                            wrapperCol={{ span: 9 }}
                                                                    fieldKey={[fieldKey, 'verificationInitiationDate']}
                                                                >
                                                                    <DatePicker />
                                                                </Form.Item>
                                                            </>
                                                        )}


                                                        <Form.Item  {...restField}
                                                            label="Agency Name&Details"
                                                            wrapperCol={{ span: 12 }}
                                                            name={[name, 'AgencyName']}
                                                            fieldKey={[fieldKey, 'AgencyName']}>
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item
                                                            {...restField}
                                                            label="Emp Type(Agency)"
                                                            name={[name, 'EmpTypeAgency']}
                                                            wrapperCol={{ span: 12 }}
                                                            fieldKey={[fieldKey, 'EmpTypeAgency']}>
                                                            <Select>
                                                                <Select.Option value="Permanent">Permanent</Select.Option>
                                                                <Select.Option value="Temporary">Temporary</Select.Option>
                                                                <Select.Option value="NA">NA</Select.Option>
                                                            </Select>
                                                        </Form.Item>
                                                        <Form.Item  {...restField}
                                                            label="Starting Base Salary"
                                                            name={[name, 'startingBaseSalary']}
                                                            wrapperCol={{ span: 12 }}
                                                            fieldKey={[fieldKey, 'startingBaseSalary']}
                                                            rules={[{ required: true, message: 'Please enter your Starting Base Salary' }]}>
                                                            <Input />
                                                        </Form.Item>

                                                        <Form.Item  {...restField}
                                                            label="Other Compensation"
                                                            name={[name, 'otherCompensation']}
                                                            wrapperCol={{ span: 12 }}
                                                            fieldKey={[fieldKey, 'otherCompensation']}>
                                                            <Input />
                                                        </Form.Item>

                                                        <Form.Item  {...restField}
                                                            label="Total"
                                                            name={[name, 'total']}
                                                            wrapperCol={{ span: 12 }}
                                                            fieldKey={[fieldKey, 'total']}>
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item  {...restField}
                                                            label="Final Base Salary"
                                                            name={[name, 'finalBaseSalary']}
                                                            wrapperCol={{ span: 12 }}
                                                            fieldKey={[fieldKey, 'finalBaseSalary']}
                                                            rules={[{ required: true, message: 'Please enter Finalized Salary' }]}>
                                                            <Input />
                                                        </Form.Item>

                                                        <Form.Item  {...restField}
                                                            label="Other Compensation"
                                                            wrapperCol={{ span: 12 }}
                                                            name={[name, 'finalOtherCompensation']}
                                                            fieldKey={[fieldKey, 'finalOtherCompensation']}>
                                                            <Input />
                                                        </Form.Item>

                                                        <Form.Item  {...restField}
                                                            label="Total"
                                                            wrapperCol={{ span: 12 }}
                                                            name={[name, 'finalTotal']}
                                                            fieldKey={[fieldKey, 'finalTotal']}>
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item  {...restField}
                                                            label="Additional Info"
                                                            wrapperCol={{ span: 12 }}
                                                            name={[name, 'otherInformation']}
                                                            fieldKey={[fieldKey, 'otherInformation']}>
                                                            <Input.TextArea />
                                                        </Form.Item>
                                                    </div>
                                                </Col>
                                                <Col span={12}>
                                                    <div>
                                                        <Form.Item  {...restField}
                                                            label="Position Held"
                                                            name={[name, 'Position']}
                                                            wrapperCol={{ span: 12 }}
                                                            fieldKey={[fieldKey, 'Position']}
                                                            rules={[{ required: true, message: 'Please enter your Position' }]}>
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item  {...restField}
                                                            label="Employee Code"
                                                            name={[name, 'EmpCode']}
                                                            wrapperCol={{ span: 12 }}
                                                            fieldKey={[fieldKey, 'EmpCode']}>
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item  {...restField}
                                                            label="SSN (if applicable):"
                                                            name={[name, 'SSN']}
                                                            wrapperCol={{ span: 12 }}
                                                            fieldKey={[fieldKey, 'SSN']}>
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item
                                                            {...restField}
                                                            label="Employement Type"
                                                            name={[name, 'EmpType']}
                                                            wrapperCol={{ span: 12 }}
                                                            fieldKey={[fieldKey, 'EmpType']}>
                                                            <Select>
                                                                <Select.Option value="Permanent">Permanent</Select.Option>
                                                                <Select.Option value="Temporary">Temporary</Select.Option>
                                                            </Select>
                                                        </Form.Item>
                                                        <Form.Item  {...restField}
                                                            label="Reason for Leaving"
                                                            name={[name, 'reasonForLeaving']}
                                                            wrapperCol={{ span: 12 }}
                                                            fieldKey={[fieldKey, 'reasonForLeaving']}
                                                        >
                                                            <Input.TextArea placeholder="Enter your reason" />
                                                        </Form.Item>
                                                        <Form.Item  {...restField}
                                                            label="Manager Name"
                                                            name={[name, 'MName']}
                                                            wrapperCol={{ span: 12 }}
                                                            fieldKey={[fieldKey, 'MName']}
                                                            rules={[{ required: true, message: 'Please enter your Manager Number' }]}>
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item  {...restField}
                                                            label="Job Title"
                                                            wrapperCol={{ span: 12 }}
                                                            name={[name, 'JobTitle']}
                                                            fieldKey={[fieldKey, 'JobTitle']}>
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item  {...restField}
                                                            label="Department"
                                                            wrapperCol={{ span: 12 }}
                                                            name={[name, 'Department']}
                                                            fieldKey={[fieldKey, 'Department']}>
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item  {...restField}
                                                            label="Manager Contact Number"
                                                            wrapperCol={{ span: 12 }}
                                                            name={[name, 'MContact']}
                                                            fieldKey={[fieldKey, 'MContact']}
                                                            rules={[{ required: true, message: 'Please enter your Manager Contact Number' }]}>
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item  {...restField}
                                                            label="Mail Address"
                                                            name={[name, 'Mail']}
                                                            wrapperCol={{ span: 12 }}
                                                            fieldKey={[fieldKey, 'Mail']}
                                                            rules={[{ required: true, message: 'Please enter your Mail' }]}>
                                                            <Input />
                                                        </Form.Item>
                                                    </div>
                                                </Col>
                                                <Col span={2}>
                                                    <Form.Item>
                                                        <Button type="primary" htmlType="submit">
                                                            Save
                                                        </Button>
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <Button onClick={onDownloadPdfClick}>Download PDF</Button>
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <Popconfirm
                                                            title="Are you sure you want to remove this Employment record?"
                                                            onConfirm={() => {
                                                                console.log('Form values:', form.getFieldsValue());
                                                                remove(name);
                                                            }}
                                                            okText="Yes"
                                                            cancelText="No"
                                                        >
                                                            <Button type="danger" icon={<MinusOutlined />}>
                                                                Remove Educational Record
                                                            </Button>
                                                        </Popconfirm>
                                                    </Form.Item>
                                                </Col>

                                            </Row>
                                        ))}
                                        <Form.Item>
                                            <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                                                Add Employment Record
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Card>
                    </Col>
                </Row >
            </Form >
        </div >

    )
}

export default Employment