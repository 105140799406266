import React, { useState, useEffect } from 'react';
import { Form, Input, Button, DatePicker, Checkbox, message, Row, Col, Card, Radio, Upload } from 'antd';
import { PlusOutlined, MinusOutlined, UploadOutlined } from '@ant-design/icons';
import axios from 'axios';

const Signature = ({ onSave, initialValues }) => {
    const [form] = Form.useForm();
    const [logoFileList, setLogoFileList] = useState([]);

    useEffect(() => {
        if (initialValues) {
            form.setFieldsValue(initialValues);
        }
    }, [initialValues]);

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onFinish = async (values) => {
        console.log("Received Values:", values);
        try {
            const signatureFile = form.getFieldValue('Signature');
    
            // Check if a signature file is present
            if (!signatureFile) {
                message.error('Please upload a signature');
                return;
            }
    
            // Convert the signature file to base64
            const reader = new FileReader();
            reader.readAsDataURL(signatureFile);
            reader.onloadend = () => {
                const signatureBase64 = reader.result.split(",")[1];
                const imageDataURI = `data:image/png;base64,${signatureBase64}`;
                onSave({ ...values, Signature: signatureBase64 });
                message.success('Data saved successfully!');
            };
        } catch (error) {
            // Handle error
            console.error('Error saving data:', error);
            message.error('Failed to save your details');
        }
    };

    // const onFinish = async (values, signatureData) => {
    //     try {
    //         // Combine form values with signature data
    //         const formData = {
    //             ...values,
    //             signature: signatureData
    //         };

    //         // Send data to the backend
    //         const response = await axios.post('http://127.0.0.1:5000/upload_logo', formData);

    //         // Handle success response
    //         console.log('Response from backend:', response.data);
    //         message.success('Data saved successfully!');
    //     } catch (error) {
    //         // Handle error
    //         console.error('Error saving data:', error);
    //         message.error('Failed to save your details');
    //     }
    // };

    const handleLogoChange = ({ fileList }) => {
        // Set the updated file list
        setLogoFileList(fileList);

        // Check if there is a file in the fileList
        if (fileList.length > 0) {
            // Update the form values with the file
            form.setFieldsValue({ Signature: fileList[0].originFileObj });
            // form.setFieldsValue({ Signature: fileList }); 
        }
    };

    return (
        <div>
            <Form
                form={form}
                name="EmploymentRecords"
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 16 }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                style={{ textAlign: 'center' }}
            >
                <Row >
                    <Col span={18}>
                        <Card title="Signature" >
                            <Form.Item
                                label='Signature'
                                name='Signature'
                                rules={[{ required: true, message: 'Please upload Signature' }]}>
                                <Upload
                                    name="Signature"
                                    listType="picture"
                                    fileList={logoFileList}
                                    onChange={handleLogoChange}
                                    beforeUpload={()=> {
                                      return false; }}
                                >
                                    <Button icon={<UploadOutlined />}>Click to upload</Button>
                                </Upload>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Save
                                </Button>
                            </Form.Item>
                        </Card>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default Signature