/*HR Home - Performance Upload->Add Review*/
import React from "react";
import { Form, Input, Select, Button } from "antd";

const { Option } = Select;

const ReviewForm = ({
  formData,
  onAddRow,
  setShowReviewForm,
  firstInputRef,
}) => {
  const statusOptions = [
    "Goals Set",
    "Performance Reviewed",
    "Comments captured",
    "Rating Done",
  ];
  const ratingOptions = [
    { label: "5– Outstanding", value: 5 },
    { label: "4– Exceeds Expectations", value: 4 },
    { label: "3- Meets Expectations", value: 3 },
    { label: "2- Needs Improvement", value: 2 },
    { label: "1- Unacceptable", value: 1 },
  ];
  const [form] = Form.useForm();
  const status = localStorage.getItem("status");
  console.log(formData,"fthgcv");

  const handleFormSubmit = () => {
    form.validateFields().then((values) => {
      onAddRow(values);
      form.resetFields();
    });
  };

  const handleCancel = () => {
    setShowReviewForm(false);
  };

  function validator(rule, value, callback) {
    if (!value) {
      callback("Please input goals!..");
    }
    const specialCharactersRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;
    // Check if the value starts with a space or contains special characters
    if (value.startsWith(" ")) {
      callback("Goals should not start with a space");
    } else if (specialCharactersRegex.test(value)) {
      callback("Goals should not contain special characters");
    } else {
      callback();
    }
  }

  return (
    <div>
      <h4>Review Form</h4>
      <Form
        form={form}
        onFinish={handleFormSubmit}
        style={{ display: "flex", flexDirection: "column" }}
        ref={firstInputRef}
      >
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1, marginRight: "20px" }}>
            <Form.Item
              name="empCode"
              label="Employee ID"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 20 }}
              rules={[
                {
                  required: true,
                  message: "Please select an employee ID",
                },
              ]}
            >
              <Select placeholder="Select an employee ID" size="large">
                {formData.employees.map((employee) => (
                  <Option key={employee.empCode} value={employee.empCode}>
                    {`${employee.empCode} - ${employee.firstName} ${employee.lastName}`}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="year"
              label="Year"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 20 }}
              rules={[
                {
                  required: true,
                  message: "Please select a year",
                },
              ]}
            >
              <Select placeholder="Select a year" size="large">
                {formData.years.map((year) => (
                  <Option key={year} value={year.toString()}>
                    {year}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="periods"
              label="Period"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 20 }}
              rules={[
                {
                  required: true,
                  message: "Please select an Period",
                },
              ]}
            >
              <Select placeholder="Select an Period" size="large">
                <Option value="Q1">Q1</Option>
                <Option value="Q2">Q2</Option>
                <Option value="Q3">Q3</Option>
                <Option value="Q4">Q4</Option>
                <Option value="H1">H1</Option>
                <Option value="H2">H2</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="goals"
              label="Goals"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 20 }}
              rules={[
                {
                  required: true,
                  message: "",
                },
                {
                  validator: validator, // Use the custom validator function
                },
              ]}
            >
              <Input.TextArea placeholder="Enter goals" />
            </Form.Item>
            <Form.Item
              name="performanceReview"
              label="Performance Review"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 20 }}
            >
              <Input.TextArea placeholder="Enter performance review" />
            </Form.Item>
          </div>
          <div style={{ flex: 1, marginLeft: "20px" }}>
            <Form.Item
              name="trainingNeeds"
              label="Training Needs"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 20 }}
            >
              <Input.TextArea placeholder="Enter training needs" />
            </Form.Item>
            <Form.Item
              name="employeeComments"
              label="Employee Comments"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 20 }}
            >
              <Input.TextArea placeholder="Enter employee comments" />
            </Form.Item>
            <Form.Item
              name="status"
              label="Status"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 20 }}
            >
              <Select placeholder="Select a status" size="large">
                {statusOptions.map((option) => (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="rating"
              label="Rating"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 20 }}
            >
              <Select placeholder="Select a rating" size="large">
                {ratingOptions.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <Form.Item style={{ flex: 1 }}>
              <Button danger onClick={handleCancel}>
                Cancel
              </Button>
            </Form.Item>
          </div>
          <div>
            <Form.Item style={{ flex: 1 }}>
              <Button
                type="primary"
                htmlType="submit"
                disabled={status === "disable"}
              >
                Submit
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default ReviewForm;
