import React, { useState, useEffect } from "react";
import {
  Layout,
  Modal,
  Input,
  Card,
  Row,
  Col,
  Pagination,
  Avatar,
  Spin,
  Button,
  Tooltip,
} from "antd";
import { url } from "../../url/url";
import axios from "axios";
import "./hrreport.css";
import { jsPDF } from "jspdf";
import { decryptRole } from "../../encrypt";
import moment from "moment";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  Image,
  pdf,
} from "@react-pdf/renderer";
const { Content } = Layout;

const HRReport = () => {
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState();
  const [headerImg, setHeaderImg] = useState();
  const [footerImg, setFooterImg] = useState();
  const [data, setData] = useState({
    companyaddress: "",
    phoneNumber: "",
    companyemail: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 12;
  const [searchQuery, setSearchQuery] = useState("");
  const colorData = localStorage.getItem("color");
  const currentDate = new Date();
  const formattedDate = `${currentDate.getDate()}-${currentDate.getMonth() +
    1}-${currentDate.getFullYear()}`;

  const [formData, setFormData] = useState({
    band: "",
    dateOfResignation: "",
    dateOfReleaving: "",
    reason: "",
    conduct: "",
    signature: null,
  });
  const users = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    fetchEmployeeData();
    fetchData();
    Logo();
  }, []);

  const handleOk = () => {
    setOpen(true);
  };

  const fetchData = async () => {
    try {
      const dbName = localStorage.getItem("dbName");
      const response = await fetch(`${url}/api/masteruser/${dbName}`);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      setData(result);
      console.log(result, "result.companyaddress");
    } catch (error) {
      console.error("Failed to get");
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };
  // Handle image loading errors and display a placeholder image
  const handleImageError = (event) => {
    // event.target.src = '/path-to-placeholder-image.jpg'; // Replace with your placeholder image URL
  };
  const fetchEmployeeData = async () => {
    try {
      setLoading(true);
      const dbName = localStorage.getItem("dbName");
      const encryptedRoleFromStorage = localStorage.getItem("role");
      const jobTitle = decryptRole(encryptedRoleFromStorage);

      let response;
      if (jobTitle === "admin") {
        response = await axios.get(`${url}/api/get/allemplist/${dbName}`);
      } else if (jobTitle === "hr") {
        response = await axios.get(
          `${url}/api/get/emplist/${users.displayName}/hr/${dbName}`
        );
      }

      if (response.status === 200) {
        console.log("Employee list fetched:", response.data);
        setEmployees(response.data);
      } else {
        console.error("Error:", response.status);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const handleTileClick = (employee) => {
    setSelectedEmployee(employee);
  };

  const Logo = async () => {
    try {
      const dbName = localStorage.getItem("dbName");
      // const response = await axios.get(`${url}/admin/logo/${dbName}`);
      const response = await axios.get(`${url}/get/combined/${dbName}`);
      setHeaderImg(response.data[0].letterheader);
      setFooterImg(response.data[0].letterfooter);
      setImage(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({
      ...prevData,
      signature: URL.createObjectURL(file),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Generate the PDF document
    const pdfBlob = await ExperienceLetterDocument();

    // Create a temporary link to trigger the download
    const blobUrl = URL.createObjectURL(pdfBlob);
    const link = document.createElement("a");
    link.href = blobUrl;
    link.download = `${selectedEmployee.displayName}_Experience_Letter.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Close the modal
    setOpen(false);
    setFormData("");
  };

  const generateAddressProofLetter = () => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.width;
    const x = 20;
    let y = 30;
    const pageHeight = doc.internal.pageSize.height;
    const headerHeight = 35; // Adjust the height as needed
    const headerY = 0; // Position at the top of the page
    const headerX = 0; // Start from the left side of the page
    const headerWidth = pageWidth; // Set width to full page width
    doc.addImage(headerImg, "PNG", headerX, headerY, headerWidth, headerHeight);
    y += 25;
    // Title
    doc.setFont("helvetica", "bold");
    doc.setFontSize(16);
    const title = "Address Proof Letter";
    const textWidth =
      (doc.getStringUnitWidth(title) * doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    const centerX = (pageWidth - textWidth) / 2;

    // Add logo and title
    // doc.addImage(logo, 'PNG', pageWidth - 50, 10, logoWidth, logoHeight);
    doc.text(title, centerX, 45);

    // Date
    doc.setFontSize(12);
    const currentDate = new Date().toDateString();
    doc.setFont("helvetica", "normal");
    doc.text(`Date: ${currentDate}`, centerX + 80, y);
    y += 10;

    // Employee Details
    doc.text(
      `${selectedEmployee.firstName} ${selectedEmployee.lastName}`,
      x,
      y
    );
    y += 8;
    doc.text(`${selectedEmployee.address}`, x, y);
    y += 8;
    doc.text(`${selectedEmployee.email}`, x, y);
    y += 8;
    doc.text(`${selectedEmployee.phoneNumber}`, x, y);
    y += 15;

    // Salutation
    doc.setFont("helvetica", "bold");
    const salutation = "To Whomsoever it may concern";
    doc.text(salutation, centerX, y);
    y += 15;

    // Body
    doc.setFont("helvetica", "normal");
    const bodyText = `          This is to confirm that ${selectedEmployee.firstName} ${selectedEmployee.lastName} is working in ${data.org.orgName} as ${selectedEmployee.empDesignation}.As per the company's record, his/her services were effective since ${selectedEmployee.joinDate}. Also would want to confirm the current address of ${selectedEmployee.firstName} ${selectedEmployee.lastName} as "${selectedEmployee.address}" as per the company's Employee Records.`;
    const bodyTextLines = doc.splitTextToSize(bodyText, pageWidth - 2 * x);
    for (const line of bodyTextLines) {
      doc.text(line, x, y);
      y += 10;
    }
    y += 10;
    const endStatement = "*** END OF THE DOCUMENT ***";
    const disclaimer = "*** This is a system-generated document ***";
    doc.setFont("helvetica", "bold");
    doc.setFontSize(12);
    doc.text(endStatement, centerX - 8, y); // Adjust vertical position as needed
    doc.setFont("helvetica", "bold");
    doc.setFontSize(12);
    doc.text(disclaimer, centerX - 18, y + 10);
    y += 25;
    const sincerely = "Yours Sincerely,";
    doc.setFont("helvetica", "normal");
    doc.text(sincerely, x, y);
    y += 10;
    const finan = data.org.orgName;
    doc.setFont("helvetica", "normal");
    doc.text(finan, x, y);
    y += 30;
    const imgWidth = 30; // Adjust the width of the image
    const imgHeight = 20; // Adjust the height of the image
    const imgY = pageHeight - 80; // y coordinate for the image (before the footer)
    // Add image to the PDF
    const imgData = require("../../images/center.png");
    doc.addImage(imgData, "PNG", x, imgY, imgWidth, imgHeight);
    const imgY1 = pageHeight - 100;
    const imgWidth1 = 30; // Adjust the width of the image
    const imgHeight1 = 30; // Adjust the height of the image
    const imgData1 = require("../../images/center.png");
    doc.addImage(imgData1, "PNG", x + 40, imgY1, imgWidth1, imgHeight1);
    y += 10;
    const name = "David a";
    doc.setFont("helvetica", "normal");
    doc.text(name, x, y);
    y += 10;
    const designation = "Manager  HR & Operations";
    doc.setFont("helvetica", "normal");
    doc.text(designation, x, y);
    const footerHeight = 25; // Adjust the height as needed
    const footerY = pageHeight - footerHeight; // Adjust the vertical position of the footer
    const footerX = 0; // Start from the left side of the page
    const footerWidth = pageWidth; // Set width to full page width
    // Add the footer image to cover the full page width at the bottom
    doc.addImage(footerImg, "PNG", footerX, footerY, footerWidth, footerHeight);
   
    // Save the PDF
    doc.save(`AddressProof_${selectedEmployee.empCode}.pdf`);
  };

  const ExperienceLetterDocument = async () => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.width;
    const x = 20;
    let y = 30;
    const pageHeight = doc.internal.pageSize.height;

    // Calculate Experience
    const joiningDate = moment(selectedEmployee.joinDate);
    const relievingDate = moment(formData.dateOfReleaving);
    const experienceDuration = moment.duration(relievingDate.diff(joiningDate));

    const years = experienceDuration.years();
    const months = experienceDuration.months();
    const days = experienceDuration.days();

    const experience = `${years} years, ${months} months, ${days} days`;

    // Header
    const headerHeight = 35;
    doc.addImage(headerImg, "PNG", 0, 0, pageWidth, headerHeight);
    y += 12;

    // Date
    doc.setFont("Times New Roman", "normal");
    doc.setFontSize(12);
    const currentDate = new Date().toDateString();
    doc.text(`Date: ${currentDate}`, pageWidth - 60, y);
    y += 10;

    // Salutation
    doc.text("To", x, y);
    y += 10;
    doc.text(`${selectedEmployee.displayName}`, x, y);
    y += 10;

    // Subject
    const subject = "Sub : Acceptance of Resignation & Experience Certificate";
    doc.text(subject, x, y);
    y += 10;

    // Reference
    doc.text(`Ref: Emp. Code: ${selectedEmployee.empCode}`, x, y);
    y += 10;

    // Body
    const bodyText = `We would refer your resignation letter dated on ${formData.dateOfReleaving} and accordingly, your resignation is being accepted and you are relieved from the service as at close ${selectedEmployee.dateOfReleaving}.`;
    const bodyTextLines = doc.splitTextToSize(bodyText, pageWidth - 2 * x);
    for (const line of bodyTextLines) {
      doc.text(line, x, y);
      y += 8;
    }
    y += 5;
    const bodyText1 = `Your contributions to the organization and its success will always be appreciated.`;
    doc.text(bodyText1, x, y);
    y += 12;
    const bodyText2 = `We at company wish you all the best in your future endeavors.`;
    doc.text(bodyText2, x, y);
    y += 5;

    // Employee Details
    y += 5;
    const details = [
      { label: "DATE OF JOINING", value: selectedEmployee.joinDate },
      { label: "DESIGNATION", value: selectedEmployee.empDesignation },
      { label: "DEPARTMENT", value: selectedEmployee.department },
      { label: "BAND", value: formData.band },
      { label: "DATE OF RESIGNATION", value: formData.dateOfResignation },
      { label: "DATE OF RELEAVING", value: formData.dateOfReleaving },
      { label: "EXPERIENCE", value: experience }, // Use calculated experience here
      { label: "REASON", value: formData.reason },
      { label: "CONDUCT", value: formData.conduct },
    ];

    details.forEach((detail) => {
      const labelX = x;
      const separatorX = x + 60;
      const valueX = separatorX + 10;

      doc.setFont("Arial", "normal");
      doc.setFontSize(12);

      // Render label
      doc.text(detail.label, labelX, y);

      // Render separator
      doc.text(":", separatorX, y);

      // Render value
      doc.text(detail.value, valueX, y);

      y += 6;
    });

    // Signature
    doc.setFont("Times New Roman", "normal");
    doc.setFontSize(12);
    y += 10;
    doc.text("Yours Sincerely,", x, y);
    y += 10;
    doc.setFont("Times New Roman", "bold");
    doc.setFontSize(12);
    doc.text(`For ${data.org.orgName}`, x, y);
    y += 30;
    const imgWidth = 50;
    const imgHeight = 15;
    const imgY = pageHeight - 80;
    const imgData = formData.signature;
    doc.addImage(imgData, "PNG", x, imgY, imgWidth, imgHeight);
    const imgY1 = pageHeight - 80;
    const imgWidth1 = 20;
    const imgHeight1 = 20;
    const imgData1 = require("../../images/center.png");
    doc.addImage(imgData1, "PNG", x + 60, imgY1, imgWidth1, imgHeight1);
    const name = "Mahadevan AC";
    doc.setFont("Times New Roman", "bold");
    doc.text(name, x, y);
    y += 8;
    const designation = "Manager – HR & Operations";
    doc.setFont("Times New Roman", "bold");
    doc.text(designation, x, y);

    // Footer
    const footerHeight = 25;
    doc.addImage(
      footerImg,
      "PNG",
      0,
      pageHeight - footerHeight,
      pageWidth,
      footerHeight
    );

    // Convert the PDF document to a blob and return it
    return doc.output("blob");
  };

  const visibleEmployees = employees.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const filterEmployeesByFirstName = (visibleEmployees, searchQuery) => {
    return visibleEmployees.filter((employee) => {
      return employee.firstName
        .toLowerCase()
        .includes(searchQuery.toLowerCase());
    });
  };

  const EmployeeCard = ({ employee }) => {
    return (
      <Card
        title={`${employee.firstName} ${employee.lastName}`}
        onClick={() => handleTileClick(employee)}
        className="employee-card"
        headStyle={{
          background: colorData,
          color: "white",
          textAlign: "center",
          display: "flex",
        }}
        bordered={false}
        style={{ height: "270px", overflow: "hidden", zoom: "90%" }}
      >
        <div className="employee-image-container">
          <Avatar
            size={64}
            src={employee.profileImage}
            alt={`${employee.firstName}'s Profile`}
            onError={handleImageError}
          />
        </div>
        <p className="job-title">{employee.empDesignation}</p>
        {/* Add more employee details here... */}
      </Card>
    );
  };

  return (
    <>
      {loading ? (
        // Render a loading indicator while data is being fetched
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <div>
          {/* <div style={{ display: "flex", justifyContent: "flex-end", marginottom: "10px" }}>
                <Input
                    type="text"
                    size="small"
                    style={{ height: "40px", fontSize: "15px" }}
                    placeholder="Search Employees..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
            </div> */}
          <Content>
            <h1 style={{ textAlign: "center" }}>Letters</h1>

            {selectedEmployee && (
              <Modal
                title="Employee Details"
                visible={selectedEmployee !== null}
                onOk={() => setSelectedEmployee(null)}
                onCancel={() => setSelectedEmployee(null)}
                cancelButtonProps={{ style: { display: "none" } }}
                width={600}
                centered
                bodyStyle={{ padding: "20px" }}
                footer={null}
              >
                {/* Render employee details in the modal */}
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ marginRight: "20px" }}>
                    {/* Display the profile image */}
                    {selectedEmployee && selectedEmployee.profileImage ? (
                      <img
                        src={selectedEmployee.profileImage}
                        alt={`${selectedEmployee.firstName}'s Profile`}
                        style={{
                          width: "100px",
                          height: "100px",
                          borderRadius: "50%",
                        }}
                      />
                    ) : (
                      <div
                        style={{
                          width: "100px",
                          height: "100px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#fde3cf",
                          color: "#f56a00",
                          borderRadius: "50%",
                          fontSize: "24px",
                        }}
                      >
                        No Image
                      </div>
                    )}
                  </div>
                  <div>
                    <p>Employee ID: {selectedEmployee.empCode}</p>
                    <p>
                      Name:{" "}
                      {`${selectedEmployee.firstName} ${selectedEmployee.lastName}`}
                    </p>
                    <p>Manager: {selectedEmployee.manager}</p>
                    <p>Job Title: {selectedEmployee.empDesignation}</p>
                    <p>Phone Number: {selectedEmployee.phoneNumber}</p>
                    <p>Gender: {selectedEmployee.gender}</p>
                    <p>Email: {selectedEmployee.email}</p>
                    <p>Date of Joining: {selectedEmployee.joinDate}</p>
                    <p>Address: {selectedEmployee.address}</p>
                    <p>Date of Birth: {selectedEmployee.dob}</p>
                    {/* Add more details as needed */}
                  </div>
                  <Modal
                    width={400}
                    visible={open}
                    footer={null}
                    onCancel={() => {
                      setOpen(false);
                      setFormData("");
                    }}
                  >
                    <form onSubmit={handleSubmit}>
                      <div>
                        <label style={{ fontSize: "15px" }}>Band:</label>
                        <input
                          style={{ width: "40vh", height: "8vh" }}
                          type="text"
                          name="band"
                          value={formData.band}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div>
                        <label style={{ fontSize: "15px" }}>
                          Date of Resignation:
                        </label>
                        <input
                          style={{ width: "40vh", height: "8vh" }}
                          type="date"
                          name="dateOfResignation"
                          value={formData.dateOfResignation}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <br />
                      <div>
                        <label style={{ fontSize: "15px" }}>
                          Date of Releaving:
                        </label>
                        <input
                          style={{ width: "40vh", height: "8vh" }}
                          type="date"
                          name="dateOfReleaving"
                          value={formData.dateOfReleaving}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <br />
                      <div>
                        <label style={{ fontSize: "15px" }}>Reason:</label>
                        <input
                          style={{ width: "40vh", height: "8vh" }}
                          type="text"
                          name="reason"
                          value={formData.reason}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <br />
                      <div>
                        <label style={{ fontSize: "15px" }}>Conduct:</label>
                        <input
                          style={{ width: "40vh", height: "8vh" }}
                          type="text"
                          name="conduct"
                          value={formData.conduct}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <br />
                      <div>
                        <label style={{ fontSize: "15px" }}>Signature:</label>
                        <input
                          type="file"
                          name="signature"
                          onChange={handleFileChange}
                          accept="image/*"
                          required
                        />
                      </div>
                      <br />
                      <Tooltip title="Click Ok to Download PDF">
                        <Button
                          style={{ left: "50vh" }}
                          htmlType="submit"
                          type="primary"
                        >
                          Ok
                        </Button>
                      </Tooltip>
                    </form>
                  </Modal>
                </div>
                <div
                  style={{
                    marginTop: "20px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Button type="primary" onClick={generateAddressProofLetter}>
                    Address Proof Letter
                  </Button>
                  <Button type="primary" onClick={handleOk}>
                    Experience Letter
                  </Button>
                </div>
              </Modal>
            )}

            <div className="Employee-grid">
              <Row gutter={[16, 16]}>
                {filterEmployeesByFirstName(visibleEmployees, searchQuery).map(
                  (employee) => (
                    <Col xs={24} sm={12} md={8} lg={6} key={employee.id}>
                      <EmployeeCard key={employee.id} employee={employee} />
                    </Col>
                  )
                )}
              </Row>
            </div>
            <div className="pagination-container">
              <Pagination
                current={currentPage}
                total={employees.length}
                pageSize={pageSize}
                onChange={handlePageChange}
              />
            </div>
          </Content>
        </div>
      )}
    </>
  );
};

export default HRReport;
