import { Navigate, Outlet } from "react-router-dom";

const LoginRoute = () => {

  const Route = false
  return (
    Route ?
      <Outlet /> : <Navigate to="/home" />
  )
}

export default LoginRoute;