import React, { useState, useEffect } from "react";
import { Table, Select, Button, Spin, Tabs, message } from "antd";
import axios from "axios";
import FileSaver from "file-saver";
import Papa from "papaparse";
import { url } from "../../url/url";
const { Option } = Select;
const { TabPane } = Tabs;

const Standardcost = () => {
  const currentYear = new Date().getFullYear();
  const lastYear = currentYear - 1;
  const currentMonth = new Date().getMonth() + 1;
  const users = JSON.parse(localStorage.getItem("user"));
  const [user] = useState(users);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [showAllReports, setShowAllReports] = useState(false);
  const [extractReport, setExtractReport] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [currentTab, setCurrentTab] = useState("weekly");
  const [loading, setLoading] = useState(false);
  const [Columns, setColumns] = useState([]);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [total, setTotal] = useState(0);

  const onPageChange = (newCurrent, newPageSize) => {
    setCurrent(newCurrent);
    setPageSize(newPageSize);
  };

  useEffect(() => {
    fetchTable();
  }, [selectedMonth, selectedYear, currentTab, showAllReports]);

  const handleYearChange = (value) => {
    setSelectedYear(parseInt(value));
    setExtractReport(false);
  };

  const handleMonthChange = (value) => {
    setTableData([]);
    setSelectedMonth(parseInt(value));
    setExtractReport(false);
  };

  const handleExtractReport = () => {
    setExtractReport(true);
  };

  const fetchTable = async () => {
    try {
      setLoading(true);
      const dbName = localStorage.getItem("dbName");
      let urlPath = `${url}/timesheet/filteredmonth/${user.displayName}/manager/${dbName}/${selectedMonth}/${selectedYear}`;

      if (showAllReports && user.jobTitle === "hr") {
        urlPath = `${url}/timesheet/filteredmonth/${user.displayName}/hr/${dbName}/${selectedMonth}/${selectedYear}`;
      } else if (showAllReports && user.jobTitle === "admin") {
        urlPath = `${url}/timesheet/filteredmonth/${user.displayName}/admin/${dbName}/${selectedMonth}/${selectedYear}`;
      }

      const response = await fetch(urlPath);
      const table = await response.json();
      let dataWithCosts;

      if (currentTab === "daily") {
        const changeData = filterDataToChange(table);
        dataWithCosts = await Promise.all(
          changeData.map(async (record) => {
            const standardCost = await calculateStandardCost(record);
            return { ...record, standardCost };
          })
        );
      } else if (currentTab === "weekly") {
        const aggregatedData = aggregateDataToEmployeeLevel(table);
        dataWithCosts = await Promise.all(
          aggregatedData.map(async (record) => {
            const standardCost = await calculateStandardCost(record);
            return { ...record, standardCost };
          })
        );
      } else if (currentTab === "aggregated") {
        const aggregatedDataWithoutProject = aggregateDataWithoutProject(table);
        dataWithCosts = await Promise.all(
          aggregatedDataWithoutProject.map(async (record) => {
            const standardCost = await calculateStandardCost(record);
            return { ...record, standardCost };
          })
        );
      }

      setTableData(dataWithCosts);
      setTotal(dataWithCosts.length);
      setLoading(false);
    } catch (error) {
      console.error(error);
      message.error("Failed to fetch data from the server.");
    }
  };

  const aggregateDataToEmployeeLevel = (dataEntry) => {
    const result = dataEntry.reduce((acc, entry) => {
      const { empCode, userName, weekTotal, ratePerHour, status } = entry;

      // Only include entries where the status is "Approved"
      if (status === "Approved") {
        let empEntry = acc.find((item) => item.empCode === empCode);

        if (!empEntry) {
          empEntry = { empCode, userName, ratePerHour, weekTotals: [] };
          acc.push(empEntry);
        }

        empEntry.weekTotals.push(weekTotal);
      }

      return acc;
    }, []);

    return result;
  };

  const filterDataToChange = (dataEntry) => {
    const result = dataEntry.reduce((acc, entry) => {
      const { empCode, userName, entrydata, weekTotal, status } = entry;

      // Only include entries where the status is "Approved"
      if (status === "Approved") {
        let empEntry = acc.find((item) => item.empCode === empCode);

        if (!empEntry) {
          empEntry = { empCode, userName, projectdata: [] };
          acc.push(empEntry);
        }

        entrydata.forEach((projectMap) => {
          const { project, task } = projectMap;

          // Add a condition to exclude tasks like 'holiday', 'leave', 'permission'
          if (task !== "Holiday" && task !== "Leave" && task !== "Permission") {
            let projectEntry = empEntry.projectdata.find(
              (proj) => proj.project === project
            );

            if (!projectEntry) {
              projectEntry = {
                empCode,
                userName,
                project,
                task: "",
                weekTotal: weekTotal,
              };
              empEntry.projectdata.push(projectEntry);
            }

            Object.keys(projectMap)
              .filter((key) => key.startsWith("day"))
              .forEach((dayKey) => {
                const date = projectMap[dayKey].date;
                const hours = projectMap[dayKey].hours;
                projectEntry[date] = (projectEntry[date] || 0) + hours;
              });
          }
        });
      }

      return acc;
    }, []);

    return result.flatMap((item) => item.projectdata);
  };

  const aggregateDataWithoutProject = (dataEntry) => {
    return dataEntry.reduce((acc, entry) => {
      const { empCode, userName, ratePerHour, entrydata } = entry;
      let empEntry = acc.find((item) => item.empCode === empCode);

      if (!empEntry) {
        empEntry = { empCode, userName, ratePerHour, dayTotals: {} };
        acc.push(empEntry);
      }

      entrydata.forEach((projectMap) => {
        Object.keys(projectMap)
          .filter((key) => key.startsWith("day"))
          .forEach((dayKey) => {
            const date = projectMap[dayKey].date;
            const hours = projectMap[dayKey].hours;
            empEntry.dayTotals[date] = (empEntry.dayTotals[date] || 0) + hours;
          });
      });

      return acc;
    }, []);
  };

  const toggleShowAllReports = () => {
    setShowAllReports(!showAllReports);
  };

  const handleTabChange = (key) => {
    setCurrentTab(key);
    setTableData([]);
  };

  const generateColumns = () => {
    const uniqueEmpCodes = Array.from(
      new Set(tableData.map((record) => record.empCode))
    );
    const uniqueEmpNames = Array.from(
      new Set(tableData.map((record) => record.userName))
    );
    const startDay = 1;
    const endDay = new Date(selectedYear, selectedMonth, 0).getDate();

    const uniquePrj = Array.from(
      new Set(tableData.map((record) => record.project))
    );

    const columns = [
      {
        title: "Employee Name",
        dataIndex: "userName",
        key: "userName",
        align: "center",
        width: 200,
        fixed: "left",
        filters: uniqueEmpNames.map((userName) => ({
          text: userName,
          value: userName,
        })),
        onFilter: (value, record) => record.userName === value,
        sorter: (a, b) => a.userName.localeCompare(b.userName),
      },
      {
        title: "Employee ID",
        dataIndex: "empCode",
        key: "empCode",
        width: 200,
        align: "center",
        filters: uniqueEmpCodes.map((empCode) => ({
          text: empCode,
          value: empCode,
        })),
        onFilter: (value, record) => record.empCode === value,
      },
      {
        title: "Cost Per Hour",
        key: "ratePerHour",
        dataIndex: "ratePerHour",
        width: 200,
        align: "center",
        render: (value) => (
          <p style={{ textAlign: "center", margin: 0 }}>
            {value ? value.toFixed(2) : "0.00"}
          </p>
        ),
      },
    ];
    if (currentTab === "weekly") {
      const weeksInMonth = getWeeksInMonth(selectedYear, selectedMonth);
      weeksInMonth.forEach((week, index) => {
        columns.push({
          title: `Week ${index + 1}`,
          dataIndex: `week${index + 1}`,
          key: `week${index + 1}`,
          width: 200,
          align: "center",
          render: (value, record) => {
            const weekTotal = parseFloat(record.weekTotals[index]);
            const hourlyRate = record.ratePerHour;
            const weeklyCost = isNaN(weekTotal) ? 0 : weekTotal * hourlyRate;
            console.log(weekTotal, hourlyRate, weeklyCost, "cost");
            return (
              <p style={{ textAlign: "center", margin: 0 }}>
                {weeklyCost.toFixed(2)}
              </p>
            );
          },
        });
      });

      columns.push({
        title: "Cost",
        key: "total",
        width: 200,
        align: "center",
        render: (text, record) => {
          const totalCost = record.weekTotals.reduce((acc, curr, index) => {
            const weekTotal = parseFloat(curr);
            const weeklyCost = isNaN(weekTotal)
              ? 0
              : weekTotal * record.ratePerHour;
            return acc + weeklyCost;
          }, 0);
          return totalCost.toFixed(2);
        },
      });
    } else if (currentTab === "daily") {
      columns.push({
        title: "Project",
        dataIndex: "project",
        key: "project",
        width: 200,
        align: "center",
        filters: uniquePrj.map((project) => ({
          text: project,
          value: project,
        })),
        onFilter: (value, record) => record.project === value,
      });

      for (let day = startDay; day <= endDay; day++) {
        const date = `${day
          .toString()
          .padStart(2, "0")}-${selectedMonth
          .toString()
          .padStart(2, "0")}-${selectedYear}`;

        columns.push({
          title: date,
          dataIndex: date,
          key: date,
          width: 200,
          align: "center",
          render: (value, record) => {
            const hourlyRate = record.ratePerHour;
            const dailyHours = value || 0;
            const dailyCost = dailyHours * hourlyRate;
            return (
              <p style={{ textAlign: "center", margin: 0 }}>
                {dailyCost.toFixed(2)}
              </p>
            );
          },
        });
      }
      columns.push({
        title: "Total Hours",
        key: "total",
        width: 200,
        align: "center",
        render: (text, record) => calculateTotalHours(record),
      });

      columns.push({
        title: "Standard Cost",
        key: "standardCost",
        dataIndex: "standardCost",
        width: 200,
        align: "center",
        render: (text) => text || "0.00",
      });
    } else if (currentTab === "aggregated") {
      const startDay = 1;
      const endDay = new Date(selectedYear, selectedMonth, 0).getDate();

      for (let day = startDay; day <= endDay; day++) {
        const date = `${day
          .toString()
          .padStart(2, "0")}-${selectedMonth
          .toString()
          .padStart(2, "0")}-${selectedYear}`;

        columns.push({
          title: date,
          dataIndex: date,
          key: date,
          width: 200,
          align: "center",
          render: (value, record) => {
            const hourlyRate = record.ratePerHour;
            const dailyHours = record.dayTotals[date] || 0;
            const dailyCost = dailyHours * hourlyRate;
            return (
              <p style={{ textAlign: "center", margin: 0 }}>
                {dailyCost.toFixed(2)}
              </p>
            );
          },
        });
      }
      columns.push({
        title: "Total Cost",
        key: "totalCost",
        width: 200,
        align: "center",
        render: (value, record) => {
          let totalCost = 0;
          for (let day = startDay; day <= endDay; day++) {
            const date = `${day
              .toString()
              .padStart(2, "0")}-${selectedMonth
              .toString()
              .padStart(2, "0")}-${selectedYear}`;
            const dailyHours = record.dayTotals[date] || 0;
            totalCost += dailyHours * record.ratePerHour;
          }
          return (
            <p style={{ textAlign: "center", margin: 0 }}>
              {totalCost.toFixed(2)}
            </p>
          );
        },
      });
    }

    return columns;
  };

  const calculateTotalHours = (record) => {
    const keysToExclude = ["empCode", "project", "task"];
    console.log(record, "record");
    const sum = Object.entries(record)
      .filter(([key]) => !keysToExclude.includes(key))
      .filter(([key]) => {
        const [day, month, year] = key.split("-").map(Number);
        console.log(month, year, "year");

        return month === selectedMonth && year === selectedYear;
      })
      .reduce((acc, [, value]) => acc + value, 0);
    console.log(sum, "sum");

    return sum;
  };
  const getWeeksInMonth = (year, month) => {
    const weeks = [];
    const firstDay = new Date(year, month - 1, 1);
    const lastDay = new Date(year, month, 0);
    const numDays = lastDay.getDate();

    let currentDay = firstDay;
    let currentWeek = [];

    for (let i = 0; i < numDays; i++) {
      currentWeek.push(currentDay);
      currentDay = new Date(currentDay);
      currentDay.setDate(currentDay.getDate() + 1);

      if (currentDay.getDay() === 0 || i === numDays - 1) {
        weeks.push(currentWeek);
        currentWeek = [];
      }
    }

    return weeks;
  };

  const calculateStandardCost = async (record) => {
    try {
      const dbName = localStorage.getItem("dbName");
      const response = await axios.get(
        `${url}/rate/${record.empCode} - ${record.userName}/${dbName}`
      );
      const hourlyRate = response.data.ratePerHour;
      record.ratePerHour = hourlyRate;

      if (currentTab === "weekly") {
        const totalHours = record.weekTotals.reduce(
          (acc, curr) => acc + curr,
          0
        );
        const standardCost = totalHours * hourlyRate;
        return standardCost.toFixed(2);
      } else {
        const totalHours = parseFloat(calculateTotalHours(record));
        const standardCost = totalHours * hourlyRate;
        console.log(standardCost, "cost");
        record.ratePerHour = hourlyRate;
        return standardCost.toFixed(2);
      }
    } catch (error) {
      console.error("Error calculating standard cost:", error);
      return "0.00";
    }
  };

  const generateYearMonthOptions = () => {
    const data = Array.from({ length: 12 }, (_, i) => {
      const month = (i + 1).toString().padStart(2, "0");
      const date = new Date(currentYear, i, 1);
      const monthYearString = date.toLocaleDateString("en-US", {
        month: "short",
      });
      return (
        <Option key={month} value={month}>
          {monthYearString}
        </Option>
      );
    });
    return data;
  };

  const exportCSV = () => {
    const csvData = tableData.map((record) => {
      const csvRecord = {
        "Employee Name": record.userName,
        "Employee ID": record.empCode,
        ...record.weekTotals.reduce((acc, weekTotal, index) => {
          acc[`Week ${index + 1}`] = weekTotal;
          return acc;
        }, {}),
        Total: record.weekTotals.reduce(
          (acc, curr) => acc + (isNaN(curr) ? 0 : curr),
          0
        ),
        "Standard Cost": record.standardCost,
      };
      return csvRecord;
    });

    const csvString = Papa.unparse(csvData);
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    FileSaver.saveAs(blob, "StandardcostReport.csv");
  };

  return (
    <div>
      <div style={{ marginBottom: 16 }}>
        <span style={{ marginRight: 8 }}>Select Year:</span>
        <Select
          defaultValue={currentYear}
          value={selectedYear}
          style={{ width: 120, marginRight: 16 }}
          onChange={handleYearChange}
        >
          <Option value={lastYear}>{lastYear}</Option>
          <Option value={currentYear}>{currentYear}</Option>
        </Select>
        <span style={{ marginRight: 8 }}>Select Month:</span>
        <Select
          defaultValue={currentMonth}
          value={selectedMonth}
          style={{ width: 120 }}
          onChange={handleMonthChange}
        >
          {generateYearMonthOptions()}
        </Select>

        <Button style={{ marginLeft: 16 }} onClick={toggleShowAllReports}>
          {showAllReports ? "Show My Reports" : "Show All Reports"}
        </Button>
        <Button style={{ marginLeft: 16 }} type="primary" onClick={exportCSV}>
          Export CSV
        </Button>
      </div>
      <Tabs
        defaultActiveKey="weekly"
        onChange={handleTabChange}
        style={{ marginTop: 20 }}
      >
        <TabPane tab="Weekly Report" key="weekly">
          {loading ? (
            <Spin />
          ) : (
            <Table
              columns={generateColumns()}
              dataSource={tableData}
              pagination={{
                current: current,
                pageSize: pageSize,
                total: total,
                onChange: (page, size) => {
                  setCurrent(page);
                  setPageSize(size);
                },
              }}
              scroll={{ x: "max-content" }}
              rowKey="empCode"
            />
          )}
        </TabPane>
        <TabPane tab="Daily Report" key="daily">
          {loading ? (
            <Spin />
          ) : (
            <Table
              columns={generateColumns()}
              dataSource={tableData}
              pagination={{
                current: current,
                pageSize: pageSize,
                total: total,
                onChange: (page, size) => {
                  setCurrent(page);
                  setPageSize(size);
                },
              }}
              scroll={{ x: "max-content" }}
              rowKey="empCode"
            />
          )}
        </TabPane>
        <TabPane tab="Daywise Report (Aggregated)" key="aggregated">
          {loading ? (
            <Spin />
          ) : (
            <Table
              columns={generateColumns()}
              dataSource={tableData}
              pagination={{
                current: current,
                pageSize: pageSize,
                total: total,
                onChange: (page, size) => {
                  setCurrent(page);
                  setPageSize(size);
                },
              }}
              scroll={{ x: "max-content" }}
              rowKey="empCode"
            />
          )}
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Standardcost;
