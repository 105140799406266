import React from "react";
import { Button, Form, Input, Row, Col, Select, Divider, Card } from "antd";

const { Option } = Select;

const ExitInterview = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <Card
        style={{
          width: "80%",
          maxWidth: 1200,
          backgroundColor: "transparent",
          boxShadow: "none",
        }}
        bodyStyle={{ padding: 0 }}
      >
        <Row gutter={[16, 16]}>
          <Col span={10}>
            <br/>
            <div style={{ textAlign: "left", marginLeft: "20px" }}>
              <Form.Item name="organization" label="Organization">
                <Select placeholder="Select your organization">
                  <Option value="Resignation">THE DIGITAL GROUP</Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="employee"
                label="Employee Name"
                rules={[
                  {
                    required: true,
                    message: "Please provide the employee name!",
                  },
                ]}
              >
                <Input placeholder="Search Employee Name" />
              </Form.Item>
              <p>
                Designation:
                <br />
              </p>
              <p>
                Resignation Letter Date:
                <br />
              </p>
            </div>
          </Col>
          <Divider type="vertical" style={{ height: 0, width: "8vh" }} />
          <Col span={10}>
          <br/>
            <div style={{ textAlign: "left", marginLeft: "20px" }}>
              <Form.Item
                name="exit"
                label="Exit Interview Template Name"
                rules={[
                  {
                    required: true,
                    message: "Please select the exit interview template!",
                  },
                ]}
              >
                <Select placeholder="Select Exit Interview Template" />
              </Form.Item>
              <p>
                Employment Number:
                <br />
              </p>
              <p>
                Date of Joining:
                <br />
              </p>
              <p>
                Actual Relieving Date:
                <br />
              </p>
            </div>
          </Col>
        </Row>
        <div style={{ textAlign: "right", marginRight: 12 }}>
          <Button type="primary">Submit</Button>
        </div>
      </Card>
    </div>
  );
};

export default ExitInterview;
