import React, { useState, useEffect } from "react";
import axios from "axios"; // Ensure Axios is installed
import "./AboutApp.css";
import { url } from "../../url/url";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";

const AboutApp = () => {
  const [showDetails, setShowDetails] = useState(false);
  const [releaseNotes, setReleaseNotes] = useState([]);
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    const fetchReleaseNotes = async () => {
      try {
        const response = await axios.get(`${url}/api/release-notes`);
        const sortedReleaseNotes = response.data.sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        );
        setReleaseNotes(sortedReleaseNotes);
      } catch (error) {
        console.error("Error fetching release notes:", error);
      }
    };

    fetchReleaseNotes();
  }, []);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  const handleClose = () => {
    setShowDetails(false);
    setShowAll(false); // Reset the showAll state when closing the modal
  };

  return (
    <div style={{ zoom: "90%" }}>
      <div style={{ position: "fixed", bottom: "80px", right: "24px" }}>
        <InfoCircleOutlined
          onClick={() => setShowDetails(true)}
          style={{ fontSize: "28px", cursor: "pointer" }}
        />
        {showDetails && (
          <div className="modal">
            <div className="modal-content">
              <span className="close" onClick={handleClose}>
                &times;
              </span>
              <div className="modal-body">
                <table className="table">
                  <thead>
                    <tr className="table-header">
                      <th className="table-cell">Date</th>
                      <th className="table-cell">Version</th>
                      <th className="table-cell">Details / Change Log</th>
                    </tr>
                  </thead>
                  <tbody>
                    {releaseNotes
                      .slice(0, showAll ? releaseNotes.length : 2)
                      .map((note, index) => (
                        <tr key={index} className="table-row">
                          <td className="table-cell">
                            {new Date(note.date).toLocaleDateString("en-GB")}
                          </td>
                          <td className="table-cell">{note.version}</td>
                          <td className="table-cell details-column">
                            {note.details}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                {/* Show More button */}
                {!showAll && (
                  <Button className="show-more-button" onClick={toggleShowAll}>
                    Show More
                  </Button>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AboutApp;
