import React, { useState, useEffect } from 'react';

function Stopwatch() {
  const [startTime, setStartTime] = useState(null);
  const [elapsedSeconds, setElapsedSeconds] = useState(0);
  const [isRunning, setIsRunning] = useState(true);

  useEffect(() => {
    const savedStartTime = localStorage.getItem('stopwatchStartTime');

    if (savedStartTime) {
      const storedStartTime = new Date(savedStartTime);
      const currentTime = new Date();
      const storedElapsedSeconds = Math.floor((currentTime - storedStartTime) / 1000);
      setStartTime(storedStartTime);
      setElapsedSeconds(storedElapsedSeconds);
    } else {
      const currentTime = new Date();
      setStartTime(currentTime);
      setElapsedSeconds(0);
      localStorage.setItem('stopwatchStartTime', currentTime.toISOString());
    }

    const interval = setInterval(() => {
      if (isRunning) {
        setElapsedSeconds(prevElapsedSeconds => {
          const newElapsedSeconds = prevElapsedSeconds + 1;
          if (newElapsedSeconds % 10 === 0) {
            changeBackgroundColor();
          }
          return newElapsedSeconds;
        });
      }
    }, 1000); // Update elapsed time every second

    return () => {
      clearInterval(interval);
    };
  }, [isRunning]);

  useEffect(() => {
    localStorage.setItem('stopwatchStartTime', startTime ? startTime.toISOString() : '');
  }, [startTime]);

  const handleReset = () => {
    const currentTime = new Date();
    setStartTime(currentTime);
    setElapsedSeconds(0);
    localStorage.setItem('stopwatchStartTime', currentTime.toISOString());
  };

  const handlePause = () => {
    setIsRunning(false);
  };

  const handleResume = () => {
    setIsRunning(true);
  };

  const changeBackgroundColor = () => {
    // Function to change the background color
    const randomColor = '#' + Math.floor(Math.random() * 16777215).toString(16);
    document.body.style.backgroundColor = randomColor;
  };

  return (
    <div>
      <h1>Stopwatch</h1>
      <p>Elapsed Time (seconds): {elapsedSeconds}</p>
      <button onClick={handleReset}>Reset</button>
      {isRunning ? (
        <button onClick={handlePause}>Pause</button>
      ) : (
        <button onClick={handleResume}>Resume</button>
      )}
    </div>
  );
}

export default Stopwatch;
