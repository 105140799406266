// constants/currencies.js
export const currencyOptions = [
    { label: 'INR', value: 'INR' },
    { label: 'AED', value: 'AED' },
    { label: 'EUR', value: 'EUR' },
    { label: 'GBP', value: 'GBP' },
    { label: 'USD', value: 'USD' },
    // Add more currencies here
];
export const yearOptions = [
    { label: '2024', value: '2024' },
    { label: '2023', value: '2023' },
    { label: '2022', value: '2022' },
];
export const locOptions = [
    { label: 'TamilNadu, India', value: 'TamilNadu, India' },
    { label: 'Karnataka, India', value: 'Karnataka, India' },
    { label: 'Other States, India', value: 'Other States, India' },
    { label: 'Dubai, UAE', value: 'Dubai, UAE' },
    { label: 'London, UK', value: 'London, UK' },
    { label: 'United States', value: 'United States' },
    // Add more currencies here
];