import { Button } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { url } from "../url/url";

const Timer = (props) => {
  
  const [startTime, setStartTime] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [permissionDenied, setPermissionDenied] = useState(false);
  const [active, setActive] = useState(false);
  const [button, setButton] = useState(false);
  const [switchChecked, setSwitchChecked] = useState(true);
  const user = props.user;

  
  useEffect(() => {
    // Check if the user is coming from the next page
    const isRoutedFromNextPage = localStorage.getItem('routedFromNextPage');
    const storedStartTime = localStorage.getItem('startTime');
    if (isRoutedFromNextPage) {
      setButtonDisabled(true); // Disable the button
    }
    if (storedStartTime) {
      setStartTime(new Date(storedStartTime)); // Retrieve and set the stored start time
    }
  }, []);

  useEffect(() => {
    console.log("Render No-1");
    const savedStartTime = localStorage.getItem("startTime");
    const savedElapsedTime = localStorage.getItem("elapsedTime");

    if (savedStartTime) {
      setStartTime(new Date(savedStartTime));
      setElapsedTime(Number(savedElapsedTime));
    }
  }, []);

  useEffect(() => {
    console.log('Location called');
    fetchLocation();
  }, []);

  useEffect(() => {
    console.log("Render No-2");
    localStorage.setItem("startTime", startTime ? startTime.toString() : "");
    localStorage.setItem("elapsedTime", elapsedTime.toString());
  }, [startTime, elapsedTime]);

  const handleStart = () => { 
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setButtonDisabled(true);
          setStartTime(new Date());
          localStorage.setItem('routedFromNextPage', 'true'); // Store the flag in local storage

        },
        () => {
          alert("Please enable location access to start the timer.");
        }
      );
    } else {
      alert("Geolocation is not supported by your browser.");
    }
  };

  useEffect(() => {
    console.log("Render No-3");
    const interval = setInterval(() => {
      if (startTime) {
        const currentTime = new Date();
        const elapsedMilliseconds = currentTime - startTime;
        const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000);
        setElapsedTime(elapsedSeconds);

        if (elapsedSeconds >= 32400) {
          clearInterval(interval);
          setElapsedTime(0);
          setStartTime(null);
          setActive(false);
        }

        if (elapsedSeconds % 10800 === 0) {
          fetchLocation();
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [startTime]);

  const formatTimer = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  const getCurrentWeekNumber = () => {
    const today = new Date();
    const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
    const pastDaysOfYear = (today - firstDayOfYear) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
  };

  const fetchLocation = () => {
    let count = 0;
    let watchId = null;

    const handlePosition = (position) => {
      if (count < 1) {
        handleSuccess(position);
        count++;
      } else {
        navigator.geolocation.clearWatch(watchId);
      }
    };

    const startWatching = () => {
      watchId = navigator.geolocation.watchPosition(
        handlePosition,
        handleError
      );
    };

    if (navigator.permissions) {
      navigator.permissions.query({ name: "geolocation" }).then((result) => {
        if (result.state === "granted") {
          console.log("Watched");
          startWatching();
        } else if (result.state === "prompt") {
          console.log("Access Now");
          navigator.geolocation.getCurrentPosition(handleSuccess, handleError);
        } else {
          showLocationAccessTab();
        }
      });
    } else if (navigator.geolocation) {
      console.log("Access watched");
      startWatching();
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  // const formatDate = (date) => {
  //   const monthNames = [
  //     "JAN",
  //     "FEB",
  //     "MAR",
  //     "APR",
  //     "MAY",
  //     "JUN",
  //     "JUL",
  //     "AUG",
  //     "SEP",
  //     "OCT",
  //     "NOV",
  //     "DEC",
  //   ];
  //   const month = monthNames[date.getMonth()];
  //   const day = date.getDate();
  //   console.log(day,"day");
  //   return `${day}`;
  // };

  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
    const year = date.getFullYear();
     return `${day}-${month}-${year}`;
  };
  
  const today = new Date();
  const weekNumber = getCurrentWeekNumber();
  const formattedDate = formatDate(today);
  console.log(formattedDate,"date");

  const handleSuccess = async (position) => {
    try {
      const subscriptionKey = "kMR9DArXg_s8DQafEkO8BjGkFRRg8uSqeVAECmde_C0";
      const searchURL = `https://atlas.microsoft.com/search/address/reverse/json?api-version=1.0&subscription-key=${subscriptionKey}&query=${position.coords.latitude},${position.coords.longitude}`;
      const response = await fetch(searchURL);
      const data = await response.json();
      const address = data.addresses[0].address;
      console.log(address);

      if (address) {
        const dbName = localStorage.getItem('dbName'); 
        await axios.post(`${url}/post/employee/location/${dbName}`, {
          empId: user.empCode,
          email: user.email,
          longitude: position.coords.longitude,
          latitude: position.coords.latitude,
          location: `${address.freeformAddress}`,
          weekId: `${weekNumber}`,
          date: formattedDate,
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleError = (error) => {
    console.error(error);
    showLocationAccessTab();
  };

  const showLocationAccessTab = () => {
    return (
      <div>
        <p>Location access is required to use this feature.</p>
        <button onClick={requestLocationAccess}>Enable Location Access</button>
      </div>
    );
  };

  const requestLocationAccess = () => {
    setPermissionDenied(false);
    if (navigator.permissions) {
      navigator.permissions.query({ name: "geolocation" }).then((result) => {
        if (result.state === "prompt") {
          navigator.geolocation.getCurrentPosition(handleSuccess, handleError);
        }
      });
    }
  };

  return (
    <>
      <Button onClick={handleStart} disabled={buttonDisabled} danger>
        Start the Work
      </Button>
      {permissionDenied ? showLocationAccessTab() : formatTimer(elapsedTime)}
      
    </>
  );
};

export default Timer;

