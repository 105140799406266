import React, { useState, useEffect } from "react";
import { Form, Input, Button, DatePicker, Table, Row, Col } from "antd";
import axios from "axios"; // Assuming axios is used for API calls
import { url } from "../../url/url";
import { notification, Popconfirm } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

const ReleaseNotes = () => {
  const [releaseNotes, setReleaseNotes] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchReleaseNotes();
  }, []);

  const fetchReleaseNotes = async () => {
    // Fetch data from backend and update state
    const response = await axios.get(`${url}/api/release-notes`);
    setReleaseNotes(response.data);
  };

  const handleSubmit = async (values) => {
    await axios.post(`${url}/api/release-notes`, values);
    fetchReleaseNotes();
    form.resetFields();
    notification.success({ message: "New release note added successfully" });
  };

  const handleDelete = async (id) => {
    console.log(id);
    await axios.delete(`${url}/api/release-notes/${id}`);
    fetchReleaseNotes();
    notification.success({ message: "Record deleted successfully" });
  };

  const columns = [
    { title: "Date", dataIndex: "date", key: "date" },
    { title: "Version", dataIndex: "version", key: "version" },
    { title: "Details", dataIndex: "details", key: "details" },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Popconfirm
          title="Are you sure delete this note?"
          onConfirm={() => handleDelete(record._id)}
          okText="Yes"
          cancelText="No"
        >
          <DeleteOutlined style={{ color: "red", cursor: "pointer" }} />
        </Popconfirm>
      ),
    },
  ];

  return (
    <div style={{ zoom: "90%" }}>
      <h2>Release Notes</h2>
      <Form form={form} onFinish={handleSubmit}>
        <Row gutter={16}>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Form.Item name="version" rules={[{ required: true }]}>
              <Input placeholder="Version" />
            </Form.Item>

            <Form.Item name="date" rules={[{ required: true }]}>
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={16} lg={12}>
            <Form.Item name="details" rules={[{ required: true }]}>
              <Input.TextArea placeholder="Details" />
            </Form.Item>
          </Col>
        </Row>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form>
      <Table
        dataSource={releaseNotes}
        columns={columns}
        className="custom-table col-lg-12 col-md-12 col-sm-12"
        sticky
      />
    </div>
  );
};

export default ReleaseNotes;
