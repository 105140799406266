/*Reports-> Reportee Leave Summary*/
import axios from "axios";
import React, { useState, useEffect } from "react";
import {
  Tabs,
  Table,
  Image,
  Modal,
  Select,
  Button,
  Input,
  Spin,
  Popconfirm,
  message,
  notification,
  Tooltip,
} from "antd";
import { url } from "../../../url/url";
import Papa from "papaparse";
import FileSaver from "file-saver";
import {
  CheckCircleFilled,
  CloseCircleOutlined,
  ClockCircleOutlined,
  QuestionCircleOutlined,
  DeleteFilled,
  EyeFilled,
  DownloadOutlined,
} from "@ant-design/icons";
import { decryptRole } from "../../../encrypt";
const { Option } = Select;
const { TabPane } = Tabs;
const { Search } = Input;

function ManagerReport(props) {
  const [EmployeeData, setEmployeeData] = useState([]);
  const [EmployeeData1, setEmployeeData1] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [selectedColumns1, setSelectedColumns1] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [Columns, setColumns] = useState([]);
  const encryptedRoleFromStorage = localStorage.getItem("role");
  const jobTitle = decryptRole(encryptedRoleFromStorage);

  const [Columns1, setColumns1] = useState([]);
  const [pdfModalContent, setPdfModalContent] = useState(null);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10); // Set an initial page size
  const [total, setTotal] = useState(EmployeeData.length); // Set the total based on your data
  const [loading, setLoading] = useState(false);
  const currentDate = new Date();
  const currentyear = currentDate.getFullYear();
  const [year, setYear] = useState(currentyear.toString());
  const [selectedYear, setSelectedYear] = useState("");
  // Handle page change
  const onPageChange = (newCurrent, newPageSize) => {
    setCurrent(newCurrent);
    setPageSize(newPageSize);
    // You may need to update the "total" based on your data or an API call here
  };
  const shouldUpdate = (prevProps, nextProps) => {
    if (
      prevProps.dataSource === nextProps.dataSource &&
      prevProps.count === nextProps.count
    ) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    fetchData();
    fetchData1();
  }, [year]);
  const fetchData = async () => {
    try {
      setLoading(true);
      const dbName = localStorage.getItem("dbName");
      
      console.log("dbName in fetchData in managerreport", dbName);
      console.log("user.jobTitle", jobTitle);
      if (jobTitle === "hr" || jobTitle === "admin") {
        const res = await axios.get(
          `${url}/leave/hradminreport/${year}/${dbName}`
        );
        setEmployeeData(res.data);
        setData(res.data);
        setLoading(false);
      } else if (jobTitle === "manager") {
        console.log("inside else", jobTitle);
        const res = await axios.get(
          `${url}/leave/managerreport/${user.displayName}/${year}/${dbName}`
        );
        console.log("res.data", res.data);

        setEmployeeData(res.data);
        setData(res.data);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const fetchData1 = async () => {
    try {
      setLoading(true);
      const dbName = localStorage.getItem("dbName");
      
      console.log("dbName in fetchData in managerreport", dbName);
      console.log("user.jobTitle", jobTitle);
      if (jobTitle === "hr" || jobTitle === "admin") {
        const res = await axios.get(
          `${url}/Compoff/hradminreport/${year}/${dbName}`
        );
        setEmployeeData1(res.data);
        setData1(res.data);
        setLoading(false);
      } else if (jobTitle === "manager") {
        console.log("inside else", jobTitle);
        const res = await axios.get(
          `${url}/Compoff/managerreport/${user.displayName}/${year}/${dbName}`
        );
        console.log("res.data", res.data);

        setEmployeeData1(res.data);
        setData1(res.data);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  function handleOk() {
    const EmployeeData = data.map((row) =>
      selectedColumns.reduce((acc, col) => {
        if (col === "image") {
          const imageBase64 = row[col];
          const imageDataURI = `data:image/png;base64,${imageBase64}`;
          acc[col] = imageDataURI; // Include the image as a Data URI in the CSV
        } else {
          acc[col] = row[col];
        }
        return acc;
      }, {})
    );
    const csv = Papa.unparse(EmployeeData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    FileSaver.saveAs(blob, "Leave_Details.csv", "C:");
    setVisible(false);
    setSelectedColumns([]);
  }

  function handleOk1() {
    const EmployeeData = data1.map((row) =>
      selectedColumns1.reduce((acc, col) => {
        if (col === "image") {
          const imageBase64 = row[col];
          const imageDataURI = `data:image/png;base64,${imageBase64}`;
          acc[col] = imageDataURI; // Include the image as a Data URI in the CSV
        } else {
          acc[col] = row[col];
        }
        return acc;
      }, {})
    );
    const csv = Papa.unparse(EmployeeData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    FileSaver.saveAs(blob, "Leave_Details.csv", "C:");
    setVisible(false);
    setSelectedColumns1([]);
  }

  function handleClick() {
    setVisible(true);
    setColumns(Object.keys(data[0]));
  }
  function handleClick1() {
    setVisible(true);
    setColumns1(Object.keys(data1[0]));
  }
  function handleCancel() {
    setVisible(false);
    setSelectedColumns([]);
    setSelectedColumns1([]);
  }
  function handleColumnSelect(value) {
    setSelectedColumns(value);
    setSelectedColumns1(value);
  }
  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredData = EmployeeData.filter((item) =>
    item.ename.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const filteredData1 = EmployeeData1.filter((item) =>
    item.ename.toLowerCase().includes(searchQuery.toLowerCase())
  );
  function openPdfInModal(pdfDataUrl) {
    // Set the PDF URL to state
    fetch(pdfDataUrl)
      .then((res) => res.blob())
      .then((blob) => {
        // Create a blob URL
        const blobUrl = URL.createObjectURL(blob);

        // Set the blob URL to a state variable for the modal content
        setPdfModalContent(blobUrl);

        // Show the modal
        setVisible1(true);
      });
  }

  function downloadPdf(pdfDataUrl) {
    const link = document.createElement("a");
    link.href = pdfDataUrl;
    link.download = "Leave Report.pdf"; // Specify the desired file name
    link.click();
  }

  function openPdfInModal1(pdfDataUrl) {
    // Set the PDF URL to state
    fetch(pdfDataUrl)
      .then((res) => res.blob())
      .then((blob) => {
        // Create a blob URL
        const blobUrl = URL.createObjectURL(blob);

        // Set the blob URL to a state variable for the modal content
        setPdfModalContent(blobUrl);

        // Show the modal
        setVisible1(true);
      });
  }

  function downloadPdf1(pdfDataUrl) {
    const link = document.createElement("a");
    link.href = pdfDataUrl;
    link.download = "Compoff Report.pdf"; // Specify the desired file name
    link.click();
  }
  const handleDelete = async (id, type) => {
    console.log(id);
    try {
      const dbName = localStorage.getItem("dbName");
      console.log("dbName in handleUpdate in project", dbName);

      const response = await axios.delete(
        `${url}/api/Leavedelete/${id}/${dbName}/${type}`
      );
      console.log(response, "response delete");
      if (response.status === 200) {
        setEmployeeData(EmployeeData.filter((record) => record._id !== id));
        notification.success({ message: "Record deleted successfully!" });
      } else {
        // Handle other errors
        message.error("Failed to delete the record.");
      }
    } catch (error) {
      console.error(error);
      message.error("Failed to delete the record.");
    }
  };
  const handleDelete1 = async (id, type) => {
    console.log(id);
    try {
      const dbName = localStorage.getItem("dbName");
      console.log("dbName in handleUpdate in project", dbName);

      const response = await axios.delete(
        `${url}/compoff/Leavedelete/${id}/${dbName}/${type}`
      );
      console.log(response, "response delete");
      if (response.status === 200) {
        setEmployeeData(EmployeeData.filter((record) => record._id !== id));
        notification.success({ message: "Record deleted successfully!" });
      } else {
        // Handle other errors
        message.error("Failed to delete the record.");
      }
    } catch (error) {
      console.error(error);
      message.error("Failed to delete the record.");
    }
  };

  

  const columns = [
    {
      title: "S.NO",
      dataIndex: "globalSerialNumber",
      key: "index",
      width: 150,
      render: (text, record, index) => {
        const currentSerialNumber = index + 1 + pageSize * (current - 1);
        return <span>{currentSerialNumber}</span>;
      },
    },
    {
      title: "Name",
      dataIndex: "ename",
      key: "ename",
      width: 300,
      render: (text) => {
        return (
          <Tooltip title={text}>
            <span>{text}</span>
          </Tooltip>
        );
      }
    },
    {
      title: "Request Date",
      dataIndex: "doa",
      key: "doa",
      width: 200
    },
    {
      title: "Start Date",
      dataIndex: "sdate",
      key: "sdate",
      width: 150

    },
    {
      title: "End Date",
      dataIndex: "edate",
      key: "edate",
      width: 150

    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
    },
    {
      title: "Days",
      dataIndex: "leavedays",
      key: "leavedays",
      width: 150

    },
    {
      title: "Document",
      width: 200,
      render: (_, record) => (
        <div>
          {record.image ? (
            <div style={{display:"flex",justifyContent:"center"}}>
              {record.image.startsWith("data:application/pdf") ? (
                <div style={{display:"flex",justifyContent:"center"}}>
                  <a href="#" onClick={() => openPdfInModal(record.image)}>
                    <Tooltip title="View PDF">
                      <EyeFilled style={{fontSize:"16px"}}/>
                    </Tooltip>
                  </a>
                  {" | "}
                  <a href="#" onClick={() => downloadPdf(record.image)}>
                    <Tooltip title="Download PDF">
                      <DownloadOutlined style={{ color: "red",fontSize:"16px" }} />
                    </Tooltip>
                  </a>
                </div>
              ) : (
                <Image width={50} src={record.image} fallback={record.image} />
              )}
            </div>
          ) : (
            <p className="text-center">No file</p>
          )}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filters: [
        ...Array.from(new Set(EmployeeData.map((item) => item.status))).map(
          (status) => ({
            text: status,
            value: status,
          })
        ),
      ],
      onFilter: (value, record) => record.status === value,
    },
    {
      title: "Action",
      dataIndex: "id",
      width: 150,
      render: (_, record) => (
        <>
          {record.status === "Approved" && jobTitle === "hr" ? (
            <Popconfirm
              title="Are you sure to delete this record?"
              onConfirm={() => handleDelete(record._id, record.type)} // Pass record._id here
              icon={
                <QuestionCircleOutlined
                  style={{
                    color: "red",
                  }}
                />
              }
              okText="Yes"
              cancelText="No"
            >
              <Button style={{ padding: "6px", fontSize: "12px" }}>
                <DeleteFilled style={{ color: "black" }} />
              </Button>
            </Popconfirm>
          ) : record.status === "Submitted" ? (
            <ClockCircleOutlined style={{ color: "blue" }} />
          ) : record.status === "Denied" ? (
            <CloseCircleOutlined style={{ color: "red" }} />
          ) : record.status === "Approved" ? (
            <CheckCircleFilled style={{ color: "Green" }} />
          ) : null}
        </>
      ),
    },
  ];

  const columns1 = [
    {
      title: "S.NO",
      dataIndex: "globalSerialNumber",
      key: "index",
      width: 150,
      render: (text, record, index) => {
        const currentSerialNumber = index + 1 + pageSize * (current - 1);
        return <span>{currentSerialNumber}</span>;
      },
    },
    {
      title: "Name",
      dataIndex: "ename",
      key: "ename",
      width: 300,
      render: (text) => {
        return (
          <Tooltip title={text}>
            <span>{text}</span>
          </Tooltip>
        );
      }
    },
    
    {
      title: "Request Date",
      dataIndex: "doa",
      key: "doa",
      width: 250
    },
    {
      title: "Date of Work",
      dataIndex: "workdate",
      key: "workdate",
      width: 200
    },
    {
      title: "Type",
      dataIndex: "worktype",
      key: "worktype",
      width: 50,
      render: (text, record) => {
        let displayText = text;
        if (text === "holidayworked") {
          displayText = "Holiday Worked";
        } else if (text === "weekendsat") {
          displayText = "Saturday Worked";
        } else if (text === "weekendsun") {
          displayText = "Sunday Worked";
        }

        return (
          <Tooltip title={displayText}>
            <span>{displayText}</span>
          </Tooltip>
        );
      },
    },
    {
      title: "Work Nature",
      dataIndex: "workNature",
      key: "workNature",
      width: 250,
      render: (text, record) => {
        let displayText = text;
        if (text === "overtime") {
          displayText = "Overtime";
        } else if (text === "projectdeadline") {
          displayText = "Project Deadline";
        } else if (text === "customersupport") {
          displayText = "Customer Support";
        } else if (text === "others") {
          displayText = "Others";
        }

        return (
          <Tooltip title={displayText}>
            <span>{displayText}</span>
          </Tooltip>
        );
      },
    },
    {
      title: "Manager",
      dataIndex: "manager",
      key: "manager",
      width: 200,
    },
    {
      title: "Document",
      width: 200,
      render: (_, record) => (
        <div>
          {record.image ? (
            <div>
              {record.image.startsWith("data:application/pdf") ? (
                <div>
                  <a href="#" onClick={() => openPdfInModal1(record.image)}>
                    View PDF
                  </a>
                  {" | "}
                  <a href="#" onClick={() => downloadPdf1(record.image)}>
                    Download PDF
                  </a>
                </div>
              ) : (
                <Image width={50} src={record.image} fallback={record.image} />
              )}
            </div>
          ) : (
            <>No file</>
          )}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filters: [
        ...Array.from(new Set(EmployeeData1.map((item) => item.status))).map(
          (status) => ({
            text: status,
            value: status,
          })
        ),
      ],
      onFilter: (value, record) => record.status === value,
    },
    {
      title: "Action",
      dataIndex: "id",
      width: 150,
      render: (_, record) => (
        <>
          {record.status === "Approved" && jobTitle === "hr" ? (
            <Popconfirm
              title="Are you sure to delete this record?"
              onConfirm={() => handleDelete(record._id, record.type)} // Pass record._id here
              icon={
                <QuestionCircleOutlined
                  style={{
                    color: "red",
                  }}
                />
              }
              okText="Yes"
              cancelText="No"
            >
              <Button style={{ padding: "6px", fontSize: "12px" }}>
                <DeleteFilled style={{ color: "black" }} />
              </Button>
            </Popconfirm>
          ) : record.status === "Submitted" ? (
            <ClockCircleOutlined style={{ color: "blue" }} />
          ) : record.status === "Denied" ? (
            <CloseCircleOutlined style={{ color: "red" }} />
          ) : record.status === "Approved" ? (
            <CheckCircleFilled style={{ color: "Green" }} />
          ) : null}
        </>
      ),
    },
  ];
  function filterColumns() {
    const excludedFields = ["_id", "image", "__v"];

    const columnsToInclude = Columns.filter(
      (col) => !excludedFields.includes(col)
    );

    return columnsToInclude;
  }

  function filterColumns1() {
    const excludedFields = ["_id", "image", "__v"];

    const columnsToInclude = Columns1.filter(
      (col) => !excludedFields.includes(col)
    );

    return columnsToInclude;
  }

  const handleYearChange = (value) => {
    setYear(value);
  };
  const currentYear = new Date().getFullYear();
  const yearsRange = Array.from(
    { length: 3 },
    (_, index) => currentYear - 1 + index
  );
  return (
    <div style={{transform: "scale(0.9)", transformOrigin: "0 0" ,width:"110%"}}>
      {loading ? (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <Tabs defaultActiveKey="1" centered>
            <TabPane
              tab={
                <span style={{ color: "black", fontSize: "20px" }}>
                  Reportee Leave Summary
                </span>
              }
              key="1"
            >
              <div
                className="tag"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "20px",
                }}
              >
                <Button className="button" type="primary" onClick={handleClick}>
                  Export to CSV
                </Button>
                <Select
                  placeholder="Select Year"
                  onChange={handleYearChange}
                  style={{ width: 130, height: 35 }}
                  value={selectedYear || undefined}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                >
                  {yearsRange.map((year) => (
                    <Select.Option key={year} value={year}>
                      {year}
                    </Select.Option>
                  ))}
                </Select>
                <Modal
                  title="Export to CSV"
                  visible={visible}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  okButtonProps={{ disabled: selectedColumns.length === 0 }}
                >
                  <p>Select columns to export:</p>
                  <Select
                    mode="multiple"
                    style={{ width: "100%" }}
                    placeholder="Please select"
                    value={selectedColumns}
                    onChange={handleColumnSelect}
                  >
                    {filterColumns().map((col) => (
                      <Option key={col} value={col}>
                        {col}
                      </Option>
                    ))}
                  </Select>
                </Modal>
                <Modal
                  title="Document"
                  visible={visible1}
                  onOk={() => setVisible1(false)}
                  footer={null} // Use the custom footer
                  onCancel={() => setVisible1(false)}
                  width={800} // Adjust the width as needed
                >
                  {pdfModalContent && (
                    <iframe
                      src={pdfModalContent}
                      title="Document"
                      style={{ width: "100%", height: "500px" }}
                    />
                  )}
                </Modal>
                <div>
                  <Input
                    type="text"
                    size="large"
                    style={{ height: "40px", fontSize: "15px" }}
                    placeholder="Search Employees..."
                    value={searchQuery}
                    onChange={handleSearchQueryChange}
                  />
                </div>
              </div>
              <div style={{ width: "110%", overflowX: "auto"}}>
                <Table
                  className="custom-table col-lg-12 col-md-12 col-sm-12"
                  columns={columns}
                  pagination={{
                    current: current,
                    pageSize: pageSize,
                    total: total,
                    onChange: onPageChange,
                  }}
                  dataSource={filteredData}
                  bordered={false}
                  shouldComponentUpdate={shouldUpdate}
                  scroll={{ x: true }}
                  sticky
                />
              </div>
            </TabPane>
            <TabPane
              tab={
                <span style={{ color: "black", fontSize: "20px" }}>
                  Reportee Compoff Summary
                </span>
              }
              key="2"
            >
              <div
                className="tag"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "20px",
                }}
              >
                <Button
                  className="button"
                  type="primary"
                  onClick={handleClick1}
                >
                  Export to CSV
                </Button>
                <Select
                  placeholder="Select Year"
                  onChange={handleYearChange}
                  style={{ width: 130, height: 35 }} // Add marginRight for spacing
                  value={selectedYear || undefined}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                >
                  {yearsRange.map((year) => (
                    <Select.Option key={year} value={year}>
                      {year}
                    </Select.Option>
                  ))}
                </Select>
                <Modal
                  title="Export to CSV"
                  visible={visible}
                  onOk={handleOk1}
                  onCancel={handleCancel}
                  okButtonProps={{ disabled: selectedColumns1.length === 0 }}
                >
                  <p>Select columns to export:</p>
                  <Select
                    mode="multiple"
                    style={{ width: "100%" }}
                    placeholder="Please select"
                    value={selectedColumns1}
                    onChange={handleColumnSelect}
                  >
                    {filterColumns1().map((col) => (
                      <Option key={col} value={col}>
                        {col}
                      </Option>
                    ))}
                  </Select>
                </Modal>
                <Modal
                  title="Document"
                  visible={visible1}
                  onOk={() => setVisible1(false)}
                  footer={null} // Use the custom footer
                  onCancel={() => setVisible1(false)}
                  width={800} // Adjust the width as needed
                >
                  {pdfModalContent && (
                    <iframe
                      src={pdfModalContent}
                      title="Document"
                      style={{ width: "100%", height: "500px" }}
                    />
                  )}
                </Modal>
                <div>
                  <Input
                    type="text"
                    size="large"
                    style={{ height: "40px", fontSize: "15px" }}
                    placeholder="Search Employees..."
                    value={searchQuery}
                    onChange={handleSearchQueryChange}
                  />
                </div>
              </div>
              <div style={{ width: "110%", overflowX: "auto", transform: "scale(0.9)", transformOrigin: "0 0" }}>
                <Table
                  className="custom-table col-lg-12 col-md-12 col-sm-12"
                  columns={columns1}
                  pagination={{
                    current: current,
                    pageSize: pageSize,
                    total: total,
                    onChange: onPageChange,
                  }}
                  dataSource={filteredData1}
                  bordered={false}
                  shouldComponentUpdate={shouldUpdate}
                  scroll={{ x: true }}
                  sticky
                />
              </div>
            </TabPane>
          </Tabs>
        </div>
      )}
    </div>
  );
}

export default ManagerReport;
