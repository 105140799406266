import React from 'react';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { Button } from 'antd';
 
const Download = ({ rootElementId, downloadFileName }) => {
  const fileDownload = async () => {
    const input = document.getElementById(rootElementId);
 
    if (input) {
      const scale = 2; // Adjust scale as needed for higher resolution
      const canvas = await html2canvas(input, {
        windowWidth: document.documentElement.clientWidth * scale,
        windowHeight: document.documentElement.clientHeight * scale,
        scale: scale,
        scrollY: -window.scrollY,
      });
 
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: 'landscape',
        unit: 'px',
        format: [canvas.width, canvas.height],
      });
 
      const pageHeight = pdf.internal.pageSize.height;
      let position = 0;
 
      while (position < canvas.height) {
        if (position !== 0) {
          pdf.addPage();
        }
 
        const remainingHeight = canvas.height - position;
        const useHeight = remainingHeight > pageHeight ? pageHeight : remainingHeight;
 
        pdf.addImage(imgData, 'PNG', 0, -position, canvas.width, canvas.height);
        pdf.setFontSize(100);
        // Add your content with adjusted font size
        position += useHeight;
      }
 
      pdf.save(downloadFileName);
    } else {
      console.error(`Element with ID '${rootElementId}' not found.`);
    }
  };
 
  return (
<div>
<Button type="primary" onClick={fileDownload}>
        Download PDF
</Button>
</div>
  );
};
 
export default Download;




