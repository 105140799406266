import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import axios from 'axios';
import './NotificationTab.css'; // Import the custom CSS file
import { url } from '../../url/url'; // Make sure to have the correct URL import

const NotificationTab = () => {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dbName = localStorage.getItem('dbName');
        const response = await axios.get(`${url}/api/separation/manager/${dbName}`);
        setDataSource(response.data);
      } catch (error) {
        setError(error);
        console.error('Error fetching records:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error fetching records: {error.message}</p>;

  return (
    <div className="table-container">
      {dataSource.length === 0 ? (
        <p>No data available</p>
      ) : (
        <table className="custom-table">
          <thead>
            <tr>
              <th>EMP Name</th>
              <th>EMP Code</th>
              <th>Date of Joining</th>
              <th>Designation</th>
              <th>Reporting Manager</th>
              <th>Separation Type</th>
              <th>Requested Relieving Date</th>
              <th>Actual Relieving Date</th>
              <th>End of Notice Period</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {dataSource.map((record) => (
              <tr key={record._id}>
                <td>{record.employeeName}</td>
                <td>{record.employeeId}</td>
                <td>{record.dateofjoining}</td>
                <td>{record.designation}</td>
                <td>{record.managerName}</td>
                <td>{record.separationType}</td>
                <td>{record.requestedRelievingDate}</td>
                <td>{record.actualRelievingDate}</td>
                <td>{record.endOfNoticePeriod}</td>
                <td>
                  <Button type="primary">View</Button>
                  <Button type="default">Acknowledge</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default NotificationTab;
{/* <Form.Item
name="actualRelievingDate"
label="Actual Relieving Date"
>
<DatePicker />
</Form.Item>

<Form.Item
name="endOfNoticePeriod"
label="End of Notice Period"
>
<DatePicker />
</Form.Item> */}