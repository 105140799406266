/*Apply leave-> Apply leave form*/
import React, { useState, useEffect } from "react";
import Layout, { Content } from "antd/es/layout/layout";
import moment from "moment";
import {
  Button,
  Modal,
  message,
  notification,
  Select,
  Row,
  Checkbox,
  Typography,
} from "antd";
import { Form, Input } from "antd";
import { UploadOutlined, DeleteFilled } from "@ant-design/icons";
import axios from "axios";
import "./empform.css";
import { url } from "../../../url/url";
import { useNavigate, useParams, useLocation } from "react-router-dom";
const { Paragraph } = Typography;
const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

function EmpLeaveApply(props) {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const today = new Date().toISOString().slice(0, 10);
  const [date] = useState(today);
  const [form] = Form.useForm();
  const [image, setImage] = useState("");
  const [balance1, setAnnualbalance] = useState("");
  const [balance2, setCasualbalance] = useState("");
  const [compOff, setCompoff] = useState("");
  const formRef = React.useRef(null);
  const [leaveType, setLeaveType] = useState([]);
  const [annualHoliday, setannualHoliday] = useState([]);
  const [casualHoliday, setCasualHoliday] = useState([]);
  const [leave, setLeave] = useState([]);
  const [managerInfo, setManagerInfo] = useState([]);
  const [halfDayLeave, setHalfDayLeave] = useState(false);
  const [count, setcount] = useState([]);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [weekend, setWeekend] = useState([]);
  const [leavedet, setLeavedet] = useState({
    reportingmanager: "",
    hr: "",
    empCode: "",
    balance1: "",
    balance2: "",
    leaveType: [],
  });
  const [pdf, setPdf] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [pdfBlobUrl, setPdfBlobUrl] = useState("");
  const status = localStorage.getItem("status");
  const [selectedLeaveType, setSelectedLeaveType] = useState(null);
  const location = useLocation();
  useEffect(() => {
    fetchData();
    Weekend();
  }, [count]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const type = searchParams.get("type");
    if (type) {
      setSelectedLeaveType(type);
    }
  }, [location]);

  const Weekend = async () => {
    try {
      const dbName = localStorage.getItem("dbName");
      const response = await axios.get(`${url}/get/leave/${dbName}`);
      setLeavedet((leaveType) => ({
        ...leaveType,
        leaveType: response.data.leaveType,
      }));
      // Update the state with the fetched data
      setWeekend(response.data.weekend);
    } catch (error) {
      // Handle errors
      console.error("Error fetching data:", error);
    }
  };
  const fetchData = async () => {
    try {
      console.log("inside fetchdata");
      const dbName = localStorage.getItem("dbName");

      const resp = await axios.get(
        `${url}/api/getUserManager/${user.displayName}/${dbName}`
      );
      console.log(resp, "resp");
      const empCode = resp.data.user.empCode;
      console.log(empCode, "empCode");

      const manager = resp.data.user.manager;
      const hrName = resp.data.user.hrName;
      const annualLeave = resp.data.user.annualLeave;
      const casualLeave = resp.data.user.casualLeave;

      const leaveResponse = await axios.get(
        `${url}/leave/ereport/${empCode}/${dbName}`
      );

      const leaveData = leaveResponse.data;
      const leavebalanceResponse = await axios.get(
        `${url}/emp/leavebalance/${empCode}/${dbName}`
      );

      if (leavebalanceResponse.data.length > 0) {
        const empLeaveBalance = leavebalanceResponse.data;
        const compoffBal = empLeaveBalance[0].compoffBal;
        setCompoff(compoffBal);
      } else {
        console.log("No leave balance data found for the employee.");
      }
      const currentYear = new Date().getFullYear();

      // Filter out denied leaves
      const approvedCasualLeaves = leaveData.filter(
        (item) =>
          item.type === "casual" &&
          (item.status === "Submitted" || item.status === "Approved") &&
          new Date(item.sdate).getFullYear() === currentYear
      );
      const approvedAnnualLeaves = leaveData.filter(
        (item) =>
          item.type === "annual" &&
          (item.status === "Submitted" || item.status === "Approved") &&
          new Date(item.sdate).getFullYear() === currentYear
      );

      const casualleavedays = approvedCasualLeaves.map(
        (item) => item.leavedays
      );

      const annualleavedays = approvedAnnualLeaves.map(
        (item) => item.leavedays
      );
      const availableAnnualLeave =
        annualLeave -
        annualleavedays.reduce((acc, val) => acc + parseFloat(val), 0);
      const availableCasualLeave =
        casualLeave -
        casualleavedays.reduce((acc, val) => acc + parseFloat(val), 0);

      setAnnualbalance(availableAnnualLeave);
      setCasualbalance(availableCasualLeave);
      setannualHoliday(annualLeave);
      setCasualHoliday(casualLeave);

      const updatedValues = {
        empid: empCode,
        empName: user.displayName,
        casualLeaveBal: availableCasualLeave,
        annualLeaveBal: availableAnnualLeave,
      };

      const Send = axios
        .put(`${url}/api/leavebalance/${dbName}`, updatedValues)
        .then((response) => {
          console.log("sync leave balance table");
        });
      setLeavedet((prevLeaveData) => ({
        ...prevLeaveData,
        reportingmanager: manager,
        hr: hrName,
        empCode: empCode,
        balance1: availableAnnualLeave,
        balance2: availableCasualLeave,
      }));
    } catch (error) {
      console.log(error);
    }
  };
  const handleOpenChange = (status) => {
    setOpen(status);
  };
  const handleOpenChange1 = (status) => {
    setOpen1(status);
  };

  async function fetchPublicHolidays(dbName) {
    try {
      const response = await axios.get(`${url}/get/leave/${dbName}`);
      const data = response.data?.officeHolidaysData;
      return data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  const handleSubmit = async (values) => {
    const { startDate, endDate } = values;
    const halfDayLeave1 = values.halfDayLeave;
    //const halfDayLeave2 = true;
    const parsedStartDate = new Date(startDate);
    const parsedEndDate = new Date(endDate);

    if (parsedStartDate > parsedEndDate) {
      message.error("Start date is greater than End date");
    } else {
      const dbName = localStorage.getItem("dbName");
      // Fetch public holidays
      const publicHolidays = await fetchPublicHolidays(dbName);
      console.log(publicHolidays, "publicHolidays");
      axios
        .get(`${url}/employee/leavebalance/${user.empCode}/${dbName}`)
        .then((response) => {
          const leaves = response.data;
          const filteredLeaves = leaves.filter((leave) => {
            const status = leave.status.toLowerCase();
            const type = leave.type.toLowerCase();
            return status === "submitted" || status === "approved";
          });

          if (checkOverlap(startDate, endDate, filteredLeaves)) {
            message.error(
              "These dates have already been accounted for in the leave application."
            );
          } else {
            // If no overlap, proceed with balance check
            const start = moment(startDate);
            const end = moment(endDate);
            let totalAnnualLeavedays;
            let totalCasualLeavedays;
            let totalCompoffLeavedays;

            if (selectedLeaveType === "annual") {
              if (halfDayLeave === true) {
                // Calculate the total leavedays excluding weekends

                totalAnnualLeavedays = calculateLeavedays(
                  start,
                  end,
                  filteredLeaves,
                  publicHolidays,
                  true
                );
              } else {
                totalAnnualLeavedays = calculateLeavedays(
                  start,
                  end,
                  filteredLeaves,
                  publicHolidays,
                  false
                );
              }
            } else if (selectedLeaveType === "casual") {
              if (halfDayLeave === true) {
                totalCasualLeavedays = calculateLeavedays(
                  start,
                  end,
                  filteredLeaves,
                  publicHolidays,
                  true
                );
              } else {
                totalCasualLeavedays = calculateLeavedays(
                  start,
                  end,
                  filteredLeaves,
                  publicHolidays,
                  false
                );
              }
            } else if (selectedLeaveType === "compoff") {
              if (halfDayLeave === true) {
                totalCompoffLeavedays = calculateLeavedays(
                  start,
                  end,
                  filteredLeaves,
                  publicHolidays,
                  true
                );
              } else {
                totalCompoffLeavedays = calculateLeavedays(
                  start,
                  end,
                  filteredLeaves,
                  publicHolidays,
                  false
                );
              }
            }
            // Calculate the available balance
            const availableAnnualBalance = balance1 - totalAnnualLeavedays;
            const availableCasualBalance = balance2 - totalCasualLeavedays;
            const availableCompoffBalance = compOff - totalCompoffLeavedays;
            console.log(
              availableAnnualBalance,
              balance1,
              totalAnnualLeavedays,
              "availableAnnualBalance,balance1,totalAnnualLeavedays"
            );
            console.log(
              availableCasualBalance,
              balance2,
              totalCasualLeavedays,
              "availableCasualBalance,balance2,totalCasualLeavedays"
            );
            console.log(
              availableCompoffBalance,
              compOff,
              totalCompoffLeavedays,
              "availableCompoffBalance,compOff,totalCompoffLeavedays"
            );
            if (selectedLeaveType === "annual" && totalAnnualLeavedays == 0) {
              message.error(
                "Your applied leave days coincide with either a weekend or a public holiday."
              );
            } else if (
              selectedLeaveType === "casual" &&
              totalCasualLeavedays == 0
            ) {
              message.error(
                "Your applied leave days coincide with either a weekend or a public holiday."
              );
            } else if (
              selectedLeaveType === "compoff" &&
              totalCompoffLeavedays == 0
            ) {
              message.error(
                "Your applied leave days coincide with either a weekend or a public holiday."
              );
            } else if (
              selectedLeaveType === "annual" &&
              (availableAnnualBalance < 0 || totalAnnualLeavedays > balance1)
            ) {
              message.error(
                "Applying for this leave exceeds the available annualleave balance."
              );
            } else if (
              selectedLeaveType === "casual" &&
              (availableCasualBalance < 0 || totalCasualLeavedays > balance2)
            ) {
              message.error(
                "Applying for this leave exceeds the available casualleave balance."
              );
            } else if (
              selectedLeaveType === "compoff" &&
              (availableCompoffBalance < 0 || totalCompoffLeavedays > compOff)
            ) {
              message.error(
                "Applying for this leave exceeds the available compoffleave balance."
              );
              // (values.leaveType === "annual" && availableAnnualBalance < 0) || ( values.leaveType === "casual" &&
              //   availableCasualBalance < 0) || (values.leaveType === "compoff" &&
              //   (availableCompoffBalance < 0 || totalCompoffLeavedays > compOff))
              // ) {
              //   message.error(
              //     "Applying for this leave exceeds the available leave balance."
              //   );
            } else if (selectedLeaveType === "annual") {
              // If both checks pass, proceed with submission
              console.log(totalAnnualLeavedays, "totalAnnualLeavedays");
              FinalSubmit(values, totalAnnualLeavedays);
            } else if (selectedLeaveType === "casual") {
              console.log(totalCasualLeavedays, "totalCasualLeavedays");
              // If both checks pass, proceed with submission
              FinalSubmit(values, totalCasualLeavedays);
            } else if (selectedLeaveType === "compoff") {
              // If both checks pass, proceed with submission
              FinalSubmit(values, totalCompoffLeavedays);
              console.log(totalCompoffLeavedays, "totalCompoffLeavedays");
              FinalSubmit(values, totalCompoffLeavedays);
            } else {
              FinalSubmit(values);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  function isWorkingDay(currentDate) {
    if (!weekend || weekend.length === 0) {
      console.log("inside if");
      return true;
    }

    console.log(weekend, "weekend");

    // Switch case directly on the weekend string
    switch (weekend) {
      case "satoff":
        return currentDate.day() !== 6;
      case "satsunoff":
        return currentDate.day() !== 6 && currentDate.day() !== 0;
      case "frisatoff":
        return currentDate.day() !== 5 && currentDate.day() !== 6;
      case "frioff":
        return currentDate.day() !== 5;
      case "sunoff":
        return currentDate.day() !== 0;
      default:
        return true;
    }
  }

  // Function to calculate the total leave days
  function calculateLeavedays(
    startDate,
    endDate,
    leaves,
    publicHolidays,
    halfDayLeave
  ) {
    let totalLeavedays = 0;
    const currentDate = startDate.clone();

    if (startDate.isSame(endDate) && halfDayLeave === true) {
      const formattedDate = currentDate.format("YYYY-MM-DD");
      const isPublicHoliday = publicHolidays?.some(
        (holiday) => holiday.date === formattedDate
      );
      if (isWorkingDay(currentDate) && !isPublicHoliday) {
        totalLeavedays += 0.5;
      }
    } else {
      while (currentDate.isSameOrBefore(endDate)) {
        const formattedDate = currentDate.format("YYYY-MM-DD");
        const isPublicHoliday = publicHolidays?.some(
          (holiday) => holiday.date === formattedDate
        );
        const isWorkingDayCheck = isWorkingDay(currentDate);

        if (!isPublicHoliday && isWorkingDayCheck) {
          const overlappingLeave = leaves.find(
            (leave) =>
              leave.sdate <= formattedDate && formattedDate <= leave.edate
          );

          if (!overlappingLeave && !isPublicHoliday) {
            totalLeavedays++;
          }
        }

        currentDate.add(1, "day");
      }
    }

    return totalLeavedays;
  }

  function checkOverlap(startDate, endDate, leaves) {
    const newStart = moment(startDate);
    const newEnd = moment(endDate);

    // Calculate the days between start and end dates, including both
    const daysBetween = newEnd.diff(newStart, "days") + 1;

    for (let i = 0; i < leaves.length; i++) {
      const leave = leaves[i];
      const leaveStart = moment(leave.sdate);
      const leaveEnd = moment(leave.edate);

      // Check if there is any overlap between the date ranges
      if (
        newStart.isBetween(leaveStart, leaveEnd, null, "[]") ||
        newEnd.isBetween(leaveStart, leaveEnd, null, "[]") ||
        leaveStart.isBetween(newStart, newEnd, null, "[]") ||
        leaveEnd.isBetween(newStart, newEnd, null, "[]")
      ) {
        return true;
      }
    }
    return false;
  }

  const FinalSubmit = async (values, difference) => {
    try {
      console.log(selectedLeaveType, values, "leavetype");
      const imageFile = image;
      const dbName = localStorage.getItem("dbName");
      // const managerResponse = await axios.get(
      //   `${url}/get/managermail/${leavedet.reportingmanager}/${dbName}`
      // );
      const managerEmail = user.managerEmail;
      const updatedValues = {
        ...values,
        image: imageFile || pdf,
        difference: difference,
        status: "Submitted",
        reportingManager: leavedet.reportingmanager,
        hrName: leavedet.hr,
        employeeId: leavedet.empCode,
        dateOfApply: date,
        employeeName: user.displayName,
        leaveType: selectedLeaveType,
        managerEmail: managerEmail,
      };

      // Make a POST request to submit the leave
      const leaveResponse = await axios.post(
        `${url}/Leave/${dbName}`,
        updatedValues
      );
      notification.success({ message: "Successfully Applied" });
      console.log(values, "type");
      if (selectedLeaveType === "compoff") {
        const compoffBal = compOff - difference;
        console.log(compoffBal, difference, "compoffBal in finalsubmit");
        // Calculate compoffBal by subtracting compOff and dayCount
        const compoffUpdateResponse = await axios.put(
          `${url}/compoff/${dbName}/${compoffBal}`,
          { empid: leavedet.empCode }
        );
      }

      handleReset();
      setcount(count + 1);

      // If the leave submission was successful, get manager email

      // // If both API calls are successful, trigger the mail
      // const mailTriggerResponse = await axios.post(`${url}/mail`, {
      //   userName: user.displayName,
      //   email: user.email,
      //   manager: managerEmail,
      //   requestType: "leave",
      // });

      // notification.success({ message: "Successfully Applied" });

      handleReset();
      setcount(count + 1);
    } catch (error) {
      console.error(error);
      message.error({ error: "Something Went Wrong" });
    }
  };

  const handleReset = () => {
    setImage("");
    setPdf("");
    setSelectedLeaveType(null);
    form.resetFields();
    setHalfDayLeave(false);
    // form.setFieldsValue({
    //   // sets the fields to the initial values
    //   startDate: form.getFieldValue("startDate"),
    //   endDate: form.getFieldValue("endDate"),
    //   reason: form.getFieldValue("reason"),
    //   Leave: form.getFieldValue("leaveType"),
    // });
  };
  const validateYearFormat = (rule, value, callback) => {
    // Check if the value is a valid date with a 4-digit year
    const regex = /^\d{4}-\d{2}-\d{2}$/; // YYYY-MM-DD format
    if (!regex.test(value)) {
      callback("Please enter a valid date in DD-MM-YYYY format.");
    } else {
      callback();
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const allowedFileTypes = [
        "image/jpeg",
        "image/png",
        "image/jpg",
        "application/pdf",
      ];
      const maxSize = 5 * 1024 * 1024;

      if (allowedFileTypes.includes(file.type) && file.size <= maxSize) {
        const reader = new FileReader();
        reader.onloadend = () => {
          if (file.type.startsWith("image/")) {
            setImage(reader.result);
            setPdf(null); // Clear the PDF if an image is selected
          } else if (file.type === "application/pdf") {
            setImage(null); // Clear the image if PDF is selected
            setPdf(reader.result); // Set the PDF file for preview
          }
        };
        reader.readAsDataURL(file);
      } else {
        // Clear the file error message if no file is selected
        setImage(null);
        setPdf(null);
        formRef.current.setFieldsValue({ image: null });
        if (file.size > maxSize) {
          message.error("File size exceeds the 5MB limit.");
        } else {
          message.error(
            "Invalid file type. Please select a valid image or PDF file."
          );
        }
      }
    }
  };
  const handleDelete = () => {
    setImage("");
    setPdf("");
    form.setFieldsValue({
      ...form.getFieldsValue(),
      image: null,
    });
  };
  function openPdfInModal(pdfDataUrl) {
    fetch(pdfDataUrl)
      .then((res) => res.blob())
      .then((blob) => {
        const blobUrl = URL.createObjectURL(blob);
        setPdfBlobUrl(blobUrl);
        setModalVisible(true);
      });
  }
  const handleLeaveTypeChange = (value) => {
    console.log(value, "value");
    setSelectedLeaveType(value);
    setHalfDayLeave("");
  };

  const getLeaveBalance = () => {
    switch (selectedLeaveType) {
      case "casual":
        return `Casual Leave Balance: ${leavedet.balance2} days`;
      case "annual":
        return `Annual Leave Balance: ${leavedet.balance1} days`;
      case "compoff":
        const compOffBalance = compOff !== undefined ? compOff : "0";
        return `Comp-off Balance: ${compOffBalance} days`;
      default:
        return null; // or you can set a default message if needed
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  console.log(leavedet.leaveType, "tyep");
  const filteredLeaveTypes = Array.isArray(leavedet?.leaveType)
    ? [
        ...leavedet.leaveType.filter((type) => {
          if (user.gender === "female" && type === "Maternity") {
            return true;
          }
          return type !== "Maternity";
        }),
      ]
    : [];

  return (
    <>
      <Content
        style={{
          padding: 20,
          boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.35)",
          minHeight: "5vh",
        }}
      >
        <Button onClick={goBack}>Back</Button>
        <Layout className="Leave">
          <Modal
            title="PDF Document"
            visible={modalVisible}
            onCancel={() => setModalVisible(false)}
            width={800} // Adjust the width as needed
            footer={null} // Remove the default footer buttons
          >
            {pdfBlobUrl && (
              <iframe
                src={pdfBlobUrl}
                title="PDF Document"
                style={{ width: "100%", height: "500px" }}
              />
            )}
          </Modal>

          <h4 className="text-center ">Apply Leave</h4>
          <Row
            style={{
              margin: "20px 0px",
              display: "flex",
              justifyContent: "space-between",
            }}
          ></Row>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ width: "50%" }}>
              <Form
                layout={layout}
                form={form}
                onFinish={handleSubmit}
                ref={formRef}
                labelCol={{ span: 8 }} 
                wrapperCol={{ span: 16 }} 
                onValuesChange={(changedValues, allValues) => {
                  const { startDate, endDate } = allValues;

                  if (changedValues.startDate || changedValues.endDate) {
                    setHalfDayLeave(false);
                  }
                }}
              >
                {/* Date of Apply */}
                <Form.Item label="Date of Apply">
                  <Input
                    value={date}
                    name="dateOfApply"
                    type="date"
                    style={{ height: "40px", fontSize: "14px" }}
                    disabled
                    className="disabled-input"
                  />
                </Form.Item>

                {/* Reporting Manager */}
                <Form.Item label="Reporting Manager">
                  <Input
                    value={leavedet.reportingmanager}
                    name="reportingManager"
                    style={{ height: "40px", fontSize: "14px" }}
                    disabled
                    className="disabled-input"
                  />
                </Form.Item>

                {/* Type of Leave */}
                <Form.Item
                  label="Type of Leave"
                  rules={[
                    { required: true, message: "Please select Type of Leave!" },
                  ]}
                >
                  <Select
                    placeholder="Please select the Leave Type"
                    size="large"
                    onChange={handleLeaveTypeChange}
                    value={selectedLeaveType}
                    style={{ fontSize: "14px" }}
                  >
                    {filteredLeaveTypes.map((leave, index) => (
                      <Option
                        key={index}
                        value={leave.type}
                        style={{ fontSize: "14px" }}
                      >
                        {leave.type}
                      </Option>
                    ))}
                  </Select>
                  {selectedLeaveType && (
                    <p style={{ color: "rgba(0, 0, 0, 0.45)" }}>
                      {getLeaveBalance()}
                    </p>
                  )}
                </Form.Item>

                {/* Start Date */}
                <Form.Item
                  label="Start Date"
                  name="startDate"
                  rules={[
                    { required: true, message: "" },
                    { validator: validateYearFormat },
                  ]}
                  required
                >
                  <Input
                    type="date"
                    size="large"
                    style={{ height: "40px", fontSize: "14px" }}
                    min={
                      selectedLeaveType === "annual" ||
                      selectedLeaveType === "casual" ||
                      selectedLeaveType === "compoff"
                        ? moment()
                            .subtract(2, "months")
                            .format("YYYY-MM-DD")
                        : undefined
                    }
                    max={
                      selectedLeaveType === "annual" ||
                      selectedLeaveType === "casual" ||
                      selectedLeaveType === "compoff"
                        ? moment()
                            .add(2, "months")
                            .format("YYYY-MM-DD")
                        : undefined
                    }
                  />
                </Form.Item>

                {/* End Date */}
                <Form.Item
                  label="End Date"
                  name="endDate"
                  rules={[
                    { required: true, message: "" },
                    { validator: validateYearFormat },
                  ]}
                  required
                >
                  <Input
                    type="date"
                    size="large"
                    style={{ height: "40px", fontSize: "14px" }}
                    min={
                      selectedLeaveType === "annual" ||
                      selectedLeaveType === "casual" ||
                      selectedLeaveType === "compoff"
                        ? moment()
                            .subtract(2, "months")
                            .format("YYYY-MM-DD")
                        : undefined
                    }
                    max={
                      selectedLeaveType === "annual" ||
                      selectedLeaveType === "casual" ||
                      selectedLeaveType === "compoff"
                        ? moment()
                            .add(2, "months")
                            .format("YYYY-MM-DD")
                        : undefined
                    }
                  />
                </Form.Item>

                {/* Half Day Leave Checkbox */}
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.startDate !== currentValues.startDate ||
                    prevValues.endDate !== currentValues.endDate
                  }
                >
                  {({ getFieldValue }) => {
                    const startDate = getFieldValue("startDate");
                    const endDate = getFieldValue("endDate");
                    if (startDate === endDate) {
                      return (
                        <Form.Item
                          style={{ marginLeft: "30vh" }}
                          wrapperCol={{ span: 24 }}
                        >
                          <Checkbox
                            onChange={(e) => setHalfDayLeave(e.target.checked)}
                          >
                            Select for Half Day Leave
                          </Checkbox>
                        </Form.Item>
                      );
                    } else {
                      return null;
                    }
                  }}
                </Form.Item>
                {/* Reason */}
                <Form.Item
                  label="Reason"
                  name="reason"
                  rules={[{ required: false, message: "Please enter Reason!" }]}
                >
                  <Input style={{ height: "40px", fontSize: "14px" }} />
                </Form.Item>

                {/* Supporting Document */}
                <Form.Item label="Supporting Document" name="image">
                  <label className="custom-file-upload">
                    <UploadOutlined />
                    Upload
                    <Input
                      type="file"
                      accept="image/jpeg, image/png, image/jpg, application/pdf"
                      onChange={(e) => handleImageChange(e, "image")}
                      style={{ display: "none" }}
                    />
                  </label>
                  {pdf || image ? (
                    <div>
                      {image && (
                        <div>
                          <img
                            src={image}
                            width="200"
                            alt="Uploaded document"
                          />
                          <Button
                            danger
                            size="medium"
                            icon={<DeleteFilled />}
                            onClick={handleDelete}
                          />
                        </div>
                      )}
                      {pdf && (
                        <div>
                          <Button
                            danger
                            size="medium"
                            icon={<DeleteFilled />}
                            onClick={handleDelete}
                          />
                          <Button
                            type="primary"
                            size="medium"
                            onClick={() => openPdfInModal(pdf)}
                          >
                            Open PDF
                          </Button>
                        </div>
                      )}
                    </div>
                  ) : null}
                </Form.Item>

                {/* Reset and Submit Buttons */}
                <div className="d-flex justify-content-between">
                  <Button type="primary" danger onClick={handleReset}>
                    Reset
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={status === "disable"}
                  >
                    Submit
                  </Button>
                </div>

                {/* Note */}
                <p
                  style={{
                    color: "red",
                    textAlign: "center",
                    marginTop: "1rem",
                  }}
                >
                  Note: To apply for half a day, start date and end date must be
                  the same.
                </p>
              </Form>
            </div>
          </div>
        </Layout>
      </Content>
    </>
  );
}

export default EmpLeaveApply;
