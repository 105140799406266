import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Descriptions } from 'antd';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend ,PieChart, Pie} from 'recharts';
import { url } from "../../url/url";

const ViewProfitability = ({ projectId, visible, onClose }) => {
    const [projectData, setProjectData] = useState(null);

    useEffect(() => {
        if (visible) {
            axios.get(`${url}/api/projects/${projectId}/profitability`)
                 .then(response => setProjectData(response.data))
                 .catch(error => console.error('Error fetching data:', error));
        }
    }, [projectId, visible]);

    const calculateProfitability = () => {
        // Default values to avoid undefined errors
        const defaultValues = { revenue: 10000, costs: 7500, profit: 0, profitMargin: 0 };
    
        if (!projectData || !projectData.revenue) return defaultValues;
    
        const { revenue, costs } = projectData;
        const profit = revenue - costs;
        const profitMargin = revenue !== 0 ? (profit / revenue) * 100 : 0;
    
        return { revenue, costs, profit, profitMargin };
    };
    
    const { revenue, costs, profit, profitMargin } = calculateProfitability();
    const chartData = [
        { name: 'Revenue', value: revenue },
        { name: 'Costs', value: costs }
    ];
    return (
        <Modal title="Project Profitability" visible={visible} onCancel={onClose}>
            <Descriptions>
                <Descriptions.Item label="Revenue">{`${revenue}`}</Descriptions.Item>
                <Descriptions.Item label="Costs">{`$${costs}`}</Descriptions.Item>
                <Descriptions.Item label="Profit">{`$${profit}`}</Descriptions.Item>
                <Descriptions.Item label="Profit Margin">{`${profitMargin.toFixed(2)}%`}</Descriptions.Item>
            </Descriptions>
            <BarChart width={400} height={300} data={chartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="value" fill="#8884d8" />
            </BarChart>
            <PieChart width={400} height={300}>
            <Pie data={chartData} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={100} fill="#8884d8" label />
            <Tooltip />
        </PieChart>
        </Modal>
    );
};

export default ViewProfitability;
