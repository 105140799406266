// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.not-found-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    align-items: center;
justify-content: center;
  }
  
  .not-found-icon {
    font-size: 48px;
    margin-bottom: 20px;
    color: #f44336;
  }
  
  .not-found-title {
    font-size: 36px;
    margin-bottom: 20px;
  }
  
  .not-found-text {
    font-size: 18px;
    margin-bottom: 40px;
  }
  
  .not-found-link {
    font-size: 16px;
    color: #2196f3;
    text-decoration: none;
    transition: color 0.2s ease-in-out;
  
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/error/NotFound.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,aAAa;IACb,mBAAmB;AACvB,uBAAuB;EACrB;;EAEA;IACE,eAAe;IACf,mBAAmB;IACnB,cAAc;EAChB;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,cAAc;IACd,qBAAqB;IACrB,kCAAkC;;EAEpC","sourcesContent":[".not-found-container {\n    display: flex;\n    flex-direction: column;\n    height: 100vh;\n    align-items: center;\njustify-content: center;\n  }\n  \n  .not-found-icon {\n    font-size: 48px;\n    margin-bottom: 20px;\n    color: #f44336;\n  }\n  \n  .not-found-title {\n    font-size: 36px;\n    margin-bottom: 20px;\n  }\n  \n  .not-found-text {\n    font-size: 18px;\n    margin-bottom: 40px;\n  }\n  \n  .not-found-link {\n    font-size: 16px;\n    color: #2196f3;\n    text-decoration: none;\n    transition: color 0.2s ease-in-out;\n  \n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
