import axios from "axios";
import React, { useState, useEffect } from "react";
import {
  Tabs,
  Table,
  Image,
  Button,
  Modal,
  Select,
  Popconfirm,
  message,
  notification,
  Spin,
  Row,
  Col,
} from "antd";
import moment from "moment";
import { url } from "../../url/url";
import Papa from "papaparse";
import FileSaver from "file-saver";
import { Document, Page } from "react-pdf";
import {
  DeleteFilled,
  QuestionCircleOutlined,
  CheckCircleFilled,
  CloseCircleOutlined,
} from "@ant-design/icons";
const { Option } = Select;
const { TabPane } = Tabs;

function ClaimStatus(props) {
  const [loading, setLoading] = useState(false);
  const [EmployeeData, setEmployeeData] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const [visible, setVisible] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [Columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [pdfModalContent, setPdfModalContent] = useState([]);
  const [pdfModalVisible, setPdfModalVisible] = useState(false);
  const [selectedPdfIndex, setSelectedPdfIndex] = useState(0);
  const [current, setCurrent] = useState(1);
  const [filteredData, setFilteredData] = useState([]);
  const [pageSize, setPageSize] = useState(10); // Set an initial page size
  const [total, setTotal] = useState(EmployeeData.length); // Set the total based on your data
  const [selectedYear, setSelectedYear] = useState("");
  const currentDate = new Date();
  const currentyear = currentDate.getFullYear();
  const [year, setYear] = useState(currentyear.toString());

  // Handle page change
  const onPageChange = (newCurrent, newPageSize) => {
    setCurrent(newCurrent);
    setPageSize(newPageSize);
    // You may need to update the "total" based on your data or an API call here
  };
  useEffect(() => {
    fetchData();
  }, [year]);

  const fetchData = async () => {
    try {
      setLoading(true);
      console.log("claimstatus");
      const dbName = localStorage.getItem("dbName");
      console.log("dbName in fetchManager in empleaveapply", dbName);
      const response = await axios.get(
        `${url}/api/getUserManager/${user.displayName}/${dbName}`
      );
      const empCode = response.data.user.empCode;
      console.log(response, "empCode");
      const res = await axios.get(
        `${url}/Claim/status/${empCode}/${year}/${dbName}`
      );
      const currentYear = moment().year();
      const filteredData = res.data.filter(
        (item) => moment(item.dateOfApply).year() === currentYear
      );
      setEmployeeData(filteredData);
      setData(filteredData);
      setLoading(false);
      console.log("res claim", res.data);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  function handleOk() {
    const EmployeeData = data.map((row) =>
      selectedColumns.reduce((acc, col) => {
        acc[col] = row[col];
        return acc;
      }, {})
    );
    const csv = Papa.unparse(EmployeeData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    FileSaver.saveAs(blob, "Claims_Details.csv", "C:");
    setVisible(false);
    setColumns(Object.keys(data[0]));
  }

  function handleClick() {
    setVisible(true);
    setColumns(Object.keys(data[0]));
  }
  const handleYearChange = (value) => {
    setYear(value);
  };
  const currentYear = new Date().getFullYear();
  const yearsRange = Array.from(
    { length: 3 },
    (_, index) => currentYear - 1 + index
  );
  function handleCancel() {
    setVisible(false);
    setSelectedColumns([]);
  }
  function handleColumnSelect(value) {
    setSelectedColumns(value);
  }

  //
  function openPdfInModal(pdfDataUrls) {
    setPdfModalContent(pdfDataUrls);
    setPdfModalVisible(true);
  }

  function closePdfModal() {
    setPdfModalVisible(false);
    setSelectedPdfIndex(0);
  }

  function viewNextPdf() {
    if (selectedPdfIndex < pdfModalContent.length - 1) {
      setSelectedPdfIndex(selectedPdfIndex + 1);
    }
  }

  function viewPreviousPdf() {
    if (selectedPdfIndex > 0) {
      setSelectedPdfIndex(selectedPdfIndex - 1);
    }
  }
  function downloadPdf(pdfDataUrl) {
    const link = document.createElement("a");
    link.href = pdfDataUrl;
    link.download = "Claims Receipt.pdf"; // Specify the desired file name
    link.click();
  }
  //

  // Define a function to filter columns
  function filterColumns() {
    // List of fields you want to exclude
    const excludedFields = ["_id", "image", "__v"]; // Add the field names you want to exclude

    // Filter the columns to include only those not in the excludedFields list
    const columnsToInclude = Columns.filter(
      (col) => !excludedFields.includes(col)
    );

    return columnsToInclude;
  }

  const handleDelete = async (id) => {
    console.log(id);
    try {
      const dbName = localStorage.getItem("dbName");
      console.log("dbName in handleUpdate in project", dbName);

      const response = await axios.delete(
        `${url}/api/claimdelete/${id}/${dbName}`
      );
      console.log(response, "response delete");
      if (response.status === 200) {
        setEmployeeData(EmployeeData.filter((record) => record._id !== id));
        notification.success({ message: "Record deleted successfully!" });
      } else {
        // Handle other errors
        message.error("Failed to delete the record.");
      }
    } catch (error) {
      console.error(error);
      message.error("Failed to delete the record.");
    }
  };

  const columns = [
    {
      title: "S.NO",
      dataIndex: "globalSerialNumber",
      key: "index",
      width: 100,
      render: (text, record, index) => {
        const currentSerialNumber = index + 1 + pageSize * (current - 1);
        return <span>{currentSerialNumber}</span>;
      },
    },
    {
      title: "Request Date",
      dataIndex: "dateOfApply",
      key: "dateOfApply",
    },
    {
      title: "Expense Date",
      dataIndex: "dateOfExpense",
      key: "dateOfExpense",
    },
    {
      title: "Description",
      dataIndex: "expenseDescription",
      key: "expenseDescription",
    },
    {
      title: "Type",
      dataIndex: "claimType",
      key: "claimType",
    },
    {
      title: "Receipt",
      render: (_, record) => (
        <div>
          {record.images && record.images.length > 0 ? (
            <Button onClick={() => openPdfInModal(record.images)}>
              View Receipts
            </Button>
          ) : (
            <>No files</>
          )}
        </div>
      ),
    },

    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Action",
      dataIndex: "id",
      render: (_, record) => (
        <>
          {record.status === "submitted" ? (
            <Popconfirm
              title="Are you sure to delete this record?"
              onConfirm={() => handleDelete(record._id)} // Pass record._id here
              icon={
                <QuestionCircleOutlined
                  style={{
                    color: "red",
                  }}
                />
              }
              okText="Yes"
              cancelText="No"
            >
              <Button style={{ padding: "6px", fontSize: "12px" }}>
                <DeleteFilled />
              </Button>
            </Popconfirm>
          ) : record.status === "Denied by Manager" ||
            record.status === "Denied by HR" ? (
            <CloseCircleOutlined style={{ color: "red" }} />
          ) : record.status === "Approved" ? (
            <CheckCircleFilled style={{ color: "Green" }} />
          ) : null}
        </>
      ),
    },
  ];

  return (
    <div style={{ transform: "scale(0.9)", transformOrigin: "0 0" }}>
      {loading ? (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <Tabs defaultActiveKey="1" centered>
            <TabPane
              tab={
                <span style={{ color: "black", fontSize: "20px" }}>
                  My Expense Report
                </span>
              }
            >
              <Row>
              <Col span={10}>
                <Button className="button" type="primary" onClick={handleClick}>
                  Export to CSV
                </Button>
                </Col>
                <Col span={14}>
                <Select
                  placeholder="Select Year"
                  onChange={handleYearChange}
                  style={{ width: 130, height: 35 }}
                  value={selectedYear || undefined}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                >
                  {yearsRange.map((year) => (
                    <Select.Option key={year} value={year}>
                      {year}
                    </Select.Option>
                  ))}
                </Select>
                </Col>
              </Row>
              <Modal
                title="Export to CSV"
                visible={visible}
                onOk={handleOk}
                onCancel={handleCancel}
                okButtonProps={{ disabled: selectedColumns.length === 0 }}
              >
                <p>Select columns to export:</p>
                <Select
                  mode="multiple"
                  style={{ width: "100%" }}
                  placeholder="Please select"
                  value={selectedColumns}
                  onChange={handleColumnSelect}
                >
                  {filterColumns().map((col) => (
                    <Option key={col} value={col}>
                      {col}
                    </Option>
                  ))}
                </Select>
              </Modal>
              <Modal
                title="Documents"
                visible={pdfModalVisible}
                onCancel={closePdfModal}
                footer={null}
                width={1000}
              >
                {pdfModalContent.length > 0 ? (
                  <>
                    <div style={{ textAlign: "center" }}>
                      <Button
                        disabled={selectedPdfIndex === 0}
                        onClick={viewPreviousPdf}
                        style={{ marginRight: "10px" }}
                      >
                        Previous
                      </Button>
                      <span>
                        Document {selectedPdfIndex + 1} of{" "}
                        {pdfModalContent.length}
                      </span>
                      <Button
                        disabled={
                          selectedPdfIndex === pdfModalContent.length - 1
                        }
                        onClick={viewNextPdf}
                        style={{ marginLeft: "10px" }}
                      >
                        Next
                      </Button>
                    </div>
                    <iframe
                      src={pdfModalContent[selectedPdfIndex]}
                      title="Document"
                      style={{ width: "100%", height: "500px" }}
                    />
                  </>
                ) : (
                  <>No PDF documents available</>
                )}
              </Modal>
              <div style={{ width: "110%", marginTop: "1vh" }}>
                <Table
                  className="custom-table col-lg-12 col-md-12 col-sm-12"
                  dataSource={EmployeeData}
                  sticky
                  columns={columns}
                  pagination={{
                    current: current,
                    pageSize: pageSize,
                    total: total,
                    onChange: onPageChange,
                  }}
                  bordered={false}
                  scroll={{ x: true }}
                  rowKey={(record) => record.id}
                />
              </div>
            </TabPane>
          </Tabs>
        </div>
      )}
    </div>
  );
}

export default ClaimStatus;
