import React, { useState, useEffect } from "react";
import axios from "axios";
import { url } from "../../url/url";
import { Link } from "react-router-dom";
import { Button } from "antd";
import { useParams } from "react-router-dom";
import "./BGV.css"; 
import "../../images/BGV.jpeg";

const BGVHome = () => {
  const { name, token1,dbName,email } = useParams();
  const [accessToken, setAccessToken] = useState("");
  const Name = name.toUpperCase();
  useEffect(() => {
    fetchData(); 
  }, [token1]); 

  const fetchData = async () => {
    try {
      const response = await axios.get(`${url}/get/accessToken/${token1}`);

      if (response.data.message === "Success") {
        setAccessToken("success");
        localStorage.setItem("accessToken",token1);
        localStorage.setItem("dbName",dbName);
        localStorage.setItem("email",email);
      } else {
        console.error("Token not found.");
        setAccessToken("error");
      }
    } catch (error) {
      console.error("Error fetching access token:", error.message);
      setAccessToken("error");
    }
  };

  return (
    <div className="bgv-container">
      <div className="bgv-content">
        <h1 className="bgv-title">Welcome to Finaran</h1>
        <h2>{Name}</h2>
        <p>Please fill the following details for verification</p>
        {accessToken === "success" ? (
          <Link to="/bgv-form" className="bgv-link">
            Click here
          </Link>
        ) : accessToken === "error" ? (
          <p className="bgv-error">
            Your link has expired or there was an issue. Please request a new
            link.
          </p>
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </div>
  );
};

export default BGVHome;
