import React from "react";
import RouterPage from "./Routes";
import LocationComponent from "./data";
import Stopwatch from "./stopwatch";
import APICalls from "./stop";
import ColorPicker from "./components/color/color";
import { MenuProvider } from "./Menuprovider";

function NetworkError() {
  return (
    <div>
      <h1>Network Error</h1>
      <p>Please check your internet connection and try again.</p>
    </div>
  );
}

function App() {
  const [isOnline, setIsOnline] = React.useState(navigator.onLine);
  

  
  React.useEffect(() => {
    window.addEventListener("offline", handleOffline);
    window.addEventListener("online", handleOnline);

    return () => {
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("online", handleOnline);
    };
  }, []);

  function handleOffline() {
    setIsOnline(false);
  }

  function handleOnline() {
    setIsOnline(true);
  }

  if (!isOnline) {
    return <NetworkError />;
  }

  return (
    <MenuProvider>
      <RouterPage /> 
    </MenuProvider>
  );
}

export default App;
