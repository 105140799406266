import React, { useState, useEffect } from "react";
import { message, Select, Table, Tag, Spin, Button, Modal } from "antd";
import { url } from "../../url/url";
import axios from "axios";
import Papa from "papaparse";
import FileSaver from "file-saver";
import moment from "moment";
const { Option } = Select;

const EmployeeComplianceReport = () => {
  const users = JSON.parse(localStorage.getItem("user"));
  const [user] = useState(users);
  const currentYr = new Date().getFullYear();
  const lastYear = currentYr - 1;
  const [selectedYear, setSelectedYear] = useState(currentYr);
  const [selectedWeekPeriod, setSelectedWeekPeriod] = useState(null);
  const [loading, setLoading] = useState(false);
  const [employeeComplianceData, setEmployeeComplianceData] = useState([]);
  const [table, setTable] = useState([]);
  const [weekPeriods, setWeekPeriods] = useState(null);
  const [showAllReports, setShowAllReports] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [Columns, setColumns] = useState([]);
  const [visible, setVisible] = useState(false);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [total, setTotal] = useState(table.length);

  const onPageChange = (newCurrent, newPageSize) => {
    setCurrent(newCurrent);
    setPageSize(newPageSize);
  };

  let data = [];

  useEffect(() => {
    fetchtable();
  }, [showAllReports]);
  const handleYearChange = (year) => {
    setSelectedYear(year);
    setSelectedWeekPeriod(null);
    // Generate a list of week periods (Monday to Sunday) for the selected year
    const weekPeriods = generateWeekPeriods(year);
    setAvailableWeekPeriods(weekPeriods);
  };

  const handleWeekIDChange = (value) => {
    // Set the selected week period as an array
    setWeekPeriods([value]);
    fetchEmployeeComplianceData(value);
  };
  const fetchtable = async () => {
    setLoading(true);
    try {
      // Reset employeeComplianceData to an empty array
      if (!showAllReports) {
        setEmployeeComplianceData([]);
        console.log("inside if");
        // Fetch manager reportee details
        const dbName = localStorage.getItem("dbName");
        const response = await fetch(
          `${url}/timesheet/getreportgen/${user.displayName}/manager/${dbName}`
        );
        const table = await response.json();
        setTable(table);
        console.log(table, "table");
        setLoading(false);
      } else if (showAllReports && user.jobTitle === "hr") {
        setEmployeeComplianceData([]);
        console.log("inside else");
        // Fetch all reports
        const dbName = localStorage.getItem("dbName");
        const response = await fetch(
          `${url}/timesheet/getreportgen/${user.displayName}/hr/${dbName}`
        );
        const table = await response.json();
        setTable(table);
        setLoading(false);
      } else if (showAllReports && user.jobTitle === "admin") {
        setEmployeeComplianceData([]);
        console.log("inside else");
        // Fetch all reports
        const dbName = localStorage.getItem("dbName");
        const response = await fetch(
          `${url}/timesheet/getreportgen/${user.displayName}/admin/${dbName}`
        );
        const table = await response.json();
        setTable(table);
        setLoading(false);
      }
      // Extract and set week periods
      const weekPeriods = table.map((record) => ({
        weekPeriod: `${record.weekStartingDate} - ${record.weekEndingDate}`,
        startDate: new Date(
          record.weekStartingDate
            .split("-")
            .reverse()
            .join("-")
        ),
      }));

      // Sort weekPeriods based on startDate
      weekPeriods.sort((a, b) => a.startDate - b.startDate);

      // Map the sorted weekPeriods back to an array of weekPeriods
      const sortedWeekPeriods = weekPeriods.map((record) => record.weekPeriod);

      setWeekPeriods(sortedWeekPeriods);
    } catch (error) {
      console.error(error);
      message.error("Failed to fetch data from the server.");
      setLoading(false);
    }
  };
  const fetchEmployeeComplianceData = async (selectedWeekPeriod) => {
    if (!selectedWeekPeriod) {
      return; // Do not fetch data if no week ID is selected
    }
    if (!showAllReports) {
      // Fetch the list of reportees from your API
      const dbName = localStorage.getItem("dbName");
      const response = await axios.get(
        `${url}/api/get/emplist/${user.displayName}/manager/${dbName}`
      );
      const reportees = response.data.map((reportee) => reportee);

      // Filter the table data based on the selectedWeekPeriod
      const filteredData = table.filter((record) => {
        const weekStart = record.weekStartingDate;
        const weekEnd = record.weekEndingDate;
        const weekPeriod = `${weekStart} - ${weekEnd}`;
        return weekPeriod === selectedWeekPeriod;
      });

      // Extract status and userName from the filtered data
      const status = filteredData.map((record) => record.status);
      const submittedDate = filteredData.map((record) => record.submittedDate);
      const approvalDate = filteredData.map((record) => record.approvalDate);
      const empName = filteredData.map((record) => record.userName);
      const manager = filteredData.map((record) => record.reportingManager);

      // Iterate through reportees
      reportees.forEach((reportee) => {
        // Check if the reportee's name doesn't exist in the empName array
        if (reportee.empStatus === "active") {
          if (!empName.includes(reportee.displayName)) {
            // Append the reportee's name to empName and set their status to "Pending"
            empName.push(reportee.displayName);
            status.push("Pending");
            manager.push(reportee.manager);
            submittedDate.push("");
            approvalDate.push("");
          }
        }
      });
      // Now you can update the employeeComplianceData state
      setEmployeeComplianceData({
        status,
        empName,
        manager,
        submittedDate,
        approvalDate,
      });
    } else if (showAllReports && user.jobTitle === "hr") {
      console.log("inside else");
      // Fetch the list of reportees from your API
      const dbName = localStorage.getItem("dbName");
      const response = await axios.get(
        `${url}/api/get/emplist/${user.displayName}/hr/${dbName}`
      );
      const reportees = response.data.map((reportee) => reportee);

      // Filter the table data based on the selectedWeekPeriod
      const filteredData = table.filter((record) => {
        const weekStart = record.weekStartingDate;
        const weekEnd = record.weekEndingDate;
        const weekPeriod = `${weekStart} - ${weekEnd}`;
        return weekPeriod === selectedWeekPeriod;
      });

      // Extract status and userName from the filtered data
      const status = filteredData.map((record) => record.status);
      const submittedDate = filteredData.map((record) => record.submittedDate);
      const approvalDate = filteredData.map((record) => record.approvalDate);
      const empName = filteredData.map((record) => record.userName);
      const manager = filteredData.map((record) => record.reportingManager);

      // Iterate through reportees
      reportees.forEach((reportee) => {
        if (reportee.empStatus === "active") {
          // Check if the reportee's name doesn't exist in the empName array
          if (!empName.includes(reportee.displayName)) {
            // Append the reportee's name to empName and set their status to "Pending"
            empName.push(reportee.displayName);
            status.push("Pending");
            manager.push(reportee.manager);
            submittedDate.push("");
            approvalDate.push("");
          }
        }
      });

      // Now you can update the employeeComplianceData state
      setEmployeeComplianceData({
        status,
        empName,
        manager,
        submittedDate,
        approvalDate,
      });
    } else if (showAllReports && user.jobTitle === "admin") {
      // Fetch the list of reportees from your API
      const dbName = localStorage.getItem("dbName");
      const response = await axios.get(`${url}/api/get/allemplist/${dbName}`);
      const reportees = response.data.map((reportee) => reportee);

      // Filter the table data based on the selectedWeekPeriod
      const filteredData = table.filter((record) => {
        const weekStart = record.weekStartingDate;
        const weekEnd = record.weekEndingDate;
        const weekPeriod = `${weekStart} - ${weekEnd}`;
        return weekPeriod === selectedWeekPeriod;
      });

      // Extract status and userName from the filtered data
      const status = filteredData.map((record) => record.status);
      const submittedDate = filteredData.map((record) => record.submittedDate);
      const approvalDate = filteredData.map((record) => record.approvalDate);
      const empName = filteredData.map((record) => record.userName);
      const manager = filteredData.map((record) => record.reportingManager);

      // Iterate through reportees
      reportees.forEach((reportee) => {
        if (reportee.empStatus === "active") {
          // Check if the reportee's name doesn't exist in the empName array
          if (!empName.includes(reportee.displayName)) {
            // Append the reportee's name to empName and set their status to "Pending"
            empName.push(reportee.displayName);
            status.push("Pending");
            manager.push(reportee.manager);
            submittedDate.push("DD-MM-YYYY");
            approvalDate.push("DD-MM-YYYY");
          }
        }
      });

      // Now you can update the employeeComplianceData state
      setEmployeeComplianceData({
        status,
        empName,
        manager,
        submittedDate,
        approvalDate,
      });
    }
  };
  // Function to generate week periods for a given year
  const generateWeekPeriods = (selectedYear) => {
    const weekPeriods = [];
    let startDate = new Date(selectedYear, 0, 1); // January 1st of the selected year
    const endDate = new Date(selectedYear, 11, 31); // December 31st of the selected year

    while (startDate <= endDate) {
      const weekStart = new Date(startDate);
      const weekEnd = new Date(startDate);

      // Set the start of the week to Monday (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
      weekStart.setDate(startDate.getDate() - startDate.getDay() + 1);

      // Calculate the end of the week (Sunday)
      weekEnd.setDate(weekStart.getDate() + 6);

      const formattedWeekPeriod = `${formatDate(weekStart)} - ${formatDate(
        weekEnd
      )}`;
      weekPeriods.push(formattedWeekPeriod);

      // Move to the next week
      startDate = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate() + 7
      );
    }

    return weekPeriods;
  };

  // Function to format a date as dd-mm-yyyy
  const formatDate = (date) => {
    const day = date
      .getDate()
      .toString()
      .padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  // Step 3: Provide a range of years for selection
  const currentYear = new Date().getFullYear();
  const yearsRange = Array.from(
    { length: 2 },
    (_, index) => currentYear - index
  );

  const uniqueWeekId = [...new Set(weekPeriods)];
  const [availableWeekPeriods, setAvailableWeekPeriods] = useState(
    uniqueWeekId
  );

  if (
    employeeComplianceData &&
    employeeComplianceData.empName &&
    employeeComplianceData.status &&
    employeeComplianceData.submittedDate &&
    employeeComplianceData.approvalDate &&
    employeeComplianceData.manager
  ) {
    data = employeeComplianceData.empName.map((empName, index) => ({
      userName: empName,
      reportingManager: employeeComplianceData.manager[index],
      status: employeeComplianceData.status[index],
      submittedDate: employeeComplianceData.submittedDate[index]  || null,
      approvalDate: employeeComplianceData.approvalDate[index]  || null,
    }));
  }
  const uniqueManager = Array.from(
    new Set(data.map((data) => data.reportingManager))
  );
  const uniqueName = Array.from(new Set(data.map((data) => data.userName)));
  const uniqueStatus = Array.from(new Set(data.map((data) => data.status)));

  const columns = [
    {
      title: "Employee Name",
      dataIndex: "userName",
      key: "userName",
      filters: uniqueName.map((userName) => ({
        text: userName,
        value: userName,
      })),
      onFilter: (value, record) => record.userName === value,
    },
    {
      title: "Manager",
      dataIndex: "reportingManager",
      key: "reportingManager",
      filters: uniqueManager.map((reportingManager) => ({
        text: reportingManager,
        value: reportingManager,
      })),
      onFilter: (value, record) => record.reportingManager === value,
    },
    {
      title: "Submission Status",
      dataIndex: "status",
      key: "status",
      render: (status) => <Tag color={getStatusColor(status)}>{status}</Tag>,
      filters: uniqueStatus.map((status) => ({
        text: status,
        value: status,
      })),
      onFilter: (value, record) => record.status === value,
    },
    {
      title: "Submitted Date",
      dataIndex: "submittedDate",
      key: "submittedDate",
      render: (text) => (text ? moment(text).format("DD-MM-YYYY") : "No date available"),
    },
    
    {
      title: "Approval Date",
      dataIndex: "approvalDate",
      key: "approvalDate",
      render: (text) => (text ? moment(text).format("DD-MM-YYYY") : "No date available"),
    },
  ];

  const getStatusColor = (status) => {
    switch (status) {
      case "Pending":
        return "orange";
      case "Draft":
        return "yellow";
      case "Approved":
        return "green";
      case "Rejected":
        return "red";
      case "Submitted":
        return "blue";
      default:
        return "defaultColor"; // You can define a default color here
    }
  };

  const toggleShowAllReports = () => {
    setShowAllReports(!showAllReports);
  };

  function handleClick() {
    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    FileSaver.saveAs(blob, "EmployeeComplianceReport.csv", "C:");
  }

  return (
    <div
      style={{ transform: "scale(0.9)", transformOrigin: "0 0", width: "110%" }}
    >
      {loading ? (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <>
          <Select
            placeholder="Select Year"
            onChange={handleYearChange}
            style={{ width: 250 }}
            value={selectedYear}
          >
            {yearsRange.map((year) => (
              <Select.Option key={year} value={year}>
                {year}
              </Select.Option>
            ))}
          </Select>

          <Select
            placeholder="Select Week Period"
            onChange={handleWeekIDChange}
            style={{ width: 250, marginLeft: "20px" }}
          >
            {availableWeekPeriods.map((WeekPeriod) => (
              <Select.Option key={WeekPeriod} value={WeekPeriod}>
                {WeekPeriod}
              </Select.Option>
            ))}
          </Select>
          <Button
            type="primary"
            onClick={handleClick}
            style={{ margin: "10px" }}
          >
            Export
          </Button>
          <div>
            <label
              style={{
                display: "flex",
                justifyContent: "flex-end",
                fontSize: "14px",
              }}
            >
              <input
                type="checkbox"
                checked={showAllReports}
                onChange={toggleShowAllReports}
              />
              Show All Employee
            </label>
          </div>
          {/* Step 5: Update the table with filtered and appended data */}
          <Table
            className="custom-table col-lg-12 col-md-12 col-sm-12"
            columns={columns}
            dataSource={data}
            pagination={{
              current: current,
              pageSize: pageSize,
              total: total,
              onChange: onPageChange,
            }}
            sticky
          />
        </>
      )}
    </div>
  );
};

export default EmployeeComplianceReport;
