import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  Form,
  Col,
  Row,
  Input,
  Button,
  message,
  notification,
  Select,
  Descriptions,
  Radio,
} from "antd";
import { url } from "../../url/url";
const { Option } = Select;

const ManageDB = () => {
  const columns = [
    {
      title: "Database Name",
      dataIndex: "dbName",
      key: "dbName",
    },
    {
      title: "Collections",
      dataIndex: "collections",
      key: "collections",
      render: (collections) => (
        <ul>
          {collections.map((collectionName) => (
            <li key={collectionName}>{collectionName}</li>
          ))}
        </ul>
      ),
    },
  ];

  const [formData, setFormData] = useState({
    dbName: "",
    tenantId: "",
    clientId: "",
    clientSecret: "",
    userType: "",
    domainName: "",
    tenantName: "",
  });
  const [selectedDatabase, setSelectedDatabase] = useState("");
  const [databases, setDatabases] = useState([]);
  const [userTypeSelected, setUserTypeSelected] = useState(false);
  const [selectedDbDetails, setSelectedDbDetails] = useState(null);

  const fetchDatabases = async () => {
    try {
      const response = await axios.get(`${url}/db/list-details`);
      const { data } = response; // Assuming the API response contains the data directly
      // console.log('API Response:', response.data);
      if (data) {
        setDatabases(data); // Set the databases state to all retrieved databases
      }
    } catch (error) {
      console.error("Error fetching databases:", error);
    }
  };

  console.log(userTypeSelected);
  useEffect(() => {
    fetchDatabases();
  }, []);

  const handleDatabaseChange = (value) => {
    setSelectedDatabase(value);
    const selectedDb = databases.find((item) => item.dbName === value);
    setSelectedDbDetails(selectedDb);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onFinish = () => {
    axios
      .post(`${url}/api/masterorg`, formData)
      .then((response) => {
        console.log(response.data.message);
        // Show success message using antd message component
        notification.success(
          `Organization name '${formData.dbName}' and other details created successfully.`
        );
        // Clear the form after successful submission
        setFormData({
          dbName: "",
          tenantName: "",
          tenantId: "",
          clientId: "",
          clientSecret: "",
          userType: "",
          domainName: "",
        });
        setUserTypeSelected(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        // Show error message using antd message component
        message.error("An error occurred. Please try again.");
      });
  };

  const onFormChange = (changedValues, allValues) => {
    setFormData(allValues);
  };

  const formRef = React.createRef();

  return (
    <Row
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "5vh",
        zoom: "90%",
      }}
    >
      <Col>
        <Form onValuesChange={onFormChange} ref={formRef}>
          <Col style={{ display: "flex", alignItems: "center" }}>
            <Form.Item
              label="Organization Name"
              name="dbName"
              rules={[
                {
                  required: true,
                  message: "Please input organization name!",
                  pattern: /^[a-zA-Z0-9\s]+$/,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="User Type" name="userType">
              <Radio.Group onChange={() => setUserTypeSelected(true)}>
                <Radio value="false">Native User</Radio>
                <Radio value="true">Cloud User</Radio>
              </Radio.Group>
            </Form.Item>

            {formData.userType === "true" && (
              <>
                <Form.Item label="Tenant Name" name="tenantName">
                  <Input
                    onChange={handleChange}
                    value={formData.tenantName}
                    name="tenantName"
                  />
                </Form.Item>
                <Form.Item label="Tenant ID" name="tenantId">
                  <Input
                    onChange={handleChange}
                    value={formData.tenantId}
                    name="tenantId"
                  />
                </Form.Item>
                <Form.Item label="Client ID" name="clientId">
                  <Input
                    onChange={handleChange}
                    value={formData.clientId}
                    name="clientId"
                  />
                </Form.Item>
                <Form.Item label="Client Secret" name="clientSecret">
                  <Input
                    onChange={handleChange}
                    value={formData.clientSecret}
                    name="clientSecret"
                  />
                </Form.Item>
              </>
            )}
          </Col>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ display: "flex", justifyContent: "end" }}
              onClick={onFinish}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Col>
      <Col>
        <Col>
          <p>Select a Database:</p>
          <Select
            style={{ width: 200 }}
            placeholder="Select a database"
            value={selectedDatabase}
            onChange={handleDatabaseChange}
          >
            {databases.map((item) => (
              <Option key={item.dbName} value={item.dbName}>
                {item.dbName}
              </Option>
            ))}
          </Select>
        </Col>
      </Col>
      <Col>
        {selectedDbDetails && selectedDbDetails.collections && (
          <Table
            className="custom-table col-lg-12 col-md-12 col-sm-12"
            columns={columns}
            dataSource={[
              {
                key: selectedDbDetails.dbName,
                dbName: selectedDbDetails.dbName,
                collections: selectedDbDetails.collections,
              },
            ]}
            pagination={false}
            sticky
          />
        )}
      </Col>
    </Row>
  );
};

export default ManageDB;
