import React, { useState, useEffect } from "react";
import { Table, Container, Row, Col } from "react-bootstrap";
import {
  Button,
  Input,
  message,
  notification,
  Modal,
  Alert,
  Tooltip,
  Checkbox,
  Descriptions,
} from "antd";
import { url } from "../../url/url";
import { useNavigate, useParams, Link, useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";

// Rest of your code

import { CloseOutlined } from "@ant-design/icons";
function Userapprove(props) {
  const { Id, userName } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const empCode = queryParams.get("empCode");
  const weekId = queryParams.get("weekId");
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const user = JSON.parse(localStorage.getItem("user"));
  const [weekend, setWeekend] = useState([]);
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [showmodal, setShowmodal] = useState(false);
  const [checkInOutData, setCheckInOutData] = useState([]);
  const [locationdata, setLocationdata] = useState([]);
  const [selectedDay, setSelectedDay] = useState(null);
  const [startend, setStartend] = useState([]);

  // Helper function to parse a date in "DD/MM/YYYY HH:MM:SS" format into a Date object
  const parseDate = (dateString) => {
    const [datePart, timePart] = dateString.split(" ");
    const [day, month, year] = datePart.split("/");
    const [hours, minutes, seconds] = timePart.split(":");
    // Note: months are 0-based in JavaScript Date constructor
    return new Date(year, month - 1, day, hours, minutes, seconds);
  };

  const calculateActualHours = (checkInTime, checkOutTime) => {
    const checkInDate = parseDate(checkInTime);
    const checkOutDate = parseDate(checkOutTime);

    const diffInMilliseconds = checkOutDate - checkInDate;

    // Calculate hours and minutes separately
    const hours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
    const remainingMilliseconds = diffInMilliseconds % (1000 * 60 * 60);
    const minutes = Math.floor(remainingMilliseconds / (1000 * 60));

    const formattedHours = String(hours);
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedResult = `${formattedHours}:${formattedMinutes}`;

    return formattedResult;
  };

  const renderActualHours = (startDate) => {
    const checkInOutEntries = checkInOutData;
    console.log(checkInOutEntries, "checkiii");
    const formattedStartDate = startDate.replace(/-/g, "/");

    const checkInOutEntry = checkInOutEntries?.find((entry) => {
      const [datePart] = entry.checkInTime.split(" ");
      return datePart === formattedStartDate;
    });

    if (checkInOutEntry && checkInOutEntry.checkOutTime) {
      const checkInDate = checkInOutEntry.checkInTime.split(" ")[0];
      const checkOutDate = checkInOutEntry.checkOutTime.split(" ")[0];

      // Compare if the dates are the same
      if (checkInDate === checkOutDate) {
        return calculateActualHours(
          checkInOutEntry.checkInTime,
          checkInOutEntry.checkOutTime
        );
      } else {
        return "0";
      }
    }
    return "0";
  };

  const Weekend = async () => {
    try {
      const dbName = localStorage.getItem("dbName");
      const response = await axios.get(`${url}/get/combined/${dbName}`);
      setWeekend(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const GetHolidays = async () => {
    try {
      const dbName = localStorage.getItem("dbName");
      const response = await axios.get(`${url}/get/combined/${dbName}`);
      const data = response.data[0]?.officeHolidaysData;

      const formattedEvents = data.map((date) => {
        const [day, month, year] = date.date.split("/");
        const isoDate = `${day}-${month}-${year}`;

        return isoDate; // Return the ISO date string
      });

      setCalendarEvents(formattedEvents);
    } catch (error) {
      console.error(error);
    }
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    //setInputValue(''); // Reset the input value
  };

  // Assuming 'daysOfWeek' and 'data.weekStartingDate' are available
  const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  const renderDays = () => {
    if (!data.weekStartingDate) return [];
    const startingDate = moment(data.weekStartingDate, "DD-MM-YYYY");
    if (!startingDate.isValid()) return [];

    const days = [];

    for (let i = 0; i < 7; i++) {
      const currentDate = startingDate.clone().add(i, "days");
      const formattedDate = currentDate.format("ddd DD");
      days.push({ currentDate, formattedDate });
    }

    return days.map(({ currentDate, formattedDate }, index) => {
      const isHoliday = isDateInCalendarEvents(
        currentDate.format("DD-MM-YYYY"),
        calendarEvents
      );
      const isSaturdayOff =
        (index === 5 &&
          weekend.length > 0 &&
          weekend[0].holidayType === "satoff") ||
        (index === 4 &&
          weekend.length > 0 &&
          weekend[0].holidayType === "frioff") ||
        (index === 6 &&
          weekend.length > 0 &&
          weekend[0].holidayType === "sunoff") ||
        ((index === 4 || index === 5) &&
          weekend.length > 0 &&
          weekend[0].holidayType === "frisatoff") ||
        ((index === 5 || index === 6) &&
          weekend.length > 0 &&
          weekend[0].holidayType === "satsunoff");
      {
        console.log(isSaturdayOff, "isSaturdayOff");
      }
      const style = isSaturdayOff ? sunCellStyle : null;
      const holidayStyle = isHoliday ? holidayCellStyle : null;
      const otherCellStyle =
        !isSaturdayOff && !isHoliday ? { backgroundColor: "#C0D6E8" } : null;

      return (
        <th
          key={index}
          style={{ ...style, ...holidayStyle, ...otherCellStyle }}
          className="column"
        >
          {formattedDate}
        </th>
      );
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("Input value:", inputValue);
    if (inputValue === "") {
      message.error("Please enter the reason");
    } else {
      try {
        const dbName = localStorage.getItem("dbName");
        const response = await axios.put(
          `${url}/timesheet/approve/${data._id}/${dbName}`,
          {
            status: "Rejected",
            reason: inputValue,
            email: data.email,
          }
        );
        handleCancel();
        notification.success({ message: "Rejected  successfully!" });
        navigate("/home/pending");
      } catch (error) {
        console.error(error);
        handleCancel();
        message.error("Failed to reject");
      }
    }
  };

  const handleChange = (event) => {
    const inputValue = event.target.value;

    const alphanumericWithSpaces = /^[a-zA-Z0-9 ]*$/;

    if (alphanumericWithSpaces.test(inputValue)) {
      setInputValue(inputValue);
    } else if (inputValue.trim() === "") {
      message.error("Enter valid input");
    } else {
      message.error("Enter valid input");
    }
  };

  useEffect(() => {
    fetchdata();
    GetHolidays();
    Weekend();
    fetchLocation();
    fetchCheckInOutData();
  }, [Id]);

  const fetchdata = async () => {
    console.log("start");
    const dbName = localStorage.getItem("dbName");

    try {
      const response = await fetch(`${url}/timesheet/get/${Id}/${dbName}`);
      const fetchedData = await response.json();
      setData(fetchedData); // Update the state with the fetched data

      // Parse the weekStartingDate as a moment object
      let startDate = moment(fetchedData.weekStartingDate, "DD-MM-YYYY");

      const updatedStartend = [];
      for (let i = 0; i < 7; i++) {
        updatedStartend.push(
          <div key={i}>{startDate.format("DD-MM-YYYY")}</div>
        );
        startDate.add(1, "day");
      }
      setStartend(updatedStartend);
      console.log(updatedStartend, "startens");
    } catch (error) {
      console.error(error);
    }
  };

  const fetchCheckInOutData = async () => {
    const dbName = localStorage.getItem("dbName");
    try {
      const response = await fetch(`${url}/checkinout/${dbName}/${userName}`);
      if (!response.ok) {
        throw new Error("Failed to fetch check-in/out data");
      }
      const data = await response.json();
      console.log(data, "data");
      setCheckInOutData(data.checkInOutEntries);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const showModal1 = () => {
    setModal(true);
  };
  const handleOk1 = () => {
    setModal(false);
  };
  const handleCancel1 = () => {
    setModal(false);
    //setInputValue(''); // Reset the input value
  };
  const fetchLocation = async () => {
    const dbName = localStorage.getItem("dbName");
    try {
      console.log(empCode, weekId, "id");
      const response = await axios.get(
        `${url}/get/employee/location/${empCode}/${weekId}/${dbName}`
      );
      const data = await response.data.address[0].data;
      setLocationdata(data);
      console.log(data, "Location");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const Approve = async (id) => {
    try {
      const dbName = localStorage.getItem("dbName");
      const response = await axios.put(
        `${url}/timesheet/approve/${id}/${dbName}`,
        {
          status: "Approved",
          email: data.email,
        }
      );
      notification.success({ message: "Approved  successfully!" });
      navigate("/home/pending");
    } catch (error) {
      console.error(error);
      message.error("Failed to Approve");
    }
  };

  const columnTotals = {};

  for (let dayIndex = 1; dayIndex <= 7; dayIndex++) {
    const dayKey = `day${dayIndex}`;
    columnTotals[dayKey] = data?.entrydata?.reduce(
      (total, entry) => total + parseFloat(entry[dayKey]?.hours || 0), // Handle cases where the value is not available
      0
    );
  }
  const dayIndex1 = 0;

  const goBack = () => {
    navigate(-1);
  };

  const sunCellStyle = {
    backgroundColor: "#e6ccff", // Yellow background color
  };

  const holidayCellStyle = {
    backgroundColor: "#ffe6e6",
  };

  let dayStartDate;

  function isDateInCalendarEvents(day, calenderevents) {
    console.log(day, "dayssis");
    if (day) {
      const dayText = day; // Assuming day is an object with props.children as the date
      return calenderevents.includes(dayText);
    } else {
      // Handle the case where day or its properties are undefined
      return false;
    }
  }
  const submissionDate = moment(data.submittedDate).format("DD-MM-YYYY");
  return (
    <Container fluid className="p-1" style={{ zoom: "80%" }}>
      <Button onClick={goBack}>Back</Button>
      <Modal
        title="Reason for reject"
        open={isModalOpen}
        onOk={handleSubmit}
        onCancel={handleCancel}
      >
        <form onSubmit={handleSubmit}>
          <Input
            type="text"
            value={inputValue}
            onChange={handleChange}
            placeholder="Enter Reason"
            required
          />
        </form>
      </Modal>

      <Modal
        width={800}
        visible={selectedDay !== null}
        onCancel={() => setSelectedDay(null)}
        footer={null}
      >
        <>
          <Descriptions title="User Info" bordered>
            <Descriptions.Item label="UserName">
              {data.userName}
            </Descriptions.Item>
            <Descriptions.Item label="ID">{empCode}</Descriptions.Item>
            <Descriptions.Item
              label="Location Accessed Date"
              span={3}
            ></Descriptions.Item>
            <Descriptions label={`Location as of Day ${selectedDay}`} span={5}>
              {selectedDay && (
                <p>
                  {locationdata
                    .find((obj) => obj.date === selectedDay)
                    ?.location.map((location, index) => (
                      <ul key={index}>{location}</ul>
                    ))}
                </p>
              )}
            </Descriptions>
          </Descriptions>
        </>
      </Modal>
      <Row
        style={{ display: "flex", justifyContent: "center", padding: "20px" }}
      >
        <div
          className="col-content"
          style={{
            display: "flex",
            alignItems: "center",
            padding: "8px",
            borderRadius: "10px",
            background: "#001233",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "10px",
              marginRight: "10px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                style={{
                  backgroundColor: "#e6e6e6",
                  borderRadius: "5px",
                  padding: "8px",
                  marginRight: "10px",
                }}
              >
                {data.weekStartingDate}
              </div>
              <div
                style={{
                  backgroundColor: "#e6e6e6",
                  borderRadius: "5px",
                  padding: "8px",
                }}
              >
                {data.weekEndingDate}
              </div>
            </div>
          </div>
        </div>
      </Row>
      <Row>
        <Col
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingBottom: "20px",
          }}
        >
          <Alert message={`User Name :  ${data.userName}`} type="info" />
          <Alert message={`Status :  ${data.status}`} type="info" />
        </Col>
      </Row>
      <Row>
        <Col
          style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingBottom: "20px",
          }}
        >
          <Alert message={`Submitted on ${submissionDate}`} type="info" />
        </Col>
      </Row>
      <Row>
        <Col>
          <Table responsive striped bordered hover>
            <thead>
              <tr>
                <th className="column-project">Project</th>
                <th className="column-task">Task</th>
                <th className="column-task">Activity</th>
                <th className="column-task">Billable</th>
                {renderDays()}
                <th className="column-total-hours">Total</th>

                <th className="column-comments">Comments</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan="4">Location</td>
                {startend.map((day, index) => {
                  const locationDetails = locationdata.find(
                    (obj) => obj.date === day.props.children
                  );
                  const locations = locationDetails
                    ? locationDetails.location
                    : [];
                  const latestLocation = locations[locations.length - 1];

                  return (
                    <td key={index}>
                      <div>
                        {locations.length > 0 && ( // Only render the image if there are locations
                          <a
                            href="#"
                            onClick={() => setSelectedDay(day.props.children)}
                            title={latestLocation} // Show the latest location on hover
                          >
                            <img
                              src={require("../../images/map.png")}
                              alt="Location Icon"
                              style={{ width: "24px", height: "24px" }} // Adjust size as needed
                            />
                          </a>
                        )}
                      </div>
                    </td>
                  );
                })}
              </tr>
              {data.entrydata &&
                data.entrydata.map((entry, index) => (
                  <tr key={entry._id}>
                    <td>{entry.project}</td>
                    <td>{entry.task}</td>
                    <td
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "30vh",
                        height: "5vh",
                      }}
                    >
                      <Tooltip title={entry.subtask}>{entry.subtask}</Tooltip>
                    </td>
                    <td>
                      <Checkbox checked={entry.billable} />
                    </td>
                    {[...Array(7)].map((_, dayIndex) => (
                      <td
                        className="border"
                        key={`day${dayIndex + 1}-${index}`}
                      >
                        <Input
                          style={{
                            fontSize: "16px",
                            backgroundColor: "#fff",
                            color: "black",
                            width: "60px",
                          }}
                          className="dark-input"
                          type="number"
                          disabled
                          id={`day${dayIndex + 1}-${index}`}
                          name={`day${dayIndex + 1}`}
                          value={entry[`day${dayIndex + 1}`].hours}
                        />
                      </td>
                    ))}
                    <td>
                      <Input
                        style={{
                          fontSize: "16px",
                          backgroundColor: "#fff",
                          color: "black",
                          width: "60px",
                        }}
                        className="dark-input"
                        type="number"
                        disabled
                        id="totalhours"
                        name="totalhours"
                        value={entry.totalhours}
                      />
                    </td>

                    <td>
                      <Input
                        style={{
                          fontSize: "16px",
                          backgroundColor: "#fff",
                          width: "60px",
                          color: "black",
                        }}
                        className="dark-input"
                        type="text"
                        disabled
                        id="comments"
                        name="comments"
                        value={entry.comments} // Display the calculated column total
                      />
                    </td>
                  </tr>
                ))}
              <tr>
                <td></td>
                <td></td>
                <td>Total</td>
                {[...Array(8)].map((_, dayIndex) => (
                  <td className="border" key={`day${dayIndex + 1}`}>
                    <Input
                      style={{
                        fontSize: "16px",
                        backgroundColor: "#fff",
                        color: "black",
                        width: "60px",
                      }}
                      className="dark-input"
                      type="number"
                      disabled
                      id={`day${dayIndex + 1}Total`}
                      name={`day${dayIndex + 1}Total`}
                      value={
                        dayIndex === 7
                          ? data.weekTotal
                          : columnTotals[`day${dayIndex + 1}`]
                      } // Display the calculated column total
                    />
                  </td>
                ))}
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Actual Logged Hours</td>
                {[...Array(1)].map((_, dayIndex) => {
                  if (!data.weekStartingDate) return null;

                  const startingDate = moment(
                    data.weekStartingDate,
                    "DD-MM-YYYY"
                  );
                  if (!startingDate.isValid()) return null;

                  const days = [];
                  for (let i = 0; i < 7; i++) {
                    const currentDate = startingDate.clone().add(i, "days");
                    const formattedDate = currentDate.format("ddd DD");
                    days.push({ currentDate, formattedDate });
                  }

                  return days.map(({ currentDate, formattedDate }, index) => {
                    return (
                      <td key={index}>
                        <Input
                          style={{
                            fontSize: "16px",
                            backgroundColor: "#fff",
                            width: "60px",
                            height: "30px",
                          }}
                          className="disabled-input"
                          disabled
                          value={renderActualHours(
                            currentDate.format("DD-MM-YYYY")
                          )}
                          readOnly
                        />
                      </td>
                    );
                  });
                })}
                <td></td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>

      <Row>
        <Modal
          title="Confirm "
          open={modal}
          onOk={() => Approve(data._id)}
          onCancel={handleCancel1}
        >
          <p>Are you sure you want to Approve this timesheet?</p>
        </Modal>
        <Col
          style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingTop: "20px",
            gap: "10px",
          }}
        >
          {data.status === "Rejected" ? (
            // Show the reason when status is "Approved" or "Rejected"
            <div>
              <Alert message={`Reason :  ${data.reason}`} type="info" />
            </div>
          ) : (
            <>
              {data.status === "Approved" ? (
                // If status is Approved, show a Reject button
                <Button type="primary" danger onClick={showModal}>
                  Reject
                </Button>
              ) : (
                // If status is Submitted, show both Approve and Reject buttons
                <div>
                  <Button type="primary" primary onClick={showModal1}>
                    Approve
                  </Button>
                  <Button type="primary" danger onClick={showModal}>
                    Reject
                  </Button>
                </div>
              )}
            </>
          )}
        </Col>
      </Row>
      <br />
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div
          style={{
            marginRight: "10px",
            backgroundColor: "#ffe6e6",
            padding: "5px",
            width: "3vh",
            height: "3vh",
          }}
        />
        <span style={{ fontSize: "15px", fontWeight: "bold" }}>Holiday</span>

        <div
          style={{
            marginRight: "10px",
            backgroundColor: "#e6ccff",
            padding: "5px",
            width: "3vh",
            height: "3vh",
            marginLeft: "5vh",
          }}
        />
        <span style={{ fontSize: "15px", fontWeight: "bold" }}>Week off</span>
      </div>
    </Container>
  );
}

export default Userapprove;
