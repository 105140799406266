import React, { useState, useEffect } from "react";
import { Table, Input, Spin, Tooltip } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { url } from "../../url/url";
import axios from "axios";
import "./hrreport.css";
import moment from "moment";
import { decryptRole } from "../../encrypt";

const AssetReport = (props) => {
  const [employees, setEmployees] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const pageSize = 9;
  const [loading, setLoading] = useState(false);
  const users = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    setLoading(true);
    fetchEmployeeData();
  }, []);
  console.log(users, "user");

  const fetchEmployeeData = async () => {
    try {
      const dbName = localStorage.getItem("dbName");
      const encryptedRoleFromStorage = localStorage.getItem("role");
      const jobTitle = decryptRole(encryptedRoleFromStorage);
      let response;
      if (jobTitle === "admin") {
        response = await axios.get(`${url}/api/get/allemplist/${dbName}`);
      } else if (jobTitle === "hr") {
        console.log("inside else");
        response = await axios.get(
          `${url}/api/get/emplist/${users.displayName}/hr/${dbName}`
        );

      }

      if (response.status === 200) {
        const filteredEmployees = response.data.filter(
          (employee) => employee.empStatus !== "inactive"
        );

        // Sort employees based on laptop warranty date in ascending order
        filteredEmployees.sort((a, b) =>
          moment(a.laptopWarranty).diff(moment(b.laptopWarranty))
        );

        setEmployees(filteredEmployees);
        setLoading(false);
      } else {
        console.error("Error:", response.status);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const uniqueName = [
    ...new Set(employees.map((employee) => employee.displayName)),
  ];

  const assetReportColumns = [
    {
      title: "Employee ID",
      dataIndex: "empCode",
      key: "empCode",
      sorter: (a, b) => a.empCode.localeCompare(b.empCode),
    },
    {
      title: "Employee Name",
      dataIndex: "displayName",
      key: "displayName",
      filters: uniqueName.map((displayName) => ({
        text: displayName,
        value: displayName,
      })),
      onFilter: (value, record) => record.displayName === value,
      render: (text, record) => `${record.firstName} ${record.lastName}`,
    },
    {
      title: "Position",
      dataIndex: "empDesignation",
      key: "empDesignation",
    },
    {
      title: "Company Laptop",
      dataIndex: "companyLaptop",
      key: "companyLaptop",
      width: 200
    },
    {
      title: "Laptop Serial Number",
      dataIndex: "laptopSerial",
      key: "laptopSerial",
      width: 200
    },
    {
      title: "Laptop Warranty",
      dataIndex: "laptopWarranty",
      key: "laptopWarranty",
    },
    {
      title: "Accessories",
      dataIndex: "accessaries",
      key: "accessaries",
      render: (text, record) => (
        <ul>
          {record.accessaries && Array.isArray(record.accessaries)
            ? record.accessaries.map((accessory, index) => (
              <li key={index}>
                <Tooltip title={accessory}>
                  {accessory}
                </Tooltip>
              </li>
            ))
            : null}
        </ul>
      ),
    },
  ];
  return (
    <div style={{ transform: "scale(0.9)", transformOrigin: "0 0", width: "110%" }}>
      <h1 style={{ textAlign: "center" }}>Asset Report</h1>

      {/* <div>
          <Input
            type="text"
            size="small"
            style={{ height: "40px", fontSize: "15px" }}
            placeholder="Search Employees..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div> */}

      {loading ? (
        // Render a loading indicator while data is being fetched
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <Table
          dataSource={employees
            .map((employee) => ({
              ...employee,
              firstName: employee.firstName.toLowerCase(),
            }))
            .filter((employee) =>
              employee.firstName.includes(searchQuery.toLowerCase())
            )}
          columns={assetReportColumns}
          sticky
          size="middle"
          scroll={{ x: "max-content" }}
          pagination={{ pageSize: pageSize }}
          className="custom-table col-lg-12 col-md-12 col-sm-12"
        />
      )}
    </div>
  );
};

export default AssetReport;
