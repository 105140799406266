import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { pdfjs } from "react-pdf";
import ColorProvider from "./pages/admin/ColorProvider";
import { QueryClient, QueryClientProvider } from "react-query";
const root = ReactDOM.createRoot(document.getElementById("root"));
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const queryClient = new QueryClient();
root.render(
  <QueryClientProvider client={queryClient}>
    <ColorProvider>
      <App />
    </ColorProvider>
  </QueryClientProvider>
);

reportWebVitals();
