import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  DatePicker,
  Checkbox,
  message,
  Row,
  Col,
  Card,
  Radio,
} from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import axios from "axios";

const Professional = ({ onSave, initialValues }) => {
  const [form] = Form.useForm();
  const [count, Setcount] = useState(2);

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues]);

  const onFinish = (values) => {
    console.log("Received values:", values);
    onSave(values);
    message.success("Saved your Details, Please click on Next");
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const onDownloadPdfClick = async () => {
    const formData = form.getFieldsValue();

    try {
      const response = await axios.post(
        "https://ems.finaran.com:5000/api/convert_pdf",
        {
          professionalReferences: formData.professionalReferences,
        },
        {
          responseType: "blob",
        }
      );

      // Create a Blob from the PDF data
      const blob = new Blob([response.data], { type: "application/pdf" });

      // Create a download link and trigger the download
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "output.pdf";
      link.click();
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  return (
    <div>
      <Form
        form={form}
        name="professionalReferences"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
      >
        <Row gutter={20}>
          <Col span={12}>
            <Card
              title="Professional Reference Details"
              style={{ marginBottom: 16 }}
            >
              <Form.List name="professionalReferences">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                      <div key={key}>
                        <Form.Item
                          {...restField}
                          label="Reference Name"
                          name={[name, "RName"]}
                          fieldKey={[fieldKey, "RName"]}
                          rules={[
                            {
                              required: true,
                              message: "Please enter Contact Telephone",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          label="Designation"
                          name={[name, "Designation"]}
                          fieldKey={[fieldKey, "Designation"]}
                          rules={[
                            {
                              required: true,
                              message: "Please enter Contact Telephone",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          label="Company Name"
                          name={[name, "Cname"]}
                          fieldKey={[fieldKey, "Cname"]}
                          rules={[
                            {
                              required: true,
                              message: "Please enter Company Name",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          label="Contact Telephone"
                          name={[name, "contactTelephone"]}
                          fieldKey={[fieldKey, "contactTelephone"]}
                          rules={[
                            {
                              required: true,
                              message: "Please enter Contact Telephone",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          label="Company Email Address"
                          name={[name, "companyEmail"]}
                          fieldKey={[fieldKey, "companyEmail"]}
                          rules={[
                            {
                              required: true,
                              message: "Please enter Company Email Address",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          label="How do you know this person?"
                          name={[name, "relationship"]}
                          fieldKey={[fieldKey, "relationship"]}
                          rules={[
                            {
                              required: true,
                              message:
                                "Please enter your relationship with this person",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          label="Can the reference be contacted?"
                          name={[name, "canContact"]}
                          fieldKey={[fieldKey, "canContact"]}
                        >
                          <Radio.Group
                            onChange={(e) =>
                              form.setFieldsValue({
                                [`professionalReferences[${name}].canContact`]:
                                  e.target.value === "No",
                              })
                            }
                          >
                            <Radio value="Yes">Yes</Radio>
                            <Radio value="No">No</Radio>
                          </Radio.Group>
                        </Form.Item>
                        {form.getFieldValue([
                          "professionalReferences",
                          name,
                          "canContact",
                        ]) === "No" && (
                          <>
                            <Form.Item
                              {...restField}
                              label="If No, please give the reason why and give an alternate reference"
                              name={[name, "alternateContact"]}
                              fieldKey={[fieldKey, "alternateContact"]}
                            >
                              <Input />
                            </Form.Item>
                          </>
                        )}

                        <Form.Item
                          {...restField}
                          label="Is the reference linked to current employment?"
                          name={[name, "linkedToCurrentEmployment"]}
                          fieldKey={[fieldKey, "linkedToCurrentEmployment"]}
                          initialValue="No" // Default value can be set as needed
                        >
                          <Radio.Group
                            onChange={(e) =>
                              form.setFieldsValue({
                                [`professionalReferences[${name}].linkedToCurrentEmployment`]:
                                  e.target.value === "Yes",
                              })
                            }
                          >
                            <Radio value="Yes">Yes</Radio>
                            <Radio value="No">No</Radio>
                          </Radio.Group>
                        </Form.Item>

                        {form.getFieldValue([
                          "professionalReferences",
                          name,
                          "linkedToCurrentEmployment",
                        ]) === "Yes" && (
                          <Form.Item
                            {...restField}
                            label="Date reference can be contacted"
                            name={[name, "dateCanContact"]}
                            fieldKey={[fieldKey, "dateCanContact"]}
                            rules={[
                              {
                                required: true,
                                message: "Please select the date",
                              },
                            ]}
                          >
                            <DatePicker />
                          </Form.Item>
                        )}

                        <Form.Item
                          label="Additional information"
                          name={[name, "additionalInformation"]}
                          fieldKey={[fieldKey, "additionalInformation"]}
                        >
                          <Input.TextArea />
                        </Form.Item>
                        <Form.Item>
                          <Button
                            type="danger"
                            onClick={() => remove(name)}
                            icon={<MinusOutlined />}
                          >
                            Remove Reference
                          </Button>
                        </Form.Item>
                        <Form.Item>
                          <Button type="primary" htmlType="submit">
                            Save
                          </Button>
                        </Form.Item>
                      </div>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        icon={<PlusOutlined />}
                      >
                        Add Reference
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Card>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Professional;
