import React, { useState, useEffect } from "react";
import {
  message,
  Select,
  Table,
  Row,
  Col,
  Empty,
  Spin,
  Input,
  Button,
  Modal,
} from "antd";
import { Spinner } from "react-bootstrap";
import Papa from "papaparse";
import { url } from "../../url/url";
import FileSaver from "file-saver";
const { Option } = Select;

const TimeSheetLog = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const users = JSON.parse(localStorage.getItem("user"));
  const [user] = useState(users);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [Columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showAllReports, setShowAllReports] = useState(false);
  const [table, setTable] = useState([]);
  const [weekPeriods, setWeekPeriods] = useState(null);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [total, setTotal] = useState(table.length);

  const onPageChange = (newCurrent, newPageSize) => {
    setCurrent(newCurrent);
    setPageSize(newPageSize);
  };

  useEffect(() => {
    fetchtable();
  }, [showAllReports]);

  const fetchtable = async () => {
    setLoading(true);
    try {
      if (!showAllReports) {
        console.log("inside if");
        // Fetch manager reportee details
        const dbName = localStorage.getItem("dbName");
        const response = await fetch(
          `${url}/timesheet/getreportgen/${user.displayName}/manager/${dbName}`
        );
        const table = await response.json();
        setTable(table);
        console.log(table, "table");
        setLoading(false);
      } else if(showAllReports && user.jobTitle === "hr") {
        console.log("inside else");
        // Fetch all reports
        const dbName = localStorage.getItem("dbName");
        const response = await fetch(`${url}/timesheet/getreportgen/${user.displayName}/hr/${dbName}`);
        const table = await response.json();
        setTable(table);
        setLoading(false);
      } else if(showAllReports && user.jobTitle === "admin") {
        console.log("inside else");
        // Fetch all reports
        const dbName = localStorage.getItem("dbName");
        const response = await fetch(`${url}/timesheet/getreportgen/${user.displayName}/admin/${dbName}`);
        const table = await response.json();
        setTable(table);
        setLoading(false);
      }
      // Extract and set week periods
      const weekPeriods = table.map((record) => ({
        weekPeriod: `${record.weekStartingDate} - ${record.weekEndingDate}`,
        startDate: new Date(
          record.weekStartingDate.split("-").reverse().join("-")
        ),
      }));

      // Sort weekPeriods based on startDate
      weekPeriods.sort((a, b) => a.startDate - b.startDate);

      // Map the sorted weekPeriods back to an array of weekPeriods
      const sortedWeekPeriods = weekPeriods.map((record) => record.weekPeriod);

      setWeekPeriods(sortedWeekPeriods);
    } catch (error) {
      console.error(error);
      message.error("Failed to fetch data from the server.");
      setLoading(false);
    }
  };
  const filteredData = showAllReports
    ? table // Show all reports
    : table.filter((record) => {
        const recordValues = Object.values(record);
        return recordValues.some((value) =>
          String(value).toLowerCase().includes(searchQuery.toLowerCase())
        );
      });

  const uniqueEmpNames = Array.from(
    new Set(table.map((record) => record.userName))
  );

  const columns = [
    {
      title: "Week Period",
      dataIndex: "weekPeriod",
      align: "center",
      render: (text, record) =>
        `${record.weekStartingDate} - ${record.weekEndingDate}`,
      sorter: (a, b) => {
        // Convert the date strings to JavaScript Date objects for comparison
        const dateA = a.weekStartingDate.split("-").reverse().join("/");
        const dateB = b.weekStartingDate.split("-").reverse().join("/");

        if (dateA < dateB) {
          return -1;
        } else if (dateA > dateB) {
          return 1;
        } else {
          return 0;
        }
      },
    },

    {
      title: "Employee",
      dataIndex: "userName",
      filters: uniqueEmpNames.map((userName) => ({
        text: userName,
        value: userName,
      })),
      onFilter: (value, record) => record.userName === value,
      sorter: (a, b) => a.userName.localeCompare(b.userName), // Enable sorting
      align: "center",
    },
    {
      title: "Reporting Manager",
      dataIndex: "reportingManager",
      sorter: (a, b) => a.reportingManager.localeCompare(b.reportingManager),
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => a.status.localeCompare(b.status), // Enable sorting
      filters: [
        // Enable filtering options
        { text: "Approved", value: "Approved" },
        { text: "Pending", value: "Pending" },
        { text: "Rejected", value: "Rejected" },
        { text: "Submitted", value: "Submitted" },
        { text: "Draft", value: "Draft" },
      ],
      onFilter: (value, record) => record.status === value,
      align: "center",
    },
    {
      title: "Reason",
      dataIndex: "reason",
      width:100,
      align: "center",
    },
    {
      title: "Week Total",
      dataIndex: "weekTotal",
      align: "center",
      render: (text) => <div style={{ textAlign: "center" }}>{text}</div>,
    },
  ];

  const expandedColumns = [
    {
      title: <span style={{ color: "#964b00" }}>Project</span>,
      dataIndex: "project",
      align: "center",
    },
    {
      title: <span style={{ color: "#964b00" }}>Task</span>,
      dataIndex: "task",
      align: "center",
    },
    {
      title: <span style={{ color: "#964b00" }}> Day Hours</span>,
      children: [
        {
          title: <span style={{ color: "#964b00" }}>Day 1</span>,
          render: (text) => <p>{text.day1.hours}</p>,
          align: "center",
        },
        {
          title: <span style={{ color: "#964b00" }}>Day 2</span>,
          render: (text) => <p>{text.day2.hours}</p>,
          align: "center",
        },
        {
          title: <span style={{ color: "#964b00" }}>Day 3</span>,
          render: (text) => <p>{text.day3.hours}</p>,
          align: "center",
        },
        {
          title: <span style={{ color: "#964b00" }}>Day 4</span>,
          render: (text) => <p>{text.day4.hours}</p>,
          align: "center",
        },
        {
          title: <span style={{ color: "#964b00" }}>Day 5</span>,
          render: (text) => <p>{text.day5.hours}</p>,
          align: "center",
        },
        {
          title: <span style={{ color: "#964b00" }}>Day 6</span>,
          render: (text) => <p>{text.day6.hours}</p>,
          align: "center",
        },
        {
          title: <span style={{ color: "#964b00" }}>Day 7</span>,
          render: (text) => <p>{text.day7.hours}</p>,
          align: "center",
        },
      ],
    },
    {
      title: <span style={{ color: "#964b00" }}>Total Hours</span>,
      dataIndex: "totalhours",
      align: "center",
    },
    {
      title: <span style={{ color: "#964b00" }}>Comments</span>,
      dataIndex: "comments",
      align: "center",
    },
  ];

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // const filteredData = tableData.filter((record) => {
  //   const recordValues = Object.values(record);
  //   return recordValues.some((value) =>
  //     String(value).toLowerCase().includes(searchQuery.toLowerCase())
  //   );
  // });

  const handleRowExpand = (expanded, record) => {
    if (expanded) {
      // Add the key of the expanded row to the state
      setExpandedRowKeys([...expandedRowKeys, record.key]);
    } else {
      // Remove the key of the collapsed row from the state
      setExpandedRowKeys(expandedRowKeys.filter((key) => key !== record.key));
    }
  };

  function handleClick() {
    const csv = Papa.unparse(filteredData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    FileSaver.saveAs(blob, "Timesheetlog.csv", "C:");
  }

  const toggleShowAllReports = () => {
    setShowAllReports(!showAllReports);
  };

  return (
    <div className="reports" id="download">
      {loading ? (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "20px",
            }}
          >
            <div>
              <Button className="button" type="primary" onClick={handleClick}>
                Export
              </Button>
            </div>
            <div>
              <label
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  fontSize: "14px",
                }}
              >
                <input
                  type="checkbox"
                  checked={showAllReports}
                  onChange={toggleShowAllReports}
                />
                Show All Employee
              </label>
            </div>
            {/* <div>
              <Input
                type="text"
                size="large"
                style={{ height: "40px", fontSize: "15px", width: "250px" }}
                placeholder="Search..."
                value={searchQuery}
                onChange={handleSearchQueryChange}
              />
            </div> */}
          </div>
          <Table
            className="custom-table col-lg-12 col-md-12 col-sm-12"
            columns={columns}
            sticky
            expandable={{
              expandedRowKeys,
              onExpand: handleRowExpand,
              expandedRowRender: (record) => (
                <Table
                  columns={expandedColumns}
                  dataSource={record.entrydata}
                  pagination={false}
                  size="middle" 
                  scroll={{ x: "max-content" }} 
                  sticky
                />
              ),
            }}
            dataSource={filteredData.map((record, index) => ({
              ...record,
              key: index,
            }))}
            size="middle" // Adjust the size as needed
            pagination={{
              current: current,
              pageSize: pageSize,
              total: total,
              onChange: onPageChange,
            }}
            scroll={{ x: "max-content" }} // Adjust horizontal scroll
          />
        </>
      )}
    </div>
  );
};

export default TimeSheetLog;
