/*Apply leave->Compoff form*/
import React, { useState, useEffect } from "react";
import {
  Layout,
  Form,
  Input,
  Button,
  DatePicker,
  Select,
  Row,
  Col,
  Typography,
  notification,
  message,
} from "antd";
import { UploadOutlined, DeleteFilled } from "@ant-design/icons";
import moment from "moment";
import axios from "axios";
import "./empform.css";
import { url } from "../../../url/url";
import { useNavigate } from "react-router-dom";
import { Content } from "antd/es/layout/layout";
const { Option } = Select;
const { Title } = Typography;

const EmpLeaveBalRequest = (props) => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const formRef = React.useRef(null);
  const [form] = Form.useForm();
  const [image, setImage] = useState("");
  const [pdf, setPdf] = useState("");
  //const today = moment().format('YYYY-MM-DD');
  const today = new Date().toISOString().slice(0, 10);
  const [date] = useState(today);
  const [count, setcount] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [pdfBlobUrl, setPdfBlobUrl] = useState("");
  const [leavedet, setLeavedet] = useState({
    reportingmanager: "",
    hrName:"",
    empCode: "",
    balance1: "",
    balance2: "",
  });
  const [holidays, setHolidays] = useState([]);

  const validateYearFormat = (rule, value, callback) => {
    // Check if the value is a valid date with a 4-digit year
    const regex = /^\d{4}-\d{2}-\d{2}$/; // YYYY-MM-DD format
    if (!regex.test(value)) {
      callback("Please enter a valid date in DD-MM-YYYY format.");
    } else {
      callback();
    }
  };
  const handleSubmit = async (values) => {
    console.log("Received values of form: ", values);
    if (values.workType === "weekendsun") {
      const selectedDate = moment(values.dateOfWork);
      console.log(selectedDate, "selecetdedate");
      if (selectedDate.day() != 0) {
        return message.error(
          'Selected date must be a Sunday when the type of work is "Sunday Worked."'
        );
      }
    }
    if (values.workType === "weekendsat") {
      const selectedDate = moment(values.dateOfWork);

      if (selectedDate.day() != 6) {
        return message.error(
          'Selected date must be a Saturday when the type of work is "Saturday Worked."'
        );
      }
    }

    if (values.workType === "holidayworked" && holidays) {
      const selectedDate = moment(values.dateOfWork, "YYYY-MM-DD").format(
        "DD/MM/YYYY"
      );

      // Convert holiday dates to the same format as selectedDate for comparison
      const holidayDates = holidays.map((holiday) =>
        moment(holiday.date, "DD/MM/YYYY").format("DD/MM/YYYY")
      );

      if (!holidayDates.includes(selectedDate)) {
        return message.error(
          "Selected date must be a Holiday when the type of work is Holiday Worked."
        );
      }
    }

    try {
      console.log(leavedet.reportingmanager, "manager");
      const imageFile = image;
      const updatedValues = {
        ...values,
        image: imageFile || pdf,
        dateOfApply: date,
        workdate: values.dateOfWork,
        worktype: values.workType,
        workNature: values.nature,
        status: "Submitted",
        reportingManager: leavedet.reportingmanager,
        employeeId: user.empCode,
        employeeName: user.displayName,
        hrName:user.hrName,
      };

      const dbName = localStorage.getItem("dbName");

      // Make a POST request to submit the leave
      const compoff = await axios.post(
        `${url}/compoff/${dbName}`,
        updatedValues
      );
      notification.success({ message: "Successfully Applied" });

      form.resetFields();
      setcount(count + 1);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        message.error("Compoff request already exists for this date");
      } else {
        console.error(error);
        message.error("Something Went Wrong");
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [count]);
  const fetchData = async () => {
    try {
      const dbName = localStorage.getItem("dbName");

      const resp = await axios.get(
        `${url}/api/getUserManager/${user.displayName}/${dbName}`
      );
      const empCode = resp.data.user.empCode;
      const manager = resp.data.user.manager;
      const hrName = resp.data.user.hrName;
      const annualLeave = resp.data.user.annualLeave;
      const casualLeave = resp.data.user.casualLeave;

      const leaveResponse = await axios.get(
        `${url}/leave/ereport/${empCode}/${dbName}`
      );

      const leaveData = leaveResponse.data; // Array of arrays

      const currentYear = new Date().getFullYear();
      console.log(manager, "manager");
      setLeavedet((prevLeaveData) => ({
        ...prevLeaveData,
        reportingmanager: manager,
        empCode: empCode,
        hrName:hrName,
        balance1: 1,
        balance2: 2,
      }));
      const publicHolidays = await fetchPublicHolidays(dbName);
      setHolidays(publicHolidays);
    } catch (error) {
      console.log(error);
    }
  };
  async function fetchPublicHolidays(dbName) {
    try {
      const response = await axios.get(`${url}/get/combined/${dbName}`);
      const data = response.data[0]?.officeHolidaysData;
      return data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
  const layout = {
    labelCol: {
      span: 7,
    },
    wrapperCol: {
      span: 16,
    },
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const allowedFileTypes = [
        "image/jpeg",
        "image/png",
        "image/jpg",
        "application/pdf",
      ];
      const maxSize = 5 * 1024 * 1024;

      if (allowedFileTypes.includes(file.type) && file.size <= maxSize) {
        const reader = new FileReader();
        reader.onloadend = () => {
          if (file.type.startsWith("image/")) {
            setImage(reader.result);
            setPdf(null); // Clear the PDF if an image is selected
          } else if (file.type === "application/pdf") {
            setImage(null); // Clear the image if PDF is selected
            setPdf(reader.result); // Set the PDF file for preview
          }
        };
        reader.readAsDataURL(file);
      } else {
        // Clear the file error message if no file is selected
        setImage(null);
        setPdf(null);
        formRef.current.setFieldsValue({ image: null });
        if (file.size > maxSize) {
          message.error("File size exceeds the 5MB limit.");
        } else {
          message.error(
            "Invalid file type. Please select a valid image or PDF file."
          );
        }
      }
    }
  };

  const handleDelete = () => {
    setImage("");
    setPdf("");
    form.setFieldsValue({
      ...form.getFieldsValue(),
      image: null,
    });
  };

  function openPdfInModal(pdfDataUrl) {
    fetch(pdfDataUrl)
      .then((res) => res.blob())
      .then((blob) => {
        const blobUrl = URL.createObjectURL(blob);
        setPdfBlobUrl(blobUrl);
        setModalVisible(true);
      });
  }

  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Content
        style={{
          padding: 20,
          boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.35)",
          minHeight: "5vh",
        }}
      >
        <Button onClick={goBack}>Back</Button>
        <Layout style={{ zoom: "90%" }}>
          <h2 className="text-center ">Raise Holiday worked Request</h2>
          <Row
            style={{
              margin: "20px 0px",
              display: "flex",
              justifyContent: "space-between",
            }}
          ></Row>
          <Form {...layout} form={form} onFinish={handleSubmit}>
            <div className="row mb-4 ">
              <div className="col-lg-6 col-sm-12 ">
                <Form.Item label="Date of Apply">
                  <Input
                    value={date}
                    name="dateOfApply"
                    type="date"
                    style={{ height: "40px", fontSize: "12px" }}
                    disabled
                    className="disabled-input"
                  />
                </Form.Item>
              </div>
              <div className="col-lg-6 col-sm-12  ">
                <Form.Item label="Manager">
                  <Input
                    value={leavedet.reportingmanager}
                    name="reportingManager"
                    style={{ height: "40px", fontSize: "12px" }}
                    disabled
                    className="disabled-input"
                  />
                </Form.Item>
              </div>
            </div>
            <div className="row mb-4 ">
              <div className="col-lg-6 col-sm-12 ">
                <Form.Item
                  label="Date of Work"
                  name="dateOfWork"
                  rules={[
                    {
                      required: true,
                      message: "Please select the date you worked on holiday.",
                    },
                    { validator: validateYearFormat },
                  ]}
                >
                  {/* <DatePicker style={{ width: '100%' }} disabledDate={(current) => current && current.isAfter(moment())} /> */}
                  <Input
                    type="date"
                    size="large"
                    style={{ height: "40px", fontSize: "12px" }}
                    min={moment().subtract(2, "months").format("YYYY-MM-DD")}
                    max={new Date().toISOString().split("T")[0]}
                  />
                </Form.Item>
              </div>
              <div className="col-lg-6 col-sm-12  ">
                <Form.Item
                  label="Type of Work"
                  name="workType"
                  rules={[
                    {
                      required: true,
                      message: "Please select the type of work.",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select the type of work"
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  >
                    <Option value="holidayworked">Holiday Worked</Option>
                    <Option value="weekendsat">Saturday Worked</Option>
                    <Option value="weekendsun">Sunday Worked</Option>
                  </Select>
                </Form.Item>
              </div>
              <div className="col-lg-6 col-sm-12  ">
                <Form.Item
                  label="Nature"
                  name="nature"
                  rules={[
                    {
                      required: true,
                      message: "Please select the nature of work.",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select the type of work"
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  >
                    <Option value="overtime">Overtime</Option>
                    <Option value="projectdeadline">Project Deadline</Option>
                    <Option value="customersupport">Customer Support</Option>
                    <Option value="others">Others</Option>
                  </Select>
                </Form.Item>
              </div>
            </div>
            <div className="row mb-4 ">
              <div className="col-lg-6 col-sm-12  ">
                <Form.Item label="Document" name="document">
                  <label className="custom-file-upload">
                    <UploadOutlined />
                    Upload
                    <Input
                      type="file"
                      accept="image/jpeg, image/png, image/jpg, application/pdf"
                      onChange={(e) => handleImageChange(e, "image")}
                      style={{ display: "none" }}
                    />
                  </label>
                  {pdf || image ? ( // Check if either a PDF or image is selected
                    <div>
                      {image && (
                        <div>
                          <img src={image} width="200" />
                          <Button
                            danger
                            size="medium"
                            icon={<DeleteFilled />}
                            onClick={handleDelete}
                          />
                        </div>
                      )}
                      {pdf && (
                        <div>
                          <Button
                            danger
                            size="medium"
                            icon={<DeleteFilled />}
                            onClick={handleDelete}
                          />
                          <Button
                            type="primary"
                            size="medium"
                            onClick={() => openPdfInModal(pdf)}
                          >
                            Open PDF
                          </Button>
                        </div>
                      )}
                    </div>
                  ) : null}
                </Form.Item>
              </div>
              <div className="col-lg-6 col-sm-12  ">
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Submit Request
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        </Layout>
      </Content>
    </>
  );
};

export default EmpLeaveBalRequest;
