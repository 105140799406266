import React, { useEffect, useState, useRef } from "react";
import {
  Card,
  Col,
  Row,
  Button,
  Empty,
  Modal,
  Input,
  Form,
  message,
  notification,
  Descriptions,
  Divider,
  Avatar,
  Tabs,
  Select,
  Badge,
  Typography,
  Timeline,
  Steps,
  Spin,
  Popconfirm,
} from "antd";
import {
  PaperClipOutlined,
  EditOutlined,
  UserOutlined,
  SendOutlined,
  UploadOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  DeleteFilled,
  EyeOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import "./query.css";
import { Link } from "react-router-dom";
import { url } from "../../url/url";
import axios from "axios";
import { updateLocale } from "moment";
const { Step } = Steps;
const { Meta } = Card;
const { TextArea } = Input;
const { TabPane } = Tabs;
const RaiseIssue = () => {
  const [card, setCard] = useState(false);
  const [card1, setCard1] = useState(false);
  const [status, setStatus] = useState(false);
  const [status1, setStatus1] = useState(false);
  // const users = JSON.parse(localStorage.getItem("user"));
  // const [user] = useState(users);
  const [model, setModel] = useState(false);
  const [heading, setHeading] = useState("");
  const [issue, setIssue] = useState("");
  const [type, setType] = useState("");
  const [queryReports, setQueryReports] = useState([]);
  const [assignedReports, setAssignedReports] = useState([]);
  const [adminQuery, setAdminQuery] = useState([]);
  const [selectedUniqueId1, setSelectedUniqueId1] = useState(null);
  const [selectedHeading1, setSelectedHeading1] = useState("");
  const [selectedContent1, setSelectedContent1] = useState({ Message: [] });
  const [selectedUniqueId, setSelectedUniqueId] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedType1, setSelectedType1] = useState(null);
  const [selectedHeading, setSelectedHeading] = useState("");
  const [selectedCreatedOn, setSelectedCreatedOn] = useState("");
  const [selectedUpdatedOn, setSelectedUpdatedOn] = useState("");
  const [selectedContent, setSelectedContent] = useState({ Message: [] });
  const [replyText, setReplyText] = useState("");
  const [editingRecord, setEditingRecord] = useState({});
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedAssignedTo, setSelectedAssignedTo] = useState("");
  const [selectedPriority, setSelectedPriority] = useState("");
  const [count, setCount] = useState(0);
  // const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [form] = Form.useForm();
  const user1 = JSON.parse(localStorage.getItem("user"));
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [image, setImage] = useState();
  const [image1, setImage1] = useState();
  const [image2, setImage2] = useState();
  const [openImage, setOpenImage] = useState(false);
  const [attachment, setAttachment] = useState("");
  const [selectedAttachment, setSelectedAttachment] = useState("");
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [newReplyId, setNewReplyId] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [messageIdToDelete, setMessageIdToDelete] = useState(null);
  const messageEndRef = useRef(null);
  const showDeleteModal = (messageId) => {
    setMessageIdToDelete(messageId);
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    await handleDeleteReply(messageIdToDelete);
    setIsModalVisible(false);
  };

  const handleDeleteCancel = () => {
    setIsModalVisible(false);
  };
  const showTicket = () => {
    setOpen(true);
  };

  const handlecancel = () => {
    setOpen(false);
  };

  const showImage = () => {
    setOpenImage(true);
  };

  const handlecancelImage = () => {
    setOpenImage(false);
  };

  const showTicket1 = () => {
    setOpen1(true);
  };

  const handlecancel1 = () => {
    setOpen1(false);
  };
  // const fetchUsers = async () => {
  //   const dbName = localStorage.getItem("dbName");
  //   console.log("dbName in fetchUsers in addproject", dbName);
  //   const response = await fetch(`${url}/api/get/allemplist/${dbName}`);
  //   const data = await response.json();
  //   setUsers(data);
  // };
  // useEffect(() => {
  //   fetchUsers();
  // }, [count]);

  useEffect(() => {
    console.log("inside query reports useeffect");
    if (queryReports.length > 0 && !card) {
      // Only set if card is false
      const firstReport = queryReports[0];
      setSelectedAssignedTo(firstReport.AssignedTo);
      setSelectedStatus(firstReport.Status);
      setSelectedCreatedOn(firstReport.CreatedOn);
      setSelectedUniqueId(firstReport.UniqueId);
      setSelectedHeading(firstReport.Content.Heading);
      setSelectedContent({ Message: firstReport.Content.Message });
      setCard(true);
      handleClick(
        firstReport.UniqueId,
        firstReport.Content.Heading,
        firstReport.Content.Message,
        firstReport.MailId,
        firstReport.UpdatedOn,
        firstReport.CreatedOn,
        firstReport.AssignedTo,
        firstReport.Status,
        firstReport.type,
        firstReport.attachment
      );
    }
  }, [queryReports, card]);

  useEffect(() => {
    if (assignedReports.length > 0 && !card1) {
      const firstReport = assignedReports[0];
      setSelectedUniqueId1(firstReport.UniqueId);
      setSelectedHeading1(firstReport.Content.Heading);
      setSelectedContent1({ Message: firstReport.Content.Message });
      setCard1(true);
      handleClick1(
        firstReport.UniqueId,
        firstReport.Content.Heading,
        firstReport.Content.Message,
        firstReport.type
      );
    }
  }, [assignedReports]);

  const handleClick = (
    uniqueId,
    heading,
    messages,
    MailId,
    UpdatedOn,
    CreatedOn,
    assignee,
    status,
    type,
    attachment
  ) => {
    setCard(true);
    setStatus(true);
    setSelectedUniqueId(uniqueId);
    setSelectedCreatedOn(CreatedOn);
    setSelectedUpdatedOn(UpdatedOn);
    setSelectedAssignedTo(assignee);
    setSelectedStatus(status);
    setSelectedType(type);
    setSelectedAttachment(attachment);
    console.log(selectedUniqueId, UpdatedOn, "selectedUniqueId");
    setSelectedHeading(heading);

    if (messages && messages.length > 0) {
      setSelectedContent({ Message: messages });
    }
  };

  const deleteQueryReport = async (type, uniqueId) => {
    const dbName = localStorage.getItem("dbName");
    console.log(uniqueId, "unique");

    try {
      const encodedUniqueId = encodeURIComponent(uniqueId);
      const response = await axios.delete(
        `${url}/query/${type}/${dbName}/${encodedUniqueId}`
      );
      setQueryReports(queryReports.filter((data) => data.UniqueId != uniqueId));
      window.location.reload();
      message.success("QueryReport deleted successfully");
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while deleting the QueryReport");
    }
  };
  const handleClick1 = (uniqueId, heading, messages, type) => {
    setCard1(true);
    setStatus1(true);
    setSelectedType1(type);
    setSelectedUniqueId1(uniqueId);
    console.log(selectedUniqueId1, type, "selectedUniqueId1");
    setSelectedHeading1(heading);

    if (messages && messages.length > 0) {
      setSelectedContent1({ Message: messages });
    }
  };

  const showModal = () => {
    setModel(true);
  };

  const handleCancel = () => {
    setModel(false);
    form.resetFields();
    setHeading("");
    setIssue("");
    setType("");
    setImage(null);
  };

  const raiseDispute = () => {
    showModal();
  };
  console.log(heading, "headingggg");
  const handleSubmit = async (values) => {
    try {
      const email = localStorage.getItem("email");
      const dbName = localStorage.getItem("dbName");
      const urlPath = `${url}/support/queryReports/${values.type}/${dbName}/${email}`;

      const response = await axios.post(urlPath, {
        type: values.type,
        Heading: heading,
        Message: [
          {
            issue: issue,
            senderemail: email,
            attachment: image,
          },
        ],
      });

      setQueryReports([...queryReports, response.data]);
      const firstReport = response.data;
      setSelectedAssignedTo(firstReport.AssignedTo);
      setSelectedStatus(firstReport.Status);
      setSelectedCreatedOn(firstReport.CreatedOn);
      setSelectedUniqueId(firstReport.UniqueId);
      setSelectedHeading(firstReport.Content.Heading);
      setSelectedContent({ Message: firstReport.Content.Message });
      setCard(true);
      handleClick(
        firstReport.UniqueId,
        firstReport.Content.Heading,
        firstReport.Content.Message,
        firstReport.MailId,
        firstReport.UpdatedOn,
        firstReport.CreatedOn,
        firstReport.AssignedTo,
        firstReport.Status,
        firstReport.type,
        firstReport.attachment
      );

      console.log("clear data", response.data);
      console.log(values.heading, values.issue, "issue");

      handleCancel();

      notification.success({ message: "Your Report has been received" });
    } catch (error) {
      console.error("Error raising dispute:", error);
      notification.error({ message: "Failed to raise the report" });
    }
  };
  const handleUpdate = async (
    selectedStatus,
    selectedAssignedTo,
    selectedPriority
  ) => {
    try {
      const dbName = localStorage.getItem("dbName");
      const uniqueId = selectedUniqueId1;

      let assignedTo = "";
      let email = "";

      if (selectedAssignedTo) {
        const selectedUser = selectedAssignedTo.split("(");
        assignedTo = selectedUser[0].trim();

        // Check if the email part exists before using it
        if (selectedUser[1]) {
          email = selectedUser[1].replace(")", "").trim();
        }
      }

      const updatedReports = assignedReports.map((report) => {
        if (report.UniqueId === selectedUniqueId1) {
          const statusToUpdate = selectedStatus || report.Status;
          const priorityToUpdate = selectedPriority || report.Priority;

          let assignedToUpdate = assignedTo || report.AssignedTo;
          if (user1.jobTitle === "superAdmin") {
            assignedToUpdate = "Finaran Support";
          }

          const mail = email || report.email;

          console.log(statusToUpdate, "Status");
          console.log(assignedToUpdate, "AssignedTo");
          console.log(priorityToUpdate, "Priority");

          return {
            ...report,
            Status: statusToUpdate,
            AssignedTo: assignedToUpdate,
            ReplierMail: mail,
            Priority: priorityToUpdate,
          };
        }

        return report;
      });

      const rows = assignedReports.filter(
        (item) => item.UniqueId === selectedUniqueId1
      );
      const type = rows[0].type;

      const response = await axios.put(
        `${url}/assignStatus/${type}/${dbName}`,
        {
          uniqueId: uniqueId,
          updatedReports: updatedReports,
        }
      );

      setEditingRecord(response.data);
      notification.success({ message: "Record updated successfully!" });
      setOpen1(false);
    } catch (error) {
      console.error(error);
      message.error("Failed to update the record.");
    }
  };

  const handleUpdateStatus = async (
    selectedStatus,

    selectedPriority
  ) => {
    try {
      const dbName = localStorage.getItem("dbName");
      const uniqueId = selectedUniqueId;

      const updatedReports = queryReports.map((report) => {
        if (report.UniqueId === selectedUniqueId) {
          const statusToUpdate =
            selectedStatus === "" || selectedStatus === null
              ? report.Status
              : selectedStatus;

          console.log(statusToUpdate, "Status");

          return {
            ...report,
            Status: statusToUpdate,
          };
        }

        return report;
      });

      const rows = queryReports.filter(
        (item) => item.UniqueId === selectedUniqueId
      );
      const type = rows[0].type;
      console.log(rows, "type");
      const response = await axios.put(
        `${url}/assignStatus/${type}/${dbName}`,
        {
          uniqueId: uniqueId,
          updatedReports: updatedReports,
        }
      );

      setEditingRecord(response.data);
      notification.success({ message: "Record updated successfully!" });
    } catch (error) {
      console.error(error);
      message.error("Failed to update the record.");
    }
  };
  const handleArchive = async (selectedUniqueId) => {
    try {
      const rows = queryReports.filter(
        (item) => item.UniqueId === selectedUniqueId
      );
      const type = rows[0].type;
      const response = await axios.post(
        `${url}/support/archiveQueryReport/${type}`,
        {
          uniqueId: selectedUniqueId,
          dbName: localStorage.getItem("dbName"),
        }
      );

      // Check the response and update your state or take necessary actions
      if (response.status === 200) {
        // Update your state or perform other actions
        // Remove the archived item from the queryReports state
        setQueryReports(
          queryReports.filter(
            (report) => report.UniqueId !== selectedUniqueId.UniqueId
          )
        );

        notification.success({ message: "Ticket archived successfully" });
      } else {
        message.error("Failed to archive ticket");
      }
    } catch (error) {
      console.error("Error archiving ticket:", error);
      message.error("Failed to archive ticket");
    }
  };

  const handleSubmitReply = async () => {
    try {
      if (!replyText.trim()) {
        message.warning("Please enter a comment before submitting.");
        return;
      }
      const rows = queryReports.filter(
        (item) => item.UniqueId === selectedUniqueId
      );
      const type = rows[0].type;
      console.log(rows, "typee");
      const dbName = localStorage.getItem("dbName");
      const email = localStorage.getItem("email");
      const now = new Date(); // Get the current date and time
      console.log(selectedUniqueId, replyText, "replyText");
      const response = await axios.post(
        `${url}/support/addReply/${type}/${dbName}/${email}`,
        {
          uniqueId: selectedUniqueId,
          issue: replyText,
          attachment: image1, // Include the attachment data
        }
      );
      console.log(response, "response of post image");
      if (response.status === 200) {
        const newReplyId = response.data.newMessage._id;
        console.log("newreplyid", newReplyId);
        // Create a new reply object with the updated date
        const newReply = {
          date: now,
          senderemail: email,
          text: replyText,
          attachment: image1,
          hasAttachment: !!image1,
          _id: newReplyId,
        };

        // Update the selectedContent with the new reply
        setSelectedContent((prevContent) => {
          const updatedContent = { ...prevContent };
          updatedContent.Message.push(newReply);
          return updatedContent;
        });

        setReplyText("");
        setImage1("");
        // Set the new reply ID and show delete button
        setNewReplyId(newReplyId);
        setShowDeleteButton(true);

        // Hide delete button after 1 minute
        setTimeout(() => {
          setShowDeleteButton(false);
        }, 60000);
        // window.location.reload();
        notification.success({ message: "Reply has been sent" });
        messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
      } else {
        console.error("Failed to add a reply to the QueryReport");
        message.error("Failed to send the reply");
      }
    } catch (error) {
      console.error("Error adding reply:", error);
      message.error("Failed to send the reply");
    }
  };

  const handleDeleteReply = async (messageId) => {
    let dbName;
    console.log(selectedUniqueId, messageId, selectedType);
    const encodedUniqueId = encodeURIComponent(selectedUniqueId);
    const encodedMessageId = encodeURIComponent(messageId);

    if (selectedType === "Internal") {
      dbName = localStorage.getItem("dbName");
    } else if (selectedType === "Finaran Support") {
      dbName = "ems-master";
    }

    try {
      const response = await axios.delete(
        `${url}/support/deleteReply/${dbName}/${selectedType}/${encodedUniqueId}/${encodedMessageId}`
      );
      if (response.status === 200) {
        setSelectedContent((prevContent) => {
          const updatedContent = { ...prevContent };
          updatedContent.Message = updatedContent.Message.filter(
            (message) => message._id !== messageId
          );
          return updatedContent;
        });
        notification.success({ message: "Reply has been deleted" });
      } else {
        message.error("Failed to delete the reply");
      }
    } catch (error) {
      console.error("Error deleting reply:", error);
      message.error("Failed to delete the reply");
    }
  };

  const handleSubmitReply1 = async () => {
    try {
      if (!replyText.trim()) {
        message.warning("Please enter a comment before submitting.");
        return;
      }
      const dbName = localStorage.getItem("dbName");
      const email = localStorage.getItem("email");
      const rows = assignedReports.filter(
        (item) => item.UniqueId === selectedUniqueId1
      );
      const type = rows[0].type;
      console.log(rows, image2, "typee");

      const now = new Date(); // Get the current date and time
      console.log(selectedUniqueId1, replyText, "replyText");

      const response = await axios.post(
        `${url}/support/addReply/${type}/${dbName}/${email}`,
        {
          date,
          uniqueId: selectedUniqueId1,
          issue: replyText,
          attachment: image2,
        }
      );

      console.log(response, "response");
      if (response.status === 200) {
        const newReplyId = response.data.newMessage._id;
        // Create a new reply object with the updated date
        const newReply = {
          date,
          senderemail: email,
          text: replyText,
          attachment: image2,
          hasAttachment: !!image2,
          _id: newReplyId,
        };

        // Update the selectedContent with the new reply
        setSelectedContent1((prevContent) => {
          const updatedContent = { ...prevContent };
          updatedContent.Message.push(newReply);
          return updatedContent;
        });
        console.log("new reply", newReply.text, selectedContent1.Message);
        setCount(count + 1);
        setReplyText("");
        setImage2("");
        notification.success({ message: "Reply has been sent" });
      } else {
        console.error("Failed to add a reply to the QueryReport");
        message.error("Failed to send the reply");
      }
    } catch (error) {
      console.error("Error adding reply:", error);
      message.error("Failed to send the reply");
    }
  };

  const queryDetails = async () => {
    try {
      console.log("QueryDetails start", new Date().toString());
      const dbName = localStorage.getItem("dbName");
      const email = localStorage.getItem("email");
      const internalResponse = await axios.get(
        `${url}/queryReports/Internal/${dbName}/${email}?excludeAttachments=true`
      );
      const finaranResponse = await axios.get(
        `${url}/queryReports/Finaran Support/${dbName}/${email}?excludeAttachments=true`
      );
      console.log(internalResponse, finaranResponse, "responses");

      const combinedResponse = [
        ...internalResponse.data,
        ...finaranResponse.data,
      ];
      setQueryReports(combinedResponse);
    } catch (error) {
      console.log(error);
    }
    console.log("QueryDetails end", new Date().toString());
  };

  const assignedQueryDetails = async () => {
    try {
      console.log("assignedQueryDetails", new Date().toString());
      const dbName = localStorage.getItem("dbName");
      const email = localStorage.getItem("email");
      const internalResponse = await axios.get(
        `${url}/queryReports/Internal/${dbName}/${email}?assigned=true&excludeAttachments=true`
      );
      const finaranResponse = await axios.get(
        `${url}/queryReports/Finaran Support/${dbName}/superAdmin?assigned=true&excludeAttachments=true`
      );
      if (user1.jobTitle !== "superAdmin") {
        setAssignedReports(internalResponse.data);
      } else {
        setAssignedReports(finaranResponse.data);
      }
      setCount(count + 1);
    } catch (error) {
      console.log(error);
    }
    console.log("assignedQueryDetails end", new Date().toString());
  };
  // useEffect(() => {
  //   fetchAttachment();
  // }, [])
  useEffect(() => {
    if (newReplyId) {
      messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedContent]);
  const fetchAttachment = async (dbName, type, ticketId, messageId) => {
    try {
      console.log(dbName, type, ticketId, messageId, "attah");
      const encodedTicket = encodeURIComponent(ticketId);
      const encodedMessage = encodeURIComponent(messageId);
      const response = await axios.get(
        `${url}/attachment/${type}/${dbName}/${encodedTicket}/${encodedMessage}`
      );
      console.log(response.data.attachment, "attachmentinside");
      return response.data.attachment;
    } catch (error) {
      console.log("Error fetching attachment:", error);
      return null;
    }
  };
  const handleViewAttachment = async (type, ticketId, messageId) => {
    let dbName;
    console.log("inside handleview attachment", type, ticketId, messageId);
    if (type === "Internal") {
      dbName = localStorage.getItem("dbName");
    } else if (type === "Finaran Support") {
      dbName = "ems-master";
    }
    const attachment = await fetchAttachment(dbName, type, ticketId, messageId);
    setAttachment(attachment);
    setSelectedMessage(messageId);
    showImage();
    setCount(count + 1);
    if (attachment) {
      // Display or use the attachment as needed
      console.log("Attachment fetched:", attachment);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const allowedFileTypes = [
        "image/jpeg",
        "image/png",
        "image/jpg",
        "application/pdf",
      ];

      // Check if the selected file type is in the allowed list
      if (allowedFileTypes.includes(file.type)) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const image = reader.result;
          setImage(image);
        };
        reader.readAsDataURL(file);
      } else {
        // Clear the file error message if no file is selected
        form.setFieldsValue({
          attachment: null,
        });
      }
    }
  };

  const handleImageChange1 = (e) => {
    const file = e.target.files[0];
    if (file) {
      const allowedFileTypes = [
        "image/jpeg",
        "image/png",
        "image/jpg",
        "application/pdf",
      ];

      // Check if the selected file type is in the allowed list
      if (allowedFileTypes.includes(file.type)) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const image = reader.result;
          setImage1(image);
        };
        reader.readAsDataURL(file);
      } else {
        // Clear the file error message if no file is selected
        form.setFieldsValue({
          attachment: null,
        });
      }
    }
  };
  const handleImageChange2 = (e) => {
    const file = e.target.files[0];
    if (file) {
      const allowedFileTypes = [
        "image/jpeg",
        "image/png",
        "image/jpg",
        "application/pdf",
      ];

      // Check if the selected file type is in the allowed list
      if (allowedFileTypes.includes(file.type)) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const image = reader.result;
          setImage2(image);
        };
        reader.readAsDataURL(file);
      } else {
        // Clear the file error message if no file is selected
        form.setFieldsValue({
          attachment: null,
        });
      }
    }
  };

  useEffect(() => {
    console.log("inside useeffect querydetails");
    queryDetails();
    assignedQueryDetails();
  }, [editingRecord]);

  const handleReplyInputChange = (e) => {
    // Update the reply text state variable as the user types
    setReplyText(e.target.value);
  };
  const formatDate = (dateString) => {
    if (!dateString) return "Invalid date";
    const date = new Date(dateString);
    if (isNaN(date)) return "Invalid date";
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return date.toLocaleDateString(undefined, options);
  };
  const validateInput = (rule, value, callback) => {
    const specialCharactersRegex = /[!@#$%^&*()_+{}\[\]:;<>.?~\\]/;
    if (specialCharactersRegex.test(value)) {
      callback("Special characters are not allowed.");
    } else {
      callback();
    }
  };
  const today = new Date();
  const date = today.toLocaleDateString();
  console.log(date, "date");

  return (
    <div style={{ zoom: "90%" }}>
      {user1.jobTitle !== "superAdmin" && (
        <>
          <h2>Support</h2>
        </>
      )}
      <Tabs defaultActiveKey="1">
        {user1.jobTitle != "superAdmin" && (
          <TabPane
            tab={
              <span>
                Requested Tickets
                <Badge
                  count={queryReports.length}
                  style={{ marginBottom: "5vh" }}
                />
              </span>
            }
            key="1"
          >
            <div>
              <Col style={{ padding: "20px" }}>
                <Button danger type="primary" onClick={raiseDispute}>
                  Raise a Ticket
                </Button>
              </Col>
              <div>
                <Row gutter={4}>
                  <Col span={9}>
                    {queryReports.length === 0 ? (
                      <Empty description="No Tickets to view" />
                    ) : (
                      <Col>
                        <Card style={{ height: "172vh", overflowY: "auto" }}>
                          <h6>My Open tickets({queryReports.length})</h6>
                          <br />
                          <div>
                            {queryReports?.map((queryReport) => (
                              <Col key={queryReport.UniqueId}>
                                <Card
                                  onClick={() =>
                                    handleClick(
                                      queryReport.UniqueId,
                                      queryReport.Content.Heading,
                                      queryReport.Content.Message,
                                      queryReport.MailId,
                                      queryReport.UpdatedOn,
                                      queryReport.CreatedOn,
                                      queryReport.AssignedTo,
                                      queryReport.Status,
                                      queryReport.type,
                                      queryReport.attachment
                                    )
                                  }
                                  style={{
                                    background: "#dce7fa",
                                    boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
                                    cursor: "pointer",
                                    transition: "transform 0.8s ease-in-out",
                                    transform: "scale(1)",
                                    ":hover": {
                                      transform: "scale(1.05)",
                                    },
                                  }}
                                >
                                  <Row justify="space-between">
                                    <Link
                                      style={{
                                        color: "black",
                                        fontWeight: "bold",
                                      }}
                                      onClick={() =>
                                        handleClick(
                                          queryReport.UniqueId,
                                          queryReport.Content.Heading,
                                          queryReport.Content.Message,
                                          queryReport.MailId,
                                          queryReport.UpdatedOn,
                                          queryReport.CreatedOn,
                                          queryReport.AssignedTo,
                                          queryReport.Status,
                                          queryReport.type,
                                          queryReport.attachment
                                        )
                                      }
                                    >
                                      {queryReport.UniqueId}
                                    </Link>
                                    {queryReport.Status === "OPEN" &&
                                      queryReport.AssignedTo === "--" && (
                                        <Popconfirm
                                          title="Are you sure you want to delete this Ticket?"
                                          onConfirm={() =>
                                            deleteQueryReport(
                                              queryReport.type,
                                              queryReport.UniqueId
                                            )
                                          }
                                          okText="Yes"
                                          cancelText="No"
                                        >
                                          <Button
                                            type="primary"
                                            danger
                                            icon={<DeleteFilled />}
                                          />
                                        </Popconfirm>
                                      )}
                                  </Row>
                                  <Typography.Text
                                    ellipsis={{
                                      tooltip: queryReport.Content.Heading,
                                    }}
                                  >
                                    {queryReport.Content.Heading}
                                  </Typography.Text>

                                  <Row justify="space-between">
                                    <Row>
                                      <b>Status:</b>
                                      <div
                                        style={{
                                          backgroundColor: "#e6ccff",
                                          width:
                                            queryReport.Status === "Inprogress"
                                              ? "12vh"
                                              : "7vh",
                                          height: "4vh",
                                        }}
                                      >
                                        {queryReport.Status}
                                      </div>
                                    </Row>
                                    {/* {queryReport.Priority && queryReport.Priority != "--" && */}
                                    <Row>
                                      <b>Priority:</b>
                                      <div
                                        style={{
                                          backgroundColor:
                                            queryReport.Priority != "--"
                                              ? "#f7e1c8"
                                              : "transparent",
                                          width: "10vh",
                                          height: "4vh",
                                        }}
                                      >
                                        {queryReport.Priority}
                                      </div>
                                    </Row>
                                  </Row>
                                </Card>
                                <br />
                              </Col>
                            ))}
                          </div>
                        </Card>
                      </Col>
                    )}
                  </Col>

                  <Col span={15}>
                    {card && (
                      <Col flex={3}>
                        <Card
                          title={
                            <div>
                              <p>
                                {selectedUniqueId}{" "}
                                <span style={{ marginLeft: "3vh" }}>
                                  {selectedHeading}
                                </span>
                                {selectedStatus === "Done" && (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                      gap: "2vh",
                                    }}
                                  >
                                    <Button
                                      type="primary"
                                      onClick={() =>
                                        handleUpdateStatus(
                                          "Inprogress",
                                          selectedPriority
                                        )
                                      }
                                    >
                                      Re-open
                                    </Button>
                                    <Button
                                      type="primary"
                                      onClick={() =>
                                        handleArchive(selectedUniqueId)
                                      }
                                      danger
                                    >
                                      Close
                                    </Button>
                                  </div>
                                )}
                              </p>
                              <Row>
                                {/* <div className="steps-container"> */}
                                <Steps current={2}>
                                  <Step
                                    title="Created"
                                    description={formatDate(selectedCreatedOn)}
                                    icon={
                                      <CheckCircleOutlined
                                        style={{
                                          fontSize: "18px",
                                          color: "green",
                                        }}
                                      />
                                    }
                                  />
                                  <Step
                                    title="Assigned To"
                                    description={selectedAssignedTo}
                                    icon={
                                      selectedAssignedTo === "--" ? (
                                        <ClockCircleOutlined
                                          style={{
                                            fontSize: "18px",
                                            color: "gray",
                                          }}
                                        />
                                      ) : (
                                        <CheckCircleOutlined
                                          style={{
                                            fontSize: "18px",
                                            color: "green",
                                          }}
                                        />
                                      )
                                    }
                                  />
                                  {selectedStatus != "OPEN" && (
                                    <Step
                                      title="Status"
                                      description={selectedStatus}
                                      icon={
                                        selectedStatus === "Inprogress" ? (
                                          <ClockCircleOutlined
                                            style={{
                                              fontSize: "18px",
                                              color: "gray",
                                            }}
                                          />
                                        ) : (
                                          <CheckCircleOutlined
                                            style={{
                                              fontSize: "18px",
                                              color: "green",
                                            }}
                                          />
                                        )
                                      }
                                    />
                                  )}
                                </Steps>
                                {/* </div> */}
                              </Row>
                            </div>
                          }
                        >
                          <div
                            style={{ maxHeight: "700px", overflowY: "auto" }}
                          >
                            {selectedContent &&
                            selectedContent.Message &&
                            selectedContent.Message.length > 0 ? (
                              <div>
                                {console.log(selectedContent, "content")}
                                {selectedContent.Message.map(
                                  (message, index) => (
                                    <div key={index}>
                                      <Row>
                                        <p>
                                          <Avatar icon={<UserOutlined />} />{" "}
                                          <span
                                            style={{
                                              color:
                                                user1.email ===
                                                message.senderemail
                                                  ? "blue"
                                                  : "#ed3e6a",
                                            }}
                                          >
                                            {message.senderemail}
                                          </span>
                                        </p>
                                      </Row>

                                      {console.log(message, "mesageeee")}

                                      <Row justify="space-between">
                                        <h6>{message.text}</h6>

                                        <p>{formatDate(message?.date)}</p>
                                      </Row>
                                      <Row justifyContent="space-between">
                                        {message.hasAttachment && (
                                          <Button
                                            type="text"
                                            onClick={() =>
                                              handleViewAttachment(
                                                selectedType,
                                                selectedUniqueId,
                                                message._id
                                              )
                                            }
                                            icon={<EyeOutlined />}
                                          >
                                            View Attachment
                                          </Button>
                                        )}
                                        {message._id === newReplyId &&
                                          showDeleteButton && (
                                            <Button
                                              type="link"
                                              onClick={() =>
                                                showDeleteModal(message._id)
                                              }
                                              style={{ marginLeft: "8px" }}
                                            >
                                              Delete
                                            </Button>
                                          )}
                                      </Row>
                                      <br />
                                      <br />
                                      {console.log(message, "mesageeee")}
                                      <Modal
                                        title="Confirm Deletion"
                                        visible={isModalVisible}
                                        onOk={handleOk}
                                        onCancel={handleDeleteCancel}
                                        okText="Yes"
                                        cancelText="No"
                                      >
                                        <p>
                                          Are you sure you want to delete this
                                          reply?
                                        </p>
                                      </Modal>
                                      <Modal
                                        visible={openImage}
                                        onCancel={handlecancelImage}
                                        footer={null}
                                        title="View Attachment"
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <img
                                            src={attachment}
                                            alt="Attachment"
                                            style={{
                                              width: "40vh",
                                              height: "40vh",
                                            }}
                                          />
                                          {console.log(
                                            attachment,
                                            "attachmessage"
                                          )}
                                        </div>
                                      </Modal>
                                    </div>
                                  )
                                )}
                              </div>
                            ) : (
                              <p>No messages available</p>
                            )}
                          </div>
                        </Card>
                        <Card>
                          <Form.Item name="attachment">
                            <label>
                              <PaperClipOutlined />

                              <Input
                                type="file"
                                onChange={(e) =>
                                  handleImageChange1(e, "attachment")
                                }
                                style={{ display: "none" }} // Hide the input element
                              />
                            </label>
                            {image1 && (
                              <div>
                                <img
                                  src={image1}
                                  alt="Uploaded Image"
                                  width="200"
                                />
                              </div>
                            )}
                          </Form.Item>
                          <Row>
                            <Col span={19}>
                              <TextArea
                                rows={1}
                                size={"large"}
                                placeholder="Reply"
                                value={replyText}
                                onChange={handleReplyInputChange}
                              />
                            </Col>
                            <Col span={5}>
                              <div>
                                <Button
                                  type="primary"
                                  htmlType="submit"
                                  onClick={handleSubmitReply}
                                  icon={<SendOutlined />}
                                />
                              </div>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    )}
                  </Col>
                </Row>

                <Modal
                  visible={open}
                  onCancel={handlecancel}
                  title="Ticket Details"
                  footer={null}
                >
                  {queryReports
                    .filter(
                      (queryReport) => queryReport.UniqueId === selectedUniqueId
                    )
                    .map((queryReport) => (
                      <Card bordered={true}>
                        <Descriptions
                          key={queryReport.UniqueId}
                          column={1}
                          title={`Ticket ${queryReport.UniqueId}`}
                        >
                          <Descriptions.Item label="Status">
                            {queryReport.Status}
                          </Descriptions.Item>
                          <Descriptions.Item label="Assigned To">
                            {queryReport.AssignedTo}
                          </Descriptions.Item>
                          <Descriptions.Item label="Priority">
                            {queryReport.Priority}
                          </Descriptions.Item>
                          <Descriptions.Item label="Created on">
                            {formatDate(queryReport.CreatedOn)}
                          </Descriptions.Item>
                          <Descriptions.Item label="Updated on">
                            {formatDate(queryReport.UpdatedOn)}
                          </Descriptions.Item>
                        </Descriptions>
                      </Card>
                    ))}
                </Modal>
              </div>
            </div>
          </TabPane>
        )}

        <TabPane
          tab={
            user1.jobTitle !== "superAdmin" && (
              <span>
                Assigned Tickets
                <Badge
                  count={assignedReports.length}
                  style={{ marginBottom: "5vh" }}
                />
              </span>
            )
          }
          key="2"
        >
          <div>
            <Row gutter={4}>
              <Col span={9}>
                {assignedReports.length === 0 ? (
                  <Empty description="No Tickets to view" />
                ) : (
                  <Col>
                    <Card>
                      <div style={{ maxHeight: "500px", overflowY: "auto" }}>
                        {assignedReports.map((assignedReport) => (
                          <Col key={assignedReport.UniqueId}>
                            <Card
                              onClick={() =>
                                handleClick1(
                                  assignedReport.UniqueId,
                                  assignedReport.Content.Heading,
                                  assignedReport.Content.Message,

                                  assignedReport.type
                                )
                              }
                              style={{
                                background: "#dce7fa",
                                boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
                                cursor: "pointer",
                                transition: "transform 0.8s ease-in-out",
                                transform: "scale(1)",
                                ":hover": {
                                  transform: "scale(1.05)",
                                },
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Link
                                  style={{
                                    color: "black",
                                    fontWeight: "bold",
                                  }}
                                  onClick={() =>
                                    handleClick1(
                                      assignedReport.UniqueId,
                                      assignedReport.Content.Heading,
                                      assignedReport.Content.Message,

                                      assignedReport.type
                                    )
                                  }
                                >
                                  {assignedReport.UniqueId}
                                </Link>
                                <EditOutlined onClick={showTicket1} />
                              </div>

                              <Row justify="space-between">
                                <Meta
                                  style={{ marginTop: "1vh" }}
                                  title={assignedReport.Content.Heading}
                                />
                                <span>
                                  {new Date(
                                    assignedReport.CreatedOn
                                  ).toLocaleDateString("en-GB", {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                  })}
                                </span>
                              </Row>
                              <Row justify="space-between">
                                <Row>
                                  <b>Status:</b>
                                  <div
                                    style={{
                                      backgroundColor: "#e6ccff",
                                      width:
                                        assignedReport.Status === "Inprogress"
                                          ? "12vh"
                                          : "7vh",
                                      height: "4vh",
                                    }}
                                  >
                                    {assignedReport.Status}
                                  </div>
                                </Row>
                                <Row>
                                  <b>Priority:</b>
                                  <div
                                    style={{
                                      backgroundColor:
                                        assignedReport.Priority != "--"
                                          ? "#f7e1c8"
                                          : "transparent",
                                      width: "10vh",
                                      height: "4vh",
                                    }}
                                  >
                                    {assignedReport.Priority}
                                  </div>
                                </Row>
                              </Row>
                            </Card>
                            <br />
                          </Col>
                        ))}
                      </div>
                    </Card>
                  </Col>
                )}
              </Col>
              <Col span={15}>
                {card1 && (
                  <Col flex={3}>
                    <Card
                      title={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            fontSize: "20px",
                            fontWeight: "bold",
                          }}
                        >
                          <div>
                            <p>
                              {selectedUniqueId1}{" "}
                              <span style={{ marginLeft: "2vh" }}>
                                {selectedHeading1}
                              </span>
                            </p>
                          </div>
                          {/* <div>
                            {selectedStatus === "Done" && (
                              <Button
                                type="primary"
                                onClick={() => handleArchive(selectedUniqueId1)}
                              >
                                Archive
                              </Button>
                            )}
                          </div> */}
                        </div>
                      }
                    >
                      <div style={{ maxHeight: "700px", overflowY: "auto" }}>
                        {selectedContent1 &&
                        selectedContent1.Message &&
                        selectedContent1.Message.length > 0 ? (
                          <div>
                            {selectedContent1.Message.map((message, index) => (
                              <div key={index}>
                                <p>
                                  <Avatar icon={<UserOutlined />} />{" "}
                                  {message.senderemail}
                                </p>
                                <Row justify="space-between">
                                  <h6>{message.text}</h6>
                                  <p>{formatDate(message?.date)}</p>
                                </Row>{" "}
                                {console.log("message in assigned", message)}
                                {message.hasAttachment && (
                                  <Button
                                    type="text"
                                    onClick={() =>
                                      handleViewAttachment(
                                        selectedType1,
                                        selectedUniqueId1,
                                        message._id
                                      )
                                    }
                                    icon={<EyeOutlined />}
                                  >
                                    View Attachment
                                  </Button>
                                )}{" "}
                                <Modal
                                  visible={openImage}
                                  onCancel={handlecancelImage}
                                  footer={null}
                                  title="View Attachment"
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <img
                                      src={attachment}
                                      alt="Attachment"
                                      style={{
                                        width: "40vh",
                                        height: "40vh",
                                      }}
                                    />
                                    {console.log(attachment, "attachmessage")}
                                  </div>
                                </Modal>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <p>No messages available</p>
                        )}
                      </div>
                    </Card>
                    <Card>
                      <Form.Item name="attachment">
                        <label>
                          <PaperClipOutlined />

                          <Input
                            type="file"
                            onChange={(e) =>
                              handleImageChange2(e, "attachment")
                            }
                            style={{ display: "none" }} // Hide the input element
                          />
                        </label>
                        {image2 && (
                          <div>
                            <img
                              src={image2}
                              alt="Uploaded Image"
                              width="200"
                            />
                          </div>
                        )}
                      </Form.Item>
                      <Row>
                        <Col span={19}>
                          <TextArea
                            rows={1}
                            size={"large"}
                            placeholder="Reply"
                            value={replyText}
                            onChange={handleReplyInputChange}
                          />
                        </Col>
                        <Col span={5}>
                          <div>
                            <Button
                              type="primary"
                              htmlType="submit"
                              onClick={handleSubmitReply1}
                              icon={<SendOutlined />}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                )}
              </Col>
            </Row>
            <Modal visible={open1} onCancel={handlecancel1} footer={null}>
              <Card bordered={true}>
                {assignedReports
                  .filter(
                    (assignedReports) =>
                      assignedReports.UniqueId === selectedUniqueId1
                  )
                  .map((assignedReport) => (
                    <Descriptions
                      key={assignedReport.UniqueId}
                      column={1}
                      title={`Ticket ${assignedReport.UniqueId}`}
                    >
                      <Descriptions.Item label="Status">
                        <Select
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode
                          }
                          defaultValue={assignedReport.Status}
                          style={{ width: 150 }}
                          onChange={(value) => setSelectedStatus(value)}
                        >
                          <Select.Option value="OPEN">OPEN</Select.Option>
                          <Select.Option value="Inprogress">
                            IN PROGRESS
                          </Select.Option>
                          <Select.Option value="Done">DONE</Select.Option>
                        </Select>
                      </Descriptions.Item>
                      {user1.jobTitle !== "superAdmin" && (
                        <Descriptions.Item label="Assigned To">
                          {/* <Select
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentNode
                            }
                            defaultValue={assignedReport.AssignedTo}
                            style={{ width: 150 }}
                            onChange={(value) => setSelectedAssignedTo(value)}
                          >
                            {users.map((user) => (
                              <option
                                key={user.id}
                                value={`${user.displayName}(${user.email})`}
                              >
                                {user.displayName}({user.email})
                              </option>
                            ))}
                          </Select> */}
                          <Input value={assignedReport.AssignedTo} />
                        </Descriptions.Item>
                      )}
                      <Descriptions.Item label="Priority">
                        <Select
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode
                          }
                          defaultValue={assignedReport.Priority}
                          style={{ width: 150 }}
                          onChange={(value) => setSelectedPriority(value)}
                        >
                          <Select.Option value="Low">Low</Select.Option>
                          <Select.Option value="Medium">Medium</Select.Option>
                          <Select.Option value="High">High</Select.Option>
                        </Select>
                      </Descriptions.Item>
                      <Descriptions.Item label="Created on">
                        {formatDate(assignedReport.CreatedOn)}
                      </Descriptions.Item>
                      <Descriptions.Item label="Updated on">
                        {formatDate(assignedReport.UpdatedOn)}
                      </Descriptions.Item>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "16px",
                        }}
                      >
                        <Button
                          type="primary"
                          onClick={() =>
                            handleUpdate(
                              selectedStatus,
                              selectedAssignedTo,
                              selectedPriority
                            )
                          }
                        >
                          Update
                        </Button>
                      </div>
                    </Descriptions>
                  ))}
              </Card>
            </Modal>
          </div>
        </TabPane>
      </Tabs>

      <Modal
        title="Raise an Ticket"
        visible={model}
        onCancel={handleCancel}
        footer={null}
        width={430}
      >
        <Form form={form} onFinish={handleSubmit}>
          <Form.Item
            label="Ticket:"
            name="Heading"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 12 }}
            rules={[
              {
                required: true,
                message: "",
              },
              {
                validator: validateInput,
              },
            ]}
          >
            <TextArea
              placeholder="Enter Ticket"
              style={{ marginTop: "10px" }}
              rows={1}
              onChange={(e) => setHeading(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            label="Type"
            name="type"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 12 }}
            rules={[
              {
                required: true,
                message: "",
              },
              {
                validator: validateInput,
              },
            ]}
          >
            <Select
              style={{ marginTop: "10px" }}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              placeholder="Select Type"
            >
              <Select.Option value="Internal">Internal</Select.Option>
              <Select.Option value="Finaran Support">
                Finaran Support
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Description"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 12 }}
          >
            <div style={{ position: "relative" }}>
              <TextArea
                style={{ marginTop: "10px" }}
                rows={1}
                placeholder="Describe your issue here"
                value={issue}
                onChange={(e) => setIssue(e.target.value)}
              />
            </div>
          </Form.Item>
          <Form.Item
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 12 }}
            label="Attach File"
            name="attachment"
          >
            <label className="custom-file-upload">
              <UploadOutlined />
              Upload File
              <Input
                type="file"
                onChange={(e) => handleImageChange(e, "attachment")}
                style={{ display: "none" }} // Hide the input element
              />
            </label>
            {image && (
              <div>
                <img src={image} alt="Uploaded Image" width="200" />
              </div>
            )}
          </Form.Item>
          <Form.Item>
            <Button style={{ left: "30vh" }} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default RaiseIssue;
