import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  DatePicker,
  Select,
  Row,
  Col,
  Divider,
  Card,
  Modal,
} from "antd";
import axios from "axios";
import { CalendarOutlined } from "@ant-design/icons";
import { url } from "../../url/url";

const { Option } = Select;

const RecordResignationTab = () => {
  const [form] = Form.useForm();
  const [user, setUser] = useState(null);
  const [separationDetails, setSeparationDetails] = useState([]);
  const [filteredReasons, setFilteredReasons] = useState([]);
  const [notice, setNotice] = useState();
  const [status, setStatus] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pullbackReason, setPullbackReason] = useState("");

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("user"));
    setUser(storedUser);
    fetchType();
    fetchStatus(storedUser);
  }, []);

  const fetchType = async () => {
    const dbName = localStorage.getItem("dbName");
    const res = await axios.get(`${url}/get/combined/${dbName}`);
    console.log(res.data[0].separationDetails, "details");
    setSeparationDetails(res.data[0].separationDetails || []);
    setNotice(res.data[0].noticePeriodDays);
  };

  const fetchStatus = async (storedUser) => {
    try {
      const dbName = localStorage.getItem("dbName");
      const empcode = storedUser.empCode;
      console.log(empcode, "code");
      const response = await axios.get(
        `${url}/api/separation/status/${dbName}`,
        {
          params: { empcode },
        }
      );
      setStatus(response.data[0].status);
    } catch (error) {
      console.error("Error fetching status:", error);
      alert("Failed to fetch status");
    }
  };

  const handleSeparationTypeChange = (value) => {
    const reasons = separationDetails
      .filter((item) => item.separationType === value)
      .map((item) => item.resignationReason);

    setFilteredReasons(reasons);
    form.setFieldsValue({ resignation: undefined });
  };
  const handleSave = async (values) => {
    try {
      const separationData = {
        employeeId: user?.empCode,
        employeeName: user?.displayName,
        dateofjoining: user?.joinDate,
        designation: user?.empDesignation,
        managerName: user?.manager,
        hrName: user?.hrName,
        separationType: values.separation,
        resignationReason: values.resignation,
        resignationLetterDate: values.resignationLetterDate
          ? values.resignationLetterDate.format("YYYY-MM-DD")
          : null,
        actualRelievingDate: values.actualRelievingDate
          ? values.actualRelievingDate.format("YYYY-MM-DD")
          : null,
        requestedRelievingDate: values.requestedRelievingDate
          ? values.requestedRelievingDate.format("YYYY-MM-DD")
          : null,
        noticePeriodDays: notice,
        leavesTakenDuringNotice: 10,
        employeeComments: values.employeeComments,
        clearanceActivityStatus: "Pending",
      };

      const dbName = localStorage.getItem("dbName");
      const response = await axios.post(
        `${url}/api/separation/${dbName}`,
        separationData
      );
      console.log("Response:", response.data);
      alert("Separation record created successfully");
      form.resetFields();
    } catch (error) {
      console.error("Error saving resignation:", error);
      alert("Failed to save separation record");
    }
  };
  const showPullbackModal = () => {
    setIsModalVisible(true);
  };

  const handlePullbackSubmit = async () => {
    try {
      const dbName = localStorage.getItem("dbName");
      const separationData = {
        employeeId: user?.empCode,
        pullbackReason,
        status: "Pulled back",
      };
      const response = await axios.post(
        `${url}/api/separation/${dbName}`,
        separationData
      );
      alert("Record pulled back successfully");
      setIsModalVisible(false);
      setPullbackReason("");
    } catch (error) {
      console.error("Error saving resignation:", error);
      alert("Failed to save separation record");
    }
  };

  return (
    <div style={{ position: "relative", minHeight: "100vh" }}>
      {console.log(status, "status")}
      {status === "Submitted to manager" ||
        (status === "Pulled back" && (
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              margin: "16px",
            }}
          >
            <Button
              style={{ display: "flex", justifyItems: "flex-end" }}
              onClick={showPullbackModal}
              disabled={status === "Pulled back"}
            >
              Pull Back
            </Button>
          </div>
        ))}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <Card
          style={{
            width: "80%",
            maxWidth: 1200,
            backgroundColor: "transparent",
            boxShadow: "none",
          }}
          bodyStyle={{ padding: 0 }}
        >
          <Form form={form} layout="vertical" onFinish={handleSave}>
            <Row gutter={[16, 16]}>
              <Col span={10}>
                <div style={{ textAlign: "left" }}>
                  <p>
                    Employee Name:
                    <br />
                    {user?.displayName}
                  </p>
                  <p>
                    Date of Joining:
                    <br />
                    {user?.joinDate}
                  </p>
                  <p>
                    Base Location:
                    <br />
                    {user?.officeLocation ? "Office" : "Remote"}
                  </p>
                  <p>
                    Grade:
                    <br />
                    Not Added
                  </p>
                  <p>
                    Functional Manager:
                    <br />
                    {user?.manager} ({user?.empCode}) - {user?.organization}
                  </p>

                  <Form.Item
                    name="separation"
                    label="Separation Type"
                    rules={[
                      {
                        required: true,
                        message: "Please select separation Type!",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select your separation type"
                      onChange={handleSeparationTypeChange}
                    >
                      {separationDetails.map((item) => (
                        <Option
                          key={item.separationType}
                          value={item.separationType}
                        >
                          {item.separationType}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="resignation"
                    label="Resignation Reason"
                    rules={[
                      {
                        required: true,
                        message: "Please select resignation reason!",
                      },
                    ]}
                  >
                    <Select placeholder="Select your resignation reason">
                      {filteredReasons.map((reason, index) => (
                        <Option key={index} value={reason}>
                          {reason}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="resignationLetterDate"
                    label="Resignation Letter Date"
                    rules={[
                      {
                        required: true,
                        message: "Please select resignation letter date!",
                      },
                    ]}
                  >
                    <DatePicker />
                  </Form.Item>

                  <p>Notice Period (In Calendar Days):{notice}</p>

                  <p>Number of Leaves Taken During Notice Period:</p>

                  <Form.Item
                    name="employeeComments"
                    label="Employee Comments"
                    rules={[
                      {
                        required: true,
                        message: "Please provide the Comment!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </div>
              </Col>

              <Divider type="vertical" style={{ height: 0, width: "8vh" }} />

              <Col span={10}>
                <div style={{ textAlign: "left" }}>
                  <p>
                    Employment Number:
                    <br />
                    {user?.empCode}
                  </p>
                  <p>
                    Business Units:
                    <br />
                    {user?.department}
                  </p>
                  <p>
                    Work Location:
                    <br />
                    {user?.officeLocation ? "Office" : "Remote"}
                  </p>
                  <p>
                    Designation:
                    <br />
                    {user?.empDesignation}
                  </p>
                  <p>
                    Reporting Manager:
                    <br />
                    {user?.manager}
                  </p>
                  <p>
                    Leave Balance:
                    <br />
                    <CalendarOutlined />
                  </p>

                  <Form.Item
                    name="requestedRelievingDate"
                    label="Requested Relieving Date"
                    rules={[
                      {
                        required: true,
                        message: "Please select relieving Date!",
                      },
                    ]}
                  >
                    <DatePicker />
                  </Form.Item>
                </div>
                <br />
                <Form.Item name="accessories" label="Accessories">
                  <ul>
                    {(user?.accessaries || []).map((accessory, index) => (
                      <li key={index}>{accessory}</li>
                    ))}
                  </ul>
                </Form.Item>

                <div style={{ textAlign: "right", marginRight: 12 }}>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
      <Modal
        title="Pull Back Reason"
        visible={isModalVisible}
        onOk={handlePullbackSubmit}
        onCancel={() => setIsModalVisible(false)}
      >
        <Form.Item
          label="Reason for Pull Back"
          rules={[
            {
              required: true,
              message: "Please provide the reason for pullback!",
            },
          ]}
        >
          <Input
            value={pullbackReason}
            onChange={(e) => setPullbackReason(e.target.value)}
          />
        </Form.Item>
      </Modal>
    </div>
  );
};

export default RecordResignationTab;
