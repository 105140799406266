// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.first-sider .ant-menu.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #00134d;
  border-radius: 5px;
  color: #ffffff;
}

.ant-menu>.ant-menu-item:hover span {
  color: rgb(250, 177, 21);
  /* Change icon color to green */
}

.ant-menu-submenu:hover .ant-menu-submenu-title>span {
  /* Change the color to green */
  color: rgb(8, 253, 253) !important;
}

.main-container {
  display: flex;
  flex-direction: row;
}

.content {
  flex: 1 1;
  transition: margin-left 0.5s;
}

.content-collapsed {
  margin-left: 80px;
  /* Adjust this width according to your design */
}`, "",{"version":3,"sources":["webpack://./src/components/sider/sider.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,wBAAwB;EACxB,+BAA+B;AACjC;;AAEA;EACE,8BAA8B;EAC9B,kCAAkC;AACpC;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,SAAO;EACP,4BAA4B;AAC9B;;AAEA;EACE,iBAAiB;EACjB,+CAA+C;AACjD","sourcesContent":[".first-sider .ant-menu.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {\n  background-color: #00134d;\n  border-radius: 5px;\n  color: #ffffff;\n}\n\n.ant-menu>.ant-menu-item:hover span {\n  color: rgb(250, 177, 21);\n  /* Change icon color to green */\n}\n\n.ant-menu-submenu:hover .ant-menu-submenu-title>span {\n  /* Change the color to green */\n  color: rgb(8, 253, 253) !important;\n}\n\n.main-container {\n  display: flex;\n  flex-direction: row;\n}\n\n.content {\n  flex: 1;\n  transition: margin-left 0.5s;\n}\n\n.content-collapsed {\n  margin-left: 80px;\n  /* Adjust this width according to your design */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
