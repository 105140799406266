import React from 'react';
import { Navigate, Outlet, Route } from 'react-router-dom';

const ProtectedRoute = () => {

  const token = localStorage.getItem('accessToken');
  const userRole = localStorage.getItem('userRole');

  return (
    token ?

      <Outlet /> : <Navigate to='/login' />
  )




};

export default ProtectedRoute;

