import React, { useEffect, useState } from "react";
import {
  Card,
  Form,
  Input,
  Select,
  Checkbox,
  Button,
  Row,
  Col,
  notification,
  Radio,
} from "antd";
import { url } from "../../url/url";
import axios from "axios";
import { CheckCircleOutlined } from "@ant-design/icons";
const tailFormItemLayout = {
  wrapperCol: {
    xs: { span: 12 },
    sm: { span: 24 },
  },
};

const PolicyAcknowledgmentForm = () => {
  const [form] = Form.useForm();
  const [employeeName, setEmployeeName] = useState("");
  const [policyAcknowledged, setPolicyAcknowledged] = useState(null);
  const [selectedPolicy, setSelectedPolicy] = useState(null);
  const [selectedPolicy1, setSelectedPolicy1] = useState(null);
  const [data, setData] = useState([]);
  const { Option } = Select;
  const users = JSON.parse(localStorage.getItem("user"));
  const [policy, setPolicy] = useState([]);
  useEffect(() => {
    fetchData();
    fetchData1();
  }, []);

  const fetchData = async () => {
    try {
      const dbName = localStorage.getItem("dbName");
      const response = await axios.get(`${url}/getPolicies/${dbName}`);
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handlePolicyChange = (policyName) => {
    const selectedPolicy = data?.find(
      (item) => item.name === policyName
    );
    if (selectedPolicy) {
      form.setFieldsValue({ policyId: selectedPolicy.policyId });
      setSelectedPolicy(selectedPolicy.policy);
      setSelectedPolicy1(selectedPolicy.name);
    }
  };

  const handleAcknowledgmentChange = (e) => {
    setPolicyAcknowledged(e.target.value);
  };
  const handleSubmit = async (values) => {
    if (policyAcknowledged === null) {
      alert("Please acknowledge the policy.");
      return;
    }
    const dbName = localStorage.getItem("dbName");
    const payload = {
      policyId: values.policyId,
      policyName: values.policy,
      userName: users.displayName,
      acknowledgment: policyAcknowledged,
    };

    try {
      await axios.post(`${url}/policy-acknowledgment/${dbName}`, payload);
      notification.success({
        message: `Acknowledgment submitted successfully!`,
        duration: 3,
      });
      form.resetFields();
    } catch (error) {
      console.error("Error submitting acknowledgment:", error);
      notification.error({
        message: `Error submitting acknowledgment`,
        duration: 3,
      });
    }
  };

  const fetchData1 = async () => {
    const dbName = localStorage.getItem("dbName");
    try {
      const response = await axios.get(
        `${url}/get/policy-acknowledgment/${dbName}`
      );
      setPolicy(response.data);
    } catch {
      console.log("error");
    }
  };
  return (
    <div>
      <h5 className="text-center">Policy Acknowledgement</h5>
      <br />
      <Form form={form} onFinish={handleSubmit}>
        <Row justify="space-around">
          <Form.Item
            name="policy"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            label="Policy"
            rules={[
              {
                required: true,
                message: "Please select a policy!",
              },
            ]}
          >
            <Select
              placeholder="Select your policy"
              style={{ width: "25vh" }}
              onChange={handlePolicyChange}
            >
              {data?.map((item) => (
                <Option key={item.name} value={item.name}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 14 }}
            name="policyId"
            label="Policy ID"
            rules={[
              {
                required: true,
                message: "Please select a policy ID!",
              },
            ]}
          >
            <Input style={{ height: "6vh" }} disabled />
          </Form.Item>
        </Row>
        {selectedPolicy1 && (
          <>
            <iframe
              id="pdf-viewer"
              src={selectedPolicy}
              frameBorder="0"
              title="Document Viewer"
              style={{
                width: "170vh",
                height: "120vh",
                border: "1px solid #ccc",
                marginTop: "5px",
              }}
            ></iframe>
            <Form.Item
              label="Employee Name"
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 14 }}
              style={{ marginTop: "2vh" }}
              rules={[
                {
                  required: true,
                  message: "Please enter a name!",
                },
              ]}
            >
              <Input value={users.displayName} disabled />
            </Form.Item>
            {(() => {
              const filteredPolicies = policy.filter(
                (item) =>
                  item.policyName === selectedPolicy1 &&
                  users.displayName === item.userName
              );

              if (filteredPolicies.length === 0) {
                // If no matching policies are found, display the acknowledgment form
                return (
                  <div>
                    <h6
                      className="text-center"
                      style={{ fontSize: "14px", fontWeight: "bold" }}
                    >
                      I certify that I am the employee listed above and that I
                      have read the selected policy.
                    </h6>
                    <Form.Item
                      labelCol={{ span: 6 }}
                      wrapperCol={{ span: 14 }}
                      name="agreement"
                      label="Acknowledgement"
                      {...tailFormItemLayout}
                    >
                      <Radio.Group onChange={handleAcknowledgmentChange}>
                        <Radio value="agree">I agree with the policy.</Radio>
                        <Radio value="disagree">
                          I disagree with the policy.
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 11, span: 12 }}>
                      <Button type="primary" htmlType="submit">
                        Submit
                      </Button>
                    </Form.Item>
                  </div>
                );
              }

              return filteredPolicies.map((item) =>
                item.acknowledgment === "agree" ? (
                  <h6
                    key={item._id}
                    style={{ color: "green" }}
                    className="text-center"
                  >
                    <CheckCircleOutlined /> You have already acknowledged the{" "}
                    {item.policyName}.
                  </h6>
                ) : (
                  <div key={item._id}>
                    <h6
                      className="text-center"
                      style={{ fontSize: "14px", fontWeight: "bold" }}
                    >
                      I certify that I am the employee listed above and that I
                      have read the selected policy.
                    </h6>
                    <Form.Item
                      labelCol={{ span: 6 }}
                      wrapperCol={{ span: 14 }}
                      name="agreement"
                      label="Acknowledgement"
                      {...tailFormItemLayout}
                    >
                      <Radio.Group onChange={handleAcknowledgmentChange}>
                        <Radio value="agree">I agree with the policy.</Radio>
                        <Radio value="disagree">
                          I disagree with the policy.
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 11, span: 12 }}>
                      <Button type="primary" htmlType="submit">
                        Submit
                      </Button>
                    </Form.Item>
                  </div>
                )
              );
            })()}
          </>
        )}
      </Form>
    </div>
  );
};

export default PolicyAcknowledgmentForm;
