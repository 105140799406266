import {
  AlertOutlined,
  CalendarFilled,
  CalendarOutlined,
  ClockCircleOutlined,
  DeleteFilled,
  DeleteOutlined,
  EditOutlined,
  EyeFilled,
  PictureOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { CardContent, Tooltip } from "@mui/material";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Switch,
  message,
  notification,
} from "antd";
import FormItem from "antd/es/form/FormItem";
import React, { useEffect, useState } from "react";
import { url } from "../../url/url";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ColorPicker from "./ColorPicker";
import axios from "axios";
import { Link } from "react-router-dom";

const layout = {
  labelCol: {
    span: 7,
  },
  wrapperCol: {
    span: 140,
  },
};

const AdminDashboard = () => {
  const storedValue = localStorage.getItem("switchState");
  const [checked, setChecked] = useState(storedValue);
  const [calendarvisible, setcalendarvisible] = useState(false);
  const [isVisible1, setIsVisible1] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  const [isVisible3, setIsVisible3] = useState(false);
  const [isVisible4, setIsVisible4] = useState(false);
  const [isLeavetype, setIsLeavetype] = useState(false);
  const [visible, setvisible] = useState(false);
  const [visible1, setvisible1] = useState(false);
  const [visible2, setvisible2] = useState(false);
  const [visible3, setvisible3] = useState(false);
  const [visible4, setvisible4] = useState(false);
  const [visible5, setvisible5] = useState(false);
  const [visible6, setvisible6] = useState(false);
  const [visible7, setvisible7] = useState(false);
  const [visible8, setvisible8] = useState(false);
  const [visible9, setvisible9] = useState(false);
  const [visible10, setvisible10] = useState(false);
  const [visible11, setvisible11] = useState(false);
  const [leavetype, setLeaveType] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [header, setHeader] = useState(false);
  const [footer, setFooter] = useState(false);
  const [anniversary, setAnniversary] = useState(false);
  const [birthday, setBirthday] = useState(false);
  const [file, setFile] = useState([]);
  const [form] = Form.useForm();
  const [count, setCount] = useState(0);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedPolicy, setSelectedPolicy] = useState(null);
  const [Images, setImage] = useState("");
  const [Images1, setImage1] = useState("");
  const [letterheader, setLetterHeader] = useState("");
  const [letterfooter, setLetterFooter] = useState("");
  const [birthdayCard, setBirthdayCard] = useState("");
  const [anniversaryCard, setAnniversaryCard] = useState("");
  const thiru = localStorage.getItem("thirukkural");
  const [isEnabled, setIsEnabled] = useState(thiru);
  const [checkedBirthday, setCheckedBirthday] = useState(false);
  const [checkedAnniversary, setCheckedAnniversary] = useState(false);
  const [data, setData] = useState([]);
  const [pdfModalVisible, setPdfModalVisible] = useState(false);
  const [pdfModalVisible1, setPdfModalVisible1] = useState(false);
  const [birthdayMessage, setBirthdayMessage] = useState("");
  const [anniversaryMessage, setAnniversaryMessage] = useState("");
  const [isEditable, setIsEditable] = useState(false);
  const [isEditable1, setIsEditable1] = useState(false);
  const toggleEditMode = () => {
    setIsEditable(!isEditable);
  };

  const toggleEditMode1 = () => {
    setIsEditable1(!isEditable1);
  };
  const handleOpenPdfModal = () => {
    setPdfModalVisible(true);
  };

  const handleOpenPdfModal1 = (item) => {
    setPdfModalVisible1(true);
    setSelectedPolicy(item);
  };

  // Function to handle closing the PDF modal
  const handleClosePdfModal = () => {
    setPdfModalVisible(false);
  };

  const handleClosePdfModal1 = () => {
    setPdfModalVisible1(false);
  };
  const fetchData = async () => {
    try {
      // Replace 'dbName' with the actual database name
      const dbName = localStorage.getItem("dbName");
      console.log(new Date(), "before calling api");
      // Make a GET request to the backend API
      const response = await axios.get(`${url}/get/combined/${dbName}`);
      console.log(new Date(), response.data, "after calling api");
      // Update the state with the fetched data
      setData(response.data);

      // setBirthdayMessage(response.data[0].birthdayMessage);
      // setAnniversaryMessage(response.data[0].anniversaryMessage);
    } catch (error) {
      // Handle errors
      console.error("Error fetching data:", error);
    }
  };

  // Fetch data when the component mounts
  useEffect(() => {
    fetchData();
  }, [count]);
  const open = () => {
    setvisible(true);
  };
  const open1 = () => {
    setvisible1(true);
  };

  const openheader = () => {
    setHeader(true);
  };
  const openfooter = () => {
    setFooter(true);
  };

  const openAnniversary = () => {
    setAnniversary(true);
  };
  const openBirthday = () => {
    setBirthday(true);
  };

  const open2 = () => {
    setvisible2(true);
  };
  const open3 = () => {
    setvisible3(true);
  };
  const open4 = () => {
    setvisible4(true);
  };
  const open5 = () => {
    setvisible5(true);
  };
  const open6 = () => {
    setvisible6(true);
  };
  const open7 = () => {
    setvisible7(true);
  };
  const open8 = () => {
    setvisible8(true);
  };
  const open9 = () => {
    setvisible9(true);
  };
  const open10 = () => {
    setvisible10(true);
  };
  const open12 = () => {
    setLeaveType(true);
  };
  const open11 = () => {
    setvisible11(true);
  };
  const handleOk = () => {
    setcalendarvisible(true);
  };
  const handleOk1 = () => {
    setIsVisible1(true);
  };
  const handleOk2 = () => {
    setIsVisible2(true);
  };
  const handleOk3 = () => {
    setIsVisible3(true);
  };
  const handleOk4 = () => {
    setIsLeavetype(true);
  };
  const handleCancel = () => {
    setcalendarvisible(false);
  };

  function handleDateInput(date) {
    setSelectedDate(date);
    setModalOpen(true);
  }

  const handleChangeBirthday = async (isBirthdayEnabled) => {
    setCheckedBirthday(isBirthdayEnabled);
    await onFinishBirthdayreminder(isBirthdayEnabled);
  };
  const handleChange1 = async (isEnabled) => {
    setIsEnabled(isEnabled);
    await onFinish2(isEnabled);
    localStorage.setItem("thirukural", JSON.stringify(isEnabled));
  };

  const handleChangeAnniversary = async (isanniversaryenabled) => {
    setCheckedAnniversary(isanniversaryenabled);
    await onFinishAnniversaryreminder(isanniversaryenabled);
  };

  const handleChange = async (newChecked) => {
    setChecked(newChecked);

    try {
      await onFinish1(newChecked);
      // Assuming switchType is defined elsewhere
      // Update server immediately
      localStorage.setItem("switchState", JSON.stringify(newChecked));
      console.log(newChecked, "newchecked in handlecahnge");

      // Call onFinish function with the updated values
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const storedValue = localStorage.getItem("switchState");
    const parsedValue = storedValue ? JSON.parse(storedValue) : false;
    setChecked(parsedValue);
  }, []);

  const onImageSubmit1 = (e) => {
    const file = e.target.files[0];

    const reader = new FileReader();
    reader.onloadend = () => {
      setImage1(reader.result);
    };
    reader.readAsDataURL(file);
  };
  const onHeaderSubmit = (e) => {
    const file = e.target.files[0];

    const reader = new FileReader();
    reader.onloadend = () => {
      setLetterHeader(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const onFooterSubmit = (e) => {
    const file = e.target.files[0];

    const reader = new FileReader();
    reader.onloadend = () => {
      setLetterFooter(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const onAnniversaryChange = (e) => {
    const file = e.target.files[0];

    const reader = new FileReader();
    reader.onloadend = () => {
      setAnniversaryCard(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const onBirthdayChange = (e) => {
    const file = e.target.files[0];

    const reader = new FileReader();
    reader.onloadend = () => {
      setBirthdayCard(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    const reader = new FileReader();
    reader.onloadend = () => {
      setFile(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const onFinishBirthdayreminder = async (values) => {
    const dbName = localStorage.getItem("dbName");
    console.log(values, "val");

    try {
      const type = "birthdayReminder";
      const response = await fetch(`${url}/admin/combined/${type}/${dbName}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ values }),
      });

      if (response.ok) {
        const responseData = await response.json();
        console.log("Data posted:", responseData);
        notification.success({
          message: `Posted Successfully!`,
          duration: 3,
        });
      } else {
        throw new Error(
          `Failed to post data: ${response.status} ${response.statusText}`
        );
      }
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };

  const onFinishAnniversaryreminder = async (values) => {
    const dbName = localStorage.getItem("dbName");
    try {
      const type = "anniversaryReminder";
      const response = await fetch(`${url}/admin/combined/${type}/${dbName}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ values }),
      });

      if (response.ok) {
        const responseData = await response.json();
        console.log("Data posted:", responseData);
        notification.success({
          message: `Posted Successfully!`,
          duration: 3,
        });
      } else {
        throw new Error(
          `Failed to post data: ${response.status} ${response.statusText}`
        );
      }
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };
  const onFinish2 = async (values) => {
    const dbName = localStorage.getItem("dbName");
    try {
      const type = "thirukural";
      const response = await fetch(`${url}/admin/combined/${type}/${dbName}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ values }),
      });

      if (response.ok) {
        const responseData = await response.json();
        console.log("Data posted:", responseData);
        notification.success({
          message: `Posted Successfully!`,
          duration: 3,
        });
      } else {
        throw new Error(
          `Failed to post data: ${response.status} ${response.statusText}`
        );
      }
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };
  const handleDeleteLeave = async (type) => {
    const dbName = localStorage.getItem("dbName");
    try {
      // Make a DELETE request to the backend API
      const response = await axios.delete(
        `${url}/remove/adminleave/${dbName}/${type}`
      );
      // Handle success response
      console.log(response.data);

      setCount(count + 1);
      // Refresh the data after deletion if needed
      // Call a function to fetch and update the holidays data
    } catch (error) {
      // Handle error response
      console.error(error.response.data.message);
    }
  };

  const onFinish1 = async (values) => {
    const dbName = localStorage.getItem("dbName");
    try {
      const type = "location";
      const response = await fetch(`${url}/admin/combined/${type}/${dbName}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ values }),
      });

      if (response.ok) {
        const responseData = await response.json();
        console.log("Data posted:", responseData);
        notification.success({
          message: `Posted Successfully!`,
          duration: 3,
        });
      } else {
        throw new Error(
          `Failed to post data: ${response.status} ${response.statusText}`
        );
      }
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };
  const onFinish3 = async (values) => {
    const dbName = localStorage.getItem("dbName");
    try {
      let type;
      console.log(values, "val");

      if ("Logo" in values) {
        type = "images";
        values.Logo = Images1;
      }

      const response = await axios.post(
        `${url}/admin/combined/${type}/${dbName}`,
        values
      );
      console.log("Data posted:", response.data);
      setCount(count + 1);
      form.resetFields();
      setvisible1(false);
      notification.success({
        message: `Posted Successfully!`,
        duration: 3,
      });
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };

  const onFinish4 = async (values) => {
    const dbName = localStorage.getItem("dbName");
    try {
      let type;
      console.log(values, "val");
      type = "header";
      values.letterheader = letterheader;
      const response = await axios.post(
        `${url}/admin/combined/${type}/${dbName}`,
        values
      );
      console.log("Data posted:", response.data);
      setCount(count + 1);
      form.resetFields();
      setHeader(false);
      notification.success({
        message: `Posted Successfully!`,
        duration: 3,
      });
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };

  const onFinish5 = async (values) => {
    const dbName = localStorage.getItem("dbName");
    try {
      let type;
      console.log(values, "val");
      type = "footer";
      values.letterfooter = letterfooter;
      const response = await axios.post(
        `${url}/admin/combined/${type}/${dbName}`,
        values
      );
      console.log("Data posted:", response.data);
      setCount(count + 1);
      form.resetFields();
      setFooter(false);
      notification.success({
        message: `Posted Successfully!`,
        duration: 3,
      });
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };
  const onBirthdayFinish = async (values) => {
    const dbName = localStorage.getItem("dbName");
    try {
      let type;
      console.log(values, "val");
      type = "birthday";
      values.birthday = birthdayCard;
      values.birthdayMessage = birthdayMessage;
      const response = await axios.post(
        `${url}/admin/combined/${type}/${dbName}`,
        values
      );
      console.log("Data posted:", response.data);
      setCount(count + 1);
      form.resetFields();
      setBirthday(false);
      notification.success({
        message: `Posted Successfully!`,
        duration: 3,
      });
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };
  const onAnniversaryFinish = async (values) => {
    const dbName = localStorage.getItem("dbName");
    try {
      let type;
      console.log(values, "val");
      type = "anniversary";
      values.anniversary = anniversaryCard;
      values.anniversaryMessage = anniversaryMessage;
      const response = await axios.post(
        `${url}/admin/combined/${type}/${dbName}`,
        values
      );
      console.log("Data posted:", response.data);
      setCount(count + 1);
      form.resetFields();
      setAnniversary(false);
      notification.success({
        message: `Posted Successfully!`,
        duration: 3,
      });
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };
  const onFinish = async (values) => {
    const dbName = localStorage.getItem("dbName");
    try {
      let type;

      if ("reimbustmentType" in values) {
        type = "claims";
      } else if ("leaveType" in values) {
        type = "leave";
      } else if ("announcementType" in values) {
        type = "announcement";
      } else if ("separationType" in values) {
        type = "separation";
      } else if ("title" in values) {
        type = "ofcholiday";
      } else if ("image" in values) {
        type = "images";
        values.image = Images;
      } else if ("policy" in values) {
        type = "policy";
        values.policy = file;
      } else {
        type = "others";
      }
      if (type === "ofcholiday") {
        console.log(type, "type");
        const title = values.title;
        const date = selectedDate?.toLocaleDateString("en-GB");
        values.date = date;
        console.log(selectedDate, "ofcholidayss");
        // Use selectedDate for the date property
        setSelectedDates([...selectedDates, { title, date: date }]);
      }

      const response = await axios.post(
        `${url}/admin/combined/${type}/${dbName}`,
        values
      );
      if ("casualHolidays" in values && values.casualHolidays !== undefined) {
        const response = await axios.put(`${url}/admin/casual/${dbName}`, {
          casual: values.casualHolidays,
        });
      }

      if ("annualHolidays" in values) {
        const response = await axios.put(`${url}/admin/annual/${dbName}`, {
          annual: values.annualHolidays,
        });
      }
      console.log("Data posted:", response.data);
      setCount(count + 1);
      form.resetFields();
      setSelectedDate(null);
      setvisible(false);
      setvisible2(false);
      setvisible3(false);
      setvisible4(false);
      setvisible5(false);
      setvisible6(false);
      setvisible7(false);
      setvisible8(false);
      setvisible9(false);
      setvisible10(false);
      setvisible11(false);

      notification.success({
        message: `Posted Successfully!`,
        duration: 3,
      });
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };

  function renderCustomDayContents(day, date) {
    // Check if the date exists in MongoDB data and render calendar icon if true
    const isHoliday = data[0]?.officeHolidaysData?.find(
      (ofc) => ofc.date === date.toLocaleDateString("en-GB")
    );
    console.log(date.toLocaleDateString("en-GB"), "hol");
    return (
      <div>
        {day}
        {isHoliday && (
          <Tooltip title={isHoliday.title}>
            <CalendarFilled />
          </Tooltip>
        )}
      </div>
    );
  }

  const handleDeleteHoliday = async (date, title) => {
    const dbName = localStorage.getItem("dbName");
    try {
      // Make a DELETE request to the backend API
      const response = await axios.delete(
        `${url}/remove/combined/${dbName}/${encodeURIComponent(
          date
        )}/${encodeURIComponent(title)}`
      );
      // Handle success response
      console.log(response.data);

      setCount(count + 1);
      // Refresh the data after deletion if needed
      // Call a function to fetch and update the holidays data
    } catch (error) {
      // Handle error response
      console.error(error.response.data.message);
    }
  };

  const handleDeleteClaims = async (type) => {
    const dbName = localStorage.getItem("dbName");
    try {
      // Make a DELETE request to the backend API
      const response = await axios.delete(
        `${url}/adminclaims/${dbName}/${type}`
      );
      // Handle success response
      console.log(response.data);

      setCount(count + 1);
    } catch (error) {
      // Handle error response
      console.error(error.response.data.message);
    }
  };
  const handleDeleteSeparation = async (type) => {
    const dbName = localStorage.getItem("dbName");
    try {
      // Make a DELETE request to the backend API
      const response = await axios.delete(
        `${url}/adminseparation/${dbName}/${type}`
      );
      // Handle success response
      console.log(response.data);

      setCount(count + 1);
      // Refresh the data after deletion if needed
      // Call a function to fetch and update the holidays data
    } catch (error) {
      // Handle error response
      console.error(error.response.data.message);
    }
  };

  const handleDeleteReason = async (type) => {
    const dbName = localStorage.getItem("dbName");
    try {
      // Make a DELETE request to the backend API
      const response = await axios.delete(
        `${url}/adminReason/${dbName}/${type}`
      );
      // Handle success response
      console.log(response.data);

      setCount(count + 1);
      // Refresh the data after deletion if needed
      // Call a function to fetch and update the holidays data
    } catch (error) {
      // Handle error response
      console.error(error.response.data.message);
    }
  };
  const handleDeletePolicy = async (policy) => {
    const dbName = localStorage.getItem("dbName");
    try {
      // Make a DELETE request to the backend API
      const response = await axios.delete(
        `${url}/remove/policies/${dbName}/${policy}`
      );
      // Handle success response
      console.log(response.data);

      setCount(count + 1);
      // Refresh the data after deletion if needed
      // Call a function to fetch and update the holidays data
    } catch (error) {
      // Handle error response
      console.error(error.response.data.message);
    }
  };
  const handleDeleteAnnouncement = async (type) => {
    const dbName = localStorage.getItem("dbName");
    try {
      // Make a DELETE request to the backend API
      const response = await axios.delete(
        `${url}/remove/adminannounce/${dbName}/${type}`
      );
      // Handle success response
      console.log(response.data);
      message.success("Announcement Deleted Successfully");
      setCount(count + 1);
      // Refresh the data after deletion if needed
      // Call a function to fetch and update the holidays data
    } catch (error) {
      // Handle error response
      console.error(error.response.data.message);
    }
  };

  return (
    <div style={{ zoom: "80%" }}>
      <Row gutter={[16, 16]} style={{ marginTop: "5vh" }}>
        <Col span={6}>
          <Card
            style={{
              borderRadius: "0%",
              borderTop: "5px solid #d6d6c2",
              height: "35vh",
            }}
          >
            <div style={{ alignItems: "center" }}>
              <Row justify="center">
                <Col span={10}></Col>
                <Col span={11}>
                  <img
                    src={require("../../images/schedule.png")}
                    style={{ width: "10vh", height: "10vh" }}
                  />
                </Col>
                <Col span={3}>
                  <Button
                    icon={
                      <img
                        src={require("../../images/edit.png")}
                        onClick={handleOk}
                        style={{ width: "3vh", height: "3vh" }}
                      />
                    }
                  />
                </Col>
                <Modal
                  visible={calendarvisible}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  footer={null}
                  title={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <span>Annual Holidays</span>
                    </div>
                  }
                >
                  <Divider />
                  <Form.Item className="flex--center">
                    <DatePicker
                      selected={selectedDate}
                      onChange={handleDateInput}
                      dateFormat="DD/MM/YYYY"
                      isClearable
                      placeholderText="Select dates"
                      renderDayContents={(day, date) =>
                        renderCustomDayContents(day, date)
                      }
                      inline
                      multiple
                    />
                    {selectedDate && (
                      <Modal
                        visible={modalOpen}
                        footer={null}
                        onCancel={() => {
                          setModalOpen(false);
                          setSelectedDate(null);
                        }}
                      >
                        <Form onFinish={onFinish} form={form}>
                          <Form.Item label="Title" name="title">
                            <Input />
                          </Form.Item>
                          <Form.Item label="Date">
                            <Input
                              value={selectedDate?.toLocaleDateString("en-GB")}
                              readOnly
                            />
                          </Form.Item>
                          <Form.Item
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              type="primary"
                              htmlType="submit"
                              className="button--color"
                            >
                              Submit
                            </Button>
                          </Form.Item>
                        </Form>
                      </Modal>
                    )}
                  </Form.Item>
                </Modal>
                {visible && (
                  <Modal
                    open={visible}
                    onCancel={() => setvisible(false)}
                    footer={null}
                  >
                    <div>
                      Holidays
                      {data[0].officeHolidaysData.map((ofc, index) => (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "5px",
                            margin: "8px",
                            borderRadius: "6px",
                            boxShadow:
                              "rgba(100, 100, 111, 0.1) 0px 7px 29px 0px",
                            border: "8px",
                            borderColor: "black",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "row",
                            }}
                          >
                            <div key={index}>
                              <p>
                                {ofc.date} - {ofc.title}
                              </p>
                            </div>
                          </div>

                          <div>
                            <Button
                              type="primary"
                              icon={<DeleteFilled />}
                              danger
                              onClick={() =>
                                handleDeleteHoliday(ofc.date, ofc.title)
                              }
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </Modal>
                )}
              </Row>
              <Row justify="center" style={{ marginTop: "3vh" }}>
                <h5 className="text-center">Holidays</h5>
              </Row>
              <Row justify="center">
                <Button
                  onClick={open}
                  type="text"
                  icon={
                    <img
                      src={require("../../images/view.png")}
                      style={{ width: "5vh", height: "5vh" }}
                    />
                  }
                />
              </Row>
            </div>
          </Card>
        </Col>
        <Col span={6}>
          <Card style={{ borderRadius: "0%", borderTop: "5px solid #d6d6c2" }}>
            <div>
              <Row justify="center">
                <Col span={10}></Col>
                <Col span={10}>
                  {data.length === 0 || !data[0].Logo ? (
                    <img
                      src={require("../../images/upload.png")}
                      style={{ width: "15vh", height: "15vh" }}
                    />
                  ) : (
                    data.map((item) => (
                      <img
                        key={item._id}
                        src={item.Logo}
                        style={{ width: "15vh", height: "15vh" }}
                      />
                    ))
                  )}
                </Col>
                <Col span={4}>
                  <Button
                    onClick={open1}
                    icon={
                      <img
                        src={require("../../images/edit.png")}
                        style={{ width: "3vh", height: "3vh" }}
                      />
                    }
                  />
                </Col>
              </Row>
              <Row justify="center" style={{ marginTop: "4vh" }}>
                <h5 className="text-center">Logo Upload</h5>
              </Row>

              <Modal
                visible={visible1}
                title="Logo Upload"
                footer={null}
                onCancel={() => setvisible1(false)}
              >
                <Divider />

                <Form form={form} onFinish={onFinish3}>
                  <Row>
                    <Col span={19}>
                      <Form.Item name="Logo">
                        <Input
                          size="large"
                          onChange={onImageSubmit1}
                          type="file"
                          accept="image/jpeg, image/png,image/jpg"
                          required
                        />
                      </Form.Item>
                    </Col>

                    <Col span={5}>
                      <Form.Item
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="button--color"
                        >
                          Submit
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Modal>
            </div>
          </Card>
        </Col>
        <Col span={6}>
          <Card style={{ borderRadius: "0%", borderTop: "5px solid #d6d6c2" }}>
            <div>
              <Row justify="center">
                <Col span={9}></Col>
                <Col span={11}>
                  <img
                    src={require("../../images/clock.png")}
                    style={{ width: "10vh", height: "10vh" }}
                  />
                </Col>
                <Col span={3}>
                  <Button
                    onClick={open2}
                    icon={
                      <img
                        src={require("../../images/edit.png")}
                        style={{ width: "3vh", height: "3vh" }}
                      />
                    }
                  />
                </Col>
              </Row>
              <Row justify="center" style={{ marginTop: "3vh" }}>
                <h5 className="text-center">Hours</h5>
              </Row>
              <Row justify="center">
                {data.map((item) => (
                  <h6 style={{ color: "#d19e5c" }} className="text-center">
                    {item.totalHours}
                  </h6>
                ))}
              </Row>
              <Modal
                visible={visible2}
                title="Total Hours"
                footer={null}
                onCancel={() => setvisible2(false)}
              >
                <Divider />

                <Form {...layout} form={form} onFinish={onFinish}>
                  <Form.Item name="totalHours">
                    <Input size="large" placeholder="Enter hours" required />
                  </Form.Item>
                  <Form.Item
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="button--color"
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </Modal>
            </div>
          </Card>
        </Col>
        <Col span={6}>
          <Card
            style={{
              borderRadius: "0%",
              borderTop: "5px solid #d6d6c2",
              height: "35vh",
            }}
          >
            <div>
              <Row justify="center">
                <Col span={10}></Col>
                <Col span={11}>
                  <img
                    src={require("../../images/announcement1.png")}
                    style={{ width: "10vh", height: "10vh" }}
                  />
                </Col>
                <Col span={3}>
                  <Button
                    icon={
                      <img
                        src={require("../../images/edit.png")}
                        onClick={open3}
                        style={{ width: "3vh", height: "3vh" }}
                      />
                    }
                  />
                </Col>
              </Row>
              <Row justify="center" style={{ marginTop: "3vh" }}>
                <h5>Announcement</h5>
              </Row>
              <Row justify="center">
                <Button
                  icon={
                    <img
                      src={require("../../images/view.png")}
                      style={{ width: "5vh", height: "5vh" }}
                    />
                  }
                  type="text"
                  onClick={handleOk1}
                />
              </Row>
              <Modal
                visible={visible3}
                title="Announcement"
                footer={null}
                onCancel={() => setvisible3(false)}
              >
                <Divider />

                <Form {...layout} form={form} onFinish={onFinish}>
                  <Form.Item name="announcementType">
                    <Input
                      size="large"
                      placeholder="Enter Announcement"
                      required
                    />
                  </Form.Item>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="button--color"
                    >
                      Submit
                    </Button>
                  </div>
                </Form>
              </Modal>
              <Modal
                visible={isVisible1}
                title="View Announcement"
                footer={null}
                onCancel={() => setIsVisible1(false)}
              >
                <div
                  style={{
                    overflow: "hidden",
                    maxHeight: "200px",
                    overflowY: "scroll",
                  }}
                >
                  {data.map((item) => (
                    <React.Fragment key={item._id}>
                      {Array.isArray(item.announcementType) ? (
                        item.announcementType.map((announcement, index) => (
                          <React.Fragment key={index}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <p>{announcement}</p>
                              <Button
                                type="primary"
                                danger
                                onClick={() =>
                                  handleDeleteAnnouncement(announcement)
                                }
                                icon={<DeleteOutlined />}
                              />
                            </div>
                            {index !== item.announcementType.length - 1 && (
                              <Divider />
                            )}
                          </React.Fragment>
                        ))
                      ) : (
                        <p>{item.announcementType}</p>
                      )}
                      <Divider />
                    </React.Fragment>
                  ))}
                </div>
              </Modal>
            </div>
          </Card>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginTop: "3vh" }}>
        <Col span={6}>
          <Card style={{ borderRadius: "0%", borderTop: "5px solid #d6d6c2" }}>
            <div style={{ alignItems: "center" }}>
              <Row justify="center">
                <Col span={10}></Col>
                <Col span={11}>
                  <img
                    src={require("../../images/weekend.png")}
                    style={{ width: "10vh", height: "10vh" }}
                  />
                </Col>
                <Col span={3}>
                  <Button
                    onClick={open4}
                    icon={
                      <img
                        src={require("../../images/edit.png")}
                        style={{ width: "3vh", height: "3vh" }}
                      />
                    }
                  />
                </Col>
              </Row>
              <Row justify="center" style={{ marginTop: "3vh" }}>
                <h5 className="text-center">Weekend</h5>
              </Row>
              <Row justify="center">
                {data.map((item) => (
                  <h5 style={{ color: "#d19e5c" }} className="text-center">
                    {item.holidayType}
                  </h5>
                ))}
              </Row>
              <Modal
                visible={visible4}
                title="Weekend"
                footer={null}
                onCancel={() => setvisible4(false)}
              >
                <Divider />

                <Form {...layout} form={form} onFinish={onFinish}>
                  <Form.Item name="holidayType">
                    <Select size="large" placeholder="Select weekend" required>
                      <Select.Option value="frioff">Friday Off</Select.Option>
                      <Select.Option value="frisatoff">
                        Friday Saturday Off
                      </Select.Option>
                      <Select.Option value="satoff">Saturday Off</Select.Option>
                      <Select.Option value="satsunoff">
                        Saturday Sunday Off
                      </Select.Option>
                      <Select.Option value="sunoff">Sunday Off</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="button--color"
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </Modal>
            </div>
          </Card>
        </Col>
        <Col span={6}>
          <Card
            style={{
              borderRadius: "0%",
              borderTop: "5px solid #d6d6c2",
              height: "35vh",
            }}
          >
            <div style={{ alignItems: "center" }}>
              <Row justify="center">
                <Col span={10}></Col>
                <Col span={11}>
                  <img
                    src={require("../../images/policy.png")}
                    style={{ width: "12vh", height: "10vh" }}
                  />
                </Col>
                <Col span={3}>
                  <Button
                    onClick={open5}
                    icon={
                      <img
                        src={require("../../images/edit.png")}
                        style={{ width: "3vh", height: "3vh" }}
                      />
                    }
                  />
                </Col>
              </Row>
              <Row justify="center" style={{ marginTop: "3vh" }}>
                <h5 className="text-center">Policies</h5>
              </Row>
              <Row justify="center">
                <Button
                  type="text"
                  icon={
                    <img
                      src={require("../../images/view.png")}
                      style={{ width: "5vh", height: "5vh" }}
                    />
                  }
                  onClick={handleOpenPdfModal}
                />
                <Modal
                  visible={pdfModalVisible}
                  onCancel={handleClosePdfModal}
                  footer={null}
                  title="View Policies"
                  width={250}
                >
                  {data[0]?.policy?.map((item, index) => (
                    <div style={{ marginTop: "2vh" }} key={index}>
                      <ul>
                        <Row>
                          <Col span={18}>
                            <li>
                              {" "}
                              <Link
                                onClick={() => handleOpenPdfModal1(item.policy)}
                              >
                                Policy {index + 1}
                              </Link>
                            </li>
                          </Col>

                          <Divider />
                        </Row>
                      </ul>
                    </div>
                  ))}
                </Modal>
                <Modal
                  visible={pdfModalVisible1}
                  onCancel={handleClosePdfModal1}
                  footer={null}
                  width={600} // Adjust width as needed
                >
                  {selectedPolicy && (
                    <div
                      className="text-center"
                      style={{ marginBottom: "10px" }}
                    >
                      <embed
                        src={selectedPolicy}
                        type="application/pdf"
                        width="500"
                        height="500"
                      />
                    </div>
                  )}
                </Modal>
              </Row>
              <Modal
                visible={visible5}
                title="Policy Upload"
                footer={null}
                onCancel={() => setvisible5(false)}
              >
                <Divider />

                <Form {...layout} form={form} onFinish={onFinish}>
                  <Form.Item label="Policy Name" name="name">
                    <Input
                      size="large"
                      placeholder="Enter policy name"
                      required
                    />
                  </Form.Item>
                  <Form.Item label="Policy valid from" name="date">
                    <Input type="date" size="large" required />
                  </Form.Item>
                  <Form.Item label="Policy" name="policy">
                    <Input
                      size="large"
                      onChange={handleFileChange}
                      type="file"
                      accept="application/pdf"
                      required
                    />
                  </Form.Item>
                  <Form.Item
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="button--color"
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </Modal>
            </div>
          </Card>
        </Col>
        <Col span={6}>
          <Card
            style={{
              borderRadius: "0%",
              borderTop: "5px solid #d6d6c2",
              height: "35vh",
            }}
          >
            <div style={{ alignItems: "center" }}>
              <Row justify="center">
                <Col span={10}></Col>
                <Col span={11}>
                  <img
                    src={require("../../images/claim.png")}
                    style={{ width: "10vh", height: "10vh" }}
                  />
                </Col>
                <Col span={3}>
                  <Button
                    onClick={open6}
                    icon={
                      <img
                        src={require("../../images/edit.png")}
                        style={{ width: "3vh", height: "3vh" }}
                      />
                    }
                  />
                </Col>
              </Row>
              <Row justify="center" style={{ marginTop: "3vh" }}>
                <h5 className="text-center">Claims</h5>
              </Row>
              <Row justify="center">
                <Button
                  type="text"
                  icon={
                    <img
                      src={require("../../images/view.png")}
                      style={{ width: "5vh", height: "5vh" }}
                    />
                  }
                  onClick={handleOk2}
                />
              </Row>
              <Modal
                visible={visible6}
                title="Claims Types"
                footer={null}
                onCancel={() => setvisible6(false)}
              >
                <Divider />

                <Form {...layout} form={form} onFinish={onFinish}>
                  <Form.Item name="reimbustmentType">
                    <Input
                      size="large"
                      placeholder="Enter Claims Types"
                      required
                    />
                  </Form.Item>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="button--color"
                    >
                      Submit
                    </Button>
                  </div>
                </Form>
              </Modal>
              <Modal
                visible={isVisible2}
                title="View Claims Type"
                footer={null}
                onCancel={() => setIsVisible2(false)}
              >
                <Divider />
                <div
                  style={{
                    overflow: "hidden",
                    maxHeight: "200px",
                    overflowY: "scroll",
                  }}
                >
                  {data.map((item) => (
                    <React.Fragment key={item._id}>
                      {Array.isArray(item.reimbustmentType) ? (
                        item.reimbustmentType.map((claim, index) => (
                          <div key={index}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <p>{claim}</p>
                              <Button
                                type="primary"
                                danger
                                onClick={() => handleDeleteClaims(claim)}
                                icon={<DeleteOutlined />}
                              />
                            </div>
                            {index !== item.reimbustmentType.length - 1 && (
                              <Divider />
                            )}
                          </div>
                        ))
                      ) : (
                        <div>
                          <p>{item.reimbustmentType}</p>
                          <Button
                            type="primary"
                            danger
                            onClick={() =>
                              handleDeleteClaims(
                                item._id,
                                item.reimbustmentType
                              )
                            }
                            icon={<DeleteOutlined />}
                          />
                        </div>
                      )}
                      <Divider />
                    </React.Fragment>
                  ))}
                </div>
              </Modal>
            </div>
          </Card>
        </Col>
        <Col span={6}>
          <Card
            style={{
              borderRadius: "0%",
              borderTop: "5px solid #d6d6c2",
              height: "35vh",
            }}
          >
            <div style={{ alignItems: "center" }}>
              <Row justify="center">
                <Col span={10}></Col>
                <Col span={11}>
                  <img
                    src={require("../../images/edit.png")}
                    style={{ width: "10vh", height: "10vh" }}
                  />
                </Col>
                <Col span={3}>
                  <Button
                    onClick={open12}
                    icon={
                      <img
                        src={require("../../images/edit.png")}
                        style={{ width: "3vh", height: "3vh" }}
                      />
                    }
                  />
                </Col>
              </Row>
              <Row justify="center" style={{ marginTop: "3vh" }}>
                <h5 className="text-center">Leave Type</h5>
              </Row>
              <Row justify="center">
                <Button
                  type="text"
                  icon={
                    <img
                      src={require("../../images/view.png")}
                      style={{ width: "5vh", height: "5vh" }}
                    />
                  }
                  onClick={handleOk4}
                />
              </Row>
              <Modal
                visible={leavetype}
                title="Leave Types"
                footer={null}
                onCancel={() => setLeaveType(false)}
              >
                <Divider />

                <Form {...layout} form={form} onFinish={onFinish}>
                  <Form.Item name="leaveType">
                    <Input
                      size="large"
                      placeholder="Enter Leave Types"
                      required
                    />
                  </Form.Item>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="button--color"
                    >
                      Submit
                    </Button>
                  </div>
                </Form>
              </Modal>
              <Modal
                visible={isLeavetype}
                title="View Leave Type"
                footer={null}
                onCancel={() => setIsLeavetype(false)}
              >
                <Divider />
                <div
                  style={{
                    overflow: "hidden",
                    maxHeight: "200px",
                    overflowY: "scroll",
                  }}
                >
                  {data.map((item) => (
                    <React.Fragment key={item._id}>
                      {Array.isArray(item.leaveType) ? (
                        item.leaveType.map((leave, index) => (
                          <div key={index}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <p>{leave}</p>
                              <Button
                                type="primary"
                                danger
                                onClick={() => handleDeleteLeave(leave)}
                                icon={<DeleteOutlined />}
                              />
                            </div>
                            {index !== item.leaveType.length - 1 && <Divider />}
                          </div>
                        ))
                      ) : (
                        <div>
                          <p>{item.leaveType}</p>
                          <Button
                            type="primary"
                            danger
                            onClick={() =>
                              handleDeleteLeave(item._id, item.leaveType)
                            }
                            icon={<DeleteOutlined />}
                          />
                        </div>
                      )}
                      <Divider />
                    </React.Fragment>
                  ))}
                </div>
              </Modal>
            </div>
          </Card>
        </Col>
        <Col span={6}>
          <Card style={{ borderRadius: "0%", borderTop: "5px solid #d6d6c2" }}>
            <div style={{ alignItems: "center" }}>
              <Row justify="center">
                <img
                  src={require("../../images/3d-map.png")}
                  style={{ width: "10vh", height: "10vh" }}
                />
              </Row>
              <Row justify="center" style={{ marginTop: "3vh" }}>
                <h5 className="text-center">Location</h5>
              </Row>
              <Row justify="center" style={{ marginTop: "2vh" }}>
                <Switch
                  checkedChildren="ON"
                  unCheckedChildren="OFF"
                  checked={checked}
                  onChange={handleChange}
                />
              </Row>
            </div>
          </Card>
        </Col>
        <Col span={6}>
          <Card style={{ borderRadius: "0%", borderTop: "5px solid #d6d6c2" }}>
            <div style={{ alignItems: "center" }}>
              <Row justify="center">
                <Col span={10}></Col>
                <Col span={11}>
                  <img
                    src={require("../../images/logout1.png")}
                    style={{ width: "10vh", height: "10vh" }}
                  />
                </Col>
                <Col span={3}>
                  <Button
                    onClick={open7}
                    icon={
                      <img
                        src={require("../../images/edit.png")}
                        style={{ width: "3vh", height: "3vh" }}
                      />
                    }
                  />
                </Col>
              </Row>
              <Row justify="center" style={{ marginTop: "3vh" }}>
                <h5 className="text-center">Casual</h5>
              </Row>
              <Row justify="center">
                {data.map((item) => (
                  <h5 style={{ color: "#d19e5c" }} className="text-center">
                    {item.casualHolidays}
                  </h5>
                ))}
              </Row>
              <Modal
                visible={visible7}
                title="Casual Leave"
                footer={null}
                onCancel={() => setvisible7(false)}
              >
                <Divider />

                <Form {...layout} form={form} onFinish={onFinish}>
                  <Form.Item name="casualHolidays">
                    <Input
                      size="large"
                      placeholder="Enter Casual Leave"
                      required
                    />
                  </Form.Item>
                  <Form.Item
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="button--color"
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </Modal>
            </div>
          </Card>
        </Col>
        <Col span={6}>
          <Card style={{ borderRadius: "0%", borderTop: "5px solid #d6d6c2" }}>
            <div style={{ alignItems: "center" }}>
              <Row justify="center">
                <Col span={10}></Col>
                <Col span={11}>
                  <img
                    src={require("../../images/calendar1.png")}
                    style={{ width: "10vh", height: "10vh" }}
                  />
                </Col>
                <Col span={3}>
                  <Button
                    icon={
                      <img
                        src={require("../../images/edit.png")}
                        onClick={open8}
                        style={{ width: "3vh", height: "3vh" }}
                      />
                    }
                  />
                </Col>
              </Row>
              <Row justify="center" style={{ marginTop: "3vh" }}>
                <h5 className="text-center">Vacation</h5>
              </Row>
              <Row justify="center">
                {data.map((item) => (
                  <h5 style={{ color: "#d19e5c" }} className="text-center">
                    {item.annualHolidays}
                  </h5>
                ))}
              </Row>
              <Modal
                visible={visible8}
                title="Vacation Leave"
                footer={null}
                onCancel={() => setvisible8(false)}
              >
                <Divider />

                <Form {...layout} form={form} onFinish={onFinish}>
                  <Form.Item name="annualHolidays">
                    <Input
                      size="large"
                      placeholder="Enter Vacation Leave"
                      required
                    />
                  </Form.Item>
                  <Form.Item
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="button--color"
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </Modal>
            </div>
          </Card>
        </Col>
        <Col span={6}>
          <Card style={{ borderRadius: "0%", borderTop: "5px solid #d6d6c2" }}>
            <div style={{ alignItems: "center" }}>
              <Row justify="center">
                <Col span={10}></Col>
                <Col span={11}>
                  <img
                    src={require("../../images/color-picker.png")}
                    style={{ width: "10vh", height: "10vh" }}
                  />
                </Col>
                <Col span={3}>
                  <Button
                    onClick={open9}
                    icon={
                      <img
                        src={require("../../images/edit.png")}
                        style={{ width: "3vh", height: "3vh" }}
                      />
                    }
                  />
                </Col>
              </Row>
              <Row justify="center" style={{ marginTop: "3vh" }}>
                <h5 className="text-center">Color Picker</h5>
              </Row>
              <Row justify="center">
                {data.map((item) => (
                  <div
                    style={{
                      backgroundColor: item.backgroundColor,
                      padding: "5px",
                      width: "5vh",
                      height: "5vh",
                      marginTop: "1vh",
                    }}
                    className="text-center"
                  />
                ))}
              </Row>
              <Modal
                visible={visible9}
                title="Color Picker"
                footer={null}
                onCancel={() => setvisible9(false)}
              >
                <Divider />
                <ColorPicker />
              </Modal>
            </div>
          </Card>
        </Col>
        <Col span={6}>
          <Card style={{ borderRadius: "0%", borderTop: "5px solid #d6d6c2" }}>
            <div style={{ alignItems: "center" }}>
              <Row justify="center">
                <img
                  src={require("../../images/images.jpg")}
                  style={{ width: "10vh", height: "10vh" }}
                />
              </Row>
              <Row justify="center" style={{ marginTop: "3vh" }}>
                <h5 className="text-center">Thirukural</h5>
              </Row>
              <Row justify="center" style={{ marginTop: "2vh" }}>
                <Switch checked={isEnabled} onChange={handleChange1} />
              </Row>
            </div>
          </Card>
        </Col>
        <Col span={6}>
          <Card style={{ borderRadius: "0%", borderTop: "5px solid #d6d6c2" }}>
            <div>
              <Row justify="center">
                <Col span={10}></Col>
                <Col span={11}>
                  {data.length === 0 || !data[0].Logo ? (
                    <img
                      src={require("../../images/upload.png")}
                      style={{ width: "12vh", height: "12vh" }}
                    />
                  ) : (
                    data.map((item) => (
                      <img
                        key={item._id}
                        src={item.letterheader}
                        style={{ width: "12vh", height: "12vh" }}
                      />
                    ))
                  )}
                </Col>
                <Col span={3}>
                  <Button
                    onClick={openheader}
                    icon={
                      <img
                        src={require("../../images/edit.png")}
                        style={{ width: "3vh", height: "3vh" }}
                      />
                    }
                  />
                </Col>
              </Row>
              <Row justify="center" style={{ marginTop: "3vh" }}>
                <h5 className="text-center">Letterpad Header</h5>
              </Row>

              <Modal
                visible={header}
                title="Letterpad Header"
                footer={null}
                onCancel={() => setHeader(false)}
              >
                <Divider />

                <Form form={form} onFinish={onFinish4}>
                  <Row>
                    <Col span={19}>
                      <Form.Item name="letterheader">
                        <Input
                          size="large"
                          onChange={onHeaderSubmit}
                          type="file"
                          accept="image/jpeg, image/png,image/jpg"
                          required
                        />
                      </Form.Item>
                    </Col>

                    <Col span={5}>
                      <Form.Item
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Button
                          style={{ bottom: "3vh" }}
                          type="primary"
                          htmlType="submit"
                          className="button--color"
                        >
                          Submit
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Modal>
            </div>
          </Card>
        </Col>
        <Col span={6}>
          <Card style={{ borderRadius: "0%", borderTop: "5px solid #d6d6c2" }}>
            <div>
              <Row justify="center">
                <Col span={10}></Col>
                <Col span={11}>
                  {data.length === 0 || !data[0].Logo ? (
                    <img
                      src={require("../../images/upload.png")}
                      style={{ width: "12vh", height: "12vh" }}
                    />
                  ) : (
                    data.map((item) => (
                      <img
                        key={item._id}
                        src={item.letterfooter}
                        style={{ width: "12vh", height: "12vh" }}
                      />
                    ))
                  )}
                </Col>
                <Col span={3}>
                  <Button
                    onClick={openfooter}
                    icon={
                      <img
                        src={require("../../images/edit.png")}
                        style={{ width: "3vh", height: "3vh" }}
                      />
                    }
                  />
                </Col>
              </Row>
              <Row justify="center" style={{ marginTop: "3vh" }}>
                <h5 className="text-center">Letterpad Footer</h5>
              </Row>

              <Modal
                visible={footer}
                title="Letterpad Footer"
                footer={null}
                onCancel={() => setFooter(false)}
              >
                <Divider />

                <Form form={form} onFinish={onFinish5}>
                  <Row>
                    <Col span={19}>
                      <Form.Item name="letterfooter">
                        <Input
                          size="large"
                          onChange={onFooterSubmit}
                          type="file"
                          accept="image/jpeg, image/png,image/jpg"
                          required
                        />
                      </Form.Item>
                    </Col>

                    <Col span={5}>
                      <Form.Item
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Button
                          style={{ bottom: "3vh" }}
                          type="primary"
                          htmlType="submit"
                          className="button--color"
                        >
                          Submit
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Modal>
            </div>
          </Card>
        </Col>
        <Col span={6}>
          <Card style={{ borderRadius: "0%", borderTop: "5px solid #d6d6c2" }}>
            <div>
              <Row justify="center">
                <Col span={10}></Col>
                <Col span={11}>
                  {data.length === 0 || !data[0].Logo ? (
                    <img
                      src={require("../../images/upload.png")}
                      style={{ width: "12vh", height: "12vh" }}
                    />
                  ) : (
                    data.map((item) => (
                      <img
                        key={item._id}
                        src={item.birthday}
                        style={{ width: "12vh", height: "12vh" }}
                      />
                    ))
                  )}
                </Col>
                <Col span={3}>
                  <Button
                    onClick={openBirthday}
                    icon={
                      <img
                        src={require("../../images/edit.png")}
                        style={{ width: "3vh", height: "3vh" }}
                      />
                    }
                  />
                </Col>
              </Row>
              <Row justify="center" style={{ marginTop: "3vh" }}>
                <h5 className="text-center">Birthday Card</h5>
              </Row>

              <Modal
                visible={birthday}
                title="Birthday Card"
                footer={null}
                onCancel={() => setBirthday(false)}
              >
                <Divider />

                <Form form={form} onFinish={onBirthdayFinish}>
                  <Form.Item name="birthday">
                    <Input
                      size="large"
                      onChange={onBirthdayChange}
                      type="file"
                      accept="image/jpeg, image/png,image/jpg"
                    />
                    {data[0]?.birthday && (
                      <img
                        src={data[0]?.birthday}
                        style={{
                          width: "30vh",
                          height: "30vh",
                          marginLeft: "25vh",
                          marginTop: "1vh",
                        }}
                      />
                    )}
                  </Form.Item>

                  <Form.Item>
                    <Input.TextArea
                      value={birthdayMessage}
                      onChange={(e) => setBirthdayMessage(e.target.value)}
                      rows={4}
                      type="text"
                      placeholder="Type message content for birthday wishes"
                      disabled={!isEditable} // Disable the input if not editable
                    />
                    <Button
                      onClick={toggleEditMode}
                      icon={isEditable ? <SaveOutlined /> : <EditOutlined />}
                      style={{ marginTop: 8 }}
                    >
                      {isEditable ? "Save" : "Edit"}
                    </Button>
                  </Form.Item>
                  <Form.Item
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Button
                      style={{ bottom: "3vh" }}
                      type="primary"
                      htmlType="submit"
                      className="button--color"
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </Modal>
            </div>
          </Card>
        </Col>
        <Col span={6}>
          <Card style={{ borderRadius: "0%", borderTop: "5px solid #d6d6c2" }}>
            <div>
              <Row justify="center">
                <Col span={10}></Col>
                <Col span={11}>
                  {data?.length === 0 || !data[0].Logo ? (
                    <img
                      src={require("../../images/upload.png")}
                      style={{ width: "12vh", height: "12vh" }}
                    />
                  ) : (
                    data?.map((item) => (
                      <img
                        key={item._id}
                        src={item.anniversary}
                        style={{ width: "12vh", height: "12vh" }}
                      />
                    ))
                  )}
                </Col>
                <Col span={3}>
                  <Button
                    onClick={openAnniversary}
                    icon={
                      <img
                        src={require("../../images/edit.png")}
                        style={{ width: "3vh", height: "3vh" }}
                      />
                    }
                  />
                </Col>
              </Row>
              <Row justify="center" style={{ marginTop: "3vh" }}>
                <h5 className="text-center">Anniversary Card</h5>
              </Row>

              <Modal
                visible={anniversary}
                title="Anniversary Card"
                footer={null}
                onCancel={() => setAnniversary(false)}
              >
                <Divider />

                <Form form={form} onFinish={onAnniversaryFinish}>
                  <Form.Item name="anniversary">
                    <Input
                      size="large"
                      onChange={onAnniversaryChange}
                      type="file"
                      accept="image/jpeg, image/png,image/jpg"
                    />
                    {data[0]?.anniversary && (
                      <img
                        src={data[0]?.anniversary}
                        style={{
                          width: "30vh",
                          height: "30vh",
                          marginLeft: "25vh",
                          marginTop: "1vh",
                        }}
                      />
                    )}
                  </Form.Item>
                  <Form.Item>
                    <Input.TextArea
                      value={anniversaryMessage}
                      onChange={(e) => setAnniversaryMessage(e.target.value)}
                      rows={4}
                      type="text"
                      placeholder="Type message content for anniversary wishes"
                      disabled={!isEditable1} // Disable the input if not editable
                    />
                    <Button
                      onClick={toggleEditMode1}
                      icon={isEditable1 ? <SaveOutlined /> : <EditOutlined />}
                      style={{ marginTop: 8 }}
                    >
                      {isEditable1 ? "Save" : "Edit"}
                    </Button>
                  </Form.Item>
                  <Form.Item
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Button
                      style={{ bottom: "3vh" }}
                      type="primary"
                      htmlType="submit"
                      className="button--color"
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </Modal>
            </div>
          </Card>
        </Col>
        <Col span={6}>
          <Card style={{ borderRadius: "0%", borderTop: "5px solid #d6d6c2" }}>
            <div style={{ alignItems: "center" }}>
              <Row justify="center">
                <img
                  src={require("../../images/birthday.png")}
                  style={{ width: "10vh", height: "10vh" }}
                />
              </Row>
              <Row justify="center" style={{ marginTop: "3vh" }}>
                <h5 className="text-center">BirthdayReminder</h5>
              </Row>
              <Row justify="center" style={{ marginTop: "2vh" }}>
                <Switch
                  checkedChildren="ON"
                  unCheckedChildren="OFF"
                  checked={checkedBirthday}
                  onChange={handleChangeBirthday}
                />
              </Row>
            </div>
          </Card>
        </Col>
        <Col span={6}>
          <Card style={{ borderRadius: "0%", borderTop: "5px solid #d6d6c2" }}>
            <div style={{ alignItems: "center" }}>
              <Row justify="center">
                <img
                  src={require("../../images/work-schedule.png")}
                  style={{ width: "10vh", height: "10vh" }}
                />
              </Row>
              <Row justify="center" style={{ marginTop: "3vh" }}>
                <h5 className="text-center">AnniversaryReminder</h5>
              </Row>
              <Row justify="center" style={{ marginTop: "2vh" }}>
                <Switch
                  checkedChildren="ON"
                  unCheckedChildren="OFF"
                  checked={checkedAnniversary}
                  onChange={handleChangeAnniversary}
                />
              </Row>
            </div>
          </Card>
        </Col>
        <Col span={6}>
          {/* Separation Card */}
          <Card
            style={{
              borderRadius: "0%",
              borderTop: "5px solid #d6d6c2",
              height: "35vh",
            }}
          >
            <div style={{ alignItems: "center" }}>
              <Row justify="center">
                <Col span={10}></Col>
                <Col span={11}>
                  <img
                    src={require("../../images/separation.png")}
                    style={{ width: "10vh", height: "10vh" }}
                  />
                </Col>
                <Col span={3}>
                  <Button
                    onClick={open10}
                    icon={
                      <img
                        src={require("../../images/edit.png")}
                        style={{ width: "3vh", height: "3vh" }}
                      />
                    }
                  />
                </Col>
              </Row>
              <Row justify="center" style={{ marginTop: "3vh" }}>
                <h5 className="text-center">Separation</h5>
              </Row>
              <Row justify="center">
                <Button
                  type="text"
                  icon={
                    <img
                      src={require("../../images/view.png")}
                      style={{ width: "5vh", height: "5vh" }}
                    />
                  }
                  onClick={handleOk3}
                />
              </Row>

              {/* Separation Modal */}
              <Modal
                visible={visible10}
                title="Exit Configuration"
                footer={null}
                onCancel={() => setvisible10(false)}
              >
                <Divider />
                <Form {...layout} form={form} onFinish={onFinish}>
                  {/* Separation Type Field */}
                  <Form.Item name="separationType">
                    <Input
                      size="large"
                      placeholder="Enter Separation Type"
                      required
                    />
                  </Form.Item>

                  {/* Resignation Reason Field */}
                  <Form.Item name="resignationReason">
                    <Input
                      size="large"
                      placeholder="Enter Resignation Reason"
                      required
                    />
                  </Form.Item>

                  {/* Submit Button */}
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="button--color"
                    >
                      Submit
                    </Button>
                  </div>
                </Form>
              </Modal>

              {/* View Separation Details Modal */}
              <Modal
                visible={isVisible3}
                title="View Separation Details"
                footer={null}
                onCancel={() => setIsVisible3(false)}
                style={{ top: 20 }} // Adjust modal position if needed
              >
                <Divider />

                <div
                  style={{
                    overflow: "hidden",
                    maxHeight: "300px", // Increased height for better visibility
                    overflowY: "auto",
                    padding: "0 16px", // Match padding from claim view modal
                  }}
                >
                  {data.map((item) => (
                    <React.Fragment key={item._id}>
                      {/* Separation Details */}
                      <div style={{ marginBottom: "16px" }}>
                        {Array.isArray(item.separationDetails) ? (
                          item.separationDetails.map((detail, index) => (
                            <div key={index} style={{ marginBottom: "12px" }}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center", // Align items vertically
                                  padding: "8px 0", // Padding for spacing
                                  borderBottom: "1px solid #d9d9d9", // Border for separation
                                }}
                              >
                                <p style={{ margin: 0 }}>
                                  Type: {detail.separationType}
                                </p>
                                <p style={{ margin: 0 }}>
                                  Reason: {detail.resignationReason}
                                </p>
                                <Button
                                  type="primary"
                                  danger
                                  onClick={() =>
                                    handleDeleteSeparation(
                                      detail.separationType
                                    )
                                  }
                                  icon={<DeleteOutlined />}
                                />
                              </div>
                              {index !== item.separationDetails.length - 1 && (
                                <Divider />
                              )}
                            </div>
                          ))
                        ) : (
                          <div>
                            <p>
                              Type: {item.separationDetails?.separationType}
                            </p>
                            <Button
                              type="primary"
                              danger
                              onClick={() =>
                                handleDeleteSeparation(
                                  item._id,
                                  item.separationDetails.separationType
                                )
                              }
                              icon={<DeleteOutlined />}
                            />
                          </div>
                        )}
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              </Modal>
            </div>
          </Card>
        </Col>

        {/* Notice Period Card */}
        <Col span={6}>
          <Card style={{ borderRadius: "0%", borderTop: "5px solid #d6d6c2" }}>
            <div>
              <Row justify="center">
                <Col span={9}></Col>
                <Col span={11}>
                  <img
                    src={require("../../images/clock.png")}
                    style={{ width: "10vh", height: "10vh" }}
                  />
                </Col>
                <Col span={3}>
                  <Button
                    onClick={open11}
                    icon={
                      <img
                        src={require("../../images/edit.png")}
                        style={{ width: "3vh", height: "3vh" }}
                      />
                    }
                  />
                </Col>
              </Row>
              <Row justify="center" style={{ marginTop: "3vh" }}>
                <h5 className="text-center">Days</h5>
              </Row>
              <Row justify="center">
                {data.map((item) => (
                  <h6 style={{ color: "#d19e5c" }} className="text-center">
                    {item.noticePeriodDays}
                  </h6>
                ))}
              </Row>
              <Modal
                visible={visible11}
                title="Notice Period Days"
                footer={null}
                onCancel={() => setvisible11(false)}
              >
                <Divider />

                <Form {...layout} form={form} onFinish={onFinish}>
                  <Form.Item name="noticePeriodDays">
                    <Input size="large" placeholder="Enter Days" required />
                  </Form.Item>
                  <Form.Item
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="button--color"
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </Modal>
            </div>
          </Card>
        </Col>
      </Row>

      <FormItem />
    </div>
  );
};

export default AdminDashboard;
