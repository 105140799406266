import React from "react";
import { Modal, Form, Input, Select, Button, Row, Col } from "antd";

const AddActivity = ({
  featureactivity,
  editingfeature,
  selectedProject,
  handleSelect,
  modalVisible2,
  setModalVisible2,
  handleFormSubmit,
}) => {
  const [form] = Form.useForm();
  console.log(featureactivity, "  featureactivit");
  return (
    <Modal
      visible={modalVisible2}
      onCancel={() => setModalVisible2(false)}
      width={650}
      footer={null}
    >
      <Form form={form} layout="vertical" onFinish={handleFormSubmit}>
        <Form.Item>
          <img
            src={require("../../images/tasks.png")}
            alt=""
            style={{ width: "4vh" }}
          />
          <span style={{ marginBottom: "4vh", marginLeft: "1vh" }}>
            {"NEW ACTIVITY"}
          </span>
        </Form.Item>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Form.Item
              labelCol={{ span: 9 }}
              wrapperCol={{ span: 24 }}
              label="Activity Name"
              name="activityName"
              rules={[{ required: true, message: "Please enter Activity Name" }]}
            >
              <Input placeholder="Enter Activity Name..." style={{ fontSize: "14px", height: "6vh" }} />
            </Form.Item>
          </Col>

        </Row>
        <Row gutter={[16, 16]}>
          {featureactivity !== "Backlog" && (
            <Col span={12}>
              <Form.Item labelCol={{ span: 9 }}
                wrapperCol={{ span: 20 }} label="Assigned To" name="assignee">
                <Select
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  allowClear
                  style={{ height: "6vh" }}
                  name="assignee"
                  placeholder="Select Assignee"
                  onChange={handleSelect}
                >
                  {featureactivity === "Backlog"
                    ? selectedProject &&
                    selectedProject.TotalEmp.map((employee, index) => (
                      <Select.Option key={index} value={employee}>
                        {employee}
                      </Select.Option>
                    ))
                    : featureactivity.assignee.map((assignee, index) => (
                      <Select.Option key={index} value={assignee}>
                        {assignee}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          {featureactivity !== "Backlog" && (
            <Col span={12}>
              <Form.Item labelCol={{ span: 9 }}
                wrapperCol={{ span: 20 }} label="Priority" name="priority">
                <Select
                  style={{ height: "6vh" }}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  placeholder="Select Priority"
                >
                  <Select.Option value="High">High</Select.Option>
                  <Select.Option value="Medium">Medium</Select.Option>
                  <Select.Option value="Low">Low</Select.Option>
                </Select>
              </Form.Item>

            </Col>
          )}
        </Row>
        <Row gutter={[16, 16]}>

          <Col span={12}>
            <Form.Item labelCol={{ span: 9 }}
              wrapperCol={{ span: 20 }} label="Type" name="type">
              <Select placeholder="Select Type" style={{ height: "6vh" }}>
                <Select.Option value="Bug">Bug</Select.Option>
                <Select.Option value="Enhancement">Enhancement</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item labelCol={{ span: 9 }}
              wrapperCol={{ span: 20 }} label="Estimated Hours" name="estimatedHours">
              <Input placeholder="Enter hours" style={{ fontSize: "14px", height: "6vh" }} />
            </Form.Item>
          </Col>


        </Row>
        <Col span={24}>
          <Form.Item labelCol={{ span: 9 }}
            wrapperCol={{ span: 24 }} label="Description" name="description">
            <Input.TextArea rows={2} placeholder="Click to add Description" />
          </Form.Item>
        </Col>
        <Form.Item >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddActivity;
