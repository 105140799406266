import { DownOutlined, FolderOpenOutlined, PaperClipOutlined, PlusOutlined, UpOutlined, UserOutlined } from '@ant-design/icons'
import { Avatar, Button, Checkbox, Col, Divider, Dropdown, Form, Input, Menu, Modal, Row, Select } from 'antd'
import React, { useState } from 'react'

const Workitems = () => {

    const [modalVisible, setModalVisible] = useState(false);
    const [modalVisible1, setModalVisible1] = useState(false);
    const [open, setOpen] = useState(false);
    const [modalVisible2, setModalVisible2] = useState(false);
    const [open1, setOpen1] = useState(false);

    const Addlink = () => {
        setOpen(true);
    };

    const handleEpicClick = () => {
        setModalVisible(true);
    };


    const Addlink1 = () => {
        setOpen1(true);
    };

    const handleTaskClick = () => {
        setModalVisible2(true);
    };
    const handleIssueClick = () => {
        // Logic to open the modal when "Epic" menu item is clicked
        setModalVisible1(true);
    };
    const menu = (
        <Menu style={{ width: "170px", height: "180px" }}>
            {/* Add menu items for the dropdown */}
            <img src={require("../../images/OIP.jpg")} alt="" style={{ width: "5vh" }} />
            <Menu.Item onClick={handleEpicClick} style={{ bottom: "5vh", left: "5vh", fontSize: "15px" }} key="1">Epic</Menu.Item>
            <img src={require("../../images/late.png")} alt="" style={{ width: "5vh" }} />
            <Menu.Item onClick={handleIssueClick} style={{ bottom: "5vh", left: "5vh", fontSize: "15px" }} key="2">Issue</Menu.Item>
            <img src={require("../../images/R.png")} alt="" style={{ width: "5vh" }} />
            <Menu.Item onClick={handleTaskClick} style={{ bottom: "5vh", left: "5vh", fontSize: "15px" }} key="3">Task</Menu.Item>
        </Menu>
    );

    const menu1 = (
        <Menu >
            <Menu.Item icon={<PaperClipOutlined />} key="1">Existing item</Menu.Item>
            <Menu.Item icon={<FolderOpenOutlined />} key="2">New item</Menu.Item>

        </Menu>
    )
    return (
        <div>
            <h3>Work Items</h3>
            <Dropdown overlay={menu} placement="bottomLeft">
                <Button type='link' icon={<PlusOutlined />}>New Work item<DownOutlined /></Button>
            </Dropdown>
            <Divider />
            <img src={require("../../images/wall-clock.png")} alt="" style={{ width: "20vh", height: "20vh", marginLeft: "80vh" }} />
            <br />
            <h4 style={{ marginTop: "5vh" }}>Find Recently Updated Items</h4>
            {/* Epic */}
            <Modal
                visible={modalVisible}
                onCancel={() => setModalVisible(false)}
                width={1200}
                footer={[

                    <Button key="submit" type="primary" onClick={() => setModalVisible(false)}>
                        Save
                    </Button>,
                ]}
            >
                <img src={require("../../images/king.png")} alt="" style={{ width: "4vh" }} />
                <span style={{ marginBottom: "4vh", marginLeft: "1vh" }}>NEW EPIC</span>
                <Input placeholder="Enter Epic Name..." size="large" style={{ marginRight: "8vw", marginTop: "2vh" }} />
                <Avatar size={40} icon={<UserOutlined />} /> Unassigned
                <Row style={{ border: '1px solid #ccc', padding: '4px', backgroundColor: '#f5f5f5', marginTop: "3vh" }}>
                    <Col span={9}>
                        <label style={{ fontSize: '17px', display: 'block' }}>State:</label>
                        <Checkbox style={{ marginLeft: '5px' }}>to do</Checkbox>
                    </Col>
                    <Col span={8}>
                        <label>Area:</label>
                        <span>Finaran</span>

                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={8}>
                        <h5>Description</h5>
                        <Divider style={{ width: "10vh" }} />
                        <Input.TextArea style={{ width: '70vh' }} rows={3} placeholder="Click to add Description" />
                        <label>Discussion</label>
                        <Divider style={{ width: "10vh" }} />
                        <Input.TextArea style={{ width: '70vh' }} rows={3} placeholder="Add a Comment" />
                    </Col>
                    <Col span={8}>
                        <h5 style={{ marginLeft: "10vh" }}>Planning</h5>
                        <Divider style={{ width: "10vh" }} />
                        <div style={{ marginLeft: "10vh" }}>
                            <label>Priority</label>
                            <Select style={{ width: "30vh" }}>
                                <Select.Option value="high">High</Select.Option>
                                <Select.Option value="medium">Medium</Select.Option>
                                <Select.Option value="low">Low</Select.Option>
                            </Select>
                            <label>Start Date</label>
                            <Input type='date' style={{ width: "30vh" }} />

                            <label>Target Date</label>
                            <Input type='date' style={{ width: "30vh" }} />
                        </div>
                    </Col>
                    <Col span={8}>
                        <h5>Related Works</h5>
                        <Divider style={{ width: "10vh" }} />
                        <Dropdown overlay={menu1} placement="bottomLeft">
                            <Button type='link' icon={<PlusOutlined style={{ color: "green" }} />}>Add links<DownOutlined /></Button>
                        </Dropdown>
                    </Col>
                </Row>

            </Modal>

            {/* Issue */}
            <Modal
                visible={modalVisible1}
                onCancel={() => setModalVisible1(false)}
                width={1200}
                footer={[

                    <Button key="submit" type="primary" onClick={() => setModalVisible1(false)}>
                        Save
                    </Button>,
                ]}
            >
                <img src={require("../../images/R.png")} alt="" style={{ width: "4vh" }} />
                <span style={{ marginBottom: "4vh", marginLeft: "1vh" }}>NEW ISSUE</span>
                <Input placeholder="Enter Issue Name..." size="large" style={{ marginRight: "8vw", marginTop: "2vh" }} />
                <Avatar size={40} icon={<UserOutlined />} /> Unassigned
                <Row style={{ border: '1px solid #ccc', padding: '4px', backgroundColor: '#f5f5f5', marginTop: "3vh" }}>
                    <Col span={9}>
                        <label style={{ fontSize: '17px', display: 'block' }}>State:</label>
                        <Checkbox style={{ marginLeft: '5px' }}>to do</Checkbox>
                    </Col>
                    <Col span={8}>
                        <label>Area:</label>
                        <span>Finaran</span>

                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={8}>
                        <h5>Description</h5>
                        <Divider style={{ width: "10vh" }} />
                        <Input.TextArea style={{ width: '70vh' }} rows={3} placeholder="Click to add Description" />
                        <label>Discussion</label>
                        <Divider style={{ width: "10vh" }} />
                        <Input.TextArea style={{ width: '70vh' }} rows={3} placeholder="Add a Comment" />
                    </Col>
                    <Col span={8}>
                        <h5 style={{ marginLeft: "10vh" }}>Planning</h5>
                        <Divider style={{ width: "10vh" }} />
                        <div style={{ marginLeft: "10vh" }}>
                            <label>Priority</label>
                            <Select style={{ width: "30vh" }}>
                                <Select.Option value="high">High</Select.Option>
                                <Select.Option value="medium">Medium</Select.Option>
                                <Select.Option value="low">Low</Select.Option>
                            </Select>
                            <label>Efforts</label>
                            <Input style={{ width: "30vh", height: "35px" }} />


                        </div>
                    </Col>
                    <Col span={8}>
                        <h5>Development</h5>
                        <Divider style={{ width: "10vh" }} />
                        <Button type='link' icon={<PlusOutlined style={{ color: "green" }} />} onClick={Addlink} >Add links</Button>
                        <h5 style={{ marginTop: "5vh" }}>Related Works</h5>
                        <Divider style={{ width: "10vh" }} />
                        <Dropdown overlay={menu1} placement="bottomLeft">
                            <Button type='link' icon={<PlusOutlined style={{ color: "green" }} />}>Add links<DownOutlined /></Button>
                        </Dropdown>
                    </Col>
                </Row>

            </Modal>
            {/* Add Links */}
            <Modal
                visible={open}
                onCancel={() => setOpen(false)}
                width={500}
                title='ADD LINKS'
            >
                <br />

                <h6>You are adding links from</h6>
                <img src={require("../../images/R.png")} alt="" style={{ width: '5vh', height: "5vh" }} />
                <label style={{ marginTop: "3vh" }}>Link Type</label>
                <Select style={{ width: "65vh" }}>

                    <Select.Option value="Build">Build</Select.Option>
                    <Select.Option value="Found in Build">Found in Build</Select.Option>
                    <Select.Option value="Integrated in Build">Integrated in Build</Select.Option>
                </Select>
                <label style={{ marginTop: "3vh" }}>Repository</label>
                <Select style={{ width: "65vh" }}>
                    <Select.Option value="high">Finaran</Select.Option>
                </Select>
                <label style={{ marginTop: "3vh" }}>Branch</label>
                <Select style={{ width: "65vh" }}>
                    <Select.Option value="Mine">Mine</Select.Option>
                    <Select.Option value="All Branches">All Branches</Select.Option>
                </Select>
                <label style={{ marginTop: "3vh" }}>Comments</label>
                <Input />
            </Modal>
            {/* Task */}
            <Modal
                visible={modalVisible2}
                onCancel={() => setModalVisible2(false)}
                width={1200}
                footer={[

                    <Button key="submit" type="primary" onClick={() => setModalVisible2(false)}>
                        Save
                    </Button>,
                ]}
            >
                <img src={require("../../images/R.png")} alt="" style={{ width: "5vh" }} />
                <span style={{ marginBottom: "4vh", marginLeft: "1vh" }}>NEW TASK</span>
                <Input placeholder="Enter Task Name..." size="large" style={{ marginRight: "8vw", marginTop: "2vh" }} />
                <Avatar size={40} icon={<UserOutlined />} /> Unassigned
                <Row style={{ border: '1px solid #ccc', padding: '4px', backgroundColor: '#f5f5f5', marginTop: "3vh" }}>
                    <Col span={9}>
                        <label style={{ fontSize: '17px', display: 'block' }}>State:</label>
                        <Checkbox style={{ marginLeft: '5px' }}>to do</Checkbox>
                    </Col>
                    <Col span={8}>
                        <label>Area:</label>
                        <span>Finaran</span>

                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={8}>
                        <h5>Description</h5>
                        <Divider style={{ width: "10vh" }} />
                        <Input.TextArea style={{ width: '70vh' }} rows={3} placeholder="Click to add Description" />
                        <label>Discussion</label>
                        <Divider style={{ width: "10vh" }} />
                        <Input.TextArea style={{ width: '70vh' }} rows={3} placeholder="Add a Comment" />
                    </Col>
                    <Col span={8}>
                        <h5 style={{ marginLeft: "10vh" }}>Planning</h5>
                        <Divider style={{ width: "10vh" }} />
                        <div style={{ marginLeft: "10vh" }}>
                            <label>Priority</label>
                            <Select style={{ width: "30vh" }}>
                                <Select.Option value="high">High</Select.Option>
                                <Select.Option value="medium">Medium</Select.Option>
                                <Select.Option value="low">Low</Select.Option>
                            </Select>
                            <label>Activity</label>
                            <Select style={{ width: "30vh" }}>
                                <Select.Option value="Deployment">Deployment</Select.Option>
                                <Select.Option value="Design">Design</Select.Option>
                                <Select.Option value="Development">Development</Select.Option>
                            </Select>
                            <label>Remaining Work</label>
                            <Input style={{ width: "30vh", height: "35px" }} />


                        </div>
                    </Col>
                    <Col span={8}>
                        <h5>Development</h5>
                        <Divider style={{ width: "10vh" }} />
                        <Button type='link' icon={<PlusOutlined style={{ color: "green" }} />} onClick={Addlink1} >Add links</Button>
                        <h5 style={{ marginTop: "5vh" }}>Related Works</h5>
                        <Divider style={{ width: "10vh" }} />
                        <Dropdown overlay={menu1} placement="bottomLeft">
                            <Button type='link' icon={<PlusOutlined style={{ color: "green" }} />}>Add links<DownOutlined /></Button>
                        </Dropdown>
                    </Col>
                </Row>

            </Modal>

            <Modal
                visible={open1}
                onCancel={() => setOpen1(false)}
                width={500}
                title='ADD LINKS'
            >
                <br />

                <h6>You are adding links from</h6>
                <img src={require("../../images/map.png")} alt="" style={{ width: '5vh', height: "5vh" }} />
                <label style={{ marginTop: "3vh" }}>Link Type</label>
                <Select style={{ width: "65vh" }}>

                    <Select.Option value="Build">Build</Select.Option>
                    <Select.Option value="Found in Build">Found in Build</Select.Option>
                    <Select.Option value="Integrated in Build">Integrated in Build</Select.Option>
                </Select>
                <label style={{ marginTop: "3vh" }}>Repository</label>
                <Select style={{ width: "65vh" }}>
                    <Select.Option value="high">Finaran</Select.Option>
                </Select>
                <label style={{ marginTop: "3vh" }}>Branch</label>
                <Select style={{ width: "65vh" }}>
                    <Select.Option value="Mine">Mine</Select.Option>
                    <Select.Option value="All Branches">All Branches</Select.Option>
                </Select>
                <label style={{ marginTop: "3vh" }}>Comments</label>
                <Input />
            </Modal>
        </div>
    )
}

export default Workitems