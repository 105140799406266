import React, { useState } from "react";
import html2canvas from "html2canvas";
import styles from "./SubmitRequestButton.module.css"; 

const SupportRequestButton = () => {
  const [isHovered, setIsHovered] = useState(false);

  const captureScreenshot = () => {
    html2canvas(document.body).then((canvas) => {
      const screenshotDataUrl = canvas.toDataURL();
      sendSupportRequest(screenshotDataUrl);
    });
  };

  const sendSupportRequest = () => {
    const subject = "Support Request";
    const body = "Please describe your issue or request here.";
    const email = "finaran-support@financonsulting.co";

    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    //window.location.href = mailtoLink;
    window.open(mailtoLink, "_blank");
  };

  return (
    <button
      className={isHovered ? styles.button + " " + styles.hovered : styles.button} // Apply the styles
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={sendSupportRequest}
    >
      Submit Support Request
    </button>
  );
};

export default SupportRequestButton;
