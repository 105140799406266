// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/BGV.jpeg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* BGV.css */

.bgv-container {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___}); 
    background-size: cover;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .bgv-content {
    text-align: center;
    color: #fff; 
  }
  
  .bgv-title {
    font-size: 2.5rem;
    margin-bottom: 10px;
  }
  
  .bgv-link {
    display: inline-block;
    margin-top: 15px;
    padding: 10px 20px;
    background-color: #1890ff; 
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
  }
  
  .bgv-error {
    color: #f5222d; 
    font-weight: bold;
    margin-top: 15px;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/BGV/BGV.css"],"names":[],"mappings":"AAAA,YAAY;;AAEZ;IACI,yDAA8C;IAC9C,sBAAsB;IACtB,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,uBAAuB;EACzB;;EAEA;IACE,kBAAkB;IAClB,WAAW;EACb;;EAEA;IACE,iBAAiB;IACjB,mBAAmB;EACrB;;EAEA;IACE,qBAAqB;IACrB,gBAAgB;IAChB,kBAAkB;IAClB,yBAAyB;IACzB,WAAW;IACX,qBAAqB;IACrB,kBAAkB;EACpB;;EAEA;IACE,cAAc;IACd,iBAAiB;IACjB,gBAAgB;EAClB","sourcesContent":["/* BGV.css */\n\n.bgv-container {\n    background-image: url('../../images/BGV.jpeg'); \n    background-size: cover;\n    height: 100vh;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n  \n  .bgv-content {\n    text-align: center;\n    color: #fff; \n  }\n  \n  .bgv-title {\n    font-size: 2.5rem;\n    margin-bottom: 10px;\n  }\n  \n  .bgv-link {\n    display: inline-block;\n    margin-top: 15px;\n    padding: 10px 20px;\n    background-color: #1890ff; \n    color: #fff;\n    text-decoration: none;\n    border-radius: 5px;\n  }\n  \n  .bgv-error {\n    color: #f5222d; \n    font-weight: bold;\n    margin-top: 15px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
