import React from 'react';
import { Table } from 'antd';

const columns = [
  {
    title: 'Employee ID',
    dataIndex: 'empId',
    key: 'empId',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Location First Name',
    dataIndex: 'firstName',
    key: 'firstName',
  },
];

const extractFirstName = (locationArray) => {
  if (locationArray && locationArray.length > 0) {
    const firstLocation = locationArray[0];
    const firstName = firstLocation;
    return firstName;
  }
  return '';
};

const LocationTable = () => {
  const data = [
    {
      "_id": {
        "$oid": "648ae752f4895e52f60f11b1"
      },
      "empId": "EMP100",
      "email": "Benjoe@gmail.com",
      "latitude": "9.9274374",
      "longitude": "78.0923869",
      "address": [
        
        {
          "weekId": "20",
          "date": "JUN 13",
          "location": [
            "chennai, Tamilnadu",
            "Madurai, Tamilnadu",
            "salem, Tamilnadu"
          ]
        }
      ],
      "__v": 0
    },
    {
      "_id": {
        "$oid": "648ae752f4895e52f60f11b1"
      },
      "empId": "EMP10044",
      "email": "joe@gmail.com",
      "latitude": "9.9274374",
      "longitude": "78.0923869",
      "address": [
        {
          "weekId": "20",
          "date": "JUN 12",
          "location": [
            "madurai, Tamilnadu",
            "Madurai, Tamilnadu",
            "salem, Tamilnadu"
          ]
        }
      ],
      "__v": 0
    },
    {
      "_id": {
        "$oid": "648ae752f4895e52f60f11b1"
      },
      "empId": "EMP100234",
      "email": "jagan@gmail.com",
      "latitude": "9.9274374",
      "longitude": "78.0923869",
      "address": [
        {
          "weekId": "20",
          "date": "JUN 12",
          "location": [
            "singapore, Tamilnadu",
            "Madurai, Tamilnadu",
            "salem, Tamilnadu"
          ]
        },
        {
          "weekId": "20",
          "date": "JUN 12",
          "location": [
            "salem, Tamilnadu",
            "Madurai, Tamilnadu",
            "salem, Tamilnadu"
          ]
        }
      ],
      "__v": 0
    }
  ];

  const tableData = data.map((item) => ({
    empId: item.empId,
    email: item.email,
    firstName: extractFirstName(item.address[0]?.location),
    key: item._id.$oid,
  }));

  return (
    <Table columns={columns} dataSource={tableData} />
  );
};

export default LocationTable;
