// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-table {
    width: 90%;
    margin: 20px auto;
    border-collapse: collapse;
    font-family: Arial, sans-serif;
  }
  
  .custom-table thead {
    background-color: #f4f6f9;
  }
  
  .custom-table th,
  .custom-table td {
    padding: 12px 15px;
    border: 1px solid #d0d7de;
    text-align: center;
  }
  
  .custom-table th {
    font-weight: bold;
    color: #333;
    font-size: 14px;
  }
  
  .custom-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .custom-table tbody tr:hover {
    background-color: #f1f4f8;
  }
  
  .custom-table td {
    font-size: 14px;
    color: #555;
  }
  
  .custom-table .ant-btn {
    margin-right: 8px;
    font-size: 12px;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/ExitManagement/NotificationTab.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,iBAAiB;IACjB,yBAAyB;IACzB,8BAA8B;EAChC;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;;IAEE,kBAAkB;IAClB,yBAAyB;IACzB,kBAAkB;EACpB;;EAEA;IACE,iBAAiB;IACjB,WAAW;IACX,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,eAAe;IACf,WAAW;EACb;;EAEA;IACE,iBAAiB;IACjB,eAAe;EACjB","sourcesContent":[".custom-table {\n    width: 90%;\n    margin: 20px auto;\n    border-collapse: collapse;\n    font-family: Arial, sans-serif;\n  }\n  \n  .custom-table thead {\n    background-color: #f4f6f9;\n  }\n  \n  .custom-table th,\n  .custom-table td {\n    padding: 12px 15px;\n    border: 1px solid #d0d7de;\n    text-align: center;\n  }\n  \n  .custom-table th {\n    font-weight: bold;\n    color: #333;\n    font-size: 14px;\n  }\n  \n  .custom-table tbody tr:nth-child(even) {\n    background-color: #f9f9f9;\n  }\n  \n  .custom-table tbody tr:hover {\n    background-color: #f1f4f8;\n  }\n  \n  .custom-table td {\n    font-size: 14px;\n    color: #555;\n  }\n  \n  .custom-table .ant-btn {\n    margin-right: 8px;\n    font-size: 12px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
