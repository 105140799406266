import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import {
  Tabs,
  Table,
  Select,
  Modal,
  Descriptions,
  Tooltip,
  Button,
  Space,
  notification,
  Form,
  Input,
  Popconfirm,
} from "antd";
import { decryptRole } from "../../encrypt";
import { url } from "../../url/url";
import { Link, useNavigate } from "react-router-dom";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  DeleteFilled,
  EditFilled,
  EyeFilled,
  SaveFilled,
} from "@ant-design/icons";
const { TabPane } = Tabs;

const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18,
  },
};
function Travelexpense() {
  const errorRef = useRef(null);
  const users = JSON.parse(localStorage.getItem("user"));
  const [travelRequests, setTravelRequests] = useState([]);
  const [allTravelRequests, setAllTravelRequests] = useState([]);

  const [editingRequest, setEditingRequest] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [form] = Form.useForm();
  const [mode, setMode] = useState("view");

  const [modalVisible, setModalVisible] = useState(false);
  const navigate = useNavigate();
  const [departureDate, setDepartureDate] = useState("");
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [error, setError] = useState(null);
  const handleClickOpen = (record) => {
    setSelectedRequest(record);
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    setSelectedRequest(null);
  };

  const handleEdit = (record) => {
    setEditingRequest(record);
    form.setFieldsValue({
      ...record,
    });
    setEditedData({ ...record });
    setIsModalVisible(true);
  };
  const handleSaveClick = async (values) => {
    console.log(values, "Value");
    const { departureDate, returnDate } = values;
    if (departureDate > returnDate) {
      setError("Departure date cannot be greater than return date.");
      // Scroll to the error element
      if (errorRef.current) {
        errorRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
      }
      return;
    }

    const dbName = localStorage.getItem("dbName");
    try {
      const response = await axios.put(
        `${url}/update/travel/${editingRequest.requestId}/${dbName}`,
        values
      );
      setTravelRequests(
        travelRequests.map((record) =>
          record.requestId === editingRequest.requestId
            ? { ...record, ...response.data }
            : record
        )
      );
      setEditingRequest(null);

      notification.success({
        message: "Updated Successfully",
        description: "New Changes Updated successfully!",
        placement: "bottomRight",
      });
      setIsModalVisible(false);
      setEditingRequest(null);
    } catch (error) {
      console.error(error.message);
    }
  };

  const handlecancel = () => {
    setEditingRequest(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      const dbName = localStorage.getItem("dbName");
      const encryptedRoleFromStorage = localStorage.getItem("role");
      const jobTitle = decryptRole(encryptedRoleFromStorage);
      try {
        if (jobTitle === "hr" || jobTitle === "admin") {
          const response = await axios.get(
            `${url}/getall/travelrequest/${dbName}`
          );
          const filteredRequests = response.data.travelRequests.filter(
            (request) => request.email !== users.email
          );
          setAllTravelRequests(filteredRequests);
        } else if (jobTitle === "manager") {
          const response = await axios.get(
            `${url}/get/travelrequest/${dbName}/${users.displayName}`
          );
          // Filter out the displayName's request details
          const filteredRequests = response.data.travelRequests.filter(
            (request) => request.email !== users.email
          );
          setAllTravelRequests(filteredRequests);
          console.log(filteredRequests, "Request");
        }
      } catch (error) {
        console.error("Error fetching travel requests:", error);
      }
    };

    fetchData();
  }, []);

  const fetchData2 = async () => {
    const dbName = localStorage.getItem("dbName");
    const empCode = users.empCode;
    try {
      const response = await axios.get(
        `${url}/getUser/travelrequest/${dbName}/${empCode}`
      );
      setTravelRequests(response.data.travelRequests);
      console.log(response.data, "Response");
    } catch (error) {
      console.error("Error fetching travel requests:", error);
    }
  };

  const deleteTravelRequest = async (requestId) => {
    const dbName = localStorage.getItem("dbName");
    const empCode = users.empCode;

    try {
      const response = await axios.delete(
        `${url}/deleteUser/travelrequest/${dbName}/${empCode}/${requestId}`
      );
      setTravelRequests(
        travelRequests.filter((record) => record.requestId !== requestId)
      );
      console.log(response.data);
    } catch (error) {
      console.error("Error deleting travel request:", error);
    }
  };

  useEffect(() => {
    fetchData2();
  }, [editingRequest]);
  const columns = [
    {
      title: "Employee Id",
      dataIndex: "employeeId",
      width: 200,

      filters: travelRequests
        .map((record) => record.employeeId)
        .filter((value, index, self) => self.indexOf(value) === index)
        .map((value) => ({ text: value, value })),
      onFilter: (value, record) => record.employeeId.includes(value),
    },
    {
      title: "Employee Name",
      dataIndex: "firstName",
      width: 250,

      filters: travelRequests
        .map((record) => `${record.firstName} ${record.lastName}`) // Extract unique values
        .filter((value, index, self) => self.indexOf(value) === index) // Filter unique values
        .map((value) => ({ text: value, value })),
      onFilter: (value, record) =>
        `${record.firstName} ${record.lastName}`.includes(value),
      render: (_, record) => (
        <>
          {record.firstName} {record.lastName}
        </>
      ),
    },
    {
      title: "Request Id",
      dataIndex: "requestId",
      width: 200,
    },
    {
      title: "Travel Type",
      dataIndex: "travelType",
      width: 150,
      filters: travelRequests
        .map((record) => record.travelType)
        .filter((value, index, self) => self.indexOf(value) === index)
        .map((value) => ({ text: value, value })),
      onFilter: (value, record) => record.travelType.includes(value),
    },
    {
      title: "Purpose",
      dataIndex: "reasonForTravel",
      width: 150,
      filters: travelRequests
        .map((record) => record.reasonForTravel)
        .filter((value, index, self) => self.indexOf(value) === index)
        .map((value) => ({ text: value, value })),
      onFilter: (value, record) => record.reasonForTravel.includes(value),
    },
    {
      title: "From date",
      dataIndex: "departureDate",
      width: 150,
      filters: travelRequests
        .map((record) => record.departureDate)
        .filter((value, index, self) => self.indexOf(value) === index)
        .map((value) => ({ text: value, value })),
      onFilter: (value, record) => record.departureDate.includes(value),
    },
    {
      title: "To Date",
      dataIndex: "returnDate",
      width: 150,
      filters: travelRequests
        .map((record) => record.returnDate)
        .filter((value, index, self) => self.indexOf(value) === index)
        .map((value) => ({ text: value, value })),
      onFilter: (value, record) => record.returnDate.includes(value),
    },

    {
      title: "Status",
      dataIndex: "status",
      width: 150,
      filters: travelRequests
        .map((record) => record.status)
        .filter((value, index, self) => self.indexOf(value) === index)
        .map((value) => ({ text: value, value })),
      onFilter: (value, record) => record.status.includes(value),
      render: (text, record) => (
        <span
          style={{
            color:
              record.status === "Approved"
                ? "green"
                : record.status === "Denied"
                  ? "red"
                  : "inherit",
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Actions",
      dataIndex: "id",
      width: 150,
      render: (text, record) => (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Button
            onClick={() => {
              handleClickOpen(record);
            }}
            icon={<EyeFilled />}
          />
          {record.status === "Submitted" && (
            <>
              <Button
                style={{ left: "1vh" }}
                onClick={() => handleEdit(record)}
                icon={<EditFilled />}
              />
              <Popconfirm
                title="Are you sure want to delete ?"
                onConfirm={() => deleteTravelRequest(record.requestId)}
              >
                <Button
                  style={{ marginLeft: "2vh" }}
                  icon={<DeleteFilled />}
                  type="primary"
                  danger
                />
              </Popconfirm>
            </>
          )}
        </div>
      ),
    },
  ];

  const handleDepartureDateChange = (e) => {
    setDepartureDate(e.target.value);
  };
  const calculateMinDate = () => {
    const currentDate = new Date();
    const oneMonthBefore = new Date(currentDate);
    oneMonthBefore.setMonth(oneMonthBefore.getMonth() - 1);
    return oneMonthBefore.toISOString().split("T")[0];
  };

  const shouldUpdate = (prevProps, nextProps) => {
    if (
      prevProps.dataSource === nextProps.dataSource &&
      prevProps.count === nextProps.count
    ) {
      return false;
    }
    return true;
  };
  const link = () => {
    navigate("/home/travelform");
  };
  return (
    <div style={{ transform: "scale(0.9)", transformOrigin: "0 0", width: "110%" }}>
      <h4>Travel Request</h4>

      <Button
        onClick={link}
        type="primary"
        style={{ display: "flex", justifyContent: "center" }}
      >
        Raise Travel Request
      </Button>
      <br />

      <div style={{ width: "100%", overflowX: "auto" }}>
        <Table
          className="custom-table col-lg-12 col-md-12 col-sm-12"
          dataSource={travelRequests}
          columns={columns}
          scroll={{ x: true }}
          sticky
        />
      </div>

      {editingRequest && (
        <Modal
          title="Edit Request"
          visible={editingRequest !== null}
          onCancel={() => handlecancel()}
          onOk={() => form.submit()} // Submit form only in edit mode
          bodyStyle={{ maxHeight: 450, overflowY: "auto", margin: "20px" }}
          footer={[
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <Button type="primary" onClick={() => form.submit()}>
                  Ok
                </Button>
                <Button onClick={() => setEditingRequest(null)}>Cancel</Button>
              </div>
            </div>,
          ]}
        >
          <Form
            form={form}
            {...layout}
            layout="vertical"
            initialValues={editingRequest}
            onFinish={handleSaveClick}
          >
            <Form.Item label="Employee id" name="employeeId">
              <Input readOnly />
            </Form.Item>
            <Form.Item label="Employee Name" name="firstName">
              <Input readOnly />
            </Form.Item>
            <Form.Item label="Email" name="email">
              <Input readOnly />
            </Form.Item>
            <Form.Item label="manager" name="manager">
              <Input readOnly />
            </Form.Item>
            <Form.Item label="Department" name="department">
              <Input readOnly />
            </Form.Item>
            <Form.Item label="Destination Country" name="destinationCountry">
              <Input />
            </Form.Item>
            <Form.Item label="Destination State" name="destinationState">
              <Input />
            </Form.Item>
            <Form.Item label="Destination City" name="destinationCity">
              <Input />
            </Form.Item>
            <Form.Item label="Zipcode" name="zipCode">
              <Input />
            </Form.Item>
            <Form.Item label="Origin Country" name="OriginCountry">
              <Input />
            </Form.Item>
            <Form.Item label="Origin State" name="OriginState">
              <Input />
            </Form.Item>
            <Form.Item label="Origin City" name="OriginCity">
              <Input />
            </Form.Item>
            <Form.Item label="Departure Date" name="departureDate">
              <Input
                type="date"
                onChange={handleDepartureDateChange}
                min={calculateMinDate()}
              />
            </Form.Item>
            {error && (
              <p style={{ color: "red" }} ref={errorRef}>
                {error}
              </p>
            )}
            <Form.Item label="Return Date" name="returnDate">
              <Input type="date" min={departureDate} />
            </Form.Item>
            <Form.Item label="Purpose" name="reasonForTravel">
              <Input />
            </Form.Item>
            <Form.Item label="Travel Cost" name="estimatedTravelCosts">
              <Input />
            </Form.Item>
            <Form.Item label="Travel Advance" name="travelAdvance">
              <Input />
            </Form.Item>
            <Form.Item label="Travel Type" name="travelType">
              <Input />
            </Form.Item>
            <Form.Item label="Currency" name="currency">
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      )}
      <Modal
        title="Travel Request Details"
        visible={modalVisible}
        onCancel={handleCloseModal}
        footer={null}
      >
        {selectedRequest && (
          <Descriptions column={1} bordered>
            <Descriptions.Item label="Employee Id">
              {selectedRequest.employeeId}
            </Descriptions.Item>
            <Descriptions.Item label="Employee Name">
              {selectedRequest.firstName} {selectedRequest.lastName}
            </Descriptions.Item>
            <Descriptions.Item label="Email">
              {selectedRequest.email}
            </Descriptions.Item>
            <Descriptions.Item label="Manager">
              {selectedRequest.manager}
            </Descriptions.Item>
            <Descriptions.Item label="Department">
              {selectedRequest.department}
            </Descriptions.Item>
            <Descriptions.Item label="Destination Country">
              {selectedRequest.destinationCountry}
            </Descriptions.Item>
            <Descriptions.Item label="Destination State">
              {selectedRequest.destinationState}
            </Descriptions.Item>
            <Descriptions.Item label="Destination City">
              {selectedRequest.destinationCity}
            </Descriptions.Item>
            <Descriptions.Item label="Zipcode">
              {selectedRequest.zipCode}
            </Descriptions.Item>
            <Descriptions.Item label="Origin Country">
              {selectedRequest.OriginCountry}
            </Descriptions.Item>
            <Descriptions.Item label="Origin State">
              {selectedRequest.OriginState}
            </Descriptions.Item>
            <Descriptions.Item label="Origin City">
              {selectedRequest.OriginCity}
            </Descriptions.Item>
            <Descriptions.Item label="Request Date">
              {selectedRequest.requestDate}
            </Descriptions.Item>
            <Descriptions.Item label="Departure Date">
              {selectedRequest.departureDate}
            </Descriptions.Item>
            <Descriptions.Item label="Return Date">
              {selectedRequest.returnDate}
            </Descriptions.Item>
            <Descriptions.Item label="Purpose">
              {selectedRequest.reasonForTravel}
            </Descriptions.Item>
            <Descriptions.Item label="Travel Cost">
              {selectedRequest.estimatedTravelCosts}
            </Descriptions.Item>
            <Descriptions.Item label="Travel Advance">
              {selectedRequest.travelAdvance}
            </Descriptions.Item>
            <Descriptions.Item label="Travel Type">
              {selectedRequest.travelType}
            </Descriptions.Item>
            <Descriptions.Item label="Currency">
              {selectedRequest.currency}
            </Descriptions.Item>
            <Descriptions.Item label="Comments">
              {selectedRequest.comments}
            </Descriptions.Item>
          </Descriptions>
        )}
      </Modal>
    </div>
  );
}
export default Travelexpense;
