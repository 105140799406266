import React, { useState, useEffect } from "react";
import {
  Table,
  Col,
  Row,
  Card,
  Button,
  Modal,
  Form,
  Input,
  Select,
  notification,
  Tabs,
  Descriptions,
  Space,
  Popconfirm,
  Tooltip,
} from "antd";
import "./Job.css";
import {
  PlusOutlined,
  SaveFilled,
  EyeOutlined,
  DownloadOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { url } from "../../url/url";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 12,
  },
};

const Postjobs = () => {
  const [modalvisible, setModalVisible] = useState(false);
  const [job, setJob] = useState([]);
  const [count, setCount] = useState(0);
  const navigate = useNavigate();
  const [offer, setOffer] = useState([]);
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [editingRecord, setEditingRecord] = useState(null);
  const users = JSON.parse(localStorage.getItem("user"));

  const handleEdit = (record) => {
    setEditingRecord(record);
    form.setFieldsValue({
      ...record,
    });
  };

  const shouldUpdate = (prevProps, nextProps) => {
    if (
      prevProps.dataSource === nextProps.dataSource &&
      prevProps.count === nextProps.count
    ) {
      return false;
    }
    return true;
  };
  const fetchData1 = async () => {
    const dbName = localStorage.getItem("dbName");
    try {
      const response = await axios.get(`${url}/get/jobs/${dbName}`);
      setOffer(response.data);
      console.log(response.data, "res");
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const handleUpdate = async (values) => {
    const dbName = localStorage.getItem("dbName");
    try {
      const response = await axios.put(
        `${url}/api/updatejobs/${editingRecord._id}/${dbName}`,
        values
      );
      setOffer(
        offer.map((record) =>
          record._id === editingRecord._id
            ? { ...record, ...response.data }
            : record
        )
      );
      setEditingRecord(null);
      notification.success({ message: "Record updated in DB successfully!" });
    } catch (err) {
      notification.error({ message: "Failed to update the Record" });
    }
  };

  const handleDelete = async (values) => {
    const dbName = localStorage.getItem("dbName");
    try {
      const response = await axios.delete(
        `${url}/api/Deletejobs/${values._id}/${dbName}`
      );
      setCount(count + 1);
      notification.success({ message: "Record updated in DB successfully!" });
    } catch (err) {
      notification.error({ message: "Failed to update the Record" });
    }
  };
  const padZero = (num) => (num < 10 ? `0${num}` : num);
  const today = new Date();

  const day = padZero(today.getDate());
  const month = padZero(today.getMonth() + 1); // Adding 1 because getMonth() returns 0-indexed month
  const year = today.getFullYear();

  const formattedDate = `${day}-${month}-${year}`;
  useEffect(() => {
    fetchData1();
  }, [editingRecord, count]);

  const columns2 = [
    {
      title: "Job Role",
      dataIndex: "role",
      filters: offer
        .map((record) => record.role)
        .filter((value, index, self) => self.indexOf(value) === index)
        .map((value) => ({ text: value, value })),
      onFilter: (value, record) => record.role.includes(value),
    },
    {
      title: "Job Description",
      dataIndex: "description",
      filters: offer
        .map((record) => record.description)
        .filter((value, index, self) => self.indexOf(value) === index)
        .map((value) => ({ text: value, value })),
      onFilter: (value, record) => record.description.includes(value),
      render: (text) => (
        <Tooltip
          title={text}
          getPopupContainer={(triggerNode) =>
            document.getElementById("tooltipContainer")
          }
        >
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      filters: offer
        .map((record) => record.date)
        .filter((value, index, self) => self.indexOf(value) === index)
        .map((value) => ({ text: value, value })),
      onFilter: (value, record) => record.date.includes(value),
    },
    {
      title: "Employee Type",
      dataIndex: "empType",
      filters: offer
        .map((record) => record.empType)
        .filter((value, index, self) => self.indexOf(value) === index)
        .map((value) => ({ text: value, value })),
      onFilter: (value, record) => record.empType.includes(value),
    },
    {
      title: "Status",
      dataIndex: "status",
      filters: offer
        .map((record) => record.status)
        .filter((value, index, self) => self.indexOf(value) === index)
        .map((value) => ({ text: value, value })),
      onFilter: (value, record) => record.status.includes(value),
    },
    {
      title: "Action",
      key: "id",
      render: (_, record) => (
        <Space>
          <Button
            onClick={() => handleEdit(record)}
            size="small"
            icon={<EditOutlined />}
          />
          <Popconfirm
            title="Are you sure you want to delete this item?"
            onConfirm={() => handleDelete(record)}
            okText="Yes"
            cancelText="No"
          >
            <Button size="small" icon={<DeleteOutlined />} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const showmodal = () => {
    setModalVisible(true);
  };

  const onCancel = () => {
    setEditingRecord(null);
  };
  const goBack = () => {
    navigate(-1);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  const postData = async (values) => {
    const dbName = localStorage.getItem("dbName");
    try {
      values.date = formattedDate;
      const response = await axios.post(`${url}/post/jobs/${dbName}`, values);
      setJob(response.data);
      notification.success({ message: "Successfully Posted" });
      form.resetFields();
      setModalVisible(false);
      setCount(count + 1);
      console.log("Data posted successfully:", response.data);
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };

  return (
    <div>
      <Button onClick={goBack}>Back</Button>
      <Button
        type="primary"
        style={{ background: "#EA5851", marginLeft: "5vh" }}
        onClick={showmodal}
        icon={<PlusOutlined />}
      >
        New Jobs
      </Button>
      <h4 style={{ color: "#B0B2CD", fontWeight: "bold" }}>Posted Jobs</h4>
      <br />

      <Table
        className="custom-table col-lg-12 col-md-12 col-sm-12"
        columns={columns2}
        dataSource={offer}
        scroll={{ y: true }}
        shouldComponentUpdate={shouldUpdate}
        sticky
      />
      <Modal
        title="Add Jobs"
        visible={modalvisible}
        onCancel={handleCancel}
        footer={null}
        width={500}
      >
        <Form form={form} {...layout} onFinish={postData}>
          <Form.Item label="Job Role" name="role" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Job Description"
            name="description"
            rules={[{ required: true }]}
          >
            <Input.TextArea rows={2} />
          </Form.Item>
          <Form.Item
            label="Job Skills"
            name="skills"
            rules={[{ required: true }]}
          >
            <Input.TextArea rows={2} />
          </Form.Item>
          <Form.Item
            label="Employee Type"
            name="empType"
            rules={[{ required: true }]}
          >
            <Select>
              <Select.Option value="Full time">Full time</Select.Option>
              <Select.Option value="Part time">Part time</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Date of Posting">
            <Input value={formattedDate} style={{ fontSize: "14px" }} />
          </Form.Item>
          <Form.Item style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              type="primary"
              style={{ background: "#EA5851" }}
              icon={<SaveFilled />}
              htmlType="submit"
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {editingRecord && (
        <Modal
          title="Edit Job Offers"
          width={550}
          visible={editingRecord !== null}
          onCancel={() => onCancel()}
          onOk={() => form.submit()}
          bodyStyle={{ maxHeight: 450, overflowY: "auto", margin: "20px" }}
          footer={null}
        >
          <Form
            {...layout}
            form={form1}
            initialValues={editingRecord}
            onFinish={handleUpdate}
            className="custom-form"
          >
            <Form.Item label="Job Role" name="role">
              <Input />
            </Form.Item>
            <Form.Item label="Job Description" name="description">
              <Input.TextArea rows={2} />
            </Form.Item>
            <Form.Item label="Job Skills" name="skills">
              <Input.TextArea rows={2} />
            </Form.Item>
            <Form.Item label="Employee Type" name="empType">
              <Select>
                <Select.Option value="Full time">Full time</Select.Option>
                <Select.Option value="Part time">Part time</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label="Date of Posting" name="date">
              <Input readOnly />
            </Form.Item>
            <Form.Item label="Status" name="status">
              <Select disabled={editingRecord.status === "Closed"}>
                <Select.Option value="Open">Open</Select.Option>
                <Select.Option value="Closed">Closed</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                type="primary"
                style={{ background: "#EA5851" }}
                icon={<SaveFilled />}
                htmlType="submit"
              >
                Update
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      )}
    </div>
  );
};

export default Postjobs;
