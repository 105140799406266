// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* SupportRequestButton.module.css */
.SubmitRequestButton_button__utsce {
    float: right;
    margin: 0 10px 10px 0;
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 12px;
  }
  
  .SubmitRequestButton_button__utsce.SubmitRequestButton_hovered__hjKv1 {
    background-color: #0056b3;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/header/SubmitRequestButton.module.css"],"names":[],"mappings":"AAAA,oCAAoC;AACpC;IACI,YAAY;IACZ,qBAAqB;IACrB,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,eAAe;IACf,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":["/* SupportRequestButton.module.css */\n.button {\n    float: right;\n    margin: 0 10px 10px 0;\n    background-color: #007bff;\n    color: #fff;\n    border: none;\n    padding: 5px 10px;\n    cursor: pointer;\n    font-size: 12px;\n  }\n  \n  .button.hovered {\n    background-color: #0056b3;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `SubmitRequestButton_button__utsce`,
	"hovered": `SubmitRequestButton_hovered__hjKv1`
};
export default ___CSS_LOADER_EXPORT___;
