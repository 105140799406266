import React, { createContext, useState } from 'react';

// Create a context
export const ColorContext = createContext();

const ColorProvider = ({ children }) => {
  const [color, setColor] = useState('#00134d'); // Initial color

  return (
    <ColorContext.Provider value={{ color, setColor }}>
      {children}
    </ColorContext.Provider>
  );
};

export default ColorProvider;
