import React, { useState, useEffect } from "react";
import axios from "axios";
import { List, Select, Form, Menu, Dropdown, Divider } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import Chart from "react-apexcharts";
import ApexCharts from "react-apexcharts";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend } from "recharts";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { Calendar, Progress } from "antd";

import {
  Card,
  Row,
  Col,
  message,
  Button,
  Empty,
  Descriptions,
  Alert,
  Tooltip,
  Avatar,
} from "antd";
import {
  FieldTimeOutlined,
  FileAddOutlined,
  IdcardOutlined,
  FileProtectOutlined,
  ExceptionOutlined,
  CalendarOutlined,
  ClockCircleOutlined,
  LeftOutlined,
  RightOutlined,
  BuildOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Stat, StatGroup } from "@chakra-ui/react";
import Stack from "@mui/material/Stack";
import Badge from "@mui/material/Badge";
import { url } from "../url/url";
import { FaUser, FaCalendarAlt } from "react-icons/fa";
import Timer from "../pages/timer";

const Dashboard2 = (props) => {
  const [reject, setReject] = useState([]);
  const [leave, setLeave] = useState([]);
  const [projData, setProjData] = useState([]);
  const [Users, setUsers] = useState([]);
  const [claims, setClaims] = useState([]);
  const [compoff, setCompoff] = useState([]);
  const [Approval, setApproval] = useState([]);
  const [EmployeeData, setEmployeeData] = useState([]);
  const [user] = useState(props.user);
  const [balance1, setAnnualbalance] = useState([]);
  const [balance2, setCasualbalance] = useState([]);
  const [balance3, setCompoffbalance] = useState([]);
  const [location, setlocation] = useState("");
  const locationCheck = localStorage.getItem("switchState");
  const dbName = localStorage.getItem("dbName");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [pdfFiles, setPdfFiles] = useState([]);
  const users = JSON.parse(localStorage.getItem("user"));
  const [selectedDate, setSelectedDate] = useState(null);
  const [checkedIn, setCheckedIn] = useState(false);
  const [checkInTime, setCheckInTime] = useState(null);
  const [checkOutTime, setCheckOutTime] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [date, setDate] = useState(new Date());
  const [tableData, setTableData] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [existingSprints, setExistingSprints] = useState([]);
  const [data, setData] = useState([]);
  const [sprint, setSprint] = useState(null);
  const [selectedSprint, setSelectedSprint] = useState(null);
  const [epic, setEpic] = useState([]);
  const [task, setTask] = useState(0);
  const [totalTask, setTotalTask] = useState(0);
  const [done, setDone] = useState(0);
  const [check, setCheck] = useState([]);
  const [disablecheckIn, setDisableCheckIn] = useState(false);
  const [disablecheckOut, setDisableCheckOut] = useState(false);
  const [count, setCount] = useState(0);

  useEffect(() => {
    // Function to fetch check-in and check-out times for the current date
    const fetchCheckInOutData = async () => {
      const dbName = localStorage.getItem("dbName");
      const currentDate = new Date().toISOString().slice(0, 10); // Get current date in YYYY-MM-DD format

      try {
        const response = await fetch(
          `${url}/api/checkinout/${dbName}/${currentDate}/${user.displayName}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch check-in/out data");
        }
        const data = await response.json();
        console.log(data.checkInOutEntries, "dat");
        setCheck(data.checkInOutEntries);

        // Check if data exists for the current date and user
        if (data.checkInOutEntries[0].checkInTime) {
          setDisableCheckIn(true);
        } else {
          setDisableCheckIn(false);
        }
        if (data.checkInOutEntries[0].checkOutTime) {
          setDisableCheckOut(true);
        } else {
          setDisableCheckOut(false);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchCheckInOutData(); // Call the function on component mount
  }, [count]);

  const handleSprintSelection = async (sprint) => {
    setSelectedSprint(sprint.sprintName);
    setSprint(sprint);
  };

  // useEffect(() => {
  //   fetchEpic(selectedSprint);
  // }, []);
  // const fetchEpic = async (selectedsprint) => {
  //   try {
  //     const dbName = localStorage.getItem("dbName");
  //     const response = await fetch(`${url}/epic/${selectedsprint}/${dbName}`);
  //     if (!response.ok) {
  //       throw new Error("Failed to fetch Epic data");
  //     }
  //     const data = await response.json();
  //     setEpic(data);
  //   } catch (error) {
  //     console.error("Error:", error);
  //     throw new Error("An error occurred while fetching sprint details.");
  //   }
  // };
  // useEffect(() => {
  //   const isCheckedIn = localStorage.getItem("checkedIn");
  //   const lastCheckInTime = localStorage.getItem("checkInTime");
  //   const lastCheckOutTime = localStorage.getItem("checkOutTime");

  //   if (isCheckedIn && lastCheckInTime) {
  //     setCheckedIn(true);
  //     setCheckInTime(new Date(lastCheckInTime));
  //     if (lastCheckOutTime) {
  //       setCheckOutTime(new Date(lastCheckOutTime));
  //     }
  //   }
  // }, []);

  // Save checked-in state and times to localStorage
  // useEffect(() => {
  //   if (checkedIn) {
  //     localStorage.setItem("checkedIn", "true");
  //     localStorage.setItem("checkInTime", checkInTime?.toString());
  //   } else {
  //     localStorage.removeItem("checkedIn");
  //     localStorage.removeItem("checkInTime");
  //   }
  // }, [checkedIn, checkInTime]);

  // useEffect(() => {
  //   if (!checkedIn) {
  //     localStorage.setItem("checkOutTime", checkOutTime?.toString());
  //   } else {
  //     localStorage.removeItem("checkOutTime");
  //   }
  // }, [checkedIn, checkOutTime]);

  const handleCheckIn = async () => {
    // setCheckedIn(true);
    const currentDate = new Date();
    setCheckInTime(currentDate);
    setCheckOutTime(null);
    await postCheckInData(currentDate);
  };

  const handleCheckOut = async () => {
    const currentDate = new Date();
    setCheckOutTime(currentDate);
    await postCheckOutData(currentDate);
  };

  const postCheckInData = async (checkInTime) => {
    const formattedCheckInTime = formatDateTime(checkInTime);
    console.log(formattedCheckInTime, "formattedCheckInTime");
    const postData = {
      userName: users.displayName,
      checkInTime: formattedCheckInTime,
    };
    const dbName = localStorage.getItem("dbName");
    console.log(postData, "post");
    try {
      const response = await fetch(`${url}/api/checkin/${dbName}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
      });
      if (!response.ok) {
        throw new Error("Failed to save check-in time");
      }
      const data = await response.json();
      console.log(data.message); // Log success message
      setCount(count + 1);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const postCheckOutData = async (checkOutTime) => {
    const formattedCheckOutTime = formatDateTime(checkOutTime);
    const postData = {
      userName: users.displayName,
      checkOutTime: formattedCheckOutTime,
    };
    const dbName = localStorage.getItem("dbName");
    try {
      const response = await fetch(`${url}/api/checkout/${dbName}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
      });
      if (!response.ok) {
        throw new Error("Failed to save check-out time");
      }
      const data = await response.json();
      console.log(data.message); // Log success message
      setCount(count + 1);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const formatTime = (time) => {
    if (!(time instanceof Date)) {
      return "Invalid Time"; // Or handle the case appropriately
    }

    const hours = time.getHours();
    const minutes = time.getMinutes();
    const seconds = time.getSeconds();
    return `${padZero(hours)}:${padZero(minutes)}:${padZero(seconds)}`;
  };

  const formatDateTime = (currentDate) => {
    if (!currentDate || !(currentDate instanceof Date)) {
      return "Invalid Date"; // Or handle the case appropriately
    }
    const day = String(currentDate.getDate()).padStart(2, "0");
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = currentDate.getFullYear();
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");

    // Format the date and time as desired
    const formattedDate = `${day}/${month}/${year}`;
    const formattedTime = `${hours}:${minutes}:${seconds}`;
    const formattedDateTime = `${formattedDate} ${formattedTime}`;
    return formattedDateTime;
  };

  const padZero = (num) => (num < 10 ? `0${num}` : num);

  const handlePanelChange = (value, mode) => {
    setSelectedDate(value);
  };
  const flattenedData = data.reduce((acc, curr) => {
    Object.keys(curr).forEach((key) => {
      if (key !== "task") {
        acc.push({ task: curr.task, day: key, hours: curr[key] });
      }
    });
    return acc;
  }, []);

  // Get unique tasks
  const tasks = [...new Set(data.map((item) => item.task))];
  //const colorData = localStorage.getItem("color");
  //console.log(colorData, "colorData in dashboard2");
  const [chartData, setChartData] = useState({
    options: {
      labels: ["Project 1", "Project 2", "Project 3"], // Your project names
      colors: ["#008FFB", "#00E396", "#FEB019"], // Colors for each project
      plotOptions: {
        pie: {
          donut: {
            size: "80%",
          },
        },
      },
      dataLabels: {
        enabled: false, // Disable data labels (numbers and percentages)
      },
      chart: {
        events: {
          dataPointSelection: function(event, chartContext, config) {
            // Prevent click event
            event.preventDefault();
          },
        },
      },
    },
    series: [30, 20, 25], // Placeholder data for timesheet hours
  });

  // Calculate total hours
  const totalHours = chartData.series.reduce((acc, curr) => acc + curr, 0);

  useEffect(() => {
    // Update screenWidth when the component mounts
    setScreenWidth(window.innerWidth);

    // Add an event listener to update screenWidth when the window is resized
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const height = screenWidth <= 1024 ? 360 : 220;
  const headerToolbarOptions = {
    left: "prev,next",
    center: "title",
    right: "today",
  };

  const renderEventContent = (eventInfo) => {
    return eventInfo.event.extendedProps.icon;
  };

  useEffect(() => {
    GetHolidays();
    fetchUsers();
    fetchData();
    fetchleave();
    fetchLeavePerc();
    fetchClaims();
    fetchCompoff();
    fetchApproval();
    fetchPDFList();
    // fetch(`${url}/timesheet/getinputs/Submitted/${dbName}`)
    //   .then((response) => response.json())
    //   .then((data) => setData(data))
    //   .catch((error) => console.error(error));

    // fetch(`${url}/timesheet/getinputs/reject/${dbName}`)
    //   .then((response) => response.json())
    //   .then((data) => setReject(data))
    //   .catch((error) => console.error(error));
    // Make a GET request to your server endpoint
    const dbName = localStorage.getItem("dbName");
  }, []);

  const fetchLeavePerc = async () => {
    try {
      const dbName = localStorage.getItem("dbName");
      // For Azure Users, user in localstorage will be set only after refresh

      const response1 = await axios.get(
        `${url}/api/getUserManager/${user.displayName}/${dbName}`
      );

      const empCode = response1.data.user.empCode;

      // const res = await axios.get(`${url}/leave/ereport/${empCode}/${dbName}`);
      // // Filter the leave records to get only the approved ones
      // const approvedLeave = res.data.filter((leave) => {
      //   const status = leave.status.toLowerCase(); // Convert status to lowercase for case-insensitive comparison
      //   return status === "submitted" || status === "approved";
      // });

      // setLeave(approvedLeave);
      // console.log("inside leave calling send leave");
      const response = await axios.get(
        `${url}/send/Leavebalance/${empCode}/${dbName}`
      );
      const availableAnnualLeave = response.data.annualLeaveBal;
      const availableCasualLeave = response.data.casualLeaveBal;
      const availableCompoffLeave = response.data.compoffBal;

      setAnnualbalance(availableAnnualLeave);
      // console.log(setAnnualbalance,"");
      setCasualbalance(availableCasualLeave);
      setCompoffbalance(availableCompoffLeave);
      console.log(
        availableAnnualLeave,
        availableCasualLeave,
        availableCompoffLeave,
        "leaves"
      );
    } catch (error) {
      console.log(error);
    }
  };

  const fetchleave = async () => {
    try {
      const dbName = localStorage.getItem("dbName");
      const res = await axios.get(
        `${url}/leave/approval/${user.displayName}/manager/${dbName}`
      );
      setEmployeeData(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchClaims = async () => {
    try {
      const dbName = localStorage.getItem("dbName");
      const res = await axios.get(
        `${url}/claim/approval/${user.displayName}/manager/${dbName}`
      );
      setClaims(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCompoff = async () => {
    try {
      const dbName = localStorage.getItem("dbName");
      const res = await axios.get(
        `${url}/compoff/approval/${user.displayName}/manager/${dbName}`
      );
      setCompoff(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async () => {
    const dbName = localStorage.getItem("dbName");
    try {
      console.log("display name in fetch data", user.displayName);
      const response = await fetch(
        `${url}/api/projectsdetails/${user.displayName}/${dbName}`
      );
      const projdata = await response.json();
      console.log("projdata", projdata);
      setProjData(projdata);
    } catch (error) {
      console.error(error);
      message.error("Failed to fetch data from the server.");
    }
  };

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const dbName = localStorage.getItem("dbName");
      console.log("user.displayName", user.displayName);
      const displayName = `${user.displayName}`;
      const response = await axios.get(
        `${url}/api/get/emplist/${displayName}/manager/${dbName}`
      );
      if (response.status === 200) {
        const activeUsers = response.data.filter(
          (user) => user.empStatus !== "inactive"
        );
        setUsers(activeUsers);
        setLoading(false);
      } else {
        console.log("Error:", response.status);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const fetchApproval = () => {
    const dbName = localStorage.getItem("dbName");
    fetch(
      `${url}/timesheet/manager/Submitted/${user.displayName}/manager/${dbName}`
    )
      .then((response) => response.json())
      .then((data) => setApproval(data))
      .catch((error) => console.error(error));
  };

  const options = {
    chart: {
      type: "bar",
      id: "basic-bar",
      stacked: true,
      toolbar: {
        show: false,
      },
    },

    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          position: "top",
        },
        colors: {
          ranges: [
            {
              from: 0,
              to: 1,
              color: "#b386eb",
            },
            {
              from: 1,
              to: 2,
              color: "#9f5df5",
            },
            {
              from: 3,
              to: 4,
              color: "#f783d8",
            },
            {
              from: 4,
              to: 6,
              color: "#86d4f0",
            },
            {
              from: 6,
              to: 8,
              color: "#5dd6f5",
            },
            {
              from: 8,
              to: 12,
              color: "#b386eb",
            },
            {
              from: 12,
              to: 20,
              color: "#b386eb",
            },
          ],
        },
        columnWidth: 35,
      },
    },
    dataLabels: {
      enabled: true,
      offsetX: -6,

      style: {
        fontSize: "12px",
        colors: ["#fff"],
      },
    },
    xaxis: {
      categories: projData?.map((proj) => proj.ProjectName),
      title: {
        text: "Project Name",
        offsetX: -10,
        offsetY: 0,
        style: {
          color: undefined,
          fontWeight: 600,
          fontSize: "12px",
        },
      },
      labels: {
        formatter: function(val) {
          return val;
        },
      },
      plotOptions: {
        column: {
          pointWidth: 2, // Adjust the width as needed
          // ...
        },
      },
    },
    yaxis: {
      title: {
        text: "No. of Employees",
        offsetX: 0,
        offsetY: 0,
        style: {
          color: undefined,
          fontWeight: 600,
          fontSize: "12px",
        },
      },
    },

    tooltip: {
      y: {
        formatter: function(val) {
          return val;
        },
      },
    },
  };

  const series = [
    {
      name: "No. of Employees",
      data: projData.map((proj) => proj.TotalEmp.length),
    },
  ];

  const [loading, setLoading] = useState(false);
  const GetHolidays = async () => {
    try {
      const dbName = localStorage.getItem("dbName");
      const response = await axios.get(`${url}/get/admin/services/${dbName}`);
      const res = await axios.get(`${url}/get/leave/${dbName}`);
  
      if (
        response.data[0]?.isActive === true ||
        response.data[0]?.isActive === false
      ) {
        const switchState = response.data[0]?.isActive;
        localStorage.setItem("switchState", switchState);
        console.log("Data retrieved and stored in local storage.");
      } else {
        console.error("No data received from the server.");
      }
  
      // Directly use officeHolidaysData from the response
      const holidays = res.data?.officeHolidaysData || [];
      console.log(holidays, "holidays");
  
      const formattedEvents = holidays.map((holiday) => {
        const [year, month, day] = holiday.date.split("-");
        const isoDate = `${year}-${month}-${day}`;
  
        return {
          title: holiday.title,
          date: isoDate,
          icon: (
            <Tooltip title={holiday.title}>
              <CalendarOutlined
                style={{
                  fontSize: "15px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
            </Tooltip>
          ),
        };
      });
      console.log(formattedEvents, "formattedEvents");
  
      setCalendarEvents(formattedEvents);
    } catch (error) {
      console.error(error);
    }
  };
  
  const EmployeeInitials = ({ employee, index, colors }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const toggleTooltip = () => {
      setShowTooltip(!showTooltip);
    };

    return (
      <div
        key={index}
        style={{
          position: "relative",
          display: "inline-block",
          margin: "0 5px",
        }}
        onMouseEnter={toggleTooltip}
        onMouseLeave={toggleTooltip}
      >
        <div
          style={{
            borderRadius: "50%",
            width: "30px",
            height: "30px",
            background: colors[index % colors.length],
            textAlign: "center",
            lineHeight: "30px",
            cursor: "pointer",
          }}
        >
          {employee.initials}
        </div>
        {showTooltip && (
          <div
            style={{
              position: "absolute",
              top: "100%",
              left: "50%",
              transform: "translateX(-50%)",
              padding: "5px",
              background: "#fff",
              border: "1px solid #ccc",
              borderRadius: "5px",
            }}
          >
            {employee.fullName}
          </div>
        )}
      </div>
    );
  };

  const renderEmployeeInitials = () => {
    if (selectedProject) {
      const initials = selectedProject.TotalEmp.map((employee) => {
        const [firstName, lastName] = employee.split(" ");
        return {
          initials: `${firstName[0]}${lastName[0]} `,
          fullName: employee,
        };
      });

      const colors = [
        "#ff726f",
        "#ffa649",
        "#ffd54f",
        "#64b5f6",
        "#81c784",
        "#ba68c8",
      ];

      return (
        <div>
          {initials.map((employee, index) => (
            <EmployeeInitials
              key={index}
              employee={employee}
              index={index}
              colors={colors}
            />
          ))}
        </div>
      );
    }
    return null;
  };
  useEffect(() => {
    fetchData3();
  }, []);

  const fetchData3 = async () => {
    try {
      const dbName = localStorage.getItem("dbName");
      const response = await axios.get(
        `${url}/api/projectsdetails/${user.displayName}/${dbName}`
      );
      setTableData(response.data);
      setData(response.data);
      console.log(response.data, "res");
    } catch (error) {
      console.error(error);
      message.error("Failed to fetch data from the server.");
    }
  };

  const fetchSprintsByProjectName = async (selectedproject) => {
    try {
      console.log("insidesprints");
      const dbName = localStorage.getItem("dbName");

      const response = await fetch(
        `${url}/sprints/${selectedproject?.ProjectName}/${dbName}`
      );

      const sprints = await response.json();
      const sprintDetails = sprints.map((sprint) => ({
        sprintId: sprint.sprintId,
        sprintName: sprint.sprintName,
      }));
      console.log(sprints, "sprints");
      setExistingSprints(sprintDetails);

      return sprintDetails;
    } catch (error) {
      console.error("Error:", error);
      throw new Error("An error occurred while fetching sprint details.");
    }
  };

  const handleSelectProject = async (projectId) => {
    const selectedProject = data.find((project) => project._id === projectId);
    setSelectedProject(selectedProject);
    await fetchSprintsByProjectName(selectedProject);
  };

  useEffect(() => {
    fetchactivities(selectedProject);
  }, [selectedProject]);

  const CustomHeader = ({ value, onChange }) => {
    const handlePrev = () => {
      const newValue = value.clone().subtract(1, "month");
      onChange(newValue);
    };

    const handleNext = () => {
      const newValue = value.clone().add(1, "month");
      onChange(newValue);
    };

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button
          type="link"
          onClick={handlePrev}
          icon={<LeftOutlined />}
        ></Button>
        <div>{value.format("MMMM YYYY")}</div>
        <Button
          type="link"
          onClick={handleNext}
          icon={<RightOutlined />}
        ></Button>
      </div>
    );
  };

  const fetchPDFList = async () => {
    try {
      const response = await fetch(`${url}/api/pdf/list`);
      if (response.ok) {
        const data = await response.json();
        setPdfFiles(data.files);
      } else {
        console.error("Failed to fetch PDF list");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // Set the first project as the default selected project
    if (data.length > 0) {
      setSelectedProject(data[0]);
    }
  }, [data]);

  const menu3 = (
    <Menu selectedKeys={[selectedProject]}>
      {data.map((project) => (
        <Menu.Item
          key={project._id}
          icon={<BuildOutlined />}
          onClick={() => handleSelectProject(project._id)}
        >
          {project?.ProjectName}
        </Menu.Item>
      ))}
    </Menu>
  );

  const fetchactivities = async (selectedproject) => {
    const dbName = localStorage.getItem("dbName");
    const featureId = selectedproject?.ProjectName;
    console.log(selectedproject, "featureid");
    try {
      console.log("calling getbyproject");
      const response = await axios.get(
        `${url}/getByProject/activity/${dbName}/${featureId}`
      );
      const allTasks = response.data;
      const completedTasks = allTasks.filter((task) => task.status === "done");
      const percentageCompleted =
        (completedTasks.length / allTasks.length) * 100;
      setTask(percentageCompleted);
      setTotalTask(allTasks);
      setDone(completedTasks);
      console.log(percentageCompleted, "activity");
    } catch (error) {
      console.error("Error fetching activities:", error.message);
    }
  };
  // const fetchTasks = async () => {
  //   console.log("inside task");
  //   console.log(featureId, "done");

  //   const dbName = localStorage.getItem("dbName");
  //   const featureId = selectedProject.ProjectName;
  //   try {
  //     const response = await axios.get(`${url}/getByProject/tasks/${dbName}/${featureId}`);
  //     // Filter tasks where status is "Done"
  //     // const doneTasks = response.data.filter(task => task.status == "Done");
  //     setTask(response.data);
  //     console.log("response", response.data);
  //   } catch (error) {
  //     console.error('Error fetching tasks:', error.message);
  //   }
  // };

  const daysOfWeek = ["S", "M", "T", "W", "T", "F", "S"]; // Days of the week
  const colors = [
    "#cfc5c4",
    "#a7b1eb",
    "#546bf0",
    "#a7b1eb",
    "#546bf0",
    "#a7b1eb",
    "#cfc5c4",
  ]; // Colors for each day
  return (
    <div
      style={{ transform: "scale(0.9)", transformOrigin: "0 0", width: "110%" }}
    >
      <h5 style={{ textAlign: "center" }}>Welcome {user.displayName}</h5>
      <Row
        align="center"
        justify="center"
        xs={24}
        sm={24}
        lg={24}
        style={{ display: "flex", gap: 20, padding: 10 }}
      >
        {locationCheck === "true" && <Timer user={user} />}
      </Row>
      {/* <Row gutter={[16, 16]} justify="space-around">
        <Col span={24}>
          <Card bordered={false}>
            <Row gutter={[16, 16]}>
              <Col span={4}>

                <h5>Status</h5>
                <p style={{ fontSize: "23px", fontWeight: "bold" }}>Approved</p>
              </Col>
              <Col span={11}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {daysOfWeek.map((day, index) => (
                    <div key={day} style={{ marginRight: '5px' }}>
                      <Row style={{ marginBottom: '10px' }}>
                        <Col span={24}>
                          <div style={{ textAlign: 'center', marginBottom: '5px' }}>{day}</div>
                        </Col>
                        <Col span={24}>
                          <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <div
                              style={{
                                width: '12vh',
                                height: '7vh',
                                backgroundColor: colors[index],
                                borderRadius: '30%',
                                border: '1px solid #ccc',
                              }}
                            ></div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ))}
                </div>
              </Col>
              <Col span={8}>
                <Select
                  defaultValue='This week'
                  style={{ background: 'white', minWidth: '120px', left: "35vh" }}
                >
                  <Select.Option value="1">This week</Select.Option>
                  <Select.Option value="2"> 2</Select.Option>
                  <Select.Option value="3"> 3</Select.Option>
                </Select>
                <div className="donut-chart">
                  <Chart options={chartData.options} series={chartData.series} type="donut" width="380" />
                  <div className="donut-center-text">{totalHours}hr</div>
                </div>
              </Col>
            </Row>
            <Row gutter={[16, 16]} style={{ bottom: "40vh" }}>
              <Col span={4}>
                <h5>Hours</h5>
                <p style={{ fontSize: "25px", fontWeight: "bold" }}>32/60</p>
              </Col>
              <Col span={14}>
                <BarChart
                  width={800}
                  height={400}
                  data={flattenedData}
                  margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="day" />
                  <YAxis dataKey="task" type="category" />
                  <Tooltip />
                  <Legend />
                  {tasks.map((task, index) => (
                    <Bar key={index} dataKey="hours" stackId="a" fill={`#${Math.floor(Math.random() * 16777215).toString(16)}`} />
                  ))}
                </BarChart>
              </Col>
              <Col span={4}>
                <h5>Projects</h5>
                <p style={{ fontSize: "25px", fontWeight: "bold" }}>3</p>
              </Col>

            </Row>
          </Card>
        </Col>
      </Row>
      <br /> */}
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} md={12} lg={12} xl={8} style={{ zIndex: 0 }}>
          <Card>
            <h5>Approvals</h5>
            <br />
            <Row
              gutter={[16, 16]}
              style={{ display: "flex", justifyContent: "space-around" }}
            >
              <div style={{ textAlign: "center" }}>
                <Link to="/home/claimapproval">
                  <Badge
                    color="error"
                    badgeContent={claims.length}
                    max={5}
                    style={{ marginBottom: "8px" }}
                  >
                    <img
                      src={require("../images/report.png")}
                      style={{
                        width: "9vh",
                        border: "2px solid #ccc",
                        padding: "5px",
                        boxShadow: "0 3px 3px #9a1f91",
                        borderRadius: "50%",
                      }}
                      alt="Claims Approval"
                    />
                  </Badge>
                  <p style={{ fontSize: "12px", margin: 0 }}>Claims</p>
                </Link>
              </div>
              <div style={{ textAlign: "center" }}>
                <Link to="/home/managerapproval">
                  <Badge
                    color="error"
                    badgeContent={EmployeeData.length}
                    max={5}
                    style={{ marginBottom: "8px" }}
                  >
                    <img
                      src={require("../images/logout.png")}
                      style={{
                        width: "9vh",
                        border: "2px solid #ccc",
                        padding: "5px",
                        boxShadow: "0 3px 3px #9a1f91",
                        borderRadius: "50%",
                      }}
                      alt="Leave Approval"
                    />
                  </Badge>
                  <p style={{ fontSize: "12px", margin: 0 }}>Leave</p>
                </Link>
              </div>
              <div style={{ textAlign: "center" }}>
                <Link to="/home/pending">
                  <Badge
                    color="error"
                    badgeContent={Approval.length}
                    max={5}
                    style={{ marginBottom: "8px" }}
                  >
                    <img
                      src={require("../images/timetable.png")}
                      style={{
                        width: "9vh",
                        border: "2px solid #ccc",
                        padding: "5px",
                        boxShadow: "0 3px 3px #9a1f91",
                        borderRadius: "50%",
                      }}
                      alt="Timesheet Approval"
                    />
                  </Badge>
                  <p style={{ fontSize: "12px", margin: 0 }}>Timesheet</p>
                </Link>
              </div>
              <div style={{ textAlign: "center" }}>
                <Link to="/home/compoff">
                  <Badge
                    color="error"
                    badgeContent={compoff.length}
                    max={5}
                    style={{ marginBottom: "8px" }}
                  >
                    <img
                      src={require("../images/late.png")}
                      style={{
                        width: "9vh",
                        border: "2px solid #ccc",
                        padding: "5px",
                        boxShadow: "0 3px 3px #9a1f91",
                        borderRadius: "50%",
                      }}
                      alt="Compoff Approval"
                    />
                  </Badge>
                  <p style={{ fontSize: "12px", margin: 0 }}>Compoff</p>
                </Link>
              </div>
            </Row>
          </Card>
          <p
            style={{ background: "linear-gradient(to right,#aa42c7,#5bc1de )" }}
          />
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={8}>
          <Card>
            <h5>Leave Details</h5>
            <br />
            <Row
              gutter={[16, 16]}
              style={{ display: "flex", justifyContent: "space-around" }}
            >
              <div style={{ textAlign: "center" }}>
                <Avatar
                  size={60}
                  style={{
                    background: "linear-gradient(to right,#b386eb,#5bc1de )",
                    color: "white",
                  }}
                >{`${balance1}`}</Avatar>
                <p style={{ fontSize: "12px", margin: 0 }}>Vacation</p>
              </div>
              <div style={{ textAlign: "center" }}>
                <Avatar
                  style={{
                    background: "linear-gradient(to right,#aa42c7,#5bc1de )",
                    color: "white",
                  }}
                  size={60}
                >{`${balance2}`}</Avatar>
                <p style={{ fontSize: "12px", margin: 0 }}>Casual</p>
              </div>
              <div style={{ textAlign: "center" }}>
                <Avatar
                  style={{
                    background: "linear-gradient(to right,#aa42c7,#5bc1de )",
                    color: "white",
                  }}
                  size={60}
                >{`${balance3}`}</Avatar>
                <p style={{ fontSize: "12px", margin: 0 }}>Compoff</p>
              </div>
            </Row>
          </Card>
          <p
            style={{ background: "linear-gradient(to right,#aa42c7,#5bc1de )" }}
          />
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={8}>
          <Card>
            <h5>Attendance Tracker</h5>
            {/* {checkedIn && (
              <div>
                <h4>{formatTime(new Date(elapsedTime * 1000))}</h4>
              </div>
            )} */}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <img
                src={require("../images/Attendance-Management-System-removebg-preview.png")}
                style={{ width: "34vh" }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Button
                  type="text"
                  style={{ width: "8vh", height: "8vh" }}
                  onClick={handleCheckIn}
                  disabled={disablecheckIn}
                >
                  <img
                    src={require("../images/play-button.png")}
                    style={{ width: "3vh", height: "3vh" }}
                  />
                </Button>
                <div>
                  {check ? (
                    <p style={{ fontSize: "8px" }}>{check[0]?.checkInTime}</p>
                  ) : (
                    checkInTime && (
                      <p style={{ fontSize: "8px" }}>
                        {formatDateTime(checkInTime)}
                      </p>
                    )
                  )}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Button
                  type="text"
                  style={{ left: "3px", width: "8vh", height: "8vh" }}
                  onClick={handleCheckOut}
                  disabled={disablecheckOut}
                >
                  <img
                    src={require("../images/stop.png")}
                    style={{ width: "3vh", height: "3vh" }}
                  />
                </Button>
                <div>
                  {console.log(check, "check")}
                  {check ? (
                    <p style={{ fontSize: "8px" }}>{check[0]?.checkOutTime}</p>
                  ) : (
                    checkOutTime && (
                      <p style={{ fontSize: "8px" }}>
                        {formatDateTime(checkOutTime)}
                      </p>
                    )
                  )}
                </div>
              </div>
            </div>
          </Card>
          <p
            style={{ background: "linear-gradient(to right,#aa42c7,#5bc1de )" }}
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={30} sm={24} lg={7} style={{ zIndex: 0 }}>
          <div className="calendar-container" style={{ zIndex: 0 }}>
            <Calendar
              className="details"
              fullscreen={false}
              headerRender={({ value, onChange }) => (
                <CustomHeader value={value} onChange={onChange} />
              )}
              dateCellRender={(date) => {
                const formattedDate = date.format("YYYY-MM-DD");
                const event = calendarEvents.find(
                  (event) => event.date === formattedDate
                );

                return event ? (
                  <Tooltip
                    title={<span>{event.title}</span>}
                    getPopupContainer={(triggerNode) =>
                      document.getElementById("tooltipContainer")
                    }
                    placement="top"
                    mouseEnterDelay={0.5}
                    mouseLeaveDelay={0.1}
                  >
                    <span style={{ color: "blue" }}>{event.icon}</span>
                  </Tooltip>
                ) : null;
              }}
              onPanelChange={handlePanelChange}
            />
          </div>
        </Col>
        <Col xs={30} sm={24} lg={7}>
          {/* <Card
            title="Project"
            extra={renderEmployeeInitials()}
            style={{ top: "2vh" }}
          >
            <Form.Item label="Project">
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Dropdown
                  overlay={menu3}
                  placement="bottomLeft"
                  getPopupContainer={(triggerNode) =>
                    document.getElementById("tooltipContainer")
                  }
                >
                  <Button type="link" icon={<BuildOutlined />}>
                    {selectedProject
                      ? selectedProject.ProjectName
                      : "Select Project"}
                    <DownOutlined />
                  </Button>
                </Dropdown>
              </div>
            </Form.Item>
            {/* <Col span={24}>
              <>
                <h6>Sprints</h6>
                <Divider />
                {existingSprints?.map((sprint, index) => (
                  <React.Fragment key={sprint.sprintId}>
                    <p
                      onClick={() => handleSprintSelection(sprint)}
                      style={{
                        backgroundColor: colors[index % colors.length],
                        padding: "8px",
                      }}
                    >
                      {index + 1}. {sprint.sprintName}
                    </p>
                    <Divider />
                  </React.Fragment>
                ))}
              </>
            </Col> 
            <br />
            <h6 style={{ textAlign: "center" }}>
              Task Done: {done.length}/{totalTask.length}
            </h6>
            <Progress
              type="circle"
              percent={task}
              style={{ display: "flex", justifyContent: "center" }}
            />
          </Card> */}
          <Card style={{ top: "2vh" }}>
            <h5>Reportees</h5>
            {loading ? (
              <div
                className="loaderhead"
                style={{
                  height: 170,
                }}
              >
                <div className="loader"></div>
              </div>
            ) : (
              <div
                id="scrollableDiv"
                style={{
                  height: height,
                  overflow: "auto",
                  padding: "0 20px",
                  margin: "10px",
                }}
              >
                <InfiniteScroll
                  style={{
                    flexWrap: "wrap",
                    display: "flex",
                    flexShrink: "initial",
                  }}
                  dataLength={Users.length}
                  hasMore={Users.length > 6}
                  scrollableTarget="scrollableDiv"
                >
                  <List
                    dataSource={Users}
                    renderItem={(item) => (
                      <List.Item>
                        <List.Item.Meta
                          avatar={<Avatar src={item.profileImage} />}
                          title={item.displayName}
                          description={
                            <span
                              style={{ fontWeight: "normal", color: "#1A1A2C" }}
                            >
                              {item.description}
                            </span>
                          }
                        />
                      </List.Item>
                    )}
                  />
                </InfiniteScroll>
              </div>
            )}
            <Link to="/home/employee">
              <Button
                style={{
                  flex: 2,
                  flexDirection: "row-reverse",
                }}
              >
                <IdcardOutlined /> View all
              </Button>
            </Link>
          </Card>
        </Col>
        <Col xs={30} sm={24} lg={10}>
          <Card style={{ top: "2vh", height: "59vh" }}>
            <h5>Project Deployment</h5>

            <ApexCharts options={options} series={series} type="bar" />
          </Card>
        </Col>
      </Row>
      {/* <Row gutter={[16, 16]} justify="space-around">
        <Col xs={24} sm={18} lg={8}>
          <Card
            headStyle={{
              display: "flex",
              background: colorData,
              color: "white",
              alignItems: "center",
              textAlign: "center",
            }}
            title="Vacation Leaves"

            style={{
              background: "#e8e8e8",
              textAlign: "center",
              boxShadow: "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
            }}
          >

            <h6><b>{`${balance1}`}</b></h6>
          </Card>
        </Col>
        <Col xs={24} sm={18} lg={8}>
          <Card
            headStyle={{
              background: colorData,
              color: "white",
              display: "flex",
              alignItems: "center",
              textAlign: "center",
            }}
            title="Casual Leaves"
            bordered={false}
            style={{
              background: "#e8e8e8",
              textAlign: "center",
              boxShadow: "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
            }}
          >


            <h6><b>{`${balance2}`}</b></h6>
          </Card>
        </Col>
        <Col xs={24} sm={18} lg={8}>
          <Card
            headStyle={{
              background: colorData,
              color: "white",
              display: "flex",
              alignItems: "center",
              textAlign: "center",
            }}
            title="Compoff Leaves"
            bordered={false}
            style={{
              background: "#e8e8e8",
              textAlign: "center",
              boxShadow: "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
            }}
          >

            <h6><b>{`${balance3}`}</b></h6>
          </Card>
        </Col>
      </Row > */}
      <br />
      <div style={{ display: "flex", justifyContent: "center" }}></div>
    </div>
  );
};

export default Dashboard2;
