import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Table,
  Button,
  Popconfirm,
  notification,
  Input,
  message,
  DatePicker,
  Select,
} from "antd";
import {
  EyeFilled,
  EditFilled,
  DeleteFilled,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { url } from "../../url/url";
import moment from "moment";

const SprintDirectory = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const projectName = queryParams.get("projectName");
  const [sprints, setSprints] = useState([]);
  const [editableSprint, setEditableSprint] = useState(null);
  const [count, setCount] = useState(0);
  useEffect(() => {
    fetchSprintsByProjectName();
  }, [count]);
  console.log(projectName, "projectName");
  const fetchSprintsByProjectName = async () => {
    try {
      console.log("insidesprints");
      const dbName = localStorage.getItem("dbName");

      const response = await fetch(`${url}/sprints/${projectName}/sprint/${dbName}`);

      const sprints = await response.json();
      const sprintDetails = sprints.map((sprint) => ({
        sprintId: sprint.sprintId,
        sprintName: sprint.sprintName,
        startDate: sprint.startDate,
        endDate: sprint.endDate,
        status: sprint.status,
      }));
      setSprints(sprintDetails);
      return sprintDetails;
    } catch (error) {
      console.error("Error:", error);
      throw new Error("An error occurred while fetching sprint details.");
    }
  };

  const columns = [
    {
      title: "Sprint ID",
      dataIndex: "sprintId",
      key: "sprintId",
    },
    {
      title: "Sprint Name",
      dataIndex: "sprintName",
      key: "sprintName",
      render: (text, record) => (
        <>
          {editableSprint?.sprintId === record.sprintId ? (
            <Input
              style={{
                height: "30px",
                width: "90px",
                fontSize: "15px",
                marginBottom: "0",
              }}
              value={editableSprint.sprintName}
              onChange={(e) =>
                setEditableSprint({
                  ...editableSprint,
                  sprintName: e.target.value,
                })
              }
            />
          ) : (
            text
          )}
        </>
      ),
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      render: (text, record) => (
        <span>
          {editableSprint?.sprintId === record.sprintId ? (
            <Input
              type="date"
              value={moment(editableSprint.startDate, "DD-MM-YYYY").format(
                "YYYY-MM-DD"
              )}
              onChange={(e) =>
                setEditableSprint({
                  ...editableSprint,
                  startDate: moment(e.target.value, "YYYY-MM-DD").format(
                    "DD-MM-YYYY"
                  ),
                })
              }
            />
          ) : (
            text
          )}
        </span>
      ),
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      render: (text, record) => (
        <span>
          {editableSprint?.sprintId === record.sprintId ? (
            <Input
              type="date"
              value={moment(editableSprint.endDate, "DD-MM-YYYY").format(
                "YYYY-MM-DD"
              )}
              onChange={(e) =>
                setEditableSprint({
                  ...editableSprint,
                  endDate: moment(e.target.value, "YYYY-MM-DD").format(
                    "DD-MM-YYYY"
                  ),
                })
              }
            />
          ) : (
            text
          )}
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <span>
          {editableSprint?.sprintId === record.sprintId ? (
            <Select
              value={editableSprint.status}
              onChange={(value) =>
                setEditableSprint({ ...editableSprint, status: value })
              }
            >
              <Select.Option value="todo">To Do</Select.Option>
              <Select.Option value="doing">Doing</Select.Option>
              <Select.Option value="done">Done</Select.Option>
            </Select>
          ) : (
            text
          )}
        </span>
      ),
    },

    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <span>
          {editableSprint?.sprintId === record.sprintId ? (
            <Button
              type="primary"
              icon={<EditFilled />}
              onClick={() => handleEdit(editableSprint)}
            >
              Save
            </Button>
          ) : (
            <>
              <Button
                icon={<EditFilled />}
                onClick={() => setEditableSprint({ ...record })}
              />
              <Popconfirm
                title="Are you sure to delete this sprint?"
                onConfirm={() => handleDelete(record.sprintId)}
                okText="Yes"
                cancelText="No"
              >
                <Button icon={<DeleteFilled />} />
              </Popconfirm>
            </>
          )}
        </span>
      ),
    },
  ];

  const handleEdit = async (record) => {
    try {
      // Prepare the updated sprint data
      const updatedSprintData = {
        sprintId: record.sprintId,
        sprintName: record.sprintName,
        startDate: record.startDate,
        endDate: record.endDate,
        status: record.status,
        // Add any other fields you want to update
      };
      const dbName = localStorage.getItem("dbName");
      console.log(new Date(),"before");
      // Call the backend API to update the sprint record
      const response = await fetch(
        `${url}/update-sprint/${record.sprintId}/${dbName}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedSprintData),
        }
      );

      if (!response.ok) {
        if (response.status === 400) {
          // Handle specific case where features are pending
          const data = await response.text();
          notification.error({ message: data });
        } else {
          throw new Error("Failed to update sprint record");
        }
      } else {
      console.log(new Date(),"after");
        notification.success({ message: "Sprint updated successfully" });
        setEditableSprint(null);
        setCount(count + 1);
      }
    } catch (error) {
      console.error("Error updating sprint:", error);
      notification.error({
        message: "An error occurred while updating the sprint.",
      });
    }
  };

  const handleDelete = async (sprintId) => {
    try {
      const dbName = localStorage.getItem("dbName");
      const response = await fetch(
        `${url}/delete-sprint/${dbName}/${sprintId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        // Remove the deleted sprint from the list
        const updatedSprints = sprints.filter(
          (sprint) => sprint.sprintId !== sprintId
        );
        setSprints(updatedSprints);
        notification.success({ message: "Sprint deleted successfully" });
      } else if (response.status === 400) {
        // Handle specific case where features are pending
        const data = await response.text();
        notification.error({ message: data });
      } else {
        throw new Error("Failed to delete sprint");
      }
    } catch (error) {
      console.error("Error deleting sprint:", error);
      message.error("Failed to delete sprint");
    }
  };

  const goBack = () => {
    navigate(-1, { state: { projectName } });
  };

  return (
    <div>
      <Button onClick={goBack}>Back</Button>
      <h4>Sprint Directory</h4>
      <span
        style={{ color: "blue", display: "flex", justifyContent: "center" }}
      >
        ProjectName: {projectName}
      </span>
      <br />
      <br />
      <Table
        columns={columns}
        dataSource={sprints}
        align="center"
        className="custom-table col-lg-12 col-md-12 col-sm-12"
        sticky
      />
    </div>
  );
};

export default SprintDirectory;
