import React, { useState, useEffect } from "react";
import { Table, Input, Spin } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { url } from "../../url/url";
import axios from "axios";
import "./hrreport.css";
import { decryptRole } from "../../encrypt";

const EmergencyContacts = () => {
  const [employees, setEmployees] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const pageSize = 9;
  const [loading, setLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    setLoading(true);
    fetchEmployeeData();
  }, []);

  const fetchEmployeeData = async () => {
    try {
      // Fetch user data or pass it as a prop

      const dbName = localStorage.getItem("dbName");
      const encryptedRoleFromStorage = localStorage.getItem("role");
      const jobTitle = decryptRole(encryptedRoleFromStorage);

      let response;
      if (jobTitle === "admin") {
        response = await axios.get(`${url}/api/get/allemplist/${dbName}`);
      } else if (jobTitle === "hr") {
        response = await axios.get(
          `${url}/api/get/emplist/${user.displayName}/hr/${dbName}`
        );
      }

      if (response.status === 200) {
        console.log("Employee list fetched:", response.data);
        const filteredEmployees = response.data.filter(
          (employee) => employee.empStatus !== "inactive"
        );
        setEmployees(filteredEmployees);
        setLoading(false);
      } else {
        console.error("Error:", response.status);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const EmpEmergencyDependentsColumns = [
    {
      title: "Employee ID",
      dataIndex: "empCode",
      key: "empCode",
      sorter: (a, b) => a.empCode.localeCompare(b.empCode),
    },
    {
      title: "Employee Name",
      dataIndex: "displayName",
      key: "displayName",
      render: (text, record) => `${record.firstName} ${record.lastName}`,
      sorter: (a, b) => a.displayName.localeCompare(b.displayName),
    },
    {
      title: "Manager",
      dataIndex: "manager",
      key: "manager",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Emergency Contact",
      dataIndex: "emergencyContact",
      key: "emergencyContact",
    },
    {
      title: "Blood Group",
      dataIndex: "bloodGroup",
      key: "bloodGroup",
      width:100
    },

    {
      title: "Dependents",
      dataIndex: "empDependents",
      key: "empDependents",
      render: (text, record) => (
        <ul>
          {Array.isArray(record.empDependents) &&
            record.empDependents.length > 0
            ? record.empDependents.map((empDependent, index) => (
              <li key={index}>{empDependent}</li>
            ))
            : null}
        </ul>
      ),
    },
  ];
  return (
    <div style={{ transform: "scale(0.9)", transformOrigin: "0 0", width: "110%" }}>
      <h1 style={{ textAlign: "center" }}>Emergency Contacts</h1>

      {/* <div>
                <Input
                    type="text"
                    size="small"
                    style={{ height: "40px", fontSize: "15px" }}
                    placeholder="Search Employees..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
            </div> */}

      {loading ? (
        // Render a loading indicator while data is being fetched
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <Table
          dataSource={employees
            .map((employee) => ({
              ...employee,
              firstName: employee.firstName.toLowerCase(),
            }))
            .filter((employee) =>
              employee.firstName.includes(searchQuery.toLowerCase())
            )}
          columns={EmpEmergencyDependentsColumns}
          size="middle"
          sticky
          scroll={{ x: "max-content" }}
          pagination={{ pageSize: pageSize }}
          className="custom-table col-lg-12 col-md-12 col-sm-12"
        />
      )}
    </div>
  );
};

export default EmergencyContacts;
