import React, { useState, useEffect, useRef } from "react";
import { Form, Input, Button, Select, Row, Col, notification } from "antd";
import axios from "axios";
import { url } from "../../url/url";
const { Option } = Select;
const MasterOrgForm = ({ currentOrg, onFormSubmitSuccess }) => {
  const formRef = useRef(null);
  const [formData, setFormData] = useState({
    orgName: "",
    tenantId: "",
    clientId: "",
    clientSecret: "",
    domain: "",
    userType: "",
    dbName: "",
    color: "",
    type: "",
    companyaddress: "",
    companyemail: "",
    phoneNumber: "",
    BillingType: "",
    UsersAllowed: "",
    status: currentOrg ? currentOrg.status : "enable",
    sellingPricePerLicense: "",
    period: "Monthly",
    currency: "",
    rate: 0,
    onboardDate: "",
    mailId: "",
    commissionPercentage: "",
  });
  const [databases, setDatabases] = useState([]);
  const [billingTypes, setBillingTypes] = useState([]);
  const currencies = ["USD", "EUR", "GBP", "INR", "AUD", "CAD"];
  const users = JSON.parse(localStorage.getItem("user"));

  const calculateRate = () => {
    const { commissionPercentage, sellingPricePerLicense, UsersAllowed } =
      formData;
    const rate =
      (parseFloat(commissionPercentage) / 100) *
      parseFloat(sellingPricePerLicense);
    setFormData((prevData) => ({
      ...prevData,
      rate,
    }));
  };

  useEffect(() => {
    calculateRate();
  }, [formData.commissionPercentage, formData.sellingPricePerLicense]);

  const handleSelectChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      let formattedDbName;
      const currentFormValues = formRef.current.getFieldsValue();
      if (!currentOrg) {
        if (formData.type !== "channel") {
          const dbPayload = { dbName: currentFormValues.orgName };
          const dbCreationResponse = await axios.post(
            `${url}/db/post/createdb`,
            dbPayload
          );
          if (dbCreationResponse.status === 200) {
            formattedDbName = dbCreationResponse.data.formattedDbName;
          } else {
            throw new Error("Database creation failed");
          }
        }
      }
      const res = await axios.get(
        `${url}/api/billing-types/${formData.BillingType}`
      );
      const billingPlanID = res.data[0].billingPlanID;

      const role = users.jobTitle;

      let channel;
      if (formData.type === "channel") {
        channel = "Partner";
      } else {
        channel = "Direct";
      }

      const channelName = users.firstName;

      console.log("chanel and channel name", channel, channelName);

      const orgPayload = {
        ...formData,
        dbName: formattedDbName || formData.dbName,
        BillingType: formData.BillingType,
        orgName: currentFormValues.orgName,
        userType: formData.userType,
        billingPlanID: billingPlanID,
        channel: channel,
        channelName: channelName,
      };
      const endpoint = currentOrg
        ? `${url}/api/master-orgs/${currentOrg._id}`
        : `${url}/api/master-orgs`;
      const method = currentOrg ? "put" : "post";
      const response = await axios[method](endpoint, orgPayload);
      if (!currentOrg) {
        if (formData.type === "organisation") {
          notification.success({
            message: "Database Created Successfully",
            description: `Database for ${formData.orgName} created successfully.`,
          });
        }
      }
      notification.success({
        message: `Organization ${
          currentOrg ? "Updated" : "Added"
        } Successfully`,
        description: `The organization ${formData.orgName} has been ${
          currentOrg ? "updated" : "added"
        } successfully.`,
      });
      if (onFormSubmitSuccess) {
        onFormSubmitSuccess();
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      notification.error({
        message: "Error",
        description: "There was an error submitting the form.",
      });
    }
  };
  useEffect(() => {
    if (currentOrg) {
      setFormData(currentOrg);
      formRef.current.setFieldsValue(currentOrg);
      console.log(currentOrg, "currentorg1");
    } else {
      console.log("!currentorg");
      setFormData({
        orgName: "",
        tenantId: "",
        clientId: "",
        clientSecret: "",
        domain: "",
        dbName: "",
        color: "#00134d",
        companyaddress: "",
        companyemail: "",
        phoneNumber: "",
        BillingType: "",
        UsersAllowed: "10",
        status: "",
        userType: "",
        sellingPricePerLicense: "",
        period: "",
        currency: "",
        rate: 0,
        onboardDate: "",
        mailId: "",
        commissionPercentage: "",
      });
      formRef.current.resetFields();
    }
  }, [currentOrg]);

  // Define a separate function to handle status change
  const handleStatusChange = (value) => {
    setFormData({ ...formData, status: value });
  };
  const handleUserType = (value) => {
    setFormData({ ...formData, userType: value });
  };
  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  //   const handleInputChange = (e) => {
  //     const { name, value } = e.target;
  //     setFormData(prevState => {
  //         const updatedFormData = { ...prevState, [name]: value };
  //         // Update form values as well
  //         formRef.current.setFieldsValue(updatedFormData);
  //         return updatedFormData;
  //     });
  // };

  const fetchDatabases = async () => {
    try {
      const response = await axios.get(`${url}/db/list-details`);
      const { data } = response;
      // console.log('API Response:', response.data);
      if (data) {
        setDatabases(data);
      }
    } catch (error) {
      console.error("Error fetching databases:", error);
    }
  };
  useEffect(() => {
    fetchDatabases();
  }, []);
  useEffect(() => {
    const fetchBillingTypes = async () => {
      try {
        const response = await axios.get(`${url}/api/billing-types`);
        setBillingTypes(response.data);
      } catch (error) {
        console.error("Error fetching billing types:", error);
      }
    };

    fetchBillingTypes();
  }, []);

  const handleDatabaseChange = (value) => {
    setFormData({ ...formData, dbName: value });
  };
  const handleTypeChange = (value) => {
    setFormData({ ...formData, type: value });
  };
  const handleBillingTypeChange = (billingPlanID) => {
    setFormData({ ...formData, BillingType: billingPlanID });
  };
  return (
    <Form
      ref={formRef}
      onFinish={handleSubmit}
      layout="vertical"
      style={{ maxWidth: "600px", margin: "auto", zoom: "90%" }}
    >
      {users.jobTitle === "superAdmin" && (
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Type"
              name="type"
              rules={[
                {
                  required: true,
                  message: "Please Select the type.",
                },
              ]}
            >
              <Select
                name="type"
                value={formData.type}
                onChange={handleTypeChange}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              >
                <Option value="organisation">Organisation</Option>
                <Option value="channel">Channel</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Organization Name"
            name="orgName"
            rules={[
              {
                required: true,
                message: "Please enter the organisation name",
              },
              {
                pattern: /^[a-zA-Z\s]+$/,
                message:
                  "Organisation name should not contain special characters and numbers!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Domain">
            <Input
              name="domain"
              value={formData.domain}
              onChange={handleInputChange}
            />
          </Form.Item>
        </Col>
      </Row>
      {/* Repeat for other fields in similar Row/Col structure */}
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="BillingType"
            label="Billing Type"
            rules={[
              { required: true, message: "Please select a billing type!" },
            ]}
          >
            <Select
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              onChange={handleBillingTypeChange}
              value={formData.BillingType}
              showSearch
              placeholder="Select a billing type"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {billingTypes.map((type) => (
                <Select.Option key={type._id} value={type.billingPlanname}>
                  {type.billingPlanname}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {/* {currentOrg && (
          <Col span={12}>
            <Form.Item label="Remount Database">
              <Select
                name="dbName"
                value={formData.dbName}
                onChange={handleDatabaseChange}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              >
                {databases.map((db) => (
                  <Select.Option key={db.dbName} value={db.dbName}>
                    {db.dbName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )} */}
        {!currentOrg && (
          <Col span={12}>
            <Form.Item label="One-time Fee">
              <Input
                name="onetimefee"
                value={formData.onetimefee}
                onChange={handleInputChange}
              />
            </Form.Item>
          </Col>
        )}
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="userType"
            label="UserType"
            rules={[{ required: true, message: "Please select UserType!" }]}
          >
            <Select
              placeholder="Select your UserType"
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              onChange={handleUserType}
            >
              <Option value="true">Azure AD User</Option>
              <Option value="false">Native User</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Status">
            <Select
              name="status"
              value={formData.status}
              onChange={handleStatusChange}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            >
              <Option value="enable">Enable</Option>
              <Option value="disable">Disable</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Currency">
            <Select
              name="currency"
              value={formData.currency}
              onChange={(value) => handleSelectChange("currency", value)}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            >
              {currencies.map((currency) => (
                <Option key={currency} value={currency}>
                  {currency}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Selling Price/License">
            <Input
              name="sellingPricePerLicense"
              value={formData.sellingPricePerLicense}
              onChange={handleInputChange}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Period">
            <Select
              name="period"
              value={formData.period}
              onChange={(value) =>
                handleInputChange({ target: { name: "period", value } })
              }
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            >
              <Option value="monthly">Monthly</Option>
              <Option value="quarterly">Quarterly</Option>
              <Option value="halfyearly">Half-Yearly</Option>
              <Option value="annual">Annually</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="On-Board Date">
            <Input
              name="onboardDate"
              type="date"
              value={formData.onboardDate}
              onChange={handleInputChange}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        {users.jobTitle === "superAdmin" && formData.type === "channel" && (
          <Col span={12}>
            <Form.Item label="Commission Percentage">
              <Input
                type="number"
                name="commissionPercentage"
                value={formData.commissionPercentage}
                onChange={(e) =>
                  handleSelectChange("commissionPercentage", e.target.value)
                }
                min={0}
                max={100}
                addonAfter="%"
              />
            </Form.Item>
          </Col>
        )}{" "}
        <Col span={12}>
          <Form.Item label="Purchased Licenses">
            <Input
              name="UsersAllowed"
              value={formData.UsersAllowed}
              onChange={handleInputChange}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Billing Address">
            <Input
              name="billingaddress"
              value={formData.billingaddress}
              onChange={handleInputChange}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label="Company Address">
            <Input
              name="companyaddress"
              value={formData.companyaddress}
              onChange={handleInputChange}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Contact email">
            <Input
              name="companyemail"
              value={formData.companyemail}
              onChange={handleInputChange}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="channel Email">
            <Input
              name="mailId"
              value={formData.mailId}
              onChange={handleInputChange}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Contact Number">
            <Input
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleInputChange}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Client ID">
            <Input
              name="clientId"
              value={formData.clientId}
              onChange={handleInputChange}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Client Secret">
            <Input
              name="clientSecret"
              value={formData.clientSecret}
              onChange={handleInputChange}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Tenant ID">
            <Input
              name="tenantId"
              value={formData.tenantId}
              onChange={handleInputChange}
            />
          </Form.Item>
        </Col>
      </Row>

      {/* Add more form fields here */}

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{ width: "100%" }}
          disabled={formData.channelName !== users.firstName && currentOrg}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default MasterOrgForm;
