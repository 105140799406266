import axios from "axios";
import React, { useState, useEffect } from "react";
import { Tabs, Table, Image, Button, Modal, Select, Input, Spin } from "antd";
import {
  CheckCircleFilled,
  CloseCircleOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import { url } from "../../url/url";
import Papa from "papaparse";
import FileSaver from "file-saver";
import { decryptRole } from "../../encrypt";

const { Option } = Select;
const { Search } = Input;
const { TabPane } = Tabs;

function ManagerStatus(props) {
  const [EmployeeData, setEmployeeData] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const [visible, setVisible] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [Columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [pdfModalContent, setPdfModalContent] = useState([]);
  const [pdfModalVisible, setPdfModalVisible] = useState(false);
  const [selectedPdfIndex, setSelectedPdfIndex] = useState(0);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10); // Set an initial page size
  const [total, setTotal] = useState(EmployeeData.length); // Set the total based on your data
  const [loading, setLoading] = useState(false);
  const [selectedYear, setSelectedYear] = useState("");
  const currentDate = new Date();
  const currentyear = currentDate.getFullYear();
  const [year, setYear] = useState(currentyear.toString());


  // Handle page change
  const onPageChange = (newCurrent, newPageSize) => {
    setCurrent(newCurrent);
    setPageSize(newPageSize);
    // You may need to update the "total" based on your data or an API call here
  };
  const shouldUpdate = (prevProps, nextProps) => {
    if (
      prevProps.dataSource === nextProps.dataSource &&
      prevProps.count === nextProps.count
    ) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    fetchData();
  }, [year]);

  const fetchData = async () => {
    setLoading(true);
    const dbName = localStorage.getItem("dbName");
    const encryptedRoleFromStorage = localStorage.getItem("role");
    const jobTitle = decryptRole(encryptedRoleFromStorage);

    try {
      if (jobTitle === "hr" || jobTitle === "admin") {
        console.log("jobtitle is HR");
        const response = await axios.get(`${url}/claim/getallstatus/${year}/${dbName}`);
        if (response.status === 200) {
          console.log("User list fetched:", response.data);
          setEmployeeData(response.data);
          setData(response.data);
          setLoading(false);
        } else {
          setLoading(false);
          console.log("Error:", response.status);
        }
      } else {
        const res = await axios.get(
          `${url}/claim/managerstatus/${user.displayName}/${year}/${dbName}`
        );
        if (res.status === 200) {
          console.log("User list fetched:", res.data);
          setEmployeeData(res.data);
          setData(res.data);
          setLoading(false);
        } else {
          console.log("Error:", res.status);
          setLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  function handleOk() {
    const EmployeeData = data.map((row) =>
      selectedColumns.reduce((acc, col) => {
        acc[col] = row[col];
        return acc;
      }, {})
    );
    const csv = Papa.unparse(EmployeeData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    FileSaver.saveAs(blob, "Claims_Details.csv", "C:");
    setVisible(false);
    setColumns(Object.keys(data[0]));
  }

  function handleClick() {
    setVisible(true);
    setColumns(Object.keys(data[0]));
  }
  const handleYearChange = (value) => {
    setYear(value);
  };
  const currentYear = new Date().getFullYear();
  const yearsRange = Array.from(
    { length: 3 },
    (_, index) => currentYear - 1 + index
  );

  function handleCancel() {
    setVisible(false);
    setSelectedColumns([]);
  }
  function handleColumnSelect(value) {
    setSelectedColumns(value);
  }

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredData = EmployeeData.filter((item) =>
    item.empName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  function openPdfInModal(pdfDataUrls) {
    setPdfModalContent(pdfDataUrls);
    setPdfModalVisible(true);
  }

  function closePdfModal() {
    setPdfModalVisible(false);
    setSelectedPdfIndex(0);
  }

  function viewNextPdf() {
    if (selectedPdfIndex < pdfModalContent.length - 1) {
      setSelectedPdfIndex(selectedPdfIndex + 1);
    }
  }

  function viewPreviousPdf() {
    if (selectedPdfIndex > 0) {
      setSelectedPdfIndex(selectedPdfIndex - 1);
    }
  }

  function downloadPdf(pdfDataUrl) {
    const link = document.createElement("a");
    link.href = pdfDataUrl;
    link.download = "Claims Receipt.pdf"; // Specify the desired file name
    link.click();
  }
  //

  const columns = [
    {
      title: "S.NO",
      dataIndex: "globalSerialNumber",
      key: "index",
      width:100,
      render: (text, record, index) => {
        const currentSerialNumber = index + 1 + pageSize * (current - 1);
        return <span>{currentSerialNumber}</span>;
      },
    },
    {
      title: "Name",
      dataIndex: "empName",
      key: "empName",
    },
    {
      title: "Request Date",
      dataIndex: "dateOfApply",
      key: "dateOfApply",
    },
    {
      title: "Expense Date",
      dataIndex: "dateOfExpense",
      key: "dateOfExpense",
    },
    {
      title: "Description",
      dataIndex: "expenseDescription",
      key: "expenseDescription",
    },
    {
      title: "Type",
      dataIndex: "claimType",
      key: "claimType",
    },
    {
      title: "Receipt",
      render: (_, record) => (
        <div>
          {record.images && record.images.length > 0 ? (
            <Button onClick={() => openPdfInModal(record.images)}>
              View Receipts
            </Button>
          ) : (
            <>No files</>
          )}
        </div>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      width:100
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Action",
      dataIndex: "id",
      width:100,
      render: (_, record) => (
        <>
          {record.status === "submitted" ||
          record.status === "Submitted to HR" ? (
            <ClockCircleOutlined style={{ color: "blue" }} />
          ) : record.status === "Denied by Manager" ||
            record.status === "Denied by HR" ? (
            <CloseCircleOutlined style={{ color: "red" }} />
          ) : record.status === "Approved" ? (
            <CheckCircleFilled style={{ color: "Green" }} />
          ) : null}
        </>
      ),
    },
  ];

  function filterColumns() {
    // List of fields you want to exclude
    const excludedFields = ["_id", "image", "__v"]; // Add the field names you want to exclude

    // Filter the columns to include only those not in the excludedFields list
    const columnsToInclude = Columns.filter(
      (col) => !excludedFields.includes(col)
    );

    return columnsToInclude;
  }

  return (
    <div style={{transform: "scale(0.9)", transformOrigin: "0 0",width:"110%"}}>
      {loading ? (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <Tabs defaultActiveKey="1" centered>
            <TabPane
              tab={
                <span style={{ color: "black", fontSize: "20px" }}>
                  Reportee Expense Summary
                </span>
              }
            >
              <div
                className="tag"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "20px",
                }}
              >
                <Button className="button" type="primary" onClick={handleClick}>
                  Export to CSV
                </Button>
                <Select
                  placeholder="Select Year"
                  onChange={handleYearChange}
                  style={{ width: 130, height: 35 }}
                  value={selectedYear || undefined}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                >
                  {yearsRange.map((year) => (
                    <Select.Option key={year} value={year}>
                      {year}
                    </Select.Option>
                  ))}
                </Select>
                <Modal
                  title="Export to CSV"
                  visible={visible}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  okButtonProps={{ disabled: selectedColumns.length === 0 }}
                >
                  <p>Select columns to export:</p>
                  <Select
                    mode="multiple"
                    style={{ width: "100%" }}
                    placeholder="Please select"
                    value={selectedColumns}
                    onChange={handleColumnSelect}
                  >
                    {filterColumns().map((col) => (
                      <Option key={col} value={col}>
                        {col}
                      </Option>
                    ))}
                  </Select>
                </Modal>
                <Modal
                  title="Documents"
                  visible={pdfModalVisible}
                  onCancel={closePdfModal}
                  footer={null}
                  width={1000}
                >
                  {pdfModalContent.length > 0 ? (
                    <>
                      <div style={{ textAlign: "center" }}>
                        <Button
                          disabled={selectedPdfIndex === 0}
                          onClick={viewPreviousPdf}
                          style={{ marginRight: "10px" }}
                        >
                          Previous
                        </Button>
                        <span>
                          Document {selectedPdfIndex + 1} of{" "}
                          {pdfModalContent.length}
                        </span>
                        <Button
                          disabled={
                            selectedPdfIndex === pdfModalContent.length - 1
                          }
                          onClick={viewNextPdf}
                          style={{ marginLeft: "10px" }}
                        >
                          Next
                        </Button>
                      </div>
                      <iframe
                        src={pdfModalContent[selectedPdfIndex]}
                        title="Document"
                        style={{ width: "100%", height: "500px" }}
                      />
                    </>
                  ) : (
                    <>No PDF documents available</>
                  )}
                </Modal>
                <div>
                  {" "}
                  <Input
                    type="text"
                    size="large"
                    style={{ height: "40px", fontSize: "15px" }}
                    placeholder="Search Employees..."
                    value={searchQuery}
                    onChange={handleSearchQueryChange}
                  />
                </div>
              </div>
              <Table
                className="custom-table col-lg-12 col-md-12 col-sm-12"
                dataSource={filteredData}
                columns={columns}
                pagination={{
                  current: current,
                  pageSize: pageSize,
                  total: total,
                  onChange: onPageChange,
                }}
                sticky
                bordered={false}
                scroll={{ x: true }}
                rowKey={(record) => record.id}
              />
            </TabPane>
          </Tabs>
        </div>
      )}
    </div>
  );
}

export default ManagerStatus;
