// PrivateRoute.js
import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const accessToken = localStorage.getItem("accessToken");

  return accessToken ? (
    children
  ) : (
    <Navigate to="/bgv/:token1/:name" state={{ from: window.location.pathname }} replace />
  );
};

export default PrivateRoute;
