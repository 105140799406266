/*Apply leave - home screen */
import React, { useState, useEffect } from "react";
import { Layout, Card, Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import { UserOutlined, CalendarOutlined} from '@ant-design/icons';
import '../../HR_home/hrhome.css';
import EmpLeaveReport  from './EmpLeaveForm';
const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };

const EmployeeManagementLeaveTile = (props) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const tileData = [
        { title: 'Apply Leave', link: '/home/empleaveapplyform', icon: <UserOutlined /> },
        { title: 'Raise Comp-off Request', link: '/home/empleavebalrequest', icon: <CalendarOutlined /> },
    ];
    const colorData = localStorage.getItem("color");
 
    
    return (
        <Layout >
        <div className="site-card-wrapper" style={{zoom:"90%"}}>
            <Row gutter={[16, 16]}>
                {tileData.map((tile, index) => (
                    <Col xs={24} sm={12} md={8} lg={6} key={index}>
                        <Card
                            className="tile-card"
                            title={tile.icon}
                            headStyle={{
                                background: colorData,
                                color: 'white',
                                textAlign: 'center',
                                display: 'flex',
                            }}
                            style={{
                                background: 'white',
             
                            }}
                        >
                            <Link className="tile-link" to={tile.link} style={{ color: colorData }}>
                                {tile.title}
                            </Link>
                        </Card>
                    </Col>
                ))}
            </Row>
        </div>
        <div>
        <EmpLeaveReport data={user}/>
        </div>
        </Layout>
    );
};



export default EmployeeManagementLeaveTile;
