import React, { useEffect, useState } from "react";

import {
  Table,
  Select,
  Button,
  Input,
  Checkbox,
  message,
  notification,
  Form,
  Modal,
  Radio,
  Drawer,
} from "antd";
import {
  UsergroupAddOutlined,
  AppstoreAddOutlined,
  HourglassOutlined,
  BankOutlined,
  AccountBookOutlined,
  ContactsOutlined,
  CarryOutOutlined,
  ReconciliationOutlined,
  ScheduleOutlined,
  SolutionOutlined,
  FieldTimeOutlined,
  WarningOutlined,
  FileDoneOutlined,
  ShopOutlined,
  FileProtectOutlined,
  DashboardOutlined,
  EnvironmentOutlined,
  DatabaseOutlined,

  EditFilled,
  PlusOutlined,
  DownCircleFilled,
  SaveOutlined,
  DownCircleOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { url } from "../../url/url";
import { DeleteFilled } from "@fluentui/react-icons";

const { Option } = Select;

const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18,
  },
};

const UserMenuTable = () => {
  const [menuData, setMenuData] = useState([]);
  const [openDropdowns, setOpenDropdowns] = useState({});
  const [openDropdowns1, setOpenDropdowns1] = useState({});
  const [editingRecord, setEditingRecord] = useState(null);
  const [billing, setBilling] = useState([]);
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const initialRoles = {
    superAdmin: "Deny",
    channelPartner: "Deny",
    admin: "Deny",
    hr: "Deny",
    manager: "Deny",
    employee: "Deny",
  };
  const [formData, setFormData] = useState({
    key: "",
    header: "",
    icon: "",
    element: "",
    menuItem: "",
    componentPath: "",
    access: {
      Basic: { ...initialRoles },
      BasicPlusHR: { ...initialRoles },
      Demo: { ...initialRoles },
      Premium: { ...initialRoles },
      PremiumPlusHR: { ...initialRoles }
    },
  });


  useEffect(() => {
    // Initialize the form data when editingRecord changes
    if (editingRecord) {
      setFormData
        ({
          ...editingRecord,
          access
            : editingRecord.access || {},
        });
    }
  }, [editingRecord]);
  // const handleInputChange = (event) => {
  //   const { name, value } = event.target;
  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //   });
  // };

  const handleEdit = (record) => {
    setEditingRecord(record);
    console.log(record, "insidehandleedit");
    form.setFieldsValue({
      ...record,
    });
  };
  const onCancel = () => {
    setEditingRecord(null);
  };
  const shouldUpdate = (prevProps, nextProps) => {
    if (
      prevProps.dataSource === nextProps.dataSource &&
      prevProps.count === nextProps.count
    ) {
      return false;
    }
    return true;
  };
  const handleDBUpdate = async (values) => {
    try {
      console.log(values, "handledb");
      const updatedFormData = {
        header: editingRecord.header || "",
        icon: editingRecord.icon || "",
        element: editingRecord.element || "",
        menuItem: editingRecord.menuItem || "",
        componentPath: editingRecord.componentPath || "",
        access: {
          Basic: editingRecord.access.Basic,
          BasicPlusHR: editingRecord.access.BasicPlusHR,
          Demo: editingRecord.access.Demo,
          Premium: editingRecord.access.Premium,
          PremiumPlusHR: editingRecord.access.PremiumPlusHR,
        },
      };


      const response = await axios.put(
        `${url}/api/updatemenu/${editingRecord.key}/`,
        { ...updatedFormData, ...values }
      );
      setMenuData(
        menuData.map((record) =>
          record.key === editingRecord.key
            ? { ...record, ...response.data }
            : record
        )
      );
      setEditingRecord(null);
      notification.success({ message: "Record updated in DB successfully!" });
    } catch (error) {
      console.error(error);
      message.error("Failed to update DB  record.");
    }
  };
  const [count, setCount] = useState([]);

  // const postMenuData = async (menuData) => {

  //     try {
  //       // Create a deep copy of menuData to remove circular references
  //       const sanitizedMenuData = sanitizeData(menuData);

  //       const response = await axios.post(`${url}/api/menus`, sanitizedMenuData);
  //       console.log('Menu added successfully:', response.data);
  //       return response.data;
  //     } catch (error) {
  //       console.error('Error adding the menu:', error);
  //       throw error;
  //     }
  //   };

  //   const sanitizeData = (data) => {
  //     // Use _.cloneDeep to create a deep copy of the data
  //     return _.cloneDeep(data);
  //   };

  useEffect(() => {
    // Make a GET request to your API endpoint
    axios
      .get(`${url}/get/menusider`)
      .then((response) => {
        // Update the menuItems state with the retrieved data
        setMenuData(response.data);
        console.log(response.data, "data");
      })
      .catch((error) => {
        console.error("Error fetching menu items:", error);
      });
  }, [editingRecord]);
  console.log(formData.access.Basic, "basiii");
  const postMenuData = async () => {
    try {

      const updatedFormData = {
        key: formData.key || "", // This will be set by the backend
        header: formData.header || "",
        icon: formData.icon || "",
        element: formData.element || "",
        menuItem: formData.menuItem || "",
        componentPath: formData.componentPath || "",
        access: {
          Basic: formData.access.Basic,
          BasicPlusHR: formData.access.BasicPlusHR,
          Demo: formData.access.Demo,
          Premium: formData.access.Premium,
          PremiumPlusHR: formData.access.PremiumPlusHR,
        },
      };

      const response = await axios.post(
        `${url}/api/menus`,
        updatedFormData
      );

      console.log(response.data);
      if (response.data) {
        notification.success({ message: "Record Added successfully!" });
        form.resetFields();
        setFormData({
          key: "",
          header: "",
          icon: "",
          element: "",
          menuItem: "",
          componentPath: "",
          access: {
            Basic: { ...initialRoles },
            BasicPlusHR: { ...initialRoles },
            Demo: { ...initialRoles },
            Premium: { ...initialRoles },
            PremiumPlusHR: { ...initialRoles },
          },
        });
        setCount(count + 1);
      }
    } catch (error) {
      message.error("Failed to add Record!");
      console.error("Error creating menu item:", error);
    }
  };




  const deleteMenuItem = async (record) => {
    try {
      const response = await axios.delete(`${url}/remove/menus/${record._id}`);

      setMenuData(menuData.filter((data) => data.id !== record._id));
      notification.success({ message: "Record deleted successfully!" });
      setCount(count + 1);
    } catch (error) {
      console.error(error);
      message.error("Failed to delete the record.");
    }
  };
  const unique = Array.from(new Set(menuData.map((user) => user.BillingType)));

  const columns = [
    {
      title: "Header",
      dataIndex: "header",
      width: 200
    },
    {
      title: "Menu Item",
      dataIndex: "menuItem",
      width: 200
    },
    {
      title: "Component Path",
      dataIndex: "componentPath",
      width: 200
    },
    {
      title: "Elements",
      dataIndex: "element",
      width: 200
    },

    {
      title: "Edit",
      dataIndex: "id",
      render: (_, record) => (
        <Button onClick={() => handleEdit(record)}>
          <EditFilled />
        </Button>
      ),
    },
    {
      title: "Delete",
      dataIndex: "id",
      render: (_, record) => (
        <Button onClick={() => deleteMenuItem(record)}>
          <DeleteFilled />
        </Button>
      ),
    },
  ];
  const ExpandableBasicColumns = [
    {
      title: <span style={{ color: "#964b00" }}>Plan</span>,
      dataIndex: "planType",
      key: "planType",
      width: 200

    },
    { title: "Admin", dataIndex: "admin", width: 200 },
    { title: "Super Admin", dataIndex: "superAdmin", width: 200 },
    { title: "Channel Partner", dataIndex: "channelPartner", width: 300 },
    { title: "Manager", dataIndex: "manager", width: 200 },
    { title: "Hr", dataIndex: "hr", width: 200 },
    { title: "Employee", dataIndex: "employee", width: 200 },

  ]


  const getExpandedBasic = (record) => {
    // Define the plan types as expected in the data
    const accessLevels = ["Basic", "BasicPlusHR", "Demo", "Premium", "PremiumPlusHR"];

    // Log the entire record to verify its structure
    console.log('Record:', record);

    return accessLevels.map((level) => {
      // Safely access each level's data
      const accessData = record.access && record.access[level] ? record.access[level] : {};

      // Log access data for debugging
      console.log(`Access Data for ${level}:`, accessData);

      return {
        key: level, // Use level name as the key
        planType: level,
        admin: accessData.admin || "N/A",
        superAdmin: accessData.superAdmin || "N/A",
        channelPartner: accessData.channelPartner || "N/A",
        manager: accessData.manager || "N/A",
        hr: accessData.hr || "N/A",
        employee: accessData.employee || "N/A",
      };
    });
  };

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };


  function validateTaskName(rule, value, callback) {
    const pattern = /^(?=.*[A-Za-z/])[A-Za-z /]*$/;
    if (rule.field === "key" && !pattern.test(value)) {
      callback("Key should not contain special characters");
    } else if (rule.field === "menuItem" && !pattern.test(value)) {
      callback(
        "Menu Item should not contain special characters and numeric values"
      );
    } else if (rule.field === "element" && !pattern.test(value)) {
      callback(
        "Element  should not contain special characters and numeric values"
      );
    } else if (rule.field === "componentPath" && !pattern.test(value)) {
      callback("Component Path should not contain special characters");
    } else {
      callback();
    }
  }

  const iconMappings = {
    UsergroupAddOutlined: <UsergroupAddOutlined />,
    AppstoreAddOutlined: <AppstoreAddOutlined />,
    HourglassOutlined: <HourglassOutlined />,
    BankOutlined: <BankOutlined />,
    AccountBookOutlined: <AccountBookOutlined />,
    ContactsOutlined: <ContactsOutlined />,
    CarryOutOutlined: <CarryOutOutlined />,
    ReconciliationOutlined: <ReconciliationOutlined />,
    ScheduleOutlined: <ScheduleOutlined />,
    SolutionOutlined: <SolutionOutlined />,
    FieldTimeOutlined: <FieldTimeOutlined />,
    WarningOutlined: <WarningOutlined />,
    FileDoneOutlined: <FileDoneOutlined />,
    ShopOutlined: <ShopOutlined />,
    FileProtectOutlined: <FileProtectOutlined />,
    DashboardOutlined: <DashboardOutlined />,
    EnvironmentOutlined: <EnvironmentOutlined />,
    DatabaseOutlined: <DatabaseOutlined />,
  };

  const handleRowExpand = (expanded, record) => {
    setExpandedRowKeys(expanded ? [record.key] : []);
  };
  const planTypes = ["Basic", "BasicPlusHR", "Demo", "Premium", "PremiumPlusHR"];


  const handleRoleCheckboxChange = (plan, role) => (e) => {
    console.log(e, 'event');

    setFormData((prevState) => ({
      ...prevState,
      access: {
        ...prevState.access,
        [plan]: {
          ...prevState.access[plan],
          [role]: e.target.checked ? "Allow" : "Deny",
        },
      },
    }));
  };

  const handleRoleCheckboxChange1 = (plan, role, event) => {
    const updatedAccess = { ...editingRecord.access };
    if (!updatedAccess[plan]) {
      updatedAccess[plan] = {};
    }
    updatedAccess[plan][role] = event.target.checked ? "Allow" : "Deny";
    setEditingRecord({ ...editingRecord, access: updatedAccess });
    form.setFieldsValue({ access: updatedAccess });
  };



  // const handleRoleCheckboxChange1 = (plan, role, e) => {
  //   console.log(e, 'event');
  //   const filter = e.target.checked;
  //   const updatedData = [...formData];
  //   updatedData.access[plan][role] = filter ? "Allow" : "Deny";
  //   setFormData(updatedData);
  //   // setFormData((prevState) => ({
  //   //   ...prevState,
  //   //   access: {
  //   //     ...prevState.access,
  //   //     [plan]: {
  //   //       ...prevState.access[plan],
  //   //       [role]: e.target.checked ? "Allow" : "Deny",
  //   //     },
  //   //   },
  //   // }));
  // };

  const toggleDropdown = (plan) => () => {
    setOpenDropdowns((prevState) => ({
      ...prevState,
      [plan]: !prevState[plan],
    }));
  };

  const toggleDropdown1 = (plan) => () => {
    setOpenDropdowns1((prevState) => ({
      ...prevState,
      [plan]: !prevState[plan],
    }));
  };
  return (
    <div style={{ transform: "scale(0.9)", transformOrigin: "0 0", width: "110%" }}>


      <Drawer
        title="Manage Sider Menu"
        width={1100}
        onClose={closeDrawer}
        visible={drawerVisible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <div>
          {/* Form for adding or editing menu items */}
          <Form form={form} onFinish={() => postMenuData(menuData)}>
            <div style={{ display: "flex", justifyContent: "center" }}>

              <Form.Item
                name="header"
                rules={[
                  {
                    required: true,
                    message: "Please Select the Header..!",
                  },
                ]}
              >
                <Select
                  placeholder="Select the Headers"
                  size="large"
                  value={formData.header}
                  style={{ width: "12rem", marginLeft: "2px" }}
                  onChange={(value) =>
                    setFormData({ ...formData, header: value })
                  }
                >
                  <Option value="My Work">My Work</Option>
                  <Option value="Approvals">Approvals</Option>
                  <Option value="Reports">Reports</Option>
                  <Option value="Support">Support</Option>
                  <Option value="My Requests">My Requests</Option>
                </Select>
              </Form.Item>
              {/* <Form.Item
              name="BillingType"
              rules={[
                {
                  required: true,
                  message: "Please Select your BillingType..!",
                },
              ]}
            >
              <Select
                placeholder="Select the BillingType"
                size="large"
                value={formData.BillingType}
                onChange={(value) =>
                  setFormData({ ...formData, BillingType: value })
                }
              >
                <Option value="Basic">Basic</Option>
                <Option value="BasicPlusHR">BasicPlusHR</Option>
                <Option value="Demo">Demo</Option>
                <Option value="Premium">Premium</Option>
                <Option value="PremiumPlusHR">PremiumPlusHR</Option>
              </Select>
            </Form.Item> */}
              <Form.Item
                name="icon"
                rules={[
                  {
                    required: true,
                    message: "Please Select the Icon..!",
                  },
                ]}
              >
                <Select
                  placeholder="Select the Icon"
                  size="large"
                  value={formData.icon}
                  style={{ width: "12rem", marginLeft: "30px" }}
                  onChange={(value) => setFormData({ ...formData, icon: value })}
                >
                  <Select.Option value="UsergroupAddOutlined">
                    UsergroupAddOutlined
                  </Select.Option>
                  <Select.Option value="AppstoreAddOutlined">
                    AppstoreAddOutlined
                  </Select.Option>
                  <Select.Option value="HourglassOutlined">
                    HourglassOutlined
                  </Select.Option>
                  <Select.Option value="BankOutlined">BankOutlined</Select.Option>
                  <Select.Option value="AccountBookOutlined">
                    AccountBookOutlined
                  </Select.Option>
                  <Select.Option value="ContactsOutlined">
                    ContactsOutlined
                  </Select.Option>
                  <Select.Option value="CarryOutOutlined">
                    CarryOutOutlined
                  </Select.Option>
                  <Select.Option value="ReconciliationOutlined">
                    ReconciliationOutlined
                  </Select.Option>
                  <Select.Option value="ScheduleOutlined">
                    ScheduleOutlined
                  </Select.Option>
                  <Select.Option value="SolutionOutlined">
                    SolutionOutlined
                  </Select.Option>
                  <Select.Option value="FieldTimeOutlined">
                    FieldTimeOutlined
                  </Select.Option>
                  <Select.Option value="WarningOutlined">
                    WarningOutlined
                  </Select.Option>
                  <Select.Option value="FileDoneOutlined">
                    FileDoneOutlined
                  </Select.Option>
                  <Select.Option value="ShopOutlined">ShopOutlined</Select.Option>
                  <Select.Option value="FileProtectOutlined">
                    FileProtectOutlined
                  </Select.Option>
                  <Select.Option value="DashboardOutlined">
                    DashboardOutlined
                  </Select.Option>
                  <Select.Option value="EnvironmentOutlined">
                    EnvironmentOutlined
                  </Select.Option>
                </Select>
              </Form.Item>
              <div style={{ fontSize: "20px", marginLeft: "15px" }}>
                <span
                  style={{
                    fontSize: "30px",
                    color: "white",
                    display: "flex",
                    flexDirection: "row",
                    borderRadius: "3px",
                    background: "black",
                  }}
                >
                  {iconMappings[formData.icon]}
                </span>
              </div>
              <Form.Item
                name="menuItem"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                  {
                    validator: validateTaskName, // Use the custom validator function
                  },
                ]}
              >
                <Input
                  placeholder="Menu Item"
                  value={formData.menuItem}
                  style={{ width: "10rem", marginLeft: "15px", height: "40px" }}
                  onChange={(e) =>
                    setFormData({ ...formData, menuItem: e.target.value })
                  }
                />
              </Form.Item>
              <Form.Item
                name="element"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                  {
                    validator: validateTaskName, // Use the custom validator function
                  },
                ]}
              >
                <Input
                  placeholder="Elements"
                  value={formData.element}
                  style={{ width: "10rem", marginLeft: "15px", height: "40px" }}
                  onChange={(e) =>
                    setFormData({ ...formData, element: e.target.value })
                  }
                />
              </Form.Item>
              <Form.Item
                name="componentPath"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                  {
                    validator: validateTaskName, // Use the custom validator function
                  },
                ]}
              >
                <Input
                  placeholder="Component Path"
                  value={formData.componentPath}
                  style={{ width: "10rem", marginLeft: "30px", height: "40px" }}
                  onChange={(e) =>
                    setFormData({ ...formData, componentPath: e.target.value })
                  }
                />
              </Form.Item>
            </div>
            {/* Role checkboxes */}
            {planTypes.map((plan) => (
              <div key={plan} style={{ marginBottom: "20px", marginLeft: "8vh" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>

                  {plan}
                  <DownCircleOutlined
                    onClick={toggleDropdown(plan)}
                    style={{ marginTop: "5px", marginLeft: "7px" }}
                  />
                </div>
                {openDropdowns[plan] && (
                  <div
                    style={{ display: "flex", flexDirection: "row", marginTop: "7px" }}
                  >
                    <Checkbox
                      checked={formData.access[plan]?.superAdmin === "Allow"}
                      onChange={handleRoleCheckboxChange(plan, "superAdmin")}
                      style={{ marginLeft: "30px" }}
                    >
                      Super-Admin
                    </Checkbox>
                    <Checkbox
                      checked={formData.access[plan]?.channelPartner === "Allow"}
                      onChange={handleRoleCheckboxChange(plan, "channelPartner")}
                      style={{ marginLeft: "30px" }}
                    >
                      Channel Partner
                    </Checkbox>
                    <Checkbox
                      checked={formData.access[plan]?.admin === "Allow"}
                      onChange={handleRoleCheckboxChange(plan, "admin")}
                      style={{ marginLeft: "30px" }}
                    >
                      Admin
                    </Checkbox>
                    <Checkbox
                      checked={formData.access[plan]?.hr === "Allow"}
                      onChange={handleRoleCheckboxChange(plan, "hr")}
                      style={{ marginLeft: "30px" }}
                    >
                      HR
                    </Checkbox>
                    <Checkbox
                      checked={formData.access[plan]?.manager === "Allow"}
                      onChange={handleRoleCheckboxChange(plan, "manager")}
                      style={{ marginLeft: "30px" }}
                    >
                      Manager
                    </Checkbox>
                    <Checkbox
                      checked={formData.access[plan]?.employee === "Allow"}
                      onChange={handleRoleCheckboxChange(plan, "employee")}
                      style={{ marginLeft: "30px" }}
                    >
                      Employee
                    </Checkbox>
                  </div>
                )}
              </div>
            ))}
            {/* <Button type="primary" onClick={handleAddRow} style={{ marginTop: "10px" }}>
                        Add Menu Item
                    </Button> */}
            <Button
              type="primary"
              style={{ width: "15vh", left: "45%" }}
              danger
              htmlType="submit"
              icon={<SaveOutlined />}
            >
              Save
            </Button>
          </Form>
        </div>
      </Drawer>

      <div style={{ overflowX: "auto" }}>


        <h5 className="text-center">MenuItems</h5>
        <Button type="primary" style={{ bottom: "10vh", left: "6vh" }} onClick={showDrawer}>
          Add Menus
        </Button>
        <Table
          className="custom-table col-lg-12 col-md-12 col-sm-12"
          columns={columns}
          dataSource={menuData}
          shouldComponentUpdate={shouldUpdate}
          scroll={{ x: true }}
          sticky
          expandable={{
            expandedRowKeys,
            onExpand: handleRowExpand,
            expandedRowRender: (record) => (
              <>
                <Table
                  columns={ExpandableBasicColumns}
                  dataSource={getExpandedBasic(record)}// Use record data here
                  pagination={false}
                  rowKey="key"
                  scroll={{ x: true }}
                />

              </>
            ),
          }}
          rowKey="key" // Ensure each row has a unique key
        />
      </div>
      {console.log(editingRecord, "recorduuu")
      }
      {editingRecord && (
        <Modal
          visible={editingRecord !== null}
          onCancel={() => onCancel()}
          onOk={() => form.submit()}
          bodyStyle={{ maxHeight: 450, overflowY: "auto", margin: "20px" }}
          footer={[
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <Button type="primary" onClick={() => form.submit()}>
                  Ok
                </Button>
                <Button onClick={() => onCancel()}>Cancel</Button>
              </div>
            </div>,
          ]}
        >
          <Form
            {...layout}
            form={form1}
            initialValues={editingRecord}
            onFinish={handleDBUpdate}
            className="custom-form"
          >
            <Form.Item
              name="key"
              label="key"
              rules={[
                {
                  required: true,
                  message: "Please Input Key..!",
                },
              ]}
            >
              <Input
                placeholder="Key"
                value={formData.key}
                onChange={(e) =>
                  setFormData({ ...formData, key: e.target.value })
                }
              />
            </Form.Item>
            <Form.Item
              name="header"
              label="header"
              rules={[
                {
                  required: true,
                  message: "Please Select the Header..!",
                },
              ]}
            >
              <Select
                placeholder="Select the Headers"
                size="large"
                value={formData.header}
                onChange={(value) =>
                  setFormData({ ...formData, header: value })
                }
              >
                <Option value="My Work">My Work</Option>
                <Option value="Approvals">Approvals</Option>
                <Option value="Support">Support</Option>
                <Option value="Reports">Reports</Option>
                <Option value="My Requests">My Requests</Option>

              </Select>
            </Form.Item>

            <Form.Item
              name="icon"
              label="icon"
              rules={[
                {
                  required: true,
                  message: "Please Select the Icon..!",
                },
              ]}
            >
              <Select
                placeholder="Select the Icon"
                size="large"
                value={formData.icon}
                onChange={(value) => setFormData({ ...formData, icon: value })}
              >
                <Select.Option value="UsergroupAddOutlined">
                  UsergroupAddOutlined
                </Select.Option>
                <Select.Option value="AppstoreAddOutlined">
                  AppstoreAddOutlined
                </Select.Option>
                <Select.Option value="HourglassOutlined">
                  HourglassOutlined
                </Select.Option>
                <Select.Option value="BankOutlined">BankOutlined</Select.Option>
                <Select.Option value="AccountBookOutlined">
                  AccountBookOutlined
                </Select.Option>
                <Select.Option value="ContactsOutlined">
                  ContactsOutlined
                </Select.Option>
                <Select.Option value="CarryOutOutlined">
                  CarryOutOutlined
                </Select.Option>
                <Select.Option value="ReconciliationOutlined">
                  ReconciliationOutlined
                </Select.Option>
                <Select.Option value="ScheduleOutlined">
                  ScheduleOutlined
                </Select.Option>
                <Select.Option value="SolutionOutlined">
                  SolutionOutlined
                </Select.Option>
                <Select.Option value="FieldTimeOutlined">
                  FieldTimeOutlined
                </Select.Option>
                <Select.Option value="WarningOutlined">
                  WarningOutlined
                </Select.Option>
                <Select.Option value="FileDoneOutlined">
                  FileDoneOutlined
                </Select.Option>
                <Select.Option value="ShopOutlined">ShopOutlined</Select.Option>
                <Select.Option value="FileProtectOutlined">
                  FileProtectOutlined
                </Select.Option>
                <Select.Option value="DashboardOutlined">
                  DashboardOutlined
                </Select.Option>
                <Select.Option value="EnvironmentOutlined">
                  EnvironmentOutlined
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="menuItem"
              label="MenuItem"
              rules={[
                {
                  required: true,
                  message: "Please Input Menu Item..!",
                },
                {
                  validator: validateTaskName, // Use the custom validator function
                },
              ]}
            >
              <Input
                placeholder="Menu Item"
                value={formData.menuItem}
                onChange={(e) =>
                  setFormData({ ...formData, menuItem: e.target.value })
                }
              />
            </Form.Item>
            <Form.Item
              name="element"
              label="Elements"
              rules={[
                {
                  required: true,
                  message: "Please Input Menu Item..!",
                },
                {
                  validator: validateTaskName, // Use the custom validator function
                },
              ]}
            >
              <Input
                placeholder="Elements"
                value={formData.element}
                onChange={(e) =>
                  setFormData({ ...formData, menuItem: e.target.value })
                }
              />
            </Form.Item>
            <Form.Item
              name="componentPath"
              label="Component"
              rules={[
                {
                  required: true,
                  message: "Please Input Component Path..!",
                },
                {
                  validator: validateTaskName, // Use the custom validator function
                },
              ]}
            >
              <Input
                placeholder="Component Path"
                onChange={(e) =>
                  setFormData({ ...formData, componentPath: e.target.value })
                }
              />
            </Form.Item>
            {planTypes.map((plan) => (
              <div key={plan} style={{ marginBottom: "20px", marginLeft: "5vh" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>

                  {plan}
                  <DownCircleFilled
                    onClick={toggleDropdown1(plan)}
                    style={{ marginTop: "5px", marginLeft: "6px" }}
                  />
                </div>
                {openDropdowns1[plan] && (
                  <div
                    style={{ display: "flex", flexDirection: "column", marginTop: "10px", }}
                  >
                    <Form.Item>
                      <Checkbox
                        checked={editingRecord.access[plan]?.superAdmin === "Allow"}
                        // value={editingRecord.access[plan]?.superAdmin}
                        onChange={(e) => handleRoleCheckboxChange1(plan, "superAdmin", e)}
                        style={{ marginLeft: "30px" }}
                      >
                        Super-Admin
                      </Checkbox>
                    </Form.Item>
                    <Form.Item>
                      <Checkbox
                        checked={editingRecord.access[plan]?.channelPartner === "Allow"}
                        // value={editingRecord.access[plan]?.channelPartner}
                        onChange={(e) => handleRoleCheckboxChange1(plan, "channelPartner", e)}
                        style={{ marginLeft: "30px" }}
                      >
                        Channel Partner
                      </Checkbox>
                    </Form.Item>
                    <Form.Item>
                      <Checkbox
                        checked={editingRecord.access[plan]?.admin === "Allow"}
                        // value={editingRecord.access[plan]?.admin}
                        onChange={(e) => handleRoleCheckboxChange1(plan, "admin", e)}
                        style={{ marginLeft: "30px" }}
                      >
                        Admin
                      </Checkbox>
                    </Form.Item>
                    <Form.Item>
                      <Checkbox
                        checked={editingRecord.access[plan]?.hr === "Allow"}
                        // value={editingRecord.access[plan]?.hr}
                        onChange={(e) => handleRoleCheckboxChange1(plan, "hr", e)}
                        style={{ marginLeft: "30px" }}
                      >
                        HR
                      </Checkbox>
                    </Form.Item>
                    <Form.Item>
                      <Checkbox
                        checked={editingRecord.access[plan]?.manager === "Allow"}
                        // value={editingRecord.access[plan]?.manager}
                        onChange={(e) => handleRoleCheckboxChange1(plan, "manager", e)}
                        style={{ marginLeft: "30px" }}
                      >
                        Manager
                      </Checkbox>
                    </Form.Item>
                    <Form.Item>
                      <Checkbox
                        checked={editingRecord.access[plan]?.employee === "Allow"}
                        // value={editingRecord.access[plan]?.employee}
                        onChange={(e) => handleRoleCheckboxChange1(plan, "employee", e)}
                        style={{ marginLeft: "30px" }}
                      >
                        Employee
                      </Checkbox>
                    </Form.Item>
                  </div>
                )}
              </div>
            ))}
            {/* <Form.Item label="superAdmin">
              <Select
                size="large"
                value={formData.superAdmin}
                onChange={(value) =>
                  setFormData({ ...formData, superAdmin: value })
                }
              >
                <Option value="Allow">Allow</Option>
                <Option value="Deny">Deny</Option>
              </Select>
            </Form.Item>
            <Form.Item name="admin" label="Admin">
              <Select
                onChange={(value) =>
                  setFormData({
                    ...formData,
                    admin: value,
                  })
                }
                checked={editingRecord.admin === "Allow"}
              >
                <Option value="Allow">Allow</Option>
                <Option value="Deny">Deny</Option>
              </Select>
            </Form.Item>
            <Form.Item name="hr" label="HR">
              <Select
                onChange={(value) =>
                  setFormData({
                    ...formData,
                    hr: value,
                  })
                }
                checked={editingRecord.hr === "Allow"}
              >
                <Option value="Allow">Allow</Option>
                <Option value="Deny">Deny</Option>
              </Select>
            </Form.Item>
            <Form.Item name="manager" label="Manager">
              <Select
                onChange={(value) =>
                  setEditingRecord({
                    ...editingRecord,
                    manager: value,
                  })
                }
                checked={editingRecord.manager === "Allow"}
              >
                <Option value="Allow">Allow</Option>
                <Option value="Deny">Deny</Option>
              </Select>
            </Form.Item>

            <Form.Item name="employee" label="Employee">
              <Select
                onChange={(value) =>
                  setFormData({
                    ...formData,
                    employee: value,
                  })
                }
                checked={editingRecord.employee === "Allow"}
              >
                <Option value="Allow">Allow</Option>
                <Option value="Deny">Deny</Option>
              </Select>
            </Form.Item> */}
          </Form>
        </Modal>
      )}
    </div>
  );
};

export default UserMenuTable;
