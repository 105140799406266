import React, { useState, useEffect } from "react";
import { Button, Popconfirm, Table, message, notification } from "antd";
import axios from "axios";
import { url } from "../../url/url";
import { Delete12Filled } from "@fluentui/react-icons";

const MasterUserTable = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const users = JSON.parse(localStorage.getItem("user"));
        const channelName = users.firstName;
        const response = await axios.get(
          `${url}/api/masterusers/${channelName}`
        );
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  
  const handleDelete = async (record) => {
    const users = JSON.parse(localStorage.getItem("user"));
    const channelName = users.displayName;
    const domain = localStorage.getItem("domain");
    let officeEmail = "";

    try {
      const response = await fetch(
        `${url}/api/projects/${channelName}/${record.dbName}`
      );
      const data = await response.json();

      if (data.length === 0) {
        if (record.userType === "true") {
          const firstName = record.firstName.toLowerCase();
          const lastName = record.lastName.toLowerCase();
          officeEmail = `${firstName}.${lastName.charAt(0)}@${domain}`;
        } else {
          officeEmail = record.email;
        }

        // Delete from master user table
        const masterResponse = await axios.delete(
          `${url}/api/deletemasteremp/${officeEmail}`
        );
        if (masterResponse.status === 200) {
          console.log("Record deleted successfully from master user table!");
        } else {
          console.error("Failed to delete record from master user table.");
          return;
        }

        // Delete from user schema
        const dbDeleteResponse = await axios.delete(
          `${url}/api/deleteemployee/${record.email}/${record.dbName}`
        );
        if (dbDeleteResponse.status === 200) {
          console.log("Record deleted successfully from user schema!");
        } else {
          console.error("Failed to delete record from user schema.");
          return;
        }

        // If Azure user, delete from Azure
        if (record.userType === "true") {
          let userPrincipalName;
          try {
            const res = await axios.get(`${url}/api/getmasterorg/${domain}`);
            const clientId = res.data.org.clientId;
            const tenantId = res.data.org.tenantId;
            const clientSecret = res.data.org.clientSecret;
            const response = await fetch(
              `${url}/api/getUser/${record.displayName}/${clientId}/${tenantId}/${clientSecret}`
            );
            if (response.ok) {
              const userData = await response.json();
              userPrincipalName = userData.value[0].userPrincipalName;
            } else {
              console.error("Failed to fetch user data");
            }
          } catch (error) {
            console.error("Error:", error);
          }

          try {
            const res = await axios.get(`${url}/api/getmasterorg/${domain}`);
            const clientId = res.data.org.clientId;
            const tenantId = res.data.org.tenantId;
            const clientSecret = res.data.org.clientSecret;
            const response = await axios.delete(
              `${url}/api/deleteUser/${userPrincipalName}/${clientId}/${tenantId}/${clientSecret}`
            );
            if (response.ok) {
              console.log("Employee deleted successfully from Azure!");
            }
          } catch (error) {
            console.error("Something went wrong while deleting from Azure!");
          }
        }

        // Remove the deleted record from the data state
        setData(data.filter((data) => data.email !== record.email));
        notification.success({ message: "Record deleted successfully!" });
      } else {
        notification.open({
          message: "Alert Notification ",
          description: "This Employee is Assigned in Project",
          onClick: () => {
            console.log("Notification Clicked!");
          },
        });
      }
    } catch (error) {
      console.error("Error:", error);
      message.error("Something went wrong!");
    }
  };

  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: "Database Name",
      dataIndex: "dbName",
      key: "dbName",
      sorter: (a, b) => a.dbName.localeCompare(b.dbName),
    },
    {
      title: "Company Name",
      dataIndex: "dbName",
      key: "companyName",
      sorter: (a, b) => a.dbName.localeCompare(b.dbName),
    },
    {
      title: "User Type",
      dataIndex: "userType",
      key: "userType",
      filters: [
        { text: "Native", value: "false" },
        { text: "Cloud", value: "true" },
        // Add more filters as needed
      ],
      onFilter: (value, record) => record.userType === value,
      sorter: (a, b) => a.userType.localeCompare(b.userType),
    },
    {
      title: "Job Title",
      dataIndex: "jobTitle",
      key: "jobTitle",
      sorter: (a, b) => a.jobTitle.localeCompare(b.jobTitle),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Popconfirm
          title="Are you sure you want to delete this record?"
          onConfirm={() => handleDelete(record)}
          okText="Yes"
          cancelText="No"
        >
          <Button type="danger" size="small">
          <Delete12Filled/>
          </Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      rowKey={(record) => record._id}
      bordered
      scroll={{ x: "max-content" }}
      style={{ boxShadow: "0px 0px 10px rgba(0,0,0,0.1)", zoom: "90%" }}
      title={() => <h2>Masters Users Reference</h2>}
      className="custom-table col-lg-12 col-md-12 col-sm-12"
      sticky
    />
  );
};

export default MasterUserTable;
