import { DatabaseOutlined, FormOutlined, MailOutlined, PushpinOutlined, UserAddOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Divider, Form, Input, Row, Select, message } from 'antd'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { url } from '../../url/url';
import axios from 'axios';
import { useForm } from 'antd/lib/form/Form';
const { Option } = Select;

const Travelform = () => {
    const users = JSON.parse(localStorage.getItem("user"));
    const [travel, setTravel] = useState([]);
    const [isInternational, setIsInternational] = useState(false);
    const [departureDate, setDepartureDate] = useState('');
    const [form] = useForm();
    const formRef = React.useRef(null);
    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);


    const handleStateChange = (value) => {
        setSelectedState(value);
        setSelectedCity('');
    };

    const handleDepartureDateChange = (e) => {
        setDepartureDate(e.target.value);
    };
    const handleTravelTypeChange = (checkedValues) => {
        setIsInternational(checkedValues.includes('International'));
        const selectedValue = isInternational ? 'International' : 'Domestic';
        form.setFieldsValue({ travelType: selectedValue.toString() });
    };

    const padZero = (num) => (num < 10 ? `0${num}` : num);
    const today = new Date();

    const day = padZero(today.getDate());
    const month = padZero(today.getMonth() + 1); // Adding 1 because getMonth() returns 0-indexed month
    const year = today.getFullYear();

    const formattedDate = `${day}-${month}-${year}`;

    const navigate = useNavigate(null);
    const goBack = () => {
        navigate(-1);
    };
    const handleReset = () => {
        formRef.current?.resetFields();
    };

    const onFinish = async (values) => {
        const dbName = localStorage.getItem("dbName");
        try {
            values.requestDate = formattedDate;
            const response = await axios.post(
                `${url}/api/travelrequest/${dbName}`,
                values
            );
            setTravel(response.data);
            message.success('Request sent successfully');
            navigate("/home/travelexpense")
            handleReset();
            console.log(response, "Rep");
        } catch (error) {
            
            console.error('Error:', error);
            message.error('An error occurred while creating the travel request');
        }
    };
    // const countries = [
    //     "Afghanistan",
    //     "Albania",
    //     "Algeria",
    //     "American Samoa",
    //     "Andorra",
    //     "Angola",
    //     "Anguilla",
    //     "Antarctica",
    //     "Antigua and Barbuda",
    //     "Argentina",
    //     "Armenia",
    //     "Aruba",
    //     "Ashmore and Cartier Island",
    //     "Australia",
    //     "Austria",
    //     "Azerbaijan",
    //     "Bahamas",
    //     "Bahrain",
    //     "Bailiwick of Jersey",
    //     "Bangladesh",
    //     "Barbados",
    //     "Belarus",
    //     "Belgium",
    //     "Belize",
    //     "Benin",
    //     "Bermuda",
    //     "Bhutan",
    //     "Bolivia",
    //     "Bosnia and Herzegovina",
    //     "Botswana",
    //     "Brazil",
    //     "British Indian Ocean Territory",
    //     "British Virgin Islands",
    //     "Brunei",
    //     "Bulgaria",
    //     "Burkina Faso",
    //     "Burundi",
    //     "Cambodia",
    //     "Cameroon",
    //     "Canada",
    //     "Cape Verde",
    //     "Cayman Islands",
    //     "Central African Republic",
    //     "Chad",
    //     "Chile",
    //     "China",
    //     "Christmas Island",
    //     "Clipperton Island",
    //     "Cocos (Keeling) Islands",
    //     "Colombia",
    //     "Comoros",
    //     "Congo, Democratic Republic of the",
    //     "Congo, Republic of the",
    //     "Cook Islands",
    //     "Costa Rica",
    //     "Cote d'Ivoire",
    //     "Croatia",
    //     "Cuba",
    //     "Cyprus",
    //     "Czech Republic",
    //     "Denmark",
    //     "Djibouti",
    //     "Dominica",
    //     "Dominican Republic",
    //     "East Timor",
    //     "Ecuador",
    //     "Egypt",
    //     "El Salvador",
    //     "Equatorial Guinea",
    //     "Eritrea",
    //     "Estonia",
    //     "Ethiopia",
    //     "Europa Island",
    //     "Falkland Islands (Islas Malvinas)",
    //     "Faroe Islands",
    //     "Fiji",
    //     "Finland",
    //     "France",
    //     "French Guiana",
    //     "French Polynesia",
    //     "French Southern and Antarctic Lands",
    //     "Gabon",
    //     "Gambia",
    //     "The",
    //     "Georgia",
    //     "Germany",
    //     "Ghana",
    //     "Gibraltar",
    //     "Glorioso Islands",
    //     "Greece",
    //     "Greenland",
    //     "Grenada",
    //     "Guadeloupe",
    //     "Guam",
    //     "Guatemala",
    //     "Guernsey",
    //     "Guinea",
    //     "Guinea-Bissau",
    //     "Guyana",
    //     "Haiti",
    //     "Heard Island and McDonald Islands",
    //     "Honduras",
    //     "Hong Kong",
    //     "Howland Island",
    //     "Hungary",
    //     "Iceland",
    //     "India",
    //     "Indonesia",
    //     "Iran",
    //     "Iraq",
    //     "Ireland",
    //     "Northern",
    //     "Israel",
    //     "Italy",
    //     "Jamaica",
    //     "Jan Mayen",
    //     "Japan",
    //     "Jarvis Island",
    //     "Jersey",
    //     "Johnston Atoll",
    //     "Jordan",
    //     "Juan de Nova Island",
    //     "Kazakhstan",
    //     "Kenya",
    //     "Kiribati",
    //     "Korea, North",
    //     "Korea, South",
    //     "Kuwait",
    //     "Kyrgyzstan",
    //     "Laos",
    //     "Latvia",
    //     "Lebanon",
    //     "Lesotho",
    //     "Liberia",
    //     "Libya",
    //     "Liechtenstein",
    //     "Lithuania",
    //     "Luxembourg",
    //     "Macau",
    //     "Macedonia, The Former Yugoslav Republic of",
    //     "Madagascar",
    //     "Malawi",
    //     "Malaysia",
    //     "Maldives",
    //     "Mali",
    //     "Malta",
    //     "Man, Isle of",
    //     "Marshall Islands",
    //     "Martinique",
    //     "Mauritania",
    //     "Mauritius",
    //     "Mayotte",
    //     "Mexico",
    //     "Micronesia, Federated States of",
    //     "Midway Islands",
    //     "Moldova",
    //     "Monaco",
    //     "Mongolia",
    //     "Montserrat",
    //     "Morocco",
    //     "Mozambique",
    //     "Myanmar / Burma",
    //     "Namibia",
    //     "Nauru",
    //     "Nepal",
    //     "Netherlands",
    //     "Netherlands Antilles",
    //     "New Caledonia",
    //     "New Zealand",
    //     "Nicaragua",
    //     "Niger",
    //     "Nigeria",
    //     "Niue",
    //     "Norfolk Island",
    //     "Northern Mariana Islands",
    //     "Norway",
    //     "Oman",
    //     "Pakistan",
    //     "Palau",
    //     "Palestinian Territories",
    //     "Panama",
    //     "Papua New Guinea",
    //     "Paraguay",
    //     "Peru",
    //     "Philippines",
    //     "Pitcairn Islands",
    //     "Poland",
    //     "Portugal",
    //     "Puerto Rico",
    //     "Qatar",
    //     "Reunion",
    //     "Romania",
    //     "Russia",
    //     "Rwanda",
    //     "Saint Helena",
    //     "Saint Kitts and Nevis",
    //     "Saint Lucia",
    //     "Saint Pierre and Miquelon",
    //     "Saint Vincent and the Grenadines",
    //     "Samoa",
    //     "San Marino",
    //     "Sao Tome and Principe",
    //     "Saudi Arabia",
    //     "Scotland",
    //     "Senegal",
    //     "Serbia and Montenegro",
    //     "Seychelles",
    //     "Sierra Leone",
    //     "Singapore",
    //     "Slovakia",
    //     "Slovenia",
    //     "Solomon Islands",
    //     "Somalia",
    //     "South Africa",
    //     "South Georgia and the South Sandwich Islands",
    //     "Spain",
    //     "Spratly Islands",
    //     "Sri Lanka",
    //     "Sudan",
    //     "Suriname",
    //     "Svalbard",
    //     "Swaziland",
    //     "Sweden",
    //     "Switzerland",
    //     "Syria",
    //     "Taiwan",
    //     "Tajikistan",
    //     "Tanzania",
    //     "Thailand",
    //     "Tobago",
    //     "Togo",
    //     "Tokelau",
    //     "Tonga",
    //     "Trinidad",
    //     "Tunisia",
    //     "Turkey",
    //     "Turkmenistan",
    //     "Tuvalu",
    //     "Uganda",
    //     "Ukraine",
    //     "United Arab Emirates",
    //     "United Kingdom",
    //     "Uruguay",
    //     "USA",
    //     "Uzbekistan",
    //     "Vanuatu",
    //     "Vatican City",
    //     "Venezuela",
    //     "Vietnam",
    //     "Virgin Islands",
    //     "Wales",
    //     "Wallis and Futuna",
    //     "Western Sahara",
    //     "Yemen",
    //     "Zambia",
    //     "Zimbabwe",
    // ];

    // const states = [
    //     "Alabama",
    //     "Alaska",
    //     "Arizona",
    //     "Arkansas",
    //     "California",
    //     "Colorado",
    //     "Connecticut",
    //     "Delaware",
    //     "Florida",
    //     "Georgia",
    //     "Hawaii",
    //     "Idaho",
    //     "Illinois",
    //     "Indiana",
    //     "Iowa",
    //     "Kansas",
    //     "Kentucky",
    //     "Louisiana",
    //     "Maine",
    //     "Maryland",
    //     "Massachusetts",
    //     "Michigan",
    //     "Minnesota",
    //     "Mississippi",
    //     "Missouri",
    //     "Montana",
    //     "Nebraska",
    //     "Nevada",
    //     "New Hampshire",
    //     "New Jersey",
    //     "New Mexico",
    //     "New York",
    //     "North Carolina",
    //     "North Dakota",
    //     "Ohio",
    //     "Oklahoma",
    //     "Oregon",
    //     "Pennsylvania",
    //     "Rhode Island",
    //     "South Carolina",
    //     "South Dakota",
    //     "Tamil Nadu",
    //     "Tennessee",
    //     "Texas",
    //     "Utah",
    //     "Vermont",
    //     "Virginia",
    //     "Washington",
    //     "West Virginia",
    //     "Wisconsin",
    //     "Wyoming"
    // ];
    // const cities = [
    //     "Chennai",
    //     "Coimbatore",
    //     "Madurai",
    //     "Tiruchirappalli",
    //     "Salem",
    //     "Tirunelveli",
    //     "Tiruppur",
    //     "Erode",
    //     "Vellore",
    //     "Thoothukudi",
    //     "Dindigul",
    //     "Thanjavur",
    //     "Ranipet",
    //     "Sivakasi",
    //     "Karur",
    //     "Udhagamandalam",
    //     "Hosur",
    //     "Nagercoil",
    //     "Kanchipuram",
    //     "Kumbakonam",
    //     "Avadi",
    //     "Tiruvannamalai",
    //     "Pollachi",
    //     "Rajapalayam",
    //     "Sirkali",
    //     "Mayiladuthurai",
    //     "Neyveli",
    //     "Pudukkottai",
    //     "Vaniyambadi",
    //     "Karaikkudi",
    //     "Nagapattinam",
    //     "Viluppuram",
    //     "Tiruchengode",
    //     "Aranthangi",
    //     "Paramakudi",
    //     "Aruppukkottai",
    //     "Virudhachalam",
    //     "Attur",
    //     "Sankarankovil",
    //     "Sholinghur",
    //     "Thiruvarur",
    //     "Sivagangai",
    //     "Tenkasi",
    //     "Ramanathapuram",
    //     "Oddanchatram",
    //     "Vikramasingapuram",
    //     "Perambalur",
    //     "Padmanabhapuram",
    //     "Tittakudi",
    //     "Arakkonam",
    //     "Namakkal",
    //     "Pallavaram",
    //     "Vriddhachalam",
    //     "Vadipatti",
    //     "Gobichettipalayam",
    //     "Musiri",
    //     "Chidambaram",
    //     "Palladam",
    //     "Thiruthani",
    //     "Tindivanam",
    //     "Udumalaipettai",
    //     "Gudiyatham",
    //     "Ambur",
    //     "Pernampattu",
    //     "Sathyamangalam",
    //     "Sankari",
    //     "Karur",
    //     "Arani",
    //     "Thirupuvanam",
    //     "Virudhunagar",
    //     "Panruti",
    //     "Kovilpatti",
    //     "Theni",
    //     "Ulundurpettai",
    //     "Chengalpattu",
    //     "Thirumangalam",
    //     "Thiruparankundram",
    //     "Pattukkottai",
    //     "Periyakulam",
    //     "Thiruvallur",
    //     "Srivilliputhur",
    //     "Thiruvarur",
    //     "Vedaranyam",
    //     "Tirupathur",
    //     "Vaniyambadi",
    //     "Vedaranyam",
    //     "Veppathur",
    //     "Viluppuram",
    //     "Walajapet",
    //     "Ambasamudram",
    //     "Arakkonam",
    //     "Bhavani",
    //     "Chinnamanur",
    //     "Dharmapuri",
    //     "Kadayanallur",
    //     "Kallakkurichi",
    //     "Kanchipuram",
    //     "Kangayam",
    //     "Karaikkudi",
    //     "Kovilpatti",
    //     "Krishnagiri",
    //     "Kunnathur",
    //     "Lalgudi",
    //     "Manachanallur",
    //     "Manapparai",
    //     "Mannargudi",
    //     "Maraimalai Nagar",
    //     "Mayiladuthurai",
    //     "Metupalayam",
    //     "Namakkal",
    //     "Needamangalam",
    //     "Oddanchatram",
    //     "Palani",
    //     "Palladam",
    //     "Paramakudi",
    //     "Pattukkottai",
    //     "Perambalur",
    //     "Pollachi",
    //     "Pudukkottai",
    //     "Pudupattinam",
    //     "Puliyankudi",
    //     "Rajapalayam",
    //     "Ramanathapuram",
    //     "Rameshwaram",
    //     "Rasipuram",
    //     "Salem",
    //     "Sankarankoil",
    //     "Sankari",
    //     "Sathyamangalam",
    //     "Sattur",
    //     "Shenkottai",
    //     "Sholavandan",
    //     "Sivaganga",
    //     "Sivagangai",
    //     "Srivilliputhur",
    //     "Surandai",
    //     "Suriyampalayam",
    //     "Tenkasi",
    //     "Thammampatti",
    //     "Thandarampattu",
    //     "Thanjavur",
    //     "Thirumangalam",
    //     "Thiruparankundram",
    //     "Thirupuvanam",
    //     "Thiruthani",
    //     "Thiruvarur",
    //     "Thuraiyur",
    //     "Tindivanam",
    //     "Tiruchendur",
    //     "Tiruchengode",
    //     "Tiruchirappalli",
    //     "Tirukalukundram",
    //     "Tirukkoyilur",
    //     "Tirunelveli",
    //     "Tirupathur",
    //     "Tirupattur",
    //     "Tiruppur",
    //     "Tiruvannamalai",
    //     "Tiruvarur",
    //     "Tittakudi",
    //     "Udhagamandalam",
    //     "Udumalaipettai",
    //     "Unnamalaikadai",
    //     "Usilampatti",
    //     "Vadakkuvalliyur",
    //     "Vadalur",
    //     "Vadipatti",
    //     "Valparai",
    //     "Vandavasi",
    //     "Vaniyambadi",
    //     "Vedaranyam",
    //     "Vellakoil",
    //     "Vellimalai",
    //     "Vellore",
    //     "Vikramasingapuram",
    //     "Viluppuram",
    //     "Virudhachalam",
    //     "Virudhunagar",
    //     "Vriddhachalam",
    //     "Walajabad",
    //     "Chidambaram",
    //     "Chingleput",
    //     "Chinna Salem",
    //     "Chinnasalem",
    //     "Coonoor",
    //     "Coonoor",
    //     "Denkanikottai",
    //     "Dharampuram",
    //     "Gobichettipalayam",
    //     "Gudalur",
    //     "Harur",
    //     "Jolarpettai",
    //     "Kadambur",
    //     "Kadathur",
    //     "Kadayal",
    //     "Kallakurichi",
    //     "Kalugumalai",
    //     "Kamuthi",
    //     "Kangeyam",
    //     "Kangayampalayam",
    //     "Kangeyam",
    //     "Kaveripattinam",
    //     "Kayatharu",
    //     "Kilvelur",
    //     "Kodaikanal",
    //     "Kodumudi",
    //     "Kolachal",
    //     "Kolappalur",
    //     "Kovilpatti",
    //     "Krishnagiri",
    //     "Krishnankoil",
    //     "Kuchanur",
    //     "Kulithalai",
    //     "Kumarapalayam",
    //     "Kurinjipadi",
    //     "Kurumbalur",
    //     "Kuttalam",
    //     "Lalgudi",
    //     "Lalpet",
    //     "Madathukulam",
    //     "Madukkur",
    //     "Madurai",
    //     "Maduranthakam",
    //     "Maduravoyal",
    //     "Mallankinaru",
    //     "Mallasamudram",
    //     "Manachanallur",
    //     "Manalmedu",
    //     "Manaparai",
    //     "Mangalampet",
    //     "Manimutharu",
    //     "Mannargudi",
    //     "Mannargudi",
    //     "Maraimalainagar",
    //     "Mayiladuthurai",
    //     "Melacheval",
    //     "Melachokkanathapuram",
    //     "Melagaram",
    //     "Melamadai",
    //     "Melathiruppanthur"
    // ]

    const currency = [
        "United States Dollar (USD)",
        "Euro (EUR)",
        "Japanese Yen(JPY)",
        "British Pound Sterling(GBP)",
        "Australian Dollar(AUD)",
        "Canadian Dollar(CAD)",
        "Swiss Franc(CHF)",
        "Chinese Yuan Renminbi(CNY)",
        "Swedish Krona(SEK)",
        "New Zealand Dollar(NZD)",
        "Mexican Peso(MXN)",
        "Singapore Dollar(SGD)",
        "Hong Kong Dollar(HKD)",
        "Norwegian Krone(NOK)",
        "South Korean Won(KRW)",
        "Turkish Lira(TRY)",
        "Russian Ruble(RUB)",
        "Indian Rupee(INR)",
        "Brazilian Real(BRL)",
        "South African Rand(ZAR)"
    ]

    const calculateMinDate = () => {
        const currentDate = new Date();
        const oneMonthBefore = new Date(currentDate);
        oneMonthBefore.setMonth(oneMonthBefore.getMonth() - 1);
        return oneMonthBefore.toISOString().split('T')[0];
    };

    return (
        <div>
            <Button onClick={goBack}>Back</Button>
            <h4 style={{zoom:"90%"}}>Travel Request</h4>
            <Divider />
            <Form
                ref={formRef}
                form={form}
                name="travel_request"
                onFinish={onFinish}
                layout="vertical"
            >
                <Row gutter={[16, 16]} style={{ justifyContent: "space-between" }}>
                    <Col span={6}>
                        <Form.Item label="Request Date"  >
                            <Input value={formattedDate} style={{ fontSize: "12px", }} />
                        </Form.Item>
                    </Col>
                    <Col span={7}>
                        <Form.Item label="Email" name="email" initialValue={users.email}>
                            <Input prefix={<MailOutlined />} readOnly />
                        </Form.Item>
                    </Col>
                </Row>
                <Divider />

                <Row gutter={[16, 16]}>
                    <Col span={8}>
                        <Form.Item label="Employee ID" name="employeeId" initialValue={users.empCode}>
                            <Input prefix={<FormOutlined />} readOnly />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="First Name" name="firstName" initialValue={users.firstName}>
                            <Input prefix={<FormOutlined />} readOnly />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Last Name" name="lastName" initialValue={users.lastName}>
                            <Input prefix={<FormOutlined />} readOnly />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>

                    <Col span={8}>
                        <Form.Item label="Travel Type" name="travelType" rules={[{ required: true, message: "Enter Travel Type " }]}>
                            <Select>
                                <Select.Option value="Domestic">Domestic</Select.Option>
                                <Select.Option value="International">International</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Department" name="department" initialValue={users.jobTitle}>
                            <Input prefix={<FormOutlined />} readOnly />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Manager" name="manager" initialValue={users.manager}>
                            <Input prefix={<UserAddOutlined />} readOnly />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col span={6}>
                        <Form.Item label="Destination Country" name="destinationCountry" rules={[{ required: true, message: "Enter Destination Country" }]} >
                        <Input />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Destination State" name="destinationState" rules={[{ required: true, message: "Enter Destination State" }]}>
                        <Input  />
                        </Form.Item>
                    </Col>
                    <Col span={6} >
                        <Form.Item label="Destination City" name="destinationCity" rules={[{ required: true, message: "Enter Destination City" }]}>
                        <Input  />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Zip Code" name="zipCode" rules={[{ required: true, message: "Enter Zip Code" }]}>
                            <Input prefix={<PushpinOutlined />} />
                        </Form.Item>
                    </Col>


                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={8}>
                        <Form.Item label="Origin Country" name="OriginCountry" rules={[{ required: true, message: "Enter Origin Country" }]} >
                            <Input  />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Origin State" name="OriginState" rules={[{ required: true, message: "Enter Origin State" }]}>
                            <Input  />
                        </Form.Item>
                    </Col>
                    <Col span={8} >
                        <Form.Item label="Origin City" name="OriginCity" rules={[{ required: true, message: "Enter O City" }]}>
                            <Input />

                        </Form.Item>
                    </Col>



                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={8}>
                        <Form.Item label="Departure Date" name="departureDate" rules={[{ required: true, message: "Choose Departure Date" }]}>
                            <Input type='date' onChange={handleDepartureDateChange} min={calculateMinDate()} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Return Date" name="returnDate" rules={[{ required: true, message: "Choose Return Date" }]}>
                            <Input type='date' min={departureDate} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Travel Purpose" name="reasonForTravel" rules={[{ required: true, message: "Enter Reason for Travel" }]}>
                            <Input.TextArea />
                        </Form.Item>
                    </Col>

                </Row>

                <Row gutter={[16, 16]}>
                    <Col span={8}>
                        <Form.Item label="Estimated Travel Costs" name="estimatedTravelCosts">
                            <Input prefix={<FormOutlined />} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Travel Advance" name="travelAdvance">
                            <Input prefix={<FormOutlined />} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Currency" name="currency">
                            <Select placeholder="Select your Currencies" showSearch filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                0
                            }>
                                {currency.map((currencies, index) => (
                                    <Option key={index} value={currencies}>
                                        {currencies}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item label="Any Comments or notes regarding the travel" name="comments">
                    <Input.TextArea />
                </Form.Item>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Form.Item>
                        <Button type="primary" danger onClick={handleReset}>
                            Reset
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>

                </div>
            </Form>
        </div>
    )
}

export default Travelform