import React, { useEffect, useState } from "react";
import { Checkbox, Col, Divider, Form, Input, Row, Select, Table } from "antd";
import axios from "axios";
import { url } from "../../url/url";

const { Option } = Select;

const Travelreport = () => {
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedFromDate, setSelectedFromDate] = useState("");
  const [selectedToDate, setSelectedToDate] = useState("");
  const [selectedTravelType, setSelectedTravelType] = useState([]);
  const [allTravelRequests, setAllTravelRequests] = useState([]);
  const [isTableVisible, setIsTableVisible] = useState(false);

  useEffect(() => {
    if (
      selectedFromDate ||
      selectedToDate ||
      selectedStatus ||
      selectedTravelType.length > 0
    ) {
      fetchTravelRequests();
    }
  }, [selectedFromDate, selectedToDate, selectedStatus, selectedTravelType]);

  const fetchTravelRequests = async () => {
    const dbName = localStorage.getItem("dbName");
    try {
      let query = `?departureDate=${selectedFromDate}`;
      if (selectedToDate) query += `&returnDate=${selectedToDate}`;
      if (selectedTravelType.length > 0) query += `&travelType=${selectedTravelType.join(",")}`;
      if (selectedStatus) query += `&status=${selectedStatus}`;
  
      const response = await axios.get(`${url}/getall/reports/${dbName}${query}`);
      setAllTravelRequests(response.data.travelRequests);
      console.log(response.data.travelRequests, "travel");
      setIsTableVisible(true);
    } catch (error) {
      console.error("Error fetching travel requests:", error);
    }
  };
  
  // Columns configuration for Ant Design Table
  const columns = [
    {
      title: "Employee Id",
      dataIndex: "employeeId",
    },
    {
      title: "Employee Name",
      dataIndex: "firstName",
      render: (_, record) => (
        <>
          {record.firstName} {record.lastName}
        </>
      ),
    },
    {
      title: "Request Id",
      dataIndex: "requestId",
    },
    {
      title: "Travel Purpose",
      dataIndex: "reasonForTravel",
    },
    {
      title: "Travel Type",
      dataIndex: "travelType",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => (
        <span
          style={{
            color:
              record.status === "Approved"
                ? "green"
                : record.status === "Denied"
                ? "red"
                : "inherit",
          }}
        >
          {text}
        </span>
      ),
    },
  ];

  return (
    <div style={{zoom:"90%"}}>
      <h4 className="text-center">Travel Reports</h4>
      <Divider />
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <label htmlFor="departureDate">From Date</label>
          <Input
            type="date"
            id="departureDate"
            onChange={(e) => setSelectedFromDate(e.target.value)}
          />
        </Col>
        <Col span={6}>
          <label htmlFor="returnDate">To Date</label>
          <Input
            type="date"
            id="returnDate"
            onChange={(e) => setSelectedToDate(e.target.value)}
          />
        </Col>
        <Col span={6}>
          <label htmlFor="status">Request Status:</label>
          <Select
            id="status"
            onChange={(value) => setSelectedStatus(value)}
            value={selectedStatus}
            style={{ width: 200 }}
          >
            <Option value="">Select a Request Status...</Option>
            <Option value="Submitted">Submitted</Option>
            <Option value="Approved">Approved</Option>
            <Option value="Rejected">Rejected</Option>
          </Select>
        </Col>
        <Col span={6}>
          <label htmlFor="travelType">Travel Type:</label>
          <Checkbox.Group
            id="travelType"
            onChange={(values) => setSelectedTravelType(values)}
            style={{ width: "100%" }}
          >
            <Row>
              <Checkbox value="Domestic">Domestic</Checkbox>
              <Checkbox value="International">International</Checkbox>
            </Row>
          </Checkbox.Group>
        </Col>
      </Row>
      <Divider />
      <br />
      {isTableVisible && (
        <Table
          columns={columns}
          dataSource={allTravelRequests}
          className="custom-table col-lg-12 col-md-12 col-sm-12"
          sticky
        />
      )}
    </div>
  );
};

export default Travelreport;
