/*My Work->Employee details->View Employee*/
import React from "react";
import "./EmpDetails2.css";
import axios from "axios";
import {
  MailTwoTone,
  CalendarTwoTone,
  SlidersTwoTone,
  FileWordTwoTone,
  PhoneTwoTone,
  ContactsTwoTone,
  LoadingOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { Spin } from "antd";
import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { url } from "../../url/url";
import img from "../../images/img_avatar.png";
import Spinner from "../../spinner/spinner";
import { Avatar } from "antd";

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);

const UserDetails = () => {
  const [data, setData] = useState([]);
  const { email } = useParams();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const dbName = localStorage.getItem("dbName");
        console.log("email in fecth data in empdetails2", email);
        const response = await axios.get(
          `${url}/api/get/emplistbyid/${email}/${dbName}`
        );
        if (response.status === 200) {
          console.log("User list fetched:", response.data);
          setData(response.data);
          // setList(response.data);
          setLoading(false);
          // Handle the success scenario
        } else {
          console.log("Error:", response.status);
          // Handle the error scenario
        }
      } catch (error) {
        console.error("Error in catch:", error);
        // Handle network errors or exceptions
        setLoading(false);
      }
    };
    fetchData();
  }, [email]);
  let nameInitials = "";
  if (data && data.firstName && data.lastName) {
    nameInitials = `${data.firstName.charAt(0).toUpperCase()}${data.lastName
      .charAt(0)
      .toUpperCase()}`;
  } else {
    nameInitials = ""; // Handle the case where data or its properties are not defined
  }
  const profileImage = data.profileImage;
  return (
    <div style={{zoom:"90%"}}>
      {loading ? (
        <Spinner />
      ) : (
        <div className="col-lg-12 ">
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Link to="/home/employee">
              <div
                className="icon-container"
                style={{
                  fontSize: "23px",
                  color: "black",
                  transition: "background-color 0.2s",
                }}
              >
                <CloseOutlined className="close-icon" />
              </div>
            </Link>
          </div>
          <div className="maindiv">
            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <div
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
                    borderRadius: "10px",
                    padding: "20px",
                  }}
                >
                  <div className="text-center">
                    {profileImage ? ( // Check if the user has a profile image
                      <Avatar
                        size="large"
                        src={data.profileImage}
                        alt="profile"
                        className="profile mb-3"
                      />
                    ) : (
                      <Avatar
                        size="large"
                        style={{
                          backgroundColor: "#fde3cf",
                          color: "#f56a00",
                        }}
                      >
                        {nameInitials}
                      </Avatar>
                    )}
                    <h2 className="fw-bold text-uppercase ">
                      {data.displayName}
                    </h2>
                    <h6 className="fw-bold text-start">BASIC INFO</h6>
                  </div>
                  <div className="p-5">
                    <p>
                      <ContactsTwoTone />
                      <b> Employee Code:</b> {data.empCode}
                    </p>
                    <p>
                      <FileWordTwoTone />
                      <b> Employee Type:</b> {data.empType}
                    </p>
                    <p>
                      <FileWordTwoTone />
                      <b> Employee Status:</b> {data.empStatus}
                    </p>
                    <p>
                      <FileWordTwoTone />
                      <b> Department:</b> {data.department}
                    </p>
                    <p>
                      <SlidersTwoTone />
                      <b> Designation:</b>{" "}
                      <span className="text-uppercase">
                        {" "}
                        {data.empDesignation}{" "}
                      </span>
                    </p>
                    <p>
                      <MailTwoTone />
                      <b> Company Email:</b> {data.email}{" "}
                    </p>

                    <p>
                      <FileWordTwoTone />
                      <b> JobTitle:</b> {data.jobTitle}
                    </p>
                    <p>
                      <CalendarTwoTone />
                      <b> Date-of-Joining:</b> {data.joinDate}
                    </p>
                    <p>
                      <FileWordTwoTone />
                      <b> Years of experience:</b> {data.yearsofexp}
                    </p>
                    <p>
                      <FileWordTwoTone />
                      <b>  Company Laptop:</b> {data.companyLaptop}
                    </p>
                    <p>
                      <FileWordTwoTone />
                      <b> Laptop Serial Number:</b> {data.laptopSerial}
                    </p>
                    <p>
                      <FileWordTwoTone />
                      <b> Laptop Warranty Date:</b> {data.laptopWarranty}
                    </p>
                    <p>
                      <FileWordTwoTone />
                      <b> Accessories:</b> {data.accessaries ? data.accessaries.join(', ') : ''}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-sm-12 " style={{ padding: "20px" }}>
                <div
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
                    borderRadius: "10px",
                    padding: "20px",
                  }}
                >
                  <h6 className="mb-5 text-center">
                    <b>PERSONAL INFORMATION</b>
                  </h6>
                  <div>
                    <p>
                      <b>Date of Birth:</b> {data.dob}
                    </p>
                    <p>
                      <b>Gender:</b> {data.gender}
                    </p>
                    <p>
                      <b>Reporting Manager Name:</b> {data.manager}
                    </p>
                    <p>
                      <b>HR:</b> {data.hrName}
                    </p>
                    <p>
                      <b>Pan Number:</b> {data.pan}
                    </p>
                    <p>
                      <b>Aadhar Number:</b> {data.aadharNumber}
                    </p>
                    <p>
                      <b>Personal Email:</b> {data.personalemail}
                    </p>

                    <p className="text-break">
                      <b>Address:</b> {data.address}
                    </p>

                    <p>
                      <b>Aadhar Copy:</b>
                      <a
                        href={data.aadharUpload}
                        download="Aadhar_Copy.png"
                        className="download-link"
                      >
                        <span
                          className="download-icon"
                          role="img"
                          aria-label="Download"
                        >
                          📥
                        </span>
                        Download
                      </a>
                    </p>

                    <p>
                      <b>Pan Copy:</b>
                      <a
                        href={data.panCopy}
                        download="Pan_Copy.png"
                        className="download-link"
                      >
                        <span
                          className="download-icon"
                          role="img"
                          aria-label="Download"
                        >
                          📥
                        </span>
                        Download
                      </a>
                    </p>
                    <p>
                      <b> Mobile:</b> {data.phoneNumber}
                    </p>
                    <p>
                      <b> Alternate Mobile:</b> {data.alternateMobile}
                    </p>
                    <p>
                      <b> Emergency Contact:</b> {data.emergencyContact}
                    </p>
                    <p>
                      <b>Blood Group:</b> {data.bloodGroup}
                    </p>
                    <p>
                    <b>Employee Dependent:</b> {data.empDependents ? data.empDependents.join(', ') : ''}
                    </p>
                  </div>
                </div>

                <div
                  className="mb-4 "
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
                    borderRadius: "10px",
                    padding: "20px",
                    marginTop: "40px",
                  }}
                >
                  <h6 className="mb-5 text-center">
                    <b>BANK DETAILS</b>
                  </h6>
                  <div className="text-start mb-4">
                    <p>
                      <b>Account Number:</b> {data.accountNumber}
                    </p>
                    <p>
                      <b>Account Type:</b> {data.accountType}
                    </p>
                    <p>
                      <b>IFSC Code:</b> {data.ifscCode}
                    </p>
                    <p>
                      <b>Bank Name:</b> {data.bankName}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserDetails;