import React, { useState, useEffect } from "react";

import {
  Table,
  notification,
  Form,
  Modal,
  Select,
  Popconfirm,
  Image,
  Avatar,
  Space,
  Spin,
} from "antd";
import { url } from "../../url/url";
import axios from "axios";
import { Button, Input } from "antd";
import "../employee/employee.css";
import {
  PlusOutlined,
  SearchOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  EditFilled,
  EyeFilled,
  DeleteFilled,
  QuestionCircleOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
const { Search } = Input;
const { Option } = Select;

const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18,
  },
};

const InactiveEmp = (props) => {
  const [Users, setUsers] = useState([]);
  const [List, setList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const user = JSON.parse(localStorage.getItem("user"));
  const [loading, setLoading] = useState(false);
  const status = localStorage.getItem("status");
  const dbName = localStorage.getItem("dbName");
  const [records, setRecords] = useState([]);
  const [viewModalVisible, setViewModalVisible] = useState(false);

  const handleView = (record) => {
    setRecords(record);
    setViewModalVisible(true);
  };

  const handleViewModalCancel = () => {
    setViewModalVisible(false);
  };

  const shouldUpdate = (prevProps, nextProps) => {
    if (
      prevProps.dataSource === nextProps.dataSource &&
      prevProps.count === nextProps.count
    ) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      setLoading(true);
      const dbName = localStorage.getItem("dbName");
      const response = await axios.get(`${url}/api/get/allemplist/${dbName}`);
      setUsers(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredData = Users.filter((item) => {
    if (item.displayName && item.empStatus === "inactive") {
      return item.displayName.toLowerCase().includes(searchQuery.toLowerCase());
    }
    return false;
  });

  const columns = [
    {
      title: "Emp Id",
      dataIndex: "empCode",
      width: "100px",
      onFilter: (value, record) => record.empCode === value,
    },
    {
      title: "Profile",
      width: "50px",
      dataIndex: "profileImage",
      key: "profileImage",
      render: (profileImage, record) => {
        if (profileImage) {
          // If a profile image is available, display it.
          return (
            <div style={{ alignItems: "center" }}>
              {" "}
              <Avatar size="large" src={profileImage} />{" "}
            </div>
          );
        } else {
          // If there's no profile image, display initials of the first and last names.
          const nameInitials = `${record.firstName
            .charAt(0)
            .toUpperCase()}${record.lastName.charAt(0).toUpperCase()}`;
          return (
            <Avatar
              size="large"
              style={{
                backgroundColor: "#fde3cf",
                color: "#f56a00",
              }}
            >
              {nameInitials}
            </Avatar>
          );
        }
      },
    },

    {
      title: "Name",
      width: "150px",
      dataIndex: "displayName",
    },

    {
      title: "Email",
      width: "200px",
      dataIndex: "email",
    },
    {
      title: "Termination Date",
      width: "50px",
      dataIndex: "terminationDate",
    },

    {
      title: "View",
      dataIndex: "id",
      width: "50px",
      render: (_, record) => (
        <Link to={`/home/userdetails/${record.email}`}>
          <Button size="small">
            <EyeFilled />
          </Button>
        </Link>
      ),
    },
  ];

  return (
    <div style={{ transform: "scale(0.9)", transformOrigin: "0 0", width: "110%" }}>
      {loading ? (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <h2 className="text-center">Inactive Employee List</h2>

          <div
            className="tag"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              margin: "20px",
            }}
          >
            <div>
              <Input
                type="text"
                size="large"
                style={{ height: "40px", fontSize: "15px" }}
                placeholder="Search Employees..."
                value={searchQuery}
                onChange={handleSearchQueryChange}
              />
            </div>
          </div>
          <div style={{ width: "100%", overflowX: "auto" }}>
            <Table
              className="custom-table col-lg-12 col-md-12 col-sm-12"
              columns={columns}
              dataSource={filteredData}
              shouldComponentUpdate={shouldUpdate}
              scroll={{ x: true }}
              sticky
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default InactiveEmp;
