import axios from "axios";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import {
  Tabs,
  Table,
  Button,
  message,
  notification,
  List,
  Image,
  Input,
  Popconfirm,
  Modal,
  Spin,
  Tooltip,
} from "antd";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  DownloadOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { url } from "../../url/url";
import { decryptRole } from "../../encrypt";

const { TabPane } = Tabs;
const { Search } = Input;

function ClaimApproval(props) {
  // const [EmployeeData, setEmployeeData] = useState([]);
  const [days, setDays] = useState({});
  const [clickedRowId, setClickedRowId] = useState(null);
  const [file, setFile] = useState(null);
  const [files, setFiles] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const user = JSON.parse(localStorage.getItem("user"));
  const [visible1, setVisible1] = useState(false);
  const [pdfModalContent, setPdfModalContent] = useState([]);
  const [pdfModalVisible, setPdfModalVisible] = useState(false);
  const [selectedPdfIndex, setSelectedPdfIndex] = useState(0);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10); // Set an initial page size
  const [total, setTotal] = useState(); // Set the total based on your data
  const encryptedRoleFromStorage = localStorage.getItem("role");
  const jobTitle = decryptRole(encryptedRoleFromStorage);

  // const [loading, setLoading] = useState(false);
  // Handle page change
  const onPageChange = (newCurrent, newPageSize) => {
    setCurrent(newCurrent);
    setPageSize(newPageSize);
    // You may need to update the "total" based on your data or an API call here
  };

  const shouldUpdate = (prevProps, nextProps) => {
    if (
      prevProps.dataSource === nextProps.dataSource &&
      prevProps.count === nextProps.count
    ) {
      return false;
    }
    return true;
  };

  // useEffect(() => {
  //   fetchData();
  // }, []);
  // const fetchData = async () => {
  //   try {
  //     const dbName = localStorage.getItem("dbName");

  //     console.log("dbName in fetchData in claimapproval", dbName);
  //     if (jobTitle === "manager") {
  //       const res = await axios.get(
  //         `${url}/claim/approval/${user.displayName}/manager/${dbName}`
  //       );
  //       setEmployeeData(res.data);
  //       console.log(res.status);
  //       console.log(res.data);
  //     } else if (jobTitle === "hr" || jobTitle === "admin") {
  //       const res = await axios.get(
  //         `${url}/getallclaim/approval/${user.displayName}/${dbName}`
  //       );
  //       const combinedData = [
  //         ...res.data.submittedToHR,
  //         ...res.data.submittedByReportingManager,
  //       ];
  //       setEmployeeData(combinedData);
  //       // console.log(res.status);
  //       // console.log(res.data);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const fetchEmployeeData = async () => {
    const dbName = localStorage.getItem("dbName");

    if (jobTitle === "manager") {
      const res = await axios.get(
        `${url}/claim/approval/${user.displayName}/manager/${dbName}`
      );
      return res.data;
    } else if (jobTitle === "hr" || jobTitle === "admin") {
      const res = await axios.get(
        `${url}/getallclaim/approval/${user.displayName}/${dbName}`
      );
      const combinedData = [
        ...res.data.submittedToHR,
        ...res.data.submittedByReportingManager,
      ];
      return combinedData;
    }
  };

  const { data: EmployeeData = [], refetch, isLoading, isError } = useQuery(
    "employeeData",
    fetchEmployeeData
  );

  const empapr = async (ids, email, empName) => {
    const dbName = localStorage.getItem("dbName");

    if (jobTitle === "manager") {
      const res = await axios.put(`${url}/Claim/Capprove/${ids}/${dbName}`, {
        status: "Submitted to HR",
        email: email,
      });
      notification.success({ message: "Successfully updated" });
      const response = await axios.get(
        `${url}/get/managermail/${empName}/${dbName}`
      );
      const manager = response.data.email;
      console.log(manager, "manager");
    } else if (jobTitle === "hr" || jobTitle === "admin") {
      const res = await axios.put(`${url}/Claim/Capprove/${ids}/${dbName}`, {
        status: "Approved",
        email: email,
      });
      notification.success({ message: "Successfully updated" });
      const response = await axios.get(
        `${url}/get/managermail/${empName}/${dbName}`
      );
      const manager = response.data.email;
      const mailTriggerResponse = await axios.post(`${url}/status/mail`, {
        userName: user.displayName,
        email: manager,
        requestType: "claims approved",
      });
    }
    refetch();
  };
  const empdeny = async (ids, email, empName) => {
    const dbName = localStorage.getItem("dbName");

    if (jobTitle === "manager") {
      const res = await axios.put(`${url}/Claim/Capprove/${ids}/${dbName}`, {
        status: "Denied by Manager",
        email: email,
      });
      notification.success({ message: "Successfully updated" });
      // If the claim submission was successful, invoke the mail trigger API
      const response = await axios.get(
        `${url}/get/managermail/${empName}/${dbName}`
      );
      const manager = response.data.email;
    } else if (jobTitle === "hr" || jobTitle === "admin") {
      const res = await axios.put(`${url}/Claim/Capprove/${ids}/${dbName}`, {
        status: "Denied by HR",
        email: email,
      });
      notification.success({ message: "Successfully updated" });
      const response = await axios.get(
        `${url}/get/managermail/${empName}/${dbName}`
      );
      const manager = response.data.email;
      const mailTriggerResponse = await axios.post(`${url}/status/mail`, {
        userName: user.displayName,
        email: manager,
        requestType: "Claim rejected",
      });

      console.log(mailTriggerResponse);
    }
    refetch();
  };

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredData = EmployeeData.filter((item) =>
    item.empName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleApprove = (record) => {
    // Handle approve button click
    console.log("Approved:", record);
    console.log(days);
    empapr(record._id, record.email, record.empName);
    // setEmployeeData((prevData) =>
    //   prevData.filter((data) => data._id !== record._id)
    // );
  };

  const handleDeny = (record) => {
    console.log("Denied:", record);
    empdeny(record._id, record.email, record.empName);
    // setEmployeeData((prevData) =>
    //   prevData.filter((data) => data._id !== record._id)
    // );
  };

  function openPdfInModal(pdfDataUrls) {
    setPdfModalContent(pdfDataUrls);
    setPdfModalVisible(true);
  }

  function closePdfModal() {
    setPdfModalVisible(false);
    setSelectedPdfIndex(0);
  }

  function viewNextPdf() {
    if (selectedPdfIndex < pdfModalContent.length - 1) {
      setSelectedPdfIndex(selectedPdfIndex + 1);
    }
  }

  function viewPreviousPdf() {
    if (selectedPdfIndex > 0) {
      setSelectedPdfIndex(selectedPdfIndex - 1);
    }
  }

  function downloadPdf(pdfDataUrl) {
    const link = document.createElement("a");
    link.href = pdfDataUrl;
    link.download = "Claims Report.pdf"; // Specify the desired file name
    link.click();
  }

  const uniqueName = Array.from(
    new Set(EmployeeData.map((data) => data.empName))
  );
  const uniqueManager = Array.from(
    new Set(EmployeeData.map((data) => data.reportingmanager))
  );

  const columns = [
    {
      title: "Name",
      dataIndex: "empName",
      key: "empName",
      render: (text) => (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
      filters: uniqueName.map((displayName) => ({
        text: displayName,
        value: displayName,
      })),
      onFilter: (value, record) => record.empName === value,
    },
    {
      title: "Emp ID",
      dataIndex: "EmpID",
      key: "EmpID",
    },
    {
      title: "Manager",
      dataIndex: "reportingmanager",
      key: "reportingmanager",
      width: 100,
      render: (text) => (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
      filters: uniqueManager.map((displayName) => ({
        text: displayName,
        value: displayName,
      })),
      onFilter: (value, record) => record.reportingmanager === value,
    },
    {
      title: "Description",
      dataIndex: "expenseDescription",
      key: "expenseDescription",
      render: (text) => (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Expense Date",
      dataIndex: "dateOfExpense",
      key: "dateOfExpense",
      width: 150,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      width: 100,
    },
    {
      title: "Type",
      dataIndex: "claimType",
      key: "claimType",
      width: 100,
      render: (text) =>
        text === "Internet charge"
          ? "Internet"
          : text === "Travel Charge"
          ? "Travel"
          : text,
    },
    {
      title: "Receipt",
      render: (_, record) => (
        <div>
          {record.images && record.images.length > 0 ? (
            <Button
              onClick={() => openPdfInModal(record.images)}
              icon={<EyeOutlined />}
            />
          ) : (
            <>No files</>
          )}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 100,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <span style={{ whiteSpace: "nowrap" }}>
          <Popconfirm
            title="Are you sure want to approve ?"
            onConfirm={() => handleApprove(record)}
          >
            <Button style={{ color: "green" }} icon={<CheckCircleFilled />} />
          </Popconfirm>
          <Popconfirm
            title="Are you sure want to deny ?"
            onConfirm={() => handleDeny(record)}
          >
            <Button style={{ color: "red" }} icon={<CloseCircleFilled />} />
          </Popconfirm>
        </span>
      ),
    },
  ];

  const handleDownload = (image) => {
    // Convert the base64 image data to a Blob
    const byteString = atob(image.split(",")[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: "image/jpeg" });

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "image.jpg";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <div
      style={{ transform: "scale(0.9)", transformOrigin: "0 0", width: "110%" }}
    >
      <Modal
        title="Documents"
        visible={pdfModalVisible}
        onCancel={closePdfModal}
        footer={null}
        width={1000}
      >
        {pdfModalContent.length > 0 ? (
          <>
            <div style={{ textAlign: "center" }}>
              <Button
                disabled={selectedPdfIndex === 0}
                onClick={viewPreviousPdf}
                style={{ marginRight: "10px" }}
              >
                Previous
              </Button>
              <span>
                Document {selectedPdfIndex + 1} of {pdfModalContent.length}
              </span>
              <Button
                disabled={selectedPdfIndex === pdfModalContent.length - 1}
                onClick={viewNextPdf}
                style={{ marginLeft: "10px" }}
              >
                Next
              </Button>
            </div>
            <iframe
              src={pdfModalContent[selectedPdfIndex]}
              title="Document"
              style={{ width: "100%", height: "500px" }}
            />
          </>
        ) : (
          <>No PDF documents available</>
        )}
      </Modal>
      <Tabs defaultActiveKey="1" centered>
        <TabPane
          tab={
            <span style={{ color: "black", fontSize: "20px" }}>
              Claim Approval
            </span>
          }
        >
          <div
            className="tag"
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <div>
              <Input
                type="text"
                size="large"
                style={{ height: "40px", fontSize: "15px" }}
                placeholder="Search Employees..."
                value={searchQuery}
                onChange={handleSearchQueryChange}
              />
            </div>
          </div>
          <div style={{ width: "100%", overflowX: "auto" }}>
            <Table
              className="custom-table col-lg-12 col-md-12 col-sm-12"
              columns={columns}
              dataSource={filteredData}
              shouldComponentUpdate={shouldUpdate}
              sticky
              pagination={{
                current: current,
                pageSize: pageSize,
                total: total,
                onChange: onPageChange,
              }}
              bordered={false}
              scroll={{ x: true }}
              rowKey={(record) => record.id}
            />
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
}
export default ClaimApproval;
