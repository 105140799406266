import React, { useState } from "react";
import Managerdashboard from "./managerdashboard";
import Dashboard2 from "./dashboard2";
import Timer from "../pages/timer";
import { Tabs } from "antd";
import OrganizationTab from "./organization";
import Empdash from "./employeedashboard";
const { TabPane } = Tabs;

const Headertabs = () => {
  const users = JSON.parse(localStorage.getItem("user"));
  const [user] = useState(users);
  let propsForTimer = {
    user: user,
    component: <Timer />,
  };
  return (
    <div>
      <Tabs defaultActiveKey="myspace">
        <TabPane tab="My Space" key="myspace">
          <Managerdashboard />
        </TabPane>
         {/* {user.jobTitle === "employee"? (
          <TabPane tab="Dashboard" key="dashboard">
            <Empdash {...propsForTimer} />
          </TabPane>
        ):(
          <TabPane tab="Dashboard" key="dashboard">
          <Dashboard2 {...propsForTimer} />
        </TabPane>
        )} */}
        <TabPane tab="Organization" key="organization">
          <OrganizationTab />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Headertabs;
