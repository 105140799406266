import React, { useState, useEffect } from "react";
import {
  message,
  Select,
  Table,
  Row,
  Col,
  Empty,
  Button,
  Tabs,
  Modal,
  Input,
  Spin,
} from "antd";
import { url } from "../../url/url";
import axios from "axios";
import TimeSheetLog from "./timeSheetlog";
import EmployeeComplianceReport from "./EmployeeComplianceReport";
import TimeSheetlist from "./timeSheetList";
import MonthlyTimesheet from "./Monthlytimesheet";
import Totaltask from "./totaltask";
import Papa from "papaparse";
import FileSaver from "file-saver";
const { Option } = Select;
const { TabPane } = Tabs;

const TimeSheetSummary = () => {
  const users = JSON.parse(localStorage.getItem("user"));
  const [user] = useState(users);
  const [projData, setProjData] = useState([]);
  const [table, setTable] = useState([]);
  const [loading, setLoading] = useState(false);
  const [Image1, setImage1] = useState([]);
  const [weekIDs, setWeekIDs] = useState([]);
  const [employeeComplianceData, setEmployeeComplianceData] = useState([]);
  const [weekPeriods, setWeekPeriods] = useState(null);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [Columns, setColumns] = useState([]);
  const [visible, setVisible] = useState(false);
  const [showAllReports, setShowAllReports] = useState(false);
  const [taskOptions, setTaskOptions] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [total, setTotal] = useState(table.length);

  const onPageChange = (newCurrent, newPageSize) => {
    setCurrent(newCurrent);
    setPageSize(newPageSize);
  };

  useEffect(() => {
    fetchData();
    fetchtable();
    fetchLogo();
    fetchtask();
  }, [showAllReports]);

  const fetchLogo = async () => {
    try {
      const dbName = localStorage.getItem("dbName");
      const response = await axios.get(`${url}/admin/get/logo/${dbName}`);
      setImage1(response.data[0].image);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const dbName = localStorage.getItem("dbName");
      const response = await fetch(`${url}/api/projectsdetails/${dbName}`);
      const projdata = await response.json();
      setProjData(projdata);
      setLoading(false);
    } catch (error) {
      console.error(error);
      message.error("Failed to fetch data from the server.");
      setLoading(false);
    }
  };
  const fetchtask = async () => {
    try {
      const dbName = localStorage.getItem("dbName");
      const response = await axios.get(`${url}/api/newtask/${dbName}`);
      const taskData = response.data;
      console.log(taskData, "taskData");
      setTaskOptions(taskData); // Set the task options from the fetched data
    } catch (error) {
      console.error(error);
      message.error("Failed to fetch data from the server.");
    }
  };

  const fetchtable = async () => {
    setLoading(true);
    try {
      if (!showAllReports) {
        console.log("inside if");
        // Fetch manager reportee details
        const dbName = localStorage.getItem("dbName");
        const response = await fetch(
          `${url}/timesheet/getreportgen/${user.displayName}/manager/${dbName}`
        );
        const table = await response.json();
        setTable(table);
        console.log(table, "table");
        setLoading(false);
      } else if (showAllReports && user.jobTitle === "hr"){
        console.log("inside else");
        const dbName = localStorage.getItem("dbName");
        const response = await fetch(`${url}/timesheet/getreportgen/${user.displayName}/hr/${dbName}`);
        const table = await response.json();
        setTable(table);
        setLoading(false);
      } else if (showAllReports && user.jobTitle === "admin"){
        console.log("inside else");
        const dbName = localStorage.getItem("dbName");
        const response = await fetch(`${url}/timesheet/getreportgen/${user.displayName}/admin/${dbName}`);
        const table = await response.json();
        setTable(table);
        setLoading(false);
      }
      // Extract and set week periods
      const weekPeriods = table.map((record) => ({
        weekPeriod: `${record.weekStartingDate} - ${record.weekEndingDate}`,
        startDate: new Date(
          record.weekStartingDate.split("-").reverse().join("-")
        ),
      }));

      // Sort weekPeriods based on startDate
      weekPeriods.sort((a, b) => a.startDate - b.startDate);

      // Map the sorted weekPeriods back to an array of weekPeriods
      const sortedWeekPeriods = weekPeriods.map((record) => record.weekPeriod);

      setWeekPeriods(sortedWeekPeriods);
    } catch (error) {
      console.error(error);
      message.error("Failed to fetch data from the server.");
      setLoading(false);
    }
  };

  // const handleWeekIDChange = (value) => {
  //   // Set the selected week period as an array
  //   setWeekPeriods([value]);
  //   fetchEmployeeComplianceData(value);
  // };

  const transformDataForTable = (tableData) => {
    const newData = {};

    tableData.forEach((record) => {
      if (Array.isArray(record.entrydata)) {
        record.entrydata.forEach((entry) => {
          if (record.weekStartingDate) {
            const dateParts = record.weekStartingDate.split("-");
            const month = parseInt(dateParts[1], 10) - 1; // Months in JavaScript are 0-based (0 = January, 11 = December)
            const key = `${entry.project}_${entry.task}_${record.empCode}_${month}`;

            if (!newData[key]) {
              newData[key] = {
                project: entry.project,
                userName: record.userName,
                task: entry.task,
                empCode: record.empCode,
                weekStartingDate: record.weekStartingDate,
                totalhours: parseFloat(entry.totalhours),
                month: month, // Add the month to the data
              };
            } else {
              newData[key].totalhours += parseFloat(entry.totalhours);
            }
          }
        });
      }
    });

    // Convert the object values to an array
    return Object.values(newData);
  };

  const Data = table.filter((record) => {
    const recordValues = Object.values(record);
    return recordValues.some((value) =>
      String(value).toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  const tableDataForAntd = transformDataForTable(Data);

  const uniqueEmpCodes = Array.from(
    new Set(table.map((record) => record.empCode))
  );
  const uniqueEmpNames = Array.from(
    new Set(table.map((record) => record.userName))
  );
  const uniquetask = Array.from(new Set(table.map((record) => record.task)));

  const columns = [
    {
      title: "Month",
      dataIndex: "month",
      render: (_, record) => {
        if (record.weekStartingDate) {
          const dateParts = record.weekStartingDate.split("-");
          const day = parseInt(dateParts[0], 10);
          const month = parseInt(dateParts[1], 10) - 1; // Months in JavaScript are 0-based (0 = January, 11 = December)
          const year = parseInt(dateParts[2], 10);

          const startDate = new Date(year, month, day);
          const monthName = startDate.toLocaleString("en-us", {
            month: "long",
          });
          return `${monthName}`;
        } else {
          return "No Date Available";
        }
      },
      filters: [
        { text: "January", value: "January" },
        { text: "February", value: "February" },
        { text: "March", value: "March" },
        { text: "April", value: "April" },
        { text: "May", value: "May" },
        { text: "June", value: "June" },
        { text: "July", value: "July" },
        { text: "August", value: "August" },
        { text: "September", value: "September" },
        { text: "October", value: "October" },
        { text: "November", value: "November" },
        { text: "December", value: "December" },
      ],
      onFilter: (value, record) => {
        const dateParts = record.weekStartingDate.split("-");
        const month = parseInt(dateParts[1], 10) - 1;
        const startDate = new Date(dateParts[2], month, dateParts[0]);
        const monthName = startDate.toLocaleString("en-us", { month: "long" });
        return monthName === value;
      },
    },

    {
      title: "Project",
      dataIndex: "project",
      sorter: (a, b) => a.project.localeCompare(b.project),
      filters: projData.map((proj) => ({
        text: proj.ProjectName,
        value: proj.ProjectName,
      })),
      onFilter: (value, record) => record.project === value,
    },

    {
      title: "Employee Id",
      dataIndex: "empCode",
      filters: uniqueEmpCodes.map((empCode) => ({
        text: empCode,
        value: empCode,
      })),
      onFilter: (value, record) => record.empCode === value,
      sorter: (a, b) => a.empCode.localeCompare(b.empCode),
    },
    {
      title: "Employee Name",
      dataIndex: "userName",
      filters: uniqueEmpNames.map((userName) => ({
        text: userName,
        value: userName,
      })),
      onFilter: (value, record) => record.userName === value,
      sorter: (a, b) => a.userName.localeCompare(b.userName),
    },
    {
      title: "Task",
      dataIndex: "task",
      sorter: (a, b) => a.task.localeCompare(b.task),
      filters: taskOptions.map((task) => ({
        text: task.task,
        value: task.task,
      })), // Use the taskOptions state for filter options
      onFilter: (value, record) => record.task === value,
    },

    {
      title: "Total Hours",
      dataIndex: "totalhours",
      sorter: (a, b) => a.totalhours - b.totalhours,
      align: "center",
    },
  ];

  function handleClick() {
    const csv = Papa.unparse(tableDataForAntd);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    FileSaver.saveAs(blob, "Timesheetsummary.csv", "C:");
  }

  const exportCsvButton = (
    <div
      className="tag"
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Button style={{bottom:"6vh"}} className="button" type="primary" onClick={handleClick}>
        Export
      </Button>
    </div>
  );

  const toggleShowAllReports = () => {
    setShowAllReports(!showAllReports);
  };
  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <div style={{transform: "scale(0.9)", transformOrigin: "0 0",width:"110%"}}>
      {loading ? (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <>
          <div className="reports" id="download">
            <h4>TimeSheet Report</h4>
            <Tabs defaultActiveKey="1">
              <TabPane tab="TimeSheet Summary" key="1">
                <div>
                  <label
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      fontSize: "14px",
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={showAllReports}
                      onChange={toggleShowAllReports}
                    />
                    Show All Employee
                  </label>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                  }}
                >
                  <div>{exportCsvButton}</div>
                  <Input
                    type="text"
                    size="large"
                    style={{ height: "40px", fontSize: "15px", width: "250px" }}
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={handleSearchQueryChange}
                  />
                </div>
                <Table
                  className="custom-table col-lg-12 col-md-12 col-sm-12"
                  columns={columns}
                  dataSource={tableDataForAntd}
                  pagination={{
                    current: current,
                    pageSize: pageSize,
                    total: total,
                    onChange: onPageChange,
                  }}
                  sticky
                />
              </TabPane>
              <TabPane tab="Timesheet Detailed Log" key="2">
                <TimeSheetLog tableData={table} loading={loading} />
              </TabPane>

              <TabPane tab="Daywise Timesheet" key="3">
                <MonthlyTimesheet />
              </TabPane>

              <TabPane tab="Daywise Summary" key="4">
                <Totaltask />
              </TabPane>

              <TabPane tab="Timesheet Report" key="5">
                <TimeSheetlist data={user} />
              </TabPane>

              <TabPane tab="Employee Compliance Report" key="6">
                <EmployeeComplianceReport />
              </TabPane>
            </Tabs>
          </div>
        </>
      )}
    </div>
  );
};

export default TimeSheetSummary;
