// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@1200&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.details {
    border-radius: 20px;
    margin: 10px;
    padding: 20px;
    font-family: 'Poppins', sans-serif;
    z-index: 0; 
}

.contnt{
    display: flex;
    padding: 70px;
    justify-content: center;
    
}
.loaderhead {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
}

.loader {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 5px solid rgba(0, 0, 0, 0.1);
    border-top-color: #3498db;
    animation: spin 1s ease-in-out infinite;
}

.quick--link:hover {
    background-color: #090639;
    color: #f8f4f4;
    box-shadow: rgba(252, 206, 0, 0.612) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(255, 235, 18, 0.653) 0px -2px 6px 0px inset;
    transition: all 0.2s ease-in;
  }

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}`, "",{"version":3,"sources":["webpack://./src/dashboard/dashboard.css"],"names":[],"mappings":"AAEA;IACI,mBAAmB;IACnB,YAAY;IACZ,aAAa;IACb,kCAAkC;IAClC,UAAU;AACd;;AAEA;IACI,aAAa;IACb,aAAa;IACb,uBAAuB;;AAE3B;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,oCAAoC;IACpC,yBAAyB;IACzB,uCAAuC;AAC3C;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,mJAAmJ;IACnJ,4BAA4B;EAC9B;;AAEF;IACI;QACI,yBAAyB;IAC7B;AACJ","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@1200&display=swap');\n\n.details {\n    border-radius: 20px;\n    margin: 10px;\n    padding: 20px;\n    font-family: 'Poppins', sans-serif;\n    z-index: 0; \n}\n\n.contnt{\n    display: flex;\n    padding: 70px;\n    justify-content: center;\n    \n}\n.loaderhead {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 99;\n}\n\n.loader {\n    width: 30px;\n    height: 30px;\n    border-radius: 50%;\n    border: 5px solid rgba(0, 0, 0, 0.1);\n    border-top-color: #3498db;\n    animation: spin 1s ease-in-out infinite;\n}\n\n.quick--link:hover {\n    background-color: #090639;\n    color: #f8f4f4;\n    box-shadow: rgba(252, 206, 0, 0.612) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(255, 235, 18, 0.653) 0px -2px 6px 0px inset;\n    transition: all 0.2s ease-in;\n  }\n\n@keyframes spin {\n    to {\n        transform: rotate(360deg);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
