// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* EmployeeForm.css */
.employee-form {
  max-width: auto;
  margin: auto;
  /*margin: 20px;*/
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.submit-button {
  float: right;
  margin-top: 20px;
}

.form-section {
  padding: 20px;
  border: 2px solid #f0f0f0;
  border-radius: 5px;
  margin-bottom: 20px;
}

.custom-file-upload {
  display: inline-block;
  padding: 10px;
  color: #0f0f0f;
  cursor: pointer;
  border-radius: 4px;
}

.custom-file-upload input[type="file"] {
  display: none;
}

.custom-file-upload span {
  margin-left: 1px;
}

/* Style the label to look like a button */`, "",{"version":3,"sources":["webpack://./src/pages/employee/EmployeeForm.css"],"names":[],"mappings":"AAAA,qBAAqB;AACrB;EACE,eAAe;EACf,YAAY;EACZ,gBAAgB;EAChB,aAAa;EACb,wCAAwC;EACxC,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,aAAa;EACb,cAAc;EACd,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;AAClB;;AAEA,0CAA0C","sourcesContent":["/* EmployeeForm.css */\n.employee-form {\n  max-width: auto;\n  margin: auto;\n  /*margin: 20px;*/\n  padding: 20px;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  border-radius: 8px;\n}\n\n.submit-button {\n  float: right;\n  margin-top: 20px;\n}\n\n.form-section {\n  padding: 20px;\n  border: 2px solid #f0f0f0;\n  border-radius: 5px;\n  margin-bottom: 20px;\n}\n\n.custom-file-upload {\n  display: inline-block;\n  padding: 10px;\n  color: #0f0f0f;\n  cursor: pointer;\n  border-radius: 4px;\n}\n\n.custom-file-upload input[type=\"file\"] {\n  display: none;\n}\n\n.custom-file-upload span {\n  margin-left: 1px;\n}\n\n/* Style the label to look like a button */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
