// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loaderhead {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  height: 100vh;
}

.loader {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-top-color: #3498db;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
      transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .button {
    font-size: 14px; 
  }
  
  .col-content {
    font-size: 16px;
  }
}
/* styles.css */
/* styles.css */
.custom-form {
width: 100%;
font-size: 16px;
}

.custom-form .ant-form-item-label {
font-size: 16px;
margin-right: 16px; /* Add margin to the right of the label */
}

.custom-form .ant-input,
.custom-form .ant-select-selector {
font-size: 16px;
width: 100%;
}

.custom-form .ant-select-selector {
width: 100%;
}
.ant-table-cell {
  max-height: 200px; 
  max-width: 200px;
  overflow: hidden; 
  text-overflow: ellipsis; 
  white-space: nowrap;
}

.fixed-height-row {
  height: 60px; 
}

.custom-row {
  cursor: pointer; 
}

`, "",{"version":3,"sources":["webpack://./src/pages/employee/employee.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,aAAa;AACf;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,oCAAoC;EACpC,yBAAyB;EACzB,uCAAuC;AACzC;;AAEA;EACE;MACI,yBAAyB;EAC7B;AACF;;AAEA;EACE;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB;AACF;AACA,eAAe;AACf,eAAe;AACf;AACA,WAAW;AACX,eAAe;AACf;;AAEA;AACA,eAAe;AACf,kBAAkB,EAAE,yCAAyC;AAC7D;;AAEA;;AAEA,eAAe;AACf,WAAW;AACX;;AAEA;AACA,WAAW;AACX;AACA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,gBAAgB;EAChB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".loaderhead {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 99;\n  height: 100vh;\n}\n\n.loader {\n  width: 30px;\n  height: 30px;\n  border-radius: 50%;\n  border: 5px solid rgba(0, 0, 0, 0.1);\n  border-top-color: #3498db;\n  animation: spin 1s ease-in-out infinite;\n}\n\n@keyframes spin {\n  to {\n      transform: rotate(360deg);\n  }\n}\n\n@media (max-width: 768px) {\n  .button {\n    font-size: 14px; \n  }\n  \n  .col-content {\n    font-size: 16px;\n  }\n}\n/* styles.css */\n/* styles.css */\n.custom-form {\nwidth: 100%;\nfont-size: 16px;\n}\n\n.custom-form .ant-form-item-label {\nfont-size: 16px;\nmargin-right: 16px; /* Add margin to the right of the label */\n}\n\n.custom-form .ant-input,\n.custom-form .ant-select-selector {\nfont-size: 16px;\nwidth: 100%;\n}\n\n.custom-form .ant-select-selector {\nwidth: 100%;\n}\n.ant-table-cell {\n  max-height: 200px; \n  max-width: 200px;\n  overflow: hidden; \n  text-overflow: ellipsis; \n  white-space: nowrap;\n}\n\n.fixed-height-row {\n  height: 60px; \n}\n\n.custom-row {\n  cursor: pointer; \n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
