import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Table,
  Input,
  Button,
  Avatar,
  Modal,
  Form,
  Select,
  message,
  notification,
  Card,
  Tabs,
} from "antd";
import { url } from "../../url/url";
import {
  EditFilled,
  EyeFilled,
  EyeOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Divider } from "@mui/material";
import RaiseIssue from "./empquery";
const { Search } = Input;
const { TabPane } = Tabs;
const ExpandableTable = () => {
  const [queryReports, setQueryReports] = useState([]);
  const [archivedReports, setArchivedReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    UniqueId: "",
    MailId: "",
    Status: "",
    AssignedTo: "",
    CreatedOn: "",
    UpdatedOn: "",
  });
  const [selectedHeading, setSelectedHeading] = useState("");
  const [selectedMessage, setSelectedMessage] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const [editingRecord, setEditingRecord] = useState("");
  const [users, setUsers] = useState([]);
  const Users = JSON.parse(localStorage.getItem("user"));
  const [user] = useState(Users);
  const [form] = Form.useForm();
  const [activeTab, setActiveTab] = useState("queryReports");
  const [operationType, setOperationType] = useState("");
  const [openImage, setOpenImage] = useState(false);
  const [attachment, setAttachment] = useState("");
  const [count, setCount] = useState(0);
  const [data, setData] = useState([]);
  const showImage = () => {
    setOpenImage(true);
  };

  const handlecancelImage = () => {
    setOpenImage(false);
  };
  useEffect(() => {
    fetchQueryReports();
    fetchArchivedReports();
    fetchUsers();
  }, [filters, editingRecord, activeTab]);

  const fetchQueryReports = async () => {
    const dbName = localStorage.getItem("dbName");
    let Response;
    if (user.jobTitle === "admin") {
      Response = await axios.get(`${url}/queryReports/Internal/${dbName}`, {
        params: filters,
      });
    } else {
      Response = await axios.get(
        `${url}/queryReports/Finaran Support/${dbName}`,
        { params: filters }
      );
    }
    const combinedResponse = [Response.data];

    setQueryReports(Response.data);
  };
  const fetchArchivedReports = async () => {
    const dbName = localStorage.getItem("dbName");
    let Response;
    if (user.jobTitle === "admin") {
      Response = await axios.get(
        `${url}/queryReports/Internal/${dbName}?archived=true`,
        { params: filters }
      );
    } else {
      Response = await axios.get(
        `${url}/queryReports/Finaran Support/${dbName}?archived=true`,
        { params: filters }
      );
    }
    const combinedResponse = [Response.data];
    setArchivedReports(Response.data);
    setLoading(false);
  };
  const columns = [
    {
      title: "Unique ID",
      dataIndex: "UniqueId",
      key: "UniqueId",
      width: 100,
    },
    {
      title: "Mail ID",
      dataIndex: "MailId",
      key: "MailId",
      width: 200,
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
      width: 100,
    },
    {
      title: "Assigned To",
      dataIndex: "AssignedTo",
      key: "AssignedTo",
      width: 150,
    },
    {
      title: "Priority",
      dataIndex: "Priority",
      key: "Priority",
      width: 100,
    },
    {
      title: "Created On",
      dataIndex: "CreatedOn",
      key: "CreatedOn",
      width: 200,
      render: (text) => formatDate(text),
    },
    {
      title: "Updated On",
      dataIndex: "UpdatedOn",
      key: "UpdatedOn",
      width: 200,
      render: (text) => formatDate(text),
    },
    {
      title: "Edit",
      dataIndex: "action",
      width: 100,
      render: (_, record) => (
        <Button
          icon={<EditFilled />}
          onClick={() => handleModalVisible(record, "edit")}
        />
      ),
    },
  ];

  const archivedColumns = [
    {
      title: "Unique ID",
      dataIndex: "UniqueId",
      key: "UniqueId",
      width: 100,
    },
    {
      title: "Mail ID",
      dataIndex: "MailId",
      key: "MailId",
      width: 200,
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
      width: 100,
    },
    {
      title: "Assigned To",
      dataIndex: "AssignedTo",
      key: "AssignedTo",
      width: 150,
    },
    {
      title: "Priority",
      dataIndex: "Priority",
      key: "Priority",
      width: 100,
    },
    {
      title: "Created On",
      dataIndex: "CreatedOn",
      key: "CreatedOn",
      width: 200,
      render: (text) => formatDate(text),
    },
    {
      title: "Updated On",
      dataIndex: "UpdatedOn",
      key: "UpdatedOn",
      width: 200,
      render: (text) => formatDate(text),
    },
    {
      title: "View",
      dataIndex: "view",
      width: 350,
      key: "view",
      render: (_, record) => (
        <Button
          type="link"
          icon={<EyeFilled />}
          onClick={() => handleView(record)}
        />
      ),
    },
  ];

  const handleView = (record) => {
    handleModalVisible(record, "view");
  };

  const handleUniqueIdClick = (record) => {
    setSelectedHeading(record.Content.Heading);
    setSelectedMessage(record.Content.Message);
  };
  const fetchAttachment = async (dbName, type, ticketId, messageId) => {
    try {
      console.log(dbName, type, ticketId, messageId, "attah");
      const encodedTicket = encodeURIComponent(ticketId);
      const encodedMessage = encodeURIComponent(messageId);
      const response = await axios.get(
        `${url}/attachment/${type}/${dbName}/${encodedTicket}/${encodedMessage}`
      );
      console.log(response.data.attachment, "attachmentinside");
      return response.data.attachment;
    } catch (error) {
      console.log("Error fetching attachment:", error);
      return null;
    }
  };
  const handleViewAttachment = async (type, ticketId, messageId) => {
    let dbName;
    console.log("inside handleview attachment", type, ticketId, messageId);
    if (type === "Internal") {
      dbName = localStorage.getItem("dbName");
    } else if (type === "Finaran Support") {
      dbName = "Master";
    }
    const attachment = await fetchAttachment(dbName, type, ticketId, messageId);
    console.log(attachment, "attachment");
    setAttachment(attachment);
    showImage();
    setCount(count + 1);
    if (attachment) {
      // Display or use the attachment as needed
      console.log("Attachment fetched:", attachment);
    }
  };
  const formatDate = (dateString) => {
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  // const formatDate = new Date().toISOString();
  console.log(formatDate, "formatDate");

  const handleModalVisible = (record, type) => {
    console.log(record, "record");
    setOperationType(type);
    handleUniqueIdClick(record);
    setSelectedReport(record);
    setIsModalVisible(true);
    setData(record);
    setFilters({ ...filters, UniqueId: record.UniqueId });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleFormSubmit = async (values) => {
    try {
      console.log(values);
      console.log(filters.UniqueId, "filters.UniqueId");
      const dbName = localStorage.getItem("dbName");
      // Separate displayName and email from the selected value
      const selectedUser = values.AssignedTo.split("(");
      const assignedTo = selectedUser[0].trim();
      const email = selectedUser[1].replace(")", "").trim();
      console.log(filters.UniqueId, "filters.UniqueId");
      // const assignedTo = values.AssignedTo;
      const priority = values.Priority;
      const uniqueId = filters.UniqueId;
      const rows = queryReports.filter((item) => item.UniqueId === uniqueId);
      const type = rows[0].type;
      console.log(type, rows, "type");

      const internal = await axios.put(
        `${url}/adminassignStatus/${type}/${dbName}`,
        {
          AssignedTo: assignedTo,
          Priority: priority,
          ReplierMail: email,
          uniqueId: uniqueId,
        }
      );

      setEditingRecord(null);
      setIsModalVisible(false);
      notification.success({ message: "Record updated successfully!" });
      form.resetFields();
      form.setFieldsValue({
        AssignedTo: undefined,
        Priority: undefined,
      });
    } catch (error) {
      console.error(error);
      message.error("Failed to update the record.");
    }
  };

  const fetchUsers = async () => {
    const dbName = localStorage.getItem("dbName");
    console.log("dbName in fetchUsers in addproject", dbName);
    const response = await fetch(`${url}/api/get/allemplist/${dbName}`);
    const data = await response.json();
    setUsers(data);
  };

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  return (
    <div
      style={{
        transform: "scale(0.9)",
        transformOrigin: "0 0",
        width: "110%",
      }}
    >
      <Tabs defaultActiveKey="queryReports" onChange={handleTabChange}>
        {user.jobTitle !== "superAdmin" && (
          <TabPane tab="Query Reports" key="queryReports">
            <Table
              columns={columns}
              dataSource={queryReports}
              loading={loading}
              pagination={{ pageSize: 10 }}
              sticky
            />
          </TabPane>
        )}
        {user.jobTitle === "superAdmin" && (
          <TabPane tab="Raised Issues">
            <RaiseIssue />
          </TabPane>
        )}
        <TabPane tab="Archived Reports" key="archivedReports">
          <Table
            columns={archivedColumns}
            dataSource={archivedReports}
            loading={loading}
            pagination={{ pageSize: 10 }}
            sticky
          />
        </TabPane>
      </Tabs>

      <Modal onCancel={handleCancel} visible={isModalVisible} footer={null}>
        <Card
          className="text-center"
          title={`${operationType === "edit" ? "Update" : "View"} ticket ${
            filters.UniqueId
          }`}
        >
          <div style={{ maxHeight: "200px", overflowY: "auto" }}>
            {Array.isArray(selectedMessage) &&
              selectedMessage.map((message, index) => (
                <div
                  className="text-left"
                  key={message._id}
                  style={{ marginBottom: "16px" }}
                >
                  <p>
                    <Avatar icon={<UserOutlined />} /> {message.senderemail}
                  </p>
                  <p>
                    <strong></strong> {message.text}
                  </p>

                  {message.hasAttachment && (
                    <Button
                      type="primary"
                      onClick={() =>
                        handleViewAttachment(
                          data.type,
                          data.UniqueId,
                          message._id
                        )
                      }
                      icon={<EyeOutlined />}
                    >
                      View
                    </Button>
                  )}

                  {index !== selectedMessage.length - 1 && <Divider />}
                  <Modal
                    visible={openImage}
                    onCancel={handlecancelImage}
                    footer={null}
                    title="View Attachment"
                  >
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <img
                        src={attachment}
                        alt="Attachment"
                        style={{
                          width: "40vh",
                          height: "40vh",
                        }}
                      />
                      {console.log(attachment, "attachmessage")}
                    </div>
                  </Modal>
                </div>
              ))}
          </div>
        </Card>
        <br />
        <Form form={form} onFinish={handleFormSubmit}>
          {operationType === "edit" && (
            <>
              <Form.Item
                label="AssignTo"
                name="AssignedTo"
                labelCol={{
                  span: 4,
                }}
                wrapperCol={{
                  span: 16,
                }}
                initialValue={selectedReport.AssignedTo}
                rules={[
                  {
                    required: true,
                    message: "Please select an assignee.",
                  },
                ]}
              >
                <Select
                  allowClear
                  name="AssignedTo"
                  placeholder="Please select"
                  size="large"
                >
                  {users.map((user) => (
                    <option
                      key={user.id}
                      value={`${user.displayName}(${user.email})`}
                    >
                      {user.displayName}({user.email})
                    </option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Priority"
                name="Priority"
                labelCol={{
                  span: 4,
                }}
                wrapperCol={{
                  span: 16,
                }}
                initialValue={selectedReport.Priority}
                rules={[
                  {
                    required: true,
                    message: "Please select a priority.",
                  },
                ]}
              >
                <Select size="large" placeholder="Please select">
                  <Select.Option value="Low">Low</Select.Option>
                  <Select.Option value="Medium">Medium</Select.Option>
                  <Select.Option value="High">High</Select.Option>
                </Select>
              </Form.Item>

              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </>
          )}
        </Form>
      </Modal>
    </div>
  );
};

export default ExpandableTable;
