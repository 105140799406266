import { React, useState, useCallback, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LineChart,
  Line,
  Rectangle,
  ResponsiveContainer,
  Customized,
} from "recharts";
import axios from "axios";
import { url } from "../../url/url";
const data = [
  {
    sprint: "Sprint 1",
    status: "todo",
    startdate: 3,
    enddate: 5,
    task: "Task 1",
    actualHours: 9,
    estimatedHours: 10,
  },
  {
    sprint: "Sprint 1",
    status: "todo",
    startdate: 3,
    enddate: 5,
    task: "Task 2",
    actualHours: 9,
    estimatedHours: 8,
  },
  {
    sprint: "Sprint 1",
    status: "todo",
    startdate: 3,
    enddate: 5,
    task: "Task 3",
    actualHours: 5,
    estimatedHours: 5,
  },
  {
    sprint: "Sprint 1",
    status: "progress",
    startdate: 2,
    enddate: 8,
    task: "Task 4",
    actualHours: 9,
    estimatedHours: 12,
  },
  {
    sprint: "Sprint 1",
    status: "done",
    startdate: 6,
    enddate: 10,
    task: "Task 5",
    actualHours: 7,
    estimatedHours: 6,
  },
  {
    sprint: "Sprint 1",
    status: "done",
    startdate: 6,
    enddate: 10,
    task: "Task 6",
    actualHours: 4,
    estimatedHours: 4,
  },
];

const data1 = [
  {
    sprint: "Sprint 1",
    status: "todo",
    startdate: 3,
    enddate: 5,
    task: "Task 1",
    actualHours: 9,
    estimatedHours: 10,
  },
  {
    sprint: "Sprint 1",
    status: "todo",
    startdate: 3,
    enddate: 5,
    task: "Task 2",
    actualHours: 9,
    estimatedHours: 8,
  },
  {
    sprint: "Sprint 1",
    status: "todo",
    startdate: 3,
    enddate: 5,
    task: "Task 3",
    actualHours: 5,
    estimatedHours: 5,
  },
  {
    sprint: "Sprint 2",
    status: "progress",
    startdate: 2,
    enddate: 8,
    task: "Task 1",
    actualHours: 9,
    estimatedHours: 12,
  },
  {
    sprint: "Sprint 2",
    status: "done",
    startdate: 6,
    enddate: 10,
    task: "Task 2",
    actualHours: 7,
    estimatedHours: 6,
  },
  {
    sprint: "Sprint 2",
    status: "done",
    startdate: 6,
    enddate: 10,
    task: "Task 3",
    actualHours: 4,
    estimatedHours: 4,
  },
  {
    sprint: "Sprint 3",
    status: "todo",
    startdate: 3,
    enddate: 5,
    task: "Task 1",
    actualHours: 9,
    estimatedHours: 10,
  },
  {
    sprint: "Sprint 3",
    status: "todo",
    startdate: 3,
    enddate: 5,
    task: "Task 2",
    actualHours: 9,
    estimatedHours: 8,
  },
  {
    sprint: "Sprint 3",
    status: "todo",
    startdate: 3,
    enddate: 5,
    task: "Task 3",
    actualHours: 5,
    estimatedHours: 5,
  },
  {
    sprint: "Sprint 4",
    status: "progress",
    startdate: 2,
    enddate: 8,
    task: "Task 1",
    actualHours: 9,
    estimatedHours: 12,
  },
  {
    sprint: "Sprint 5",
    status: "done",
    startdate: 6,
    enddate: 10,
    task: "Task 1",
    actualHours: 7,
    estimatedHours: 6,
  },
  {
    sprint: "Sprint 5",
    status: "done",
    startdate: 6,
    enddate: 10,
    task: "Task 2",
    actualHours: 4,
    estimatedHours: 4,
  },
  {
    sprint: "Sprint 5",
    status: "todo",
    startdate: 3,
    enddate: 5,
    task: "Task 3",
    actualHours: 9,
    estimatedHours: 10,
  },
  {
    sprint: "Sprint 6",
    status: "todo",
    startdate: 3,
    enddate: 5,
    task: "Task 1",
    actualHours: 9,
    estimatedHours: 8,
  },
  {
    sprint: "Sprint 6",
    status: "todo",
    startdate: 3,
    enddate: 5,
    task: "Task 2",
    actualHours: 5,
    estimatedHours: 5,
  },
  {
    sprint: "Sprint 7",
    status: "progress",
    startdate: 2,
    enddate: 8,
    task: "Task 1",
    actualHours: 9,
    estimatedHours: 12,
  },
  {
    sprint: "Sprint 7",
    status: "done",
    startdate: 6,
    enddate: 10,
    task: "Task 2",
    actualHours: 7,
    estimatedHours: 6,
  },
  {
    sprint: "Sprint 8",
    status: "done",
    startdate: 6,
    enddate: 10,
    task: "Task 1",
    actualHours: 4,
    estimatedHours: 4,
  },
];

const data2 = [
  { name: "Sprint 1", actualHours: 4, estimatedHours: 2.5, status: "done" },
  {
    name: "Sprint 2",
    actualHours: 3,
    estimatedHours: 1.5,
    status: "in progress",
  },
  { name: "Sprint 3", actualHours: 2, estimatedHours: 3, status: "to do" },
  { name: "Sprint 4", actualHours: 2.78, estimatedHours: 3.25, status: "done" },
  {
    name: "Sprint 5",
    actualHours: 1.89,
    estimatedHours: 4.8,
    status: "in progress",
  },
  { name: "Sprint 6", actualHours: 2.39, estimatedHours: 3.8, status: "to do" },
  { name: "Sprint 7", actualHours: 3.49, estimatedHours: 4.3, status: "done" },
];

const GanttBar = ({ x, y, width, height, fill }) => {
  return (
    <rect x={x} y={y} width={width} height={height} fill={fill} rx={3} ry={3} />
  );
};


const SprintGanttChart = ({ selectedProject }) => {
  const [burndownData, setBurndownData] = useState(null);
  const [existingSprints, setExistingSprints] = useState(null);
  const [activities,setActivities] = useState();

  useEffect(() => {
    fetchActivityData();
  }, [selectedProject]);
console.log(selectedProject,"selectedProject");
  const fetchActivityData = async () => {
    try {
      console.log("inisdefetch");
      const dbName = localStorage.getItem("dbName");
      const response = await axios.get(
        `${url}/getByProject/activity/${dbName}/${selectedProject.ProjectName}`
      );
      const activities = response.data || [];
      const labels = activities.map((activity) => activity.activityName.trim());
      const id = activities.map((activity) => activity.activityId.trim());
      const estimatedHours = activities.map(
        (activity) => parseFloat(activity.estimatedHours) || 0
      );
      const actualHours = activities.map((activity) => {
        const totalActualHours = (activity.weekId || []).reduce(
          (sum, week) => sum + parseFloat(week.actualHours || 0),
          0
        );
        return totalActualHours;
      });
      console.log(labels, estimatedHours, actualHours, "data");
      const activity = activities.map((activity, index) => ({
        activityName: labels[index],
        activityId: id[index],
        estimatedHours: estimatedHours[index],
        actualHours: actualHours[index],
        status: activity.status,
      }));
      console.log(activity,"activity");
      setActivities(activity);

      setBurndownData({
        labels: labels,
        estimatedHours: estimatedHours,
        actualHours: actualHours,

      });
    } catch (error) {
      console.error("Error fetching activity data:", error);
      setBurndownData(null);
    }
  };

  const cumulativeData = burndownData?.labels?.map((label, index) => ({
    sprint: label,
    cumulativeEstimatedHours: burndownData.estimatedHours[index],
    cumulativeActualHours: burndownData.actualHours[index],
  }));

  const chartData = cumulativeData ? Object.values(cumulativeData) : [];

  const CustomizedRectangle = (props) => {
    const { formattedGraphicalItems } = props;

    if (formattedGraphicalItems.length < 2) {
      return null;
    }

    const firstSeries = formattedGraphicalItems[0];
    const secondSeries = formattedGraphicalItems[1];

    return firstSeries?.props?.points.map((_, index) => {
      const firstSeriesPoint = firstSeries.props.points[index];
      const secondSeriesPoint = secondSeries.props.points[index];
      const yDiff = firstSeriesPoint.y - secondSeriesPoint.y;

      return (
        <Rectangle
          key={index}
          width={10}
          height={Math.abs(yDiff)}
          x={firstSeriesPoint.x - 5}
          y={yDiff > 0 ? secondSeriesPoint.y : firstSeriesPoint.y}
          fill={yDiff > 0 ? "red" : yDiff < 0 ? "green" : "none"}
        />
      );
    });
  };

  const getIntroOfPage = (label) => {
    switch (label) {
      case "Sprint 1":
        return "Sprint 1 is about men's clothing";
      case "Sprint 2":
        return "Sprint 2 is about women's dress";
      case "Sprint 3":
        return "Sprint 3 is about women's bag";
      case "Sprint 4":
        return "Sprint 4 is about household goods";
      case "Sprint 5":
        return "Sprint 5 is about food";
      case "Sprint 6":
        return "Sprint 6 is about baby food";
      default:
        return "";
    }
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${label} : ${payload[0].value}`}</p>
          <p className="intro">{getIntroOfPage(label)}</p>
          <p className="desc">Anything you want can be displayed here.</p>
        </div>
      );
    }

    return null;
  };

  return (
    <div>
      <h1 style={{ textAlign: "center" }}>Sprint Bar Chart</h1>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <BarChart
          width={700}
          height={500}
          data={activities}
          margin={{ top: 20, right: 30, left: 90, bottom: 5 }}
          layout="vertical"
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="number" />
          <YAxis dataKey="activityId" type="category" />
          <Tooltip />
          <Legend />
          {/* <Bar dataKey="activityName" fill="#82ca9d" stackId="a" />
          <Bar dataKey="status" fill="#edda2f" stackId="a" />
          <Bar dataKey="task" fill="#ab2e90" stackId="a" /> */}
          <Bar dataKey="actualHours" stackId="a" fill="#8884d8">
            {activities?.map((entry, index) => (
              <GanttBar
                key={`bar-${index}`}
                x={entry.actualHours}
                y={entry.activityName}
                width={entry.actualHours}
                height={20}
                fill={
                  entry.status === "todo"
                    ? "#FFBB33"
                    : entry.status === "progress"
                    ? "#FF5733"
                    : entry.status === "done"
                    ? "#33FF57"
                    : "#8884d8"
                }
              />
            ))}
          </Bar>
        </BarChart>
      </div>


      <div>
        <h1 style={{ textAlign: "center" }}>Burndown Multi Sprint Chart</h1>
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <LineChart
            width={800}
            height={400}
            data={chartData}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="sprint" type="category" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="cumulativeEstimatedHours"
              name="Cumulative Estimated Hours"
              stroke="#ab2e90"
            />
            <Line
              type="monotone"
              dataKey="cumulativeActualHours"
              name="Cumulative Actual Hours"
              stroke="#8884d8"
            />
          </LineChart>
        </div>
      </div>
      <div>
        <h1 style={{ textAlign: "center" }}>CustomizedRectangle Chart</h1>
        <ResponsiveContainer width="100%" height={500}>
          <LineChart
            width={500}
            height={300}
            data={data2}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <Line type="monotone" dataKey="actualHours" stroke="#8884d8" />
            <Line type="monotone" dataKey="estimatedHours" stroke="#82ca9d" />
            <Customized component={CustomizedRectangle} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default SprintGanttChart;
