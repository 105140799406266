import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Form, Input, List, message, Upload } from 'antd';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';

function FileUpload() {
  const [file, setFile] = useState(null);
  const [files, setFiles] = useState([]);

  const handleFileChange = (event) => {
    setFile(event.fileList[0].originFileObj);
  };

  const handleUpload = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('file', file);
    axios
      .post('http://localhost:3000/upload', formData)
      .then((response) => {
        console.log(response.data);
        message.success('File uploaded successfully!');
        setFile(null);
      })
      .catch((error) => {
        console.error(error);
        message.error('File upload failed');
      });
  };

  useEffect(() => {
    axios
      .get('http://localhost:3000/files')
      .then((response) => {
        console.log(response.data);
        setFiles(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleDownload = (filename) => {
    axios({
      url: `http://localhost:3000/download/${filename}`,
      method: 'GET',
      responseType: 'blob',
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error(error);
        message.error('File download failed');
      });
  };

  return (
    <div>
      <h1>File Upload and Listing</h1>
      <Form >
        <Form.Item>
          <Upload onChange={handleFileChange}>
            <Button icon={<UploadOutlined />}>Select File</Button>
          </Upload>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" onClick={handleUpload} disabled={!file} icon={<UploadOutlined />}>
            Upload
          </Button>
        </Form.Item>
      </Form>
      <h2>Files:</h2>
      <List
        dataSource={files}
        renderItem={(file, index) => (
          <List.Item key={index}>
            {file.filename}
            <Button
              type="primary"
              icon={<DownloadOutlined />}
              onClick={() => handleDownload(file.filename)}
              style={{ marginLeft: '1rem' }}
            >
              Download
            </Button>
          </List.Item>
        )}
      />
    </div>
  );
}

export default FileUpload;
