import React, { useEffect, useState } from "react";
import { url } from "../../url/url";
import axios from "axios";
import {
  Button,
  Descriptions,
  Modal,
  Popconfirm,
  Table,
  notification,
} from "antd";
import {
  EyeFilled,
  CheckCircleFilled,
  CloseCircleFilled,
} from "@ant-design/icons";
import { decryptRole } from "../../encrypt";

const Teamrequest = () => {
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [allTravelRequests, setAllTravelRequests] = useState([]);
  const users = JSON.parse(localStorage.getItem("user"));
  const role = localStorage.getItem("role");
  const [modalVisible, setModalVisible] = useState(false);
  const handleClickOpen = (record) => {
    setSelectedRequest(record);
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    setSelectedRequest(null);
  };
  const empapr = async (ids, email) => {
    const dbName = localStorage.getItem("dbName");
    console.log("dbName in empapr in managerapproval", dbName);
    const res = await axios.put(`${url}/request/approve/${ids}/${dbName}`, {
      status: "Approved",
      email: email,
    });
    notification.success({ message: "Successfully updated" });
  };
  const empdeny = async (ids, email) => {
    const dbName = localStorage.getItem("dbName");
    const res = await axios.put(`${url}/request/approve/${ids}/${dbName}`, {
      status: "Denied",
      email: email,
    });
    notification.success({ message: "Successfully updated" });
  };
  const handleApprove = (record) => {
    console.log("Approved:", record);
    const updatedRequests = allTravelRequests.map((request) =>
      request._id === record._id ? { ...request, status: "Approved" } : request
    );
    setAllTravelRequests(updatedRequests);
    empapr(record._id, record.email);
  };

  const handleDeny = (record) => {
    console.log("Denied:", record);
    const updatedRequests = allTravelRequests.map((request) =>
      request._id === record._id ? { ...request, status: "Denied" } : request
    );
    setAllTravelRequests(updatedRequests);
    empdeny(record._id, record.email);
  };

  useEffect(() => {
    const fetchData = async () => {
      const dbName = localStorage.getItem("dbName");
      const encryptedRoleFromStorage = localStorage.getItem("role");
      const jobTitle = decryptRole(encryptedRoleFromStorage);
      try {
        if (jobTitle === "hr" || jobTitle === "admin") {
          const response = await axios.get(
            `${url}/getall/travelrequest/${dbName}`
          );
          const filteredRequests = response.data.travelRequests.filter(
            (request) => request.email !== users.email
          );
          setAllTravelRequests(filteredRequests);
        } else if (jobTitle === "manager") {
          const response = await axios.get(
            `${url}/get/travelrequest/${dbName}/${users.displayName}`
          );
          // Filter out the displayName's request details
          const filteredRequests = response.data.travelRequests.filter(
            (request) => request.email !== users.email
          );
          setAllTravelRequests(filteredRequests);
          console.log(filteredRequests, "Request");
        }
      } catch (error) {
        console.error("Error fetching travel requests:", error);
      }
    };

    fetchData();
  }, []);

  const columns1 = [
    {
      title: "Employee Id",
      dataIndex: "employeeId",
      width: 200,

      filters: allTravelRequests
        .map((record) => record.employeeId)
        .filter((value, index, self) => self.indexOf(value) === index)
        .map((value) => ({ text: value, value })),
      onFilter: (value, record) => record.employeeId.includes(value),
    },
    {
      title: "Employee Name",
      dataIndex: "firstName",
      width: 300,
      filters: allTravelRequests
        .map((record) => `${record.firstName} ${record.lastName}`)
        .filter((value, index, self) => self.indexOf(value) === index)
        .map((value) => ({ text: value, value })),
      onFilter: (value, record) =>
        `${record.firstName} ${record.lastName}`.includes(value),
      render: (_, record) => (
        <>
          {record.firstName} {record.lastName}
        </>
      ),
    },
    {
      title: "Request Id",
      dataIndex: "requestId",
      width: 200,
      filters: allTravelRequests
        .map((record) => record.requestId)
        .filter((value, index, self) => self.indexOf(value) === index)
        .map((value) => ({ text: value, value })),
      onFilter: (value, record) => record.requestId.includes(value),
    },
    {
      title: "Travel Type",
      dataIndex: "travelType",
      width: 150,
      filters: allTravelRequests
        .map((record) => record.travelType)
        .filter((value, index, self) => self.indexOf(value) === index)
        .map((value) => ({ text: value, value })),
      onFilter: (value, record) => record.travelType.includes(value),
    },
    {
      title: "Purpose",
      dataIndex: "reasonForTravel",
      width: 150,
      filters: allTravelRequests
        .map((record) => record.reasonForTravel)
        .filter((value, index, self) => self.indexOf(value) === index)
        .map((value) => ({ text: value, value })),
      onFilter: (value, record) => record.reasonForTravel.includes(value),
    },
    {
      title: "From date",
      dataIndex: "departureDate",
      width: 150,
      filters: allTravelRequests
        .map((record) => record.departureDate)
        .filter((value, index, self) => self.indexOf(value) === index)
        .map((value) => ({ text: value, value })),
      onFilter: (value, record) => record.departureDate.includes(value),
    },
    {
      title: "To Date",
      dataIndex: "returnDate",
      width: 150,
      filters: allTravelRequests
        .map((record) => record.returnDate)
        .filter((value, index, self) => self.indexOf(value) === index)
        .map((value) => ({ text: value, value })),
      onFilter: (value, record) => record.returnDate.includes(value),
    },

    {
      title: "Status",
      dataIndex: "status",
      width: 150,
      filters: allTravelRequests
        .map((record) => record.status)
        .filter((value, index, self) => self.indexOf(value) === index)
        .map((value) => ({ text: value, value })),
      onFilter: (value, record) => record.status.includes(value),
      render: (text, record) => (
        <span
          style={{
            color:
              record.status === "Approved"
                ? "green"
                : record.status === "Denied"
                ? "red"
                : "inherit",
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "View",
      dataIndex: "action",
      width: 150,
      render: (text, record) => (
        <Button
          type="primary"
          onClick={() => {
            handleClickOpen(record);
          }}
          icon={<EyeFilled />}
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 250,
      render: (text, record) =>
        record.status === "Submitted" && (
          <span style={{ whiteSpace: "nowrap" }}>
            <Popconfirm
              title="Are you sure want to approve ?"
              onConfirm={() => handleApprove(record)}
            >
              <Button style={{ color: "green" }} icon={<CheckCircleFilled />} />
            </Popconfirm>
            <Popconfirm
              title="Are you sure want to deny ?"
              onConfirm={() => handleDeny(record)}
            >
              <Button style={{ color: "red" }} icon={<CloseCircleFilled />} />
            </Popconfirm>
          </span>
        ),
    },
  ];
  return (
    <div
      style={{ transform: "scale(0.9)", transformOrigin: "0 0", width: "110%" }}
    >
      <h4>Travel Request</h4>
      <br />
      {role === "employee" ? null : (
        <div style={{ width: "100%", overflowX: "auto" }}>
          <Table
            className="custom-table col-lg-12 col-md-12 col-sm-12"
            dataSource={allTravelRequests}
            columns={columns1}
            scroll={{ x: true }}
            sticky
          />
        </div>
      )}
      <Modal
        title="Travel Request Details"
        visible={modalVisible}
        onCancel={handleCloseModal}
        footer={null}
      >
        {selectedRequest && (
          <Descriptions column={1} bordered>
            <Descriptions.Item label="Employee Id">
              {selectedRequest.employeeId}
            </Descriptions.Item>
            <Descriptions.Item label="Employee Name">
              {selectedRequest.firstName} {selectedRequest.lastName}
            </Descriptions.Item>
            <Descriptions.Item label="Email">
              {selectedRequest.email}
            </Descriptions.Item>
            <Descriptions.Item label="Manager">
              {selectedRequest.manager}
            </Descriptions.Item>
            <Descriptions.Item label="Department">
              {selectedRequest.department}
            </Descriptions.Item>
            <Descriptions.Item label="Destination Country">
              {selectedRequest.destinationCountry}
            </Descriptions.Item>
            <Descriptions.Item label="Destination State">
              {selectedRequest.destinationState}
            </Descriptions.Item>
            <Descriptions.Item label="Destination City">
              {selectedRequest.destinationCity}
            </Descriptions.Item>
            <Descriptions.Item label="Zipcode">
              {selectedRequest.zipCode}
            </Descriptions.Item>
            <Descriptions.Item label="Origin Country">
              {selectedRequest.OriginCountry}
            </Descriptions.Item>
            <Descriptions.Item label="Origin State">
              {selectedRequest.OriginState}
            </Descriptions.Item>
            <Descriptions.Item label="Origin City">
              {selectedRequest.OriginCity}
            </Descriptions.Item>
            <Descriptions.Item label="Request Date">
              {selectedRequest.requestDate}
            </Descriptions.Item>
            <Descriptions.Item label="Departure Date">
              {selectedRequest.departureDate}
            </Descriptions.Item>
            <Descriptions.Item label="Return Date">
              {selectedRequest.returnDate}
            </Descriptions.Item>
            <Descriptions.Item label="Purpose">
              {selectedRequest.reasonForTravel}
            </Descriptions.Item>
            <Descriptions.Item label="Travel Cost">
              {selectedRequest.estimatedTravelCosts}
            </Descriptions.Item>
            <Descriptions.Item label="Travel Advance">
              {selectedRequest.travelAdvance}
            </Descriptions.Item>
            <Descriptions.Item label="Travel Type">
              {selectedRequest.travelType}
            </Descriptions.Item>
            <Descriptions.Item label="Currency">
              {selectedRequest.currency}
            </Descriptions.Item>
            <Descriptions.Item label="Comments">
              {selectedRequest.comments}
            </Descriptions.Item>
          </Descriptions>
        )}
      </Modal>
    </div>
  );
};

export default Teamrequest;
